import { Typography } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from 'react-select';
import vendorImg from "../../assets/images/vendor/vendor.png"
import axios from "axios";
import useAxiosUserSecureConfig from "../../hooks/useAxiosUserSecureConfig";
import { useNavigate , useParams } from "react-router-dom";
import { toast } from "react-toastify";
import placholder_image from "../../assets/images/booking/placeholder-image.jpg"
import error from "../Error";
import uplooad from "../../assets/images/icons/upload.svg";
import Loader from "../../components/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";


const optionsInOut =[
    {label:"indoor", value:"indoor"},
    {label:"outdoor", value:"outdoor"},
];
const optionVailable =[
    {label:"Yes", value:1},
    {label:"No", value:2},
];
const optionsService =[
  {label:"", value:""},
  {label:"", value:""},
];


function AddVenues() {
  const { t, i18n } = useTranslation();
  const Dayoptions =[
    {label:t("SUN"), value:"1"},
    {label:t("MON"), value:"2"},
    {label:t("TUE"), value:"3"},
    {label:t("WED"), value:"4"},
    {label:t("THU"), value:"5"},
    {label:t("FRI"), value:'6'},
    {label:t("SAT"), value:"7"},
    
  ];
  const [imageBase64, setImageBase64] = useState(placholder_image);
  const [imageName, setImageName] = useState('');

  const handleImageChange = (e) => {
    setImageName(e.target.files[0].name);
    const file = e.target.files[0];
  
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Extract base64 data without the prefix
        const base64WithoutPrefix = reader.result;
        setImageBase64(base64WithoutPrefix);
      };
      reader.readAsDataURL(file);
    }
  };


    const [selectedOption, setSelectedOption] = useState(null);
    const [services, setServices] = useState({ loading: true, data: [] });

const fetchData = () => {
  axios.post('/api/my_services', {}, config)
    .then((res) => {
      console.log("services", res.data);
      if (!res.data?.result?.success) {
        toast.error("Error while fetching services!");
        return;
      }
      setServices({
        loading: false,
        data: res.data?.result?.products?.slice().reverse() || [],
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
useEffect(() => {
  fetchData();
}, []);
    const customStyles = {
      control: (styles) => ({
      ...styles,
      background: "#F2F4F8", "border-color": "#F2F4F8" , "border-radius" :"8px" ,padding: "6px" ,
      width:"100%" })
  }
  const [selectedItems, setSelectedItems] = useState(new Set()); // Use a Set for efficient lookups

  const handleSelect = (item) => {
    const newSelectedItems = new Set(selectedItems); // Create a new Set to avoid mutation
    if (newSelectedItems.has(item)) {
      newSelectedItems.delete(item);
    } else {
      newSelectedItems.add(item);
    }
    setSelectedItems(newSelectedItems);
  };

  const getList = (items) => items.filter((item) => !selectedItems.has(item)); // Filter based on selected items

  
    const navigate = useNavigate();
    const {
        register,
        watch,
        handleSubmit,
        setValue,
        control,
        formState: { errors },
      } = useForm();
    
      const guestIncreaseValue = watch("guestIncrease");
      useEffect(() => {
        // Reset the NoOfGuest field when guestIncrease changes to "No"
        if (guestIncreaseValue?.value === 2) {
          setValue("NoOfGuest", "");
        }
      }, [guestIncreaseValue, setValue]);

      const config = useAxiosUserSecureConfig();

      const [venue, setVenue] = useState({
        loading: true,
        data: {},
      });

      const onSubmit=(data)=>{
        const selectedValues = data.Unavailable.map((option) => option.value);
        const availableDays = {
          available_sun: selectedValues.includes("1"),
          available_mon: selectedValues.includes("2"),
          available_tue: selectedValues.includes("3"),
          available_wed: selectedValues.includes("4"),
          available_thu: selectedValues.includes("5"),
          available_fri: selectedValues.includes("6"),
          available_sat: selectedValues.includes("7"),
        };

        const IncludedArray = [];
        const otherArray = []; 

        const filterService =()=>{
           data.IncludedService.map ((service)=>{
            IncludedArray.push(service.value)
           })
           data.OtherService.map ((service)=>{
            otherArray.push(service.value)
           })
        }
        filterService ();
        data={
          name: data.VenueName,
          facility_id: facility.data.id,
          size: data.size.value,
          indoor_outdoor: data.InOut.value,
          basic_price:  data.basicPrice,
          description: data.description,
          included_services: IncludedArray,
          optional_services: otherArray,
          ...availableDays,
          image :imageBase64.split(",")[1],
        }
        console.log("formDataWithAvailability", data);
      axios.post('/api/venue-vendor/venue/create', data, config)
      .then((res)=>{
        if(!res.data.result?.success){
          console.log("error:",res.data)
          toast.error(res.data.result?.message || t("Request failed!"));
          return;
        }

        toast.success(t("You add Venue successfully!"));
        
       
     const venue = res.data.result;
     console.log("venue",venue)


      navigate (`/vendor/edit-venues/${facility.data.id}/${venue.venue.id}`)
      })
      .catch((err) => {
        console.log("err" , err)
        toast.error(t("Error while adding Venue!"))
      })
      
        }
       

        const params = useParams();
        const [facility, setFacility] = useState({
          loading: true,
          data: {},
        });
        useEffect(() => {
          axios
            .post(`/api/venue-vendor/facility/${params.id}`, { id: params.id } , config)
            .then((res) => {
              console.log("facility", res.data);
      
              if (!res.data?.result?.success) {
                toast.error("Error while fetching facility!");
              }
              if (res.data?.result?.status === 'new') {
                toast.error("Sorry Facility isn't approved yet by admins!");
              }
        
              setFacility({
                loading: false,
                data: res.data?.result || {}, // Set to an empty object if undefined
              });
            })
            .catch((err) => {
              console.log(err);
            });
        }, [params.id]);
      
        const [venueSize , setVenueSize] = useState({ loading: true, data: [] })
        useEffect(() => {
        axios.post('/api/venue-sizes', {}).then((res) => {
          console.log("Venue size", res.data);
        
          if (!res.data?.result?.success) {
            toast.error("Error while fetching venue size");
            return;
          }
        
          setVenueSize(prevState => ({
            ...prevState,
            loading: false,
            data: res.data?.result?.venueSizes 
          }))
        
        }).catch((err) => {
          console.log(err);
        })
        },[])
        console.log("venue sizes :" , venueSize)  
        


        const [includedServices, setIncludedServices] = useState([]);
        const [otherServices, setOtherServices] = useState([]);

  return (
    <section className="px-5 xl:px-10 py-10">
      <div className="justify-start items-center gap-2 hidden sm:inline-flex">
        <div
          onClick={() => navigate("/vendor/account")}
          className="text-zinc-400 text-base font-normal leading-[48px] hover:text-gray-dark cursor-pointer"
        >
          {t("My Account")}
        </div>
        <FontAwesomeIcon
          className="text-zinc-400 text-base"
          icon={faAngleRight}
        />
        <div
          onClick={() => navigate("/vendor/Facilities")}
          className="text-zinc-400 text-base font-normal leading-[48px] hover:text-gray-dark cursor-pointer"
        >
          {t("My Facilities")}
        </div>
        <FontAwesomeIcon
          className="text-zinc-400 text-base"
          icon={faAngleRight}
        />
        <div
          onClick={() => navigate(`/vendor/Facilities/${facility.data.id}`)}
          className="text-zinc-400 text-base font-normal leading-[48px] hover:text-gray-dark cursor-pointer"
        >
          {facility.data.name}
        </div>
      </div>
      <div className="xl:px-10 flex gap-10 flex-wrap md:flex-nowrap ">
        <div className="flex md:w-1/3 w-full flex-col gap-6 items-center">
          <img
            src={imageBase64}
            className=" w-full h-[260px] object-cover rounded-lg"
          />

          <div className="self-stretch flex-col justify-start items-start gap-2 flex">
            <div className="relative w-full bg-slate-100 border-slate-100 rounded-lg">
              <label>
                <div className="h-12 flex justify-between items-center">
                  <input
                    type="file"
                    hidden
                    onChange={handleImageChange}
                    id="fileInput"
                    className="custom-file-input absolute left-[-100px] text-zinc-500 "
                  />

                  <label htmlFor="fileInput" className="cursor-pointer ">
                    <img
                      src={uplooad}
                      className={`absolute ${i18n.language === 'en' ? 'right-3' : 'left-3'}  bottom-3`}
                      alt="upload"
                    />
                  </label>

                  {/* Placeholder and image name */}
                  <input
                    type="text"
                    className="border-0 flex-grow p-4 bg-slate-100 rounded-lg text-zinc-500 text-base font-normal leading-tight placeholder-[#808080]"
                    placeholder={t("Choose a file")}
                    value={imageName ? imageName : ""}
                    readOnly
                  />
                </div>
              </label>
            </div>
          </div>
        </div>

        <div className="grow ">
          <p className="text-lg md:text-2xl text-red font-semibold leading-9 mb-6">
            {t("Add Venue")}
          </p>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col gap-10">
              <div className="grid lg:grid-cols-2 gap-x-6 gap-y-4">
                <div className="flex flex-col justify-start items-start gap-2 ">
                  <label className="text-zinc-800 text-base font-normal leading-snug">
                    {t("Venue name")}{" "}
                    <span className="text-red-500 text-base font-normal leading-snug">
                      *
                    </span>
                  </label>
                  <input
                    {...register("VenueName", {
                      required: t("Venue name is required"),
                    })}
                    placeholder={t("name")}
                    className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                  />

                  {errors.VenueName && (
                    <Typography variant="small" color="red">
                      {errors.VenueName?.message}
                    </Typography>
                  )}
                </div>
                <div className="flex flex-col justify-start items-start gap-2 ">
                  <label className="text-zinc-800 text-base font-normal leading-snug">
                    {t("Basic Price")}{" "}
                    <span className="text-red-500 text-base font-normal leading-snug">
                      *
                    </span>
                  </label>
                  <input
                    {...register("basicPrice", {
                      required: t("Basic Price is required"),
                    })}
                    type="number"
                    placeholder={t("price")}
                    className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                  />

                  {errors.basicPrice && (
                    <Typography variant="small" color="red">
                      {errors.basicPrice?.message}
                    </Typography>
                  )}
                </div>

                <div className="flex flex-col justify-start items-start gap-2 ">
                  <label className="text-zinc-800 text-base font-normal leading-snug">
                    {t("Available Days")}{" "}
                    <span className="text-red-500 text-base font-normal leading-snug">
                      *
                    </span>
                  </label>
                  <Controller
                    control={control}
                    name="Unavailable"
                    render={({ field }) => (
                      <Select
                        placeholder={t("Select...")}
                        className="w-full  border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                        defaultValue={selectedOption}
                        onChange={setSelectedOption}
                        options={Dayoptions}
                        isMulti
                        isClearable
                        {...field}
                        styles={customStyles}
                      />
                    )}
                  />
                  {errors.Unavailable && (
                    <Typography variant="small" color="red">
                      {errors.Unavailable?.message}
                    </Typography>
                  )}
                </div>

                {venueSize.loading && <Loader slim />}
                {!venueSize.loading && (
                  <div className="flex flex-col justify-start items-start gap-2 ">
                    <label className="text-zinc-800 text-base font-normal leading-snug">
                      {t("Number of Guest")}{" "}
                      <span className="text-red-500 text-base font-normal leading-snug">
                        *
                      </span>
                    </label>
                    <Controller
                      control={control}
                      name="size"
                      rules={{ required: t("Number of guest is required") }}
                      render={({ field, field: { name, ref } }) => {
                        const options = Object.entries(venueSize.data).map(
                          ([value, label]) => ({
                            value,
                            label,
                          })
                        );
                        return (
                          <Select
                            styles={customStyles}
                            name={name}
                            ref={ref}
                            isClearable
                            isSearchable
                            options={options}
                            {...field}
                            className="w-full"
                            placeholder={t("Select...")}
                          />
                        );
                      }}
                    />
                    {errors.size && (
                      <Typography variant="small" color="red">
                        {errors.size?.message}
                      </Typography>
                    )}
                  </div>
                )}
                {/* {venueSize.loading && <Loader slim />}
              {!venueSize.loading && (
              <div className="flex flex-col justify-start items-start gap-2 ">
                <label className="text-zinc-800 text-base font-normal leading-snug">
                 Number of Guest
                </label>
                <Controller
                    control={control}
                    name="size"
                    rules={{required: 'Number of guest is required'}}
                    render={({ field, field: { name, ref } }) => {
                      return <Select
                          styles={customStyles}
                          name={name}
                          ref={ref}
                          isClearable
                          isSearchable
                          options={venueSize.data.map((size) => ({
                            value: size.id,
                            label: size.name,
                          }))}
                          {...field}
                          className="w-full"
                      />
                    }}
                />
                {errors.size && (
                    <Typography variant="small" color="red">
                      {errors.size?.message}
                    </Typography>
                )}
              </div>
                 )} */}

                <div className="flex flex-col justify-start items-start gap-2 ">
                  <label className="text-zinc-800 text-base font-normal leading-snug">
                    {t("Indoor/Outdoor")}{" "}
                    <span className="text-red-500 text-base font-normal leading-snug">
                      *
                    </span>
                  </label>
                  <Controller
                    control={control}
                    name="InOut"
                    rules={{ required: t("Indoor or Outdoor") }}
                    render={({ field }) => (
                      <Select
                        styles={customStyles}
                        isClearable
                        isSearchable
                        options={optionsInOut}
                        {...field}
                        className="w-full"
                        placeholder={t("Select...")}
                      />
                    )}
                  />

                  {errors.InOut && (
                    <Typography variant="small" color="red">
                      {errors.InOut?.message}
                    </Typography>
                  )}
                </div>

                {services.loading && <Loader slim />}
                {!services.loading && (
                  <div className="flex flex-col justify-start items-start gap-2 ">
                    <label className="text-zinc-800 text-base font-normal leading-snug">
                      {t("Included Services")}{" "}
                      <span className="text-red-500 text-base font-normal leading-snug">
                        *
                      </span>
                    </label>
                    <Controller
                      control={control}
                      name="IncludedService"
                      rules={{}}
                      render={({ field }) => (
                        <Select
                          styles={customStyles}
                          isDisabled={false}
                          isClearable
                          isMulti
                          isSearchable
                          placeholder={t("Select...")}
                          options={services.data
                            .filter((serv) => !otherServices.includes(serv.id)) // Filter out services selected in otherServices
                            .map((serv) => ({
                              value: serv.id,
                              label: serv.name,
                            }))}
                          {...field}
                          className="w-full"
                          onChange={(selectedOptions) => {
                            setIncludedServices(
                              selectedOptions.map((option) => option.value)
                            );
                            field.onChange(selectedOptions);
                          }}
                        />
                      )}
                    />

                    {errors.IncludedService && (
                      <Typography variant="small" color="red">
                        {errors.IncludedService?.message}
                      </Typography>
                    )}
                  </div>
                )}
                {services.loading && <Loader slim />}
                {!services.loading && (
                  <div className="flex flex-col justify-start items-start gap-2 ">
                    <label className="text-zinc-800 text-base font-normal leading-snug">
                      {t("Other services")}{" "}
                      <span className="text-red-500 text-base font-normal leading-snug">
                        *
                      </span>
                    </label>
                    <Controller
                      control={control}
                      name="OtherService"
                      rules={{}}
                      render={({ field }) => (
                        <Select
                          styles={customStyles}
                          isDisabled={false}
                          isClearable
                          isMulti
                          placeholder={t("Select...")}
                          isSearchable
                          options={services.data
                            .filter(
                              (serv) => !includedServices.includes(serv.id)
                            ) // Filter out services selected in includedServices
                            .map((serv) => ({
                              value: serv.id,
                              label: serv.name,
                            }))}
                          {...field}
                          className="w-full"
                          onChange={(selectedOptions) => {
                            setOtherServices(
                              selectedOptions.map((option) => option.value)
                            );
                            field.onChange(selectedOptions);
                          }}
                        />
                      )}
                    />

                    {errors.OtherService && (
                      <Typography variant="small" color="red">
                        {errors.OtherService?.message}
                      </Typography>
                    )}
                  </div>
                )}

                <div className="flex flex-col justify-start items-start gap-2 ">
                  <label className="text-zinc-800 text-base font-normal leading-snug">
                    {t("Description")}{" "}
                    <span className="text-red-500 text-base font-normal leading-snug">
                      *
                    </span>
                  </label>
                  <textarea
                    {...register("description", {})}
                    placeholder={t("description")}
                    className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                  />

                  {errors.description && (
                    <Typography variant="small" color="red">
                      {errors.description?.message}
                    </Typography>
                  )}
                </div>
              </div>
              <div className="flex justify-end w-full">
                <button
                disabled={facility.data.status === 'new'}
                  type="submit"
                  className={`${facility.data.status === 'new' && 'opacity-50'} w-fit px-16 py-3 bg-red rounded-[10px] shadow border border-white justify-center items-center gap-2.5 inline-flex`}
                >
                  <div  className="text-center text-white text-base font-normal leading-normal">
                    {t("Save")}
                  </div>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );

    }
    


export default AddVenues;