import React, { useEffect, useState } from 'react';
import { useTable, useSortBy, usePagination } from 'react-table';
import ReactPaginate from 'react-paginate';
import useAxiosUserSecureConfig from '../../../hooks/useAxiosUserSecureConfig';
import axios from 'axios';
import { toast } from 'react-toastify';
import Loader from '../../../components/Loader';
import { useNavigate } from 'react-router';
import editIcon from "../../../assets/images/icons/edit.png";
import trashIcon from "../../../assets/images/icons/trash.png";
import eyeIcon from "../../../assets/images/icons/eye.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import {useTranslation} from "react-i18next";




function InvitaionsList () {
  const [currentPage, setCurrentPage] = useState(0);
  const config = useAxiosUserSecureConfig();
  const [bookingRequests, setBookingRequests] = useState({ loading: true, data: [] });
  const {t ,i18n } = useTranslation();
  const columns = React.useMemo(
    ()=>[
    { Header: t('Groom Name'), accessor: 'groomName' },
    { Header: t('Wedding Address'), accessor: 'address' },
    { Header: t('Date'), accessor: 'date' },] ,
    [t]
  );
const fetchData = () => {
  axios.post('/api/invitation', {}, config)
    .then((res) => {
      console.log("invitations", res.data);
      if (!res.data?.result?.success) {
        toast.error("Error while fetching invitations!");
        return;
      }
      setBookingRequests({
        loading: false,
        data: res.data?.result?.invitations?.slice().reverse() || [],
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
useEffect(() => {
  fetchData();
}, []);

  const reqestsData = React.useMemo(() => {
    return bookingRequests.data.length > 0
      ? bookingRequests.data
          .map((key) => ({
            id: key.id,
            address: key.wedding_address,
            groomName: key.groom_name,
            date: key.wedding_date,
          }))
      : [];
  }, [bookingRequests.data]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data: reqestsData,
      initialState: { pageIndex: currentPage, pageSize: 20 }, 
    },
    useSortBy,
    usePagination
  );

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const navigate = useNavigate()

  const handleClick = (rowData) => {
    navigate(`/invitation/view/${rowData.original.id}`);
  };
  const handleDeleteInvitation = (data) => {
    console.log(data.original.id);
    setBookingRequests((prevState) => ({
      ...prevState,
      loading: true,
    }));
    data = {
      invitation_id: data.original.id,
   }
    axios
      .post("/api/invitation/delete", data, config)
      .then((res) => {
        if (!res.data.result?.success) {
          toast.error(res.data.result?.message || "Request failed!");
          return;
        }
        setBookingRequests((prevState) => ({
          ...prevState,
          loading: false,
        }));
        toast.success(t("You delete successfully!"));
        fetchData()
      })
      .catch((err) => {
        console.log("err", err);
        toast.error("Error while deleting guest!");
        setBookingRequests((prevState) => ({
          ...prevState,
          loading: false,
        }));
      });
  };


  return  (
    <section className="px-5 xl:px-20 py-10">
        <div className="justify-start items-center gap-2  hidden sm:inline-flex mb-5">
          <div
            onClick={() => navigate("/user/account")}
            className="text-zinc-400 text-base font-normal leading-[48px] hover:text-gray-dark cursor-pointer"
          >
            {t("My Account")}
          </div>
          <FontAwesomeIcon
            className="text-zinc-400 text-base"
            icon={faAngleRight}
          />
          <div
            className="text-zinc-400 text-base font-normal leading-[48px] hover:text-gray-dark cursor-pointer"
          >
            {t("My Invitations")}
          </div>
          </div>
      {bookingRequests.loading && <Loader slim />}
      {!bookingRequests.loading && <>
        <div className="justify-start items-center gap-2 flex mb-8">
        <div className="text-2xl font-semibold leading-9 text-red">{t("My Invitations")}</div>
        <div className="text-base font-light leading-normal">({reqestsData.length})</div>
      </div>
      <div className='!overflow-x-auto'>
      <table {...getTableProps()} className="w-full table-auto border-collapse mb-10">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr
              className="h-12 text-nowrap bg-slate-100"
              {...headerGroup.getHeaderGroupProps()}
            >
              <th className="py-2 px-4 border text-nowrap text-gray-500 text-base font-medium leading-snug">
                {"#"}
              </th>{" "}
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={`py-2 px-4 border text-nowrap text-gray-500 text-base font-medium leading-snug ${
                    column.isSorted
                      ? column.isSortedDesc
                        ? "sort-desc"
                        : "sort-asc"
                      : ""
                  }`}
                >
                  {column.render("Header")}
                </th>
              ))}
              <th
                colSpan="3"
                className="py-2 px-4 border text-nowrap text-gray-500 text-base font-medium leading-snug"
              >
                {t("Actions")}
              </th>
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.slice(currentPage * 20, (currentPage + 1) * 20).map((row, index) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className="border-t h-12 cursor-pointer hover:bg-slate-100">
                <td className="px-4 border text-center bg-slate-100 text-nowrap text-gray-500 text-xs font-light leading-none">
                  {index + 1 + currentPage * 20}
                </td>{" "}
                {row.cells.map((cell, cellIndex) => (
                 <td
                 {...cell.getCellProps()}
                 className="px-4 border text-center text-nowrap text-[#697077] text-sm font-normal leading-tight"
                 key={cellIndex}
               >
                 
                   
                    {cell.render("Cell")}
               </td>
                ))}
                <td className="px-2 text-nowrap border text-center ">
                  <button
                  onClick={()=>navigate(`/invitation/edit/${row.original.id}`)}
                    className=""
                  >         
                    <img src={editIcon} alt="editIcon" />
                  </button>
                </td>
                <td className="px-2 border text-center">
                  <button
                  onClick={() => handleClick(row)} 
                    className=""
                  >
                    <img src={eyeIcon} alt="eyeIcon" />
                  </button>
                </td>
                <td className="px-2 border text-center">
                  <button
                   onClick={() => handleDeleteInvitation(row)} 
                    className=""
                  >
                    <img src={trashIcon} alt="trashIcon" />
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      </div>
        <ReactPaginate
        breakLabel="..."
        nextLabel={t("Next >")}
        onPageChange={handlePageClick}
        pageRangeDisplayed={Math.ceil(rows.length / 20)} 
        pageCount={Math.ceil(rows.length / 20)}
        previousLabel={t("< Previous")}
        renderOnZeroPageCount={null}
        containerClassName="pagination"
        pageLinkClassName="page-num"
        previousLinkClassName="page-btn"
        nextLinkClassName="page-btn"
        activeLinkClassName="active"
        disabledLinkClassName="disabled-btn"
      />
      </>}
    </section>
  );
};

export default InvitaionsList;