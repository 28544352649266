import React, { useEffect, useState } from 'react';
import { useTable, useSortBy, usePagination } from 'react-table';
import eyeIcon from "../../assets/images/icons/eye.png";
import filterIcon from "../../assets/images/sevices/filter.png";
import actionIcon from "../../assets/images/plan/action.png";
import daterIcon from "../../assets/images/plan/date.png";
import progressIcon from "../../assets/images/plan/progress.png";
import costIcon from "../../assets/images/plan/cost.png";
import notesIcon from "../../assets/images/plan/notes.png";
import weddingIcon from "../../assets/images/plan/happy-wedding 1.png";
import { Box, Modal,Typography, Checkbox, FormControlLabel,FormGroup } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faCaretDown, faX } from '@fortawesome/free-solid-svg-icons';
import { CiCircleCheck } from "react-icons/ci";
import trashIcon from "../../assets/images/icons/trash.png";
import {useTranslation} from "react-i18next";


const TableComponent = ({ columns, data , handleAddNotes, handleViewNotes , handleAddDate , handleChangProgress , handleChangeCost, handleDeleteAction }) => {
  const {t ,i18n } = useTranslation(); 
  const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
    } = useTable(
      {
        columns,
        data,
      },
      useSortBy,
      usePagination
    );
  
    const iconMapping = {
      action: actionIcon,
      date: daterIcon,
      state: progressIcon,
      cost: costIcon,
      notes: notesIcon,
    };
    const [progressShowDropdown, setProgressShowDropdown] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const handleProgressFilterChange = (value) => {
      setProgressFilter(value);
      setProgressShowDropdown(false);
    };
    return(
      <table
      {...getTableProps()}
      className="w-full table-auto border-collapse border mb-10 plan-table"
    >
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr
            className="h-12 bg-slate-100"
            {...headerGroup.getHeaderGroupProps()}
          >
            <th className="py-2 px-4 text-gray-500 text-base font-medium leading-snug">
              {"#"}
            </th>{" "}
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps(
                  column.getSortByToggleProps()
                )}
                className={`py-2 px-4 text-gray-500 text-base font-medium leading-snug ${
                  column.isSorted
                    ? column.isSortedDesc
                      ? "sort-desc"
                      : "sort-asc"
                    : ""
                }`}
              >
                <div className="flex justify-center items-center gap-2">
                  {iconMapping[column.id] && (
                    <img
                      src={iconMapping[column.id]}
                      alt={column.id}
                    />
                  )}{" "}
                  {column.render("Header")}
                </div>
              </th>
            ))}
            <th className="py-2 px-2 text-gray-500 text-base font-medium leading-snug"></th>
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, index) => {
          prepareRow(row);
          // Assuming weddingPlan.data[key] corresponds to row.original.id
          return (
            <tr
              {...row.getRowProps()}
              className=" h-12 cursor-pointer border-b py-5"
            >
              <td className="px-4 text-center text-gray-500 text-xs font-light leading-none">
                <Checkbox
                  checked={row.values.state === "completed"}
                  className="text-red"
                />
              </td>{" "}
              {row.cells.map((cell, cellIndex) => (
                <td
                  {...cell.getCellProps()}
                  className="px-4 text-center text-[#697077] text-sm font-normal leading-tight"
                  key={cellIndex}
                >
                  {cell.column.id === "state" ? (
                    <div className="relative inline-block ">
                      <button
                        onClick={(e) => {
                          setProgressShowDropdown(!progressShowDropdown);
                          e.stopPropagation();
                          setSelectedRow(row);
                        }}
                        className={`w-[120px] py-2 ${
                          cell.value === "completed"
                            ? "bg-[#E5FFE5]"
                            : cell.value === "not_required"
                            ? "bg-[#F5DCDC]"
                            : cell.value === "in_progress"
                            ? "bg-[#FFF9D9]"
                            : "bg-[#F5F5F5]"
                        } rounded-lg justify-center items-center flex gap-2 mx-auto`}
                      >
                        <span
                          className={`${
                            cell.value === "completed"
                              ? "text-[#49CC29]"
                              : cell.value === "not_required"
                              ? "text-[#FF4B4B]"
                              : cell.value === "in_progress"
                              ? "text-[#F2D230]"
                              : "text-[#CCCCCC]"
                          } text-sm font-medium leading-none tracking-wide`}
                        >
                          {cell.value === "completed" && t("Completed")}
                          {cell.value === "in_progress" && t("In Progress")}
                          {cell.value === "not_required" && t("Not Required")}
                          {cell.value === "not_started" && t("Not Started")}
                        </span>
                        <FontAwesomeIcon
                          className="text-zinc-500 text-sm font-medium leading-none tracking-wide"
                          icon={faCaretDown}
                        />
                      </button>
                      {progressShowDropdown && selectedRow === row && (
                        <ul className="absolute left-0 mt-2 w-48 bg-white border border-gray-200 rounded-lg shadow-lg z-10">
                          <li
                            className="cursor-pointer font-medium px-4 py-2 bg-[#E5FFE5] text-[#49CC29]"
                            onClick={() => {
                              handleChangProgress("completed", row.original.id),
                                setProgressShowDropdown(false);
                            }}
                          >
                            {t("Completed")}
                          </li>
                          <li
                            className="cursor-pointer font-medium px-4 py-2 bg-[#F5DCDC] text-[#FF4B4B]"
                            onClick={() => {
                              handleChangProgress(
                                "not_required",
                                row.original.id
                              ),
                                setProgressShowDropdown(false);
                            }}
                          >
                            {t("Not Required")}
                          </li>
                          <li
                            className="cursor-pointer font-medium px-4 py-2 bg-[#FFF9D9] text-[#F2D230]"
                            onClick={() => {
                              handleChangProgress(
                                "in_progress",
                                row.original.id
                              ),
                                setProgressShowDropdown(false);
                            }}
                          >
                            {t("In Progress")}
                          </li>
                          <li
                            className="cursor-pointer font-medium px-4 py-2 bg-[#F5F5F5] text-[#CCCCCC]"
                            onClick={() => {
                              handleChangProgress(
                                "not_started",
                                row.original.id
                              ),
                                setProgressShowDropdown(false);
                            }}
                          >
                            {t("Not Started")}
                          </li>
                        </ul>
                      )}
                    </div>
                  ) : cell.column.id === "date" ? (
                    <input
                      value={row.original.target_date}
                      onChange={(e) =>
                        handleAddDate(e.target.value, row.original.id)
                      }
                      type="date"
                      className="text-[#BFBFBF] focus:outline-none !border-0"
                    />
                  ) : cell.column.id === "cost" ? (
                    <div className="text-[#BFBFBF]">
                      <div className="relative">
                        <input
                          defaultValue={
                            row.original.cost !== 0 ? row.original.cost : ""
                          }
                          onChange={(e) =>
                            handleChangeCost(e.target.value, row.original.id)
                          }
                          className="placeholder-[#BFBFBF] focus:outline-none text-center text-[#697077] pl-8" // Adjust styling as needed
                          placeholder={t("Write cost here")}
                        />
                      </div>
                    </div>
                  ) : cell.column.id === "notes" ? (
                    row.values.notes === "" ? (
                      <button
                        onClick={() => handleAddNotes(row.original.id)}
                        className="text-red"
                      >
                        + {t("Add notes")}{" "}
                      </button>
                    ) : (
                      <button
                        onClick={() =>
                          handleViewNotes(row.original.notes, row.original.id)
                        }
                        className="text-red underline"
                      >
                        {t("View notes")}
                      </button>
                    )
                  ) : cell.column.id === "name" ? (
                    <p
                      className={`${
                        row.values.state === "completed" && "line-through"
                      }`}
                    >
                      {cell.render("Cell")}
                    </p>
                  ) : (
                    cell.render("Cell")
                  )}
                </td>
              ))}
             {row.original.seq === 0 && <td className="px-2 text-center text-[#697077] text-sm font-normal leading-tight">
                <button onClick={()=> handleDeleteAction(row.original.id)} className="">
                  <img className="w-5 h-5" src={trashIcon} alt="trashIcon" />
                </button>
              </td>}
            </tr>
          );
        })}
      </tbody>
    </table>
    )
    }
    export default TableComponent;