import React from "react";
import invitationImg from "../../assets/images/invitation/invitation.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight,faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import mainImg from "../../assets/images/consaltant/Rectangle 134.png";
import object2 from "../../assets/images/consaltant/Group1.png";
import object1 from "../../assets/images/consaltant/Group2.png";
import {useTranslation} from "react-i18next";

function WeddingPlanPreview() {
  const { t, i18n } = useTranslation();
  const user = useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  return (
    <section className="bg-[#f2f2f2] px-5 xl:px-10 py-12">
      <div className="flex flex-col md:flex-row md:justify-between justify-center items-center md:px-10 px-7 gap-6">
        <div className="lg:w-[55%] md:w-[70%] xl:ms-16">
          <div className="mb-6">
            <p className="text-[68px] font-['Rochester'] font-normal">
              {t("Welcome")}
            </p>
            <p className="text-2xl font-semibold">{t("to Wedding Plan")}</p>
            <p className="text-base">{t("The Whole Wedding Planning Guide")}</p>
          </div>
          <div className="lg:text-lg text-sm">
            <p className="text-[17px] font-normal leading-[22px] mb-2">
            {t("Together with the love of your life, select the necessities for your nuptials. Is it a specific date, the wedding's theme, or the venue? Maybe a particular wedding ceremony? In terms of your money, give those the utmost importance.")}
            </p>
            <p className="text-[17px] font-normal leading-[22px] mb-2">
           {t(" Start early to get a list of everything you want done for your wedding and an estimated price for each item. Using this list to help you determine your estimated wedding day budget will be very helpful. Rose Bazaar helps you stay within your budget and focus on the things that are truly important. There can be a ton of ideas for a wedding. Avoid making an effort to multitask.")}
            </p>
            <p className="text-[17px] font-normal leading-[22px] mb-10">
            {t("For this reason, you should strive to spend as little time as possible reviewing concepts and tasks in a single session. Browse through our selection of wedding plans to decide what you want and don't want, likes and dislikes. Rose Bazaar can assist you in determining what motivates you to achieve more success.")}
            </p>
            <div>
              <button
                onClick={() =>
                  user === null
                    ? (toast.error("You must be logged in"), navigate("/login"))
                    : navigate("/wedding-plan")
                }
                className="self-stretch w-[80%] mx-auto md:w-[285px] h-12 p-4 bg-red rounded-lg justify-center items-center inline-flex gap-3"
              >
                <span className="text-white text-base font-medium leading-none tracking-wide">
                  {t("Create Wedding plan")}
                </span>
                {i18n.language === 'en' ? (
                    <FontAwesomeIcon className="text-white text-base font-medium" icon={faArrowRight} />
                ) : (<FontAwesomeIcon className="text-white text-base font-medium" icon={faArrowLeft} />)}
               
              </button>
            </div>
          </div>
        </div>
        <div className="md:w-4/12 w-full just ml-3">
          <div className="relative">
          <img src={mainImg} className='lg:w-8/12 md:w-11/12 w-7/12 mx-auto md:mx-4 md:mt-10 relative z-10'/>
          <img src={object1}  className={`w-14 md:w-28 absolute ${i18n.language === 'en' ? 'lg:left-[65%]' : 'lg:left-[89%]'} top-[30%]  left-[76%] z-0`} />
          <img  src={object2}  className={`w-14 md:w-36 absolute ${i18n.language === 'en' ? 'md:right-[83%]' : 'md:right-[60%]'} top-[73%]   right-[73%] z-0`}/> 
          </div>
        </div>
      </div>
    </section>
  );
}

export default WeddingPlanPreview;
