import React, {useEffect, useState} from 'react'
import {AiFillStar, AiOutlineHeart} from 'react-icons/ai';
import {useDispatch, useSelector} from "react-redux";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import details from "../../assets/images/shop/details.png";
import { FiShoppingCart } from "react-icons/fi";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import Loader from "../../components/Loader";
import Error from "../Error";
import {cartActions} from "../../store/slicer/cartSlice";
import {toast} from "react-toastify";
import useAxiosUserSecureConfig from "../../hooks/useAxiosUserSecureConfig";
import placholder_image from "../../assets/images/booking/placeholder-image.jpg"
import ConfirmationModal from '../../components/ConfirmationModal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { wishListActions } from '../../store/slicer/wishListSlice';
import {useTranslation} from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination } from "swiper/modules";
import { Navigation } from 'swiper/modules';
import Rating from "@mui/material/Rating";


function ProductDetails() {
  const user = useSelector(state => state.auth.user);
  const location = useLocation();
  const [variantId, setVariantId] = useState();
  const {t ,i18n } = useTranslation();

  useEffect(() => {
    const variantId = location.state?.data;
    setVariantId(variantId);
  }, [location.state]);
  console.log( "variantId",variantId)
  
  const navigate = useNavigate();
  const [open, setOpen] =useState(false);
  const handleClose = () => setOpen(false);
  const handleDeleteProduct = () => {

  axios.post(`/api/vendor/product/${params.id}/delete` ,{}, config)
      .then((res) => {
        if (!res.data.result?.success) {
          toast.error(res.data.result?.message || "Request failed!");
          return;
        }
        toast.success(t("You archive product successfully!"));
        navigate("/productSupplier/productList")
      })
      .catch((err) => {
        console.log("err" , err)
        toast.error(t("Error while dearchive product!"));
      })
  }
  const [colors, setColors] = useState({ loading: true, data: [] });
  useEffect(() => {
    axios
      .post(`/api/product/colors`, {})
      .then((res) => {
        console.log("colors", res.data);

        if (!res.data?.result?.success) {
          toast.error(t("Error while fetching colors!"));
          return;
        }

        setColors((prevState) => ({
          ...prevState,
          loading: false,
          data: res.data?.result?.colors ?? [],
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
 
  const [sizes, setSizes] = useState({ loading: true, data: [] });
  useEffect(() => {
    axios
      .post(`/api/product/sizes`, {})
      .then((res) => {
        console.log("sizes", res.data);

        if (!res.data?.result?.success) {
          toast.error(t("Error while fetching sizes!"));
          return;
        }

        setSizes((prevState) => ({
          ...prevState,
          loading: false,
          data: res.data?.result?.sizes ?? [],
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const cart = useSelector((state) => state.cart);
  const [product , setProduct] = useState({ loading: true, data: [] })
  const [error , setError] = useState(null)
  const params = useParams();
  const [quantity, setQuantity] = useState(1);
  const [productImage , setProductImage] = useState(null)
  const [variantsWithImages , setVariantsWithImages] = useState([])
  const [selectedVariant, setSelectedVariant] = useState({ loading: true, data: {} });

  const fetchProductData = () => {
    axios
    .post(`/api/product/${params.id}`, {}, config)
    .then(async (res) => {
      if (!res.data?.result?.success) {
        toast.error(t("Error while fetching product!"));
        return;
      }
      setProduct({
        loading: false,
        data: res.data?.result.product || [], 
      });
      const variantsWithImages = await Promise.all(
        res.data.result.product.variants.map(async (variant) => {
          try {
            const imageRes = await axios.post(`/api/product/variant/${variant.id}/image2`, {}, config);
            if (!imageRes.data.result?.success) {
              throw new Error(imageRes.data.result?.message || t("Request failed!"));
            }
            return { ...variant, image: imageRes.data.result.image };
          } catch (error) {
            console.error('Error fetching image:', error);
            return { ...variant, image: null };
          }
        })
      );
      const filteredVariants = variantsWithImages.filter(variant => variant.available !== false);
      setVariantsWithImages(filteredVariants);
      axios.post(`/api/product/${params.id}/image` , {} , config).then((res) => {
        console.log("image", res.data);
        if (!res.data?.result?.success) {
          setError("Something went wrong")
          return;
        }
        setProductImage(res.data?.result?.image)
      }).catch((err) => {
        console.log(err);
        })
    })
    .catch((err) => {
      console.log(err);
    });
  }

  useEffect(() => {
    fetchProductData()
  }, []);

  useEffect(() => {
    if (variantId && variantsWithImages.length > 0) {
      const selectedVariant = variantsWithImages.find(variant => variant.id === variantId);
      setSelectedVariant({
        loading: false,
        data: selectedVariant,
      });
    }
  }, [variantId, variantsWithImages]);
  
  console.log("product",product)
  console.log("selectedVariant",selectedVariant)
  console.log("variantsWithImages", variantsWithImages)
  const dispatch = useDispatch();
  const config = useAxiosUserSecureConfig();

  const handleAddToCart = async () => {
    if (user === null) {
      toast.error(t("You must be logged in"));
      navigate("/login");
    } else {
      dispatch(cartActions.setLoading(true));
      console.log(product.id);

      if (
        cart.items.findIndex((item) => item.product.id === product.id) === -1
      ) {
        await axios
          .post(
            "/api/add_to_cart",
            { product_id: selectedVariant.data.id },
            config
          )
          .catch((err) => {
            console.log(err);
            // dispatch(uiActions.stopLoading());
            dispatch(cartActions.setLoading(false));
            toast.error(t("Error While Updating Cart!"));
          });
      }

      axios
        .post(
          "/api/set_cart_item_qty",
          { product_id: product.id, qty: quantity },
          config
        )
        .then(() => {
          axios.post("/api/cart", {}, config).then((res) => {
            console.log(res.data.result);
            dispatch(cartActions.setCart(res.data.result));
            dispatch(cartActions.setLoading(false));
            // dispatch(uiActions.stopLoading());
          });

          toast.success(t("Cart Updated!"));
          fetchProductData()
        })
        .catch((err) => {
          console.log(err);
          // dispatch(uiActions.stopLoading());
          dispatch(cartActions.setLoading(false));
          toast.error(t("Error While Updating Cart!"));
        });
    }
  };
  const [productsWishList, setProductsWishList] = useState();
  const fetchWishList = () => {
    axios
      .post(`/api/wishlist/product`, {}, config)
      .then((res) => {
        setProductsWishList(res.data?.result.products);
        dispatch(wishListActions.setProductsWishList(res.data.result));
        dispatch(wishListActions.setLoading(false));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    fetchWishList();
  }, []);

  const handleAddToWishList = (e) => {
    e.stopPropagation();
    axios
      .post("/api/wishlist/product/add", { product_id: selectedVariant.data.id }, config)
      .then((res) => {
        console.log(res.data);
        if (!res.data.result?.success) {
          toast.error(res.data.result?.message || "Request failed!");
          fetchWishList();
          return;
        }
        toast.success(t("You add product to wishlist successfully!"));
        fetchWishList();
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(t("Error while adding product to wishlist!"));
        fetchWishList();
      });
  };
  const deleteFromWishList = (e) => {
    e.stopPropagation();
    axios
      .post(`/api/wishlist/product/${selectedVariant.data.id}/remove`, {}, config)
      .then((res) => {
        console.log(res.data);
        if (!res.data.result?.success) {
          toast.error(res.data.result?.message || "Request failed!");
          fetchWishList();
          return;
        }
        toast.success(t("You remove product from wishlist successfully!"));
        fetchWishList();
      })
      .catch((err) => {
        console.log("err", err);
        toast.error("Error while removing product to wishlist!");
        fetchWishList();
      });
  };
  console.log("productsWishList", productsWishList);
  const isProductInWishList = (productID) => {
    if (Array.isArray(productsWishList)) {
      return productsWishList.some((item) => item === productID);
    } else {
      return false;
    }
  };

  if (error) {
    return <Error msg={error} />;
  }

  if (!product) {
    return <Loader />;
  }

  return (
    <>
      <section className="relative">
        <ConfirmationModal
          open={open}
          handleClose={handleClose}
          handleAction={handleDeleteProduct}
          msg={t("Are you sure you want to archive this product!")}
        />
        <img className="w-full" src={details} alt="home" />
        <div className="flex flex-col sm:gap-4 absolute bottom-[20%] px-5 xl:px-10">
          <div className="text-[#D9D9D9] sm:text-xl font-normal hidden sm:inline-flex ">
            <Link to={"/shop"} className="px-1">
              {t("Store")}
            </Link>
            <FontAwesomeIcon
              className="text-zinc-400 text-base px-1 pt-2"
              icon={faAngleRight}
            />
            {t("Product Details")}
          </div>
          <h1 className="text-white text-2xl sm:text-[40px] font-medium font-['Roboto'] sm:leading-[44px]">
            {t("Product Details")}
          </h1>
        </div>
      </section>
      {selectedVariant.loading && <Loader slim />}
      {!selectedVariant.loading && (
        <section className="px-5 xl:px-10 py-12">
          <div className="lg:flex justify-center gap-6 pb-12 border-b">
            <div className="flex flex-col justify-center mb-4 lg:mb-0 gap-4 sm:w-[420px]">
              <div className="relative h-[320px]">
                <button
                  onClick={(e) => {
                    if (isProductInWishList(selectedVariant.data.id)) {
                      deleteFromWishList(e);
                    } else {
                      handleAddToWishList(e);
                    }
                  }}
                  className="absolute top-6 left-8 text-zinc bg-red rounded-full p-1 bg-opacity-30"
                >
                  <FaHeart
                    size={24}
                    className={`${
                      isProductInWishList(selectedVariant.data.id)
                        ? "text-red"
                        : "text-white"
                    }`}
                  />
                </button>
                <div className="sm:w-[420px] h-full flex justify-center items-center">
                  <img
                    className="w-full h-full rounded-lg object-cover border"
                    src={selectedVariant.data.image}
                    alt=""
                  />
                </div>
              </div>
              <div className="flex flex-wrap gap-1 w-full">
                {variantsWithImages.map((variant2, index) => (
                  <img
                    onClick={() => {
                      const selectedVariantByImage = variantsWithImages.find(
                        (variant) => variant.id === variant2.id
                      );
                      setSelectedVariant((prevState) => ({
                        ...prevState,
                        loading: false,
                        data: selectedVariantByImage,
                      }));
                    }}
                    key={index}
                    src={variant2.image}
                    alt="variantImage"
                    className={`cursor-pointer h-40 w-[49%]  md:w-[24%] md:h-24 rounded-lg object-cover border ${
                      selectedVariant.data.id === variant2.id && "border-red"
                    }`}
                  />
                ))}
              </div>
              <div className="flex gap-2">
                <button
                  disabled={selectedVariant.data.quantity === 0}
                  onClick={handleAddToCart}
                  className={`w-full md:px-8  px-4 py-3 rounded-[10px] shadow bg-red justify-center items-center gap-2.5 inline-flex ${
                    selectedVariant.data.quantity === 0 ? "opacity-50" : ""
                  }`}
                >
                  <span className="text-center text-nowrap text-white text-sm font-normal leading-tight flex items-center gap-3">
                    {t("Add to Cart")} <FiShoppingCart size={"22px"} />
                  </span>
                </button>
                <button
                  disabled={selectedVariant.data.quantity === 0}
                  onClick={() => {
                    handleAddToCart();
                    if (user !== null) {
                      navigate("/cart");
                    }
                  }}
                  className={`md:px-8 px-4 py-3 rounded-[10px] shadow bg-white border border-red justify-center items-center gap-2.5 inline-flex ${
                    selectedVariant.data.quantity === 0 ? "opacity-50" : ""
                  }`}
                >
                  <span className="whitespace-nowrap text-center text-red text-sm font-medium leading-tight flex items-center gap-3">
                    {t("Buy Now")}
                  </span>
                </button>
              </div>
            </div>

            <div className="flex flex-col gap-4 grow">
              <div className="flex justify-between items-center">
                <p className="text-gray-dark text-2xl md:text-[32px] font-medium uppercase">
                  {product.data.name}
                </p>
              </div>

              <div className="flex-col justify-start items-start gap-3 inline-flex">
                <div className="flex items-center gap-3">
                  {selectedVariant.data.condition === "used" ? (
                    <div className="w-16 px-3 py-0.5 bg-[#CCCCCC] rounded-[22px] justify-start items-start gap-[10px] inline-flex">
                      <div className="text-[#4C4C4C] text-base font-normal font-['Roboto'] leading-relaxed">
                        {t("Used")}
                      </div>
                    </div>
                  ) : (
                    <div className="w-14 px-3 py-0.5 bg-red rounded-[22px] justify-start items-start gap-[10px] inline-flex">
                      <div className="text-white text-base font-normal font-['Roboto'] leading-relaxed">
                        {t("New")}
                      </div>
                    </div>
                  )}
                  {selectedVariant.data.quantity === 0 && (
                    <div className="px-3 py-0.5 bg-zinc-400 rounded-[22px] justify-start items-start gap-[10px] inline-flex">
                      <div className="text-white text-base font-normal font-['Roboto'] leading-relaxed">
                        {t("Sold Out")}
                      </div>
                    </div>
                  )}
                </div>
                <div>
                  <span className="text-[#666666] text-lg font-normal">
                    {t("Brand")}:{" "}
                  </span>
                  <span className="text-[#666666] text-lg font-light">
                    {product.data.brand || "None"}{" "}
                  </span>
                </div>
                <div>
                  <span className="text-[#666666] text-lg font-normal">
                    {t("Model")}:
                  </span>
                  <span className="text-[#666666] text-lg font-light">
                    {" "}
                    {selectedVariant.data.name2}
                  </span>
                </div>
                <div>
                  <span className="text-[#666666] text-lg font-normal">
                    {t("Quantity Available")}:{" "}
                  </span>
                  <span className="text-[#666666] text-lg font-light">
                    {selectedVariant.data.quantity}{" "}
                  </span>
                </div>
                <div>
                  <span className="text-[#666666] text-lg font-normal">
                    {t("Supplier name")}:
                  </span>
                  <span className="text-[#666666] text-lg font-light">
                    {" "}
                    {product.data.vendor.name}
                  </span>
                </div>
              </div>

              <div
                dangerouslySetInnerHTML={{ __html: product.data.description }}
              />

              <div className="place-items-center w-full md:w-[75%] items-center grid grid-cols-6 sm:grid-cols-8 md:grid-cols-12 gap-[5px]">
                {[
                  ...new Set(
                    variantsWithImages.map((variant) => variant.color)
                  ),
                ].map((color, index) => (
                  <button
                    key={index}
                    className={`${
                      selectedVariant.data.color === color &&
                      "border border-red "
                    } p-1 rounded-full`}
                    onClick={() => {
                      const selectedVariantByColor = variantsWithImages.find(
                        (variant) => variant.color === color
                      );
                      setSelectedVariant((prevState) => ({
                        ...prevState,
                        loading: false,
                        data: selectedVariantByColor,
                      }));
                    }}
                  >
                    <div
                      className={`rounded-full w-6 h-6 focus:outline-none`}
                      style={{
                        backgroundColor: `${color.toLowerCase()}`,
                      }}
                    ></div>
                  </button>
                ))}
              </div>

              <div className="flex flex-col gap-3 pb-4 border-b ">
                <div className="flex gap-3">
                  {[
                    ...new Set(
                      variantsWithImages
                        .filter(
                          (variant) =>
                            variant.color === selectedVariant.data.color
                        )
                        .map((variant) => variant.size)
                    ),
                  ].map((size, index) => (
                    <button
                      key={index}
                      className={`rounded-lg border border-zinc-400 ${
                        selectedVariant.data.size === size && "!border-red"
                      } py-2 px-3 xl:px-6`}
                      onClick={() => {
                        const selectedVariantBySize = variantsWithImages.find(
                          (variant) =>
                            variant.size === size &&
                            variant.color === selectedVariant.data.color
                        );
                        setSelectedVariant((prevState) => ({
                          ...prevState,
                          loading: false,
                          data: selectedVariantBySize,
                        }));
                      }}
                    >
                      <div
                        className={`text-[#666666] ${
                          selectedVariant.data.size === size && "text-red"
                        } text-lg font-normal font-['Roboto']`}
                      >
                        {size}
                      </div>
                    </button>
                  ))}
                </div>
              </div>

              <div className="flex flex-col gap-5 items-start">
                <div className='flex gap-5' >
                  <div className="text-[#666666] text-lg font-normal">
                    {t("Price")} :{" "}
                  </div>
                <div className={` flex ${i18n.language === 'en' ? 'flex-row' : 'flex-row-reverse'} `}>
                  <div className="text-red text-2xl md:text-[32px] font-semibold uppercase">
                    {selectedVariant.data.price}
                  </div>{" "}
                  <div className="text-red text-xl font-light uppercase">
                    EGP
                  </div>
                 </div>
              </div>
              </div>
            </div>
          </div>
          <div className="py-12">
            <div className="flex gap-6 items-center mb-6">
              <div>
                <p className="px-4 py-2 bg-white border-b border-zinc-500 justify-center items-center gap-2 inline-flex">
                  <span className="text-zinc-500 text-base font-normal  leading-normal">
                    {t("Reviews")}
                  </span>
                  <span className="text-neutral-400 text-sm font-normal  leading-[21px]">
                    ({product.data.reviews.length})
                  </span>
                </p>
              </div>
              <div className="flex gap-1 items-center">
                <AiFillStar className="text-[16px] text-[#FFC200]" />
                <p className="text-gray-dark text-base font-medium leading-normal">
                  {product.data.rating}
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-6">
              {product.data.reviews.map((review) => (
                  <div>
                    <div className="p-6 border rounded-2xl">
                      <div className="justify-start items-center gap-4 inline-flex pb-4 border-b w-full">
                        <p className="text-gray-900 text-base font-normal  leading-normal">
                          {review.user.name}
                        </p>
                      </div>
                      <div className="flex gap-1 py-4">
                        <Rating
                          name="quality"
                          value={review.rating}
                          precision={0.5}
                          readOnly
                        />
                      </div>
                      <p className="text-gray-900 text-sm font-normal leading-[21px]">
                        {review.review}
                      </p>
                    </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default ProductDetails
