import React, {useEffect, useState} from 'react'
import {AiFillStar, AiOutlineHeart} from 'react-icons/ai';
import {useDispatch, useSelector} from "react-redux";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import details from "../../../assets/images/shop/details.png";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import {faCircle} from "@fortawesome/free-solid-svg-icons";
import { FiShoppingCart } from "react-icons/fi";
import {Link, useParams , useNavigate } from "react-router-dom";
import axios from "axios";
// import {toast} from "react-toastify";
import Loader from "../../../components/Loader";
import Error from "../../Error";
import {cartActions} from "../../../store/slicer/cartSlice";
import {toast} from "react-toastify";
import useAxiosUserSecureConfig from "../../../hooks/useAxiosUserSecureConfig";
import img from "../../../assets/images/sevices/download.png";
import serv from "../../../assets/images/sevices/serv.png";
import icon from "../../../assets/images/sevices/icon.png";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Modal } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import Select from 'react-select';
import { faFacebookF, faYoutube, faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons';
import {SlideshowLightbox} from 'lightbox.js-react'
import 'lightbox.js-react/dist/index.css'
import placholder_image from "../../../assets/images/booking/placeholder-image.jpg"
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FaEdit } from "react-icons/fa";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from 'swiper/modules';
import {useTranslation} from "react-i18next";

function ProfileDataService() {
  const {t, i18n } = useTranslation();
  const user = useSelector(state => state.auth.user)


  const [open, setOpen] =useState(false);
  const handleClose = () => setOpen(false); 
  const {
    register,
    watch,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm();
  const [showFullDescription , setShowFullDescripton] = useState(false);
  const [fullDescription , setFullDescription] = useState();

  const toggleDescription = (e , fullDescription) => {
    e.stopPropagation()
    setShowFullDescripton(!showFullDescription);
    setFullDescription(fullDescription)
  }
  const dommyImgs = [placholder_image , placholder_image, placholder_image,placholder_image , placholder_image, placholder_image]
  const [images , setImages] = useState({loading :true , data : []})
  useEffect(()=> {
    setImages({
      loading : true,
      data : dommyImgs.map((img) => ({src : img , alt :"img"})),
    })
    
  },[])
  const navigate = useNavigate();
  const cart = useSelector((state) => state.cart);
  const [product , setProduct] = useState(null)
  const [error , setError] = useState(null)
  const params = useParams();
  const [quantity, setQuantity] = useState(1);
  const [activeTap, setActiveTap] = useState(1);
  const [imageUrls, setImageUrls] = useState({loading : true , data :[]});
  const Dayoptions =[
    {label:t("SUN"), value:"1"},
    {label:t("MON"), value:"2"},
    {label:t("TUE"), value:"3"},
    {label:t("WED"), value:"4"},
    {label:t("THU"), value:"5"},
    {label:t("FRI"), value:'6'},
    {label:t("SAT"), value:"7"},
    ];

    
  const dispatch = useDispatch();
  const config = useAxiosUserSecureConfig();


  const servicePlaces =[
    { value: "wedding_location", label: t("Wedding Location") },
    { value: "working_address",  label: t("At my working Address") },
    {value: "any", label: t("Wedding Location and Working Address")},
   ];

  const customStyles = {
    control: (styles) => ({
      ...styles,
      background: "#F2F4F8", "border-color": "#F2F4F8" , "border-radius" :"8px" ,padding: "6px" })
    }
    const [vendor, setVendor]=useState({ loading: true, data: [] })
    const [imageBase64, setImageBase64] = useState(null);
    const [categoryNames ,setCategoryNames] = useState();
    useEffect(() => {
        axios
          .post(`/api/vendor/${user.partnerId}`,{}, config)
          .then((res) => {
            console.log("profile data", res.data);
    
            if (!res.data?.result?.success) {
              toast.error("Error while fetching Profile data!");
            }
            setImageBase64(res.data?.result?.vendor.image);
            setVendor((prevState) => ({
              ...prevState,
              loading: false,
              data: res.data?.result?.vendor || [], 
            }));
            setCategoryNames(res.data?.result?.vendor.service_categories.map(category => category.name).join(', '))
            const displayImageIds = res.data?.result?.vendor.display_image_ids || [];
              const imagePromises = displayImageIds.map(id =>
                axios.post(`/api/vendor-image/${id}`, {}, config)
                  .then(response => ({
                    src: response.data?.result?.image,
                    alt: `Image ${id}`
                  }))
              );
              Promise.all(imagePromises)
                .then(images => {
                  setImageUrls((prevState) => ({
                    ...prevState,
                    loading: false,
                    data: images,
                  }));
                })
                .catch(error => {
                  console.error('Error fetching images:', error);
                });
          })
          .catch((err) => {
            console.log(err);
          });
      }, []);
  
      const availableDays = Object.keys(vendor.data)
      .filter((key) => key.startsWith('available_') && vendor.data[key] === true)
      .map((key) => key.split('_')[1])
      .join(', ');

      const getCategoryImg = async (id) => {
        try {
          const response = await axios.post(`/api/product-category/${id}`, {});
          if (!response.data?.result?.success) {
            toast.error("Error while fetching category!");
            return null; // or handle error as needed
          }
          return response.data?.result?.category?.image;
        } catch (error) {
          console.error("Error while fetching category:", error);
          toast.error("Error while fetching category!");
          return null; // or handle error as needed
        }
      };
      const [imgUrls, setImgUrls] = useState([]);
      useEffect(() => {
        const fetchData = async () => {
          if (!vendor.data.services) return; // Guard against undefined or empty array
          const urls = [];
          for (const serv of vendor.data.services) {
            const imgUrl = await getCategoryImg(serv.category.id);
            urls.push(imgUrl);
          }
          setImgUrls(urls);
        };
      
        fetchData();
      }, [vendor.data.services]);

      const isMobile = window.innerWidth <= 375; //  for mobile 
      const isTablet =window.innerWidth  <=425;
      const isLaptop =window.innerWidth  <1024;
  return (
    <>
      <section className="relative">
        {showFullDescription && (
          <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-10 bg-gray-800 bg-opacity-50">
            <div className="bg-white p-8 rounded-2xl w-1/2 border-4 border-red">
              <p>{fullDescription}</p>
              <div className="flex justify-center">
                <button
                  className="h-[42px] px-[18px] py-2.5 rounded-[10px] shadow border border-red justify-center items-center gap-2.5 inline-flex mt-4"
                  onClick={toggleDescription}
                >
                  <span className="text-center text-red text-base font-normal  leading-tight">
                    {" "}
                    {t("Close")}
                  </span>
                </button>
              </div>
            </div>
          </div>
        )}
      </section>
      <section className="px-5 xl:px-14 py-4">
        {vendor.loading && <Loader slim />}
        {!vendor.loading && (
          <>
            <div className="justify-start items-center gap-2  hidden sm:inline-flex mb-2">
              <div
                onClick={() => navigate("/serviceProvider/account")}
                className="text-zinc-400 text-base font-normal leading-[48px] hover:text-gray-dark cursor-pointer"
              >
                {t("Service Provider Account")}
              </div>
              <FontAwesomeIcon
                className="text-zinc-400 text-base"
                icon={faAngleRight}
              />
              <div
                onClick={() => navigate("/profileDataService")}
                className="text-zinc-400 text-base font-normal leading-[48px] hover:text-gray-dark cursor-pointer"
              >
                {t("Profile Data")}
              </div>
            </div>
            <div className="flex justify-between items-start ">
              <h1 className="my-3 font-bold text-3xl text-red">{t("Profile Data")}</h1>
              <Link
                to={"/ServiceProvider/profile-data"}
                className="md:px-8 px-5 py-[9px]  rounded-[10px] shadow border border-red justify-center items-center gap-2.5 flex"
              >
                <span className="text-center text-red text-base font-normal font-['Inter'] leading-tight">
                  {t("Edit Profile")}
                </span>
                <FaEdit className="text-red font-normal" />
              </Link>
            </div>

            <div className="lg:flex justify-center gap-6  border-b mb-4 ">
              <div className="flex justify-center mb-4 lg:mb-0">
                <div className="relative mb-6">
                  <div className="sm:w-[340px] flex justify-center items-center mb-4">
                    <img
                      className="h-[250px] w-full object-cover rounded-lg"
                      src={vendor.data.image || placholder_image}
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-col gap-4 grow">
                <div className="flex justify-between items-center">
                  <p className="text-gray-dark text-2xl md:text-[28px] font-medium uppercase">
                    {vendor.data.brand_name}
                  </p>
                </div>
                <div className="md:flex justify-between">
                  <div className="flex-col justify-start items-start gap-3 inline-flex w-1/2">
                    <div>
                      <span className="text-[#666666] text-base font-semibold">
                        {t("Category")}:{""}
                      </span>{" "}
                      <span className="text-[#666666] text-base font-light">
                        {" "}
                        {categoryNames}
                      </span>
                    </div>
                    <div>
                      <span className="text-[#666666] text-base font-semibold">
                        {t("Available Days")}:
                      </span>{" "}
                      <span className="text-[#666666] text-base font-light">
                        {" "}
                        {availableDays || "No available days"}
                      </span>
                    </div>
                    <div>
                      <span className="text-[#666666] text-base font-semibold">
                        {t("Vendor address")}:
                      </span>{" "}
                      <span className="text-[#666666] text-base font-light">
                        {" "}
                        {vendor.data.address}
                      </span>
                    </div>
                    <div>
                      <span className="text-[#666666] text-base font-semibold">
                        {t("Vendor website")}:
                      </span>{" "}
                      <span className="text-[#666666] text-base font-light">
                        {" "}
                        {vendor.data.website}
                      </span>
                    </div>
                   
                    <div className="flex items-center justify-center space-x-3">
                      {vendor.data.facebook_link && (
                        <Link
                          target="_blank"
                          to={vendor.data.facebook_link}
                          className="flex items-center justify-center w-7 h-7 rounded-full bg-blue-500 text-white hover:bg-blue-600 transition-colors duration-300"
                        >
                          <FontAwesomeIcon
                            icon={faFacebookF}
                            className="text-sm"
                          />
                        </Link>
                      )}
                      {vendor.data.youtube_link && (
                        <Link
                          target="_blank"
                          to={vendor.data.youtube_link}
                          className="flex items-center justify-center w-7 h-7 rounded-full bg-red-500 text-white hover:bg-red-600 transition-colors duration-300"
                        >
                          <FontAwesomeIcon
                            icon={faYoutube}
                            className="text-sm"
                          />
                        </Link>
                      )}
                      {vendor.data.instagram_link && (
                        <Link
                          target="_blank"
                          to={vendor.data.instagram_link}
                          className="flex items-center justify-center w-7 h-7 rounded-full bg-pink-500 text-white hover:bg-pink-600 transition-colors duration-300"
                        >
                          <FontAwesomeIcon
                            icon={faInstagram}
                            className="text-sm"
                          />
                        </Link>
                      )}
                      {vendor.data.tiktok_link && (
                        <Link
                          target="_blank"
                          to={vendor.data.tiktok_link}
                          className="flex items-center justify-center w-7 h-7 rounded-full bg-black text-white hover:bg-black transition-colors duration-300"
                        >
                          <FontAwesomeIcon
                            icon={faTiktok}
                            className="text-sm"
                          />
                        </Link>
                      )}
                      {vendor.data.x_link && (
                        <Link
                          target="_blank"
                          to={vendor.data.x_link}
                          className="flex items-center justify-center w-7 h-7 text-sm rounded-full bg-gray-500 text-white hover:bg-gray-600 transition-colors duration-300"
                        >
                          X
                        </Link>
                      )}
                    </div>
                  </div>
                  <div className="w-1/2">
                    <span className="text-[#666666] text-base font-semibold mb-3">
                     {t("About")}:
                    </span>
                    <div className="description text-[#666666] text-base font-light">
                      <p className={`overflow-hidden line-clamp-2`}>
                        {vendor.data.about}
                      </p>
                      <div className="flex items-center justify-between">
                        {!showFullDescription && (
                          <button
                            className="text-red text-end pt-4 text-sm font-semibold"
                            onClick={(e) =>
                              toggleDescription(e, vendor.data.about)
                            }
                          >
                            {t("Read More")}
                          </button>
                        )}
                      </div>
                    </div>
                    <div>
                      <span className="text-[#666666] text-base font-semibold">
                        {t("Service Location")}:
                      </span>{" "}
                      <span className="text-[#666666] text-base font-light">
                        {" "}
                        {servicePlaces.find(
                          (place) =>
                            place.value === vendor.data.service_location
                        )?.label || ""}
                      </span>
                    </div>
                  </div>
                  
                </div>
                
                {imageUrls.loading && <Loader slim />}
                {!imageUrls.loading && (
                  <div className="mx-auto ">
                    <SlideshowLightbox
                      images={imageUrls.data}
                      className="slideshowLightbox container grid grid-cols-2 md:grid-cols-6 gap-2 mx-auto venue-gallery mb-3"
                      showThumbnails={true}
                    ></SlideshowLightbox>
                  </div>
                )}
              
              </div>
                </div>

            <div>
              <div className="tabs flex  justify-between items-start gap-6 pb-4 mb-2">
                <button
                  className={`${
                    activeTap === 1 && "!text-red !font-medium"
                  } text-zinc-500 text-base font-normal  leading-normal `}
                  key={1}
                  disabled={activeTap === 1}
                  onClick={() => setActiveTap(1)}
                >
                  {t("Services")}
                </button>
                <Link
                  to={"/ServiceProvider/services-list"}
                  className="px-8 py-[9px] rounded-[10px] shadow border border-red justify-center items-center gap-2.5 flex"
                >
                  <span className="text-center text-red text-base font-normal font-['Inter'] leading-tight">
                    {t("Edit Service")}
                  </span>
                  <FaEdit className="text-red font-normal" />
                </Link>
              </div>
              <div className="my-slider">
                {activeTap === 1 && (
                  <Swiper
                    spaceBetween={20}
                    slidesPerView={
                      isMobile ? 1 : isTablet ? 2 : isLaptop ? 3 : 5
                    }
                    navigation={true}
                    modules={[Navigation]}
                  >
                    <div className="flex items-center gap-6">
                      {vendor.data.services.map((serv, index) => (
                        <SwiperSlide key={serv.id}>
                          <Controller
                            control={control}
                            name={`serv_${serv.id}`}
                            render={({ field: { onChange, value } }) => (
                              <div
                                className="p-3 w-48 rounded-xl border flex gap-3 items-center"
                                key={serv.id}
                              >
                                <div className="flex-col justify-start items-start gap-2.5 inline-flex">
                                  <div className="justify-start items-center gap-4 inline-flex">
                                    <div className="w-7 h-7 px-px py-[5px] justify-center items-center flex">
                                      <img src={imgUrls[index]} alt="Icon" />
                                    </div>
                                    <div className="text-red text-base font-normal">
                                      {serv.name}
                                    </div>
                                  </div>
                                  <div className="text-zinc-800 text-base font-normal">
                                    {serv.list_price} EGP
                                  </div>
                                </div>
                              </div>
                            )}
                          />
                        </SwiperSlide>
                      ))}
                    </div>
                  </Swiper>
                )}
              </div>
            </div>
          </>
        )}
      </section>
    </>
  );
}

export default ProfileDataService
