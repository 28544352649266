import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import Loader from "../../components/Loader";
import useAxiosUserSecureConfig from "../../hooks/useAxiosUserSecureConfig";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation } from 'swiper/modules';
import placholder_image from "../../assets/images/booking/placeholder-image.jpg"
import { FaEdit } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import ConfirmationModal from "../../components/ConfirmationModal";
import { Swiper, SwiperSlide } from "swiper/react";
import {useTranslation} from "react-i18next";

function ViewVenue() {
  const { t, i18n } = useTranslation();
  const {
    register,
    watch,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm();
  const [facility, setFacility] = useState({
    loading: true,
    data: {},
  });
  const navigate = useNavigate();
 
 
  const params = useParams();
  const [venue, setVenue] = useState({
    loading: true,
    data: {},
  });
  const [imageBase64, setImageBase64] = useState(null);
  useEffect(() => { 
    axios
      .post(`/api/facility/${params.id1}`, { id: params.id1 })
      .then((res) => {
        console.log("facility", res.data);

        if (!res.data?.result?.success) {
          toast.error("Error while fetching facility!");
        }
        setImageBase64(res.data?.result?.image);
        setFacility({
          loading: false,
          data: res.data?.result || {}, // Set to an empty object if undefined
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [params.id1]);

  const user = useSelector(state => state.auth.user)
  const [vendor, setVendor]=useState({ loading: true, data: [] })
 useEffect(() => {
        axios
          .post(`/api/vendor/${user.partnerId}`,{}, config)
          .then((res) => {
            console.log("profile data", res.data);
    
            if (!res.data?.result?.success) {
              toast.error("Error while fetching Profile data!");
            }
            setImageBase64(res.data?.result?.vendor.image);
            setVendor((prevState) => ({
              ...prevState,
              loading: false,
              data: res.data?.result?.vendor || [], 
            }));
           
          })
          .catch((err) => {
            console.log(err);
          });
      }, []);

  const getCategoryImg = async (id) => {
    try {
      const response = await axios.post(`/api/product-category/${id}`, {});
      if (!response.data?.result?.success) {
        toast.error("Error while fetching category!");
        return null; // or handle error as needed
      }
      return response.data?.result?.category?.image;
    } catch (error) {
      console.error("Error while fetching category:", error);
      toast.error("Error while fetching category!");
      return null; // or handle error as needed
    }
  };
  const [imgUrls, setImgUrls] = useState([]);
      useEffect(() => {
        const fetchData = async () => {
          if (!vendor.data.services) return; // Guard against undefined or empty array
          const urls = [];
          for (const serv of vendor.data.services) {
            const imgUrl = await getCategoryImg(serv.category.id);
            urls.push(imgUrl);
          }
          setImgUrls(urls);
        };
      
        fetchData();
      }, [vendor.data.services]);

    const [imageUrls, setImageUrls] = useState({loading : true , data : null});
  useEffect(() => {
    axios
      .post(`/api/venue-vendor/venue/${params.id2}`, { id: params.id2 })
      .then((res) => {
        console.log("venue", res.data);

        if (!res.data?.result?.success) {
          toast.error("Error while fetching venue!");
        }
        setImageBase64(res.data?.result?.image);
        setVenue({
          loading: false,
          data: res.data?.result || {}, // Set to an empty object if undefined
        });
        const displayImageIds = res.data?.result?.extraImages || [];
        const imagePromises = displayImageIds.map((id) =>
          axios
            .post(`/api/vendor/venue/${params.id2}/image/${id}`, {}, config)
            .then((response) => {
              console.log("res", response);
              return {
                src: response.data?.result?.image,
                id: id,
                alt: `Image ${id}`,
              };
            })
        );
        Promise.all(imagePromises).then((images) => {
          setImageUrls((prevState) => ({
            ...prevState,
            loading: false,
            data: images,
          }));
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [params.id2]);

  const [includedServicesImages, setIncludedServicesImages] = useState([]);
  const [optionalServicesImages, setOptionalServicesImages] = useState([]);

  useEffect(() => {
    const fetchServicesImages = async () => {
      try {
        const includedServicesImages = await Promise.all(
          venue.data.included_services.map((service) =>
            axios.post(`/api/product-category/${service.category.id}`, {}, config)
          )
        );
  
        const optionalServicesImages = await Promise.all(
          venue.data.optional_services.map((service) =>
            axios.post(`/api/product-category/${service.category.id}`, {}, config)
          )
        );
  
        setIncludedServicesImages(includedServicesImages);
        setOptionalServicesImages(optionalServicesImages);
      } catch (error) {
        console.error("Error fetching services images:", error);
      }
    };
  
    if (venue.data?.included_services?.length > 0 && venue.data?.optional_services?.length > 0) {
      fetchServicesImages();
    }
  }, [venue]);

  const isMobile = window.innerWidth <= 375;

  const customStyles = {
    control: (styles) => ({
      ...styles,
      background: "#F2F4F8",
      "border-color": "#F2F4F8",
      "border-radius": "8px",
      padding: "6px",
    }),
  };
  const config = useAxiosUserSecureConfig(); 
  const [open, setOpen] =useState(false);
  const handleClose = () => setOpen(false); 
  const onSubmit = (data) => {
    setOpen(true)
    console.log("data" , data)
  };
  const handleDeleteVenue = (data) => {
     data = {
      venue_id: venue.data.id
  };

  axios.post('/api/venue-vendor/venue/delete' ,data, config)
      .then((res) => {
        if (!res.data.result?.success) {
          console.log('error:',res.data)
          toast.error(res.data.result?.message || t("Venue has upcoming bookings"));
          return;
        }
        toast.success(t("You delete Venue successfully!"));
        navigate(`/vendor/Facilities/${facility.data.id}`)
        
      })
      .catch((err) => {
        console.log("err" , err)
        toast.error(t("Error while deleting Venue!"));
      })
  }



  return (
    <section className="px-5 xl:px-20 py-10">
        <ConfirmationModal
      open={open}
      handleClose={handleClose}
      handleAction={handleDeleteVenue}
      msg={t("Are you sure you want to delete this Venue?")}
    />
      {venue.loading && <Loader slim />}
      {!venue.loading && (
        
        <>
        <form onSubmit={handleSubmit(onSubmit)}>
         <div   className="flex items-center justify-between flex-wrap md:flex-nowrap mb-10">
           <div className="justify-start items-center gap-2  hidden sm:inline-flex mb-5">
              <div
                onClick={() => navigate("/vendor/account")}
                className="text-zinc-400 text-xs md:text-base font-normal md:leading-[48px] hover:text-gray-dark cursor-pointer"
              >
                {t("My Account")}
              </div>
              <FontAwesomeIcon
                className="text-zinc-400 text-xs md:text-base"
                icon={faAngleRight}
              />
              <div
                onClick={() => navigate("/vendor/Facilities")}
                className="text-zinc-400 text-xs md:text-base font-normal md:leading-[48px] hover:text-gray-dark cursor-pointer"
              >
                {t("My Facilities")}
              </div>
              <FontAwesomeIcon
                className="text-zinc-400 text-xs md:text-base"
                icon={faAngleRight}
              />
              <div  onClick={() => navigate(`/vendor/Facilities/${facility.data.id}`)} className="text-zinc-400 text-xs md:text-base font-normal md:leading-[48px] hover:text-gray-dark cursor-pointer">
                {facility.data.name}
              </div>
              <FontAwesomeIcon
                className="text-zinc-400 text-xs md:text-base"
                icon={faAngleRight}
              />
              <div className="text-zinc-400 text-xs md:text-base font-normal md:leading-[48px] hover:text-gray-dark cursor-pointer">
                {venue.data.name}
              </div>
             </div>

              <div className="flex gap-4">
              <Link
                to={`/vendor/edit-venues/${facility.data.id}/${venue.data.id}`}
                className="px-8 py-[9px] rounded-[10px] shadow border border-red justify-center items-center gap-2.5 flex"
              >
                <span className="text-center text-red text-base font-normal font-['Inter'] leading-tight">
                {t("Edit Venue")}
                </span>
                <FaEdit className="text-red font-normal"/>
              </Link>
              <button type="submit"
                className="px-8 py-[9px] rounded-[10px] shadow border border-red justify-center items-center gap-2.5 flex"
              >
                <span className="text-center text-red text-base font-normal font-['Inter'] leading-tight">
                {t("Delete Venue")}
                </span>
                <RiDeleteBin6Line className="text-red text-lg font-normal"/>
              </button>
              </div>
            </div>

            <div  className="flex gap-20 flex-wrap md:flex-nowrap border-b pb-6 mb-6">
              <div className="flex w-full md:w-1/3  flex-col gap-4 items-center">
          
                <img
                  className="w-full h-[260px] object-cover rounded-lg"
                  src={venue.data.image}
                  alt=""
                />
               {imageUrls.loading && <Loader slim/>}
              {!imageUrls.loading && <div className="w-full grid grid-cols-2 gap-2">
                {imageUrls.data.map((image)=>(
                  <img
                  className="w-full h-[160px] object-cover rounded-lg"
                  src={image.src}
                  alt=""
                />
                ))
               }
               </div>}
              </div>
           
            <div className="grow w-2/3">
            <div className="flex justify-between items-center mb-10">
              <p className="text-red text-2xl font-medium ">{venue.data.name}</p>
             
            </div>
              <div className="">
                <div className="flex flex-col gap-10">
                  <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-x-6 gap-y-4">
                    <div className="flex flex-col justify-start items-start gap-2 ">
                      <label className="text-zinc-800 text-base font-normal leading-snug">
                        {t("Venue name")}
                      </label>
                      <input
                        type="text"
                        value={venue.data.name}
                        readOnly
                        className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                      />
                    </div>

                    <div className="flex flex-col justify-start items-start gap-2 ">
                      <label className="text-zinc-800 text-base font-normal leading-snug">
                        {t("Basic Price")}
                      </label>
                      <input
                        type="text"
                        value={venue.data.minPrice}
                        readOnly
                        className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                      />
                    </div>
                    <div className="flex flex-col justify-start items-start gap-2 ">
                      <label className="text-zinc-800 text-base font-normal leading-snug">
                        {t("Available Days")}
                      </label>
                      <input
                        type="text"
                        value={venue.data.weekAvailability}
                        readOnly
                        className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                      />
                    </div>

                    <div className="flex flex-col justify-start items-start gap-2 ">
                      <label className="text-zinc-800 text-base font-normal leading-snug">
                        {t("Number of Guest")}
                      </label>
                      <input
                        type="text"
                        value={venue.data.size.name}
                        readOnly
                        className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                      />
                    </div>

                    <div className="flex flex-col justify-start items-start gap-2 ">
                      <label className="text-zinc-800 text-base font-normal leading-snug">
                        {t("Indoor/Outdoor")}
                      </label>
                      <input
                        type="text"
                        value={venue.data.indoorOutdoor}
                        readOnly
                        className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                      />
                    </div>

                    <div className="flex flex-col justify-start items-start gap-2 ">
                      <label className="text-zinc-800 text-base font-normal leading-snug">
                        {t("Description")}
                      </label>
                      <input
                        type="text"
                        value={venue.data.description}
                        readOnly
                        className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                      />
                    </div>

                    <div className="flex flex-col justify-start items-start gap-2 ">
                      <label className="text-zinc-800 text-base font-normal leading-snug">
                        {t("Included Services")}
                      </label>
                      <Swiper
                          spaceBetween={10} 
                          slidesPerView={isMobile ? 1 : 2} 
                          navigation={true}
                          modules={[Navigation]}  
                        >
                      <div className="w-full grid grid-cols-1 md:grid-cols-2 items-center  gap-6">
                        {venue.data.included_services.map(
                          (serv , index) => (
                            <SwiperSlide key={serv.id}>
                              <div className="p-4 rounded-xl w-[98%] border flex-col justify-start items-start gap-2.5 inline-flex">
                                <div className="justify-start items-center gap-4 inline-flex">
                                 
                                  <div key={serv.id} className="w-7 h-7 px-px py-[5px] justify-center items-center flex">
                                   <img src={includedServicesImages[index]?.data?.result?.category.image} alt="Icon" /> 
                                  </div>
                                  
                                  <div className="text-red text-base font-normal">
                                    {serv.name}
                                  </div>
                                </div>
                                <div className="text-zinc-800 text-base font-normal">
                                  {serv.list_price} EGP
                                </div>
                                </div>
                            </SwiperSlide>
                            )
                        )}
                      </div> 
                      </Swiper>
                    </div>


                    <div className="flex flex-col justify-start items-start gap-2 ">
                      <label className="text-zinc-800 text-base font-normal leading-snug">
                        {t("Other Services")}
                      </label>
                     
                  <Swiper
                  spaceBetween={10} 
                  slidesPerView={isMobile ? 1 : 2} 
                  navigation={true}
                  modules={[Navigation]}
                >
                   <div className="w-full grid grid-cols-1 md:grid-cols-2 items-center  gap-6">
                        {venue.data.optional_services.map(
                            (serv , index) =>(
                            <SwiperSlide key={serv.id}>
                              <div className="p-4 rounded-xl w-[98%] border flex-col justify-start items-start gap-2.5 inline-flex">
                                <div className="justify-center items-center gap-4 inline-flex">
                                  <div className="w-7 h-7 px-px py-[5px] justify-center items-center flex">
                                 <img src={optionalServicesImages[index]?.data?.result?.category.image} alt="Icon" /> 
                                  </div>
                                  <div className="text-red text-base font-normal">
                                    {serv.name}
                                  </div>
                                </div>
                                <div className="text-zinc-800 text-base font-normal">
                                  {serv.list_price} EGP
                                  
                                </div>
                                </div>
                                </SwiperSlide>
                            )
                        )}
                      
                      </div>
                </Swiper>
                     
          </div>
              </div>
                  
          </div>
        </div>
      </div>
    </div>
          
  </form>
</>
         

          )
          }
 
         

           </ section>
  )}
 
export default ViewVenue