import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import mastercard from "../../assets/images/booking/icons/mastercard.svg"
import visa from "../../assets/images/booking/icons/Visa.svg"
import bitcoin from "../../assets/images/booking/icons/bitcoin.svg"
import clippath from "../../assets/images/booking/icons/clippath.svg"
import cashIcon from "../../assets/images/booking/icons/cash.svg"
import {Controller, useForm} from "react-hook-form";
import { toast } from "react-toastify";
import { useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import useAxiosUserSecureConfig from "../../hooks/useAxiosUserSecureConfig";




function BookingPaymentRequest({ open, handleClose , setBookingItem , itemId , fetchData}) {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    control
  } = useForm();

 
  const onSubmit = (data)=> {
    console.log("data", data.paymentMethod);
    setBookingItem((prevState) => ({
      ...prevState,
      loading: true,
    }));
    if(data.paymentMethod === "cash"){
      axios.post(`/api/venue-booking/pay/${params.id === undefined ? itemId : params.id}`, {} , config)
      .then((res) => {
        console.log("paid item", res.data);
        if (!res.data?.result?.success) {
          toast.error("Error while sending cash payment request!");
          return;
        }
        setBookingItem((prevState) => ({
          ...prevState,
          loading: false,
        }));
        handleClose()
        toast.success("You have sent cash payment request successfully!");
        fetchData();
      }).catch((err) => {
        console.log(err);
        setBookingItem((prevState) => ({
          ...prevState,
          loading: false,
        }));
      })
      
    }
    else if(data.paymentMethod === "online"){
      setBookingItem((prevState) => ({
        ...prevState,
        loading: true,
      }));

      axios.post(`/api/venue-booking/online-payment/create-session/${itemId}` ,data, config)
          .then((res) => {
            console.log("session_id", res.data)

            if (res.data.result?.success === false) {
              toast.error(res.data.result.message);
              setBookingItem((prevState) => ({
                ...prevState,
                loading: false,
              }));
              return;
            }


            setBookingItem((prevState) => ({
              ...prevState,
              loading: false,
            }));

            navigate(`/payment/${res.data.result.session_id}`);

          })
          .catch((err) => {
            console.error(err)
            toast.error("Error while placing order!");
          })
    }
  }
  const params = useParams();
  const config = useAxiosUserSecureConfig();



  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="absolute md:w-2/5 w-11/12 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 px-8 py-6 bg-white rounded-2xl shadow flex-col justify-center items-start inline-flex border-4 border-red">
        <form onSubmit={handleSubmit(onSubmit)} className="w-full">
          <div className="justify-between items-center inline-flex w-full mb-8">
            <p className="text-zinc-800 text-2xl font-medium font-['Roboto']">
              Select method
            </p>
            <button
              onClick={() => handleClose()}
              className="self-stretch justify-between items-center flex"
            >
              <FontAwesomeIcon icon={faX} className="text-xl text-gray-500" />
            </button>
          </div>
          <div className="w-full mb-8">
            <Controller
              name="paymentMethod"
              control={control}
              defaultValue="cash"
              render={({ field }) => (
                <RadioGroup
                  {...field}
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                >
                  <div className="w-full flex flex-col gap-6">
                    <div className="w-full px-4 py-3 bg-zinc-100 rounded-xl justify-between items-center inline-flex">
                      <div className="w-1/2">
                        <FormControlLabel
                          value="online"
                          control={<Radio />}
                          label="Online payment"
                        />
                      </div>
                      <div className="flex gap-2 w-1/2">
                        <div className="px-4 py-2 rounded-[9px] border border-zinc-300 flex-col justify-center items-center gap-3 inline-flex">
                          <img src={mastercard} alt="mastercard" />
                        </div>
                        <div className="px-4 py-2 rounded-[9px] border border-zinc-300 flex-col justify-center items-center gap-3 inline-flex">
                          <img src={visa} alt="visa" />
                        </div>
                        <div className="px-4 py-2 rounded-[9px] border border-zinc-300 flex-col justify-center items-center gap-3 inline-flex">
                          <img src={bitcoin} alt="bitcoin" />
                        </div>
                        <div className="px-4 py-2 rounded-[9px] border border-zinc-300 flex-col justify-center items-center gap-3 inline-flex">
                          <img src={clippath} alt="clippath" />
                        </div>
                      </div>
                    </div>
                    <div className="w-full px-4 py-3 bg-zinc-100 rounded-xl justify-between items-center inline-flex">
                      <div className="w-1/2">
                        <FormControlLabel
                          value="cash"
                          control={<Radio />}
                          label="Cash payment"
                        />
                      </div>
                      <div className="flex gap-2 w-1/2">
                        <div className="w-full px-4 py-2 rounded-[9px] border border-zinc-300 flex-col justify-center items-center gap-3 inline-flex">
                          <img src={cashIcon} alt="cashIcon" />
                        </div>
                      </div>
                    </div>
                  </div>
                </RadioGroup>
              )}
            />
          </div>
          <div className="w-full flex gap-8">
            <button
              onClick={() => handleClose()}
              className="w-full h-[47px] px-[18px] py-[6.40px] bg-white rounded-[10px] shadow border border-red justify-center items-center gap-2.5 inline-flex"
            >
              <span className="text-center text-red text-base font-normal font-['Roboto'] leading-normal">
                Cancel
              </span>
            </button>
            <button
              type="submit"
              className="w-full h-[47px] px-[18px] py-[6.40px] bg-red rounded-[10px] shadow border border-red justify-center items-center gap-2.5 inline-flex"
            >
              <span className="text-center text-white text-base font-normal font-['Roboto'] leading-normal">
                Confirm
              </span>
            </button>
          </div>
        </form>
      </Box>
    </Modal>
  );
}

export default BookingPaymentRequest;
