
import {
  faArrowUpFromBracket,
  faDownload,
  faPenToSquare,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import deleteIcon from "../../../assets/images/icons/delete.svg";
import eyeIcon from "../../../assets/images/icons/eyeIcon.png";
import eyeOffIcon from "../../../assets/images/icons/eye-off.png";
import uplooad from "../../../assets/images/icons/upload.svg";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import invitation1 from "../../../assets/images/invitation/invitation1.jpeg";
import invitation2 from "../../../assets/images/invitation/invitation2.jpeg";
import invitation3 from "../../../assets/images/invitation/invitation3.jpeg";
import invitation4 from "../../../assets/images/invitation/invitation4.jpeg";
import invitation5 from "../../../assets/images/invitation/invitation5.jpeg";
import invitation6 from "../../../assets/images/invitation/invitation6.jpeg";
import axios from "axios";
import useAxiosUserSecureConfig from "../../../hooks/useAxiosUserSecureConfig";
import { toast } from "react-toastify";
import invitationHeader from "../../../assets/images/invitation/invitationHeader.png";
import { useTable, useSortBy, usePagination, updateRow } from "react-table";
import rejectIcon from "../../../assets/images/icons/reject.svg";
import editIcon from "../../../assets/images/icons/edit.png";
import trashIcon from "../../../assets/images/icons/trash.png";
import Loader from "../../../components/Loader";
import acceptIcon from "../../../assets/images/icons/accept.svg";
import { PhoneInput } from "react-international-phone";
import galleryIcon from "../../../assets/images/icons/gallery.png";
import uploadIcon from "../../../assets/images/icons/upload.png";
import placeholder from "../../../assets/images/invitation/placeholder-image.jpg";
import icon1 from "../../../assets/images/invitation/object (2).png";
import icon2 from "../../../assets/images/invitation/object (3).png";
import icon3 from "../../../assets/images/invitation/object (4).png";
import doneIcon from "../../../assets/images/icons/done.png";
import {useTranslation} from "react-i18next";


// const data = [
//   { guestName: 'John Doe', guestNumber: '1234567890', classified: 'Friend'},
//   { guestName: 'Jane Smith', guestNumber: '0987654321', classified: 'Family'},
//   // Add more data as needed
// ];

// const columns = [
//   { Header:"Guest name", accessor: "guestName" },
//     { Header: "Guest mobile number", accessor: "guestNumber" },
//     { Header: "Classified", accessor: "classified.label" },
//   ];

function CreateInvitation() {
  const { t, i18n } = useTranslation();

  const [groomPhotoName, setGroomPhotoName] = useState("");
  const handleGroomPhotoUpload = (e) => {
    console.log(e.target.files[0]);
    const file = e.target.files[0];
    setGroomPhotoName(file?.name);
  };
 
  const [bridePhotoName, setBridePhotoName] = useState("");
  const handleBridePhotoUpload = (e) => {
    console.log(e.target.files[0]);
    const file = e.target.files[0];
    setBridePhotoName(file?.name);
  };
  const invitations = [
    {
      id: 1,
      src: invitation1,
      alt: "invitation1",
      label: t("Classic"),
      value: "classic",
    },
    {
      id: 2,
      src: invitation2,
      alt: "invitation2",
      label: t("Modern"),
      value: "modern",
    },
    {
      id: 3,
      src: invitation3,
      alt: "invitation3",
      label: t("Islamic"),
      value: "islamic",
    },
    {
      id: 4,
      src: invitation4,
      alt: "invitation4",
      label: t("Old"),
      value: "old",
    },
    {
      id: 5,
      src: invitation5,
      alt: "invitation5",
      label: t("Dark"),
      value: "dark",
    },
    {
      id: 6,
      src: invitation6,
      alt: "invitation6", 
      label: t("New"),
      value: "new",
    },
  ];
  const {
    register,
    handleSubmit,
    control,
    trigger,
    formState: { errors },
    setValue,
    triggerValidation,
    watch
  } = useForm({ mode: "all" });
  const config = useAxiosUserSecureConfig();
  const [selectedInvitation, setSelectedInvitation] = useState(null);
  const [invitaionId, setInvitaionId] = useState(invitations[0]);
  const [invitaionLoading , setInvitaionLoading] = useState(false)
  const columns = React.useMemo(
    () => [
      { Header: t('Guest name'), accessor: 'guestName' },
      { Header: t('Guest mobile number'), accessor: 'guestNumber' },
      { Header: t('Classified'), accessor: 'classified.label' },
    ],
    [t]
  );
  
  const handleInvitationClick = (invitation) => {
     setInvitaionId(invitation);
     setInvitaionLoading(true)
     const headers = {
      ...config.headers,
      'Content-Type': 'application/octet-stream', // Example content type
  };
  console.log(invitation.value)
    const data = {
        groom_name: watch("groom_name"),
        bride_name: watch("bride_name"),
        wedding_address: watch("wedding_address"),
        wedding_date: watch("wedding_date"),
        wedding_time: watch("wedding_time"),
        style: invitation.value,
    };
    axios.post('/api/invitation/render', data, { ...config, headers, responseType: 'blob' })
        .then((res) => {
            if (!res.data) {
                setInvitaionLoading(false)
                toast.error(res.data.result?.message || "Request failed!");
                return;
            }
            const reader = new FileReader();
            reader.onload = () => {
                setSelectedInvitation(reader.result);
            };
            reader.onerror = (error) => {
                console.error("Error reading image:", error);
                toast.error("Error while reading image!");
            };
            reader.readAsDataURL(res.data);
            setInvitaionLoading(false)
        })
        .catch((err) => {
            setInvitaionLoading(false)
            console.log("Error fetching image:", err);
            toast.error("Error while adding invitation!");
        });
};
  const checkFields = () => {
    const requiredFields = ['groom_name', 'bride_name', 'wedding_address', 'wedding_date', 'wedding_time'];
    return requiredFields.every(field => watch(field));
  };
  console.log("====>" ,checkFields())
  const [invitationClicked, setInvitationClicked] = useState(false);

  useEffect(() => {
    if (!invitationClicked && checkFields()) {
      handleInvitationClick(invitations[0]);
      setInvitationClicked(true);
      console.log("hii")
    }
  }, [invitationClicked, checkFields, invitations]);
  
  const navigate = useNavigate();
  const [formStep, setFormStep] = useState(0);

  const [loading, setLoading] = useState(false);
  axios.defaults.timeout = 50000;
  axios.defaults.timeoutErrorMessage = "timeout";
  
  const onSubmit = async (data) => {
    console.log("Data:", data);
    setLoading(true);
    const convertToBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    };
    const storyArray = [];
    dateInputs.forEach((input) => {
      const storyData = {
        date: input.date || "",
        about_story: input.aboutStory || "",
        photo: "",
      };
      storyArray.push(storyData);
    });
    const guestsArray = [];
    guestsList.forEach((input) => {
      const guestsData = {
        name: input.guestName || "",
        phone: input.guestNumber || "",
        classified: input.classified.value || "",
      };
      guestsArray.push(guestsData);
    });
    // const photosArrayPromises = [];
    // const files = data.photos || [];
    // for (let i = 0; i < files.length; i++) {
    //   const file = files[i];
    //   try {
    //     const photoBase64 = await convertToBase64(file);
    //     photosArrayPromises.push(photoBase64);
    //   } catch (error) {
    //     console.error(`Error converting photo ${i} to base64:`, error);
    //   }
    // }
    const photosArray = await Promise.all(photos);
    const cleanedPhotosArray = photosArray.map(photo => photo.substring(photo.indexOf(',') + 1));
    console.log("Photos Array:", cleanedPhotosArray);
    const requestData = {
      groom_name: data.groom_name,
      bride_name: data.bride_name,
      // groom_photo: groomPhotoBase64,
      // bride_photo: bridePhotoBase64,
      wedding_address: data.wedding_address,
      wedding_location: data.location,
      wedding_date: data.wedding_date,
      wedding_time: data.wedding_time,
      about_groom: data.about_groom || "",
      about_bride: data.about_bride || "",
      notes: data.notes || "",
      style: data.invitation_style,
      story: storyArray,
      guests: guestsArray,
      photos: cleanedPhotosArray,
    };
    console.log("requestData", requestData);
    axios
      .post("/api/invitation/create", requestData, config)
      .then((res) => {
        if (!res.data.result?.success) {
          console.log(res.data);
          toast.error(res.data.result?.msg || t("Request failed!"));
          setLoading(false);
          return;
        }
        toast.success(t("You create wedding invitaion successfully!"));
        setLoading(false);
        navigate(`/invitation/view/${res.data.result.invitation.id}`);
        console.log("ii", res.data.result.invitation)
      })
      .catch((err) => {
        console.log("err", err);
        toast.error("Error while creating wedding invitation!");
        setLoading(false);
      });
  };
  
  const selectedFile = watch("photos");
  const isFile = selectedFile instanceof File;

  const [guestsList, setGuestsList] = useState([]);
  const [newGuest, setNewGuest] = useState({
    guestName: "",
    guestNumber: "",
    classified: "",
  });
   const [guseterrors, setGuestErrors] = useState({
    guestName: '',
    guestNumber: '',
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log("value" ,value);
    setNewGuest((prevGuest) => ({ ...prevGuest, [name]: value }));
  };
  const handleClassifiedChange = (selectedOption) => {
    setNewGuest((prevGuest) => ({ ...prevGuest, classified: selectedOption }));
  };
  const validateFields = () => {
    const newErrors = {};
    let isValid = true;

    if (!newGuest.guestName.trim()) {
      newErrors.guestName = t('Guest name is required');
      isValid = false;
    }

    if (!newGuest.guestNumber.trim()) {
      newErrors.guestNumber = t('Guest mobile number is required');
      isValid = false;
    }
    if(guestsList.some(guest => guest.guestNumber === newGuest.guestNumber)){
      newErrors.guestNumber = t('Guest mobile number already exists');
      isValid = false;
    }

    if (!newGuest.classified) {
      newErrors.classified = t('Classified is required');
      isValid = false;
    }

    setGuestErrors(newErrors);
    return isValid;
  };

  const addGuest = () => {
    if (!validateFields()) {
      return;
    }
    console.log("newGuest" ,newGuest);
    setGuestsList((prevList) => [...prevList, { ...newGuest, id: Date.now() }]);
    setNewGuest({ guestName: "", guestNumber: "", classified: "" });
    console.log(guestsList);
  };
  // const isGuestNumberExists = (guestNumber) => {
  //   const exists = guestsList.some(guest => guest.guestNumber === guestNumber);
  //   return exists;
  // };
  const [editingRowId, setEditingRowId] = useState(null);

  // Inside your handleEdit function
  const handleEdit = (rowId, guestNumber) => {
    console.log("Editing row:", rowId);
    setEditingRowId(rowId);
    setNewNumber(guestNumber); // Set the newNumber state to the current guest's number
  };
  const [duplicateMobileError, setDuplicateMobileError] = useState(null);
  const [newNumber, setNewNumber] = useState("");
  const [currentRowIndex, setCurrentRowIndex] = useState(null);
  const validateNewNumber = (number) => {
    console.log("Validating number:", number);
    const isDuplicate = guestsList.some(
      (guest, index) => guest.guestNumber === number && index !== currentRowIndex
    );
    if (isDuplicate) {
      setDuplicateMobileError(t("Guest mobile number already exists"));
      return false;
    } else {
      setDuplicateMobileError(null); // Clear error if no duplicates
      return true;
    }
  };

  const handleSave = () => {
    console.log("Saving number:", newNumber);
    if (!validateNewNumber(newNumber)) {
      return;
    }
    console.log("Validation passed, saving number:", newNumber);
    setEditingRowId(null);
    // Save changes to the backend or update state as needed
  };
  

  const handleDelete = (id) => {
    setGuestsList((prevList) => prevList.filter((guest) => guest.id !== id));
  };
  const images = [icon1 , icon2 , icon3]
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [dateInputs, setDateInputs] = useState([
    {
      id: Date.now(), // Unique ID for each date input
      date: '',
      aboutStory: '',
      image: images[currentImageIndex], // Use the image at the current index
    }
  ]);

  const incrementImageIndex = () => {
    setCurrentImageIndex(prevIndex => (prevIndex + 1) % images.length);
  };

  const handleAddDate = () => {
    setDateInputs(prevInputs => [
      ...prevInputs,
      {
        id: Date.now(),
        date: '',
        aboutStory: '',
        image: images[(currentImageIndex + 1) % images.length], // Use the next image in the array
      }
    ]);
    incrementImageIndex(); // Increment the image index
  };
  const [hiddenRows, setHiddenRows] = useState([]);

  const handleToggleRow = (id) => {
    setHiddenRows(prevHiddenRows => {
      if (prevHiddenRows.includes(id)) {
        return prevHiddenRows.filter(rowId => rowId !== id);
      } else {
        return [...prevHiddenRows, id];
      }
    });
  };

  useEffect(() => {
    setHiddenRows(dateInputs.map(input => input.id));
  }, []);

  const handleRemoveDate = (id) => {
    setDateInputs((prevDateInputs) =>
      prevDateInputs.filter((input) => input.id !== id)
    );
  };

  const handleChange = (index, field, value) => {
    setDateInputs((prevDateInputs) => {
      const updatedDateInputs = [...prevDateInputs];
      updatedDateInputs[index][field] = value;
      return updatedDateInputs;
    });
  };
  console.log("inputs" , dateInputs)
  const customStyles = {
    control: (styles) => ({
      ...styles,
      background: "#F2F4F8",
      "border-color": "#F2F4F8",
      "border-radius": "8px",
      padding: "6px",
      height: "48px",
    }),
  };

  useEffect(() => {
    const hasErrors =
      errors.groom_name ||
      errors.bride_name ||
      errors.wedding_address ||
      errors.location ||
      errors.wedding_date ||
      errors.wedding_time;
    if (hasErrors && formStep !== 0) {
      setFormStep(0);
    }
  }, [errors, formStep]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    updateRow,
  } = useTable(
    {
      columns,
      data: guestsList,
    },
    useSortBy,
    usePagination
  );
console.log("guestsList" , guestsList)
const [photos, setPhotos] = useState([]);

const handleFileChange = (e) => {
  const file = e.target.files[0];
  if (!file) return;

  if (file.size > 50 * 1024 * 1024) {
    toast.error(t("File size exceeds 50MB limit"));
    return;
  }

  const extension = file.name.split(".").pop().toLowerCase();
  if (extension !== "jpg") {
    toast.error(t("Only JPG files are allowed"));
    return;
  }

  if (photos.length >= 5) {
    toast.error(t("Maximum 5 files allowed"));
    return;
  }

  const reader = new FileReader();
  reader.onload = (event) => {
    setPhotos((prevPhotos) => [...prevPhotos, event.target.result]);
  };
  reader.readAsDataURL(file);
};

const handleRemoveImage = (imageSrc) => {
  setPhotos((prevPhotos) => prevPhotos.filter((photo) => photo !== imageSrc));
};
  return (
    <section>
      <div className="relative">
        <img className="w-full" src={invitationHeader} alt="invitationHeader" />
        <div className="absolute bottom-[1%] px-10 py-5 lg:p-10 w-full flex items-center justify-between">
          <p className="text-center text-[#FAF5EE]  font-medium">
            {t("Wedding Invitation")}
          </p>
        </div>
      </div>
      <div className="px-4 md:px-14 lg:px-[120px] py-10 bg-zinc-100">
        {loading && <Loader slim />}
        {!loading && (
          <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
            <div className={formStep === 0 ? "block" : "hidden"}>
              <div className="w-full justify-between items-center inline-flex mb-6">
                <div className="text-neutral-800 text-lg md:text-2xl font-semibold leading-9">
                  {t("Wedding Invitation")}
                </div>
                <div className="justify-start items-center gap-2 flex">
                  <div className="text-neutral-800 text-[12px] md:text-base font-normal leading-tight">
                    {t("Step 1 of 4")}
                  </div>
                  <div className="justify-start items-start gap-2 flex">
                    <div className="w-3 h-3 md:w-4 md:h-4 bg-red rounded-full" />
                    <div className="w-3 h-3 md:w-4 md:h-4 bg-zinc-300 rounded-full" />
                    <div className="w-3 h-3 md:w-4 md:h-4 bg-zinc-300 rounded-full" />
                    <div className="w-3 h-3 md:w-4 md:h-4 bg-zinc-300 rounded-full" />
                  </div>
                </div>
              </div>
              <div>
                <div className=" md:p-8 p-4  bg-white rounded-3xl mb-6">
                  <div className="flex items-start mb-5 ">
                    <p className=" text-neutral-800 text-lg md:text-2xl font-normal">
                      {" "}
                      {t("Personal Information's")}
                    </p>{" "}
                    <span className="text-red-500 text-base font-normal leading-snug">
                      *
                    </span>
                  </div>
                  <div className="flex-col md:grid grid-cols-2 gap-x-6 gap-y-4">
                    <div className="flex flex-col justify-start items-start gap-2 ">
                      <div>
                        <label className="text-zinc-800 text-base font-normal leading-snug">
                          {t("Bride Name")}
                        </label>
                        <span className="text-red-500 text-base font-normal leading-snug">
                          *
                        </span>
                      </div>
                      <input
                        placeholder={t("Name")}
                        {...register("bride_name", {
                          required: t("Bride Name is required"),
                        })}
                        className="w-full h-12 px-4 py-3 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 font-normal leading-tight text-sm placeholder-[#808080]"
                      />
                      <Typography variant="small" color="red">
                        {errors.bride_name ? errors.bride_name?.message : ""}
                      </Typography>
                    </div>
                    <div className="self-stretch flex flex-col justify-start items-start gap-2 ">
                      <div>
                        <label className="text-zinc-800 text-base font-normal leading-snug">
                          {t("Groom Name")}
                        </label>
                        <span className="text-red-500 text-base font-normal leading-snug">
                          *
                        </span>
                      </div>
                      <input
                        placeholder={t("Name")}
                        {...register("groom_name", {
                          required: t("Groom Name is required"),
                        })}
                        className="w-full h-12 px-4 py-3 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 font-normal leading-tight text-sm placeholder-[#808080]"
                      />
                      <Typography variant="small" color="red">
                        {errors.groom_name ? errors.groom_name?.message : ""}
                      </Typography>
                    </div>
                    <div className="flex flex-col justify-start items-start gap-2 ">
                      <div>
                        <label className="text-zinc-800 text-base font-normal leading-snug">
                          {t("Wedding Address")}
                        </label>
                        <span className="text-red-500 text-base font-normal leading-snug">
                          *
                        </span>
                      </div>
                      <input
                        placeholder={t("Address")}
                        {...register("wedding_address", {
                          required: t("Wedding Address is required"),
                        })}
                        className="w-full h-12 px-4 py-3 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 font-normal leading-tight text-sm placeholder-[#808080]"
                      />
                      <Typography variant="small" color="red">
                        {errors.wedding_address
                          ? errors.wedding_address?.message
                          : ""}
                      </Typography>
                    </div>
                    <div className="flex flex-col justify-start items-start gap-2 ">
                      <div>
                        <label className="text-zinc-800 text-base font-normal leading-snug">
                          {t("Wedding location")}
                        </label>
                        <span className="text-red-500 text-base font-normal leading-snug">
                          *
                        </span>
                      </div>
                      <input
                        placeholder={t("Location")}
                        {...register("location", {
                          required: t("Wedding location is required"),
                        })}
                        className="w-full h-12 px-4 py-3 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 font-normal leading-tight text-sm placeholder-[#808080]"
                      />
                      <Typography variant="small" color="red">
                        {errors.location ? errors.location?.message : ""}
                      </Typography>
                    </div>
                    <div className="flex flex-col justify-start items-start gap-2 ">
                      <div>
                        <label className="text-zinc-800 text-base font-normal leading-snug">
                          {t("Wedding date")}
                        </label>
                        <span className="text-red-500 text-base font-normal leading-snug">
                          *
                        </span>
                      </div>
                      <input
                        type="date"
                        {...register("wedding_date", {
                          required: t("Wedding date is required"),
                          validate: (value) =>
                            new Date(value) > new Date()
                              ? true
                              : t("Wedding date must be in the future"),
                        })}
                        className="w-full h-12 px-4 py-3 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 font-normal leading-tight text-sm placeholder-[#808080]"
                      />
                      <Typography variant="small" color="red">
                        {errors.wedding_date
                          ? errors.wedding_date?.message
                          : ""}
                      </Typography>
                    </div>
                    <div className="flex flex-col justify-start items-start gap-2 ">
                      <div>
                        <label className="text-zinc-800 text-base font-normal leading-snug">
                          {t("Wedding time")}
                        </label>
                        <span className="text-red-500 text-base font-normal leading-snug">
                          *
                        </span>
                      </div>
                      <input
                        type="time"
                        {...register("wedding_time", {
                          required: t("Wedding time is required"),
                        })}
                        className="w-full h-12 px-4 py-3 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 font-normal leading-tight text-sm placeholder-[#808080]"
                      />
                      <Typography variant="small" color="red">
                        {errors.wedding_time
                          ? errors.wedding_time?.message
                          : ""}
                      </Typography>
                    </div>
                    <div className="flex flex-col justify-start items-start gap-2 ">
                      <div>
                        <label className="text-zinc-800 text-base font-normal leading-snug">
                          {t("About Bride")}
                        </label>
                      </div>
                      <textarea
                        placeholder={t("About Bride")}
                        {...register("about_bride")}
                        className="w-full h-24 px-4 py-3 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 font-normal leading-tight text-sm placeholder-[#808080]"
                      />
                    </div>
                    <div className="flex flex-col justify-start items-start gap-2 mt-3 md:mt-0">
                      <div>
                        <label className="text-zinc-800 text-base font-normal leading-snug">
                          {t("About Groom")}
                        </label>
                      </div>
                      <textarea
                        placeholder={t("About Groom")}
                        {...register("about_groom")}
                        className="w-full h-24 px-4 py-3 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 font-normal leading-tight text-sm placeholder-[#808080]"
                      />
                    </div>

                    <div className="col-span-2 flex flex-col justify-start items-start gap-2 mb-4 mt-3 md:mt-0">
                      <div>
                        <label className="text-zinc-800 text-base font-normal leading-snug">
                          {t("Notes for the wedding")}
                        </label>
                      </div>
                      <textarea
                        placeholder={t("Notes for the wedding")}
                        {...register("notes")}
                        className="w-full h-24 px-4 py-3 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 font-normal leading-tight text-sm placeholder-[#808080]"
                      />
                    </div>
                  </div>
                  <div className="flex items-center justify-between">
                    <button
                      onClick={() => navigate("/invitation")}
                      type="button"
                      className="w-[150px] h-12 px-8 py-2 rounded-lg border border-red justify-center items-center inline-flex"
                    >
                      <span className="text-red text-sm font-medium leading-[14px] tracking-wide">
                        {t("Back")}
                      </span>
                    </button>
                    <button
                      onClick={() => {
                        setFormStep(1);
                        trigger([
                          "groom_name",
                          "bride_name",
                          "wedding_address",
                          "location",
                          "wedding_date",
                          "wedding_time",
                        ]);
                      }}
                      type="button"
                      className={`w-[150px] h-12 px-8 py-2 rounded-lg border  ${
                        errors.groom_name ||
                        errors.bride_name ||
                        errors.wedding_address ||
                        errors.location ||
                        errors.wedding_date ||
                        errors.wedding_time
                          ? "bg-[#CCCCCC]"
                          : "bg-red"
                      } justify-center items-center inline-flex text-white text-sm font-medium leading-[14px] tracking-wide`}
                    >
                      <span className="text-white text-sm font-medium leading-[14px] tracking-wide">
                        {t("Next")}
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className={formStep === 1 ? "block" : "hidden"}>
              <div className="w-full justify-between items-center inline-flex mb-6">
                <div className="text-neutral-800 text-base text-balance sm:text-lg md:text-2xl font-semibold sm:leading-9">
                  {t("Choose Invitation Style")}
                </div>
                <div className="justify-start items-center gap-2 flex">
                  <div className="text-neutral-800 text-[12px] md:text-base font-normal leading-tight">
                    {t("Step 2 of 4")}
                  </div>
                  <div className="justify-start items-start gap-2 flex">
                    <div className="w-3 h-3 md:w-4 md:h-4 bg-zinc-300 rounded-full" />
                    <div className="w-3 h-3 md:w-4 md:h-4 bg-red rounded-full" />
                    <div className="w-3 h-3 md:w-4 md:h-4 bg-zinc-300 rounded-full" />
                    <div className="w-3 h-3 md:w-4 md:h-4 bg-zinc-300 rounded-full" />
                  </div>
                </div>
              </div>
              {invitaionLoading && <Loader slim />}
              <div className=" mb-10 md:p-8 p-4 bg-white rounded-3xl">
                <div className="grid grid-cols-3 md:grid-cols-6 gap-6">
                  {selectedInvitation === null && (
                    <div
                      className={`col-span-6 h-[170px] bg-zinc-100 rounded-2xl flex justify-center items-center`}
                    >
                      <img src={galleryIcon} alt="galleryIcon" />
                    </div>
                  )}
                  {!invitaionLoading && selectedInvitation && (
                    <div
                      className={`col-span-6 md:h-[600px] bg-zinc-100 rounded-2xl flex justify-center items-center`}
                    >
                      <img
                        src={selectedInvitation}
                        alt="galleryIcon"
                        className="md:h-full"
                      />
                    </div>
                  )}
                </div>
                <div className="grid grid-cols-2 md:grid-cols-6 gap-6 mt-4">
                  {invitations.map((invitation) => {
                    const invitationId = invitation.id;
                    return (
                      <div key={invitationId} className="relative ">
                        <Controller
                          name={`invitation_style`}
                          control={control}
                          defaultValue={invitations[0].value}
                          value={invitation.value}
                          render={({ field }) => (
                            <div
                              className={`h-[250px] flex-col justify-start items-center gap-4 inline-flex cursor-pointer `}
                              onClick={() => {
                                field.onChange(invitation.value);
                                handleInvitationClick(invitation);
                              }}
                            >
                              <img
                                src={invitation.src}
                                alt={invitation.alt}
                                className={`h-[200px] rounded-[211px] shadow-lg ${
                                  invitaionId?.id === invitationId
                                    ? "border-[3px] border-red"
                                    : ""
                                }`}
                              />
                              <div className="text-center text-zinc-800 text-base font-normal leading-snug">
                                {invitation.label}
                              </div>
                            </div>
                          )}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="flex items-center justify-between">
                <button
                  onClick={() => setFormStep(0)}
                  type="button"
                  className="w-[150px] h-12 px-8 py-2 rounded-lg border border-red justify-center items-center inline-flex"
                >
                  <span className="text-red text-sm font-medium leading-[14px] tracking-wide">
                    {t("Back")}
                  </span>
                </button>
                <button
                  onClick={() => {
                    setFormStep(2);
                  }}
                  type="button"
                  className="w-[150px] h-12 px-8 py-2 rounded-lg border bg-red justify-center items-center inline-flex"
                >
                  <span className="text-white text-sm font-medium leading-[14px] tracking-wide">
                    {t("Next")}
                  </span>
                </button>
              </div>
            </div>
            <div className={formStep === 2 ? "block" : "hidden"}>
              <div className="w-full justify-between items-center inline-flex mb-6">
                <div className="text-neutral-800 text-lg md:text-2xl font-semibold leading-9">
                  {t("Wedding Invitation")}
                </div>
                <div className="justify-start items-center gap-2 flex">
                  <div className="text-neutral-800 text-[12px] md:text-base font-normal leading-tight">
                    {t("Step 3 of 4")}
                  </div>
                  <div className="justify-start items-start gap-2 flex">
                    <div className="w-3 h-3 md:w-4 md:h-4 bg-zinc-300 rounded-full" />
                    <div className="w-3 h-3 md:w-4 md:h-4 bg-zinc-300 rounded-full" />
                    <div className="w-3 h-3 md:w-4 md:h-4 bg-red rounded-full" />
                    <div className="w-3 h-3 md:w-4 md:h-4 bg-zinc-300 rounded-full" />
                  </div>
                </div>
              </div>
              <div className="md:p-8 p-2 bg-white rounded-3xl mb-10">
                <div className="flex justify-between items-center mb-4">
                  <p className="text-neutral-800 text-2xl font-semibold leading-9 flex items-center gap-1">
                    {t("Our Album")}
                    <span className="text-[#999999] text-base font-normal">
                      {t("(optional)")}
                    </span>
                  </p>
                  {/* <button
                    type="button"
                    className="h-12 text-base text-red justify-center items-center inline-flex gap-1"
                  >
                    <img src={uploadIcon} alt="uploadIcon" />
                    <span> Upload Bulk Photos</span>
                  </button> */}
                </div>
                <div className="">
                  <div className="flex flex-col justify-start items-start gap-2">
                    <label className="text-zinc-800 text-base font-normal leading-snug flex items-center gap-1">
                      {t("Photos")}
                      <span className="text-[#999999] text-base font-normal">
                        {t("(Max 5)")}
                      </span>
                    </label>
                    <div className="relative w-full bg-slate-100 border-slate-100 rounded-lg">
                      <label>
                        <div className="h-12 flex justify-between items-center">
                          <input
                            type="file"
                            id="mediafileInput"
                            className="absolute -top-48 left-0 opacity-0 cursor-pointer w-20"
                            onChange={handleFileChange}
                          />
                          {/* Button to trigger file input */}
                          <label
                            htmlFor="mediafileInput"
                            className="cursor-pointer"
                          >
                            <div className={` absolute  ${i18n.language === 'en' ? 'right-0' : 'left-0'}  bottom-0 h-12 px-2 md:px-6 py-4 text-base bg-white text-red rounded-lg border border-red justify-center items-center inline-flex gap-1 md:gap-2`}>
                              <img src={uploadIcon} alt="uploadIcon" />
                              <span>{t("Upload")}</span>
                            </div>
                          </label>
                          {/* Input field to display the selected file names */}
                          <input
                            id="mediaFileName"
                            placeholder={t("Photos")}
                            className="border-0 h-full flex-grow p-4 bg-slate-100 rounded-lg text-zinc-500 text-base font-normal leading-tight placeholder-[#808080]"
                            readOnly // Make the input field read-only to prevent manual editing
                          />
                        </div>
                      </label>
                    </div>
                    <p className="text-neutral-400 text-sm font-normal leading-tight">
                      {t("(Max Size 50 MB)")}
                    </p>
                  </div>
                  {/* Container to display uploaded images */}
                  <div id="uploadedImages" className="flex flex-wrap mt-2">
                    {photos.map((photo, index) => (
                      <div key={index} className="relative inline-block">
                        <img
                          src={photo}
                          alt={`Uploaded ${index + 1}`}
                          className="h-24 w-20 object-cover rounded-lg mr-2 mb-2"
                        />
                        <button
                        type="button"
                          onClick={() => handleRemoveImage(photo)}
                          className="absolute top-0 right-0 bg-red text-white rounded-full px-1 text-xs"
                        >
                          X
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="md:p-8 p-4 bg-white rounded-3xl mb-10">
                <div className="flex justify-between items-center mb-4">
                  <p className="text-neutral-800 text-2xl font-semibold leading-9 flex items-center gap-1">
                    {t("Our Story")}
                    <span className="text-[#999999] text-base font-normal">
                      {t("(optional)")}
                    </span>
                  </p>
                </div>
                <div className="flex flex-col gap-4">
                  {dateInputs.map((input, index) => (
                    <div
                      key={input.id}
                      className="grid grid-cols-2 md:grid-cols-5 gap-6 pb-4"
                    >
                      <div className="col-span-2 flex flex-col justify-start items-start gap-2 w-full">
                        <div>
                          <label className="text-zinc-800 text-base font-normal leading-snug">
                            {t("Date")}
                          </label>
                          <span className="text-red-500 text-base font-normal leading-snug">
                            *
                          </span>
                        </div>
                        <input
                          type="date"
                          value={input.date}
                          onChange={(e) =>
                            handleChange(index, "date", e.target.value)
                          }
                          className="w-full h-12 px-4 py-3 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 font-normal leading-tight text-sm placeholder-[#808080]"
                        />
                      </div>
                      <div className="col-span-2 flex flex-col justify-start items-start gap-2 w-full">
                        <div>
                          <label className="text-zinc-800 text-base font-normal leading-snug">
                            {t("About Story")}
                          </label>
                          <span className="text-red-500 text-base font-normal leading-snug">
                            *
                          </span>
                        </div>
                        <input
                          type="text"
                          value={input.aboutStory}
                          onChange={(e) =>
                            handleChange(index, "aboutStory", e.target.value)
                          }
                          placeholder={t("About Story")}
                          className="w-full h-12 px-4 py-3 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 font-normal leading-tight text-sm placeholder-[#808080]"
                        />
                      </div>
                      <div className=" inline-flex md:flex-col lg:flex-row justify-between col-span-2 md:col-span-1 md:-ml-2">
                        <button
                          type="button"
                          onClick={() => handleToggleRow(input.id)}
                          className="flex items-center gap-2 md:gap-1 mt-6 mx-auto"
                        >
                          <img
                            src={
                              hiddenRows.includes(input.id)
                                ? eyeIcon
                                : eyeOffIcon
                            }
                            alt={
                              hiddenRows.includes(input.id)
                                ? t("Show")
                                : t("Hide")
                            }
                          />
                          <span
                            className={`text-red text-base md:text-[14px] font-normal leading-snug`}
                          >
                            {hiddenRows.includes(input.id)
                              ? t("Show")
                              : t("Hide")}
                          </span>
                        </button>
                        <button
                          type="button"
                          onClick={() => handleRemoveDate(input.id)}
                          className="flex items-center gap-2 md:gap-1 mt-6 mx-auto"
                        >
                          <img src={deleteIcon} alt="deleteIcon" />
                          <span className="text-[#E84A41] text-base md:text-[14px] font-normal leading-snug">
                            {t("Delete")}
                          </span>
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="grid grid-cols-5 mb-10">
                  <button
                    type="button"
                    onClick={handleAddDate}
                    className="col-span-5 md:col-span-4 h-12 w-full pl-3 pr-4 py-2 rounded-lg text-base text-red border border-red justify-center items-center gap-2 inline-flex"
                  >
                    <FontAwesomeIcon
                      className="font-normal leading-[21px]"
                      icon={faPlus}
                    />
                    <span className="font-normal leading-[21px]">
                      {t("Add story")}
                    </span>
                  </button>
                </div>
                {dateInputs.some((input) => !hiddenRows.includes(input.id)) && (
                  <div className="bg-[#F2F2F2] md:px-20 px-2 py-10 rounded-3xl">
                    {dateInputs.map(
                      (story, index) =>
                        !hiddenRows.includes(story.id) && (
                          <div key={index} className="flex items-start">
                            {index % 2 === 0 ? (
                              <div className="items-end relative border-e-[3px] border-[#f1cfcf] flex flex-col md:px-8 px-2 w-1/2  h-full pt-0 pb-28 gap-2">
                                <div className="absolute -top-[6px] -end-[19.5px]">
                                  <img src={doneIcon} alt="doneIcon" />
                                </div>
                                <h4 className="text-red font-[Rochester]">
                                  {story.date || "mm/dd/yy"}
                                </h4>
                                <p className="sm:text-lg text-base text-wrap">
                                  {story.aboutStory || t("about story")}</p>
                              </div>
                            ) : (
                              <div className="relative  flex justify-end md:px-8 px-2 w-1/2 h-full pt-0 pb-10 gap-2">
                                <div className="absolute -top-[6px] -end-[19.5px]">
                                  <img src={doneIcon} alt="doneIcon" />
                                </div>
                                <div className="relative">
                                  <img
                                    src={story.image || placeholder}
                                    className="w-[150px] cursor-pointer object-cover rounded-3xl"
                                    alt="YourImage"
                                  />
                                </div>
                              </div>
                            )}
                            {index % 2 === 0 ? (
                              <div className="flex justify-start px-2 md:px-8 w-1/2">
                                <div className="relative">
                                  <img
                                    src={story.image || placeholder}
                                    className="w-full cursor-pointer object-cover rounded-3xl"
                                    alt="YourImage"
                                  />
                                </div>
                              </div>
                            ) : (
                              <div className="flex flex-col border-s-[3px] border-[#f1cfcf] md:px-8 px-2 w-1/2 h-full pt-0 pb-28 gap-2">
                                <h4 className="text-red font-[Rochester] text-lg">
                                  {story.date || "mm/dd/yy"}
                                </h4>
                                <p className="sm:text-lg text-base">
                                  {story.aboutStory || t("about story")}
                                </p>
                              </div>
                            )}
                          </div>
                        )
                    )}
                  </div>
                )}
              </div>
              <div className="flex items-center justify-between">
                <button
                  onClick={() => setFormStep(1)}
                  type="button"
                  className="w-[150px] h-12 px-8 py-2 rounded-lg border border-red justify-center items-center inline-flex"
                >
                  <span className="text-red text-sm font-medium leading-[14px] tracking-wide">
                    {t("Back")}
                  </span>
                </button>
                <button
                  onClick={() => {
                    setFormStep(3);
                  }}
                  type="button"
                  className="w-[150px] h-12 px-8 py-2 rounded-lg border bg-red justify-center items-center inline-flex"
                >
                  <span className="text-white text-sm font-medium leading-[14px] tracking-wide">
                    {t("Next")}
                  </span>
                </button>
              </div>
            </div>
            <div className={formStep === 3 ? "block" : "hidden"}>
              <div className="w-full justify-between items-center inline-flex mb-6">
                <div className="text-neutral-800 text-lg sm:text-2xl font-semibold leading-9">
                  {t("Guests List")}
                </div>
                <div className="justify-start items-center gap-2 flex">
                  <div className="text-neutral-800 text-[12px] sm:text-base font-normal leading-tight">
                    {t("Step 4 of 4")}
                  </div>
                  <div className="justify-start items-start gap-2 flex">
                    <div className="w-3 h-3 sm:w-4 sm:h-4 bg-zinc-300 rounded-full" />
                    <div className="w-3 h-3 sm:w-4 sm:h-4 bg-zinc-300 rounded-full" />
                    <div className="w-3 h-3 sm:w-4 sm:h-4 bg-zinc-300 rounded-full" />
                    <div className="w-3 h-3 sm:w-4 sm:h-4 bg-red rounded-full" />
                  </div>
                </div>
              </div>
              <div className="md:p-8 p-4 bg-white rounded-3xl mb-10">
                <div className="flex flex-col md:flex-row justify-start md:justify-between items-start md:items-center">
                  <p className="text-neutral-800 text-base sm:text-2xl font-semibold leading-9 flex items-center gap-1 mb-3">
                    {t("Add Guests List")}
                    <span className="text-[#999999] text-xs sm:text-base font-normal">
                      {t("(optional)")}
                    </span>
                  </p>
                  {/* <button
                    type="button"
                    className="h-12 w-[110px] md:w-[157px] pl-3 pr-4  py-2 mb-4 rounded-lg text-base text-red border border-red justify-center items-center gap-0 md:gap-2 inline-flex"
                  >
                    <FontAwesomeIcon
                      className="text-sm md:text-base font-normal leading-[21px]"
                      icon={faArrowUpFromBracket}
                    />
                    <span className="text-sm md:text-base">Upload Guests</span>
                  </button> */}
                </div>
                <div className="flex flex-col gap-4">
                  <div className="flex flex-col md:flex-row items-start md:items-end md:justify-between gap-6">
                    <div className="grid grid-cols-2 md:grid-cols-6 gap-6 items-start md:items-end grow">
                      {/* Guest Name */}
                      <div className="col-span-2 flex flex-col justify-start items-start gap-2 w-full">
                        <div>
                          <label className="text-zinc-800 text-base font-normal leading-snug">
                            {t("Guest name")}
                          </label>
                          <span className="text-red-500 text-base font-normal leading-snug">
                            *
                          </span>
                        </div>
                        <input
                          type="text"
                          placeholder={t("Guest name")}
                          name="guestName"
                          value={newGuest.guestName}
                          onChange={handleInputChange}
                          className="w-full h-12 px-4 py-3 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 font-normal leading-tight text-sm placeholder-[#808080]"
                        />
                        {guseterrors.guestName && (
                          <span className="text-red-500 text-sm">
                            {guseterrors.guestName}
                          </span>
                        )}
                      </div>
                      {/* Guest Mobile Number */}
                      <div className="col-span-2 flex flex-col justify-start items-start gap-2 w-full invitaion-verification">
                        <div>
                          <label className="text-zinc-800 text-base font-normal leading-snug">
                            {t("Guest mobile number")}
                          </label>
                          <span className="text-red-500 text-base font-normal leading-snug">
                            *
                          </span>
                        </div>
                        <Controller
                          className="w-full"
                          control={control}
                          name="guestNumber"
                          render={({ field, field: { name, ref } }) => {
                            return (
                              <PhoneInput
                                className=""
                                defaultCountry="eg"
                                inputStyle={{
                                  background: "rgb(242 244 248",
                                  width: "100%",
                                  margin: "0px 4px",
                                  border: "0px",
                                  padding: "0 14px",
                                  height: "46px",
                                  borderRadius: "8px",
                                }}
                                name={name}
                                ref={ref}
                                {...field}
                                value={newGuest.guestNumber}
                                onChange={(value) =>
                                  setNewGuest((prevGuest) => ({
                                    ...prevGuest,
                                    guestNumber: value,
                                  }))
                                }
                              />
                            );
                          }}
                        />
                        {guseterrors.guestNumber && (
                          <span className="text-red-500 text-sm">
                            {guseterrors.guestNumber}
                          </span>
                        )}
                      </div>
                      {/* Classified */}
                      <div className="col-span-2 flex flex-col justify-start items-start gap-2 w-full">
                        <div>
                          <label className="text-zinc-800 text-base font-normal leading-snug">
                            {t("Classified")}
                          </label>
                          <span className="text-red-500 text-base font-normal leading-snug">
                            *
                          </span>
                        </div>
                        <Select
                          options={[
                            { value: "groom", label: t("Groom") },
                            { value: "bride", label: t("Bride") },
                            { value: "friends", label: t("Friends") },
                            { value: "others", label: t("Others") },
                          ]}
                          value={newGuest.classified}
                          onChange={handleClassifiedChange}
                          placeholder={t("Select...")}
                          className="w-full"
                          styles={customStyles}
                        />
                        {guseterrors.classified && (
                          <span className="text-red-500 text-sm">
                            {guseterrors.classified}
                          </span>
                        )}
                      </div>
                      {/* Add Guest button */}
                    </div>
                    <button
                      type="button"
                      className="h-12 w-[157px] pl-3 pr-4 py-2 rounded-lg text-base text-red border border-red justify-center items-center gap-2 inline-flex"
                      onClick={addGuest}
                    >
                      <FontAwesomeIcon
                        className="font-normal leading-[21px]"
                        icon={faPlus}
                      />
                      <span>{t("Add Guest")}</span>
                    </button>
                  </div>
                  {/* <div className="flex justify-end gap-2">
                    <button
                      type="button"
                      className="h-12 pl-3 pr-4 py-2 rounded-lg text-base text-gray-500 justify-center items-center gap-2 inline-flex"
                    >
                      <FontAwesomeIcon
                        className="font-normal leading-[21px]"
                        icon={faDownload}
                      />
                      <span>download template</span>
                    </button>
                    <button
                      type="button"
                      className="h-12 pl-3 pr-4 py-2 rounded-lg text-base text-gray-500 justify-center items-center gap-2 inline-flex"
                    >
                      <FontAwesomeIcon
                        className="font-normal leading-[21px]"
                        icon={faArrowUpFromBracket}
                      />
                      <span>Upload Bulk Guests</span>
                    </button>
                  </div> */}
                </div>
                <div className="flex justify-end">
                  {/* <button
                type="button"
                onClick={handleAddGuest}
                className="h-12 pl-3 pr-4 py-2 rounded-lg text-base text-red border border-red justify-start items-center gap-2 inline-flex"
              >
                <FontAwesomeIcon
                  className="font-normal leading-[21px]"
                  icon={faPlus}
                />
                <span className="font-normal leading-[21px]">Add Guest</span>
              </button> */}
                </div>
              </div>
              {guestsList.length > 0 && (
                <div className="overflow-x-auto">
                  <table className="w-full border-collapse mb-10 rounded-3xl shadow-md border border-gray-500">
                    <thead>
                      {headerGroups.map((headerGroup) => (
                        <tr
                          className="h-12  text-nowrap bg-slate-100"
                          {...headerGroup.getHeaderGroupProps()}
                        >
                          <th className="py-2 text-nowrap px-4 border text-gray-500 text-sm md:text-base font-medium leading-snug">
                            #
                          </th>
                          {headerGroup.headers.map((column) => (
                            <th
                              {...column.getHeaderProps(
                                column.getSortByToggleProps()
                              )}
                              className={`py-2 px-4 border  text-nowrap text-gray-500 text-base md:text-base font-medium leading-snug ${
                                column.isSorted
                                  ? column.isSortedDesc
                                    ? "sort-desc"
                                    : "sort-asc"
                                  : ""
                              }`}
                            >
                              {column.render("Header")}
                            </th>
                          ))}
                          <th
                            colSpan="2"
                            className="py-2 px-4 text-nowrap border text-gray-500 text-base md:text-base font-medium leading-snug"
                          >
                            {t("Actions")}
                          </th>
                        </tr>
                      ))}
                    </thead>
                    <tbody className="bg-white">
                      {rows.map((row, index) => {
                        prepareRow(row);
                        return (
                          <tr
                            {...row.getRowProps()}
                            className="border-t h-12"
                            key={index}
                          >
                            <td className="px-4 border  text-nowrap text-center text-gray-500 text-xs font-light leading-none">
                              {index + 1}
                            </td>
                            {row.cells.map((cell, cellIndex) => (
                              <td
                                {...cell.getCellProps()}
                                className="px-4 border text-center text-[#697077] text-sm font-normal leading-tight"
                                key={cellIndex}
                              >
                                {editingRowId === row.id ? (
                                  cell.column.Header ===
                                  t("Guest mobile number") ? (
                                    <>
                                      <Controller
                                        className="w-[170px]"
                                        control={control}
                                        name="tableguestNumber"
                                        render={({ field }) => (
                                          <PhoneInput
                                            className=""
                                            defaultCountry="eg"
                                            inputStyle={{
                                              background: "rgb(242 244 248",
                                              width: "150px",
                                              margin: "0px 4px",
                                              border: "0px",
                                              padding: "0 14px",
                                              height: "46px",
                                              borderRadius: "8px",
                                            }}
                                            name={field.name}
                                            ref={field.ref}
                                            value={cell.value} // Use newNumber as the value
                                            onChange={(selectedOption) => {
                                              console.log(
                                                "Phone input changed:",
                                                selectedOption
                                              );
                                              setNewNumber(selectedOption);
                                              setCurrentRowIndex(
                                                cell.row.index
                                              ); // Set the current row index being edited

                                              const newValue = selectedOption;
                                              const updatedRows =
                                                guestsList.map(
                                                  (guest, index) => {
                                                    if (
                                                      index === cell.row.index
                                                    ) {
                                                      return {
                                                        ...guest,
                                                        guestNumber: newValue,
                                                      };
                                                    }
                                                    return guest;
                                                  }
                                                );
                                              setGuestsList(updatedRows);
                                              setValue(
                                                "guestNumber",
                                                selectedOption
                                              );
                                            }}
                                          />
                                        )}
                                      />
                                      {duplicateMobileError && (
                                        <span className="text-red-500 text-sm">
                                          {duplicateMobileError}
                                        </span>
                                      )}
                                    </>
                                  ) : cell.column.Header === t("Classified") ? (
                                    <Select
                                      options={[
                                        { value: "groom", label: t("Groom") },
                                        { value: "bride", label: t("Bride") },
                                        {
                                          value: "friends",
                                          label: t("Friends"),
                                        },
                                        { value: "others", label: t("Others") },
                                      ]}
                                      defaultValue={{
                                        value: cell.value,
                                        label: cell.value,
                                      }}
                                      onChange={(selectedOption) => {
                                        const newValue = selectedOption;
                                        const updatedRows = guestsList.map(
                                          (guest, index) => {
                                            if (index === cell.row.index) {
                                              return {
                                                ...guest,
                                                classified: newValue,
                                              };
                                            }
                                            return guest;
                                          }
                                        );
                                        setGuestsList(updatedRows);
                                        setValue("classified", selectedOption);
                                      }}
                                      className="w-[150px] text-nowrap"
                                      styles={customStyles}
                                      formatOptionLabel={(option) => (
                                        <div>{option.label}</div>
                                      )}
                                    />
                                  ) : (
                                    <input
                                      className=" h-12 text-nowrap  px-4 py-3 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 font-normal leading-tight text-sm placeholder-[#808080]"
                                      type="text"
                                      value={cell.value}
                                      onChange={(e) => {
                                        const newValue = e.target.value;
                                        const updatedRows = guestsList.map(
                                          (guest, index) => {
                                            if (index === cell.row.index) {
                                              return {
                                                ...guest,
                                                [cell.column.id]: newValue,
                                              };
                                            }
                                            return guest;
                                          }
                                        );
                                        setGuestsList(updatedRows);
                                        setValue(cell.column.id, newValue); // Optionally update form data
                                      }}
                                      onBlur={() => {
                                        // Save cell value when input is blurred
                                        if (triggerValidation) {
                                          triggerValidation(cell.column.id);
                                        }
                                      }}
                                    />
                                  )
                                ) : (
                                  cell.render("Cell")
                                )}
                              </td>
                            ))}
                            <td className="px-2 text-nowrap border text-center">
                              {editingRowId !== row.id ? (
                                <button
                                  type="button"
                                  title="Edit"
                                  onClick={() => handleEdit(row.id)}
                                >
                                  <img src={editIcon} alt="editIcon" />
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  title="Save"
                                  onClick={handleSave}
                                >
                                  <img src={acceptIcon} alt="acceptIcon" />
                                </button>
                              )}
                            </td>
                            <td className="px-2 border text-center">
                              <button
                                type="button"
                                title="Delete"
                                onClick={() => handleDelete(row.original.id)}
                              >
                                <img src={trashIcon} alt="trashIcon" />
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}

              <div className="flex items-center gap-4 justify-between">
                <button
                  onClick={() => setFormStep(2)}
                  type="button"
                  className="w-[150px] h-12 px-8 py-2 rounded-lg border border-red justify-center items-center inline-flex"
                >
                  <span className="text-red text-sm font-medium leading-[14px] tracking-wide">
                    {t("Back")}
                  </span>
                </button>
                <button
                  onClick={() => ""}
                  type="submit"
                  className="w-[150px] h-12 px-8 py-2 rounded-lg border bg-red justify-center items-center inline-flex"
                >
                  <span className="text-white text-sm font-medium leading-[14px] tracking-wide">
                    {t("Create")}
                  </span>
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
    </section>
  );
}

export default CreateInvitation;