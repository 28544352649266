import React ,{ useEffect, useState}  from 'react'
import ReactPaginate from 'react-paginate';
import {AiFillStar} from 'react-icons/ai';
import Select, { components } from 'react-select';
import clsx from "clsx"; 
import axios from 'axios';
import {useForm , Controller} from "react-hook-form";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination } from "swiper/modules";
import venue from "../../assets/images/venus/venues.png";
import filterIcon from "../../assets/images/venus/icons/Icon.svg";
import { Link } from 'react-router-dom';
import { HiOutlineViewGrid } from 'react-icons/hi';
import { TfiViewListAlt } from 'react-icons/tfi';
import {toast} from "react-toastify";
import Loader from "../../components/Loader";
import Info from "../Info";
import { useNavigate} from "react-router-dom";
import { FaHeart, FaRegHeart, FaSearch } from "react-icons/fa";
import resetSearch from "../../assets/images/venus/icons/reset-search.svg"
import { states_json } from '../../data';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input } from '@mui/material';
import {Checkbox, Typography} from "@material-tailwind/react";
import {useTranslation} from "react-i18next";



function WeddingVenues() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const baseStyle = 
  "text-[#FE6972] text-sm font-normal leading-[21px] h-12 px-[18px] bg-white rounded-[10px] border border-neutral-400 justify-start items-center gap-2.5 inline-flex";

  const orderStyle = 
  "w-44 text-[#FE6972] text-sm font-normal leading-[21px] h-12 px-[18px] bg-white rounded-[10px] border border-neutral-400 justify-start items-center gap-2.5 inline-flex";

  const menuStyle = 
  "border rounded-md bg-white text-[13px] hover:cursor-pointer px-4 py-2 whitespace-nowrap";

  const optionStyles = { 
    focus: "hover:cursor-pointer  hover:text-red", 
    Selected: "bg-slate-300", 
  };

  const {
    watch,
    control,
    register,
    setValue,
    handleSubmit,
    reset
  } = useForm();

  const [categories , setCategories] = useState({ loading: true, data: [] })
  const [countries , setCountries] = useState({ loading: true, data: [] })
  const [states , setStates] = useState({ loading: false, data: [] })
  const [cities , setCities] = useState({ loading: false, data: [] })
  const [facility , setFacility] = useState({ loading: true, data: [] })
  const [sizes , setSizes] = useState({ loading: true, data: {} })

  const [venues, setVenues] = useState({ loading: true, data: [] });
  const [query, setQuery] = useState({ type: null, size: null, country: null,  state: null, condition: null , rating : null , budget :null , order :null /*page: 1, limit: 10*/ });

  useEffect(() => {
    axios.post('/api/venue-sizes', {}).then((res) => {
      console.log("venue sizes", res.data);

      if (!res.data?.result?.success) {
        toast.error("Error while fetching venue sizes!");
        return;
      }

      setSizes(prevState => ({
        ...prevState,
        loading: false,
        data: res.data?.result?.venueSizes ?? {}
      }))

      console.log(sizes.data)
    }).catch((err) => {
      console.log(err);
    })

    axios.post('/api/country', {}).then((res) => {
      console.log("countries", res.data);

      if (!res.data?.result?.success) {
        toast.error("Error while fetching countries!");
        return;
      }

      setCountries(prevState => ({
        ...prevState,
        loading: false,
        data: res.data?.result?.countries ?? []
      }))
    }).catch((err) => {
      console.log(err);
    })

    axios.post('/api/facility-types', {}).then((res) => {
      console.log("facility types", res.data);

      if (!res.data?.result?.success) {
        toast.error("Error while fetching facility types");
        return;
      }

      setCategories(prevState => ({
        ...prevState,
        loading: false,
        data: res.data?.result?.facilityTypes?? []
      }))

    }).catch((err) => {
      console.log(err);
    })

  }, []);

  const country = watch("country");
  const state = watch("state");

  useEffect(() => {
    setValue("state", null);
    setValue("city", null);

    if (!country) {
      setStates(prevState => ({
        ...prevState,
        data: []
      }))
      setCities(prevState => ({
        ...prevState,
        data: []
      }))
      return;
    }

    setStates(prevState => ({
      ...prevState,
      loading: true,
    }));

    axios.post(`/api/country/${country.value}`, {}).then((res) => {
      console.log("states", res.data);

      if (!res.data?.result?.success) {
        toast.error("Error while fetching states!");
        return;
      }

      setStates(prevState => ({
        ...prevState,
        loading: false,
        data: res.data?.result?.countries ?? [] // TODO: fix naming in backend from countries to states
      }))
    }).catch((err) => {
      console.log(err);
    })
  }, [country]);

  useEffect(() => {
    setValue("city", null)

    if (!state) {
      setCities(prevState => ({
        ...prevState,
        data: []
      }))
      return;
    }

    setCities(prevState => ({
      ...prevState,
      loading: true,
    }));

    axios.post(`/api/state/${state.value}`, {}).then((res) => {
      console.log("cities", res.data);

      if (!res.data?.result?.success) {
        toast.error("Error while fetching cities!");
        return;
      }

      setCities(prevState => ({
        ...prevState,
        loading: false,
        data: res.data?.result?.state.cities ?? []
      }))
    }).catch((err) => {
      console.log(err);
    })
  }, [state]);

  const [pageCount, setPageCount] = useState(1); 
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(9);

  const handlePageClick = (event) => {
    const newPageNumber = event.selected + 1; 
    setCurrentPage(newPageNumber);
  };

  useEffect(() => {
    setVenues(prevState => ({
      ...prevState,
      loading: true,
    }));

    const data = {
      countryId: query.country || "",
      stateId: query.state || "",
      typeId: query.type || "",
      cityId: query.city || "",
      capacity: query.size || "",
      indoor : query.indoor || "",
      outdoor : query.outdoor || "",
      rating : query.rating || "",
      budget : query.budget || "",
      order: query.order || "",
      pageNumber: currentPage,
      pageSize: itemsPerPage,
    };

    console.log("request sent to server", data);

    axios.post('/api/facilities', data).then((res) => {
      console.log("facilities", res.data);

      if (!res.data?.result?.success) {
        toast.error("Error while fetching facilities!");
        return;
      }

      setVenues(prevState => ({
        ...prevState,
        loading: false,
        data: res.data?.result?.facilities ?? []
      }))
      setPageCount(res.data?.result?.totalPages || 1);
    }).catch((err) => {
      console.log(err);
    })

  }, [query, currentPage]);

  const onSubmit = (data)=> {
    console.log("data",data)
    setOpen(false)
    
    setQuery({
      type: data.category?.value,
      size: data.size,
      country: data.country?.value,
      state: data.state?.value,
      condition : data.condition?.value,
      city: data.city?.value,
      indoor: data.condition?.value.indoor,
      outdoor: data.condition?.value.outdoor,
      rating : data.rating?.value,
      budget : data.budget,
    })
  }

  const defaultValues = {
    category: "",
    country: "",
    state:"",
    city: "",
    facility:"",
    size: "",
    outdoor : "",
    indoor : "",
    rating : "",
    budget : "",
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [showFullDescription , setShowFullDescripton] = useState(false);
  const [fullDescription , setFullDescription] = useState();

  const toggleDescription = (e , fullDescription) => {
    e.stopPropagation()
    setShowFullDescripton(!showFullDescription);
    setFullDescription(fullDescription)
  }

  function selectOrder(input) {
    if(input !== null){
      setQuery({
        order: input.value ,
      })
    }
  }
  
  const stars =[(<div><AiFillStar className="text-base text-[#FFC200]"/></div>) , 
                (<div className="flex gap-2">
                  <AiFillStar className="text-base text-[#FFC200]"/>
                  <AiFillStar className="text-base text-[#FFC200]"/> 
                  </div>),
                (<div className="flex gap-2">
                  <AiFillStar className="text-base text-[#FFC200]"/>
                  <AiFillStar className="text-base text-[#FFC200]"/> 
                  <AiFillStar className="text-base text-[#FFC200]"/> 
                  </div>),
                (<div className="flex gap-2">
                  <AiFillStar className="text-base text-[#FFC200]"/>
                  <AiFillStar className="text-base text-[#FFC200]"/> 
                  <AiFillStar className="text-base text-[#FFC200]"/> 
                  <AiFillStar className="text-base text-[#FFC200]"/> 
                  </div>),
                (<div className="flex gap-2">
                  <AiFillStar className="text-base text-[#FFC200]"/>
                  <AiFillStar className="text-base text-[#FFC200]"/> 
                  <AiFillStar className="text-base text-[#FFC200]"/> 
                  <AiFillStar className="text-base text-[#FFC200]"/> 
                  <AiFillStar className="text-base text-[#FFC200]"/> 
                  </div>)
]
  const orders = [{label : t("Top Rated") , value : "rating"} ,{label : t("Price : High to Low") , value :"price_za"},  {label : t("Price : Low to High") , value :"price_az"}];
  const condtions = [{label : t("Any") , value : {indoor : false , outdoor : false} } ,{label : t("Indoor") , value : {indoor : true , outdoor : false} } ,{label : t("Outdoor") , value : {indoor : false , outdoor : true}},  {label : t("Indoor and Outdoor") , value : {indoor : true , outdoor : true}}];
  const ratings = [{ value: 1, label: stars[0]},{value: 2, label: stars[1]}, {value: 3, label: stars[2]} ,{value: 4, label: stars[3]}, {value: 5, label: stars[4]}]
  
  return (
    <>
      <Swiper
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={true}
        modules={[Autoplay, Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className="relative">
            <img className="w-full" src={venue} alt="home" />
            <div className="absolute bottom-[20%] p-5 lg:bottom-[40%] w-full flex flex-col items-center gap-4">
              <p className="text-center text-[#FAF5EE] text-lg sm:text-3xl md:text-5xl font-bold ">
                {t("Find Your Ideal Venue Here")}
              </p>
              <p className="hidden md:block text-orange-50 text-base font-normal leading-normal">
                {t("Designing Settings for Lifelong Memories")}
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="relative">
            <img className="w-full" src={venue} alt="home" />
            <div className="absolute bottom-[20%] p-5 lg:bottom-[40%] w-full flex flex-col items-center gap-4">
              <p className="text-center text-[#FAF5EE] text-lg sm:text-3xl md:text-5xl font-bold ">
              {t("Find Your Ideal Venue Here")}
              </p>
              <p className="hidden md:block text-orange-50 text-base font-normal leading-normal">
              {t("Designing Settings for Lifelong Memories")}
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="relative">
            <img className="w-full" src={venue} alt="home" />
            <div className="absolute bottom-[20%] p-5 lg:bottom-[40%] w-full flex flex-col items-center gap-4">
              <p className="text-center text-[#FAF5EE] text-lg sm:text-3xl md:text-5xl font-bold ">
              {t("Find Your Ideal Venue Here")}
              </p>
              <p className="hidden md:block text-orange-50 text-base font-normal leading-normal">
              {t("Designing Settings for Lifelong Memories")}
              </p>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>

      <section className="px-5 xl:px-16 py-10">
        {showFullDescription && (
          <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-10 bg-gray-800 bg-opacity-50">
            <div className="bg-white p-8 rounded-2xl border-4 border-red w-1/2">
              <p>{fullDescription}</p>
              <div className="flex justify-center">
                <button
                  className="h-[42px] px-[18px] py-2.5 rounded-[10px] shadow border border-red justify-center items-center gap-2.5 inline-flex mt-4"
                  onClick={toggleDescription}
                >
                  <span className="text-center text-red text-base font-normal  leading-tight">
                    {" "}
                    {t("Close")}
                  </span>
                </button>
              </div>
            </div>
          </div>
        )}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="absolute xl:w-1/4 md:w-1/2 w-3/4 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 px-8 py-6 bg-white rounded-2xl shadow flex-col justify-center items-start gap-6 inline-flex">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="flex-col justify-center items-start gap-6 inline-flex w-full"
            >
              <div className="self-stretch justify-end items-center gap-6 inline-flex">
                <button
                  onClick={() => setOpen(false)}
                  className="self-stretch justify-between items-center flex"
                >
                  <FontAwesomeIcon
                    icon={faX}
                    className="text-2xl text-gray-500"
                  />
                </button>
              </div>
              <div className="self-stretch justify-between items-center inline-flex">
                <div className="text-gray-dark text-2xl font-medium">
                  {t("Filter")}
                </div>
                <button
                  type="submit"
                  onClick={() => reset(defaultValues)}
                  className="text-red text-base font-normal"
                >
                  {t("Reset")}
                </button>
              </div>
              <div className="flex flex-col gap-4 w-full">
                {categories.loading && <Loader slim />}
                {!categories.loading && (
                  <Controller
                    control={control}
                    name="category"
                    render={({ field, field: { name, ref } }) => {
                      return (
                        <Select
                          placeholder={t("Type")}
                          classNames={{
                            input: () => "[&_input:focus]:ring-0",
                            menu: () => menuStyle,
                            option: ({ isFocused, isSelected }) =>
                              clsx(
                                isFocused && optionStyles.focus,
                                isSelected && optionStyles.Selected
                              ),
                            control: () => baseStyle,
                          }}
                          unstyled
                          isClearable
                          isSearchable
                          options={categories.data.map((category) => ({
                            value: category.id,
                            label: category.name,
                          }))}
                          name={name}
                          ref={ref}
                          {...field}
                        />
                      );
                    }}
                  />
                )}

                {countries.loading && <Loader slim />}
                {!countries.loading && (
                  <Controller
                    control={control}
                    name="country"
                    render={({ field, field: { name, ref } }) => {
                      return (
                        <Select
                          placeholder={t("Country")}
                          classNames={{
                            input: () => "[&_input:focus]:ring-0",
                            menu: () => menuStyle,
                            option: ({ isFocused, isSelected }) =>
                              clsx(
                                isFocused && optionStyles.focus,
                                isSelected && optionStyles.Selected
                              ),
                            control: () => baseStyle,
                          }}
                          unstyled
                          isClearable
                          isSearchable
                          options={countries.data.map((country) => ({
                            value: country.id,
                            label: country.name,
                          }))}
                          name={name}
                          ref={ref}
                          {...field}
                        />
                      );
                    }}
                  />
                )}
                {states.loading && <Loader slim />}
                {!states.loading && (
                  <Controller
                    control={control}
                    name="state"
                    render={({ field, field: { name, ref } }) => {
                      return (
                        <Select
                          placeholder={t("State")}
                          classNames={{
                            input: () => "[&_input:focus]:ring-0",
                            menu: () => menuStyle,
                            option: ({ isFocused, isSelected }) =>
                              clsx(
                                isFocused && optionStyles.focus,
                                isSelected && optionStyles.Selected
                              ),
                            control: () => baseStyle,
                          }}
                          unstyled
                          isClearable
                          isSearchable
                          options={states.data.map((state) => ({
                            value: state.id,
                            label: state.name,
                          }))}
                          name={name}
                          ref={ref}
                          {...field}
                        />
                      );
                    }}
                  />
                )}
                {cities.loading && <Loader slim />}
                {!cities.loading && (
                  <Controller
                    control={control}
                    name="city"
                    render={({ field, field: { name, ref } }) => {
                      return (
                        <Select
                          placeholder={t("City")}
                          classNames={{
                            input: () => "[&_input:focus]:ring-0",
                            menu: () => menuStyle,
                            option: ({ isFocused, isSelected }) =>
                              clsx(
                                isFocused && optionStyles.focus,
                                isSelected && optionStyles.Selected
                              ),
                            control: () => baseStyle,
                          }}
                          unstyled
                          isClearable
                          isSearchable
                          options={cities.data.map((category) => ({
                            value: category.id,
                            label: category.name,
                          }))}
                          name={name}
                          ref={ref}
                          {...field}
                        />
                      );
                    }}
                  />
                )}

                {sizes.loading && <Loader slim />}
                {!sizes.loading && (
                  <Controller
                    control={control}
                    name="size"
                    render={({ field, field: { name, ref } }) => {
                      return (
                        <input
                          placeholder={t("Size")}
                          type="number"
                          classNames={{
                            input: () => "[&_input:focus]:ring-0",
                            menu: () => menuStyle,
                            option: ({ isFocused, isSelected }) =>
                              clsx(
                                isFocused && optionStyles.focus,
                                isSelected && optionStyles.Selected
                              ),
                            control: () => baseStyle,
                          }}
                          unstyled
                          isClearable
                          isSearchable
                          options={Object.entries(sizes.data).map((size) => ({
                            value: size[0],
                            label: size[1],
                          }))}
                          name={name}
                          ref={ref}
                          {...field}
                          className="border border-neutral-400 rounded-[10px] h-12 bg-white hover:cursor-pointer px-4 py-2 placeholder:text-red placeholder:text-sm"
                        />
                      );
                    }}
                  />
                )}

                <Controller
                  control={control}
                  name="condition"
                  render={({ field, field: { name, ref } }) => {
                    return (
                      <Select
                        placeholder={t("Condition")}
                        classNames={{
                          input: () => "[&_input:focus]:ring-0",
                          menu: () => menuStyle,
                          option: ({ isFocused, isSelected }) =>
                            clsx(
                              isFocused && optionStyles.focus,
                              isSelected && optionStyles.Selected
                            ),
                          control: () => baseStyle,
                        }}
                        unstyled
                        isClearable
                        isSearchable
                        options={condtions.map((condition) => ({
                          value: condition.value,
                          label: condition.label,
                        }))}
                        name={name}
                        ref={ref}
                        {...field}
                      />
                    );
                  }}
                />
                <Controller
                  control={control}
                  name="rating"
                  render={({ field, field: { name, ref } }) => {
                    return (
                      <Select
                        placeholder={t("Rating")}
                        classNames={{
                          input: () => "[&_input:focus]:ring-0",
                          menu: () => menuStyle,
                          option: ({ isFocused, isSelected }) =>
                            clsx(
                              isFocused && optionStyles.focus,
                              isSelected && optionStyles.Selected
                            ),
                          control: () => baseStyle,
                        }}
                        unstyled
                        isClearable
                        isSearchable
                        options={ratings}
                        name={name}
                        ref={ref}
                        {...field}
                      />
                    );
                  }}
                />
                <Controller
                  control={control}
                  name="budget"
                  render={({ field, field: { name, ref } }) => {
                    return (
                      <input
                        placeholder={t("Budget")}
                        type="number"
                        classNames={{
                          input: () => "[&_input:focus]:ring-0",
                          menu: () => menuStyle,
                          option: ({ isFocused, isSelected }) =>
                            clsx(
                              isFocused && optionStyles.focus,
                              isSelected && optionStyles.Selected
                            ),
                          control: () => baseStyle,
                        }}
                        unstyled
                        isClearable
                        isSearchable
                        name={name}
                        ref={ref}
                        {...field}
                        className="border border-neutral-400 rounded-[10px] h-12 bg-white hover:cursor-pointer px-4 py-2 placeholder:text-red placeholder:text-sm"
                      />
                    );
                  }}
                />
              </div>

              <button
                type={"submit"}
                className="self-stretch h-12 px-[18px] py-[6.40px] bg-red rounded-[10px] shadow border border-white justify-center items-center gap-2.5 inline-flex"
              >
                <div className="w-20 self-stretch py-1 justify-center items-center gap-2.5 flex">
                  <div className="text-center text-white text-base font-normal font-['Roboto'] leading-normal">
                    {t("Apply Filter")}
                  </div>
                </div>
              </button>
            </form>
          </Box>
        </Modal>
        <div className="flex justify-center mb-8">
          <form
            className="w-full px-3 pt-3 pb-2 xl:px-6 xl:pt-6 xl:pb-2 bg-white rounded-[10px] shadow border gap-3 flex flex-wrap sm:flex-nowrap items-start justify-center"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="grid gap-3 grid-cols-2 sm:grid-cols-4 w-full">
              {categories.loading && <Loader slim />}
              {!categories.loading && (
                <Controller
                  control={control}
                  name="category"
                  render={({ field, field: { name, ref } }) => {
                    return (
                      <Select
                        placeholder={t("Type")}
                        classNames={{
                          input: () => "[&_input:focus]:ring-0",
                          menu: () => menuStyle,
                          option: ({ isFocused, isSelected }) =>
                            clsx(
                              isFocused && optionStyles.focus,
                              isSelected && optionStyles.Selected
                            ),
                          control: () => baseStyle,
                        }}
                        unstyled
                        isClearable
                        isSearchable
                        options={categories.data.map((category) => ({
                          value: category.id,
                          label: category.name,
                        }))}
                        name={name}
                        ref={ref}
                        {...field}
                      />
                    );
                  }}
                />
              )}

              {countries.loading && <Loader slim />}
              {!countries.loading && (
                <Controller
                  control={control}
                  name="country"
                  render={({ field, field: { name, ref } }) => {
                    return (
                      <Select
                        placeholder={t("Country")}
                        classNames={{
                          input: () => "[&_input:focus]:ring-0",
                          menu: () => menuStyle,
                          option: ({ isFocused, isSelected }) =>
                            clsx(
                              isFocused && optionStyles.focus,
                              isSelected && optionStyles.Selected
                            ),
                          control: () => baseStyle,
                        }}
                        unstyled
                        isClearable
                        isSearchable
                        options={countries.data.map((country) => ({
                          value: country.id,
                          label: country.name,
                        }))}
                        name={name}
                        ref={ref}
                        {...field}
                      />
                    );
                  }}
                />
              )}
              {states.loading && <Loader slim />}
              {!states.loading && (
                <Controller
                  control={control}
                  name="state"
                  render={({ field, field: { name, ref } }) => {
                    return (
                      <Select
                        placeholder={t("State")}
                        classNames={{
                          input: () => "[&_input:focus]:ring-0",
                          menu: () => menuStyle,
                          option: ({ isFocused, isSelected }) =>
                            clsx(
                              isFocused && optionStyles.focus,
                              isSelected && optionStyles.Selected
                            ),
                          control: () => baseStyle,
                        }}
                        unstyled
                        isClearable
                        isSearchable
                        options={states.data.map((category) => ({
                          value: category.id,
                          label: category.name,
                        }))}
                        name={name}
                        ref={ref}
                        {...field}
                      />
                    );
                  }}
                />
              )}
              {cities.loading && <Loader slim />}
              {!cities.loading && (
                <Controller
                  control={control}
                  name="city"
                  render={({ field, field: { name, ref } }) => {
                    return (
                      <Select
                        placeholder={t("City")}
                        classNames={{
                          input: () => "[&_input:focus]:ring-0",
                          menu: () => menuStyle,
                          option: ({ isFocused, isSelected }) =>
                            clsx(
                              isFocused && optionStyles.focus,
                              isSelected && optionStyles.Selected
                            ),
                          control: () => baseStyle,
                        }}
                        unstyled
                        isClearable
                        isSearchable
                        options={cities.data.map((category) => ({
                          value: category.id,
                          label: category.name,
                        }))}
                        name={name}
                        ref={ref}
                        {...field}
                      />
                    );
                  }}
                />
              )}
            </div>

            <button
              onClick={() => reset(defaultValues)}
              title="Reset"
              type="submit"
              className="h-12 px-[18px] py-4 rounded-[10px] border border-neutral-400 justify-center items-center gap-2.5 inline-flex"
            >
              <img src={resetSearch} alt="resetSearch" />
            </button>
            <div className="flex flex-col justify-center gap-2">
              <button
                type={"submit"}
                className="h-12 px-[18px] py-[6.40px] bg-red rounded-[10px] shadow border border-white justify-center items-center gap-2.5 inline-flex"
              >
                <span className="text-center text-white text-sm sm:text-base font-normal leading-normal flex gap-[10px] items-center whitespace-nowrap">
                  {t("Search Now")} <FaSearch />
                </span>
              </button>
              <button
              type='button'
                onClick={handleOpen}
                className="text-center text-red text-base font-normal font-['Roboto'] leading-normal"
              >
                {t("Advanced Search")}
              </button>
            </div>
          </form>
        </div>
        <div className="flex justify-between items-center mb-6">
          <div className="text-red text-2xl md:text-4xl font-medium">
            {t("All Venues")}
          </div>

          <div className="gap-4 items-center flex">
            <Select
              defaultValue={orders[0].value}
              onChange={selectOrder}
              placeholder={t("Preferences")}
              classNames={{
                input: () => "[&_input:focus]:ring-0",
                menu: () => menuStyle,
                option: ({ isFocused, isSelected }) =>
                  clsx(
                    isFocused && optionStyles.focus,
                    isSelected && optionStyles.Selected
                  ),
                control: () => orderStyle,
              }}
              unstyled
              isClearable
              isSearchable
              options={orders.map((order) => ({
                value: order.value,
                label: order.label,
              }))}
            />
          </div>
        </div>
        {venues.loading && <Loader />}
        <div className="gap-10 mb-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          {!venues.loading &&
            venues.data.map(
              ({
                id,
                name,
                rating,
                category,
                country,
                image,
                description,
                venuesCount,
                reviewsCount,
                minimum_price,
                city,
              }) => (
                <div
                  onClick={() => navigate(`/facility/${id}`)}
                  key={id}
                  className="border rounded-[10px] p-4 cursor-pointer hover:shadow-lg "
                >
                  <div className="relative mb-6">
                    <img
                      className="mb-4 object-cover w-full h-72"
                      src={image}
                      alt=""
                    />
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="mb-2 text-gray-dark text-xl font-bold leading-[28.97px]">
                      {name}
                    </div>
                    <div className=" text-zinc-500 text-xs font-semibold leading-tight tracking-wide">
                      {city.name}
                    </div>
                  </div>
                  <div className="w-full justify-between items-center inline-flex mb-4">
                    <div className="justify-start items-center gap-4 flex">
                      <div className="justify-start items-center gap-1 flex border-e-2 pe-4">
                        <div className="text-zinc-950 text-sm font-normal leading-[21px]">
                          {reviewsCount}
                        </div>
                        <div className="text-[#666666] text-sm font-normal leading-[21px]">
                          {t("Reviews")}
                        </div>
                      </div>
                      <div className="justify-start items-center gap-1 flex">
                        <div>
                          <AiFillStar className="text-[12px] text-[#FFC200]" />
                        </div>
                        <p className="text-zinc-950 text-sm font-normal leading-[21px]">
                          {Math.round(rating * 10) / 10}
                        </p>
                      </div>
                    </div>
                    <div className="text-center text-zinc-500 text-xs font-semibold leading-tight tracking-wide">
                      ( {venuesCount} {t('Venues')} )
                    </div>
                  </div>
                  {/* <p className="description text-zinc-500 text-sm">
                    {description}
                  </p> */}
                  <div className="description text-zinc-500 text-sm">
                    <p className={`overflow-hidden line-clamp-2`}>
                      {description}
                    </p>
                    <div className="flex items-center justify-between">
                      {!showFullDescription && (
                        <button
                          className="text-red text-end pt-4"
                          onClick={(e) => toggleDescription(e, description)}
                        >
                        {t("Read More")}
                        </button>
                      )}
                      <div className="text-zinc-500 text-sm font-semibold pt-4">
                        {t("Price from")} :{" "}
                        <span className="text-red text-base">
                          {minimum_price} EGP
                        </span>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              )
            )}
        </div>

        {!venues.loading && venues.data.length < 1 && (
          <Info
            msg={"No found Venues."}
            description={"Please refresh or remove filters to show results."}
          />
        )}

        <ReactPaginate
          breakLabel="..."
          nextLabel={t("Next >")}
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          pageCount={pageCount}
          previousLabel={t("< Previous")}
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          pageLinkClassName="page-num"
          previousLinkClassName="page-btn"
          nextLinkClassName="page-btn"
          activeLinkClassName="active"
          disabledLinkClassName="disabled-btn"
        />
      </section>
    </>
  );
}

export default WeddingVenues
