import React, { useState } from 'react';
import logo from "../../assets/images/rose-bazaar.png"
import { Link , useLocation } from 'react-router-dom';
import { FaArrowRight, FaLinkedinIn, FaFacebookF, FaTwitter  } from "react-icons/fa";
import {useTranslation} from "react-i18next";
import TermsAndConditions from '../../views/content/TermsAndConditions';
import PrivacyPolicy from '../../views/content/PrivacyPolicy';


function Footer({ }) {


  const { t, i18n } = useTranslation();
  const location = useLocation();

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const [open, setOpen] =useState(false);
  const handleClose = () => setOpen(false); 
  const [openPrivacy, setOpenPrivacy] =useState(false);
  const handleClosePrivacy = () => setOpenPrivacy(false); 
  return (
      <footer className="mt-auto">
        <TermsAndConditions open={open} handleClose={handleClose} />
        <PrivacyPolicy open={openPrivacy} handleClose={handleClosePrivacy} />
        <div className="bg-red pt-10 xl:pt-10 px-5 xl:px-10 flex flex-wrap pb-6 gap-4 sm:gap-0 ">
          <div className='w-full sm:w-1/2 lg:w-1/3 mb-5 xl:mb-0'>
            <div className="w-fit mb-4 flex flex-col items-center">
              <img src={logo} alt='' className="mx-auto w-16"/>
              <div className="text-white text-[12px] md:text-[14px] font-medium font-['Roboto'] uppercase leading-[16.80px] tracking-wider">
                  Rose Bazaar
              </div>
            </div>
            <p className="text-xl font-light text-white mb-2">+2 1#########</p>
            <p className="text-xl font-light text-white">support@########.com</p>
          </div>
          <div className='w-full sm:w-1/2 lg:w-2/3 mb-5 xl:mb-0'>
            <h4 className="text-white text-2xl font-medium leading-7 mb-5">{t("Quick Links")}</h4>
            <ul className="columns-3">
              <li className="text-base font-light text-white mb-4"><Link to={'/'} onClick={scrollToTop}>{t("Home")}</Link></li>
              <li className="text-base font-light text-white mb-4"><Link to={'/faq'} onClick={scrollToTop}>{t("FAQ")}</Link></li>
              <li className="text-base font-light text-white mb-4"><Link to={'/about'} onClick={scrollToTop}>{t("About Us")}</Link></li>
              <li className="text-base font-light text-white mb-4"><Link to={'/contact'} onClick={scrollToTop}>{t("Contact Us")}</Link></li>
              <li className="text-base font-light text-white mb-4"><Link onClick={() => setOpen(true)}>{t("Terms and Conditions")}</Link></li>
              <li className="text-base font-light text-white mb-4"><Link onClick={() => setOpenPrivacy(true)}>{t("Privacy Policy")}</Link></li>
            </ul>
          </div>

        </div>
        <div className="bg-red px-5 xl:px-12 pt-5 pb-5 flex flex-wrap sm:flex-nowrap justify-center gap-5 sm:justify-between">
          <ul className="flex gap-2 ">
            <li className="border border-white rounded-full text-white w-8 h-8 flex items-center justify-center"><Link><FaLinkedinIn /></Link></li>
            <li className="border border-white rounded-full text-white w-8 h-8 flex items-center justify-center"><Link><FaFacebookF  /></Link></li>
            <li className="border border-white rounded-full text-white w-8 h-8 flex items-center justify-center"><Link><FaTwitter  /></Link></li>
          </ul>
          <p className="text-white ">{t("© 2023 Rose bazaar . All rights reserved")}</p>
        </div>
        
      </footer>
  );
}

export default Footer;