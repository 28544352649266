import React, { useState, useEffect } from 'react';
import { addHours } from 'date-fns';
import {useTranslation} from "react-i18next";

const Ticker = ({ confirmedDate , setIsCountdownFinished }) => {
  const { t, i18n } = useTranslation();
  const [timeLeft, setTimeLeft] = useState('');

  useEffect(() => {
    const startOfConfirmedDate = new Date(confirmedDate);
    const targetTime = addHours(startOfConfirmedDate, 51); // Add 51 hours

    const updateTimer = () => {
      const currentTime = new Date();
      const distanceInSeconds = Math.max(0, Math.floor((targetTime - currentTime) / 1000));

      if (distanceInSeconds === 0) {
        setIsCountdownFinished(true);
      }

      const remainingHours = Math.floor(distanceInSeconds / 3600);
      const remainingMinutes = Math.floor((distanceInSeconds % 3600) / 60);
      const remainingSeconds = distanceInSeconds % 60;

      setTimeLeft(
        <p className='text-nowrap'>
          <span className="text-red md:text-xl  text-base font-bold">{String(remainingHours).padStart(2, '0')}<span className="text-red md:text-base text-sm font-normal">{t("Hr")}</span></span> :{' '}
          <span className="text-red md:text-xl text-base font-bold">{String(remainingMinutes).padStart(2, '0')}<span className="text-red md:text-base text-sm font-normal">{t("Min")}</span></span> :{' '}
          <span className="text-red md:text-xl  text-base font-bold">{String(remainingSeconds).padStart(2, '0')}<span className="text-red md:text-base text-sm font-normal">{t("Sec")}</span></span>
        </p>
      );
    };

    updateTimer();
    const intervalId = setInterval(updateTimer, 1000);

    return () => clearInterval(intervalId);
  }, [confirmedDate]);

  return <div>{timeLeft}</div>;
};

export default Ticker;
