import React from "react";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Modal } from "@mui/material";
import {useTranslation} from "react-i18next";
import { Link } from "react-router-dom";

function TermsAndConditions({open , handleClose }) {
  const { t, i18n } = useTranslation();
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="absolute h-[70%] overflow-y-scroll  lg:w-[30%] w-[80%] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 px-8 py-6 bg-white rounded-2xl shadow flex-col justify-center items-start inline-flex border-4 border-red">
        <div className="w-full h-full">
          <div className="flex justify-end">
            <button
              onClick={() => handleClose()}
              className="self-stretch justify-between items-center flex"
            >
              <FontAwesomeIcon icon={faX} className="text-base text-gray-500" />
            </button>
          </div>
          {i18n.language === "en" && (
            <section className="">
              <div className="flex flex-col gap-2 mb-10">
                <div className="text-red text-lg font-bold text-center mb-5">
                  Terms and Conditions
                </div>
                <p className="underline text-base font-medium">
                  Please read these terms and conditions carefully.
                </p>
                <p className="text-sm font-normal">
                  These Terms and Conditions, as well as our Privacy Policy, and
                  any other terms, conditions, notices and disclaimers contained
                  elsewhere on this site, www.Rose-Bazaar.com  ('Website
                  hereinafter') govern your access to and use of this Website.
                  By continuing to use and/or browse this website and/or any
                  services on the website, you hereby acknowledge and agree to
                  be bound by these terms and conditions. These terms and
                  conditions are subject to change and can be modified at any
                  time without prior notice. Your continued or subsequent use of
                  this website following the posting of changes to these Terms
                  and Conditions constitutes your acceptance of and agreement to
                  them. If you do not agree to these Terms and Conditions, or
                  object to any changes to them, please stop browsing or using
                  this website immediately.
                </p>
                <p className="underline text-base font-medium">
                  Intellectual Property Rights
                </p>
                <p className="text-sm font-normal">
                  This Website contains information and materials (the
                  'Materials') that are protected by intellectual property
                  rights, including, but not limited to, trademarks, copyrights
                  and rights to databases and databases of such sites, Examples
                  are not limited to digital contents , software and downloads,
                  or any combination thereof or other materials. All materials,
                  information, and content mentioned in this clause are the
                  exclusive property of the rosebazzar and are protected by
                  intellectual property rights and copyrights.
                </p>
                <p className="underline text-base font-medium">
                  Use of Information and Materials
                </p>
                <p className="text-sm font-normal">
                  All information and materials on this website, including the
                  terms, conditions and descriptions contained therein, are
                  subject to change. Not all products and services described on
                  this site are available at all times. Your eligibility for any
                  particular products and services is subject to our final
                  decision and approval. This site is intended for the personal
                  and professional use of attorneys only for all litigation
                  procedures and economic court services only and may not be
                  used for any commercial purposes, and you may not reproduce,
                  transfer, or sell any information, materials, software,
                  products or services on this website (unless you have entered
                  into a separate prior written agreement with us to that
                  effect), and you may not use the Site or its content.
                </p>
                <p className="underline text-base font-medium">
                  DISCLAIMER OF LIABILITY
                </p>
                <p className="text-sm font-normal">
                  IN NO EVENT WILL WE BE RESPONSIBLE FOR ANY DAMAGES, INCLUDING,
                  WITHOUT LIMITATION, DIRECT, INDIRECT, SPECIAL, INCIDENTAL,
                  RESULTING OR ARISING AS A RESULT OF YOUR USE OF THE WEBSITE,
                  EXCEPT FOR THE WEBSITE Any loss resulting from any reliance on
                  the information or materials contained on the website, except
                  for the information sent by each website to the electronic
                  address chosen for you or the mobile phone installed by you on
                  the website. We are also not responsible for not finding your
                  chosen e-mail address if you are among those registered in the
                  electronic record of the site 'as a plaintiff and/or an entry
                  discount' in the event of a search for your data. Adequate
                  knowledge of the information available about you in the search
                  rights available.
                </p>
                <p className="underline text-base font-medium">
                  User financial obligations
                </p>
                <p className="text-sm font-normal">
                  As a user of this website, you are obligated to pay all fees,
                  charges, costs of using the website and any other expenses in
                  order to obtain the required service within the limits set out
                  in laws, regulations, rules and regulations. In the event that
                  you do not pay the amounts owed, we have the right to withhold
                  your use of it and stop providing you with its services in the
                  way we deem fit without any liability to us and you will not
                  have the right to seek compensation for the compensation.
                </p>
                <p className="underline text-base font-medium">
                  Compensation guarantee
                </p>
                <p className="text-sm font-normal">
                  As a user of this website, you agree to indemnify us, as we
                  see fit, for or against all liabilities, costs, expenses and
                  damages arising in connection with your (i) claim or claim; or
                  (ii) use or access it for whatever or whatever purpose,
                  including in breach of the Terms and Conditions.
                </p>
                <p className="underline text-base font-medium">
                  Restrictions on use
                </p>
                <p className="text-sm font-normal">
                  As a user of this site, you acknowledge the correctness of all
                  data and information you enter, as well as the correctness of
                  the documents you upload on the site for the purpose of use by
                  yourself or by a non-legal representative or legal
                  representative. You shall be responsible for criminal and
                  civil invalidity in accordance with the provisions of the
                  organizing laws and without any liability on the website. As a
                  user of this website, you agree not to misuse this website in
                  any way; This includes, but is not limited to, defamation,
                  threat or abuse in violation of any other person's rights
                  under the law; any infringement or infringement of any
                  intellectual property, trademark, copyright, database or other
                  applicable laws or other illegal purposes; Damaging or
                  interfering with the operations of any hardware and/or
                  software by uploading, sending, or downloading computer
                  viruses or any files corrupt in this respect; or direct or
                  upload any bots, malicious software, tracking programs, or any
                  other automated process that may impair this Site or harm our
                  software, the Site's network or infrastructure. You further
                  agree not to infringe the intellectual property rights or
                  other rights of other persons
                </p>
                <p className="underline text-base font-medium">
                  severability and modification
                </p>
                <p className="text-sm font-normal">
                  We have the right to modify, add or delete any text or part of
                  a text from these Terms and Conditions at our sole discretion.
                  This condition or relevant part will be modified, added or
                  deleted, as required, from these Terms and Conditions without
                  canceling the remaining terms and conditions, and in no way
                  affect any other circumstances or conditions.
                </p>
                <p className="underline text-base font-medium">
                  Applicable Law and Competent Jurisdiction
                </p>
                <p className="text-sm font-normal">
                  By browsing this website and/or obtaining any facilities,
                  products or services offered through it, you acknowledge and
                  agree that the terms and conditions and access to and
                  provision of the facilities, products, and services are free
                  of charge. You also acknowledge and agree to submit to the
                  judicial jurisdiction of the courts (), with respect to any
                  matter, litigation or dispute arising from, or from, or in
                  connection with the breach of these Terms and Conditions.
                </p>
              </div>
              <div className="w-full flex justify-center pb-5">
                <button
                  onClick={() => handleClose()}
                  type="button"
                  className="w-[150px] h-12 px-8 py-2 rounded-lg border bg-red justify-center items-center inline-flex"
                >
                  <span className="text-white text-sm font-medium leading-[14px] tracking-wide">
                    Close
                  </span>
                </button>
              </div>
            </section>
          )}
          {i18n.language === "ar" && (
            <section className="">
              <div className="flex flex-col gap-2 mb-10">
                <div className="text-red text-lg font-bold text-center mb-5">
                  الشروط و الأحكام
                </div>
                <p className="underline text-base font-medium">
                  يرجي قراءة هذه الشروط والأحكام بعناية.
                </p>
                <p className="text-sm font-normal">
                  إنّ هذه اﻟﺸﺮوط واﻷﺣﻜﺎم، ﺑﺎﻹﺿﺎﻓﺔ إﻟﻰ ﺳﻴﺎﺳﺔ اﻟﺨﺼﻮﺻﻴﺔ اﻟﺨﺎﺻﺔ ﺑﻨﺎ،
                  وأﻳﺔ ﺷﺮوط وأﺣﻜﺎم (www.Rose-Bazaar.com )وإﺷﻌﺎرات وﺗﻨﻮﻳﻬﺎت أﺧﺮى
                  واردة ﻓﻲ ﻣﻜﺎن آﺧﺮ ﻋﻠﻰ ﻫﺬا ﻟﻤﻮﻗﻊ،  ، ('وﻳﺸﺎر إﻟﻴﻪ ﻻﺣﻘﺎً ﺑﺎﻟﻤﻮﻗﻊ
                  اﻹﻟﻜﺘﺮوﻧﻲ') ﺗﺤﻜﻢ دﺧﻮﻟﻚ واﺳـــﺘﺨﺪاﻣﻚ ﻟﻬﺬا اﻟﻤﻮﻗﻊ اﻹﻟﻜﺘﺮوﻧﻲ.
                  وﻋﺒﺮ اﻻﺳـــﺘﻤﺮار ﻓﻲ اﺳـــﺘﺨﺪام و/ أو ﺗﺼــــــﻔﺢ ﻫﺬا اﻟﻤﻮﻗﻊ و/
                  أو أﻳﺔ ﺧﺪﻣﺎت ﻋﻠﻰ اﻟﻤﻮﻗﻊ، ﻓﺈﻧﻚ ﺗﻘﺮّ وﺗﻮاﻓﻖ ﺑﻤﻮﺟﺒﻪ ﻋﻠﻰ اﻻﻟﺘﺰام
                  ﺑﻬﺬه اﻟﺸﺮوط واﻷﺣﻜﺎم. ﺗﺨﻀﻊ ﻫﺬه اﻟﺸﺮوط واﻷﺣﻜﺎم ﻟﻠﺘﻐﻴﻴﺮ وﻳﻤﻜﻦ
                  ﺗﻌﺪﻳﻠﻬﺎ ﻓﻲ أي وﻗﺖ ودون إﺷﻌﺎر ﻣﺴﺒﻖ. وإن اﺳﺘﺨﺪاﻣﻚ اﻟﻤﺴــــــﺘﻤﺮ
                  أو اﻟﻼﺣﻖ ﻟﻬﺬا اﻟﻤﻮﻗﻊ ﺑﻌﺪ ﻧﺸــــــﺮ ﺗﻠﻚ اﻟﺘﻐﻴﻴﺮات ﻋﻠﻰ ﻫﺬه
                  اﻟﺸــــــﺮوط واﻷﺣﻜﺎم ﻳﻌﻨﻲ ﻗﺒﻮﻟﻚ وﻣﻮاﻓﻘﺘﻚ ﻋﻠﻴﻬﺎ. ﻓﺈذا ﻛﻨﺖ ﻻ
                  ﺗﻮاﻓﻖ ﻋﻠﻰ ﻫﺬه اﻟﺸــــــﺮوط واﻷﺣﻜﺎم، أو ﺗﻌﺘﺮض ﻋﻠﻰ أي ﺗﻐﻴﻴﺮات
                  ﻋﻠﻴﻬﺎ، ﻳﺮﺟﻰ اﻟﺘﻮﻗﻒ ﻓﻮراً ﻋﻦ اﺳﺘﺨﺪام ﻫﺬا اﻟﻤﻮﻗﻊ أو ﺗﺼﻔﺤﻪ
                </p>
                <p className="underline text-base font-medium">
                  ﺣﻘﻮق اﻟﻤﻠﻜﻴّﺔ اﻟﻔﻜﺮﻳّﺔ
                </p>
                <p className="text-sm font-normal">
                  ﻳﺤﺘﻮي ﻫﺬا اﻟﻤﻮﻗﻊ اﻹﻟﻜﺘﺮوﻧﻲّ ﻋﻠﻰ ﻣﻌﻠﻮﻣﺎت وﻣﻮاد ('اﻟﻤﻮاد') ﻣﺤﻤﻴﺔ
                  ﺑﻤﻮﺟﺐ ﺣﻘﻮق اﻟﻤﻠﻜﻴﺔ اﻟﻔﻜﺮﻳﺔ، ﺑﻤﺎ ﻓﻲ ذﻟﻚ، وﻋﻠﻰ ﺳـــﺒﻴﻞ اﻟﻤﺜﺎل ﻻ
                  اﻟﺤﺼـــﺮ، اﻟﻌﻼﻣﺎت اﻟﺘﺠﺎرﻳﺔ وﺣﻘﻮق اﻟﻤﺆﻟﻔﻴﻦ وﺣﻘﻮق ﻗﻮاﻋﺪ
                  اﻟﺒﻴﺎﻧﺎت، ﻣﻦ ﻣﺤﺘﻮى وﻣﻮاد ﻣﺪرﺟﺔ ﻓﻲ ﻫﺬا اﻟﻤﻮﻗﻊ، ﺑﻤﺎ ﻓﻲ ذﻟﻚ، وﻋﻠﻰ
                  ﺳـﺒﻴﻞ اﻟﻤﺜﺎل ﻻ اﻟﺤﺼـﺮ، اﻟﺒﺮاﻣﺞ واﻟﺘﻨﺰﻳﻼت اﻟﺮﻗﻤﻴﺔ، أو أي ﻣﺰﻳﺞ
                  ﻣﻨﻬﺎ أو ﻣﻦ ﻣﻮاد أﺧﺮى. وﻳﻤﺜﻞ ﻛﻞّ ﻣﺎ ورد ذﻛﺮه ﻓﻲ ﻫﺬا اﻟﺒﻨﺪ ﻣﻦ
                  ﻣﻮاد وﻣﻌﻠﻮﻣﺎت وﻣﺤﺘﻮى ﻣﻠﻜﻴﺔ ﺣﺼـــﺮّﻳﺔ لشركة (روز بازار) وﻣﺤﻤﻴﺎً
                  ﺑﻤﻮﺟﺐ ﺣﻘﻮق اﻟﻤﻠﻜﻴﺔ اﻟﻔﻜﺮﻳﺔ وﺣﻘﻮق اﻟﻄﺒﻊ واﻟﻨﺸﺮ
                </p>
                <p className="underline text-base font-medium">
                  اﺳﺘﺨﺪام اﻟﻤﻌﻠﻮﻣﺎت واﻟﻤﻮاد
                </p>
                <p className="text-sm font-normal">
                  إنّ ﺟﻤﻴﻊ اﻟﻤﻌﻠﻮﻣﺎت واﻟﻤﻮاد اﻟﻮاردة ﻓﻲ ﻫﺬا اﻟﻤﻮﻗﻊ، ﺑﻤﺎ ﻓﻲ ذﻟﻚ
                  اﻟﺸــﺮوط واﻷﺣﻜﺎم واﻷوﺻــﺎف اﻟﻮاردة ﻓﻴﻪ، ﻋﺮﺿـــﺔ ﻟﻠﺘﻐﻴﻴﺮ
                  ﺑﺸـــﻜﻞٍ ﻣﺴـــﺘﻤﺮّ. وﻻ ﺗﺘﻮﻓﺮ ﺟﻤﻴﻊ اﻟﻤﻨﺘﺠﺎت واﻟﺨﺪﻣﺎت اﻟﻤﻮﺿـــﺤﺔ
                  ﻓﻲ ﻫﺬا اﻟﻤﻮﻗﻊ ﻓﻲ ﺟﻤﻴﻊ اﻷوﻗﺎت. وإن أﻫﻠﻴﺘﻚ ﻟﻠﺤﺼـــــﻮل ﻋﻠﻰ أﻳﺔ
                  ﻣﻨﺘﺠﺎت وﺧﺪﻣﺎت ﻣﻌﻴﻨﺔ ﺗﺨﻀـــــﻊ ﻟﻠﻘﺮار اﻟﻨﻬﺎﺋﻲ واﻟﻤﻮاﻓﻘﺔ اﻟﺼﺎدرة
                  ﻣﻦ ﺟﺎﻧﺒﻨﺎ. ﻫﺬا اﻟﻤﻮﻗﻊ ﻣﺨﺼــﺺ ﻟﻼﺳــﺘﺨﺪام العام ﻟﺠﻤﻴﻊ لجميع
                  الاشخاص وللاغراض التجارية وأﻧﺖ ﺑﻤﻮﺟﺒﻪ ﺗﻘﺮّ وﺗﻮاﻓﻖ ﻋﻠﻰ ﻋﺪم
                  ﺗﻌﺪﻳﻞ أو ﻧﺴــــﺦ أو ﺗﻮزﻳﻊ أو إﻋﺎدة إﻧﺘﺎج أو ﻧﻘﻞ أو ﺑﻴﻊ أي
                  ﻣﻌﻠﻮﻣﺎت أو ﻣﻮاد أو ﺑﺮاﻣﺞ أو ﻣﻨﺘﺠﺎت أو ﺧﺪﻣﺎت ﻣﻮﺟﻮدة ﻋﻠﻰ ﻫﺬا
                  اﻟﻤﻮﻗﻊ (إﻻ إذا ﻛﻨﺖ ﻗﺪ دﺧﻠﺖ ﻓﻲ اﺗﻔﺎﻗﻴﺔ ﻣﻜﺘﻮﺑ ٍﺔ ﻣﺴـــﺒﻘﺔ
                  وﻣﻨﻔﺼـــﻠﺔ ﻣﻌﻨﺎ ﺑﻬﺬا اﻟﺨﺼﻮص)، وﻻ ﻳﻤﻜﻨﻚ اﺳﺘﺨﺪام ﻣﺜﻞ ﻫﺬه
                  اﻟﻤﻌﻠﻮﻣﺎت أو اﻟﻤﻮاد ﻓﻲ اﻟﻤﻨﺸﻮرات اﻟﺨﺎﺻﺔ ﺑﻚ.
                </p>
                <p className="underline text-base font-medium">
                  إﻋﻔﺎء ﻣﻦ اﻟﻤﺴﺆوﻟﻴﺔ
                </p>
                <p className="text-sm font-normal">
                  ﻟﻦ ﻧﻜﻮن ﻣﺴــﺆوﻟﻴﻦ ﺑﺄي ﺣﺎل ﻣﻦ اﻷﺣﻮال ﻋﻦ أﻳﺔ أﺿــﺮار، ﺑﻤﺎ ﻓﻲ ذﻟﻚ
                  ﻋﻠﻰ ﺳــﺒﻴﻞ اﻟﻤﺜﺎل ﻻ اﻟﺤﺼــﺮ، اﻷﺿــﺮار اﻟﻤﺒﺎﺷﺮة أو ﻏﻴﺮ اﻟﻤﺒﺎﺷﺮة
                  أو اﻟﺨﺎﺻﺔ أو اﻟﻌﺎرﺿﺔ أو اﻟﻨﺎﺗﺠﺔ أو اﻟﻨﺎﺷﺌﺔ ﻧﺘﻴﺠﺔ اﺳﺘﺨﺪاﻣﻚ ﻟﻬﺬا
                  اﻟﻤﻮﻗﻊ، ﺑﻤﺎ ﻓﻲ ذﻟﻚ ﻋﻠﻰ ﺳــﺒﻴﻞ اﻟﻤﺜﺎل ﻻ اﻟﺤﺼــﺮ، أي ﻣﺴــﺆوﻟﻴﺔ
                  ﻋﻦ أي ﺧﺴــﺎرة ﻧﺎﺟﻤﺔ ﻋﻦ أي اﻋﺘﻤﺎد ﻋﻠﻰ اﻟﻤﻌﻠﻮﻣﺎت أو اﻟﻤﻮاد
                  اﻟﻮاردة ﻓﻲ اﻟﻤﻮﻗﻊ ، ﻋــﺪا اﻟﻤﻌﻠﻮﻣــﺎت اﻟﺘﻲ ﻳﺮﺳــــــﻠــﻪ ﻟﻜــﻞ
                  اﻟﻤﻮﻗﻊ اﻹﻟﻜﺘﺮوﻧﻲ ﻋﻠﻰ اﻟﻌﻨﻮان اﻹﻟﻜﺘﺮوﻧﻲ اﻟﻤﺨﺘﺎر ﻟﻚ او اﻟﻬﺎﺗﻒ
                  اﻟﻤﺤﻤﻮل اﻟﻤﺜﺒﺖ ﻣﻦ ﻗﺒﻠﻜﻢ ﻋﻠﻰ اﻟﻤﻮﻗﻊ اﻹﻟﻜﺘﺮوﻧﻲ. ﻛﻤﺎ ﻻ ﻧﻜﻮن
                  ﻣﺴــــﺆوﻟﻴﻦ ﻋﻦ ﻋﺪم اﻟﻌﺜﻮر ﻋﻠﻰ ﻋﻨﻮاﻧﻚ اﻹﻟﻜﺘﺮوﻧﻲ اﻟﻤﺨﺘﺎر اذا ﻛﻨﺖ
                  ﻣﻦ ﺿــــﻤﻦ اﻟﻤﻘﻴﺪﻳﻦ ﻓﻲ اﻟﺴــــــﺠﻞ اﻹﻟﻜﺘﺮوﻧﻲ اﻟﺨﺎص ﺑﺎﻟﻤﻮﻗﻊ
                  كمستخدم او كمقدم خدمة ﻓﻲ ﺣﺎﻟﺔ اﻟﺒﺤﺚ ﻋﻦ ﺑﻴﺎﻧﺎﺗﻚ ﻣﻦ ﻗﺒﻞ المستخدم
                  للموقع او مقدم خدمة اذا ﻟﻢ ﻳﻜﻦ ﻋﻠﻰ دراﻳﺔ ﻛﺎﻓﻴﺔ ﺑﺎﻟﻤﻌﻠﻮﻣﺎت
                  اﻟﻤﺘﺎﺣﺔ ﻋﻨﻚ ﻓﻲ ﺣﻘﻮق اﻟﺒﺤﺚ اﻟﻤﺘﺎﺣﺔ.
                </p>
                <p className="underline text-base font-medium">
                  اﻻﻟﺘﺰاﻣﺎت اﻟﻤﺎﻟﻴﺔ ﻟﻠﻤﺴﺘﺨﺪم
                </p>
                <p className="text-sm font-normal">
                  ﺑﺼﻔﺘﻚ ﻣﺴﺘﺨﺪﻣﺎ ﻟﻬﺬا اﻟﻤﻮﻗﻊ، ﻓﺈﻧﻚ ﺗﻠﺘﺰم ﺑﺴﺪاد ﻛﺎﻓﺔ اﻟﺮﺳﻮم
                  واﻟﻤﺼﺎرﻳﻒ وﺗﻜﺎﻟﻴﻒ اﺳﺘﺨﺪام اﻟﻤﻮﻗﻊ وأي ﻧﻔﻘﺎت أﺧﺮى ﻓﻲ ﺳـــﺒﻴﻞ
                  اﻟﺤﺼـــﻮل ﻋﻠﻰ اﻟﺨﺪﻣﺔ اﻟﻤﻄﻠﻮﺑﺔ وﻓﻘﺎ ﻟﻠﺤﺪود اﻟﻤﺒﻴﻨﺔ ﻓﻲ اﻟﻘﻮاﻧﻴﻦ
                  واﻟﻠﻮاﺋﺢ واﻟﻘﺮارات اﻟﻤﻨﻈﻤﺔ ﻟﻬﺎ واﻟﺘﻲ ﺗﻜﻮن ﻋﺮﺿﻪ ﻟﻠﺘﻐﻴﻴﺮ دون ان
                  ﻳﻜﻮن ﻟﻚ ﺣﻖ اﻻﻋﺘﺮاض ﻋﻠﻴﻬﺎ. وﻓﻰ ﺣﺎﻟﺔ ﻋﺪم ﺳــــــﺪادك ﻟﺘﻠﻚ
                  اﻟﻤﺒﺎﻟﻎ اﻟﻤﺴــــــﺘﺤﻘﺔ ﻳﺤﻖ ﻟﻨﺎ ﺣﺠﺐ اﺳــــــﺘﺨﺪاﻣﻚ ﻟﻪ ووﻗﻒ ﻣﺪك
                  ﺑﺨﺪﻣﺎﺗﻪ ﺑﺎﻟﻜﻴﻔﻴﺔ اﻟﺘﻲ ﻧﺮاﻫﺎ ﻣﻨﺎﺳـــــﺒﺔ دون أي ﻣﺴـــــﺆوﻟﻴﺔ
                  ﻋﻠﻴﻨﺎ وﻻ ﻳﺤﻖ ﻟﻚ اﻟﻤﻄﺎﻟﺒﺔ ﺑﺎﻟﺘﻌﻮﻳﺾ، ﺑﺎﻹﺿـــــﺎﻓﺔ اﻟﻰ ﻣﻄﺎﻟﺒﺘﻚ
                  ﺑﺘﻠﻚ اﻟﻤﺒﺎﻟﻎ وﻓﻘﺎ ﻷﺣﻜﺎم اﻟﻘﻮاﻧﻴﻦ اﻟﻤﻨﻈﻤﺔ ﻟﻬﺬا اﻟﺸﺄن
                </p>
                <p className="underline text-base font-medium">ﺿﻤﺎن اﻟﺘﻌﻮﻳﺾ</p>
                <p className="text-sm font-normal">
                  ﺑﺼـــﻔﺘﻚ ﻣﺴـــﺘﺨﺪﻣًﺎ ﻟﻬﺬا اﻟﻤﻮﻗﻊ، ﻓﺈﻧﻚ ﺗﻮاﻓﻖ ﻋﻠﻰ ﺗﻌﻮﻳﻀـــﻨﺎ،
                  وﺣﺴـــﺒﻤﺎ ﻧﺮاه ﻣﻨﺎﺳـــﺒﺎً ، ﻋﻦ أو ﻣﻘﺎﺑﻞ ﺟﻤﻴﻊ اﻻﻟﺘﺰاﻣﺎت
                  واﻟﺘﻜﺎﻟﻴﻒ واﻟﻨﻔﻘﺎت واﻷﺿﺮار اﻟﻨﺎﺷﺌﺔ، ﻓﻴﻤﺎ ﻳﺘﻌﻠﻖ ﺑــــــــ (1)
                  أي ﻣﻄﺎﻟﺒﺔ ﻧﺎﺗﺠﺔ ﻋﻦ اﺳﺘﺨﺪاﻣﻚ، أو ﺗﺼــّﻔﺤﻚ ﻟﻬﺬا اﻟﻤﻮﻗﻊ؛ أو (2)
                  اﺳــﺘﺨﺪاﻣﻪ أو اﻟﻮﺻــﻮل إﻟﻴﻪ أﻳﺎ ﻛﺎن أو ﻣﻬﻤﺎ ﻛﺎن اﻟﻐﺮض، ﺑﻤﺎ ﻓﻲ
                  ذﻟﻚ ﺧﺮق اﻟﺸﺮوط واﻷﺣﻜﺎم.
                </p>
                <p className="underline text-base font-medium">
                  ﻗﻴﻮد ﻋﻠﻰ اﻻﺳﺘﺨﺪام
                </p>
                <p className="text-sm font-normal">
                  ﺑﺼــــﻔﺘﻚ ﻣﺴــــﺘﺨﺪﻣﺎً ﻟﻬﺬا اﻟﻤﻮﻗﻊ، ﻓﺈﻧﻚ ﺗﻘﺮ ﺑﺼــــﺤﺔ ﻛﺎﻓﺔ
                  اﻟﺒﻴﺎﻧﺎت واﻟﻤﻌﻠﻮﻣﺎت اﻟﺘﻲ ﺗﻘﻮم ﺑﺈدﺧﺎﻟﻬﺎ وﻛﺬا ﺻﺤﺔ ﻣﺎ ﺗﻘﻮم
                  ﺑﺘﺤﻤﻴﻠﻪ ﻋﻠﻰ اﻟﻤﻮﻗﻊ ﻣﻦ بيانات او محتوى ﻣﻄﻠﻮﺑﺔ ﻟﻐﺮض اﻻﺳﺘﺨﺪام
                  ﺑﻨﻔﺴﻚ او ﺑﻮاﺳﻄﺔ شريك المحتمل، وﻓﻰ ﺣﺎﻟﺔ ﻋﺪم ﺻـــﺤﺘﻬﺎ ﺗﻜﻮن
                  ﻣﺴـــﺌﻮﻻ ﻋﻦ ﻋﺪم ﺻﺤﺘﻬﺎ قانونيا وﻓﻘﺎ ﻷﺣﻜﺎم اﻟﻘﻮاﻧﻴﻦ اﻟﻤﻨﻈﻤﺔ ودون
                  أي ﻣﺴﺌﻮﻟﻴﺔ ﻋﻠﻰ اﻟﻤﻮﻗﻊ اﻹﻟﻜﺘﺮوﻧﻲ. ﺑﺼﻔﺘﻚ ﻣﺴﺘﺨﺪﻣًﺎ ﻟﻬﺬا اﻟﻤﻮﻗﻊ ،
                  ﻓﺈﻧﻚ ﺗﻮاﻓﻖ ﻋﻠﻰ ﻋﺪم إﺳﺎءة اﺳﺘﺨﺪام ﻫﺬا اﻟﻤﻮﻗﻊ اﻹﻟﻜﺘﺮوﻧﻲ ﺑﺄي ﺣﺎل
                  ﻣﻦ اﻷﺣﻮال؛ وﻳﺸﻤﻞ ذﻟﻚ ، ﻋﻠﻰ ﺳﺒﻴﻞ اﻟﻤﺜﺎل ﻻ اﻟﺤﺼﺮ، اﻟﺘﺸﻬﻴﺮ أو
                  اﻟﺘﻬﺪﻳﺪ أو اﻹﺳﺎءة ﺑﻤﺎ ﻳﻨﺘﻬﻚ ﺣﻘﻮق أي ﺷــﺨﺺ آﺧﺮ ﺑﻤﻮﺟﺐ اﻟﻘﺎﻧﻮن؛
                  أو أي ﻣﺨﺎﻟﻔﺔ أو اﻧﺘﻬﺎك أي ﻣﻠﻜﻴﺔ ﻓﻜﺮﻳﺔ أو ﻋﻼﻣﺔ ﺗﺠﺎرﻳﺔ أو ﺣﻘﻮق
                  ﻧﺸــﺮ أو ﻗﺎﻋﺪة ﺑﻴﺎﻧﺎت أو ﻗﻮاﻧﻴﻦ أﺧﺮى ﻣﻌﻤﻮل ﺑﻬﺎ أو أﻳﺔ أﻏﺮاض
                  ﻏﻴﺮ ﻗﺎﻧﻮﻧﻴﺔ؛ إﻟﺤﺎق اﻟﻀــــــﺮر أو اﻟﺘﺪﺧﻞ ﻓﻲ ﻋﻤﻠﻴّﺎت أﻳﺔ أﺟﻬﺰة
                  و/ أو ﺑﺮاﻣﺞ ﻋﻦ ﻃﺮﻳﻖ ﺗﺤﻤﻴﻞ ﻓﻴﺮوﺳــــﺎت اﻟﺤﺎﺳــــﺐ اﻵﻟﻲ أو
                  إرﺳــــﺎﻟﻬﺎ أو ﺗﻨﺰﻳﻠﻬﺎ أو أي ﻣﻠﻔﺎت ﻓﺎﺳــﺪة ﺑﻬﺬا اﻟﺨﺼــﻮص؛ أو
                  ﺗﻮﺟﻴﻪ أو رﻓﻊ أي ﺑﺮاﻣﺞ آﻟﻴﺔ أو ﺑﺮﻣﺠﻴﺎت ﺿــﺎ ّرة أو ﺑﺮاﻣﺞ ﺗﺘﺒّﻊ
                  اﻻرﺗﺒﺎﻃﺎت أو أي ﻋﻤﻠﻴﺔ ﺗﻠﻘﺎﺋﻴﺔ أﺧﺮى ﻗﺪ ﺗﺆدي إﻟﻰ إﺿـــــﻌﺎف ﻫﺬا
                  اﻟﻤﻮﻗﻊ أو اﻹﺿـــــﺮار ﺑﺒﺮاﻣﺠﻨﺎ أو ﺷـــــﺒﻜﺔ اﻟﻤﻮﻗﻊ أو ﺑﻨﻴﺘﻪ
                  اﻷﺳﺎﺳﻴﺔ. ﻛﻤﺎ ﺗﻮاﻓﻖ ﻛﺬﻟﻚ ﻋﻠﻰ ﻋﺪم اﻧﺘﻬﺎك ﺣﻘﻮق اﻟﻤﻠﻜﻴﺔ اﻟﻔﻜﺮﻳﺔ أو
                  أﻳﺔ ﺣﻘﻮق أﺧﺮى ﻷﺷﺨﺎص آﺧﺮﻳﻦ
                </p>
                <p className="underline text-base font-medium">
                  اﻟﻤﻌﻠﻮﻣﺎت اﻟﻤﺎﻟﻴﺔ
                </p>
                <p className="text-sm font-normal">
                  إﻧّﻨﺎ ﻧﺨﻠﻲ ﻃﺮﻓﻨﺎ ﺑﺎﻟﻜﺎﻣﻞ ﻋﻦ أي ﻣﺴــــﺆوﻟﻴﺔ ﻓﻴﻤﺎ ﻳﺘﻌﻠﻖ ﺑﺪﻗﺔ
                  ﻣﻌﻠﻮﻣﺎﺗﻚ اﻟﻤﺎﻟﻴﺔ؛ ﻛﻤﺎ ﺗﻘﺮّ وﺗﻮاﻓﻖ ﺑﻤﻮﺟﺒﻪ ﻋﻠﻰ ﻋﺪم ﺗﺤﻤﻴﻠﻨﺎ أﻳﺔ
                  ﻣﺴــــﺆوﻟﻴﺔ ﺗﺠﺎﻫﻚ ﻓﻴﻤﺎ ﻳﺘﻌﻠﻖ ﺑﺄي ﻣﻌﻠﻮﻣﺎت أو ﺑﻴﺎﻧﺎت ﻣﺎﻟﻴﺔ ﻗﺪ
                  ﺗﻘﻮم ﺑﻤﺸــــﺎرﻛﺘﻬﺎ ﻣﻌﻨﺎ، وﻟﻦ ﻧﻜﻮن ﻣﺴــــــﺆوﻟﻴﻦ ﺑﺄي ﺣﺎل ﻣﻦ
                  اﻷﺣﻮال ﻋﻦ أﻳﺔ اﺿــــــﺮار ﻧﺎﺟﻤﺔ ﻋﻦ اﺳــــــﺘﺨﺪام ﻣﻨﻈﻮﻣﺔ اﻟﺪﻓﻊ
                  اﻹﻟﻜﺘﺮوﻧﻲ ﻋﻠﻰ اﻟﻤﻮﻗﻊ اﻹﻟﻜﺘﺮوﻧﻲ.
                </p>
                <p className="underline text-base font-medium">
                  إﺧﻼء اﻟﻤﺴﺆوﻟﻴﺔ اﻟﻘﺎﻧﻮﻧﻴﺔ
                </p>
                <p className="text-sm font-normal">
                  ﻧﺤﺘﻔﻆ ﺑﺤّﻘﻨﺎ ﻓﻲ اﻟﻜﺸﻒ ﻋﻦ ﻣﻌﻠﻮﻣﺎت اﻟﺘﻌﺮﻳﻒ اﻟﺸﺨﺼﻴﺔ اﻟﺨﺎﺻﺔ ﺑﻚ،
                  ﺑﻤﺎ ﻓﻲ ذﻟﻚ ﻋﻨﺪﻣﺎ ﻧﻌﺘﻘﺪ أن ﻣﺜﻞ ﻫﺬا اﻹﻓﺼــﺎح ﺿــﺮوري ﻟﺘﻄﺒﻴﻖ أو
                  ﺗﻨﻔﻴﺬ اﻟﺸــﺮوط واﻷﺣﻜﺎم اﻟﺨﺎﺻــﺔ ﺑﻨﺎ، وﺣﻤﺎﻳﺔ ﺣﻘﻮﻗﻨﺎ ﺑﻤﻮﺟﺐ
                  اﻟﻘﺎﻧﻮن و/ أو اﻻﻣﺘﺜﺎل ﻷﻳﺔ إﺟﺮاءات ﻗﻀـــــــﺎﺋﻴﺔ أو أﻣﺮ ﻣﻦ
                  اﻟﻤﺤﻜﻤﺔ أو اﻹﺟﺮاءات اﻟﻘﺎﻧﻮﻧﻴﺔ اﻟﻮاردة ﻓﻲ ﻫﺬا اﻟﻤﻮﻗﻊ اﻹﻟﻜﺘﺮوﻧﻲ،
                  أو ﻛﻤﺎ ﻳﻘﺘﻀﻴﻪ اﻟﻘﺎﻧﻮن.
                </p>
                <p className="underline text-base font-medium">
                  ﻗﺎﺑﻠﻴﺔ اﻟﻔﺼﻞ واﻟﺘﻌﺪﻳﻞ
                </p>
                <p className="text-sm font-normal">
                  ﻳﺤﻖ ﻟﻨﺎ ﺗﻌﺪﻳﻞ أو إﺿــــﺎﻓﺔ أو ﺣﺬف أي ﻧﺺ أو ﺟﺰء ﻣﻦ ﻧﺺ ﻣﻦ ﻫﺬه
                  اﻟﺸــــﺮوط واﻷﺣﻜﺎم ﺑﺎﻹرادة اﻟﻤﻨﻔﺮدة ﻣﻨﺎ. وﺳﻴﺘﻢّ ﺗﻌﺪﻳﻞ أو إﺿﺎﻓﺔ
                  أو ﺣﺬف ﻫﺬا اﻟﺸﺮط أو اﻟﺠﺰء اﻟﻤﻌﻨﻲّ ، ﺣﺴﺒﻤﺎ ﻫﻮ ﻣﻄﻠﻮب، ﻣﻦ ﻫﺬه
                  اﻟﺸﺮوط واﻷﺣﻜﺎم دون اﻟﻐﺎء اﻟﺒﻨﻮد واﻟﺸـــﺮوط اﻟﻤﺘﺒﻘﻴﺔ، وﻻ ﻳﺆﺛﺮ
                  ﺑﺄي ﺷـــﻜﻞ ﻣﻦ اﻷﺷـــﻜﺎل ﻋﻠﻰ أي ﻇﺮوف أﺧﺮى أو ﺳﺮﻳﺎن أو إﻧﻔﺎذ
                  ﻟﺒﻘﻴّﺔ اﻟﺸﺮوط واﻷﺣﻜﺎم اﻷﺧﺮى
                </p>
                <p className="underline text-base font-medium">
                  اﻟﻘﺎﻧﻮن اﻟﺴﺎري واﻟﺴﻠﻄﺔ اﻟﻘﻀﺎﺋﻴّﺔ اﻟﻤﺨﺘﺼّﺔ
                </p>
                <p className="text-sm font-normal">
                  ﻋﺒﺮ ﺗﺼـــّﻔﺢ ﻫﺬا اﻟﻤﻮﻗﻊ و/ أو اﻟﺤﺼـــﻮل ﻋﻠﻰ أﻳﺔ ﺗﺴـــﻬﻴﻼت أو
                  ﻣﻨﺘﺠﺎت أو ﺧﺪﻣﺎت ﻣﻘﺪﻣﺔ ﻣﻦ ﺧﻼﻟﻪ، ﻓﺈﻧﻚ ﺗﻘﺮّ وﺗﻮاﻓﻖ ﻋﻠﻰ أن
                  اﻟﺸـــﺮوط واﻷﺣﻜﺎم واﻟﻮﺻـــﻮل إﻟﻰ وﺗﻮﻓﻴﺮ اﻟﺘﺴـــﻬﻴﻼت واﻟﻤﻨﺘﺠﺎت
                  واﻟﺨﺪﻣﺎت، ﺗﺨﻀـــﻊ للقوانين المغمول ﺑﻬﺎ. ﻛﻤﺎ ﺗﻘﺮّ وﺗﻮاﻓﻖ
                  أﻳﻀًــﺎ ﻋﻠﻰ اﻟﺨﻀــﻮع ﻟﻼﺧﺘﺼــﺎص اﻟﻘﻀــﺎﺋﻲ ﻟﻤﺤﺎﻛﻢ (الولايات
                  المتحدة الامريكية والاتحاد الاوربى) ﻓﻴﻤﺎ ﻳﺘﻌﻠﻖ ﺑﺄي ﻣﺴــــﺄﻟﺔ
                  أو ﺗﻘﺎﺿــــﻲ أو ﻧﺰاع ﻳﻨﺸــــﺄ ﻋﻦ، أو ﻣﻦ، أو ﻓﻴﻤﺎ ﻳﺘﻌﻠﻖ ﺑﻬﺬه
                  اﻟﺸﺮوط واﻷﺣﻜﺎم أو اﺳﺘﺨﺪاﻣﻚ ﻟﻬﺬا اﻟﻤﻮﻗﻊ اﻹﻟﻜﺘﺮوﻧﻲ ﺑﺎﻟﻤﺨﺎﻟﻔﺔ
                  ﻟﻠﻐﺮض اﻟﺬى اﻧﺸﻰ ﻟﻪ
                </p>
              </div>
              <div className="w-full flex justify-center pb-5">
                <button
                  onClick={() => handleClose()}
                  type="button"
                  className="w-[150px] h-12 px-8 py-2 rounded-lg border bg-red justify-center items-center inline-flex"
                >
                  <span className="text-white text-sm font-medium leading-[14px] tracking-wide">
                    {t("Close")}
                  </span>
                </button>
              </div>
            </section>
          )}
        </div>
      </Box>
    </Modal>
  );
}

export default TermsAndConditions;
