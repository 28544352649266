import React, { useEffect, useState } from 'react'
import { FaHeart , FaRegHeart } from "react-icons/fa";
import img from "../../../assets/images/sevices/download.png";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faYoutube, faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons';
import { SlideshowLightbox } from 'lightbox.js-react';
import placholder_image from "../../../assets/images/booking/placeholder-image.jpg"
import icon from "../../../assets/images/sevices/icon.png";
import { useNavigate , useParams } from "react-router-dom";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import { toast } from 'react-toastify';
import useAxiosUserSecureConfig from '../../../hooks/useAxiosUserSecureConfig';
import Loader from '../../../components/Loader';
import Info from '../../Info';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from 'swiper/modules';
import {useTranslation} from "react-i18next";

function RequestsDetails() {
  const { t, i18n } = useTranslation();
    const dommyImgs = [
      placholder_image,
      placholder_image,
      placholder_image,
      placholder_image,
      placholder_image,
      placholder_image,
    ];
    const [images, setImages] = useState({ loading: true, data: [] });
    useEffect(() => {
      setImages({
        loading: true,
        data: dommyImgs.map((img) => ({ src: img, alt: "img" })),
      });
    }, []);
    
    const isMobile = window.innerWidth <= 375; //  for mobile 
    const isTablet =window.innerWidth  <=425;
    const isLaptop =window.innerWidth  <1024;
    const navigate = useNavigate()
    const params = useParams()
    const config = useAxiosUserSecureConfig();
    const [bookingDetails, setBookingDetails] = useState({ loading: true, data: [] });
    const [vendor , setVendor] = useState({ loading: true, data: [] })
    const [imageUrls, setImageUrls] = useState({loading : true , data :[]});

    useEffect(() => {
      axios.post(`/api/booking/${params.id}`, {}, config)
      .then((res) => {
        console.log("booking details", res.data);
        if (!res.data?.result?.success) {
          toast.error("Error while fetching booking details!");
          return;
        }
        setBookingDetails({
          loading: false,
          data: res.data?.result?.booking || [],
        });
      })
      .catch((err) => {
        console.log(err);
      });
    }, []);

    useEffect(() => {
      if(bookingDetails.data && Object.keys(bookingDetails.data).length > 0 ){
        axios.post(`/api/vendor/${bookingDetails.data.vendor_id}`, {id: bookingDetails.data.vendor_id}).then((res) => {
          console.log("vendor", res.data);
          if (!res.data?.result?.success) {
            toast.error("Error while fetching vendor!");
            return;
          }
          setVendor((prevState) => ({
            ...prevState,
            loading: false,
            data: res.data?.result?.vendor || [],
          }));
          const displayImageIds = res.data?.result?.vendor.display_image_ids || [];
            const imagePromises = displayImageIds.map(id =>
              axios.post(`/api/vendor-image/${id}`, {}, config)
                .then(response => ({
                  src: response.data?.result?.image,
                  alt: `Image ${id}`
                }))
            );
            Promise.all(imagePromises)
              .then(images => {
                setImageUrls((prevState) => ({
                  ...prevState,
                  loading: false,
                  data: images,
                }));
              })
              .catch(error => {
                console.error('Error fetching images:', error);
              });
          })
        .catch((err) => {
          console.log(err);
        });
      }
    }, [bookingDetails.data]);
    const getCategoryImg = async (id) => {
      try {
        const response = await axios.post(`/api/product-category/${id}`, {});
        if (!response.data?.result?.success) {
          toast.error("Error while fetching category!");
          return null; 
        }
        return response.data?.result?.category?.image;
      } catch (error) {
        console.error("Error while fetching category:", error);
        toast.error("Error while fetching category!");
        return null; 
      }
    };
    const [imgUrls, setImgUrls] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if (!bookingDetails.data.services) return; 
      const urls = [];
      for (const serv of bookingDetails.data.services) {
        const imgUrl = await getCategoryImg(serv.category.id);
        urls.push(imgUrl);
      }
      setImgUrls(urls);
    };
  
    fetchData();
  }, [bookingDetails.data.services]);
 
  return (
    <>
      <div className="justify-start md:mx-10 mx-4 py-7 items-center gap-2  hidden sm:inline-flex ">
        <div
          onClick={() => navigate("/serviceProvider/account")}
          className="text-zinc-400 text-sm md:text-base font-normal md:leading-[48px] hover:text-gray-dark cursor-pointer"
        >
          {t("Service Provider Account")}
        </div>
        <FontAwesomeIcon
          className="text-zinc-400 text-sm md:text-base"
          icon={faAngleRight}
        />
        <div
          onClick={() => navigate("/serviceProvider/booking-requests")}
          className="text-zinc-400 text-sm md:text-base font-normal md:leading-[48px] hover:text-gray-dark cursor-pointer"
        >
          {t("Booking Requests")}
        </div>
        <FontAwesomeIcon
          className="text-zinc-400 text-sm md:text-base"
          icon={faAngleRight}
        />
        <div className="text-zinc-400 text-sm md:text-base font-normal md:leading-[48px] hover:text-gray-dark cursor-pointer">
          {t("Details")}
        </div>
      </div>
      {bookingDetails.loading && <Loader slim />}
      {!bookingDetails.loading && (
        <section className="md:px-10 px-4 flex flex-col md:flex-row gap-6">
          <div className="p-6 bg-zinc-100 rounded-2xl mb-4 md:w-4/5 w-full">
            <div className="lg:flex justify-center gap-6 pb-6 border-b mb-6 ">
              <div className="flex justify-center mb-4 lg:mb-0">
                <div className="relative mb-6">
                  <div className="sm:w-[440px] flex justify-center items-center mb-0">
                    <img
                      className="h-80 w-full object-cover rounded-lg"
                      src={vendor.data.image || placholder_image}
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-col gap-6 grow">
                <div className="flex justify-between items-center">
                  <p className="text-gray-dark text-2xl md:text-[32px] font-medium uppercase">
                    {vendor.data.name}
                  </p>
                </div>
                <div className="flex justify-between">
                  <div className="flex-col justify-start items-start gap-3 inline-flex w-1/2">
                    <div>
                      <span className="text-[#666666] text-lg font-semibold">
                        {t("Booking ID")} :
                      </span>
                      <span className="text-[#666666] text-lg font-light">
                        {" "}
                        {bookingDetails.data.id}
                      </span>
                    </div>
                    <div>
                      <span className="text-[#666666] text-lg font-semibold">
                        {t("Event Date")} :
                      </span>
                      <span className="text-[#666666] text-lg font-light">
                        {" "}
                        {bookingDetails.data.date}
                      </span>
                    </div>
                    <div>
                      <span className="text-[#666666] text-lg font-semibold">
                        {t("Total Price")} :
                      </span>
                      <span className="text-[#666666] text-lg font-light">
                        {" "}
                        {bookingDetails.data.services.reduce(
                          (total, service) => total + service.list_price,
                          0
                        )}{" "}
                        EGP
                      </span>
                    </div>
                    <div>
                      <span className="text-[#666666] text-lg font-semibold">
                        {t("Client Name")} :
                      </span>
                      <span className="text-[#666666] text-lg font-light">
                        {" "}
                        {bookingDetails.data.client}
                      </span>
                    </div>
                    <div>
                      <span className="text-[#666666] text-lg font-semibold">
                        {t("Client Number")} :
                      </span>
                      <span className="text-[#666666] text-lg font-light">
                        {" "}
                        {bookingDetails.data.client_mobile_number}
                      </span>
                    </div>
                  </div>
                  <div className="flex-col justify-start items-start gap-3 inline-flex w-1/2">
                    <div>
                      <span className="text-[#666666] text-lg font-semibold">
                        {t("Status")} :
                      </span>
                      <span className="text-lg capitalize font-bold ">
                        {" "}
                        {bookingDetails.data.state === "accepted" ? (<span className=' text-[#1A7F37] '>{t("Accepted")}</span>):
                        bookingDetails.data.state === "new" ? (<span className='text-[#9A6700]'>{t("Hold")}</span>) :
                        bookingDetails.data.state === "cancelled" ? (<span className='text-gray-dark'>{t("Cancelled")}</span>) :
                        bookingDetails.data.state === "rejected" ? (<span className='text-[#CF222E]'>{t("Rejected")}</span>):bookingDetails.data.state}
                      </span>
                    </div>
                    <div>
                      <span className="text-[#666666] text-lg font-semibold">
                        {t("Event Time")} :
                      </span>
                      <span className="text-[#666666] text-lg font-light">
                        {" "}
                        {bookingDetails.data.time}
                      </span>
                    </div>
                    <div>
                      <span className="text-[#666666] text-lg font-semibold">
                        {t("Accepted at")} :
                      </span>
                      <span className="text-[#666666] text-lg font-light">
                        {" "}
                        {bookingDetails.data.accepted_date || t("Not acceptd yet")}
                      </span>
                    </div>
                  </div>
                </div>
                {/* {imageUrls.loading && <Loader slim />}
                {!imageUrls.loading && (
                  <div className="mx-auto">
                    <SlideshowLightbox
                      images={imageUrls.data}
                      className="slideshowLightbox container grid grid-cols-3 gap-2 mx-auto venue-gallery"
                      showThumbnails={true}
                    ></SlideshowLightbox>
                  </div>
                )} */}
              </div>
            </div>
            <div className="">
              <Swiper
                spaceBetween={20}
                slidesPerView={
                  isMobile ? 1 : isTablet ? 2 : isLaptop ? 3 : 6
                }
                navigation={true}
                modules={[Navigation]}
              >
                <div className="flex items-center gap-6">
                  {bookingDetails.data.services.length === 0 ? (
                    <Info msg={"No services Found!"} />
                  ) : (
                    bookingDetails.data.services.map((serv, index) => (
                      <SwiperSlide key={serv.id}>
                        <div
                          className="p-3 rounded-xl border flex gap-3 items-center"
                          key={serv.id}
                        >
                          <div className="flex-col justify-start items-start gap-2.5 inline-flex">
                            <div className="justify-start items-center gap-4 inline-flex">
                              <div className="w-7 h-7 px-px py-[5px] justify-center items-center flex">
                                <img src={imgUrls[index]} alt="Icon" />
                              </div>
                              <div className="text-red text-base font-normal">
                                {serv.name}
                              </div>
                            </div>
                            <div className="text-zinc-800 text-base font-normal">
                              {serv.list_price} EGP
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))
                  )}
                </div>
              </Swiper>
            </div>
          </div>
          <div className="p-6 mb-2 bg-zinc-100 rounded-2xl md:w-1/5 w-full h-fit">
            <div className="flex flex-col justify-center items-center py-4 border-b">
              <span className="text-lg text-zinc-500 font-normal">
                {t("Request date")}:
              </span>
              <span className="text-base text-zinc-400 font-normal">
                {bookingDetails.data.create_date.substring(0, 10)}
              </span>
            </div>
            <div className="flex flex-col justify-center items-center py-4 border-b">
              <span className="text-lg text-zinc-500 font-normal">
                {t("Accept date")}:
              </span>
              <span className="text-base text-zinc-400 font-normal">
                {bookingDetails.data.accepted_date || t("Not acceptd yet")}
              </span>
            </div>
            {/* <div className="flex flex-col justify-center items-center py-4 border-b">
              <span className="text-lg text-zinc-500 font-normal">
                Payment date:
              </span>
              <span className="text-base text-zinc-400 font-normal">
                02/02/2024
              </span>
            </div> */}
            <div className="flex flex-col justify-center items-center py-4">
              <span className="text-lg text-zinc-500 font-normal">
                {t("Event date")}:
              </span>
              <span className="text-base text-zinc-400 font-normal">
                {bookingDetails.data.date}
              </span>
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export  {RequestsDetails as ProviderRequestsDetails}
