import React from 'react';
import { Sidebar } from 'flowbite-react';
import { HiArrowSmRight, HiChartPie, HiInbox, HiShoppingBag, HiTable, HiUser, HiViewBoards } from 'react-icons/hi';
import { Label, Radio } from 'flowbite-react';


function ProductsSidebar({handleChange}) {
  return (
    <Sidebar className="" aria-label="Default sidebar example">
      <Sidebar.Items >
        <Sidebar.ItemGroup >
          <fieldset className="flex max-w-md flex-col gap-4 mt-16">
              <legend className="mb-4 font-bold dark:text-white">Category</legend>
              <div className="flex items-center gap-2">
                <Radio onChange={handleChange} name="category" value="" defaultChecked />
                <Label>All</Label>
              </div>
              <div className="flex items-center gap-2">
                <Radio onChange={handleChange} name="category"  value="Hair Accessories" title="Hair Accessories" />
                <Label>Hair Accessories</Label>
              </div>
              <div className="flex items-center gap-2">
                <Radio onChange={handleChange}  name="category"  value="Dressess" title="Dressess" />
                <Label >Dressess</Label>
              </div>
              <div className="flex items-center gap-2">
                <Radio onChange={handleChange} name="category" value="Cosmetic" title="Cosmetic"/>
                <Label >Cosmetic & Toiletry Bags.</Label>
              </div>
              <div className="flex items-center gap-2">
                <Radio onChange={handleChange} name="category" value="Heels" title="Heels" />
                <Label >Heels</Label>
              </div>
          </fieldset>
        </Sidebar.ItemGroup>
      </Sidebar.Items>
    </Sidebar>
  )
}

export default ProductsSidebar
