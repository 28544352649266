import React from 'react';
import contactus from '../../assets/images/plan/plan.png'
import object3 from "../../assets/images/ContactUs/OBJECTS3.png"
import object4 from "../../assets/images/ContactUs/OBJECTS4.png"
import frame from "../../assets/images/ContactUs/Frame.png"
import mail from "../../assets/images/ContactUs/mail.png"
import location from "../../assets/images/ContactUs/location.png"
import phone from "../../assets/images/ContactUs/Icon.png"
import {Controller, useForm} from "react-hook-form";
import {Typography} from "@material-tailwind/react";
import {useTranslation} from "react-i18next";
import object from "../../assets/images/About/OBJECTS.png"
import object2 from "../../assets/images/About/Isolation_Mode.png"
import axios from 'axios';
import useAxiosUserSecureConfig from '../../hooks/useAxiosUserSecureConfig'
import { PhoneInput, defaultCountries, parseCountry } from 'react-international-phone';
import {toast}  from "react-toastify";

function Contact(props) {
  const {
    register,
    control,
    formState: { errors },
    handleSubmit
  } = useForm({mode: "all"});
  const config = useAxiosUserSecureConfig();
  const {t ,i18n} = useTranslation();

  function onSubmit(data){
    console.log('data',data) ;
       data={
        name: data.name,
        email: data.email,
        phone: data.phone,
        message: data.message
       }
console.log("data:",data)
     axios.post('/api/contact-request/create',data,config).then((res)=>{

         if (!res.data?.result?.success){ toast.error(res.data.result?.message || "Request Failed")

         }
         toast.success(t('Message send Successufly'))

     })
     .catch((error)=>{toast.error('Error while sending message')
   })
 }


  return (
    <div className="bg-[#f2f2f2]">
      <div className="relative">
        <img src={contactus} className="w-full relative" />
        <h1 className="z-10 px-5 xl:px-10 absolute bottom-[20%] text-white text-2xl sm:text-[40px] font-medium font-['Roboto'] leading-[44px]">
          {t("Contact Us")}
        </h1>
      </div>
      <div
        className={`flex justify-center ${
          i18n.language === "en" ? "flex-row" : "flex-row-reverse"
        } w-3/4 mx-auto mt-10 mb-8`}
      >
        <img src={object3} className="w-14 md:w-24 object-contain" />
        <p className="self-stretch text-black text-wrap text-center font-bold text-base md:text-lg lg:text-2xl">
          {t("LET'S ANSWER YOUR QUERIES")}
        </p>
        <img src={object4} className="w-14 md:w-24 object-contain" />
      </div>
      <div className="flex flex-col lg:flex-row ">
        <div className="flex flex-col justify-center  w-full md:w-1/2 ">
          <div className="w-full">
            <img src={frame} className="  object-cover " />
          </div>

          <div className="flex flex-col justify-center md:justify-start">
            <div className="flex md:ml-20 mb-9">
              <img src={mail} className="px-3  h-[50px]" />
              <p>
                {t("Email")} <br></br> <span>support@########.com</span>
              </p>
            </div>
            <div className="flex md:ml-20 mb-9 ">
              <img src={phone} className="px-3   h-[50px]" />
              <p>
                {t("Phone")} <br></br> <span>+2 1#########</span>
              </p>
            </div>
            <div className="flex  md:ml-20 mb-9 ">
              <img src={location} className="px-3 h-[50px]" />
              <p>
                {t("Main office")} <br></br>{" "}
                <span>1054 st,####################</span>
              </p>
            </div>
          </div>
        </div>

        <div className=" w-5/6 lg:w-[50%] h-[600px] lg:mt-32 mt-20 mb-5 mx-auto lg:mx-20 bg-white border rounded-xl relative">
          <img
            src={object}
            className="w-[101px] h-[140px] absolute right-[-21px] top-[-20px]"
          />
          <img
            src={object2}
            className="w-[85px] h-[101px] absolute left-[-14px] bottom-[-13px]"
          />

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="w-full flex-col justify-start items-start gap-2 inline-flex ">
              <div className="self-stretch flex-col justify-start p-4 items-start gap-2 flex">
                <label className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">
                  {" "}
                  {t("Name")}
                  <span className="text-red-500 text-base font-normal leading-snug">
                    *
                  </span>
                </label>

                <input
                  error={!!errors.name}
                  {...register("name", {
                    required: "Name is required.",
                    minLength: {
                      value: 3,
                      message: t("Name must be at least 3 characters long"),
                    },
                  })}
                  type="text"
                  className="w-full p-3 bg-slate-100 border-slate-100 rounded-lg "
                  placeholder={t("Name")}
                />
                {errors.name && (
                  <Typography variant="small" color="red">
                    {errors.name?.message}
                  </Typography>
                )}
              </div>
              <div className="self-stretch flex-col justify-start p-4 items-start gap-2 flex">
                <label className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">
                  {t("Email")}
                  <span className="text-red-500 text-base font-normal leading-snug">
                    *
                  </span>{" "}
                </label>
                <div className="w-full relative">
                  <input
                    error={!!errors.email}
                    {...register("email", {
                      required: t("E-mail is required"),
                    })}
                    type={"email"}
                    className="w-full p-3 bg-slate-100 border-slate-100 rounded-lg"
                    placeholder={t("Email")}
                  />
                </div>
                {errors.email && (
                  <Typography variant="small" color="red">
                    {errors.email?.message}
                  </Typography>
                )}
              </div>
              <div className="invitaion-verification self-stretch flex-col p-4 justify-start items-start gap-2 flex">
                <label className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">
                  {t("Phone Number")}{" "}
                  <span className="text-red-500 text-base font-normal leading-snug">
                    *
                  </span>
                </label>
                <div className="w-full relative flex justify-between">
                  <Controller
                    className="w-full"
                    control={control}
                    name="phone"
                    rules={{ required: "Phone Number is required" }}
                    render={({ field, field: { name, ref } }) => {
                      return (
                        <PhoneInput
                          className=""
                          error={!!errors.mobile}
                          defaultCountry="eg"
                          inputStyle={{
                            background: "rgb(242 244 248",
                            width: "100%",
                            margin: "0px 4px",
                            border: "0px",
                            padding: "0 14px",
                            height: "46px",
                            borderRadius: "8px",
                          }}
                          name={name}
                          ref={ref}
                          {...field}
                        />
                      );
                    }}
                  />
                  <Typography variant="small" color="red">
                    {errors.phone ? errors.phone?.message : ""}
                  </Typography>
                </div>
              </div>

              <div className="self-stretch w-full flex-col justify-start p-4 items-start gap-2 flex">
                <label className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">
                  {" "}
                  {t("Message")}
                  <span className="text-red-500 text-base font-normal leading-snug">
                    *
                  </span>
                </label>

                <input
                  error={!!errors.message}
                  {...register("message", {
                    required: "your message is required.",
                  })}
                  type="text"
                  className="w-full p-3 bg-slate-100 border-slate-100 rounded-lg "
                  placeholder={t("message")}
                />
                {errors.message && (
                  <Typography variant="small" color="red">
                    {errors.message?.message}
                  </Typography>
                )}
              </div>
              <div className="self-stretch w-full flex-col justify-start p-4 items-start gap-2 flex">
                <button
                  type="submit"
                  className="self-stretch w-full p-4  bg-red rounded-lg justify-center items-center inline-flex"
                >
                  <span className="text-white text-base font-medium leading-none tracking-wide">
                    {t("Send message")}
                  </span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Contact;