import React ,{useEffect, useRef, useState} from 'react';
import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAsterisk, faAt, faQrcode , faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import {linkPartner} from "../../../store/dispatches/partner";
import useAxiosUserSecureConfig from "../../../hooks/useAxiosUserSecureConfig";
import {useTranslation} from "react-i18next";
import partnerImg from "../../../assets/images/partner/picture.png"
import QRCode from "react-qr-code";
import Loader from "../../../components/Loader";
import { Typography} from "@material-tailwind/react";
import {useNavigate} from "react-router-dom";
import { Card } from 'flowbite-react';
import {initiatePartner} from "../../../store/dispatches/partner";



function UserPartnerLink(props) {
  const { register, setError, setValue, formState: { errors }, handleSubmit } = useForm()
  const dark = useSelector(state => state.ui.dark);
  const config = useAxiosUserSecureConfig();
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const onSubmit = (data) => {
    linkPartner(data.code, dispatch, config)
        .then(value => {
          if ( value === true ) {
            toast.success("Success in Linking.")
          } else {
            toast.error(value || t("Invalid Link Code"))

            setError('code', { type: 'custom', message: t('Invalid Code') });
            setValue('code', '')
          }
        })
    ;
  }

  const [code, setCode] = useState(null);
  const navigate = useNavigate();

  const effect = useRef(false);

  useEffect(() => {
    if (!effect.current) {
      initiatePartner(dispatch, config)
          .then(value => {
            if ( value ) {
              if (value.result?.success === false) {
                toast.warn(value.result?.msg || "Unknown error")
                navigate("/");
                return;
              }

              toast.success("Success in initiating. Please scan or copy the code")
              setCode(value.result?.code);
            } else if (value === false ) {
              toast.error("Invalid response from server")
            }
          })
      ;
    }
    return () => effect.current = true;
  }, []);

  const [formStep , setFormStep] = useState(0)
  const completeFormStep =()=>{
    setFormStep (cur => cur + 1)
  }

  return (
      <section>
        <div className="flex items-center justify-center">
          <img className="w-1/2 hidden xl:block" src={partnerImg} alt="link-partner"/>
          <div className="xl:w-1/2 flex justify-center p-10 xl:p-0">
          {formStep === 0 && (<div className="w-fit flex flex-col items-center">
            <p className="text-center text-zinc-800 text-[40px] font-normal leading-[44px] mb-8">Pair With Partner</p>
            <button onClick={completeFormStep} className="w-full mb-4 px-3 py-4 bg-red rounded-lg justify-center items-center inline-flex">
                <div className="px-4 justify-center items-center gap-2.5 flex">
                    <span className="text-orange-50 text-base font-medium leading-none tracking-wide">Get Code</span>
                </div>
                <FontAwesomeIcon className="text-orange-50" icon={faArrowRight} />
            </button>
            <span className="text-red text-base font-normal leading-none">Scan Code</span>
          </div>)}

            { formStep === 1 &&(<section className='text-center'>
                    <div className='text-center m-auto mb-16'>
                    { code == null && <Loader /> }
                    { code != null && <QRCode className='w-auto m-auto mb-6' value={code} /> }
                    { code != null && 
                    <div className="w-full justify-center items-center gap-4 inline-flex mb-6">
                    <div className="grow shrink basis-0 h-[0px] border border-zinc-500"></div>
                      <div className="grow shrink basis-0 flex-col justify-start items-center gap-2 inline-flex">
                      <Typography className="text-red text-[32px] font-medium leading-loose tracking-widest">{ code }</Typography>
                      </div>
                    <div className="grow shrink basis-0 h-[0px] border border-zinc-500"></div>
                    </div>
                     }
                     <button className="w-3/4 px-[18px] py-3 rounded-[10px] shadow border border-red justify-center items-center gap-2.5 inline-flex">
                      <span className="text-center text-red text-base font-normal leading-tight">Share Code</span>
                    </button>
                    </div>
                    
                    <form className="mt-8 mb-2" onSubmit={handleSubmit(onSubmit)}>
                      <div className="flex flex-col mb-6">
                          <input
                                className="h-12 px-4 py-3 bg-slate-100 rounded-lg border-b justify-start items-center gap-2 inline-flex placeholder-[#A6A6A6] leading-snug"
                                type={"code"}
                                placeholder='Enter Code'
                                error={!!errors.code}
                                {...register("code", {required: t('Code is required')})}
                          />
                          <Typography className="mt-2" variant="small" color="red">{errors.code?.message ?? ""}</Typography>
                      </div>
                      <button className="w-3/4 px-[18px] py-3 rounded-[10px] shadow bg-red border border-red justify-center items-center gap-2.5 inline-flex" type={"submit"}>
                        <span className="text-center text-white text-base font-normal leading-tight">Verify</span>
                      </button>
                    </form>

            </section>)}

          </div>
        </div>
      </section>
      
  );
}

export default UserPartnerLink;