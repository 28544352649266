import React , { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight ,faBars, faClose} from "@fortawesome/free-solid-svg-icons";
import { Collapse } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import useAxiosUserSecureConfig from "../../../hooks/useAxiosUserSecureConfig";
import Loader from "../../../components/Loader";
import moment from 'moment';
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

function ConsultationSession (){
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
    const [openNav1, setOpenNav1] = React.useState(false);
    React.useEffect(() => {
        window.addEventListener(
            "resize",
            () => window.innerWidth >= 950 && setOpenNav1(false)
        );
      }, []);

      const [openNav2, setOpenNav2] = React.useState(false);
      React.useEffect(() => {
          window.addEventListener(
              "resize",
              () => window.innerWidth >= 950 && setOpenNav2(false)
          );
        }, []);
        
        const config = useAxiosUserSecureConfig();
        
        const [bookingUser, setBookingUser]=useState({ loading: true, data: [] })

        const fetchData = () => {
          axios
            .post('/api/wedding-consultant-booking',{}, config)
            .then((res) => {
              console.log("Booking_data", res.data);
      
              if (!res.data?.result?.success) {
                toast.error("Error while fetching Booking data!");
              }
              setBookingUser((prevState) => ({
                ...prevState,
                loading: false,
                data: res.data?.result?.wedding_consultant_bookings|| [], 
              }));
            })
            .catch((err) => {
              console.log(err);
            });
        }
      
        useEffect(() => {
          fetchData()
        }, []);

        const [openNav, setOpenNav] = useState({});

        const toggleOpenNav = (bookingId) => {
          setOpenNav((prevState) => ({
            ...prevState,
            [bookingId]: !prevState[bookingId], // Toggle the open/close state for the specific booking
          }));
        };

        const handleCancelSession = (id) => {
          console.log(id)
          axios.post(`/api/wedding-consultant-booking/${id}/cancel` ,{}, config)
              .then((res) => {
                console.log(res.data)
                if (!res.data.result?.success) {
                  toast.error(res.data.result?.message || "Request failed!");
                  return;
                }
                toast.success("You cancel Consultation Session successfully!");
                fetchData()
              })
              .catch((err) => {
                console.log("err" , err)
                toast.error("Error while canceling Consultation Session!");
              })
         }

    return (
      <div className="bg-zinc-100">
        <div className="justify-start items-center gap-2  hidden sm:inline-flex my-7 mx-4 md:mx-12">
          <div
            onClick={() => navigate("/user/account")}
            className="text-zinc-400 text-base font-normal leading-[48px] hover:text-gray-dark cursor-pointer"
          >
            {t("My Account")}
          </div>
          <FontAwesomeIcon
            className="text-zinc-400 text-base"
            icon={faAngleRight}
          />
          <div
            onClick={() => navigate("/BookConsultant")}
            className="text-zinc-400 text-base font-normal leading-[48px] hover:text-gray-dark cursor-pointer"
          >
            {t("My Consultation Sessions")}
          </div>
        </div>

        <div className="w-4/5 mx-auto ">
          <p className="text-lg mb-11 text-red md:text-2xl font-semibold leading-9  ">
            {t("My Consultation Sessions")}
          </p>
          {bookingUser.loading && <Loader slim />}
          {!bookingUser.loading &&
            bookingUser.data.map((booking) => (
              <div
                key={booking.id}
                className="mx-auto bg-white border border-white rounded-xl py-4 px-4 mb-6"
              >
                <div className="flex justify-between mb-4">
                  <div>
                    <p className="font-bold">
                      {t("Requested On")} {booking.request_date}
                    </p>
                  </div>
                  <div className="flex items-center gap-6">
                    {booking.state === "cancelled" ? (
                      <div className="px-2.5 py-[3px] rounded-[100px] border border-[#CF222E] flex-col justify-start items-start gap-2.5 inline-flex">
                        <div className="text-center text-[#CF222E] text-xs font-semibold font-['SF Pro Text'] leading-[18px]">
                          {t("Cancelled")}
                        </div>
                      </div>
                    ) : (
                      <button onClick={() => handleCancelSession(booking.id)}>
                        <p className="text-[#ef4444]">{t("Cancel")}</p>
                      </button>
                    )}
                    <button onClick={() => toggleOpenNav(booking.id)}>
                      {openNav[booking.id] ? (
                        <p className="text-red">{t("View Less")}</p>
                      ) : (
                        <p className="text-red">{t("View All details")}</p>
                      )}
                    </button>
                  </div>
                </div>
                <Collapse open={openNav[booking.id]}>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6 grow mb-6 ">
                    <div>
                      <span className="text-[#666666] text-lg font-semibold">
                        {t("Location")}:{" "}
                      </span>{" "}
                      <span className="text-[#666666] text-lg font-light">
                        {""}
                        {booking.location}
                      </span>
                    </div>
                    <div>
                      <span className="text-[#666666] text-lg font-semibold">
                        {t("Client Name")}:{" "}
                      </span>{" "}
                      <span className="text-[#666666] text-lg font-light">
                        {""}
                        {booking.name}
                      </span>
                    </div>
                    <div>
                      <span className="text-[#666666] text-lg font-semibold">
                        {t("Partner Name")}:{" "}
                      </span>{" "}
                      <span className="text-[#666666] text-lg font-light">
                        {" "}
                        {booking.bride_or_groom_name}
                      </span>
                    </div>
                    <div>
                      <span className="text-[#666666] text-lg font-semibold">
                        {t("Phone Number")}:{" "}
                      </span>{" "}
                      <span className="text-[#666666] text-lg font-light">
                        {" "}
                        {booking.phone}
                      </span>
                    </div>
                    <div>
                      <span className="text-[#666666] text-lg font-semibold">
                        {t("Wedding Date")}:{" "}
                      </span>{" "}
                      <span className="text-[#666666] text-lg font-light">
                        {" "}
                        {booking.wedding_date}
                      </span>
                    </div>
                    <div>
                      <span className="text-[#666666] text-lg font-semibold">
                        {t("Estimated Number of Guest")}:{" "}
                      </span>{" "}
                      <span className="text-[#666666] text-lg font-light">
                        {" "}
                        {booking.estimated_guests}
                      </span>
                    </div>
                  </div>
                  <div className="w-full md:w-11/12 grid grid-cols-1 grow mb-6">
                    <span className="text-[#666666] text-lg font-semibold">
                      {t("More Details About Your Inquiry")}:{" "}
                    </span>{" "}
                    <span className="text-[#666666] text-lg font-light">
                      {" "}
                      {booking.details}
                    </span>
                  </div>
                </Collapse>

                <div className="pb-3">
                  {booking.create_wedding_schedule && (
                    <p className="pb-3 text-xl">
                      {t("Creating a Wedding Schedule")}
                    </p>
                  )}
                  {booking.supervising_logistics && (
                    <p className="pb-3 text-xl">
                      {t("Supervising the Logistics")}
                    </p>
                  )}
                  {booking.choosing_suppliers && (
                    <p className="pb-3 text-xl">{t("Choosing Suppliers")}</p>
                  )}
                  {booking.managing_unexpected_challenges && (
                    <p className="pb-3 text-xl">
                      {t("Managing Unexpected Challenges")}
                    </p>
                  )}
                  {booking.wedding_advisor && (
                    <p className="pb-3 text-xl">{t("Wedding Advisors")}</p>
                  )}
                  {booking.handling_financial_plans && (
                    <p className="pb-3 text-xl">
                      {t("Handling Financial Plans")}
                    </p>
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
    );

}
export default ConsultationSession;