import React, {createRef, useEffect, useState} from 'react';
import {Button, Card, Checkbox, Input,  Typography, Option, Textarea} from "@material-tailwind/react";
import {Link, useNavigate} from "react-router-dom";
import {Controller, useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {login, registerStatuses, userRegister} from "../../store/dispatches/auth";
import {toast} from "react-toastify";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAt, faIdCard, faLock, faMobilePhone, faUser} from "@fortawesome/free-solid-svg-icons";
import ReCAPTCHA from "react-google-recaptcha";
import {useTranslation} from "react-i18next";
import signupImg from "../../assets/images/signup/sign-up.png"
import google from "../../assets/images/login/google.svg";
import { faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Loader from "../../components/Loader";
import clsx from "clsx";
import Select from "react-select";
import {baseStyle, menuStyle, optionStyles} from "../../utils/utils";
import facebook from "../../assets/images/login/facebook.svg";
import { PhoneInput, defaultCountries, parseCountry } from 'react-international-phone';
import TermsAndConditions from '../content/TermsAndConditions';
import validator from 'validator';


function Register({ }) {

  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);
  
  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
    setIsEmailValid(validator.isEmail(event.target.value));
  };
  const [formStep , setFormStep] = useState(0)

  const renderNavigation = () => {
    if (formStep > 1) {
      return undefined
    }

    else if( formStep === 1 ){
      return (
          <div className={"flex gap-x-4 justify-between"}>
            <button onClick={() => setFormStep(0)} type="button" className="w-56 px-3 py-4  border-2 border-red rounded-lg justify-center items-center inline-flex">
              <span className="text-red text-base font-medium leading-none tracking-wide">{t("Back")}</span>
            </button>
            <button type="submit"  className="w-56 px-3 py-4 bg-red rounded-lg justify-center items-center inline-flex">
              <span className="text-white text-base font-medium leading-none tracking-wide">{t("Sign up")}</span>
            </button>
          </div>
      )
    }
    else{
      return(
          <div className={"flex justify-between"}>
            <button onClick={() => {setFormStep(1); // noinspection JSIgnoredPromiseFromCall
              trigger(["name", "phone", "mobile"]);}} type="button"  disabled={!isEmailValid} className="w-56 px-3 py-4 bg-red rounded-lg justify-center items-center inline-flex">
              <span className="text-white text-base font-medium leading-none tracking-wide">{t("Next")}</span>
            </button>
          </div>
      )
    }
  }

  const [showPassword , setShowPassword]= useState(false);
  const [showConfirmPassword , setShowConfirmPassword]= useState(false);
  const [password, setPassword] = useState("");
  const [strength, setStrength] = useState(0);
  const [isValid, setIsValid] = useState(false); // Flag for valid password

  const handleChange = (event) => {
    setPassword(event.target.value);
  };

  const evaluatePasswordStrength = (password) => {
    let score = 0;

    // Minimum length (customize as needed)
    if (password.length >= 8) {
      score++;
    }

    // Character types
    if (/[a-z]/.test(password)) score++;
    if (/[A-Z]/.test(password)) score++;
    if (/[0-9]/.test(password)) score++;
    if (/[!@#$%^&*()_+\-=\[\]{};':",./<>?|\\ ]/.test(password)) score++;

    // Deductions for common patterns (optional)
    if (
      password
        .toLowerCase()
        .split("")
        .some((char) => password.includes(char.repeat(3)))
    ) {
      score--; // Repeated characters
    }
    if (password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^a-zA-Z\d]+$/)) {
      score--; // Predictable keyboard patterns
    }

    setStrength(score);
    setIsValid(score >= 4); // Set validity based on strength
  };

  const getStrengthText = (score) => {
    switch (score) {
      case 0:
      case 1:
        return t("Weak"); // Use translation key for "weak"
      case 2:
      case 3:
        return t("Normal"); // Use translation key for "normal"
      case 4:
        return t("Good"); // Use translation key for "good"
      default:
        return t("Very Strong"); // Handle potential future extensions (optional)
    }
  };

  const strengthColor = (score) => {
    switch (score) {
      case 0:
      case 1:
        return "text-red-50"; // Red for weak
      case 2:
      case 3:
        return "text-yellow-400"; // Yellow for normal
      default:
        return "text-green-700"; // Green for strong
    }
  };

  React.useEffect(() => {
    evaluatePasswordStrength(password);
  }, [password]);


  const { register, trigger, watch, control, setValue, setError, formState: { errors }, handleSubmit } = useForm({mode : "all"})

  const dark = useSelector(state => state.ui.dark);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const recaptchaRef = createRef()
  const { t, i18n } = useTranslation();

  const [countries , setCountries] = useState({ loading: true, data: [] })
  const [states , setStates] = useState({ loading: false, data: [] })
  const [cities , setCities] = useState({ loading: false, data: [] })
  
  const customStyles = {
    control: (styles) => ({
      ...styles,
      background: "#F2F4F8", "border-color": "#F2F4F8" , "border-radius" :"8px" ,padding: "6px" })
  }
  useEffect(() => {
    axios.post('/api/country', {}).then((res) => {
      console.log("countries", res.data);

      if (!res.data?.result?.success) {
        toast.error("Error while fetching countries!");
        return;
      }

      setCountries(prevState => ({
        ...prevState,
        loading: false,
        data: res.data?.result?.countries ?? []
      }))
    }).catch((err) => {
      console.log(err);
    })

  }, []);

  useEffect(() => {
    if ((errors.email || errors.name || errors.mobile) && formStep !== 0) {
      setFormStep(0)
    }
  }, [errors.email, errors.name, errors.mobile]);

  // noinspection DuplicatedCode
  const country = watch("country");
  const state = watch("state");

  useEffect(() => {
    setValue("state", null);
    setValue("city", null);

    if (!country) {
      setStates(prevState => ({
        ...prevState,
        data: []
      }))
      setCities(prevState => ({
        ...prevState,
        data: []
      }))
      return;
    }

    setStates(prevState => ({
      ...prevState,
      loading: true,
    }));

    axios.post(`/api/country/${country.value}`, {}).then((res) => {
      console.log("states", res.data);

      if (!res.data?.result?.success) {
        toast.error("Error while fetching states!");
        return;
      }

      setStates(prevState => ({
        ...prevState,
        loading: false,
        data: res.data?.result?.countries ?? [] // TODO: fix naming in backend from countries to states
      }))
    }).catch((err) => {
      console.log(err);
    })
  }, [country]);

  useEffect(() => {
    setValue("city", null)

    if (!state) {
      setCities(prevState => ({
        ...prevState,
        data: []
      }))
      return;
    }

    setCities(prevState => ({
      ...prevState,
      loading: true,
    }));

    axios.post(`/api/state/${state.value}`, {}).then((res) => {
      console.log("cities", res.data);

      if (!res.data?.result?.success) {
        toast.error("Error while fetching cities!");
        return;
      }

      setCities(prevState => ({
        ...prevState,
        loading: false,
        data: res.data?.result?.state.cities ?? []
      }))
    }).catch((err) => {
      console.log(err);
    })
  }, [state]);

  const onSubmit = (data) => {
    const token = recaptchaRef.current.getValue();
    if (token === "") {
      toast.error(t("Please solve the reCAPTCHA"));
      return;
    }

    // Validate password strength
    if (!isValid) {
      setError("password", {
        type: "custom",
        message: t("Password strength should be at least Good"),
      });
      return;
    }

   


    userRegister(data, dispatch).then((value) => {
      if (value === registerStatuses.SUCCESS) {
        toast.success(t("Success registering"));
        navigate("/login");
      } else if (value === registerStatuses.EMAIL_TAKEN) {
        toast.error(t("Failure in registering"));
        setError("email", {
          type: "custom",
          message: t("E-mail already registered"),
        });
        
      } 
      else if (!isEmailValid) {
        setError("email", {
           type: "custom",
           message: t("Please enter a valid email address."),
         });
         return;
       }

      else if (value === registerStatuses.ERROR) {
        toast.error(t("Failure in registering"));
      }
    });
  };
  
  

  const [open, setOpen] =useState(false);
  const handleClose = () => setOpen(false); 

  return (
    <section className="flex justify-center">
      <TermsAndConditions open={open} handleClose={handleClose} />
      <div className="w-1/2 hidden xl:block">
        <img className="w-full" src={signupImg} alt="login" />
      </div>
      <div className="xl:px-32 px-10 py-10 w-full lg:w-1/2 mt-5">
        <div className="w-full justify-center items-center gap-2 inline-flex mb-12">
          <div className="grow shrink basis-0 h-[0px] border border-red"></div>
          <div className="grow shrink basis-0 flex-col justify-start items-center gap-2 inline-flex">
            <div className="text-red text-2xl font-normal leading-relaxed">
              {t("Sign up")}
            </div>
          </div>
          <div className="grow shrink basis-0 h-[0px] border border-red"></div>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <section className={formStep === 0 ? "block" : "hidden"}>
            <div className="mb-6 flex justify-between items-center flex-col lg:flex-row gap-3">
              <p className="text-red lg:text-xl font-medium leading-normal">
                {t("Enter Your information")}
              </p>
              <div className="flex items-center">
                <p className="text-neutral-800 whitespace-nowrap text-sm lg:text-base font-normal leading-tight me-4 ">
                  {t("Step 1 of 2")}
                </p>
                <div className="w-4 h-4 bg-red rounded-full me-2" />
                <div className="w-4 h-4 bg-[#D9D9D9] rounded-full" />
              </div>
            </div>

            <div>
              <div className="w-full flex-col justify-start items-start gap-4 inline-flex mb-10">
                <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                  <label className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">
                    {t("Full Name")}{" "}
                    <span className="text-red-500 text-base font-normal leading-snug">
                      *
                    </span>
                  </label>
                  <input
                    error={!!errors.name}
                    {...register("name", {
                      required: t("Name is required."),
                      minLength: {
                        value: 3,
                        message: t("Name must be at least 3 characters long"),
                      },
                    })}
                    type="text"
                    className="w-full p-3 bg-slate-100 border-slate-100 rounded-lg "
                    placeholder={t("Name")}
                  />
                  {errors.name && (
                    <Typography variant="small" color="red">
                      {errors.name?.message}
                    </Typography>
                  )}
                </div>
                <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                  <label className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">
                    {t("Email")}{" "}
                    <span className="text-red-500 text-base font-normal leading-snug">
                      *
                    </span>
                  </label>
                  <div className="w-full relative">
                    <input
                      error={!!errors.email}
                      {...register("email", {
                        required: t("E-mail is required"),
                        validate: () =>
                        isEmailValid || t("Invalid email address"),
                      })}
                      value={email} 
                      onChange={handleChangeEmail}
                      type={"email"}
                      className="w-full p-3 bg-slate-100 border-slate-100 rounded-lg"
                      placeholder={t("Email")}
                    />
                    
                  </div>
                  {errors.email && (
                    <Typography variant="small" color="red">
                      {errors.email?.message}
                    </Typography>
                  )}
                </div>

                <div className="invitaion-verification self-stretch flex-col justify-start items-start gap-2 flex">
                  <label className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">
                    {t("Phone Number")}{" "}
                    <span className="text-red-500 text-base font-normal leading-snug">
                      *
                    </span>
                  </label>
                  <div className="w-full relative flex justify-between">
                    <Controller
                      className="w-full"
                      control={control}
                      name="phone"
                      rules={{ required: t("Phone Number is required") }}
                      render={({ field, field: { name, ref } }) => {
                        return (
                          <PhoneInput
                            className=""
                            error={errors.phone}
                            defaultCountry="eg"
                            inputStyle={{
                              background: "rgb(242 244 248",
                              width: "100%",
                              margin: "0px 4px",
                              border: "0px",
                              padding: "0 14px",
                              height: "46px",
                              borderRadius: "8px",
                            }}
                            name={name}
                            ref={ref}
                            {...field}
                          />
                        );
                      }}
                    />
                  </div>
                  {errors.phone && (
                    <Typography variant="small" color="red">
                      {errors.phone?.message}
                    </Typography>
                  )}
                </div>
              </div>
              <div className="w-full flex flex-col items-center gap-4">
                {renderNavigation()}
                <div className="">
                  <span className="text-red text-sm font-normal leading-tight">
                    {t("Already have an account?")}{" "}
                  </span>
                  <Link
                    to={"/login"}
                    className="text-red text-sm font-normal underline leading-tight"
                  >
                    {t("Login")}
                  </Link>
                </div>
              </div>
            </div>

            <div className="w-full justify-center py-6">
              <div className="w-full justify-between items-center inline-flex">
                <div className="w-full h-[0px] border"></div>
                {/* <div className="text-[#999999] text-base font-light leading-none tracking-wide">
                  or
                </div>
                <div className="w-40 h-[0px] border "></div> */}
              </div>
            </div>

            {/* <div className="w-full flex flex-col gap-4">
              <button className="w-full px-10 py-4 rounded-lg border border-red justify-center items-center gap-6 inline-flex">
                <img src={google} alt="google" />
                <span className="text-black text-sm font-medium leading-snug">
                  Continue with Google
                </span>
              </button>
              <button className="w-full px-10 py-4 rounded-lg border border-red justify-center items-center gap-6 inline-flex">
                <img src={facebook} alt="facebbok" />
                <span className="text-black text-sm font-medium leading-snug">
                  Continue with Facebook
                </span>
              </button>
            </div> */}
          </section>

          <section className={formStep === 1 ? "block" : "hidden"}>
            <div className="mb-6 flex justify-between items-center flex-col lg:flex-row gap-3">
              <p className="text-red lg:text-xl font-medium leading-normal">
                {t("Complete Your information")}
              </p>
              <div className="flex items-center">
                <p className="text-neutral-800 text-sm lg:text-base font-normal leading-tight me-4 ">
                  {t("Step 2 of 2")}
                </p>
                <div className="w-4 h-4 bg-[#D9D9D9] rounded-full me-2" />
                <div className="w-4 h-4 bg-red rounded-full" />
              </div>
            </div>

            <div>
              <div className="w-full flex-col justify-start items-start gap-4 inline-flex mb-10">
                <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                  <label className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">
                    {t("Password")}{" "}
                    <span className="text-red-500 text-base font-normal leading-snug">
                      *
                    </span>
                  </label>
                  <div className="relative w-full">
                    <input
                      error={!!errors.password}
                      {...register("password", {
                        required: t("Password is required"),
                        validate: () =>
                          isValid || t("Password should be at least Good"),
                      })}
                      value={password}
                      type={showPassword ? "text" : "password"}
                      className="w-full p-3 bg-slate-100 border-slate-100 rounded-lg"
                      placeholder={t("Password")}
                      onChange={handleChange}
                    />
                    {strength === 0 && (
                      <p className="text-zinc-500 text-xs font-normal leading-none mt-2">
                        {t(
                          "It must be a combination of minimum 8 letters, numbers and symbols."
                        )}
                      </p>
                    )}
                    {strength >= 1 && (
                      <p>
                        {t("Password Strength")}:
                        <span className={strengthColor(strength)}>
                          {getStrengthText(strength)}
                        </span>
                      </p>
                    )}
                    {showPassword ? (
                      <FontAwesomeIcon
                        icon={faEyeSlash}
                        onClick={() =>
                          setShowPassword((prevState) => !prevState)
                        }
                        className={`absolute ${
                          i18n.language === "en" ? "right-3" : "left-3"
                        } top-4 text-zinc cursor-pointer`}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faEye}
                        onClick={() =>
                          setShowPassword((prevState) => !prevState)
                        }
                        className={`absolute ${
                          i18n.language === "en" ? "right-3" : "left-3"
                        } top-4 text-zinc cursor-pointer`}
                      />
                    )}
                  </div>
                  {errors.password && (
                    <Typography variant="small" color="red">
                      {errors.password?.message}
                    </Typography>
                  )}
                </div>
                <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                  <label className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">
                    {t("Confirm Password")}{" "}
                    <span className="text-red-500 text-base font-normal leading-snug">
                      *
                    </span>
                  </label>
                  <div className="relative w-full">
                    <input
                      error={!!errors.password_repeat}
                      {...register("password_repeat", {
                        required: t("Password Confirmation is required"),
                        validate: (value) =>
                          value === watch("password") ||
                          t("The passwords do not match"),
                      })}
                      type={showConfirmPassword ? "text" : "password"}
                      className="w-full p-3 bg-slate-100 border-slate-100 rounded-lg "
                      placeholder={t("Password")}
                    />
                    {showConfirmPassword ? (
                      <FontAwesomeIcon
                        icon={faEyeSlash}
                        onClick={() =>
                          setShowConfirmPassword((prevState) => !prevState)
                        }
                        className={`absolute ${
                          i18n.language === "en" ? "right-3" : "left-3"
                        } top-4 text-zinc cursor-pointer`}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faEye}
                        onClick={() =>
                          setShowConfirmPassword((prevState) => !prevState)
                        }
                        className={`absolute ${
                          i18n.language === "en" ? "right-3" : "left-3"
                        } top-4 text-zinc cursor-pointer`}
                      />
                    )}
                  </div>
                  {errors.password_repeat && (
                    <Typography variant="small" color="red">
                      {errors.password_repeat?.message}
                    </Typography>
                  )}
                </div>
                <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                  <label className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">
                    {t("Country")}{" "}
                    <span className="text-red-500 text-base font-normal leading-snug">
                      *
                    </span>
                  </label>
                  {countries.loading && <Loader slim />}
                  {!countries.loading && (
                    <Controller
                      className="w-full"
                      control={control}
                      name="country"
                      rules={{ required: t("Country is required") }}
                      render={({ field, field: { name, ref } }) => {
                        return (
                          <Select
                            className="w-full"
                            placeholder={t("Country")}
                            classNames={{
                              input: () => "[&_input:focus]:ring-0",
                              menu: () => menuStyle,
                              option: ({ isFocused, isSelected }) =>
                                clsx(
                                  isFocused && optionStyles.focus,
                                  isSelected && optionStyles.Selected
                                ),
                              control: () => baseStyle,
                            }}
                            styles={customStyles}
                            isClearable
                            isSearchable
                            options={countries.data.map((country) => ({
                              value: country.id,
                              label: country.name,
                            }))}
                            name={name}
                            ref={ref}
                            {...field}
                          />
                        );
                      }}
                    />
                  )}
                  <Typography variant="small" color="red">
                    {errors.country?.message || ""}
                  </Typography>
                </div>

                <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                  <label className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">
                    {t("State")}{" "}
                    <span className="text-red-500 text-base font-normal leading-snug">
                      *
                    </span>
                  </label>
                  {states.loading && <Loader slim />}
                  {!states.loading && (
                    <Controller
                      className="w-full"
                      control={control}
                      name="state"
                      rules={{ required: t("State is required") }}
                      render={({ field, field: { name, ref } }) => {
                        return (
                          <Select
                            className="w-full"
                            placeholder={t("State")}
                            classNames={{
                              input: () => "[&_input:focus]:ring-0",
                              menu: () => menuStyle,
                              option: ({ isFocused, isSelected }) =>
                                clsx(
                                  isFocused && optionStyles.focus,
                                  isSelected && optionStyles.Selected
                                ),
                              control: () => baseStyle,
                            }}
                            styles={customStyles}
                            isClearable
                            isSearchable
                            options={states.data.map((state) => ({
                              value: state.id,
                              label: state.name,
                            }))}
                            name={name}
                            ref={ref}
                            {...field}
                          />
                        );
                      }}
                    />
                  )}
                  <Typography variant="small" color="red">
                    {errors.state?.message || ""}
                  </Typography>
                </div>

                <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                  <label className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">
                    {t("City")}{" "}
                    <span className="text-red-500 text-base font-normal leading-snug">
                      *
                    </span>
                  </label>
                  {cities.loading && <Loader slim />}
                  {!cities.loading && (
                    <Controller
                      className="w-full"
                      control={control}
                      name="city"
                      rules={{ required: t("City is required") }}
                      render={({ field, field: { name, ref } }) => {
                        return (
                          <Select
                            className="w-full"
                            placeholder={t("City")}
                            classNames={{
                              input: () => "[&_input:focus]:ring-0",
                              menu: () => menuStyle,
                              option: ({ isFocused, isSelected }) =>
                                clsx(
                                  isFocused && optionStyles.focus,
                                  isSelected && optionStyles.Selected
                                ),
                              control: () => baseStyle,
                            }}
                            styles={customStyles}
                            isClearable
                            isSearchable
                            options={cities.data.map((city) => ({
                              value: city.id,
                              label: city.name,
                            }))}
                            name={name}
                            ref={ref}
                            {...field}
                          />
                        );
                      }}
                    />
                  )}
                  <Typography variant="small" color="red">
                    {errors.city?.message || ""}
                  </Typography>
                </div>

                <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                  <label className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">
                    {t("Address")}
                  </label>
                  <textarea
                    error={!!errors.address}
                    {...register("address")}
                    type="text"
                    className="w-full p-3 bg-slate-100 border-slate-100 rounded-lg "
                    placeholder={t("Address")}
                  />
                </div>
                <Checkbox
                  {...register("terms", {
                    required: t("Terms and Conditions must be accepted"),
                  })}
                  label={
                    <Typography
                      variant="small"
                      color={dark ? "white" : null}
                      className="flex items-center font-normal"
                    >
                      {t("I agree on the")}
                      <button
                        type="button"
                        onClick={() => setOpen(true)}
                        className="font-medium transition-colors hover:text-gray-900"
                      >
                        &nbsp;{t("Terms and Conditions")}
                      </button>
                    </Typography>
                  }
                  className={errors.terms ? "border-red-500" : null}
                  containerProps={{ className: "-ml-2.5" }}
                />
                <div className="h-[60px] w-full flex justify-center mb-5">
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_SITE_KEY}
                    ref={recaptchaRef}
                  />
                </div>
              </div>
              <div className="w-full flex flex-col items-center gap-4">
                {renderNavigation()}
                <div className="">
                  <span className="text-red text-sm font-normal leading-tight">
                    {t("Already have an account?")}{" "}
                  </span>
                  <Link
                    to={"/login"}
                    className="text-red text-sm font-normal underline leading-tight"
                  >
                    {t("Login")}
                  </Link>
                </div>
              </div>
            </div>
          </section>
        </form>
      </div>
    </section>
  );
}

export default Register;