// noinspection JSValidateTypes
import React, { useState, useEffect } from 'react';
import { Checkbox, Typography} from "@material-tailwind/react";
import {useForm} from "react-hook-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faXmark} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate ,useLocation} from "react-router-dom";
import {login} from "../../store/dispatches/auth";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import loginImg from "../../assets/images/login/picture.png";
import {  FormControlLabel } from '@mui/material';
import google from "../../assets/images/login/google.svg";
import facebook from "../../assets/images/login/facebook.svg";
import { Button } from 'flowbite-react';
import axios from 'axios';
import { data } from 'autoprefixer';



function Login({ }) {
  const { register:registerLogin, setError:setErrorLogin, setValue:setValueLogin, formState: { errors:errorsLogin }, handleSubmit:handleSubmitLogin } = useForm()
  const { register:registerForgot, formState: { errors:errorsForgot }, handleSubmit:handleSubmitForgot } = useForm()
  const dark = useSelector(state => state.ui.dark);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {t ,i18n } = useTranslation();
  const [showPassword , setShowPassword]= useState(false);

  const location = useLocation();

  // Check for scrollToTop state in location
  const shouldScrollToTop = location.state?.scrollToTop;

  // ... Login form logic

  useEffect(() => {
    if (shouldScrollToTop) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [shouldScrollToTop]);

  const onSubmit = (data) => {
    login(data.email, data.password, dispatch , navigate)
        .then(value => {
          if ( value === true ) {
            toast.success(t("Success in signing in."))

          } else if (value === false ) {
            toast.error(t("Invalid Login Credentials."))

            setErrorLogin('email', { type: 'custom', message: t('Invalid E-mail/Password Combination') });
            setErrorLogin('password', { type: 'custom', message: t('Invalid E-mail/Password Combination') });
            setValueLogin('email', '')
            setValueLogin('password', '')
          }
        })
    ;
  }


  const [sendEmail , setSendEmail] = useState(false);

  const sendCode =(data)=>{
    data={
      email: data.Vemail, 
    };
    console.log("data:",data)
    axios.post('/api/forgot-password', data)
    .then((res)=>{
      console.log("codee",res.data)
      if(!res.data.result?.success){
        toast.error(res.data.result?.msg || "Request failed!");
        return;
      }
      toast.success(t("Verification code sent!"));
      navigate('/Forgot-change-password')
    })
    .catch((err) => {
      console.log(err);
    })}
     
  const toggleMenu = ( ) => {
    setSendEmail(!sendEmail);
  }

  const eyeIcon = showPassword ? faEye : faEyeSlash;
  const iconPosition = t('language') === 'en' ? 'right-3' : 'left-3';
  return (
      <section className="flex justify-center">
         {sendEmail && (
          <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-10 bg-gray-800 bg-opacity-50">
            <div className="bg-white p-8 rounded-lg md:w-1/2 w-full mx-2">
              <form onSubmit={handleSubmitForgot(sendCode)} >
               <FontAwesomeIcon icon={faXmark} size='2xl' className=' ' onClick={(e) => toggleMenu()} />
                <h1 className='flex justify-center p-4 text-base md:text-2xl font-bold'>{t("Forgot Password")}</h1>
                <div className="flex-col justify-start gap-2">
                  <label>{t("Email")}<span className="text-red-500 text-base font-normal leading-snug">
                      *
                    </span></label>
                  <input {...registerForgot("Vemail", {required: t('E-mail is required')})}
                      type='text' className="w-full p-3 bg-slate-100 border-slate-100 rounded-lg " placeholder={t('Email Address')}  />
                    {errorsForgot.Vemail &&
                  <Typography variant="small" color="red">
                    {errorsForgot.Vemail?.message}
                  </Typography>
              }
              </div>

                <button type='submit' className="h-[42px] px-[18px] py-2.5 rounded-[10px]  text-white bg-red shadow border border-red flex justify-center items-center gap-2.5 mt-4" 
                >
                {t("Send")}
                </button>
                
              </form>
             
             
            </div>
          </div>
        )}
        <div className="w-1/2 hidden xl:block">
          <img className="w-full" src={loginImg} alt='login'/>
        </div>
        <div className="xl:px-24 px-10 py-10 w-full lg:w-1/2 mt-5">
          <div className="w-full justify-center items-center gap-2 inline-flex mb-12">
            <div className="grow shrink basis-0 h-[0px] border border-red"></div>
            <div className="grow shrink basis-0 flex-col justify-start items-center gap-2 inline-flex">
            <div className="text-red text-2xl text-nowrap font-normal leading-relaxed">{t("Sign in")}</div>
            </div>
            <div className="grow shrink basis-0 h-[0px] border border-red"></div>
          </div>
          <form onSubmit={handleSubmitLogin (onSubmit)} >
            <div className="w-full flex-col justify-start items-start gap-4 inline-flex mb-10">
                <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                    <label className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">{t("Email Address")}</label>
                    <input {...registerLogin("email", {required: t('E-mail is required')})}
                      type='text' className="w-full p-3 bg-slate-100 border-slate-100 rounded-lg " placeholder={t("Email Address")}/>
                    {errorsLogin.email &&
                  <Typography variant="small" color="red">
                    {errorsLogin.email?.message}
                  </Typography>
              }
                </div>
                <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                    <label className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">{t("Password")}</label>
                    <div className="w-full  relative">
                     
                      <input {...registerLogin("password", {required: t('Password is required')})}
                      type={showPassword ? "text" : "password"} className="w-full p-3 bg-slate-100 border-slate-100 rounded-lg" placeholder={t('Password')}/>
                       {showPassword ? 
                         <FontAwesomeIcon icon={faEyeSlash} onClick={()=>setShowPassword((prevState) =>!prevState)} className={`absolute ${i18n.language === 'en' ? 'right-3' : 'left-3'} top-4 text-zinc cursor-pointer`} /> :
                         <FontAwesomeIcon icon={faEye} onClick={()=>setShowPassword((prevState) =>!prevState)}  className={`absolute ${i18n.language === 'en' ? 'right-3' : 'left-3'} top-4 text-zinc cursor-pointer`} />
                      }
                    </div> 
                    {errorsLogin.password &&
                  <Typography variant="small" color="red">
                    {errorsLogin.password?.message}
                  </Typography>
              }
                </div>
                <div className="w-full flex justify-between items-center">
                  <div className="justify-start items-center gap-2 flex">
                        <FormControlLabel className="text-zinc-800 text-sm font-normal font-['Roboto'] leading-tight" control={<Checkbox />} label={t("Remember me")} />
                      </div>
                      <div className="justify-end items-center gap-2 flex">
                      <button onClick={(e) => toggleMenu()}
                      className="grow shrink basis-0 text-right text-red text-sm font-normal underline leading-tight">{t("Forgot Password?")}</button>
                      </div>
                  </div>
            </div>
            <div className="w-full flex flex-col items-center gap-4">
              <button type={"submit"} className="w-1/2 px-3 py-4 bg-red rounded-lg justify-center items-center inline-flex">
                <span className="text-white text-base font-medium leading-none tracking-wide">{t("Log In")}</span>
              </button>
              <div className=""><span className="text-red text-sm font-normal leading-tight">{t("No account yet?")} </span><Link to={"/register"} className="text-red text-sm font-normal underline leading-tight">{t("Sign Up")}</Link></div>
            </div>
          </form>

          <div className="w-full justify-center py-6">
            <div className="w-full justify-between items-center inline-flex">
              <div className="w-full h-[0px] border"></div>
             {/* <div className="text-[#999999] text-base font-light leading-none tracking-wide">or</div>
              <div className="w-40 h-[0px] border "></div> */}
            </div>
          </div>

          {/* <div className="w-full flex flex-col gap-4">
            <button className="w-full px-10 py-4 rounded-lg border border-red justify-center items-center gap-6 inline-flex">
              <img src={google} alt='google'/>
              <span className="text-black text-sm font-medium leading-snug">Continue with Google</span>
            </button>
            <button className="w-full px-10 py-4 rounded-lg border border-red justify-center items-center gap-6 inline-flex">
              <img src={facebook} alt='apple'/>
              <span className="text-black text-sm font-medium leading-snug">Continue with Facebook</span>
            </button>
          </div> */}

        </div>
      </section>
  );
}

export default Login;