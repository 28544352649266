import React, { useEffect, useState } from "react";
import { Typography } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";
import error from "../../Error";
import useAxiosUserSecureConfig from "../../../hooks/useAxiosUserSecureConfig";
import Select from "react-select";
import Loader from "../../../components/Loader";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import placholder_image from "../../../assets/images/booking/placeholder-image.jpg";
import uplooad from "../../../assets/images/icons/upload.svg";
import { useTable, useSortBy, usePagination } from 'react-table';
import editIcon from "../../../assets/images/icons/edit.png";
import trashIcon from "../../../assets/images/icons/trash.png";



function SupplierAddProduct() {
  const user = useSelector((state) => state.auth.user);
  const { t,i18n } = useTranslation();
  const navigate = useNavigate();
  const {
    register,
    control,
    setError,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const columns = React.useMemo(
    () => [ 
  { Header: 'Product Image', accessor: 'productImage' },
  { Header: t('Product Name'), accessor: 'productName' },
  { Header: t('Product Color'), accessor: 'productColor' },
  { Header: t('Product Size'), accessor: 'productSize' },
  { Header: t('Product Condition'), accessor: 'productCondition' },
  { Header: t('Product Price'), accessor: 'productPrice' },
  { Header: t('Product Enabled'), accessor: 'productavailable' },
    ],
    [t]
  );

  const [categories, setCategories] = useState({ loading: true, data: [] });
  useEffect(() => {
    axios
      .post(`/api/product_categories`, {},
        {headers: {
        'Accept-Language': 'ar'}
      }
    )
      .then((res) => {
        console.log("categories", res.data);

        if (!res.data?.result?.success) {
          toast.error("Error while fetching categories!");
          return;
        }

        setCategories((prevState) => ({
          ...prevState,
          loading: false,
          data: res.data?.result?.categories ?? [],
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const [brands, setBrands] = useState({ loading: true, data: [] });
  useEffect(() => {
    axios
      .post(`/api/product/brands`, {})
      .then((res) => {
        console.log("brands", res.data);

        if (!res.data?.result?.success) {
          toast.error("Error while fetching brands!");
          return;
        }

        setBrands((prevState) => ({
          ...prevState,
          loading: false,
          data: res.data?.result?.brands ?? [],
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const [colors, setColors] = useState({ loading: true, data: [] });
  useEffect(() => {
    axios
      .post(`/api/product/colors`, {})
      .then((res) => {
        console.log("colors", res.data);

        if (!res.data?.result?.success) {
          toast.error("Error while fetching colors!");
          return;
        }

        setColors((prevState) => ({
          ...prevState,
          loading: false,
          data: res.data?.result?.colors ?? [],
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const [sizes, setSizes] = useState({ loading: true, data: [] });
  useEffect(() => {
    axios
      .post(`/api/product/sizes`, {})
      .then((res) => {
        console.log("sizes", res.data);

        if (!res.data?.result?.success) {
          toast.error("Error while fetching sizes!");
          return;
        }

        setSizes((prevState) => ({
          ...prevState,
          loading: false,
          data: res.data?.result?.sizes ?? [],
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const customStyles = {
    control: (styles) => ({
      ...styles,
      background: "#F2F4F8",
      "border-color": "#F2F4F8",
      "border-radius": "8px",
      padding: "6px",
     
    }),
  };
  const [productsvariant , setProductsvariant] = useState({
    loading: true,
    data: [],
  });
  const [loading , setLoading] = useState(false)
  const config = useAxiosUserSecureConfig();
  axios.defaults.timeout = 50000
  axios.defaults.timeoutErrorMessage='timeout'
  
  function onSubmit(data) {
    const selectedSizes = data.sizes.map(size => size.value);
    const selecteColors = data.colors.map(size => size.value);
    data = {
      name: data.name,
      categ_id: data.category.value,
      list_price: Number(data.price),
      description: data.description,
      weight: data.weight,
      colors: selecteColors,
      sizes : selectedSizes,
      brand: data.brand,
      image_1920 :imageBase64.split(",")[1]
    };
    setLoading(true)
    console.log("data", data);
    axios
      .post("/api/register_product", data, config)
      .then((res) => {
        if (!res.data.result?.success) {
          console.log(res.data)
          toast.error(res.data.result?.message || "Request failed!");
          setLoading(false)
          return;
        }
        toast.success("You add product successfully! Now, complete the rest of the product details.");
        navigate(`/ProductSupplier/edit-product/${res.data.result.data.id}`);
        setLoading(false)
      })
      .catch((err) => {
        console.log("err", err);
        toast.error("Error while adding product!");
        setLoading(false)
      });
  }

  console.log("productsvariant", productsvariant)
  const [imageBase64, setImageBase64] = useState(placholder_image);
  const [imageName, setImageName] = useState("");

  const handleImageChange = (e) => {
    setImageName(e.target.files[0].name);
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Extract base64 data without the prefix
        const base64WithoutPrefix = reader.result;
        setImageBase64(base64WithoutPrefix);
      };
      reader.readAsDataURL(file);
    }
  };

  const reqestsData = React.useMemo(() => {
    return productsvariant.data.length > 0
      ? productsvariant.data
          .map((key) => ({
            id: key.id,
            productImage :'',
            productName: key.name2,
            productColor : key.color,
            productSize: key.size,
            productCondition: key.condition,
            productPrice : key.price,
            productavailable : key.available,
          }))
      : [];
  }, [productsvariant.data]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data: reqestsData,
      initialState: { pageIndex: 0, pageSize:  4 }, 
    },
    useSortBy,
    usePagination
  );

  return (
    <section className="px-5 xl:px-20 py-10">
      <div className="flex justify-between items-center mb-5">
        <div className="flex items-center gap-6">
          <div className="justify-start items-center gap-2  hidden sm:inline-flex">
            <div
              onClick={() => navigate("/product-Supplier/Account")}
              className="text-zinc-400 text-sm sm:text-base font-normal sm:leading-[48px] hover:text-gray-dark cursor-pointer"
            >
              {t("Product Supplier Account")}
            </div>
            <FontAwesomeIcon
              className="text-zinc-400 text-base"
              icon={faAngleRight}
            />
            <div
              onClick={() => navigate("/productSupplier/productList")}
              className="text-zinc-400 text-sm sm:text-base font-normal sm:leading-[48px] hover:text-gray-dark cursor-pointer"
            >
              {t("My Products")}
            </div>
            <FontAwesomeIcon
              className="text-zinc-400 text-base"
              icon={faAngleRight}
            />
            <div className="text-zinc-400 text-sm sm:text-base font-normal sm:leading-[48px] hover:text-gray-dark cursor-pointer">
            {t("My Products")}
            </div>
          </div>
        </div>
      </div>
      {loading && <Loader slim />}
      {!loading && (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col gap-10"
        >
          <div className=" w-[90%] mx-auto self-stretch flex-col justify-center flex">
            <p className="text-lg md:text-2xl font-semibold leading-9 mb-6 text-red">
              {t("Add Products")}
            </p>
            <div className="w-full grid grid-col-1 md:grid-cols-2 lg:grid-cols-3 justify-start items-start gap-4 ">
              <div className="flex w-full flex-col justify-start items-start gap-2 ">
                <label className="text-zinc-800 text-base font-normal leading-snug">
                  {t("Category")}{" "}
                  <span className="text-red-500 text-base font-normal leading-snug">
                    *
                  </span>
                </label>
                {categories.loading && <Loader slim />}
                {!categories.loading && (
                  <Controller
                    control={control}
                    name="category"
                    className="w-full"
                    rules={{ required: t("category is required") }}
                    render={({ field, field: { name, ref } }) => {
                      return (
                        <Select
                          styles={customStyles}
                          name={name}
                          ref={ref}
                          isClearable
                          isSearchable
                          placeholder={t("Select...")}
                          options={categories.data.map((category) => ({
                            value: category.id,
                            label: category.name,
                          }))}
                          {...field}
                          className="w-full"
                        />
                      );
                    }}
                  />
                )}
                {errors.category && (
                  <Typography variant="small" color="red">
                    {errors.category?.message}
                  </Typography>
                )}
              </div>

              <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                <label className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">
                  {t("Name")}{" "}
                  <span className="text-red-500 text-base font-normal leading-snug">
                    *
                  </span>
                </label>
                <div className="relative w-full">
                  <input
                    error={!!errors.name}
                    {...register("name", {
                      required: t("Product name is required"),
                    })}
                    className="w-full p-3 bg-slate-100 border-slate-100 rounded-lg "
                    placeholder={t("Product name")}
                  />
                </div>
                {errors.name && (
                  <Typography variant="small" color="red">
                    {errors.name?.message}
                  </Typography>
                )}
              </div>

              <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                <label className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">
                  {t("Price")}{" "}
                  <span className="text-red-500 text-base font-normal leading-snug">
                    *
                  </span>
                </label>
                <div className="relative w-full">
                  <input
                    type="number"
                    error={!!errors.price}
                    {...register("price", {
                      required: t("Price is required"),
                    })}
                    className="w-full p-3 bg-slate-100 border-slate-100 rounded-lg "
                    placeholder={t("Product price")}
                  />
                </div>
                {errors.price && (
                  <Typography variant="small" color="red">
                    {errors.price?.message}
                  </Typography>
                )}
              </div>
              <div className="flex gap-2 items-end">
                <img
                  src={imageBase64}
                  alt="imageBase64"
                  className="w-20 h-[50px] object-cover rounded-lg border"
                />
                <div className="flex flex-col justify-start items-start gap-2 grow">
                  <label className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">
                    {t("Image")}{" "}
                    <span className="text-red-500 text-base font-normal leading-snug">
                      *
                    </span>
                  </label>

                  <div className="relative w-full bg-slate-100 border-slate-100 rounded-lg">
                    <label>
                      <div className="h-12 flex justify-between items-center">
                        {/* Hidden file input */}
                        <input
                          hidden
                          type="file"
                          {...register("productImage", {
                            required: t("Product image is required"),
                          })}
                          onChange={handleImageChange}
                          id="fileInput" // Add an ID for labeling purposes
                        />

                        {/* Upload icon */}
                        <label htmlFor="fileInput" className="cursor-pointer">
                          <img
                            src={uplooad}
                            className={`absolute ${i18n.language === 'en' ? 'right-3' : 'left-3'}  bottom-3`}
                            alt="upload"
                          />
                        </label>

                        {/* Placeholder and image name */}
                        <input
                          type="text"
                          className="border-0 w-full flex-grow p-4 bg-slate-100 rounded-lg text-zinc-500 text-base font-normal leading-tight placeholder-[#808080]"
                          placeholder={t("Choose a file")}
                          value={imageName ? imageName : ""}
                          readOnly
                        />
                      </div>
                    </label>
                  </div>
                  {errors.productImage && (
                    <Typography variant="small" color="red">
                      {errors.productImage?.message}
                    </Typography>
                  )}
                </div>
              </div>
              <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                <label className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">
                  {t("Brand")}
                </label>
                <input
                  {...register("brand")}
                  className="w-full p-3 bg-slate-100 border-slate-100 rounded-lg "
                  placeholder={t("Product name")}
                />
              </div>
              <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                <label className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">
                  {t("Weight")}{" "}
                  <span className="text-red-500 text-base font-normal leading-snug">
                    *{" "}
                  </span>
                  <span className="text-gray-400 text-base font-light lead">
                    {t("(Kg)")}
                  </span>
                </label>
                <div className="relative w-full">
                  <input
                    type="text"
                    error={!!errors.weight}
                    {...register("weight", {
                      required: t("weight is required"),
                    })}
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9.]/g, "");
                    }}
                    className="w-full p-3 bg-slate-100 border-slate-100 rounded-lg"
                    placeholder={t("Product weight")}
                  />
                </div>
                {errors.weight && (
                  <Typography variant="small" color="red">
                    {errors.weight?.message}
                  </Typography>
                )}
              </div>
              <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                <label className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">
                  {t("Description")}
                </label>
                <div className="relative w-full">
                  <input
                    {...register("description")}
                    className="w-full p-3 bg-slate-100 border-slate-100 rounded-lg "
                    placeholder={t("Product description")}
                  />
                </div>
              </div>
              <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                <label className="text-zinc-800 text-base font-normal leading-snug">
                  {t("Colors")}{" "}
                  <span className="text-red-500 text-base font-normal leading-snug">
                    *
                  </span>
                </label>
                {colors.loading && <Loader slim />}
                {!colors.loading && (
                  <Controller
                    control={control}
                    name="colors"
                    rules={{ required: t("color is required") }}
                    render={({ field, field: { name, ref } }) => {
                      return (
                        <Select
                          styles={customStyles}
                          name={name}
                          ref={ref}
                          isClearable
                          isSearchable
                          placeholder={t("Select...")}
                          options={colors.data.map((color) => ({
                            value: color,
                            label: color,
                          }))}
                          {...field}
                          className="w-full"
                          isMulti
                        />
                      );
                    }}
                  />
                )}
                {errors.colors && (
                  <Typography variant="small" color="red">
                    {errors.colors?.message}
                  </Typography>
                )}
              </div>
              <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                <label className="text-zinc-800 text-base font-normal leading-snug">
                  {t("Sizes")}{" "}
                  <span className="text-red-500 text-base font-normal leading-snug">
                    *
                  </span>
                </label>
                {sizes.loading && <Loader slim />}
                {!sizes.loading && (
                  <Controller
                    control={control}
                    name="sizes"
                    rules={{ required: t("size is required") }}
                    render={({ field, field: { name, ref } }) => {
                      return (
                        <Select
                          styles={customStyles}
                          name={name}
                          ref={ref}
                          isClearable
                          placeholder={t("Select...")}
                          isSearchable
                          options={sizes.data.map((size) => ({
                            value: size,
                            label: size,
                          }))}
                          {...field}
                          className="w-full"
                          isMulti
                        />
                      );
                    }}
                  />
                )}
                {errors.sizes && (
                  <Typography variant="small" color="red">
                    {errors.sizes?.message}
                  </Typography>
                )}
              </div>
            </div>
          </div>
          <div className="flex justify-end w-[90%] mx-auto">
            <button
              type="submit"
              className="w-fit px-16 py-3 bg-red rounded-[10px] shadow border border-white justify-center items-center gap-2.5 inline-flex"
            >
              <div className="text-center text-white text-base font-normal leading-normal">
                {t("Next")}
              </div>
            </button>
          </div>
        </form>
      )}
    </section>
  );
}

export default SupplierAddProduct;
