import { createSlice } from "@reduxjs/toolkit";

const uiSlice = createSlice({
  name: "ui",
  initialState: { isLoading: false, messages: {items: [], loading: true , unread_count : 0 }, dark: false },
  reducers: {
    startLoading(state) {
      console.log("Start Loading")
      state.isLoading = true;
    },
    stopLoading(state) {
      console.log("Stop Loading")
      state.isLoading = false;
    },
    toggleDarkMode(state) {
      state.dark = !state.dark;
    },
    setMessages(state, action) {
      state.messages = action.payload;
      localStorage.setItem("messages", JSON.stringify(action.payload.items))
    },
    loadMessagesFromLocalStorage(state, action) {
      if (localStorage.getItem("messages")) {
        state.messages.items = JSON.parse(localStorage.getItem("messages"));
      }
    },
    storeMessagesToLocalStorage(state) {
      localStorage.setItem("messages", JSON.stringify(state.messages.items))
    },
  },
});

export const uiActions = uiSlice.actions;
export default uiSlice;