import React, { useEffect, useState } from "react";
import bookingIcon from "../../../assets/images/account/icons/booking.svg"
import settingsIcon from "../../../assets/images/account/icons/settings.svg"
import resetpasswordIcon from "../../../assets/images/account/icons/reset-password.svg"
import checkoutIcon from "../../../assets/images/account/icons/check-out.svg"
import {authActions} from "../../../store/slicer/auth";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import wishlistIcon from "../../../assets/images/account/icons/wish-list.svg"
import collaborationIcon from "../../../assets/images/account/icons/collaboration.svg"
import ConfirmationModal from "../../../components/ConfirmationModal";
import {useForm} from "react-hook-form";
import {toast}  from "react-toastify";
import useAxiosUserSecureConfig from '../../../hooks/useAxiosUserSecureConfig'
import {useTranslation} from "react-i18next";
import axios from "axios";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash , faXmark} from "@fortawesome/free-solid-svg-icons";
import {Typography} from "@material-tailwind/react";
import Loader from "../../../components/Loader";

function ProductAccount() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const config = useAxiosUserSecureConfig();
  const {t} = useTranslation();

  const [open, setOpen] =useState(false);
  const handleClose = () => setOpen(false);

  const handleLogout = () => {
    dispatch(authActions.logout());
    navigate('/');
  }
 

 
  return (
    <section className="px-10 py-12">
      <ConfirmationModal
      open={open}
      handleClose={handleClose}
      handleAction={handleLogout}
      msg={t("Are you sure you want to logout?")}
    />
    
      <p className="text-red text-2xl font-medium mb-6">{t("Product Supplier Account")}</p>
      <div className="py-6">

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-6 gap-y-8">
         
        <Link to={'/productSupplier/productList'} className="p-4 bg-zinc-100 rounded-[10px] border border-[#B3B3B3] justify-start items-start gap-2 inline-flex">
            <div className="flex items-center gap-4">
                <img src={settingsIcon} alt="bookingIcon"/>
                <div className="flex flex-col items-start gap-2">
                    <p className="text-red text-lg font-normal">{t("My Products")}</p>
                    <p className="text-gray-500 text-sm font-light ">{t("See and manage all your Product.")}</p>
                </div>
            </div>
          </Link>
          <Link to={'/ProductSupplier/shipment'} className="p-4 bg-zinc-100 rounded-[10px] border border-[#B3B3B3] justify-start items-start gap-2 inline-flex">
            <div className="flex items-center gap-4">
            <img src={wishlistIcon} alt="bookingIcon"/>
                <div className="flex flex-col items-start gap-2">
                    <p className="text-red text-lg font-normal">{t("My Shipments")}</p>
                    <p className="text-gray-500 text-sm font-light ">{t("See and manage all your Shipment.")}</p>
                </div>
            </div>
          </Link>
          {/* <Link to={"/ServiceProvider/booking-list"} className="p-4 bg-zinc-100 rounded-[10px] border border-[#B3B3B3] justify-start items-start gap-2 inline-flex">
            <div className="flex items-center gap-4">
                <img src={wishlistIcon} alt="bookingIcon"/>
                <div className="flex flex-col items-start gap-2">
                    <p className="text-red text-lg font-normal">Booking list</p>
                    <p className="text-gray-500 text-sm font-light ">See and manage all your Booking list.</p>
                </div>
            </div>
          </Link>
          <Link to={"/ServiceProvider/booking-requests"} className="p-4 bg-zinc-100 rounded-[10px] border border-[#B3B3B3] justify-start items-start gap-2 inline-flex">
            <div className="flex items-center gap-4">
                <img src={collaborationIcon} alt="bookingIcon"/>
                <div className="flex flex-col items-start gap-2">
                    <p className="text-red text-lg font-normal">Booking requests</p>
                    <p className="text-gray-500 text-sm font-light ">See and manage all your Booking requests.</p>
                </div>
            </div>
          </Link> */}
          <Link to={'/ProductSupplier/edit-account'} className="p-4 bg-zinc-100 rounded-[10px] border border-[#B3B3B3] justify-start items-start gap-2 inline-flex">
            <div className="flex items-center gap-4">
                <img src={settingsIcon} alt="bookingIcon"/>
                <div className="flex flex-col items-start gap-2">
                    <p className="text-red text-lg font-normal">{t("Edit account")}</p>
                    <p className="text-gray-500 text-sm font-light ">{t("Update your personal information and preferences.")}</p>
                </div>
            </div>
          </Link>
         
          <Link to={'/Change-Password'} className="p-4 bg-zinc-100 rounded-[10px] border border-[#B3B3B3] justify-start items-start gap-2 inline-flex">
            <div className="flex items-center gap-4">
                <img src={resetpasswordIcon} alt="bookingIcon"/>
                <div className="flex flex-col items-start gap-2">
                    <p className="text-red text-lg font-normal">{t("Change Password")}</p>
                    <p className="text-gray-500 text-sm font-light ">{t("Ensure your account security by updating your password.")}</p>
                </div>
            </div>
          </Link> 
          
          <button onClick={() => {
              setOpen(true)
            }} className="p-4 bg-zinc-100 rounded-[10px] border border-[#B3B3B3] justify-start items-start gap-2 inline-flex">
            <div className="flex items-center gap-4">
                <img src={checkoutIcon} alt="bookingIcon"/>
                <div className="flex flex-col items-start gap-2">
                    <p className="text-red text-lg font-normal">{t("Logout")}</p>
                    <p className="text-gray-500 text-sm font-light ">{t("Sign out of your account.")}</p>
                </div>
            </div>
          </button>
          
        </div>
      </div>
    </section>
  );
}

export default ProductAccount;
