import React from "react";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Modal } from "@mui/material";
import {useTranslation} from "react-i18next";

function ConfirmationModal({open , handleClose, handleAction ,msg}) {
  const {t} = useTranslation();
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="absolute md:w-2/5 w-[90%] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 px-8 py-6 bg-white rounded-2xl shadow flex-col justify-center items-start inline-flex border-4 border-red">
        <div className="w-full">
          <div className="flex justify-end">
            <button
              onClick={() => handleClose()}
              className="self-stretch justify-between items-center flex"
            >
              <FontAwesomeIcon icon={faX} className="text-xl text-gray-500" />
            </button>
          </div>

          <p className="text-center text-zinc-800 text-xl font-normal mb-8">
            {msg}
          </p>
          <div className="w-full flex justify-center gap-5">
            <button
              onClick={() => handleClose()}
              className="w-1/3 h-[47px] px-[18px] py-[6.40px] bg-white rounded-[10px] shadow border border-red justify-center items-center gap-2.5 inline-flex"
            >
              <span className="text-center text-red text-base font-normal font-['Roboto'] leading-normal">
                {t("Cancel")}
              </span>
            </button>
            <button
              onClick={() => handleAction()}
              className="w-1/3 h-[47px] px-[18px] py-[6.40px] bg-red rounded-[10px] shadow border border-red justify-center items-center gap-2.5 inline-flex"
            >
              <span className="text-center text-white text-base font-normal font-['Roboto'] leading-normal">
                {t("Confirm")}
              </span>
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  );
}

export default ConfirmationModal;
