import React, { useEffect, useState } from 'react';
import { useTable, useSortBy, usePagination } from 'react-table';
import ReactPaginate from 'react-paginate';
import useAxiosUserSecureConfig from '../../hooks/useAxiosUserSecureConfig';
import axios from 'axios';
import { toast } from 'react-toastify';
import Loader from '../../components/Loader';
import { useNavigate } from 'react-router';
import editIcon from "../../assets/images/icons/edit.png";
import trashIcon from "../../assets/images/icons/trash.png";
import eyeIcon from "../../assets/images/icons/eye.png";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { Controller, useForm } from "react-hook-form";
import Select from 'react-select';
import ConfirmationModal from '../../components/ConfirmationModal';
import { useSelector } from "react-redux";
import filterIcon from "../../assets/images/sevices/filter.png";
import { useTranslation } from "react-i18next";



function ServicesList() {

  const { t, i18n } = useTranslation();
  const [currentPage, setCurrentPage] = useState(0);
  const config = useAxiosUserSecureConfig();
  const user = useSelector(state => state.auth.user)
  const [services, setServices] = useState({ loading: true, data: [] });
  const [filter, setFilter] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);

  const columns = React.useMemo(
    () => [
      { Header: t('Service Name'), accessor: 'serviceName' },
      { Header: t('Service Category'), accessor: 'serviceCategory' },
      { Header: t('Service Description'), accessor: 'serviceDescription' },
      { Header: t('Service Price'), accessor: 'servicePrice' },
     ],
    [t]
  );
  const handleFilterChange = (value) => {
    setFilter(value);
    setShowDropdown(false);
  };


const fetchData = () => {
  axios.post('/api/my_services', {}, config)
    .then((res) => {
      console.log("services", res.data);
      if (!res.data?.result?.success) {
        toast.error("Error while fetching services!");
        return;
      }
      setServices({
        loading: false,
        data: res.data?.result?.products || [],
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
useEffect(() => {
  fetchData();
}, []);

  const reqestsData = React.useMemo(() => {
    return services.data.length > 0
      ? services.data
      .filter((key) => key.enabled_by_user === true)
          .map((key) => ({
            id: key.id,
            serviceName: key.name,
            serviceCategory : key.category.name,
            serviceDescription: key.description.replace(/<\/?p>/g, ''),
            servicePrice: key.list_price,
            categoryId : key.category.id,
            categoryName : key.category.name,
          }))
      : [];
  }, [services.data]);
  console.log( "request",reqestsData)
  const filteredRows = React.useMemo(() => {
    return filter ? reqestsData?.filter((row) => row.categoryId === filter) : reqestsData;
  }, [filter, reqestsData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data: filteredRows,
      initialState: { pageIndex: currentPage, pageSize:  20 }, 
    },
    useSortBy,
    usePagination
  );
  const [categories, setCategories] = useState({ loading: true, data: [] });
  useEffect(() => {
    axios.post(`/api/vendor/${user.partnerId}`, {id: user.partnerId}).then((res) => {
        console.log("categories", res.data);

        if (!res.data?.result?.success) {
            toast.error("Error while fetching categories!");
            return;
        }

        const categories =
          res.data?.result?.vendor?.services.map(
            (service) => service.category
          ) ?? [];

          const uniqueCategories = categories.filter((category, index, self) =>
          index === self.findIndex((c) => c.name === category.name)
      );

        setCategories((prevState) => ({
          ...prevState,
          loading: false,
          data: uniqueCategories,
        }));
    }).catch((err) => {
        console.log(err);
    })
}, [])
console.log("cate",categories)

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const navigate = useNavigate()
  const {
    control,
  } = useForm();
  const [open, setOpen] =useState(false);
  const [servId , setServId] = useState();
  const handleClose = () => setOpen(false); 
  const handleDeleteService = () => {
    setOpen(false)
    axios
      .post(`/api/service/${servId}/delete`, {}, config)
      .then((res) => {
        if (!res.data.result?.success) {
          console.log("res",res.data)
          toast.error(res.data.result?.message || t("Request failed!"));
          return;
        }
        fetchData();
        toast.success(t("You delete service successfully!"));
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(t("Error while deleting service!"));
      });
  };
  
  const customStyles = {
    control: (styles) => ({
      ...styles,
      background: "#F2F4F8", "border-color": "#F2F4F8" , "border-radius" :"8px" ,padding: "6px" })
    }

  return (
    <section className="md:px-10 px-5 py-12">
      <ConfirmationModal
        open={open}
        handleClose={handleClose}
        handleAction={handleDeleteService}
        msg={t("Are you sure you want to delete this service?")}
      />
      <div className="flex justify-between items-center mb-5">
        <div className="flex items-center gap-6">
          <div className="justify-start items-center gap-2  hidden sm:inline-flex">
            <div
              onClick={() => navigate('/vendor/account')}
              className="text-zinc-400 text-base font-normal leading-[48px] hover:text-gray-dark cursor-pointer"
            >
              {t("My Account")}
            </div>
            <FontAwesomeIcon
              className="text-zinc-400 text-base"
              icon={faAngleRight}
            />
            <div className="text-zinc-400 text-base font-normal leading-[48px] hover:text-gray-dark cursor-pointer">
              {t("My Services")}
            </div>
          </div>
        </div>
       
      </div>
      <div className="flex justify-between items-center mb-8">
        <div className="justify-start items-center gap-2 inline-flex">
          <div className="md:text-2xl text-base font-semibold leading-9">{t("My Services")}</div>
          <div className="md:text-base text-sm font-light leading-normal">
            ({reqestsData.length})
          </div>
        </div>
        <div className="relative ">
          <div className='flex flex-col md:flex-row gap-2 w-36 md:w-80'>
        <Link
          to={"/vendor/add-services"}
          className="px-4 md:px-8 w-full  py-[9px] rounded-[10px] shadow border border-red justify-center items-center gap-2.5 flex"
        >
          <span className="text-center text-red text-base font-medium font-['Inter'] leading-tight">
            {t("Add Service")}
          </span>
        </Link>
          <button
            className="px-4 w-full py-2  rounded-lg border border-red justify-center items-center flex gap-2"
            onClick={() => setShowDropdown(!showDropdown)}
          >
            <img src={filterIcon} alt="filterIcon" />
            <span className="text-red text-base font-medium font-['Inter'] leading-none tracking-wide">
              {t("Category")}
            </span>
            <FontAwesomeIcon
              className="text-red text-base font-medium leading-none tracking-wide"
              icon={faCaretDown}
            />
          </button>
          </div>
          {showDropdown && (
            <ul className="absolute right-0 mt-2 py-2 w-48 bg-white border border-gray-200 rounded-lg shadow-lg z-10">
              <li
                className="cursor-pointer px-4 py-2 hover:bg-gray-100"
                onClick={() => handleFilterChange(null)}
              >
                {t("Show All")}
              </li>
              {categories.data.map((category) => (
                <li
                  className="cursor-pointer px-4 py-2 hover:bg-gray-100"
                  onClick={() => handleFilterChange(category.id)}
                >
                  {category.name}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      {services.loading && <Loader slim />}
      {!services.loading && (
        <div className='overflow-x-auto'>
        <table
          {...getTableProps()}
          className="w-full table-auto border-collapse mb-10"
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                className="h-12 bg-slate-100"
                {...headerGroup.getHeaderGroupProps()}
              >
                <th className="py-2 px-4 border text-gray-500 text-base font-medium leading-snug">
                  {"#"}
                </th>{" "}
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={`py-2 px-4 border text-gray-500 text-base font-medium leading-snug ${
                      column.isSorted
                        ? column.isSortedDesc
                          ? "sort-desc"
                          : "sort-asc"
                        : ""
                    }`}
                  >
                    {column.render("Header")}
                  </th>
                ))}
                <th
                  colSpan="2"
                  className="py-2 px-4 border text-gray-500 text-base font-medium leading-snug"
                >
                  {t("Actions")}
                </th>
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows
              .slice(currentPage * 20, (currentPage + 1) * 20)
              .map((row, index) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    className="border-t h-12 cursor-pointer hover:bg-slate-100"
                  >
                    <td className="px-4 border text-center bg-slate-100 text-gray-500 text-xs font-light leading-none">
                      {index + 1 + currentPage * 20}
                    </td>{" "}
                    {row.cells.map((cell, cellIndex) => (
                      <td
                        {...cell.getCellProps()}
                        className="px-4 border text-center text-[#697077] text-sm font-normal leading-tight"
                        key={cellIndex}
                      >
                        {cell.render("Cell")}
                      </td>
                    ))}
                    <td className="px-2 border text-center ">
                      <button
                        onClick={() =>
                          navigate("/vendor/edit-services", {
                            state: { rowData: row.original },
                          })
                        }
                        className=""
                      >
                        <img src={editIcon} alt="editIcon" />
                      </button>
                    </td>
                    <td className="px-2 border text-center">
                      <button
                        className=""
                        onClick={() => {
                          setOpen(true);
                          setServId(row.original.id);
                        }}
                      >
                        <img src={trashIcon} alt="trashIcon" />
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        </div>
      )}
      <ReactPaginate
        breakLabel="..."
        nextLabel={t("Next >")}
        onPageChange={handlePageClick}
        pageRangeDisplayed={Math.ceil(rows.length / 20)}
        pageCount={Math.ceil(rows.length / 20)}
        previousLabel={t("< Previous")}
        renderOnZeroPageCount={null}
        containerClassName="pagination"
        pageLinkClassName="page-num"
        previousLinkClassName="page-btn"
        nextLinkClassName="page-btn"
        activeLinkClassName="active"
        disabledLinkClassName="disabled-btn"
      />
    </section>
  );
}

export default ServicesList
