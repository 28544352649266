import React, { useEffect, useState } from "react";
import { Typography } from "@material-tailwind/react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import editIcon from "../../assets/images/profile/icons/edit.svg";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import useAxiosUserSecureConfig from "../../hooks/useAxiosUserSecureConfig";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination } from "swiper/modules";
import { Navigation } from 'swiper/modules';
import placholder_image from "../../assets/images/booking/placeholder-image.jpg"
import { AiFillStar } from "react-icons/ai";
import { FaEdit } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { PhoneInput } from "react-international-phone";
import ConfirmationModal from "../../components/ConfirmationModal";
import Info from "../Info";
import {useTranslation} from "react-i18next";

function FacilityDetails() {
  const { t, i18n } = useTranslation();
  const {
    register,
    watch,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm();


  const isMobile = window.innerWidth <= 375; //  for mobile 
  const isTablet =window.innerWidth  <=425;
  const isLaptop =window.innerWidth  <1024;
  const [countries, setCountries] = useState({ loading: true, data: [] });
  const [states, setStates] = useState({ loading: false, data: [] });
  const [cities, setCities] = useState({ loading: false, data: [] });
  const [categories, setCategories] = useState({ loading: true, data: [] });

  useEffect(() => {
    axios
      .post("/api/country", {})
      .then((res) => {
        if (!res.data?.result?.success) {
          toast.error("Error while fetching countries!");
          return;
        }

        setCountries((prevState) => ({
          ...prevState,
          loading: false,
          data: res.data?.result?.countries ?? [],
        }));
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .post("/api/facility-types", {})
      .then((res) => {
        console.log("facility types", res.data);

        if (!res.data?.result?.success) {
          toast.error("Error while fetching facility types");
          return;
        }

        setCategories((prevState) => ({
          ...prevState,
          loading: false,
          data: res.data?.result?.facilityTypes ?? [],
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const country = watch("country");
  const state = watch("state");

  useEffect(() => {

    if (!country) {
      setStates((prevState) => ({
        ...prevState,
        data: [],
      }));
      setCities((prevState) => ({
        ...prevState,
        data: [],
      }));
      return;
    }

    setStates((prevState) => ({
      ...prevState,
      loading: true,
    }));

    axios
      .post(`/api/country/${country.value}`, {})
      .then((res) => {
        if (!res.data?.result?.success) {
          toast.error("Error while fetching states!");
          return;
        }

        setStates((prevState) => ({
          ...prevState,
          loading: false,
          data: res.data?.result?.countries ?? [],
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [country]);

  useEffect(() => {
    if (!state) {
      setCities((prevState) => ({
        ...prevState,
        data: [],
      }));
      return;
    }

    setCities((prevState) => ({
      ...prevState,
      loading: true,
    }));

    axios
      .post(`/api/state/${state.value}`, {})
      .then((res) => {
        if (!res.data?.result?.success) {
          toast.error("Error while fetching cities!");
          return;
        }

        setCities((prevState) => ({
          ...prevState,
          loading: false,
          data: res.data?.result?.state.cities ?? [],
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [state]);

  const params = useParams();
  const [facility, setFacility] = useState({
    loading: true,
    data: {},
  });
  const [imageBase64, setImageBase64] = useState(null);
  const handleImageChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageBase64(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  useEffect(() => {
    axios
      .post(`/api/venue-vendor/facility/${params.id}`, { id: params.id } , config)
      .then((res) => {
        console.log("facility", res.data);

        if (!res.data?.result?.success) {
          toast.error("Error while fetching facility!");
        }
        setImageBase64(res.data?.result?.image);
        setFacility({
          loading: false,
          data: res.data?.result || {}, // Set to an empty object if undefined
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [params.id]);


  const customStyles = {
    control: (styles) => ({
      ...styles,
      background: "#F2F4F8",
      "border-color": "#F2F4F8",
      "border-radius": "8px",
      padding: "6px",
    }),
  };
  const navigate = useNavigate();
  const config = useAxiosUserSecureConfig();
  const [open, setOpen] =useState(false);
  const handleClose = () => setOpen(false);
  const handleDeleteFacility = () => {
    let data = {
      facility_id: facility.data.id
  };

  axios.post('/api/venue-vendor/delete-facility' ,data, config)
      .then((res) => {
        if (!res.data.result?.success) {
          toast.error(res.data.result?.message || "Request failed!");
          return;
        }
        toast.success(t("You delete facility successfully!"));
        navigate("/vendor/Facilities")
        
      })
      .catch((err) => {
        console.log("err" , err)
        toast.error("Error while deleting facility!");
      })
  }

  return (
    <section className="px-5 xl:px-20 py-10">
      <ConfirmationModal
        open={open}
        handleClose={handleClose}
        handleAction={handleDeleteFacility}
        msg={t("Are you sure you want to delete this facility?")}
      />
      {facility.loading && <Loader slim />}
      {!facility.loading && (
        <>
          <div className="flex items-center justify-between flex-wrap sm:flex-nowrap mb-10">
            <div className="justify-start hidden items-center gap-2 sm:inline-flex mb-7">
              <div
                onClick={() => navigate("/vendor/account")}
                className="text-zinc-400 text-sm md:text-base font-normal md:leading-[48px] hover:text-gray-dark cursor-pointer"
              >
                {t("My Account")}
              </div>
              <FontAwesomeIcon
                className="text-zinc-400 text-base"
                icon={faAngleRight}
              />
              <div
                onClick={() => navigate("/vendor/Facilities")}
                className="text-zinc-400 text-sm md:text-base font-normal md:leading-[48px] hover:text-gray-dark cursor-pointer"
              >
                {t("My Facilities")}
              </div>
              <FontAwesomeIcon
                className="text-zinc-400 text-base"
                icon={faAngleRight}
              />
              <div className="text-zinc-400 text-sm md:text-base font-normal md:leading-[48px] hover:text-gray-dark cursor-pointer">
                {facility.data.name}
              </div>
            </div>
            <div className="flex gap-4">
              <Link
                to={`/vendor/edit-facility/${facility.data.id}`}
                className="sm:px-7 px-2 py-[9px] rounded-[10px] shadow border border-red justify-center items-center gap-2.5 flex"
              >
                <span className="text-center text-red text-base font-normal font-['Inter'] leading-tight">
                  {t("Edit Facility")}
                </span>
                <FaEdit className="text-red font-normal" />
              </Link>
              <button
                onClick={() => setOpen(true)}
                className="sm:px-7 px-2 py-[9px] rounded-[10px] shadow border border-red justify-center items-center gap-2.5 flex"
              >
                <span className="text-center text-red text-base font-normal font-['Inter'] leading-tight">
                  {t("Delete Facility")}
                </span>
                <RiDeleteBin6Line className="text-red text-lg font-normal" />
              </button>
            </div>
          </div>
          <form className="flex gap-20 flex-wrap md:flex-nowrap border-b pb-6 mb-6">
            <div className="flex flex-col items-center gap-6 w-full md:w-auto">
              <div className="flex flex-col gap-6 items-center">
                <img
                  className="w-[205px] h-[205px] rounded-full border object-cover"
                  src={imageBase64}
                  alt=""
                />
              </div>
            </div>
            <div className="grow">
              <div className="flex justify-between items-center mb-10">
                <p className="text-red text-2xl font-medium ">
                  {facility.data.name}
                </p>
              </div>
              <div>
                <div className="flex flex-col gap-10">
                  <div className="grid lg:grid-cols-2 gap-x-6 gap-y-4">
                    <div className="flex flex-col justify-start items-start gap-2 ">
                      <label className="text-zinc-800 text-base font-normal leading-snug">
                        {t("Facility name")}
                      </label>
                      <input
                        type="text"
                        value={facility.data.name}
                        className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                      />
                    </div>
                    <div className="flex flex-col justify-start items-start gap-2 ">
                      <label className="text-zinc-800 text-base font-normal leading-snug">
                        {t("Business website")}
                      </label>
                      <input
                        type="text"
                        value={facility.data.website}
                        className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                      />
                    </div>
                    <div className="flex flex-col justify-start items-start gap-2 ">
                      <label className="text-zinc-800 text-base font-normal leading-snug">
                        {t("Description")}
                      </label>
                      <input
                        type="text"
                        value={facility.data.description}
                        className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                      />
                    </div>

                    <div className="flex flex-col justify-start items-start gap-2">
                      <label className="text-zinc-800 text-base font-normal leading-snug">
                        {t("Type")}
                      </label>
                      <input
                        type="text"
                        value={facility.data.type.name}
                        className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                      />
                    </div>
                    <div className="flex flex-col justify-start items-start gap-2 ">
                      <label className="text-zinc-800 text-base font-normal leading-snug">
                        {t("The year your business starts")}
                      </label>
                      <input
                        type="number"
                        value={facility.data.business_start_year}
                        className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                      />
                    </div>
                    <div className="flex flex-col justify-start items-start gap-2">
                      <label className="text-zinc-800 text-base font-normal leading-snug">
                        {t("Country")}
                      </label>
                      <input
                        type="text"
                        value={facility.data.country.name}
                        className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                      />
                    </div>
                    <div className="flex flex-col justify-start items-start gap-2">
                      <label className="text-zinc-800 text-base font-normal leading-snug">
                        {t("State")}
                      </label>
                      <input
                        type="text"
                        value={facility.data.state.name}
                        className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                      />
                    </div>
                    <div className="flex flex-col justify-start items-start gap-2">
                      <label className="text-zinc-800 text-base font-normal leading-snug">
                        {t("City")}
                      </label>
                      <input
                        type="text"
                        value={facility.data.city.name}
                        className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                      />
                    </div>
                    <div className="flex flex-col justify-start items-start gap-2 ">
                      <label className="text-zinc-800 text-base font-normal leading-snug">
                        {t("Zip code")}
                      </label>
                      <input
                        type="number"
                        value={facility.data.zip_code}
                        className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                      />
                    </div>
                    <div className="flex flex-col justify-start items-start gap-2 ">
                      <label className="text-zinc-800 text-base font-normal leading-snug">
                        {t("Address")}
                      </label>
                      <input
                        type="text"
                        value={facility.data.address}
                        className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                      />
                    </div>
                    <div className="flex flex-col justify-start items-start gap-2 ">
                      <label className="text-zinc-800 text-base font-normal leading-snug">
                        {t("Location on map")}
                      </label>
                      <input
                        type="text"
                        value={facility.data.location_on_map}
                        className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                      />
                    </div>
                    <div className="invitaion-verification flex flex-col justify-start items-start gap-2 ">
                      <label className="text-zinc-800 text-base font-normal leading-snug">
                        {t("Phone Number")}
                      </label>
                      <Controller
                        className="w-full"
                        control={control}
                        name="phone"
                        rules={{ required: "Phone Number is required" }}
                        defaultValue={facility.data.phone}
                        render={({ field, field: { name, ref } }) => {
                          return (
                            <PhoneInput
                              className=""
                              error={errors.phone}
                              defaultCountry="eg"
                              inputStyle={{
                                background: "rgb(242 244 248",
                                width: "100%",
                                margin: "0px 4px",
                                border: "0px",
                                padding: "0 14px",
                                height: "46px",
                                borderRadius: "8px",
                              }}
                              name={name}
                              ref={ref}
                              {...field}
                            />
                          );
                        }}
                      />
                    </div>
                    <div className="flex flex-col justify-start items-start gap-2 ">
                      <label className="text-zinc-800 text-base font-normal leading-snug">
                        {t("Email address")}
                      </label>
                      <input
                        type="text"
                        value={facility.data.email}
                        className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                      />
                    </div>
                    <div className="flex flex-col justify-start items-start gap-2 ">
                      <label className="text-zinc-800 text-base font-normal leading-snug">
                      {t("Commercial Record")}
                      </label>
                      <input
                        type="number"
                        value={facility.data.commercial_record_number}
                        className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                      />
                    </div>
                    <div className="flex flex-col justify-start items-start gap-2 ">
                      <label className="text-zinc-800 text-base font-normal leading-snug">
                        {t("Tax ID")}
                      </label>
                      <input
                        type="number"
                        value={facility.data.tax_id}
                        className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                      />
                    </div>
                    <div className="flex flex-col justify-start items-start gap-2 ">
                      <label className="text-zinc-800 text-base font-normal leading-snug">
                      {t("Youtube Link")}
                      </label>
                      <input
                        type="text"
                        value={facility.data.youtube_link}
                        className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                      />
                    </div>
                    <div className="flex flex-col justify-start items-start gap-2 ">
                      <label className="text-zinc-800 text-base font-normal leading-snug">
                      {t("Facebook Link")}
                      </label>
                      <input
                        type="text"
                        value={facility.data.facebook_link}
                        className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                      />
                    </div>
                    <div className="flex flex-col justify-start items-start gap-2 ">
                      <label className="text-zinc-800 text-base font-normal leading-snug">
                      {t("TikTok Link")}
                      </label>
                      <input
                        type="text"
                        value={facility.data.tiktok_link}
                        className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                      />
                    </div>
                    <div className="flex flex-col justify-start items-start gap-2 ">
                      <label className="text-zinc-800 text-base font-normal leading-snug">
                      {t("Instagram Link")}
                      </label>
                      <input
                        type="text"
                        value={facility.data.instagram_link}
                        className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                      />
                    </div>
                    <div className="flex flex-col justify-start items-start gap-2 ">
                      <label className="text-zinc-800 text-base font-normal leading-snug">
                      {t("X Link")}
                      </label>
                      <input
                        type="text"
                        value={facility.data.x_link}
                        className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div className="mb-6">
            <div className="flex justify-between items-center mb-10">
              <p className="text-red text-2xl font-medium ">{t("My Venues")}</p>
              <Link
                to={`/vendor/add-venues/${facility.data.id}`}
                className="px-8 py-[9px] rounded-[10px] shadow border border-red justify-center items-center gap-2.5 flex"
              >
                <span className="text-center text-red text-base font-normal font-['Inter'] leading-tight">
                  {t("Add Venue")}
                </span>
              </Link>
            </div>
            {!facility.loading && facility.data.venues.length < 1 && (
              <Info
                msg={"No results found."}
                description={"Please refresh to show results."}
              />
            )}
            <Swiper
              spaceBetween={20}
              slidesPerView={isMobile ? 1 : isTablet? 2 : isLaptop? 3 : 5} 
              navigation={true}
              modules={[Navigation]}
            >
              <div className=" grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-10">
                {facility.data.venues.map((venue) => (
                  <SwiperSlide>
                    <Link
                      to={`/Vendor/Venue/${facility.data.id}/${venue.id}`}
                      key={venue.id}
                    >
                      <div className="mb-4">
                        <img
                          className="mx-auto object-cover h-40 rounded-lg w-full"
                          src={
                            venue.image === "" ? placholder_image : venue.image
                          }
                          alt=""
                        />
                      </div>
                      <div className="">
                        <div className="flex justify-between items-center w-full">
                          <span className="text-gray-500 text-xl font-bold leading-[29px]">
                            {venue.name}
                          </span>
                          <span className="whitespace-nowrap text-zinc-500 text-base font-semibold leading-tight tracking-wide">
                            {venue.size.name} {t("Guests")}
                          </span>
                        </div>
                      </div>
                    </Link>
                  </SwiperSlide>
                ))}
              </div>
            </Swiper>
          </div>
        </>
      )}
    </section>
  );
}

export default FacilityDetails;
