import DefaultLayout from "./layouts/DefaultLayout";
import Login from "./views/site/Login";
import Demo from "./views/content/Demo";
import {roleOptions} from "./roleOptions";
import UserLayout from "./layouts/UserLayout";
import UserPartnerLink from "./views/user/partner/UserPartnerLink";
import UserPartnerNew from "./views/user/partner/UserPartnerNew";
import Register from "./views/site/Register";
import VendorRegistration from "./views/vendor/VendorRegistration";
import About from "./views/content/About";
import Services from "./views/content/Services";
import Shop from "./views/content/Shop";
import Blog from "./views/content/Blog";
import Contact from "./views/content/Contact";
import EditProfile from "./views/user/EditProfile";
import VendorProfile from "./views/vendor/VendorProfile";
import WeddingVenues from "./views/content/WeddingVenues";
import FullWidthLayout from "./layouts/FullWidthLayout";
import Cart from "./views/content/Cart";
import ProductDetails from "./views/content/ProductDetails";
import Favourits from "./views/content/Favourits";
import Home from "./views/content/Home";
import Shipping from "./views/content/Shipping";
import Order from "./views/content/Order"
import VenueDetails from "./views/content/VenueDetails";
import Invitation from "./views/content/WeddingInvitation/Invitation";
import Website from "./views/content/Website";
import BookingList from "./views/content/BookingList";
import MyAccount from "./views/user/MyAccount";
import VenueAvailability from "./views/content/VenueAvailability";
import BookingDetails from "./views/content/BookingDetails";
import BookingReview from "./views/content/BookingReview";
import Ticker from "./components/venues/Ticker";
import VendorFacilities from "./views/vendor/VendorFacilities";
import VendorAccount from "./views/vendor/VendorAccount";
import FacilityDetails from "./views/vendor/FacilityDetails";
import AddFacility from "./views/vendor/AddFacility";
import EditFacility from "./views/vendor/EditFacility";
import DeleteFacility from "./views/vendor/DeleteFacility";
import Notifications from "./views/content/Notifications";
import EditAccount from "./views/vendor/EditAccount";
import AddVenues from "./views/vendor/AddVenues";
import BookingRequests from "./views/vendor/BookingRequests";
import BookingRequestsDetails from "./views/vendor/BookingRequestsDetails";
import CashRequests from "./views/vendor/CashRequests";
import CreateInvitation from "./views/content/WeddingInvitation/CreateInvitation";
import EditVenue from "./views/vendor/EditVenue";
import InvitationView from "./views/content/WeddingInvitation/InvitationView";
import ViewVenue from "./views/vendor/ViewVenue";
import ForgotChangePassword from "./views/vendor/ForgotChangePassword"; 
import InvitationVerification from "./views/content/WeddingInvitation/InvitationVerification";
import GuestsStatus from "./views/content/WeddingInvitation/GuestsStatus";
import WeddingPlan from"./views/content/WeddingPlan";
import WeddingConsltant from "./views/content/WeddingConsultant"
import InvitationsList from "./views/content/WeddingInvitation/InvitationsList";
import GuestInvitation from "./views/content/WeddingInvitation/GuestInvitation";
import AddGuests from "./views/content/WeddingInvitation/AddGuests";
import EditInvitation from "./views/content/WeddingInvitation/EditInvitation";
import VendorServices from "./views/vendor/VendorServices";
import AddServices from "./views/vendor/AddServices";
import {VenueEditSercive} from "./views/vendor/EditService";
import VendorBookingList from "./views/vendor/VendorBookingList";
import EditService from "./views/content/ServiceProvider/EditService";
import DeleteVenue from "./views/vendor/DeleteVenue";
import ServiceDetails from "./views/content/ServiceDetails";
import DeleteAccount from "./views/vendor/DeleteAccount";
import ServiceProviderAccount from "./views/content/ServiceProvider/ServiceProviderAccount";
import ProviderEditAccount from "./views/content/ServiceProvider/ProviderEditAccount";  
import ProfileData from "./views/content/ServiceProvider/ProfileData";
import ServicesList from "./views/content/ServiceProvider/ServicesList";
import ProviderAddServices from "./views/content/ServiceProvider/ProviderAddServices";
import { ProviderBookingRequests } from "./views/content/ServiceProvider/BookingRequests";
import { ProviderBookingList } from "./views/content/ServiceProvider/BookingList";
import WishList from "./views/content/WishList";
import { ProviderBookingDetails } from "./views/content/ServiceProvider/BookingDetails";
import ProfileDataService from "./views/content/ServiceProvider/ProfileDataService";
import MyOrders from "./views/user/MyOrders";
import ChangPassword from "./views/vendor/ChangePassword";
import Consultant from "./views/content/WeddingConsaltant/Consaltant";
import { ProviderRequestsDetails } from "./views/content/ServiceProvider/RequstsDetails";
import MyServices from "./views/user/MyServices";
import BookConsultant from "./views/content/WeddingConsaltant/BookConsultant";
import ConsultationSession from "./views/content/WeddingConsaltant/ConsultationSession";
import ProductAccount from "./views/content/ProductSupplier/ProductAccount";
import ProductList from "./views/content/ProductSupplier/ProductList";
import SupplierAddProduct from "./views/content/ProductSupplier/SupplierAddProduct";
import SupplierEditAccount from "./views/content/ProductSupplier/SupplierEditAccount";
import WeddingPlanPreview from "./views/content/WeddingPlanPreview";
import SupplierEditProduct from "./views/content/ProductSupplier/SupplierEditProduct";
import MyShipment from "./views/content/ProductSupplier/MyShipment";
import SupplierProductDetails from "./views/content/ProductSupplier/SupplierProductDetails";
import OrderDetails from "./views/user/OrderDetails";
import ShipmentStatus from "./views/content/ProductSupplier/ShipmentStatus";
import OnlinePayment from "./views/content/OnlinePayment";
import OrderReviw from "./views/user/OrderReviw";
import ServiceReviw from "./views/user/ServiceReview";
import PaymentGateway from "./views/content/Payment/PaymentGateway";
import Faq from "./views/content/Faq";

export const routes = [
  { path: '/login', element: Login, role: roleOptions.Guest},
  { path: '/register', element: Register, layout: DefaultLayout},
  { path: '/register/vendor', element: VendorRegistration, layout: DefaultLayout},
  { path: '/user/partner/link', element: UserPartnerLink, layout: UserLayout},
  { path: '/user/partner/initiate', element: UserPartnerNew, layout: UserLayout},
  { path: '/user/edit-profile', element: EditProfile, layout: UserLayout},
  { path: '/user/notifications', element: Notifications, layout: UserLayout},
  { path: '/user/online-payment', element: OnlinePayment, layout: UserLayout},
  { path: '/user/wishlist', element: WishList, layout: UserLayout},
  { path: '/user/services', element: MyServices, layout: UserLayout},
  { path: '/vendor/account', element:VendorAccount, layout: UserLayout},
  { path: '/facility/venue/availability/:id', element: VenueAvailability, layout: UserLayout},
  { path: '/user/account', element: MyAccount, layout: UserLayout},
  { path: '/user/orders', element: MyOrders, layout: UserLayout},
  { path: '/user/order-details/:id', element: OrderDetails, layout: UserLayout},
  { path: '/user/order-review/:id', element: OrderReviw, layout: UserLayout},
  { path: '/user/service-review/:id', element: ServiceReviw, layout: UserLayout},
  { path: '/vendor/account', element: VendorAccount, layout: UserLayout},
  { path: '/user/booking-list', element: BookingList, layout: UserLayout},
  { path: '/user/booking-list/:id', element: BookingDetails},
  { path: '/vendor/edit-profile', element: EditAccount, layout: UserLayout},
  { path: '/vendor/Facilities', element: VendorFacilities, layout: UserLayout},
  { path: '/vendor/Facilities/:id', element: FacilityDetails, layout: UserLayout},
  { path: '/vendor/add-facility', element: AddFacility, layout: UserLayout},
  { path: '/vendor/edit-facility/:id', element: EditFacility, layout: UserLayout},
  { path: '/vendor/delete-facility/:id', element: DeleteFacility, layout: UserLayout},
  { path: '/vendor/booking-requests', element: BookingRequests, layout: UserLayout},
  { path: '/vendor/booking-list', element: VendorBookingList, layout: UserLayout},
  { path: '/vendor/booking-requests/:id', element: BookingRequestsDetails, layout: UserLayout},
  { path: '/vendor/cash-requests', element: CashRequests, layout: UserLayout},
  { path: '/booking-review/:id', element: BookingReview, layout: UserLayout},
  { path: '/vendor/edit-account', element: EditAccount,layout:UserLayout},
  { path: '/vendor/add-venues/:id', element: AddVenues, layout:UserLayout},
  { path: '/invitation/create', element: CreateInvitation, layout:UserLayout},
  { path: '/invitation/edit/:id', element: EditInvitation, layout:UserLayout},
  { path: '/invitation/view/:id', element: InvitationView, layout:UserLayout},
  { path: '/invitation/verification/:id', element: InvitationVerification, layout:UserLayout},
  { path: '/invitations', element: InvitationsList, layout:UserLayout},
  { path: '/invitation/guests-status/:id', element: GuestsStatus, layout:UserLayout},
  { path: '/invitation/guests-status', element: GuestsStatus, layout:UserLayout},
  { path: '/invitation/add-guests/:id', element: AddGuests, layout:UserLayout},
  { path: '/guestInvitation/:id', element: GuestInvitation, layout:UserLayout},
  { path: '/vendor/edit-venues/:id1/:id2',element: EditVenue, layout: UserLayout }, 
  { path: '/Vendor/Venue/:id1/:id2', element: ViewVenue, layout:UserLayout},
  { path: '/Forgot-change-password', element: ForgotChangePassword, layout:DefaultLayout},
  { path: '/vendor/add-services', element: AddServices, layout:UserLayout},
  { path: '/vendor/edit-services', element: VenueEditSercive, layout:UserLayout},
  { path: '/vendor/services', element: VendorServices, layout:UserLayout},
  { path: '/vendor/delete-venues/:id',element: DeleteVenue, layout: UserLayout }, 
  { path:  '/vendor/delete-account' ,element: DeleteAccount, layout:UserLayout},
  { path: '/Change-Password',element: ChangPassword, layout: UserLayout},
  { path: '/ServiceProvider/account',element:ServiceProviderAccount, layout: UserLayout},
  { path: '/ServiceProvider/edit-account', element:ProviderEditAccount, layout: UserLayout},
  { path: '/ServiceProvider/profile-data', element:ProfileData, layout: UserLayout},
  { path: '/ServiceProvider/services-list', element:ServicesList, layout: UserLayout},
  { path: '/ServiceProvider/add-service', element:ProviderAddServices, layout: UserLayout},
  { path: '/ServiceProvider/edit-service', element: EditService, layout:UserLayout},
  { path: '/ServiceProvider/booking-requests', element:ProviderBookingRequests, layout: UserLayout},
  { path: '/ServiceProvider/booking-list', element:ProviderBookingList, layout: UserLayout},
  { path: '/ServiceProvider/booking-list/:id', element:ProviderBookingDetails, layout: UserLayout},
  { path: '/ServiceProvider/booking-requests/:id', element:ProviderRequestsDetails, layout: UserLayout},
  { path: '/profileDataService', element:ProfileDataService, layout: UserLayout},
  { path: '/BookConsultant', element: BookConsultant, layout:UserLayout},
  { path: '/ConsultationSession', element:ConsultationSession, layout:UserLayout},
  {path: '/product-Supplier/Account', element:ProductAccount, layout:UserLayout},
  {path: '/productSupplier/productList', element:ProductList, layout:UserLayout},
  {path: '/productSupplier/product/:id', element:SupplierProductDetails, layout:UserLayout},
  {path: '/ProductSupplier/add-product',element:SupplierAddProduct, layout:UserLayout},
  {path: '/ProductSupplier/edit-product/:id',element:SupplierEditProduct, layout:UserLayout},
  {path: '/ProductSupplier/edit-account', element:SupplierEditAccount, layout:UserLayout},
  { path:'/wedding-plan', element: WeddingPlan , layout:UserLayout},
  { path: '/ProductSupplier/shipment', element: MyShipment , layout:UserLayout}, 
  { path: '/ProductSupplier/shipment/:id', element: ShipmentStatus, layout:UserLayout},
 


  { path: '/about', element: About},
  { path: '/services', element: Services},
  { path: '/service/:id', element: ServiceDetails},
  { path: '/venues', element: WeddingVenues },
  { path: '/facility/:id', element: VenueDetails },
  { path: '/shop', element: Shop },
  { path: '/product/:id', element: ProductDetails },
  { path: '/blog', element: Blog},
  { path: '/contact', element: Contact},
  { path: '/cart', element: Cart  },
  { path: '/shipping', element: Shipping  },
  { path: '/order-complete', element: Order  },
  { path: '/favourits', element: Favourits  },
  { path: '/invitation', element: Invitation  },
  { path: '/website', element: Website  },
  { path: '/', element: Home },
  { path: '/faq', element: Faq },
  { path: '/ticker', element: Ticker },
  { path:'/consultant', element: Consultant},
  { path:'/create-wedding-plan', element: WeddingPlanPreview},
  { path: '/payment/:session_id', element: PaymentGateway},
];