import React from 'react';
import {Route, Routes, useLocation} from "react-router-dom";
import {
  faCar, faDashboard, faStore,
} from "@fortawesome/free-solid-svg-icons";
import Header from "../components/default/Header";
import Footer from "../components/default/Footer";
import {useSelector} from "react-redux";
import Demo from "../views/content/Demo";
import UserPartnerNew from "../views/user/partner/UserPartnerNew";
import UserPartnerLink from "../views/user/partner/UserPartnerLink";


const LoginPage = React.lazy(() => import('./../views/site/Login'))
const RegisterPage = React.lazy(() => import('../views/site/Register'))

function DefaultLayout({ children }) {
    const location = useLocation();
    const dark = useSelector(state => state.ui.dark)

    return (
      <div className={''}>
      <div className={"bg-white min-h-screen flex flex-col"}>
        <Header position="fixed"/>

       
            { children }

        <Footer/>
      </div>
    </div>
    );
}

export default DefaultLayout;