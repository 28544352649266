import React, {useEffect, useState} from "react";
import {Typography} from "@material-tailwind/react";
import {useTranslation} from "react-i18next";
import {Controller, useForm} from "react-hook-form";
import {defaultCountries, parseCountry, PhoneInput} from "react-international-phone";
import Loader from "../../../components/Loader";
import Select from "react-select";
import clsx from "clsx";
import axios from "axios";
import {toast} from "react-toastify";
import {Link, useNavigate } from "react-router-dom";
import placholder_image from "../../../assets/images/booking/placeholder-image.jpg"
import useAxiosUserSecureConfig from "../../../hooks/useAxiosUserSecureConfig";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FaEdit } from "react-icons/fa";
import uplooad from "../../../assets/images/icons/upload.svg";
import {useDispatch, useSelector} from "react-redux";
import { faPlus} from "@fortawesome/free-solid-svg-icons";



function ProfileData(){
  
  const { t, i18n } = useTranslation();
    const navigate = useNavigate();
   

   

    const {
        register,
        watch,
        control,
        setError,
        setValue,
        formState: { errors },
        handleSubmit
    } = useForm({ defaultValues: {country_id: 1, product_ids: []} });


    const customStyles = {
        control: (styles) => ({
            ...styles,
            background: "#F2F4F8", "border-color": "#F2F4F8" , "border-radius" :"8px" ,padding: "6px" })
    }



    const menuStyle =
        "border rounded-md bg-white hover:cursor-pointer px-4 py-2";

    const optionStyles = {
        focus: "hover:cursor-pointer hover:text-red",
        Selected: "bg-[#F2F4F8]",
    };

    const baseStyle =
        " bg-[#F2F4F8]  rounded-lg self-stretch grow shrink bg- text-zinc-500 text-sm font-normal leading-tight h-12 px-[18px]  justify-start items-center gap-2 inline-flex";
       
       
  const Dayoptions =[
    {label:t("SUN"), value:"1"},
    {label:t("MON"), value:"2"},
    {label:t("TUE"), value:"3"},
    {label:t("WED"), value:"4"},
    {label:t("THU"), value:"5"},
    {label:t("FRI"), value:'6'},
    {label:t("SAT"), value:"7"},
    
];
const [selectedOption, setSelectedOption] = useState(null);
    const [countries , setCountries] = useState({ loading: true, data: [] })
    const [states , setStates] = useState({ loading: false, data: [] })
    const [cities , setCities] = useState({ loading: false, data: [] })
   
    const user = useSelector(state => state.auth.user)
    useEffect(() => {
    
        axios
          .post("/api/country", {})
          .then((res) => {
                        if (!res.data?.result?.success) {
                toast.error("Error while fetching countries!");
                return;
            }

            setCountries((prevState) => ({
                ...prevState,
                loading: false,
                data: res.data?.result?.countries ?? [],
            }));
        })
          .catch((err) => {
            console.log(err);
        });
    }, []);

    const country = watch("country");
    const state = watch("state");

    useEffect(() => {
        setValue("state", null);
        setValue("city", null);

        if (!country) {
            setStates((prevState) => ({
                ...prevState,
                data: [],
            }));
            setCities((prevState) => ({
                ...prevState,
                data: [],
            }));
            return;
        }

        setStates((prevState) => ({
            ...prevState,
            loading: true,
        }));

        axios
          .post(`/api/country/${country.value}`, {})
          .then((res) => {
                        if (!res.data?.result?.success) {
                toast.error("Error while fetching states!");
                return;
            }

            setStates((prevState) => ({
                ...prevState,
                loading: false,
                data: res.data?.result?.countries ?? [],
            }));
        })
          .catch((err) => {
            console.log(err);
        });
    }, [country]);

    useEffect(() => {
        setValue("city", null)

        if (!state) {
            setCities((prevState) => ({
                ...prevState,
                data: [],
            }));
            return;
        }

        setCities((prevState) => ({
            ...prevState,
            loading: true,
        }));

        axios
          .post(`/api/state/${state.value}`, {})
          .then((res) => {
                        if (!res.data?.result?.success) {
                toast.error("Error while fetching cities!");
                return;
            }

            setCities((prevState) => ({
                ...prevState,
                loading: false,
                data: res.data?.result?.state.cities ?? [],
            }));
        })
          .catch((err) => {
            console.log(err);
        });
    }, [state]);

    const config = useAxiosUserSecureConfig(); 
    const [imageBase64, setImageBase64] = useState(placholder_image);
    const [imageName, setImageName] = useState('');
    const [categoryNames ,setCategoryNames] = useState();
    const handleImageChange = (e) => {
     setImageName(e.target.files[0].name);
     const file = e.target.files[0];
   
     if (file) {
       const reader = new FileReader();
       reader.onloadend = () => {
         // Extract base64 data without the prefix
         const base64WithoutPrefix = reader.result;
         setImageBase64(base64WithoutPrefix);
       };
       reader.readAsDataURL(file);
     }
   };
   
   const onSubmit= (data)=>{
    console.log(" data", data);

    const selectedValues = data.Unavailable.map((option) => option.value);
    const availableDays = {
      available_sun: selectedValues.includes("1"),
      available_mon: selectedValues.includes("2"),
      available_tue: selectedValues.includes("3"),
      available_wed: selectedValues.includes("4"),
      available_thu: selectedValues.includes("5"),
      available_fri: selectedValues.includes("6"),
      available_sat: selectedValues.includes("7"),
    };

    const requestData={
        brand_name: data.brand_name,
        service_location: data.serviceplace,
        address: data.address,
        website: data.website,
        about: data.about,
        youtube_link: data.youtube_link,
        facebook_link: data.facebook_link,
        tiktok_link: data.tiktok_link,
        instagram_link: data.instagram_link,
        x_link: data.x_link || "",
        ...availableDays,
        image: imageBase64.split(",")[1],
        service_category_ids: data?.service_categories || "",
    };
    console.log("request data",requestData);
    axios.post('/api/update-vendor' ,requestData, config)
    .then((res) => {
      console.log("DATA",res.data)
      if (!res.data.result?.success) {
        toast.error(res.data.result?.msg || "Request failed!");
        return;
      }
      toast.success(t("You Edit Account successfully!"));
      navigate("/serviceProvider/account")
      
    })
    .catch((err) => {
      console.log("err" , err)
      toast.error("Error while Editing Account!");
    })
}
const [imageUrls, setImageUrls] = useState({loading : true , data :[]});
    const [vendor, setVendor]=useState({ loading: true, data: [] })


    const fetchData = ()=>{
      axios
      .post(`/api/vendor/${user.partnerId}`, {}, config)
      .then((res) => {
        console.log("profile data", res.data);
  
        if (!res.data?.result?.success) {
          toast.error("Error while fetching Profile data!");
        }
        setImageBase64(res.data?.result?.vendor.image);
        setVendor((prevState) => ({
          ...prevState,
          loading: false,
          data: res.data?.result?.vendor || [],
        }));
        setCategoryNames(res.data?.result?.vendor.service_categories.map(category => category.name))
        const displayImageIds = res.data?.result?.vendor.display_image_ids || [];
        const imagePromises = displayImageIds.map((id) =>
          axios
            .post(`/api/vendor-image/${id}`, {}, config)
            .then((response) => ({
              src: response.data?.result?.image, id:id,
              alt: `Image ${id}`,
            }))
        );
        Promise.all(imagePromises)
          .then((images) => {
            setImageUrls((prevState) => ({
              ...prevState,
              loading: false,
              data: images,
            }));
          
            const filledImages = images.map((image) => ({image:image.src, id:image.id}));
            setSelectedImages(filledImages);
          })
          .catch((error) => {
            console.error("Error fetching images:", error);
          });
      })
      .catch((err) => {
        console.log(err);
      });
    }

    useEffect(() => {

    fetchData()

    }, []);
    


    const [phone, setPhone] = useState('');
    const countriies = defaultCountries.filter((country) => {
        const { iso2 } = parseCountry(country);
        return ['us', 'ua', 'gb','eg',].includes(iso2);
    })

   

   const servicePlaces =[
    { value: "wedding_location", label: t("Wedding Location") },
    { value: "working_address",  label: t("At my working Address") },
    {value: "any", label: t("Wedding Location and Working Address")},
   ];
   
const [selectedImages, setSelectedImages] = useState(); 

// const handleImageClick = (index) => {
//   const fileInput = document.getElementById(`fileInput-${index}`);
//   if (fileInput) {
//     fileInput.click();
//   }
// };

const handleImageUpload = (e, index) => {
  const file = e.target.files[0];
  if (file) {
    const reader = new FileReader();
    reader.onload = () => {
     

      // Upload the image to the server
      uploadImageToServer(reader.result);
// TODO: Update images in the UI
     const updatedSelectedImages = [...selectedImages];
     updatedSelectedImages[index] = reader.result;
     setSelectedImages(updatedSelectedImages);
    };
    reader.readAsDataURL(file);
  }
};
console.log("selectedImages",selectedImages)
  const uploadImageToServer = (imageData) => {
    setImageUrls((prevState) => ({
      ...prevState,
      loading: true,
    }));
    const data = {
      image: imageData.split(",")[1]
    };
    axios.post('/api/vendor/add-image',  data  , config)
    .then((res) => {
      if (!res.data.result?.success) {
        toast.error(res.data.result?.msg || "Request failed!");
        return;
      }
      toast.success(t("You add image successfully!"));
      fetchData()
      setImageUrls((prevState) => ({
        ...prevState,
        loading: false,
      }));
    })

    .catch((err) => {
      console.log("err" , err)
      toast.error("Error while adding image!");
    })
  };



 const handledeleteImage = (id)=>{
  axios.post('/api/vendor/delete-image',  {vendor_image_id: id}  , config)
  .then((res) => {
    if (!res.data.result?.success) {
      toast.error(res.data.result?.msg || "Request failed!");
      return;
    }
    toast.success("delete image successfully!");
    fetchData()
    setImageUrls((prevState) => ({
      ...prevState,
      loading: false,
    }));
  })
  .catch((err) => {
    console.log("err" , err)
    toast.error("Error while deleting image!");
  })
 };


  const [categories, setCategories] = useState({ loading: true, data: [] });
  useEffect(() => {
    axios.post('/api/service_categories', {} ,
      {headers: {
        'Accept-Language': 'ar'}
      }
    ).then((res) => {
        console.log("categories", res.data);

        if (!res.data?.result?.success) {
            toast.error("Error while fetching categories!");
            return;
        }

        setCategories(prevState => ({
            ...prevState,
            loading: false,
            data: res.data?.result?.categories ?? []
        }))
    }).catch((err) => {
        console.log(err);
    })
}, [])



const availableDays = Object.keys(vendor.data)
.filter((key) => key.startsWith('available_') && vendor.data[key] === true)
.map((key) => key.split('_')[1])
.join(', ');
    return(
<section className="px-5 xl:px-10 py-10">
 {vendor.loading && <Loader slim />}
      {!vendor.loading && (
        <>
         <div className="justify-start items-center gap-2  hidden sm:inline-flex mb-10">
          <div
            onClick={() => navigate('/serviceProvider/account')}
            className="text-zinc-400 text-base font-normal leading-[48px] hover:text-gray-dark cursor-pointer"
          >
            {t("Service Provider Account")}
          </div>
          <FontAwesomeIcon
            className="text-zinc-400 text-base"
            icon={faAngleRight}
          />
          <div
            onClick={() => navigate( '/profileDataService')}
            className="text-zinc-400 text-base font-normal leading-[48px] hover:text-gray-dark cursor-pointer"
          >
         {t("Profile Data")}
          </div>
          <FontAwesomeIcon
            className="text-zinc-400 text-base"
            icon={faAngleRight}
          />
          <div
            onClick={() => navigate('/serviceProvider/profile-data')}
            className="text-zinc-400 text-base font-normal leading-[48px] hover:text-gray-dark cursor-pointer"
          >
           {t("Edit")} 
          </div>
          </div>

          <form onSubmit={handleSubmit(onSubmit)}>  
    <div className="xl:px-10 flex flex-wrap md:flex-nowrap gap-10 ">
   
    <div className="flex md:w-1/3 w-full flex-col gap-6 items-center">
          <img
            src={imageBase64}
            className=" w-full h-[260px] object-cover rounded-lg"
          />

          <div className="self-stretch flex-col justify-start items-start gap-2 flex">
            <div className="relative w-full bg-slate-100 border-slate-100 rounded-lg">
              <label>
                <div className="h-12 flex justify-between items-center">
                  <input
                    type="file"
                    hidden
                    onChange={handleImageChange}
                    id="fileInput"
                    className="custom-file-input absolute left-[-100px] text-zinc-500 "
                  />

                  <label htmlFor="fileInput" className="cursor-pointer ">
                    <img
                      src={uplooad}
                      className={`absolute ${i18n.language === 'en' ? 'right-3' : 'left-3'}  bottom-3`}
                      alt="upload"
                    />
                  </label>

                  {/* Placeholder and image name */}
                  <input
                    type="text"
                    className="border-0 flex-grow p-4 bg-slate-100 rounded-lg text-zinc-500 text-base font-normal leading-tight placeholder-[#808080]"
                    placeholder={t("Choose a file")}
                    value={imageName ? imageName : ""}
                    readOnly
                  />
                </div>
              </label>
            </div>
          </div>
         {imageUrls.loading && <Loader slim/>}
         {!imageUrls.loading && <div className="grid grid-cols-2 gap-2">
               {selectedImages.map(({image,id}, index) => (
                 <div key={index} className="relative">
                   <img
                     src={image}
                     className={`w-full h-[180px]  object-cover rounded-lg hover:brightness-50 ${
                       image === placholder_image && "cursor-pointer"
                     }`}
                     alt={"service_image"}
                    
                     
                   />
                  
                   {/* {image === placholder_image && ( // Render input only if image is a placeholder
                    
                 
                   )} */}
                   <button type="button" onClick={()=>{handledeleteImage(id)}}>
                     <FontAwesomeIcon icon={faTrashCan}   className={`absolute opacity-55 ${i18n.language === 'en' ? 'right-3' : 'left-3'} top-3`}
                      />
                    </button>
                 </div>
                 
               ))}

                {selectedImages.length < 6 && (
                  <div >
                    <div>
                      <label>
                      <div className="pl-3 pr-4 py-2 rounded-lg hover:cursor-pointer text-base text-red border border-red justify-center items-start gap-2 inline-flex">
                        <FontAwesomeIcon
                          className="font-normal leading-[21px]"
                          icon={faPlus}
                        />
                          <span className="font-normal leading-[21px]">{t("Add Images")} </span> 
                     <input
                       title="Upload image"
                       type="file"
                       defaultValue={""}
                      //  id={`fileInput-${index}`}
                       accept="image/*"
                       className="hidden"
                       onChange={(e) => handleImageUpload(e)}
                     /></div>
                     </label>
                     </div>
                     </div>
                   )}
             </div>}
        </div>

        <div className="grow">
            <div className=" mx-auto flex justify-between items-center mb-4">
                <p className="text-lg text-red md:text-2xl font-semibold leading-9">{t("Edit Profile Data")}</p>
              
            </div>
            

           
                <div className=" mx-auto flex-col  md:grid grid-cols-2 justify-start items-start gap-4 inline-flex mb-10">
                   

                    <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                        <label
                            className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">
                              {t("Brand name")}</label>
                            <Controller
                       rules={{ required: t("Brand name is required") }}
                       name="brand_name"
                       control={control}
                       defaultValue={vendor.data.brand_name}
                       render={({ field }) => (
                         <input
                           type="text"
                           {...field}
                           placeholder={t("brand_name")}
                           className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                         />
                       )}
                     />
                        {errors.brand_name&&
                            <Typography variant="small" color="red">
                                {errors.brand_name?.message}
                            </Typography>
                        }
                    </div>

                    <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                        <label
                            className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">{t("Category")}
                           </label>
              {categories.loading && <Loader slim />}
              {!categories.loading && !vendor.loading && (
              <Controller
                control={control}
                name="service_categories"
                rules={{ required: t("category is required") }}
                defaultValue={vendor.data.service_categories.map((item) => item.id)}
                render={({ field, field: { name, ref, value, onChange } }) => {
                  const options =categories.data.map((category) => ({
                    value: category.id,
                    label: category.name,
                  }))
                  return (
                    <Select
                      styles={customStyles}
                      name={name}
                      ref={ref}
                      isMulti
                      isClearable
                      isSearchable
                      options={options}
                    value={options.filter((entry) => value.includes(entry.value))}
                    onChange={(items) => {
                      console.log(items)
                      onChange(items.map(item => item.value))
                    }}
                      className="w-full"
                    />
                  );
                }}
              />
              )}
                        {errors.category &&
                            <Typography variant="small" color="red">
                                {errors.category?.message}
                            </Typography>
                        }
                    </div>
                    
                    <div className="flex flex-col justify-start items-start gap-2 ">
                  <label className="text-zinc-800 text-base font-normal leading-snug">
                    {t("Available Days")}
                  </label>
                  <Controller
                    control={control}
                    name="Unavailable"
                    defaultValue={Dayoptions
                      .filter(item => item.label !== "SUN" || item.label === "SUN" && vendor.data.available_sun)
                      .filter(item => item.label !== "MON" || item.label === "MON" && vendor.data.available_mon)
                      .filter(item => item.label !== "TUE" || item.label === "TUE" && vendor.data.available_tue)
                      .filter(item => item.label !== "WED" || item.label === "WED" && vendor.data.available_wed)
                      .filter(item => item.label !== "THU" || item.label === "THU" && vendor.data.available_thu)
                      .filter(item => item.label !== "FRI" || item.label === "FRI" && vendor.data.available_fri)
                      .filter(item => item.label !== "SAT" || item.label === "SAT" && vendor.data.available_sat)
                    }
                    render={({ field }) => (
                      <Select
                        placeholder={t("Select...")}
                        className="w-full  border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                      
                        onChange={setSelectedOption}
                        options={Dayoptions}
                        isMulti
                        isClearable
                        {...field}
                        styles={customStyles}
                      />
                    )}
                  />
                  {errors.Unavailable && (
                    <Typography variant="small" color="red">
                      {errors.Unavailable?.message}
                    </Typography>
                  )}
                </div>
                <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                        <label
                            className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">{t("How do you give service?")}
                           </label>
                        <Controller
                            control={control}
                            name="serviceplace"
                            defaultValue={vendor.data.service_location}
                            rules={{required: t('Service Location is required')}}
                            render={({field: {onChange, value, name, ref}}) => {
                                return <Select
                                    className="w-full"
                                    styles={customStyles}
                                    placeholder={t("Type")}
                                    name={name}
                                   
                                    ref={ref}
                                    defaultValue={
                                      vendor.data.service_location
                                        ? servicePlaces.find(option => option.value ===vendor.data.service_location)
                                        : null
                                    }
                                    onChange={(entry) => {
                                      onChange(entry.value) }}
                                      options={servicePlaces}
                                />
                            }}
                        />
                        {errors.serviceplace &&
                            <Typography variant="small" color="red">
                                {errors.serviceplace?.message}
                            </Typography>
                        }
                    </div>
                    <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                        <label
                            className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">{t("Vendor address")}
                            </label>
                        <Controller
                    
                       name="address"
                       control={control}
                       defaultValue={vendor.data.address}
                       render={({ field }) => (
                         <input
                           type="text"
                           {...field}
                           placeholder={t("address")}
                           className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                         />
                       )}
                     />
                    </div>


                    <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                        <label
                            className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">{t("Vendor website")}
                            </label>
                     <Controller
                       name="website"
                       control={control}
                       render={({ field }) => (
                         <input
                           type="text"
                           {...field}
                           defaultValue={vendor.data.website}
                           placeholder={t("website")}
                           className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                         />
                       )}
                     />
                    </div>


                    <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                        <label
                            className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">
                              {t("Youtube Link")}</label>
                            <Controller
                 
                       name="youtube_link"
                       defaultValue={vendor.data.youtube_link}
                       control={control}
                       render={({ field }) => (
                         <input
                           type="text"
                           {...field}
                           isClearable
                           placeholder={t("youtube link")}
                           className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                         />
                       )}
                     />
                    </div>


                    <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                        <label
                            className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">{t("Facebook Link")}</label>
                            <Controller
                      
                       name="facebook_link"
                       defaultValue={vendor.data.facebook_link}
                       control={control}
                       render={({ field }) => (
                         <input
                           type="text"
                           {...field}
                           isClearable
                           placeholder={t("facebook link")}
                           className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                         />
                       )}
                     />
                    </div>

                    <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                        <label
                            className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">{t("TikTok Link")}</label>
                            <Controller
                            name="tiktok_link"
                            defaultValue={vendor.data.tiktok_link}
                            control={control}
                            render={({ field }) => (
                                <input
                                type="text"
                                {...field}
                                isClearable
                                placeholder={t("tiktok link")}
                                className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                                />
                            )}
                            />
                    </div>

                    <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                        <label
                            className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">{t("Instagram Link")}</label>
                            <Controller
                         name="instagram_link"
                         defaultValue={vendor.data.instagram_link}
                       control={control}
                       render={({ field }) => (
                         <input
                           type="text"
                           {...field}
                           isClearable
                           placeholder={t("instgram link")}
                           className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                         />
                       )}
                     />
                    </div>


                    <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                        <label
                            className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">{t("X Link")}</label>
                            <Controller
                         name="x_link"
                         defaultValue={vendor.data.x_link}
                       control={control}
                       render={({ field }) => (
                         <input
                           type="text"
                           {...field}
                           isClearable
                           placeholder={t("x link")}
                           className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                         />
                       )}
                     />
                    </div>
                   
                </div>
                <div className="mx-auto flex-col  grid grid-cols-1 justify-start items-start gap-4   mb-10">
                <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                        <label
                            className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">{t("About Vendor")}
                           </label>
                            <Controller
                            name="about"
                            control={control}
                            defaultValue={vendor.data.about}
                            render={({ field }) => (
                                <input
                                type="text"
                                {...field}
                                placeholder={t("about")}
                                className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                                />
                            )}
                            />
                    </div>
                </div>

                <div className="flex justify-end w-full">
                    <button type="submit"
                            className="w-fit px-16 py-3 bg-red rounded-[10px] shadow border border-white justify-center items-center gap-2.5 inline-flex">
                        <div className="text-center text-white text-base font-normal leading-normal">{t("Save")}</div>
                    </button>
                </div>
                </div>
                </div>
            </form>
   </>
   )} 
</section>
    )
}

export default ProfileData;