import React, {useEffect, useRef, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faAngleRight, faBoxesStacked, faPlusSquare, faX} from "@fortawesome/free-solid-svg-icons";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import Grid from "@mui/material/Grid";
import { TimeClock } from "@mui/x-date-pickers/TimeClock";
import receptionIcon from "../../assets/images/venus/icons/reception.svg";
import ceremonyIcon from "../../assets/images/venus/icons/ceremony.svg";
import barIcon from "../../assets/images/venus/icons/maki_bar.svg";
import storgeIcon from "../../assets/images/venus/icons/mdi_storage.svg"
import Badge from "@mui/material/Badge";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import axios from "axios";
import {Link, useNavigate, useParams} from "react-router-dom";
import Error from "../Error";
import Loader from "../../components/Loader";
import Select from "react-select";
import clsx from "clsx";
import {Checkbox} from "@material-tailwind/react";
import {toast} from "react-toastify";
import {Spinner} from "flowbite-react";
import useAxiosUserSecureConfig from "../../hooks/useAxiosUserSecureConfig";
import { useSelector } from "react-redux";
import VenueServices from "../../components/venues/VenueServices";
import { PhoneInput } from "react-international-phone";
import { Box, Modal, Typography } from "@mui/material";
import { CiCircleCheck } from "react-icons/ci";
import {useTranslation} from "react-i18next";

function VenueAvailability({ open, handleClose }) {
 
  const user = useSelector(state => state.auth.user)
  const {
    register,
    control,
    watch,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const {t ,i18n } = useTranslation();
  const dateCalendarRef = useRef(null);

  useEffect(() => {
    if (errors.date) {
      dateCalendarRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [errors.date]);
  
  const params = useParams();
  const [
    venue: {
      minPrice : number,
      availableDays: { sun: boolean, mon: boolean, tue: boolean, wed: boolean, thu: boolean, fri: boolean, sat: boolean, },
      unavailable: [{ date_from: string, date_to: string, }],
      services: [{ id: number, name: string, price: number, optional: boolean }],
    },
    setVenue
  ] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const config = useAxiosUserSecureConfig();
  const navigate = useNavigate();

  useEffect(() => {
    axios.post(`/api/venue/${params.id}`, {}).then((res) => {
      console.log("venue", res.data);

      if (!res.data?.result?.success) {
        setError("Something went wrong")
        return;
      }

      setVenue(res.data?.result)
    }).catch((err) => {
      console.log(err);
    })
  }, []);
  
  const [includedServicesImages, setIncludedServicesImages] = useState([]);
  const [optionalServicesImages, setOptionalServicesImages] = useState([]);

  useEffect(() => {
    const fetchServicesImages = async () => {
      try {
        const includedServicesImages = await Promise.all(
          venue.included_services.map((service) =>
            axios.post(`/api/product-category/${service.category.id}`, {}, config)
          )
        );
        const optionalServicesImages = await Promise.all(
          venue.optional_services.map((service) =>
            axios.post(`/api/product-category/${service.category.id}`, {}, config)
          )
        );
        setIncludedServicesImages(includedServicesImages);
        setOptionalServicesImages(optionalServicesImages);
      } catch (error) {
        console.error("Error fetching services images:", error);
      }
    };
    if (venue?.included_services?.length > 0 && venue?.optional_services?.length > 0) {
      fetchServicesImages();
    }
  }, [venue]);
  console.log("optionalServicesImages" , optionalServicesImages)
  const shouldDisableDate = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return (
      date < today ||
      (date.getDay() === 0 && !venue.availableDays.sun) ||
      (date.getDay() === 1 && !venue.availableDays.mon) ||
      (date.getDay() === 2 && !venue.availableDays.tue) ||
      (date.getDay() === 3 && !venue.availableDays.wed) ||
      (date.getDay() === 4 && !venue.availableDays.thu) ||
      (date.getDay() === 5 && !venue.availableDays.fri) ||
      (date.getDay() === 6 && !venue.availableDays.sat) ||
      venue.unavailable.find((entry) => {
        const dateFrom = new Date(entry.date_from);
        const dateTo = new Date(entry.date_to);
  
        dateFrom.setHours(0);
        dateTo.setHours(0);
  
        return date >= dateFrom && date <= dateTo;
      })
    );
  };
  

  const [openConfirmation, setOpenConfirmation] =useState(false);
  const handleCloseConfirmation = () => setOpenConfirmation(false); 
  const [bookingId , setBookingId] = useState()

  const onSubmit = (data) => {
    console.log("data" , data)
    data = {
      venueId: params.id,
      date: data.date.toISOString().split('T')[0],
      brideName: data.bride_name,
      groomName: data.groom_name,
      phone: data.number.replace("+2", ""),
      email: data.email,
      notes: data.message,
      services: data.services || [],
    }

    setLoading(true);
   

    axios.post("/api/book-venue", data, config)
        .then((res) => {
          console.log(res.data)

          if (!res.data.result?.success) {
            toast.error(res.data.result?.message || "Request failed!");
            setLoading(false);
            return;
          }

          toast.success(t("Request sent successfully!"));
          setBookingId(res.data.result?.reservationId)
          setOpenConfirmation(true)
        })
        .then(() => {
          setLoading(false);
        })
        .catch((err) => {
          console.log(err)
          toast.error("Error while sending request!");
        })
  };

  if (error) {
    return <Error msg={error} />;
  }

  if (!venue) {
    return <Loader />;
  }

  const venuePrice = venue.minPrice;
  // const servicesPrice = (venue.services.filter(s => !s.optional).reduce((acc, current) => acc + current.price, 0));
  const otherServicesPrice = (venue.optional_services.filter(s => (watch("services") || []).find(c => c === s.id) ).reduce((acc, current) => acc + current.list_price, 0));
  const vat = (0);
  const totalPrice = (venuePrice + otherServicesPrice + vat);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="xl:px-10 px-5 py-12 venue_form">
      <Modal
          open={openConfirmation}
          onClose={handleCloseConfirmation}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="absolute w-11/12 md:w-2/5 mx-auto top-1/2 left-1/2  -translate-x-1/2 -translate-y-1/2 px-8 py-6 bg-white rounded-2xl shadow flex-col justify-center items-start inline-flex border-4 border-red">
            <div className="w-full">
              <div className="flex justify-end">
                <button
                  onClick={() => handleCloseConfirmation()}
                  className="self-stretch justify-between items-center flex"
                >
                  <FontAwesomeIcon
                    icon={faX}
                    className="text-xl text-gray-500"
                  />
                </button>
              </div>
              <div className="flex flex-col items-center mb-4">
                <p className="text-center">
                {t("Your booking request has been created. You will get back to you soon")}{" "}
                </p>
                <CiCircleCheck className="text-[200px] text-[#00ff2b]" />
              </div>
              <div className="w-full flex justify-center gap-5">
                <button
                  onClick={() => {handleCloseConfirmation(), navigate(`/user/booking-list/${bookingId}`);}}
                  className="w-1/3 h-[47px] px-[18px] py-[6.40px] bg-white rounded-[10px] shadow border border-red justify-center items-center gap-2.5 inline-flex"
                >
                  <span className="text-center text-red text-base font-normal font-['Roboto'] leading-normal">
                    {t("Ok")}
                  </span>
                </button>
              </div>
            </div>
          </Box>
        </Modal>
      <div className="justify-start items-center gap-2  hidden sm:inline-flex mb-6 flex-wrap">
        <div
          onClick={() => navigate("/venues")}
          className="text-zinc-400 text-base font-normal leading-[48px] hover:text-gray-dark cursor-pointer"
        >
          {t("Wedding Venues")}
        </div>
        <FontAwesomeIcon
          className="text-zinc-400 text-base"
          icon={faAngleRight}
        />
        <div
          onClick={() => navigate(`/facility/${venue.facility.id}`)}
          className="text-zinc-400 text-base font-normal leading-[48px] hover:text-gray-dark cursor-pointer"
        >
          {venue.facility?.name}
        </div>
        <FontAwesomeIcon
          className="text-zinc-400 text-base"
          icon={faAngleRight}
        />
        <div
          onClick={() => navigate(`/facility/${venue.facility.id}`)}
          className="text-zinc-400 text-base font-normal leading-[48px] hover:text-gray-dark cursor-pointer"
        >
          {venue.name}
        </div>
        <FontAwesomeIcon
          className="text-zinc-400 text-base"
          icon={faAngleRight}
        />
        <div className="text-zinc-400 text-base font-normal leading-[48px] hover:text-gray-dark cursor-pointer">
          {t("Check Availability")}
        </div>
      </div>
      <p className="text-red-dark text-2xl font-medium mb-6">
      {t("Check Availability")}
      </p>
      <div className="flex items-start gap-10 flex-wrap md:flex-nowrap">
        <div className="md:w-1/2 w-10/12">
          <div className="mb-6 flex flex-col items-center">
            <Controller
              control={control}
              rules={{ required: t("Date is required") }}
              name="date"
              render={({ field: { ref, onChange } }) => (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateCalendar
                    showDaysOutsideCurrentMonth
                    shouldDisableDate={shouldDisableDate}
                    fixedWeekNumber={6}
                    onChange={(date) => {
                      date.setMinutes(-date.getTimezoneOffset());
                      onChange(date);
                    }}
                    ref={dateCalendarRef}
                  />
                </LocalizationProvider>
              )}
            />
            <Typography variant="small" className="text-red-700">
              {errors.date?.message || ""}
            </Typography>
          </div>
          <div className="mb-6">
            <p className="text-zinc-800 text-base font-bold mb-4">
              {t("Included services list")}
            </p>
            <div className="flex flex-wrap items-center gap-4">
              {venue.included_services
                .map((service, index) => (
                  <div
                    className="p-3 rounded-xl border flex-col justify-start items-start gap-2.5 inline-flex"
                    key={index}
                  >
                    <div className="justify-start items-center gap-4 inline-flex">
                      <div className="w-7 h-7 px-px py-[5px] justify-center items-center flex">
                        <img src={includedServicesImages[index]?.data?.result?.category.image} alt="Icon" />
                      </div>
                      <div className="text-red text-base font-normal">
                        {service.name}
                      </div>
                    </div>
                    <div className="text-zinc-800 text-base font-normal">
                      {service.list_price} EGP
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className="mb-6">
            <p className="text-zinc-800 text-base font-bold mb-4">
              {t("Other Services")}
            </p>
            <div className="flex flex-wrap items-center gap-4">
              <VenueServices
                services={venue.optional_services}
                check={true}
                control={control}
                optionalServicesImages={optionalServicesImages}
              />
            </div>
          </div>
          {/* <div className="flex flex-col">
            <Checkbox
              {...register("terms", {
                required: "terms-and-conditions is required",
              })}
              label={
                <Link
                  to={"/terms-and-conditions"}
                  target="_blank"
                  className="text-blue-600 text-base font-normal underline leading-snug"
                >
                  Terms and conditions
                </Link>
              }
            />
            <Typography variant="small" className={"text-red-700"}>
              {errors.terms?.message || ""}
            </Typography>
          </div> */}
        </div>
        <div className="md:w-1/2 w-full">
          <div className="w-full flex-col justify-center items-start gap-6 inline-flex">
            <div className="self-stretch flex-col justify-center items-start gap-4 flex">
              <div className="self-stretch flex-col justify-start items-start gap-6 flex">
                <div className="self-stretch  flex-col justify-start items-start gap-1 flex">
                  <div className="self-stretch  flex-col justify-start items-start gap-2 flex">
                    <div className="self-stretch">
                      <span className="text-gray-dark text-base font-normal  leading-snug">
                        {t("Name of bride")}
                      </span>
                      <span className="text-red-500 text-base font-normal  leading-snug">
                        *
                      </span>
                    </div>
                    <input
                      {...register("bride_name", {
                        required: t("Bride name is required"),
                      })}
                      type="text"
                      className="w-full p-3 bg-slate-100 border-slate-100 rounded-lg placeholder-zinc-500 text-sm "
                      placeholder={t("Name")}
                    />
                    <Typography variant="small" className={"text-red-700"}>
                      {errors.bride_name?.message || ""}
                    </Typography>
                  </div>
                </div>
                <div className="self-stretch  flex-col justify-start items-start gap-1 flex">
                  <div className="self-stretch  flex-col justify-start items-start gap-2 flex">
                    <div className="self-stretch">
                      <span className="text-gray-dark text-base font-normal  leading-snug">
                        {t("Name of groom")}
                      </span>
                      <span className="text-red-500 text-base font-normal  leading-snug">
                        *
                      </span>
                    </div>
                    <input
                      {...register("groom_name", {
                        required: t("Groom name is required"),
                      })}
                      type="text"
                      className="w-full p-3 bg-slate-100 border-slate-100 rounded-lg placeholder-zinc-500 text-sm "
                      placeholder="Name"
                    />
                    <Typography variant="small" className={"text-red-700"}>
                      {errors.groom_name?.message || ""}
                    </Typography>
                  </div>
                </div>
                <div className="invitaion-verification self-stretch flex-col justify-start items-start gap-1 flex">
                  <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                    <div className="self-stretch">
                      <span className="text-gray-dark text-base font-normal  leading-snug">
                        {t("Mobile number")}
                      </span>
                      <span className="text-red-500 text-base font-normal  leading-snug">
                        *
                      </span>
                    </div>
                    <Controller
                      className="w-full"
                      control={control}
                      name="number"
                      rules={{ required: t("Phone Number is required") }}
                      render={({ field, field: { name, ref } }) => {
                        return (
                          <PhoneInput
                            className=""
                            error={errors.number}
                            defaultCountry="eg"
                            inputStyle={{
                              background: "rgb(242 244 248",
                              width: "100%",
                              margin: "0px 4px",
                              border: "0px",
                              padding: "0 14px",
                              height: "46px",
                              borderRadius: "8px",
                            }}
                            name={name}
                            ref={ref}
                            {...field}
                          />
                        );
                      }}
                    />
                    <Typography variant="small" className={"text-red-700"}>
                      {errors.number?.message || ""}
                    </Typography>
                  </div>
                </div>
                <div className="self-stretch  flex-col justify-start items-start gap-1 flex">
                  <div className="self-stretch  flex-col justify-start items-start gap-2 flex">
                    <div className="self-stretch text-gray-dark text-base font-normal  leading-snug">
                      {t("Email")}
                      <span className="text-red-500 text-base font-normal  leading-snug">
                        *
                      </span>
                    </div>
                    <input
                      {...register("email", {
                        required: t("Email is required"),
                      })}
                      value={user?.email}
                      type="text"
                      className="w-full p-3 bg-slate-100 border-slate-100 rounded-lg placeholder-zinc-500 text-sm "
                      placeholder={t("Email")}
                    />
                    <Typography variant="small" className={"text-red-700"}>
                      {errors.email?.message || ""}
                    </Typography>
                  </div>
                </div>
                <div className="self-stretch flex-col justify-start items-start gap-1 flex">
                  <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                    <div className="self-stretch">
                      <span className="text-gray-dark text-base font-normal  leading-snug">
                        {t("Message to provider")}
                      </span>
                    </div>
                    <input
                      {...register("message")}
                      type="text"
                      className="w-full p-3 h-[96px] bg-slate-100 border-slate-100 rounded-lg placeholder-zinc-500 text-sm "
                      placeholder={t("Type your message here...")}
                    />
                    <Typography variant="small" className={"text-red-700"}>
                      {errors.message?.message || ""}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
            <div className={"self-stretch"}>
              <dl className={"row space-y-3"}>
                <dt className={"text-dark"}>
                  {t("Venue Price")}{" "}
                  <span className={` ${i18n.language === 'en' ? 'float-right' : 'float-left '} text-red-dark`}>
                    {" "}
                    EGP {venuePrice.toFixed(2)}
                  </span>
                </dt>
                <dt className={"text-dark"}>
                 {t("Other Services Price")}{" "}
                  <span className= {` ${i18n.language === 'en' ? 'float-right' : 'float-left '} text-red-dark `}>
                    {" "}
                    EGP {otherServicesPrice.toFixed(2)}
                  </span>
                </dt>
              </dl>
              <hr className={"my-4"} />
              <dl className={"row gy-2"}>
                <dt className={"text-dark text-2xl font-bold"}>
                  {t("Total Price")}{" "}
                  <span
                    className={` ${i18n.language === 'en' ?'float-right':'float-left'}  text-red-dark text-2xl font-bold `}
                  >
                    {" "}
                    EGP {totalPrice.toFixed(2)}
                  </span>
                </dt>
              </dl>
            </div>
            <button
              disabled={loading}
              className="self-stretch px-[18px] py-[6.40px] bg-red disabled:brightness-50 rounded-[10px] shadow border border-white justify-center items-center gap-2.5 inline-flex"
            >
              <div className="w-full self-stretch py-1 justify-center items-center gap-2.5 flex">
                <div className="text-center text-white text-base font-normal leading-normal">
                  <Spinner hidden={!loading} size={"sm"} color={"gray"} /> 
                  {t("Send Request")}
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}

export default VenueAvailability;
