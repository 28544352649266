import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: { user: null },
  reducers: {
    loadFromLocalStorage(state, action) {
      if (sessionStorage.getItem("user")) {
        state.user = JSON.parse(sessionStorage.getItem("user"));
      }
    },
    login(state, action) {
      state.user = action.payload;
      sessionStorage.setItem("user", JSON.stringify(action.payload));
    },
    logout(state) {
      state.user = null;
      sessionStorage.removeItem("user");
    },
  },
});

export const authActions = authSlice.actions;
export default authSlice;