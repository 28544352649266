import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";
import {Typography} from "@material-tailwind/react";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import axios from "axios";
import {toast}  from "react-toastify";
import error from "../Error";
import useAxiosUserSecureConfig from '../../hooks/useAxiosUserSecureConfig'
import { useNavigate } from 'react-router';

function ForgotChangePassword (){
   
       
        const [showNewPassword , setShowNewPassword]= useState(false);
        const [showConfirmPassword , setShowConfirmPassword]= useState(false);
        const {t,i18n} = useTranslation();
        const [password, setPassword] = useState(" ");
        const [strength, setStrength] = useState(0);
        const [isValid, setIsValid] = useState(false);

        const handleChange = (event) => {
            setPassword(event.target.value);
          };
          const navigate = useNavigate()
          const evaluatePasswordStrength = (password) => {
            let score = 0;
        
            // Minimum length (customize as needed)
            if (password.length >= 8) {
              score++;
            }
        
            // Character types
            if (/[a-z]/.test(password)) score++;
            if (/[A-Z]/.test(password)) score++;
            if (/[0-9]/.test(password)) score++;
            if (/[!@#$%^&*()_+\-=\[\]{};':",./<>?|\\ ]/.test(password)) score++;
        
            // Deductions for common patterns (optional)
            if (password.toLowerCase().split("")
            .some((char) => password.includes(char.repeat(3))))
           {
              score--; // Repeated characters
            }
            if (password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^a-zA-Z\d]+$/)) {
              score--; // Predictable keyboard patterns
            }
        
            setStrength(score);
            setIsValid(score >= 4); // Set validity based on strength
            
          };
          const getStrengthText = (score) => {
            switch (score) {
              case 0:
              case 1:
                return t("Weak"); // Use translation key for "weak"
              case 2:
              case 3:
                return t("Normal"); // Use translation key for "normal"
              case 4:
                return t("Good"); // Use translation key for "strong"
              default:
                return t("Very Strong"); // Handle potential future extensions (optional)
            }
          };
        
          const strengthColor = (score) => {
            switch (score) {
              case 0: 
              case 1:
                return "text-red-50"; // Red for weak
              case 2:
              case 3:
                return "text-yellow-400"; // Yellow for normal
              default:
                return "text-green-700"; // Green for strong
            }
          };
        
        
          React.useEffect(() => {
            evaluatePasswordStrength(password);
           
          }, [password]);

    const {
        register,
        watch,
        formState: { errors },
        handleSubmit
    } = useForm();

   
    const config = useAxiosUserSecureConfig();


  function onSubmit(data){
     console.log('data',data) ;
        data={
            email: data.email,
            otp: data.otp,
            new_password: data.NewPassword,
        }

      axios.post('/api/forgot-change-password',data,config).then((res)=>{
          console.log(res.data.result)
          if (!res.data?.result?.success){ toast.error(res.data.result?.message || t("Wrong verification code !"))
                console.log(res.data)
              return ;
          }
          
          if (!isValid) {
          setError("password", {
            type: "custom",
            message: t("Password should be at least Good"),
          });
        
      return;
    }
        
          toast.success(t("Password reset Successufly"))
          navigate('/login');
      })
      .catch((error)=>{toast.error(t('Error while reset password'))
    })
  }


    return(
        <form onSubmit={handleSubmit(onSubmit)} >
           <div className=' md:w-1/2 w-4/5 mx-auto mb-5  mt-10 md:p-14  p-4 self-stretch '>
            <div className="text-red items-start text-2xl font-semibold font-['Roboto'] leading-9 mb-10">{t("Reset Password")}</div>
            
           <div className="flex-col justify-center items-center gap-4 flex">
                <div className="self-stretch flex-col justify-start items-start gap-4 flex">
                    <label
                        className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">{t("Your Email")}
                        </label>
                    <div className="relative w-full">
                        <input error={!!errors.email}
                               {...register("email", {required: t('email is required')})}
                               type='email'
                               className="w-full p-3 bg-slate-100 border-slate-100 rounded-lg "
                               placeholder={t('Email')}/>
                       
                    </div>
                    {errors.email &&
                        <Typography variant="small" color="red">
                            {errors.email?.message}
                        </Typography>
                    }
                </div>
               
                <div className="self-stretch flex-col justify-start items-start gap-4 flex">
                    <label
                        className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">{t("Verification Code")}
                        </label>
                    <div className="relative w-full">
                        <input error={!!errors.otp}
                               {...register("otp", {required: t('Verification Code is required')})}
                               type='text'
                               className="w-full p-3 bg-slate-100 border-slate-100 rounded-lg "
                               placeholder={t('code')}/>
                       
                    </div>
                    {errors.otp &&
                        <Typography variant="small" color="red">
                            {errors.otp?.message}
                        </Typography>
                    }
                </div>

        
                <div className="self-stretch flex-col justify-start items-start gap-4 flex">
                    <label
                        className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">
                            {t("New Password")}</label>
                    <div className="relative w-full">
                        <input error={!!errors.NewPassword}
                               {...register("NewPassword", 
                               {required: t('New Password is required'),
                               validate: () => isValid || t("Password should be at least Good"),})}
                               type={showNewPassword ? "text" : "password"}
                               value={password}
                               className="w-full p-3 bg-slate-100  border-slate-100 rounded-lg "
                               onChange={handleChange}
                               placeholder={t('New Password')}/>
                               {strength ==0 &&(<p className='text-zinc-500 text-xs font-normal leading-none mt-2'>
                                    {t("It must be a combination of minimum 8 letters, numbers and symbols.")}
                                    </p>
                                      )}
                                { strength >= 1 &&
                                    (<p className="">
                                      {t("Password Strength")}:
                                      <span className={strengthColor(strength)}>  
                                  {getStrengthText(strength)}
                                </span>
                                </p>)}
                             {showNewPassword ?
                                <FontAwesomeIcon icon={faEyeSlash} 
                                onClick={()=>setShowNewPassword((prevState) =>!prevState)} 
                                className={`absolute ${i18n.language === 'en' ? 'right-3' : 'left-3'} top-4 text-zinc cursor-pointer`}  /> 
                                :
                                <FontAwesomeIcon icon={faEye} onClick={()=>setShowNewPassword((prevState) =>!prevState)}  
                                className={`absolute ${i18n.language === 'en' ? 'right-3' : 'left-3'} top-4 text-zinc cursor-pointer`} />
                                }
                    </div>
                          {errors.NewPassword &&
                              <Typography variant="small" color="red" tex >
                                  {errors.NewPassword?.message}
                              </Typography>
                          }
                </div>


                <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                     <label className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">
                        {t("Confirm New Password")}
                        </label>
                    <div className="relative w-full">
                        <input error={!!errors.passwordRepeat}
                               {...register("passwordRepeat", {
                                   required: t('Password Confirmation is required'),
                                   validate: value => value === watch("NewPassword") || t("The passwords do not match"),
                               })}
                               type={showConfirmPassword ? "text" : "password"}
                               className="w-full p-3 bg-slate-100 border-slate-100 rounded-lg "
                               placeholder={t('New Password')}/>
                         {showConfirmPassword ?
                              <FontAwesomeIcon icon={faEyeSlash} onClick={()=>setShowConfirmPassword((prevState) =>!prevState)}
                               className={`absolute ${i18n.language === 'en' ? 'right-3' : 'left-3'} top-4 text-zinc cursor-pointer`} /> 
                               :
                              <FontAwesomeIcon icon={faEye} onClick={()=>setShowConfirmPassword((prevState) =>!prevState)} 
                               className={`absolute ${i18n.language === 'en' ? 'right-3' : 'left-3'} top-4 text-zinc cursor-pointer`} />
                               }
                    </div>
                    {errors.passwordRepeat &&
                        <Typography variant="small" color="red">
                            {errors.passwordRepeat?.message}
                        </Typography>
                    }
                </div>
         </div>
         </div>
                 <div className="w-full flex justify-center items-center my-10 gap-2">
                    <button  type="reset"
                             className="md:w-1/6 w-2/6 px-3 py-4 bg-white border border-red rounded-lg ">
                        <span onClick={()=>navigate('/login')} className="text-red text-base font-medium leading-none tracking-wide">
                          {t("Cancel")}
                          </span>
                    </button>
                    <button type="submit" className="md:w-1/6 w-2/6 px-3 py-4 bg-red rounded-lg  "
                     >
                        <span className="text-white text-base font-medium leading-none tracking-wide">{t("Save")}</span>
                    </button>
               </div>
        </form>
    )


}

export default ForgotChangePassword
