import { Typography } from "@material-tailwind/react";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import useAxiosUserSecureConfig from "../../hooks/useAxiosUserSecureConfig";
import { FaEdit } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { PhoneInput } from "react-international-phone";
import {useTranslation} from "react-i18next";

function EditFacility() {
  const { t, i18n } = useTranslation();
  const {
    register,
    watch,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const [countries, setCountries] = useState({ loading: true, data: [] });
  const [states, setStates] = useState({ loading: false, data: [] });
  const [cities, setCities] = useState({ loading: false, data: [] });
  const [categories, setCategories] = useState({ loading: true, data: [] });

  useEffect(() => {
    axios
      .post("/api/country", {})
      .then((res) => {
        if (!res.data?.result?.success) {
          toast.error("Error while fetching countries!");
          return;
        }

        setCountries((prevState) => ({
          ...prevState,
          loading: false,
          data: res.data?.result?.countries ?? [],
        }));
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .post("/api/facility-types", {})
      .then((res) => {
        console.log("facility types", res.data);

        if (!res.data?.result?.success) {
          toast.error("Error while fetching facility types");
          return;
        }

        setCategories((prevState) => ({
          ...prevState,
          loading: false,
          data: res.data?.result?.facilityTypes ?? [],
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const country = watch("country");
  const state = watch("state");

  useEffect(() => {

    if (!country) {
      setStates((prevState) => ({
        ...prevState,
        data: [],
      }));
      setCities((prevState) => ({
        ...prevState,
        data: [],
      }));
      return;
    }

    setStates((prevState) => ({
      ...prevState,
      loading: true,
    }));

    axios
      .post(`/api/country/${country.value}`, {})
      .then((res) => {
        if (!res.data?.result?.success) {
          toast.error("Error while fetching states!");
          return;
        }

        setStates((prevState) => ({
          ...prevState,
          loading: false,
          data: res.data?.result?.countries ?? [],
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [country]);

  useEffect(() => {

    if (!state) {
      setCities((prevState) => ({
        ...prevState,
        data: [],
      }));
      return;
    }

    setCities((prevState) => ({
      ...prevState,
      loading: true,
    }));

    axios
      .post(`/api/state/${state.value}`, {})
      .then((res) => {
        if (!res.data?.result?.success) {
          toast.error("Error while fetching cities!");
          return;
        }

        setCities((prevState) => ({
          ...prevState,
          loading: false,
          data: res.data?.result?.state.cities ?? [],
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [state]);

  const params = useParams();
  const [facility, setFacility] = useState({
    loading: true,
    data: {},
  });
  const [imageBase64, setImageBase64] = useState(null);
  const handleImageChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageBase64(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  useEffect(() => {
    axios
      .post(`/api/facility/${params.id}`, { id: params.id })
      .then((res) => {
        console.log("facility", res.data);

        if (!res.data?.result?.success) {
          toast.error("Error while fetching facility!");
        }
        setImageBase64(res.data?.result?.image);
        setFacility({
          loading: false,
          data: res.data?.result || {}, // Set to an empty object if undefined
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [params.id]);


  const navigate = useNavigate();
  const config = useAxiosUserSecureConfig();

  const onSubmit = (data) => {
    console.log("Form Data:", data);
    data = {
      facility_id: facility.data.id,
      name: data.facilityName,
      description: data.description,
      facility_type_id: data.type.value,
      country_id: data.country.value,
      state_id: data.state.value,
      city_id: data.city.value,
      address: data.address,
      website: data.website,
      email: data.email,
      phone: data.phone,
      image: imageBase64.split(",")[1],
      business_start_year: data.startingYear,
      zip_code: data.zipCode,
      location_on_map: data.mapLocation,
      commercial_record_number: data.commericalRecord,
      tax_id: data.taxId,
      youtube_link: data.youtubeLink,
      facebook_link: data.facebookLink,
      tiktok_link: data.tiktokkLink,
      instagram_link: data.instagramLink,
      x_link: data.xLink,
    };
    axios
      .post("/api/venue-vendor/facility/update", data, config)
      .then((res) => {
        if (!res.data.result?.success) {
          toast.error(res.data.result?.message || "Request failed!");
          return;
        }
        toast.success(t("You update facility successfully!"));
        navigate("/vendor/Facilities");
      })
      .catch((err) => {
        console.log("err", err);
        toast.error("Error while updating facility!");
      });
  };

  const customStyles = {
    control: (styles) => ({
      ...styles,
      background: "#F2F4F8",
      "border-color": "#F2F4F8",
      "border-radius": "8px",
      padding: "6px",
    }),
  };

  return (
    <section className="px-5 xl:px-20 py-10">
      {facility.loading && <Loader slim />}
      {!facility.loading && (
        <>
          <div className="justify-start items-center gap-2 hidden sm:inline-flex mb-10">
            <div
              onClick={() => navigate("/vendor/account")}
              className="text-zinc-400 text-base font-normal leading-[48px] hover:text-gray-dark cursor-pointer"
            >
              {t("My Account")}
            </div>
            <FontAwesomeIcon
              className="text-zinc-400 text-base"
              icon={faAngleRight}
            />
            <div
              onClick={() => navigate("/vendor/Facilities")}
              className="text-zinc-400 text-base font-normal leading-[48px] hover:text-gray-dark cursor-pointer"
            >
              {t("My Facilities")}
            </div>
            <FontAwesomeIcon
              className="text-zinc-400 text-base"
              icon={faAngleRight}
            />
            <div
              onClick={() => navigate(`/vendor/Facilities/${facility.data.id}`)}
              className="text-zinc-400 text-base font-normal leading-[48px] hover:text-gray-dark cursor-pointer"
            >
              {facility.data.name}
            </div>
            <FontAwesomeIcon
              className="text-zinc-400 text-base"
              icon={faAngleRight}
            />
            <div className="text-zinc-400 text-base font-normal leading-[48px] hover:text-gray-dark cursor-pointer">
              {t("Edit")}
            </div>
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex gap-20 flex-wrap md:flex-nowrap"
          >
            <div className="flex flex-col items-center gap-6 w-full md:w-auto">
              <div className="flex flex-col gap-6 items-center">
                <img
                  className="w-[205px] h-[205px] rounded-full border object-cover"
                  src={imageBase64}
                  alt=""
                />
              </div>
              <label htmlFor="uploadBtn">
                <button
                  type="button"
                  className="px-2 py-[6.40px] bg-white rounded-[10px] shadow border border-red justify-center items-center gap-2.5 inline-flex"
                  onClick={() => document.getElementById("uploadBtn").click()}
                >
                  <div className="py-1 justify-center items-center gap-2.5 flex">
                    <div className="text-center text-red text-base font-normal leading-normal">
                      {t("Change photo")}
                    </div>
                    <FaEdit className="text-red font-normal" />
                  </div>
                </button>
                <input
                  type="file"
                  {...register("photo")} // assuming "photo" is the field name
                  id="uploadBtn"
                  hidden
                  onChange={handleImageChange}
                />
              </label>
            </div>
            <div className="grow">
              <p className="text-lg md:text-2xl font-semibold leading-9 mb-6 text-red">
                {t("Edit Facility")}
              </p>
              <div>
                <div className="flex flex-col gap-10">
                  <div className="grid lg:grid-cols-2 gap-x-6 gap-y-4">
                    <div className="flex flex-col justify-start items-start gap-2 ">
                      <label className="text-zinc-800 text-base font-normal leading-snug">
                        {t("Facility name")}
                      </label>
                      <Controller
                        rules={{ required: t("Facility brand name is required") }}
                        name="facilityName"
                        control={control}
                        defaultValue={facility.data.name}
                        render={({ field }) => (
                          <input
                            type="text"
                            {...field}
                            className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                          />
                        )}
                      />
                      {errors.facilityName && (
                        <Typography variant="small" color="red">
                          {errors.facilityName?.message}
                        </Typography>
                      )}
                    </div>

                    <div className="flex flex-col justify-start items-start gap-2 ">
                      <label className="text-zinc-800 text-base font-normal leading-snug">
                        {t("Business website")}
                      </label>
                      <Controller
                        rules={{ required: t("Business website is required")}}
                        name="website"
                        control={control}
                        defaultValue={facility.data.website}
                        render={({ field }) => (
                          <input
                            type="text"
                            {...field}
                            className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                          />
                        )}
                      />
                      {errors.website && (
                        <Typography variant="small" color="red">
                          {errors.website?.message}
                        </Typography>
                      )}
                    </div>
                    <div className="flex flex-col justify-start items-start gap-2 ">
                      <label className="text-zinc-800 text-base font-normal leading-snug">
                        {t("Description")}
                      </label>
                      <Controller
                        rules={{ required: t("Description is required") }}
                        name="description"
                        control={control}
                        defaultValue={facility.data.description}
                        render={({ field }) => (
                          <input
                            type="text"
                            {...field}
                            className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                          />
                        )}
                      />
                      {errors.description && (
                        <Typography variant="small" color="red">
                          {errors.description?.message}
                        </Typography>
                      )}
                    </div>
                    {categories.loading && <Loader slim />}
                    {!categories.loading && (
                      <div className="flex flex-col justify-start items-start gap-2">
                        <Controller
                          control={control}
                          name="type"
                          rules={{ required: t("Type is required") }}
                          defaultValue={
                            facility.data.type.id
                              ? {
                                  value: facility.data.type.id,
                                  label: facility.data.type.name,
                                }
                              : null
                          }
                          render={({ field, field: { name, ref } }) => (
                            <>
                              <label className="text-zinc-800 text-base font-normal leading-snug">
                                {t("Type")}
                              </label>
                              <Select
                                styles={customStyles}
                                name={name}
                                ref={ref}
                                isClearable
                                isSearchable
                                options={categories.data.map((category) => ({
                                  value: category.id,
                                  label: category.name,
                                }))}
                                {...field}
                                className="w-full"
                                value={field.value}
                                onChange={(selectedOption) => {
                                  field.onChange(selectedOption);
                                }}
                              />
                              {errors.type && (
                                <Typography variant="small" color="red">
                                  {errors.type?.message}
                                </Typography>
                              )}
                            </>
                          )}
                        />
                      </div>
                    )}

                    <div className="flex flex-col justify-start items-start gap-2 ">
                      <label className="text-zinc-800 text-base font-normal leading-snug">
                        {t("The year your business starts")}
                      </label>
                      <Controller
                        rules={{
                          required:
                            t("The year your business starts is required"),
                        }}
                        name="startingYear"
                        control={control}
                        defaultValue={facility.data.business_start_year}
                        render={({ field }) => (
                          <input
                            type="number"
                            {...field}
                            className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                          />
                        )}
                      />
                      {errors.startingYear && (
                        <Typography variant="small" color="red">
                          {errors.startingYear?.message}
                        </Typography>
                      )}
                    </div>
                    {countries.loading && <Loader slim />}
                    {!countries.loading && (
                      <div className="flex flex-col justify-start items-start gap-2">
                        <Controller
                          control={control}
                          name="country"
                          rules={{ required: "Country is required" }}
                          defaultValue={
                            facility.data.country.id
                              ? {
                                  value: facility.data.country.id,
                                  label: facility.data.country.name,
                                }
                              : null
                          }
                          render={({ field, field: { name, ref } }) => (
                            <>
                              <label className="text-zinc-800 text-base font-normal leading-snug">
                                {t("Country")}
                              </label>
                              <Select
                                styles={customStyles}
                                name={name}
                                ref={ref}
                                isClearable
                                isSearchable
                                options={countries.data.map((country) => ({
                                  value: country.id,
                                  label: country.name,
                                }))}
                                {...field}
                                className="w-full"
                                value={field.value}
                                onChange={(selectedOption) => {
                                  field.onChange(selectedOption);
                                }}
                              />
                              {errors.country && (
                                <Typography variant="small" color="red">
                                  {errors.country?.message}
                                </Typography>
                              )}
                            </>
                          )}
                        />
                      </div>
                    )}

                    {states.loading && <Loader slim />}
                    {!states.loading && (
                      <div className="flex flex-col justify-start items-start gap-2">
                        <Controller
                          control={control}
                          name="state"
                          rules={{ required: "State is required" }}
                          defaultValue={
                            facility.data.state.id
                              ? {
                                  value: facility.data.state.id,
                                  label: facility.data.state.name,
                                }
                              : null
                          }
                          render={({ field, field: { name, ref } }) => (
                            <>
                              {console.log("Inside State Select Render")}
                              <label className="text-zinc-800 text-base font-normal leading-snug">
                                {t("State")}
                              </label>
                              <Select
                                styles={customStyles}
                                name={name}
                                ref={ref}
                                isClearable
                                isSearchable
                                options={states.data.map((state) => ({
                                  value: state.id,
                                  label: state.name,
                                }))}
                                {...field}
                                className="w-full"
                                value={field.value}
                                onChange={(selectedOption) => {
                                  field.onChange(selectedOption);
                                }}
                              />
                              {errors.state && (
                                <Typography variant="small" color="red">
                                  {errors.state?.message}
                                </Typography>
                              )}
                            </>
                          )}
                        />
                      </div>
                    )}

                    {cities.loading && <Loader slim />}
                    {!cities.loading && (
                      <div className="flex flex-col justify-start items-start gap-2">
                        <Controller
                          control={control}
                          name="city"
                          rules={{ required: "City is required" }}
                          defaultValue={
                            facility.data.city.id
                              ? {
                                  value: facility.data.city.id,
                                  label: facility.data.city.name,
                                }
                              : null
                          }
                          render={({ field, field: { name, ref } }) => (
                            <>
                              <label className="text-zinc-800 text-base font-normal leading-snug">
                                {t("City")}
                              </label>
                              <Select
                                styles={customStyles}
                                name={name}
                                ref={ref}
                                isClearable
                                isSearchable
                                options={cities.data.map((city) => ({
                                  value: city.id,
                                  label: city.name,
                                }))}
                                {...field}
                                className="w-full"
                                value={field.value}
                                onChange={(selectedOption) => {
                                  field.onChange(selectedOption);
                                }}
                              />
                              {errors.city && (
                                <Typography variant="small" color="red">
                                  {errors.city?.message}
                                </Typography>
                              )}
                            </>
                          )}
                        />
                      </div>
                    )}
                    <div className="flex flex-col justify-start items-start gap-2 ">
                      <label className="text-zinc-800 text-base font-normal leading-snug">
                        {t("Zip code")}
                      </label>
                      <Controller
                        rules={{ required: t("zip code is required") }}
                        name="zipCode"
                        control={control}
                        defaultValue={facility.data.zip_code}
                        render={({ field }) => (
                          <input
                            type="number"
                            {...field}
                            className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                          />
                        )}
                      />
                      {errors.zipCode && (
                        <Typography variant="small" color="red">
                          {errors.zipCode?.message}
                        </Typography>
                      )}
                    </div>
                    <div className="flex flex-col justify-start items-start gap-2 ">
                      <label className="text-zinc-800 text-base font-normal leading-snug">
                        {t("Address")}
                      </label>
                      <Controller
                        rules={{ required: t("Address is required") }}
                        name="address"
                        control={control}
                        defaultValue={facility.data.address}
                        render={({ field }) => (
                          <input
                            type="text"
                            {...field}
                            className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                          />
                        )}
                      />
                      {errors.address && (
                        <Typography variant="small" color="red">
                          {errors.address?.message}
                        </Typography>
                      )}
                    </div>
                    <div className="flex flex-col justify-start items-start gap-2 ">
                      <label className="text-zinc-800 text-base font-normal leading-snug">
                        {t("Location on map")}
                      </label>
                      <Controller
                        rules={{ required: t("location is required") }}
                        name="mapLocation"
                        control={control}
                        defaultValue={facility.data.location_on_map}
                        render={({ field }) => (
                          <input
                            type="text"
                            {...field}
                            className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                          />
                        )}
                      />
                      {errors.mapLocation && (
                        <Typography variant="small" color="red">
                          {errors.mapLocation?.message}
                        </Typography>
                      )}
                    </div>
                    <div className="invitaion-verification flex flex-col justify-start items-start gap-2 ">
                      <label className="text-zinc-800 text-base font-normal leading-snug">
                        {t("Phone Number")}
                      </label>
                      <Controller
                        className="w-full"
                        control={control}
                        name="phone"
                        rules={{ required: t("Phone Number is required") }}
                        defaultValue={facility.data.phone}
                        render={({ field, field: { name, ref } }) => {
                          return (
                            <PhoneInput
                              className=""
                              error={errors.phone}
                              defaultCountry="eg"
                              inputStyle={{
                                background: "rgb(242 244 248",
                                width: "100%",
                                margin: "0px 4px",
                                border: "0px",
                                padding: "0 14px",
                                height: "46px",
                                borderRadius: "8px",
                              }}
                              name={name}
                              ref={ref}
                              {...field}
                            />
                          );
                        }}
                      />
                      {errors.phone && (
                        <Typography variant="small" color="red">
                          {errors.phone?.message}
                        </Typography>
                      )}
                    </div>
                    <div className="flex flex-col justify-start items-start gap-2 ">
                      <label className="text-zinc-800 text-base font-normal leading-snug">
                        {t("Email address")}
                      </label>
                      <Controller
                        rules={{ required: t("email is required") }}
                        name="email"
                        control={control}
                        defaultValue={facility.data.email}
                        render={({ field }) => (
                          <input
                            type="text"
                            {...field}
                            className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                          />
                        )}
                      />
                      {errors.email && (
                        <Typography variant="small" color="red">
                          {errors.email?.message}
                        </Typography>
                      )}
                    </div>
                    <div className="flex flex-col justify-start items-start gap-2 ">
                      <label className="text-zinc-800 text-base font-normal leading-snug">
                      {t("Commercial Record")}
                      </label>
                      <Controller
                        rules={{
                          required: t("Commercial record number is required"),
                        }}
                        name="commericalRecord"
                        control={control}
                        defaultValue={facility.data.commercial_record_number}
                        render={({ field }) => (
                          <input
                            type="number"
                            {...field}
                            className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                          />
                        )}
                      />
                      {errors.commericalRecord && (
                        <Typography variant="small" color="red">
                          {errors.commericalRecord?.message}
                        </Typography>
                      )}
                    </div>
                    <div className="flex flex-col justify-start items-start gap-2 ">
                      <label className="text-zinc-800 text-base font-normal leading-snug">
                        {t("Tax ID")}
                      </label>
                      <Controller
                        rules={{ required: t("Tax ID is required") }}
                        name="taxId"
                        control={control}
                        defaultValue={facility.data.tax_id}
                        render={({ field }) => (
                          <input
                            type="number"
                            {...field}
                            className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                          />
                        )}
                      />
                      {errors.taxId && (
                        <Typography variant="small" color="red">
                          {errors.taxId?.message}
                        </Typography>
                      )}
                    </div>
                    <div className="flex flex-col justify-start items-start gap-2 ">
                      <label className="text-zinc-800 text-base font-normal leading-snug">
                      {t("Youtube Link")}
                      </label>
                      <Controller
                        name="youtubeLink"
                        control={control}
                        defaultValue={facility.data.youtube_link}
                        render={({ field }) => (
                          <input
                            type="text"
                            {...field}
                            className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                          />
                        )}
                      />
                    </div>
                    <div className="flex flex-col justify-start items-start gap-2 ">
                      <label className="text-zinc-800 text-base font-normal leading-snug">
                        {t("Facebook Link")}
                      </label>
                      <Controller
                        name="facebookLink"
                        control={control}
                        defaultValue={facility.data.facebook_link}
                        render={({ field }) => (
                          <input
                            type="text"
                            {...field}
                            className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                          />
                        )}
                      />
                    </div>
                    <div className="flex flex-col justify-start items-start gap-2 ">
                      <label className="text-zinc-800 text-base font-normal leading-snug">
                      {t("TikTok Link")}
                      </label>
                      <Controller
                        name="tiktokkLink"
                        control={control}
                        defaultValue={facility.data.tiktok_link}
                        render={({ field }) => (
                          <input
                            type="text"
                            {...field}
                            className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                          />
                        )}
                      />
                    </div>
                    <div className="flex flex-col justify-start items-start gap-2 ">
                      <label className="text-zinc-800 text-base font-normal leading-snug">
                      {t("Instagram Link")}
                      </label>
                      <Controller
                        name="instagramLink"
                        control={control}
                        defaultValue={facility.data.instagram_link}
                        render={({ field }) => (
                          <input
                            type="text"
                            {...field}
                            className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                          />
                        )}
                      />
                    </div>
                    <div className="flex flex-col justify-start items-start gap-2 ">
                      <label className="text-zinc-800 text-base font-normal leading-snug">
                      {t("X Link")}
                      </label>
                      <Controller
                        name="xLink"
                        control={control}
                        defaultValue={facility.data.x_link}
                        render={({ field }) => (
                          <input
                            type="text"
                            {...field}
                            className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                          />
                        )}
                      />
                    </div>
                  </div>

                  <div className="flex justify-end w-full">
                    <button
                      type="submit"
                      className="w-fit px-16 py-3 bg-red rounded-[10px] shadow border border-white justify-center items-center gap-2.5 inline-flex"
                    >
                      <div className="text-center text-white text-base font-normal leading-normal">
                        {t("Save")}
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </>
      )}
    </section>
  );
}

export default EditFacility;
