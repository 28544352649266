import React, {useEffect, useState} from "react";
import {Typography} from "@material-tailwind/react";
import {useTranslation} from "react-i18next";
import {Controller, useForm} from "react-hook-form";
import vendorImg from "../../assets/images/vendor/vendor.png"
import {defaultCountries, parseCountry, PhoneInput} from "react-international-phone";
import Loader from "../../components/Loader";
import Select from "react-select";
import clsx from "clsx";
import axios from "axios";
import {toast} from "react-toastify";
import editIcon from "../../assets/images/profile/icons/edit-2.svg";
import { useNavigate } from "react-router-dom";
import placholder_image from "../../assets/images/booking/placeholder-image.jpg"
import uplooad from "../../assets/images/icons/upload.svg";
import useAxiosUserSecureConfig from "../../hooks/useAxiosUserSecureConfig";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FaEdit } from "react-icons/fa";
import ConfirmationModal from "../../components/ConfirmationModal";

function DeleteAccount(){
    const [imageBase64, setImageBase64] = useState(null);

    const handleImageChange = (e) => {
      const selectedFile = e.target.files[0];
  
      if (selectedFile) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImageBase64(reader.result);
        };
        reader.readAsDataURL(selectedFile);
      }
    };

    const navigate = useNavigate();
   

    const {t} = useTranslation();

    const {
        register,
        watch,
        control,
        setError,
        setValue,
        formState: { errors },
        handleSubmit
    } = useForm({ defaultValues: {country_id: 1, product_ids: []} });


    const customStyles = {
        control: (styles) => ({
            ...styles,
            background: "#F2F4F8", "border-color": "#F2F4F8" , "border-radius" :"8px" ,padding: "6px" })
    }



    const menuStyle =
        "border rounded-md bg-white hover:cursor-pointer px-4 py-2";

    const optionStyles = {
        focus: "hover:cursor-pointer hover:text-red",
        Selected: "bg-[#F2F4F8]",
    };

    const baseStyle =
        " bg-[#F2F4F8]  rounded-lg self-stretch grow shrink bg- text-zinc-500 text-sm font-normal leading-tight h-12 px-[18px]  justify-start items-center gap-2 inline-flex";

    const [countries , setCountries] = useState({ loading: true, data: [] })
    const [states , setStates] = useState({ loading: false, data: [] })
    const [cities , setCities] = useState({ loading: false, data: [] })
    const [vendor, setVendor]=useState({ loading: true, data: [] })
    useEffect(() => {
        axios
          .post('/api/my-vendor-profile',{}, config)
          .then((res) => {
            console.log("vendor", res.data);
    
            if (!res.data?.result?.success) {
              toast.error("Error while fetching vendor data!");
            }
            setVendor({
              loading: false,
              data: res.data?.result.vendor || {}, // Set to an empty object if undefined
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }, []);

    useEffect(() => {
        axios.post('/api/country', {}).then((res) => {
            console.log("countries", res.data);

            if (!res.data?.result?.success) {
                toast.error("Error while fetching countries!");
                return;
            }

            setCountries(prevState => ({
                ...prevState,
                loading: false,
                data: res.data?.result?.countries ?? []
            }))
        }).catch((err) => {
            console.log(err);
        })

    }, []);

    const country = watch("country");
    const state = watch("state");

    useEffect(() => {
        setValue("state", null);
        setValue("city", null);

        if (!country) {
            setStates(prevState => ({
                ...prevState,
                data: []
            }))
            setCities(prevState => ({
                ...prevState,
                data: []
            }))
            return;
        }

        setStates(prevState => ({
            ...prevState,
            loading: true,
        }));

        axios.post(`/api/country/${country.value}`, {}).then((res) => {
            console.log("states", res.data);

            if (!res.data?.result?.success) {
                toast.error("Error while fetching states!");
                return;
            }

            setStates(prevState => ({
                ...prevState,
                loading: false,
                data: res.data?.result?.countries ?? [] // TODO: fix naming in backend from countries to states
            }))
        }).catch((err) => {
            console.log(err);
        })
    }, [country]);

    useEffect(() => {
        setValue("city", null)

        if (!state) {
            setCities(prevState => ({
                ...prevState,
                data: []
            }))
            return;
        }

        setCities(prevState => ({
            ...prevState,
            loading: true,
        }));

        axios.post(`/api/state/${state.value}`, {}).then((res) => {
            console.log("cities", res.data);

            if (!res.data?.result?.success) {
                toast.error("Error while fetching cities!");
                return;
            }

            setCities(prevState => ({
                ...prevState,
                loading: false,
                data: res.data?.result?.state.cities ?? []
            }))
        }).catch((err) => {
            console.log(err);
        })
    }, [state]);

    const config = useAxiosUserSecureConfig(); 

    


    const [phone, setPhone] = useState('');
    const countriies = defaultCountries.filter((country) => {
        const { iso2 } = parseCountry(country);
        return ['us', 'ua', 'gb','eg',].includes(iso2);
    })

    const [open, setOpen] =useState(false);
    const handleClose = () => setOpen(false); 
    const onSubmit = (data) => {
      setOpen(true)
      console.log("data" , data)
    };
    const handleDeleteVendor= () => {
        let data = {
            Vendor_id: vendor.data
        };
    };
  
  //   axios.post('/api/venue-vendor/delete-venue' ,data, config)
  //       .then((res) => {
  //         if (!res.data.result?.success) {
  //           toast.error(res.data.result?.message || "Request failed!");
  //           return;
  //         }
  //         toast.success("You delete Venue successfully!");
  //         navigate(`/vendor/Facilities/${facility.data.id}`)
          
  //       })
  //       .catch((err) => {
  //         console.log("err" , err)
  //         toast.error("Error while deleting Venue!");
  //       })
   // }
    return(
<section className="px-5 xl:px-10 py-10">
<ConfirmationModal
      open={open}
      handleClose={handleClose}
      handleAction={handleDeleteVendor}
      msg={t("Are you sure you want to delete this Venue?")}
    />
{vendor.loading && <Loader slim />}
      {!vendor.loading && (
        <>
         <div className="justify-start items-center gap-2 inline-flex mb-10">
          <div
            onClick={() => navigate("/vendor/account")}
            className="text-zinc-400 text-base font-normal leading-[48px] hover:text-gray-dark cursor-pointer"
          >
            My Account
          </div>
          <FontAwesomeIcon
            className="text-zinc-400 text-base"
            icon={faAngleRight}
          />
          <div
            onClick={() => navigate("/vendor/delete-account")}
            className="text-zinc-400 text-base font-normal leading-[48px] hover:text-gray-dark cursor-pointer"
          >
           Delete Account
          </div>
          </div>
    <div className="xl:px-10 flex gap-20 flex-wrap md:flex-nowrap">
   
        <div className="flex flex-col justify-between items-center gap-6 w-full md:w-auto">
        <div className="flex flex-col items-center gap-6 w-full md:w-auto">
            <div className="flex flex-col gap-6 items-center">
              <img
                className="w-[205px] h-[205px] rounded-full border object-cover"
                src={imageBase64}
                alt=""
              />
            </div>
            <label htmlFor="uploadBtn">
              <button
                type="button"
                className="px-2 py-[6.40px] bg-white rounded-[10px] shadow border border-red justify-center items-center gap-2.5 inline-flex"
                onClick={() => document.getElementById("uploadBtn").click()}
              >
                <div className="py-1 justify-center items-center gap-2.5 flex">
                  <div className="text-center text-red text-base font-normal leading-normal">
                    Change photo
                  </div>
                 <FaEdit className="text-red font-normal"/>
                </div>
              </button>
              <input
                type="file"
                {...register("photo")} // assuming "photo" is the field name
                id="uploadBtn"
                hidden
                onChange={handleImageChange}
              />
            </label>
          </div>
               
            </div>


        <div className="grow">
            <div className="flex justify-between items-center mb-4">
                <p className="text-lg md:text-2xl text-red font-semibold leading-9">Delete Account</p>
              
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div
                    className="w-full flex-col  md:grid grid-cols-2 justify-start items-start gap-4 inline-flex mb-10">
                    <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                    <label className="text-zinc-800 text-base font-normal leading-snug">
                       Vendor name
                     </label>
                     <input
                        type="text"
                        value={vendor.data.name}
                        readOnly
                        className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                      />
                
                    </div>
                    <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                        <label
                            className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">Email</label>
                       <input
                        type="text"
                        value={vendor.data.email}
                        readOnly
                        className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                      />
                    </div>

                    <div className="invitaion-verification self-stretch flex-col justify-start items-start gap-2 flex">
                        <label
                            className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">Phone
                            Number</label>

                        <div className="w-full relative flex justify-between">

                            <Controller
                            className="w-full"
                            control={control}
                            defaultValue={vendor.data.phone}
                            name="phone"
                            render={({ field, field: { name, ref } }) => {
                                return (
                                <PhoneInput
                                    className=""
                                    error={errors.phone}
                                    defaultCountry="eg"
                                    inputStyle={{
                                    background: "rgb(242 244 248",
                                    width: "100%",
                                    margin: "0px 4px",
                                    border: "0px",
                                    padding: "0 14px",
                                    height: "46px",
                                    borderRadius: "8px",
                                    }}
                                    name={name}
                                    ref={ref}
                                    {...field}
                                />
                                );
                            }}
                            />

                        </div>
                      
                    </div>
                

                    <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                        <label
                            className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">Brand
                            name</label>
                            <input
                        type="text"
                        value={vendor.data.name}
                        readOnly
                        className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                      />
                    </div>


                    <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                        <label
                            className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">Business
                            website</label>
                            <input
                        type="text"
                        value={vendor.data.website}
                        readOnly
                        className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                      />
                    </div>


                    <div className=" self-stretch flex-col justify-start items-start gap-2 flex">
                        <label
                            className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">The
                            year your business starts</label>
                        <input type="text"
                        value={vendor.data.business_start_year}
                        readOnly
                        className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                      />
                    </div>


            
                {countries.loading && <Loader slim />}
                  {!countries.loading && (
                    <div className="flex flex-col justify-start items-start gap-2">
                      <Controller
                        control={control}
                        name="country"
                        rules={{ required: "Country is required" }}
                        defaultValue={vendor.data.country_id}
                        //     ? {
                        //         value: vendor.data.country.id,
                        //         label: vendor.data.country.name,
                        //       }
                        //     : null
                        
                        render={({ field}) => (
                          <>
                            <label className="text-zinc-800 text-base font-normal leading-snug">
                              Country
                            </label>
                            <input type="text"
                        value={vendor.data.country_id}
                        readOnly
                        className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                      />
                        
                          </>
                        )}
                      />
                    </div>
                  )}
                   
                    <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                        <label
                            className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">State</label>
                        {states.loading && <Loader slim/>}
                        {!states.loading && (
                            <Controller
                                control={control}
                                name="state" 
                                defaultValue={vendor.data.state_id}
                                render={({field, field: {name, ref}}) => {
                                    return (
                                        <input type="text"
                                        value={vendor.data.state_id}
                                        readOnly
                                        className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                                      />
                                    );
                                }}
                            />
                        )}
                        <Typography variant="small" color="red">{errors.state?.message || ""}</Typography>
                    </div>

                    <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                        <label
                            className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">City</label>
                        {cities.loading && <Loader slim/>}
                        {!cities.loading && (
                            <Controller className='w-full'
                                        control={control}
                                        name="city"
                                       
                                        render={({field, field: {name, ref}}) => {
                                            return (
                                            <input type="text"
                                            value={vendor.data.city_id}
                                            readOnly
                                            className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                                            />
                                            );
                                        }}
                            />
                        )}
                        <Typography variant="small" color="red">{errors.city?.message || ""}</Typography>
                    </div>

                    <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                        <label
                            className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">Zip
                            Code</label>
                            <input type="text"
                        value={vendor.data.zip}
                        readOnly
                        className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                      />
                    </div>


                    <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                        <label
                            className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">
                            Address</label>
                            <input type="text"
                        value={vendor.data.address}
                        readOnly
                        className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                      />
                    </div>

                    <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                        <label
                            className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">Location
                            on map</label>
                            <input type="text"
                        value={vendor.data.location}
                        readOnly
                        className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                      />
                    </div>


                    <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                        <label
                            className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">Commercial
                            Record</label>
                            <input type="text"
                        value={vendor.data.cr_no}
                        readOnly
                        className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                      />
                    </div>

                    <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                        <label
                            className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">Tax
                            ID</label>
                            <input type="text"
                        value={vendor.data.tax_id}
                        readOnly
                        className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                      />
                    </div>

                    <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                        <label
                            className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">Company
                            Type</label>
                            <input type="text"
                        value={vendor.data.company_type}
                        readOnly
                        className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                      />
                    </div>

                    <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                        <label
                            className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">Vendor
                            Type</label>
                            <input type="text"
                        value={vendor.data.vendor_type}
                        readOnly
                        className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                      />
                    </div>


                    <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                        <label
                            className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">Youtube
                            Link</label>
                            <input type="text"
                        value={vendor.data.youtube_link}
                        readOnly
                        className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                      />
                    </div>


                    <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                        <label
                            className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">Facebook
                            Link</label>
                            <input type="text"
                        value={vendor.data.facebook_link}
                        readOnly
                        className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                      />
                    </div>

                    <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                        <label
                            className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">TikTok
                            Link</label>
                            <input type="text"
                        value={vendor.data.tiktok_link}
                        readOnly
                        className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                      />
                    </div>

                    <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                        <label
                            className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">Instagram
                            Link</label>
                            <input type="text"
                        value={vendor.data.instagram_link}
                        readOnly
                        className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                      />
                    </div>


                    <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                        <label
                            className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">X
                            Link</label>
                            <input type="text"
                        value={vendor.data.x_link}
                        readOnly
                        className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                      />
                    </div>
                </div>

                <div className="flex justify-end w-full">
                    <button type="submit"
                            className="w-fit px-16 py-3 bg-red rounded-[10px] shadow border border-white justify-center items-center gap-2.5 inline-flex">
                        <div className="text-center text-white text-base font-normal leading-normal">Delete</div>
                    </button>
                </div>

            </form>
        </div>
    </div>
    </>
    )}
   
</section>
    )


}

export default DeleteAccount;