import React from 'react';
import Loader from "./Loader";

function LoaderOverlay({ loading }) {
  return (
      <>
        { loading &&
          <div style={{ backgroundColor: "rgba(255 255 255 / 80%)", backdropFilter: "blur(5px)", zIndex: 1100}} className={"fixed top-0 w-full h-full flex justify-center "}>
            <div className={"h-min my-auto scale-y-150 scale-x-150"}>
              <Loader />
            </div>
          </div>
        }
      </>
  );
}

export default LoaderOverlay;