import React, { useEffect, useState } from 'react';
import { useTable, useSortBy, usePagination } from 'react-table';
import acceptIcon from "../../../assets/images/icons/accept.svg";
import rejectIcon from "../../../assets/images/icons/reject.svg";
import ReactPaginate from 'react-paginate';
import useAxiosUserSecureConfig from '../../../hooks/useAxiosUserSecureConfig';
import axios from 'axios';
import { toast } from 'react-toastify';
import Loader from '../../../components/Loader';
import { useNavigate } from 'react-router';
import { faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Modal } from "@mui/material";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";


const data = [
    {
      bookingId: '12345',
      bookingDate: '2024-01-21',
      totalPrice: 150,
      clientName: 'John Doe',
      clientMobileNumber: '123-456-7890'
    },
    {
      bookingId: '67890',
      bookingDate: '2024-01-22',
      totalPrice: 200,
      clientName: 'Jane Smith',
      clientMobileNumber: '987-654-3210'
    },
    {
      bookingId: '54321',
      bookingDate: '2024-01-23',
      totalPrice: 180,
      clientName: 'Alice Johnson',
      clientMobileNumber: '555-123-4567'
    }
  ];

function BookingRequests () {
  const { t, i18n } = useTranslation();
  const [currentPage, setCurrentPage] = useState(0);
  const config = useAxiosUserSecureConfig();
  const [bookingRequests, setBookingRequests] = useState({ loading: true, data: [] });
  const columns = React.useMemo(
    () => [
      { Header: t('Booking ID'), accessor: 'bookingId' },
      { Header: t('Client Name'), accessor: 'clientName' },
      { Header: t('Client Mobile Number'), accessor: 'clientMobileNumber' },
      { Header: t('Payment Amount'), accessor: 'totalPrice' },
      { Header: t('Booking Date'), accessor: 'bookingDate' },
     
    ] ,[t]
  );
const fetchData = () => {
  axios.post('/api/bookings', {}, config)
    .then((res) => {
      console.log("bookings requests", res.data);
      if (!res.data?.result?.success) {
        toast.error(t("Error while fetching bookings requests!"));
        return;
      }
      setBookingRequests({
        loading: false,
        data: res.data?.result?.bookings?.slice().reverse() || [],
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
useEffect(() => {
  fetchData();
}, []);

const reqestsData = React.useMemo(() => {
  return bookingRequests.data.length > 0
    ? bookingRequests.data
    .filter((key) => key.state === 'new')
        .map((key) => ({
          id: key.id,
          bookingId: key.id,
          bookingDate: key.date,
          totalPrice:  key.services.reduce((total, service) => total + service.list_price, 0),
          clientName: key.client,
          clientMobileNumber: key.client_mobile_number ,
        }))
    : [];
}, [bookingRequests.data]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data: reqestsData,
      initialState: { pageIndex: currentPage, pageSize:  20 }, 
    },
    useSortBy,
    usePagination
  );

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleAccept = (e, row) => {
    e.stopPropagation();
    console.log('Accepted:', row);
    setBookingRequests((prevState) => ({
      ...prevState,
      loading: true,
    }));
    
    axios.post(`/api/booking/${row.bookingId}/accept`, {}, config)
      .then((res) => {
        if (!res.data.result?.success) {
          toast.error(res.data.result?.message || t("Request failed!"));
          return;
        }
        setBookingRequests((prevState) => ({
          ...prevState,
          loading: false,
        }));
        toast.success(t("The request has been accepted and the user will pay within 48 hours"));
        fetchData();
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(t("Error while accepting the booking!"));
        setBookingRequests((prevState) => ({
          ...prevState,
          loading: false,
        }));
      });
  };

  const [openRejectDialog, setOpenRejectDialog] = useState(false);
  const [rejectReason, setRejectReason] = useState('');
  const [loading, setLoading] = useState(false);
  const [rejectRow, setRejectRow] = useState(null);
  const handleOpenRejectDialog = (e, row) => {
    e.stopPropagation();
    setOpenRejectDialog(true);
    setRejectReason('');
    setRejectRow(row);
  };

  const handleCloseRejectDialog = () => {
    setOpenRejectDialog(false);
    setRejectReason('');
    setRejectRow(null);
  };

  const handleRejectConfirm = () => {
    setLoading(true);
    setBookingRequests((prevState) => ({
      ...prevState,
      loading: true,
    }));
    axios.post(`/api/booking/${rejectRow.id}/reject`, {
      reason: rejectReason
    }, config)
      .then((res) => {
        console.log(res.data)
        if (!res.data.result?.success) {
          toast.error(res.data.result?.message || t("Request failed!"));
          return;
        }
        setBookingRequests((prevState) => ({
          ...prevState,
          loading: false,
        }));
        setLoading(false);
        toast.success(t("You rejected the booking successfully!"));
        handleCloseRejectDialog();
        fetchData();
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(t("Error while rejecting the booking!"));
        setLoading(false);
        handleCloseRejectDialog();
        setBookingRequests((prevState) => ({
          ...prevState,
          loading: false,
        }));
      });
  };

  const navigate = useNavigate()

  const handleClick = (rowData) => {
    navigate(`/serviceProvider/booking-requests/${rowData.id}`);
  };

  return (
    <section className="px-3 xl:px-20 py-10 flex flex-col">
      
      <Modal
        open={openRejectDialog}
        onClose={handleCloseRejectDialog}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="absolute lg:w-2/5 w-11/12 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 px-8 py-6 bg-white rounded-2xl shadow flex-col justify-center items-start inline-flex border-4 border-red">
          <div className="w-full">
            <div className="flex justify-end">
              <button
                onClick={() => handleCloseRejectDialog()}
                className="self-stretch justify-between items-center flex"
              >
                <FontAwesomeIcon icon={faX} className="text-xl text-gray-500" />
              </button>
            </div>
            <div className="flex flex-col justify-start items-start gap-2 mb-5">
              <label className="text-zinc-800 text-base font-normal leading-snug">
                {t("Enter Rejection Reason")}
              </label>
              <input    
                className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-base font-normal leading-tight placeholder-[#808080]"
                placeholder={t('Rejection Reason')}
                value={rejectReason}
                onChange={(e) => setRejectReason(e.target.value)}
              />
            </div>
            <div className="w-full flex justify-center gap-5">
              <button
                onClick={() => handleCloseRejectDialog()}
                className="w-1/3 h-[47px] px-[18px] py-[6.40px] bg-white rounded-[10px] shadow border border-red justify-center items-center gap-2.5 inline-flex"
              >
                <span className="text-center text-red text-base font-normal font-['Roboto'] leading-normal">
                  {t("Cancel")}
                </span>
              </button>
              <button
                onClick={() => handleRejectConfirm()}
                className="w-1/3 h-[47px] px-[18px] py-[6.40px] bg-red rounded-[10px] shadow border border-red justify-center items-center gap-2.5 inline-flex"
              >
                <span className="text-center text-white text-base font-normal font-['Roboto'] leading-normal">
                  {t("Confirm")}
                </span>
              </button>
            </div>
          </div>
        </Box>
      </Modal>
      {bookingRequests.loading && <Loader slim />}
      {!bookingRequests.loading && <>
      <>
      <div className="justify-start items-center py- gap-2  hidden sm:inline-flex mb-2">
          <div
           onClick={() => navigate('/serviceProvider/account')}
           className="text-zinc-400 text-sm sm:text-base text-balance font-normal sm:leading-[48px] hover:text-gray-dark cursor-pointer"
         >
           {t("Service Provider Account")}
          </div>
          <FontAwesomeIcon
            className="text-zinc-400 text-sm sm:text-base"
            icon={faAngleRight}
          />
          <div
            onClick={() => navigate( '/serviceProvider/booking-requests')}
            className="text-zinc-400 text-sm sm:text-base font-normal sm:leading-[48px] hover:text-gray-dark cursor-pointer"
          >
           {t("Booking Requests")}
          </div>
      </div>

      <div className="justify-start items-center mt-2 gap-2 inline-flex mb-8">
        <div className="text-base sm:text-2xl font-semibold leading-9">{t("Booking Requests")}</div>
        <div className="text-base font-light leading-normal">
          ({reqestsData.length})
        </div>
      </div>
      <div className='!overflow-x-auto'>
      <table
        {...getTableProps()}
        className="w-full table-auto border-collapse mb-10"
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr
              className="h-12 bg-slate-100"
              {...headerGroup.getHeaderGroupProps()}
            >
              <th className="py-2 px-4 border text-gray-500 text-base font-medium leading-snug">
                {"#"}
              </th>{" "}
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={`py-2 px-4 border text-gray-500 text-base font-medium leading-snug ${
                    column.isSorted
                      ? column.isSortedDesc
                        ? "sort-desc"
                        : "sort-asc"
                      : ""
                  }`}
                >
                  {column.render("Header")}
                </th>
              ))}
              <th
                colSpan="2"
                className="py-2 px-4 border text-gray-500 text-base font-medium leading-snug"
              >
                {t("Actions")}
              </th>
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows
            .slice(currentPage *20, (currentPage + 1) * 20)
            .map((row, index) => {
              prepareRow(row);
              return (
                <tr
                onClick={() => handleClick(row.original)}
                  {...row.getRowProps()}
                  className="border-t h-12 cursor-pointer hover:bg-slate-100"
                >
                  <td className="px-4 border text-center bg-slate-100 text-gray-500 text-xs font-light leading-none">
                    {index + 1 + currentPage * 20}
                  </td>{" "}
                  {row.cells.map((cell, cellIndex) => (
                    <td
                      {...cell.getCellProps()}
                      className="px-4 border text-center text-[#697077] text-sm font-normal leading-tight"
                      key={cellIndex}
                    >
                      {cell.column.id === "totalPrice" // Replace 'yourProperty' with the actual property name
                        ? `${cell.value} EGP`
                        : cell.render("Cell")}
                    </td>
                  ))}
                  <td className="px-2 border text-center bg-[#F2FFF6]">
                    <button
                      className=""
                      onClick={(e) => handleAccept(e, row.original)}
                    >
                      <img src={acceptIcon} alt="acceptIcon" />
                    </button>
                  </td>
                  <td className="px-2 border text-center bg-[#FFF2F2]">
                    <button
                      className=""
                      onClick={(e) => handleOpenRejectDialog(e, row.original)}
                    >
                      <img src={rejectIcon} alt="rejectIcon" />
                    </button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      </div>
      <ReactPaginate
        breakLabel="..."
        nextLabel={t("Next >")}
        onPageChange={handlePageClick}
        pageRangeDisplayed={Math.ceil(rows.length / 20)}
        pageCount={Math.ceil(rows.length / 20)}
        previousLabel={t("< Previous")}
        renderOnZeroPageCount={null}
        containerClassName="pagination"
        pageLinkClassName="page-num"
        previousLinkClassName="page-btn"
        nextLinkClassName="page-btn"
        activeLinkClassName="active"
        disabledLinkClassName="disabled-btn"
      />
      </>
      </>}
    </section>
  );
};

export {BookingRequests as ProviderBookingRequests};