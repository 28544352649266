import React, { useEffect, useState } from "react";
import useAxiosUserSecureConfig from '../../../hooks/useAxiosUserSecureConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { useTable, useSortBy, usePagination } from 'react-table';
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router';
import { Controller, useForm } from "react-hook-form";
import filterIcon from "../../../assets/images/sevices/filter.png";
import { Link, useParams } from "react-router-dom";
import editIcon from "../../../assets/images/icons/edit.png";
import axios from "axios";
import Loader from "../../../components/Loader";
import {useTranslation} from "react-i18next";



function MyShipment() {
  const { t, i18n } = useTranslation();
  const [currentPage, setCurrentPage] = useState(0);
  const config = useAxiosUserSecureConfig();

  const [showDropdown, setShowDropdown] = useState(false);
  const [filter, setFilter] = useState(null);

  const [shipments, setShipments] = useState({
    loading: true,
    data: [],
  }); 
  const columns = React.useMemo(
    () => [
      { Header: t('ShipmentID'), accessor: 'shipmentId' },
      { Header: t('OrderId'), accessor: 'orderId' },
      { Header: t('Ordered By'), accessor: 'orderBy' },
      { Header: t('Phone'), accessor: 'phone' },
      { Header: t('Email'), accessor: 'email' },
      { Header: t('Address'), accessor: 'address' },
      { Header: t('Items'), accessor: 'items' },
      { Header: t('Total Price'), accessor: 'totalPrice' },
      { Header: t('Status'), accessor: 'status' },
     ],
    [t]
  );
  const fetchData = () => {
    axios
      .post("/api/vendor/shipment", {}, config)
      .then((res) => {
        if (!res.data?.result?.success) {
          toast.error("Error while fetching shipment!");
          return;
        }
        setShipments({
          loading: false,
          data: res.data?.result?.shipments?.slice().reverse() || [],
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    fetchData();
  }, []);
  console.log("shipment", shipments);
  const reqestsData = React.useMemo(() => {
    return shipments.data.length > 0
      ? shipments.data.map((key) => ({
          id: key.id,
          status: key.state,
          totalPrice: key.total_price,
          items: key.no_of_items,
          address: key.buyer_address,
          email: key.buyer_email,
          phone: key.buyer_phone,
          orderBy:key.buyer_name,
          orderId: key.order_id,
          shipmentId: key.id,
        }))
      : [];
  }, [shipments.data]);

  const handleFilterChange = (value) => {
    setFilter(value);
    setShowDropdown(false);
  };
  const filteredRows = React.useMemo(() => {
    return filter
      ? reqestsData?.filter((row) => row.status === filter)
      : reqestsData;
  }, [filter, reqestsData]);
  console.log("filteredRows", filteredRows);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: filteredRows,
        initialState: { pageIndex: currentPage, pageSize: 4 },
      },
      useSortBy,
      usePagination
    );

  const navigate = useNavigate();
  const { control } = useForm();

  const customStyles = {
    control: (styles) => ({
      ...styles,
      background: "#F2F4F8",
      "border-color": "#F2F4F8",
      "border-radius": "8px",
      padding: "6px",
    }),
  };

  const handleClick = () => {
    navigate("/ProductSupplier/confirm-shipment");
  };

  return (
    <section className="px-4 sm:px-10 py-12">
      <div className="sm:flex hidden justify-between items-center mb-5">
        <div className="flex items-center gap-6">
          <div className="justify-start items-center gap-2  hidden sm:inline-flex">
            <div
              onClick={() => navigate("/product-Supplier/Account")}
              className="text-zinc-400 text-sm sm:text-base font-normal sm:leading-[48px] hover:text-gray-dark cursor-pointer"
            >
              {t("Product Supplier Account")}
            </div>
            <FontAwesomeIcon
              className="text-zinc-400 text-base"
              icon={faAngleRight}
            />
            <div
              onClick={() => navigate("/ProductSupplier/shipment")}
              className="text-zinc-400 text-sm sm:text-base font-normal sm:leading-[48px] hover:text-gray-dark cursor-pointer"
            >
              {t("My Shipments")}
            </div>
          </div>
        </div>
      </div>
      {shipments.loading && <Loader />}
      {!shipments.loading && (
        <>
          <div className="flex justify-between items-center mb-8">
            <div className="justify-start items-center gap-2 inline-flex">
              <div className="text-base sm:text-2xl font-semibold leading-9">
              {t("My Shipments")}
              </div>
              <div className="text-base font-light leading-normal">
                ({shipments.data.length})
              </div>
            </div>
            <div className="relative">
              <div className="flex ">
                <button
                  className="px-2 sm:px-6 py-2 ml-2 rounded-lg border border-red justify-center items-center flex gap-2"
                  onClick={() => setShowDropdown(!showDropdown)}
                >
                  <img src={filterIcon} alt="filterIcon" />
                  <span className="text-red text-base font-medium font-['Inter'] leading-none tracking-wide">
                    {t("Filtered By")}
                  </span>
                  <FontAwesomeIcon
                    className="text-red text-base font-medium leading-none tracking-wide"
                    icon={faCaretDown}
                  />
                </button>
              </div>
              {showDropdown && (
                <ul className="absolute right-0 mt-2 py-2 w-48 bg-white border border-gray-200 rounded-lg shadow-lg z-10">
                  <li
                    className="cursor-pointer px-4 py-2 hover:bg-gray-100"
                    onClick={() => handleFilterChange(null)}
                  >
                    {t("Show All")}
                  </li>
                  <li
                    className="cursor-pointer px-4 py-2 hover:bg-gray-100"
                    onClick={() => handleFilterChange("new")}
                  >
                    {t("New")}
                  </li>

                  <li
                    className="cursor-pointer px-4 py-2 hover:bg-gray-100"
                    onClick={() => handleFilterChange("confirmed")}
                  >
                    {t("Confirmed")}
                  </li>
                  <li
                    className="cursor-pointer px-4 py-2 hover:bg-gray-100"
                    onClick={() => handleFilterChange("shipped")}
                  >
                    {t("Shipped")}
                  </li>
                </ul>
              )}
            </div>
          </div>

          <div className="!overflow-x-auto">
            <table
              {...getTableProps()}
              className="w-full table-auto border-collapse mb-10"
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr
                    className="h-12 bg-slate-100"
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    <th className="py-2 px-4 border text-gray-500 text-base font-medium leading-snug">
                      {"#"}
                    </th>{" "}
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        className={`py-2 px-4 border text-gray-500 text-base font-medium leading-snug ${
                          column.isSorted
                            ? column.isSortedDesc
                              ? "sort-desc"
                              : "sort-asc"
                            : ""
                        }`}
                      >
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows
                  .slice(currentPage * 20, (currentPage + 1) * 20)
                  .map((row, index) => {
                    prepareRow(row);
                    return (
                      <tr
                        onClick={() =>
                          navigate(
                            `/ProductSupplier/shipment/${row.original.id}`, { state: { data: shipments.data.find(item => item.id === row.original.id) } }
                          )
                        }
                        {...row.getRowProps()}
                        className="border-t h-12 cursor-pointer hover:bg-slate-100"
                      >
                        <td className="px-4 border text-center bg-slate-100 text-gray-500 text-xs font-light leading-none">
                          {index + 1 + currentPage * 20}
                        </td>{" "}
                        {row.cells.map((cell, cellIndex) => (
                          <td
                            {...cell.getCellProps()}
                            className="px-4 border text-center text-[#697077] text-sm font-normal leading-tight"
                            key={cellIndex}
                          >
                            {cell.column.Header === "Status" ? (
                              cell.value === "new" ? (
                                <div
                                  className={
                                    "py-2 px-4 text-gray-500 text-base font-medium leading-snug "
                                  }
                                >
                                  {t("New")}
                                </div>
                              ) : cell.value === "confirmed" ? (
                                <div
                                  className={
                                    "py-2 px-4 text-[#50E809] text-base font-medium leading-snug "
                                  }
                                >
                                  {t("Confirmed")}
                                </div>
                              ) : cell.value === "shipped" ? (
                                <div
                                className={
                                  "py-2 px-4 text-[#6DC6F9] text-base font-medium leading-snug "
                                }
                              >
                                {t("Shipped")}
                              </div>
                              ) :(
                                cell.render("Cell")
                              )
                            ) : (
                              cell.render("Cell")
                            )}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </>
      )}
    </section>
  );
}
export default MyShipment