import React, { useEffect, useState } from 'react'
import mastercard from "../../assets/images/shop/icons/mastercard.svg";
import visa from "../../assets/images/shop/icons/Visa.svg";
import coin from "../../assets/images/shop/icons/bitcoin.svg";
import interac from "../../assets/images/shop/icons/interac.svg";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Typography } from '@material-tailwind/react';
import { useTranslation } from 'react-i18next';
import useAxiosUserSecureConfig from '../../hooks/useAxiosUserSecureConfig';
import axios from 'axios';
import { toast } from 'react-toastify';
import Loader from '../../components/Loader';

function OnlinePayment() {

const { t, i18n } = useTranslation();
const [itemId, setItemId] = useState();
  
  const location = useLocation();

  useEffect(() => {
    const itemID = location.state?.data;
    console.log('Data from previous page:', itemID);
    setItemId(itemID);
    axios.post(`/api/venue-booking/${itemID}`, {} , config).then((res) => {
        console.log("bookingItem", res.data);
    
        if (!res.data?.result?.success) {
          toast.error("Error while fetching bookings!");
          return;
        }
        setBookingItem((prevState) => ({
          ...prevState,
          loading: false,
          data: res.data?.result?.reservation
        }));
      }).catch((err) => {
        console.log(err);
      })
  }, [location.state]);

  const [bookingItem, setBookingItem] = useState({ loading: true, data: [null] });
  const config = useAxiosUserSecureConfig();

  const fetchData = () => {
    
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <section className="px-5 xl:px-20 py-12">
      <p className="text-red text-2xl font-medium mb-6">
        Enter Card Information
      </p>
      {bookingItem.loading && <Loader slim/>}
      {!bookingItem.loading && <div className="flex justify-center w-full">
        <div className="w-2/5 p-6 rounded-2xl border  flex-col justify-start items-start gap-5 inline-flex">
         
          <div className="w-full flex flex-col gap-4">
            <div className="w-full justify-between items-center inline-flex">
              <div className="text-black text-xl font-normal">Total</div>
              <div className="text-black text-2xl font-medium ">
                {bookingItem.data.price} {"EGP"}
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <div className="flex items-center gap-4">
                <input type="radio" name="Credit" disabled />
                <label className="flex flex-col">
                  <span className="text-black text-opacity-60 text-base font-bold">
                    Credit and debit cards
                  </span>
                  <span className="text-black text-opacity-60 text-base font-normal">
                    We accept all major credit and debit cards:
                  </span>
                  <span></span>
                </label>
              </div>
            </div>
            <div className="px-3 sm:px-6 py-4 rounded-[15px] border flex-col justify-start items-start gap-4 inline-flex">
              <div className="w-full">
                <div className="mb-2 block">
                  <label className="text-stone-500 text-base font-normal leading-normal">
                    Card Number
                  </label>
                </div>
                <input className="w-full h-12 px-4 rounded-lg border border-stone-300 justify-start items-center gap-4 inline-flex" />
              </div>
              <div className="w-full">
                <div className="mb-2 block">
                  <label className="text-stone-500 text-base font-normal leading-normal">
                    Name on card
                  </label>
                </div>
                <input className="w-full h-12 px-4 rounded-lg border border-stone-300 justify-start items-center gap-4 inline-flex" />
              </div>
              <div className="w-full flex gap-2 items-end">
                <div className="w-1/2">
                  <div className="mb-2 block">
                    <label className="text-stone-500 text-base font-normal leading-normal">
                      Expiration date
                    </label>
                  </div>
                  <input className="w-full h-12 px-4 rounded-lg border border-stone-300 justify-start items-center gap-4 inline-flex" />
                </div>
                <div className="w-1/2">
                  <input
                    type="date"
                    className="w-full h-12 px-4 rounded-lg border border-stone-300 justify-start items-center gap-4 inline-flex"
                  />
                </div>
              </div>
              <div className="flex gap-6">
                <input type="checkbox" />
                <div className="text-black text-opacity-60 text-base font-normal ">
                  Use as my default payment
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <div className="flex gap-4 items-center">
                <input type="checkbox" />
                <div className="text-black text-opacity-60 text-base font-normal ">
                  I have read and agree to the website{" "}
                  <Link
                    to={
                      i18n.language === "en"
                        ? "/terms-and-conditions/en"
                        : "/terms-and-conditions/ar"
                    }
                    target="_blank"
                    className="font-medium transition-colors hover:text-gray-900"
                  >
                    &nbsp;{t("Terms and Conditions")}
                  </Link>{" "}
                  *
                </div>
              </div>
              {/* {errors.terms && (
                      <Typography variant="small" color="red">
                        {errors.terms?.message}
                      </Typography>
                    )} */}
            </div>
            <button
              type="submit"
              className="w-full h-[46px] px-6 py-[7px] bg-red rounded-[10px] shadow justify-center items-center gap-2.5 inline-flex"
            >
              <span className="text-center text-white text-base font-normal font-['Roboto'] leading-normal">
                Place Order
              </span>
            </button>
            <div>
              <p className="text-stone-500 text-base font-light font-['Roboto'] lowercase leading-normal mb-4">
                SECURE PAYMENTS PROVIDED BY
              </p>
              <ul className="w-full flex justify-between gap-1">
                <li>
                  <button className="h-12 px-5 rounded-[9px] border flex-col justify-center items-center gap-3 inline-flex">
                    <img src={mastercard} alt="paymenet" />
                  </button>
                </li>
                <li>
                  <button className="h-12 px-5 rounded-[9px] border flex-col justify-center items-center gap-3 inline-flex">
                    <img src={visa} alt="paymenet" />
                  </button>
                </li>
                <li>
                  <button className="h-12 px-5 rounded-[9px] border flex-col justify-center items-center gap-3 inline-flex">
                    <img src={coin} alt="paymenet" />
                  </button>
                </li>
                <li>
                  <button className="h-12 px-5 rounded-[9px] border flex-col justify-center items-center gap-3 inline-flex">
                    <img src={interac} alt="paymenet" />
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>}
    </section>
  );
}

export default OnlinePayment
