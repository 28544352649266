import React, { useEffect, useState } from "react";
import invitationImg from "../../../assets/images/invitation/invitation.gif";
import invitationHeader from "../../../assets/images/invitation/invitationHeader.png";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Layer1 from "../../../assets/images/About/Layer_1.png"
import vector3 from "../../../assets/images/About/Vector 2.png"
import vector2 from "../../../assets/images/About/Vector3.png" 
import heart from "../../../assets/images/invitation/mdi_heart.png"
import fram1 from "../../../assets/images/invitation/OBJECTS (5).png"
import fram2 from "../../../assets/images/invitation/OBJECTS (6).png"
import object1 from "../../../assets/images/invitation/object (1).png"
import object2 from "../../../assets/images/invitation/object (2).png"
import object3 from "../../../assets/images/invitation/object (3).png"
import object4 from "../../../assets/images/invitation/object (4).png"
import object5 from "../../../assets/images/invitation/object (5).png"
import object7 from "../../../assets/images/invitation/OBJECTS (7).png"
import object8 from "../../../assets/images/invitation/OBJECT.png"
import side1 from "../../../assets/images/invitation/Layer_1.png"
import side2 from "../../../assets/images/invitation/Layer_2.png"
import sideObj from "../../../assets/images/invitation/object (5).png"
import sideObj2 from "../../../assets/images/invitation/OBJECT (9).png"
import viewImg from "../../../assets/images/invitation/view.png"
import axios from "axios";
import useAxiosUserSecureConfig from "../../../hooks/useAxiosUserSecureConfig";
import Loader from "../../../components/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import groomPhoto from "../../../assets/images/invitation/groom.jpg";
import bridePhoto from "../../../assets/images/invitation/bride.jpg";
import placeholder from "../../../assets/images/invitation/placeholder-image.jpg";
import classic from "../../../assets/images/invitation/invitation1.jpeg";
import modern from "../../../assets/images/invitation/invitation2.jpeg";
import islamic from "../../../assets/images/invitation/invitation3.jpeg";
import old from "../../../assets/images/invitation/invitation4.jpeg";
import dark from "../../../assets/images/invitation/invitation5.jpeg";
import newStyle from "../../../assets/images/invitation/invitation6.jpeg";
import { useForm } from "react-hook-form";
import {SlideshowLightbox} from 'lightbox.js-react'
import 'lightbox.js-react/dist/index.css';
import icon1 from "../../../assets/images/invitation/object (2).png";
import icon2 from "../../../assets/images/invitation/object (3).png";
import icon3 from "../../../assets/images/invitation/object (4).png";

import {useTranslation} from "react-i18next";

const photo= 
  { src: placeholder}
;

function GuestInvitation() {
  const {t ,i18n } = useTranslation();
  const navigate = useNavigate();
  const config = useAxiosUserSecureConfig();
  const params = useParams();
  const [invitation, setInvitation] = useState({loading: true,data: {},});

  const location = useLocation();

  useEffect(() => {
    const data = location.state?.data;
    console.log('Data from previous page:', data);
    setInvitation((prevState) => ({
      ...prevState,
      loading: false,
      data: data
    }));
  }, [location.state]);

  // const fetchData = () => {
  //   axios
  //     .post(`/api/invitation/${params.id}`, {}, config)
  //     .then((res) => {
  //       console.log("invitation", res.data);
  //       if (!res.data?.result?.success) {
  //         toast.error("Error while fetching invitation!");
  //       }
  //       setInvitation({
  //         loading: false,
  //         data: res.data?.result.invitation || {},
  //       });
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  // useEffect(() => {
  //   fetchData();
  // }, []);
  const [selectedInvitation, setSelectedInvitation] = useState(null);
  const [invitaionLoading , setInvitaionLoading] = useState(false)
  useEffect(() => {
    if (invitation.data) {
        setInvitaionLoading(true);
        const headers = {
            ...config.headers,
            'Content-Type': 'text/plain',
        };
        const data = {
            groom_name: invitation.data.groom_name,
            bride_name: invitation.data.bride_name,
            wedding_address: invitation.data.wedding_address,
            wedding_date: invitation.data.wedding_date,
            wedding_time: invitation.data.wedding_time,
            style: invitation.data.style,
        };
        axios.post('/api/invitation/render', data, { ...config, headers, responseType: 'blob' })
            .then((res) => {
                if (!res.data) {
                    setInvitaionLoading(false);
                    toast.error(res.data.result?.message || t("Request failed!"));
                    return;
                }
                const reader = new FileReader();
                reader.onload = () => {
                    setSelectedInvitation(reader.result);
                };
                reader.onerror = (error) => {
                    console.error("Error reading image:", error);
                };
                reader.readAsDataURL(res.data);
                setInvitaionLoading(false);
            })
            .catch((err) => {
                setInvitaionLoading(false);
                console.log("Error fetching image:", err);
            });
    }
}, [invitation.data]);
  console.log("inv: ", invitation )
  
  const param = new URLSearchParams(location.search);
  const phoneNumber = param.get("phone");
  console.log("phone",phoneNumber)
  const [buttonsVisible, setButtonsVisible] = useState(true);
  useEffect(() => {
    // Check if the buttons were hidden previously for the specific ID and phone number
    const storedButtonsVisible = localStorage.getItem(`buttonsVisible_${params.id}_${phoneNumber}`);
    if (storedButtonsVisible) {
      setButtonsVisible(false);
    }
  }, [params.id, phoneNumber]);

  const acceptInvitation = (data) => {
    data = {
      invitation_id: invitation.data.id,
      phone_number: "+" + phoneNumber.trim()
   }
   console.log("data",data)
    axios
      .post("/api/invitation/accept-invitation", data, config)
      .then((res) => {
        if (!res.data.result?.success) {
          toast.error(res.data.result?.message || t("Request failed!"));
          return;
        }
        toast.success(t("You accept invitation successfully!"));
        setButtonsVisible(false);
        localStorage.setItem(`buttonsVisible_${params.id}_${phoneNumber}`, "false");
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(t("Error while accepting invitation!"));
      });
  };
  const cannotAttend = (data) => {
    data = {
      invitation_id: invitation.data.id,
      phone_number: "+" + phoneNumber.trim()
   }
   console.log("data",data)
    axios
      .post("/api/invitation/cannot-attend", data, config)
      .then((res) => {
        if (!res.data.result?.success) {
          toast.error(res.data.result?.message || t("Request failed!"));
          return;
        }
        toast.success(t("You reject invitation successfully!"));
        setButtonsVisible(false);
        localStorage.setItem(`buttonsVisible_${params.id}_${phoneNumber}`, "false");
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(t("Error while rejecting invitation!"));
      });
  };
  const imagesArray = Array.isArray(invitation.data.images) ? invitation.data.images : [];
  const combinedPhotos = imagesArray.map(item => ({ src: item.image, alt: `Image ${item.id}` }));
  const {
    register,
    handleSubmit,
    reset 
} = useForm();

const onSubmit = (data) => {
  console.log("Data:", data); 
  data = {
    invitation_id: invitation.data.id,
    phone_number: "+" + phoneNumber.trim(),
    comment: data.comment
  }
  axios
    .post("/api/invitation/guest-comment", data, config)
    .then((res) => {
      if (!res.data.result?.success) {
        toast.error(res.data.result?.message || t("Request failed!"));
        return;
      }
      toast.success(t("You add comment successfully!"));
      fetchData()
    })
    .catch((err) => {
      console.log("err", err);
      toast.error(t("Error while adding comment!"));
    });
}
const images = [icon1 , icon2 , icon3]
  return (
    <section>
      <div className="relative ">
        <img
          src={side1}
          className="hidden sm:block absolute left-0 top-[1100px] "
        />
        <img src={side2} className="hidden sm:block absolute right-0 top-[1300px]  w-1/6"/>
        <img src={sideObj} className="hidden sm:block absolute right-0 top-[1000px] "/>
        <img src={sideObj2} className="hidden sm:block absolute left-0 top-[1800px]  " />
        <img className="w-full h-20 sm:h-auto " src={invitationHeader} alt="invitationHeader" />
        <div className="absolute bottom-[10%] lg:p-10 px-2 w-full flex items-center justify-between">
          <p className="md:text-center  text-[#fff7eb] text-[15px] lg:text-[40px] font-medium">
            {t("Wedding Invitation")}
          </p>
          <img
            src={Layer1}
            className="absolute left-[35%] top-[82%] md:top-[73%] md:left-[40%] w-2/6 md:w-1/5  "
          />
          
        </div>
      </div>
      {invitation.loading && <Loader slim />}
      {!invitation.loading && (
        <div className="px-[60px] py-12 bg-zinc-100 flex justify-center">
          <div className="flex-col gap-14 inline-flex w-full">
            <div className="flex-col justify-start items-center gap-6 inline-flex w-full relative">
              {buttonsVisible && (
              <div className="xl:absolute top-0 end-0">
                <div className="gap-2 flex flex-col border border-red p-2 rounded-lg">
                <button
                  className="md:px-8 md:py-3 px-1 py-1 text-xs md:text-base bg-white rounded-lg justify-center items-center flex"
                  onClick={() => navigate("/")}
                >
                  <span className="text-red text-base font-medium leading-none tracking-wide">
                    {t("Cancel")}
                  </span>
                </button>
                <button
                  onClick={() => cannotAttend()}
                  className="md:px-8 md:py-3 px-1 py-1 text-xs md:text-base bg-white rounded-lg justify-center items-center flex"
                >
                  <span className="text-red text-base font-medium leading-none tracking-wide">
                    {t("Cannot Attend")}
                  </span>
                </button>
                <button
                  onClick={() => acceptInvitation()}
                  className="md:px-8 md:py-3 px-1 py-1 text-xs md:text-base bg-white rounded-lg justify-center items-center flex"
                >
                  <span className="text-red text-base font-medium leading-none tracking-wide">
                    {t("Accept Invitation")}
                  </span>
                </button>
              </div>
              </div>
              )}
            <div className={` flex justify-center ${i18n.language === 'en' ? 'flex-row' : 'flex-row-reverse'}  `}>
                <img src={vector2} className="w-14 md:w-24 object-contain" />
                <p className="text-red px-3 text-2xl lg:text-3xl whitespace-nowrap">
                  {t("Invitation Card")}
                </p>
                <img src={vector3} className="w-14 md:w-24 object-contain" />
              </div>
              {invitaionLoading && <Loader slim />}
              {!invitaionLoading && selectedInvitation && (
                <div
                  className={`w-full flex justify-center items-center`}
                >
                  <img
                    src={selectedInvitation}
                    alt="galleryIcon"
                    className="w-full md:w-5/12 mx-auto"
                  />
                </div>
              )}
              <img
                src={object8}
                className="w-[222px] mx-auto"
                alt="invitationImg"
              />
            </div>

            <div className="flex-col justify-start items-center gap-6 inline-flex w-full">
              <div className={`flex justify-center ${i18n.language === 'en' ? 'flex-row' : 'flex-row-reverse'}`}>
                <img src={vector2} className="w-14 md:w-24 object-contain" />
                <p className="text-red px-3 text-2xl lg:text-3xl">
                  {t("Our profile")}
                </p>
                <img src={vector3} className="w-14 md:w-24 object-contain" />
              </div>

              <div className="self-stretch justify-center items-start gap-5 inline-flex">
                <div className="grow shrink-0 basis-0 self-stretch px-4 py-6  flex flex-col lg:flex-row-reverse justify-start items-center gap-4 ">
                  <div>
                    <div className="relative w-[120px] h-[120px] sm:w-[148px] sm:h-[148px]">
                      <img
                        src={invitation.data.bride_photo || bridePhoto}
                        alt="bride_photo"
                        className="w-full h-full rounded-full object-cover"
                      />
                      <img
                        className="rounded-full absolute top-0 right-1 w-full h-full"
                        src={fram2}
                        alt="InvitationView"
                      />
                    </div>
                  </div>
                  <div>
                    <div className= {` ${i18n.language === 'en'? 'text-right':'text-left'} text-zinc-800 text-2xl font-semibold leading-relaxed`}>
                      {invitation.data.bride_name}
                    </div>
                    <div className={`${i18n.language === 'en'? 'text-right' :'text-left' } self-stretch text-wrap w-full text-gray-500 text-xl font-normal leading-7`}>
                      {invitation.data.about_bride}
                    </div>
                  </div>
                </div>
                <img src={heart} className="mt-20" />
                <div className="grow shrink basis-0 self-stretch md:px-4 py-6 flex flex-col lg:flex-row justify-start items-center gap-4 ">
                  <div>
                    <div className="relative w-[120px] h-[120px] sm:w-[148px] sm:h-[148px]">
                      <img
                        src={invitation.data.groom_photo || groomPhoto}
                        alt="groom_photo"
                        className="w-full h-full rounded-full object-cover"
                      />
                      <img
                        className="rounded-full absolute top-0 right-1 w-full h-full"
                        src={fram2}
                        alt="InvitationView"
                      />
                    </div>
                  </div>
                  <div>
                    <div className={` ${i18n.language === 'en'? 'text-left':'text-right'} text-zinc-800 text-2xl font-semibold leading-relaxed`}>
                      {invitation.data.groom_name}
                    </div>
                    <div className="self-stretch text-left text-gray-500 text-xl w-full font-normal leading-7">
                      {invitation.data.about_groom}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-col justify-start items-center gap-6 inline-flex w-full">
              {combinedPhotos.length > 0 && (
                <>
                  <div className="flex justify-center">
                    <img src={vector2} className="" />
                    <p className="text-red px-3 text-2xl lg:text-3xl">
                      {t("Our Album")}
                    </p>
                    <img src={vector3} className="" />
                  </div>
                  <div className="w-3/4 mx-auto">
                    {combinedPhotos.length !== 5 && (
                      <SlideshowLightbox
                        className={`slideshowLightbox container grid ${
                          combinedPhotos.length === 1
                            ? "grid-cols-1"
                            : combinedPhotos.length === 2
                            ? "grid-cols-2"
                            : combinedPhotos.length === 3
                            ? "grid-cols-3"
                            : combinedPhotos.length === 4
                            ? "grid-cols-2"
                            : ""
                        } gap-2 mx-auto album-gallery`}
                        images={combinedPhotos}
                        showThumbnails={true}
                        renderImage={(idx, image, toggleLightbox) => (
                          <div
                            className="grid-item"
                            onClick={() => toggleLightbox(idx)}
                          >
                            <img
                              className="rounded-xl object-cover w-full h-full"
                              src={image.src}
                              alt={image.alt}
                            />
                          </div>
                        )}
                      />
                    )}
                  </div>
                  <div className="invitaionGallery w-3/4 mx-auto">
                    {combinedPhotos.length === 5 && (
                      <SlideshowLightbox
                        images={combinedPhotos}
                        showThumbnails={true}
                        renderImage={(idx, image, toggleLightbox) => (
                          <div
                            className="grid-item"
                            onClick={() => toggleLightbox(idx)}
                          >
                            <img
                              className="rounded-xl object-cover w-full h-full"
                              src={image.src}
                              alt={image.alt}
                            />
                          </div>
                        )}
                      />
                    )}
                  </div>
                </>
              )}

              <div className="flex flex-col justify-center gap-5">
             
              <div className={`flex justify-center ${i18n.language === 'en' ? 'flex-row' : 'flex-row-reverse'}`}>
                  <img src={vector2} className="w-14 md:w-24 object-contain" />
                  <p className="text-red px-3 text-2xl lg:text-3xl">{t("Our story")}</p>
                  <img src={vector3} className="w-14 md:w-24 object-contain" />
                </div>
              </div>
              <div className="flex flex-col justify-center items-center w-[95%] mx-auto">
                {invitation.data.story.map((story, index) => (
                  <div key={index} className="flex items-start">
                    {index % 2 === 0 ? (
                      <div className="items-end relative border-e-[3px] border-[#f1cfcf] flex flex-col px-8 w-1/2 h-full pt-6 pb-20 gap-2">
                        <div className="absolute top-0 -end-[9.5px] w-[17px] h-[17px] bg-[#f1cfcf] rounded-full"></div>
                        <h4 className="text-red font-[Rochester]">
                          {story.date}
                        </h4>
                        <p>{story.about_story}</p>
                      </div>
                    ) : (
                      <div className="relative border-e-[3px] border-[#f1cfcf] flex justify-end px-8 w-1/2 h-full">
                        <div className="absolute top-0 -end-[9.5px] w-[17px] h-[17px] bg-[#f1cfcf] rounded-full"></div>
                        <div className="relative">
                          <img
                            src={images[index % images.length]}
                            className="w-[150px] cursor-pointer object-cover rounded-3xl"
                            alt="YourImage"
                          />
                        </div>
                      </div>
                    )}
                    {index % 2 === 0 ? (
                      <div className="flex justify-start px-8 w-1/2">
                        <div className="relative">
                          <img
                            src={images[index % images.length]} // Access images cyclically
                            className="w-[150px] cursor-pointer object-cover rounded-3xl"
                            alt="YourImage"
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="flex flex-col px-8 w-1/2 h-full pt-6 pb-10 gap-2">
                        <h4 className="text-red font-[Rochester]">
                          {story.date}
                        </h4>
                        <p>{story.about_story}</p>
                      </div>
                    )}
                  </div>
                ))}
              </div>

              <div className="flex flex-col justify-center items-start gap-20 ">
                <img src={invitationImg} alt="invitationImg" />
                <img
                  src={object8}
                  className="w-[222px] mx-auto"
                  alt="invitationImg"
                />
              </div>

              <div className={`flex justify-center ${i18n.language === 'en' ? 'flex-row' : 'flex-row-reverse'}`}>
                <img src={vector2} className="w-14 md:w-24 object-contain" />
                <p className="text-red px-3 text-2xl lg:text-3xl">
                  {t("Leave Comment")}
                </p>
                <img src={vector3} className="w-14 md:w-24 object-contain" />
              </div>
              <div className="flex flex-col gap-5 w-full">
                {invitation.data.guests
                  .filter((guest) => guest.comment !== false)
                  .map((guest, index) => (
                    <div
                      key={index}
                      className="flex flex-col border rounded-xl bg-white justify-start"
                    >
                      <div className="border-b py-4">
                        <h1 className="text-gray-500 px-5">{guest.name}</h1>
                        <p className="text-gray-dark text-lg px-5">
                          {guest.comment}
                        </p>
                        {guest.user_response && <p className="mt-5 pt-2 px-5 border-t w-full text-gray-400">
                            {guest.user_response}
                          </p>}
                      </div>
                    </div>
                  ))}
              </div>
              {!invitation.data.guests.some(
                (guest) =>
                  guest.phone === `+${phoneNumber.trim()}` &&
                  guest.comment !== false
              ) && (
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="w-full flex flex-col border rounded-xl bg-white justify-start"
                >
                  <h1 className="text-gray-500 mt-2 px-5">{t("Your Comment")}</h1>
                  <input
                    {...register("comment")}
                    className="py-2 self-stretch px-5 mt-3 mx-5 bg-gray-200 border border-gray-200 rounded-xl text-gray-500"
                    type="text"
                    placeholder={t("Text here")}
                  />
                  <button
                    type="submit"
                    className={`text-white bg-red border border-red w-24 my-6 mx-auto rounded-xl px-4 py-2 `}
                  >
                    {t("Comment")}
                  </button>
                </form>
              )}
            </div>
          </div>
        </div>
      )}
    </section>
  );
}

export default GuestInvitation;