import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import orderHeader from "../../assets/images/shop/order.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from "axios";
import {cartActions} from "../../store/slicer/cartSlice";
import {toast} from "react-toastify";
import useAxiosUserSecureConfig from "../../hooks/useAxiosUserSecureConfig";
import {faBagShopping, faCreditCard, faTrashCan, faWallet ,faX ,faCheck} from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate} from "react-router-dom";
import placholder_image from "../../assets/images/booking/placeholder-image.jpg"
import {useTranslation} from "react-i18next";

function Order() {
    const {t ,i18n } = useTranslation();
    const [order, setOrder] = useState({ loading: true, data: [null] });

    const location = useLocation();

    const navigate = useNavigate();

    useEffect(() => {
      const data = location.state?.data;
      console.log("Data from previous page:", data);
      setOrder((prevState) => ({
        ...prevState,
        loading: false,
        data: data,
      }));
    }, [location.state]);

    const customTheme = {
        color: {
          red: 'bg-red',
        },
      };

    const cart = useSelector((state) => state.cart);
    const dispatch = useDispatch();
    const config = useAxiosUserSecureConfig();

    const handleAddToCart = (product) => {
        dispatch(cartActions.setLoading(true));
        axios.post("/api/add_to_cart", {"product_id": product.id,}, config)
            .then(() => {
                axios.post("/api/cart", {}, config)
                    .then((res) => {
                        dispatch(cartActions.setCart(res.data.result));
                        dispatch(cartActions.setLoading(false));
                        // dispatch(uiActions.stopLoading());
                    })

                toast.success(t("Cart Updated!"));
            })
            .catch((err) => {
                console.log(err)
                // dispatch(uiActions.stopLoading());
                dispatch(cartActions.setLoading(false));
                toast.error(t("Error While Updating Cart!"));
            })

    };
    const handleDecreaseCart = (product, currentQty) => {
        dispatch(cartActions.setLoading(true));
        axios.post("/api/set_cart_item_qty", {"product_id": product.id, "qty": currentQty - 1}, config)
            .then(() => {
                axios.post("/api/cart", {}, config)
                    .then((res) => {
                        dispatch(cartActions.setCart(res.data.result));
                        dispatch(cartActions.setLoading(false));
                        // dispatch(uiActions.stopLoading());
                    })

                toast.success(t("Cart Updated!"));
            })
            .catch((err) => {
                console.log(err)
                // dispatch(uiActions.stopLoading());
                dispatch(cartActions.setLoading(false));
                toast.error(t("Error While Updating Cart!"));
            })
    };
    const handleRemoveFromCart = (product) => {
        dispatch(cartActions.setLoading(true));
        axios.post("/api/remove_to_cart", {"product_id": product.id}, config)
            .then(() => {
                axios.post("/api/cart", {}, config)
                    .then((res) => {
                        dispatch(cartActions.setCart(res.data.result));
                        dispatch(cartActions.setLoading(false));
                        // dispatch(uiActions.stopLoading());
                    })

                toast.success(t("Cart Updated!"));
            })
            .catch((err) => {
                console.log(err)
                // dispatch(uiActions.stopLoading());
                dispatch(cartActions.setLoading(false));
                toast.error(t("Error While Updating Cart!"));
            })
    };

    const downloadInvoice = () => {  
        const headers = {
          ...config.headers,
          'Content-Type': 'application/pdf'
        };
      
        axios.post(`api/store/order/${order.data.id}/print`, {}, { ...config, headers, responseType: 'blob' })
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'invoice.pdf');
            document.body.appendChild(link);
            link.click();
            link.remove();
            })
          .catch((err) => {
            console.error("Error:", err);
            toast.error(t("Failed to download the invoice!"));
          });
      };
    
    

  return (
    <>
    <section className="relative">
        <img className="w-full" src={orderHeader} alt='home'/>
        <div className="flex flex-col sm:gap-4 absolute bottom-[20%] px-5 xl:px-10">
                    <p className="text-[#D9D9D9] sm:text-xl font-normal sm:leading-[48px]">{t("Shop > Shopping Cart > Shipping")}</p>
                    <h1 className="text-white text-2xl sm:text-[40px] font-medium font-['Roboto'] sm:leading-[44px]">{t("Shipping")}</h1>
        </div>
    </section>
    <header className="py-7 px-5 bg-orange-50 justify-center items-center gap-2 sm:gap-4 inline-flex">
        <button onClick={() => navigate("/cart")} className="flex items-center gap-2">
            <div className="bg-red rounded-[100px] justify-center items-center gap-2 inline-flex"><FontAwesomeIcon className="text-sm text-white p-2" icon={faBagShopping} /></div>
            <p className="text-gray-dark text-sm font-medium leading-[21px]">{t("Shopping Cart")}</p>
        </button>
        <hr className="w-[85.50px] h-px bg-[#C3D2CC]"/>
        <button onClick={() => navigate("/shipping")} className="flex items-center gap-2">
            <div className="bg-red rounded-[100px] justify-center items-center gap-2 inline-flex"><FontAwesomeIcon className="text-sm text-white p-2" icon={faCreditCard} /></div>
            <p className="text-zinc text-sm font-medium leading-[21px]">{t("Checkout")}</p>
        </button>
        <hr className="w-[85.50px] h-px bg-[#C3D2CC]"/>
        <button onClick={() => navigate("/order-complete")} className="flex items-center gap-2">
            <div className="bg-red rounded-[100px] justify-center items-center gap-2 inline-flex"><FontAwesomeIcon className="text-sm text-white p-2" icon={faWallet} /></div>
            <p className="text-zinc text-sm font-medium leading-[21px]">{t("Order Complete")}</p>
        </button>
    </header>
    <section className="px-4 xl:px-20 py-10">
        <div className="flex justify-center mb-12">
            <div className="px-[46px] py-4 rounded-[15px] border flex-col justify-start items-center gap-2 inline-flex">
                <p className="text-[#2E7328] text-2xl font-light font-['Roboto']">{t("Thank you. Your order has been received.")}</p>
                <ul className="list-disc text-gray-dark text-base font-light">
                    <li>{t("Order number")}: {order.data.id}</li>
                    <li>{t("Date")}: { new Date(order.data.time_placed).toLocaleDateString()} </li>
                    <li>{t("Total")}: {order.data.total_price}  {cart.currency}</li>
                    <li>{t("Payment method: Cash on delivery")}</li>
                </ul>
                <button onClick={downloadInvoice}  className=" px-4 py-2 bg-red rounded-[10px] shadow justify-center items-center gap-2.5 inline-flex">
                    <span className="text-center text-white text-base font-normal leading-normal">{t("Download Invoice")}</span>
                </button>
            </div>
        </div>
        <div className="lg:flex gap-12 items-start">
            <div className="cart lg:w-2/3 mb-5 lg:mb-0">
                <div className="w-full pb-4 justify-between items-center inline-flex">
                    <div className="text-red text-2xl font-medium leading-9">{t("Your Cart")}</div>
                    <p className="text-red text-2xl font-normal leading-9 flex items-center gap-1"><FontAwesomeIcon className="bg-red p-1 rounded-full text-[10px] text-white" icon={faCheck} />{t("Paid")}</p>
                </div>
                <div className="rounded-[20px] border ">
                {!order.loading && order.data.items.map((item) => (
                    <div className="flex flex-row justify-between hover:bg-gray-100  px-2 sm:px-6 py-5">
                       <div className="flex  gap-4">
                            <div className="w-28 h-28 flex justify-center items-center">
                                <img 
                                  className="w-28 h-full object-cover rounded-[20px]"
                                    src={item.product.image || placholder_image}
                                    alt={item.name}
                                />
                            </div>
                            <div className="flex justify-between">
                            <div className="flex flex-col gap-1 md:gap-10">
                                <div className="flex flex-col">
                                    <p className="text-black sm:text-xl   font-medium mb-[6px]">{item.product.name}</p>
                                    {/* <div  className="flex justify-center items-center gap-1 sm:gap-3 "><FontAwesomeIcon icon={faX} /> {item.qty}
                                    </div> */}
                               </div>
                                 <div  className="flex  justify-start px-6 items-center gap-1 sm:gap-3 "><FontAwesomeIcon icon={faX} /> {item.qty}
                                    </div>
                               </div>
                              </div>     
                       </div>
                       <div  className=" hidden justify-start px-6 items-center gap-1 sm:gap-3 "><FontAwesomeIcon icon={faX} /> {item.qty}
                                    </div>
                    <div className="flex flex-col justify-between items-end">     
                  <div></div>
                      <div className={`text-black sm:text-xl flex ${i18n.language === 'en' ? 'flex-row' : 'flex-row-reverse'} ${i18n.language === 'en' ? ' ml-[-100px] ' : ' mr-[-100px] '}  font-medium whitespace-nowrap `}>
                        <div className=" ">{item.value}</div>
                        <div className=" ">  {cart.currency}</div>
                        </div>
                         </div>
                        
             </div>
        ))}
        </div>
            </div>
            <div className="checkout lg:w-1/3">
                <div className="text-red text-2xl font-medium leading-9 mb-4">{t("Order Summary")}</div>
                <div className="w-full p-6 rounded-2xl border  flex-col justify-start items-start gap-5 inline-flex">
                <div className="w-full flex flex-col gap-4 border-b pb-5">
                    <div className="w-full justify-between items-center inline-flex">
                        <div className="text-zinc text-sm font-normal leading-[21px]">{t("Subtotal")}</div>
                        <div className={` flex  ${i18n.language === 'en' ? 'flex-row' : 'flex-row-reverse'} `}>
                        <div className="text-gray-dark text-sm font-normal leading-[21px]">{order.data.total_price}</div>
                        <div className="text-gray-dark text-sm font-normal leading-[21px]">  {cart.currency}</div>
                    </div>
                    </div>
                    {/* <div className="w-full justify-between items-center inline-flex">
                        <div className="text-zinc text-sm font-normal leading-[21px]">Delivery Fee</div>
                        <div className="text-gray-dark text-sm font-normal leading-[21px]">{cart.shipping} {cart.currency}</div>
                    </div>
                    <div className="w-full justify-between items-center inline-flex">
                      <div className="text-zinc text-sm font-normal leading-[21px]">Taxes Fee</div>
                      <div className="text-gray-dark text-sm font-normal leading-[21px]">{cart.taxes} {cart.currency}</div>
                    </div> */}
                </div>
                <div className="w-full flex flex-col gap-4">
                <div className="w-full justify-between items-center inline-flex">
                        <div className="text-black text-2xl font-medium">{t("Total")}</div>
                        <div className={` flex  ${i18n.language === 'en' ? 'flex-row' : 'flex-row-reverse'} `}>
                        <div className="text-black text-2xl font-medium leading-[21px]">{order.data.total_price}</div>
                        <div className="text-black text-2xl font-medium leading-[21px]">  {cart.currency}</div>
                    </div>
                </div>
                
                </div>
               
                </div>
                <div className="w-full flex flex-col items-center">
                <div className="text-stone-500 text-base font-normal leading-normal w-full text-center my-4">{t("New Order, Click button bellow")}</div>
                <button onClick={()=>navigate("/shop")} className=" px-6 py-3 bg-red rounded-[10px] shadow justify-center items-center gap-2.5 inline-flex">
                    <span className="text-center text-white text-base font-normal leading-normal">{t("Continue Shopping")}</span>
                </button>
                </div>
            </div>
        </div>
    </section>
    </>
  );
};
export default Order;