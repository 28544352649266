import React, { useEffect, useState } from "react";
import headerImg from "../../assets/images/wishlist/header.png";
import { AiFillStar } from "react-icons/ai";
import { FaHeart } from "react-icons/fa";
import { FiShoppingCart } from "react-icons/fi";
import img from "../../assets/images/sevices/download.png";
import venueImg from "../../assets/images/venus/venue.jpg";
import axios from "axios";
import useAxiosUserSecureConfig from "../../hooks/useAxiosUserSecureConfig";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation } from 'swiper/modules';
import { Autoplay, Pagination } from "swiper/modules";
import placholder_image from "../../assets/images/booking/placeholder-image.jpg"
import Loader from "../../components/Loader";
import { useNavigate } from "react-router-dom";
import ProductCard from "./ProductCard";
import { toast } from "react-toastify";
import Info from "../Info";
import { useTranslation } from "react-i18next";
import { wishListActions } from "../../store/slicer/wishListSlice";
import { useDispatch } from "react-redux";

function WishList() {
  const [activeTap, setActiveTap] = useState(1);
  const { t, i18n } = useTranslation();
  const [showFullDescription , setShowFullDescripton] = useState(false);
  const [fullDescription , setFullDescription] = useState();

  const toggleDescription = (e , fullDescription) => {
    e.stopPropagation()
    setShowFullDescripton(!showFullDescription);
    setFullDescription(fullDescription)
  }

  const config = useAxiosUserSecureConfig();
  const navigate = useNavigate();

  const [venuesWishList, setVenuesWishList] = useState({loading: true, data : []});
  const fetchWishList = () => {
    axios
      .post(`/api/wishlist/venue`, {} , config)
      .then((res) => {
        // console.log("venuesWishList", res.data);
        setVenuesWishList({
          loading: false,
          data: res.data?.result.wishlist || {}, // Set to an empty object if undefined
        });
        dispatch(wishListActions.setVenuesWishList(res.data.result));
        dispatch(wishListActions.setLoading(false));
      })
      .catch((err) => {
        console.log(err);
      });
  }
  useEffect(() => {
    fetchWishList()
  }, []);
  console.log("venuesWishList" , venuesWishList)
  const isVenueInWishList = (venueId) => {
    return venuesWishList.data.some((item) => item.id === venueId);
  };
  const dispatch = useDispatch();
  const [productsWishList, setProductsWishList] = useState();
  const [productsWishListImages, setProductsWishListImages] = useState([]);

  const fetchProductsWishList = () => {
    axios
      .post(`/api/wishlist/product`, {}, config)
      .then(async (res) => {
        setProductsWishList(res.data?.result.products);
        dispatch(wishListActions.setProductsWishList(res.data.result));
        dispatch(wishListActions.setLoading(false));
        const variantsWithImages = await Promise.all(
          res.data?.result.products.map(async (variantId) => {
            try {
              const imageRes = await axios.post(
                `/api/product/variant/${variantId}/image2`,
                {},
                config
              );
              if (!imageRes.data.result?.success) {
                throw new Error(
                  imageRes.data.result?.message || "Request failed!"
                );
              }
              return { id: variantId, image: imageRes.data.result.image };
            } catch (error) {
              console.error("Error fetching image:", error);
              return { id: variantId, image: null };
            }
          })
        );
        setProductsWishList(variantsWithImages.map((variant) => variant.id));
        setProductsWishListImages(variantsWithImages);
      })
      .catch((err) => {
        console.log(err);
      });

  };
    useEffect(() => {
      fetchProductsWishList();
    }, []);

    console.log("productsWishList", productsWishList);
    console.log("productsWishListImages", productsWishListImages);
    const isProductInWishList = (productID) => {
      if (Array.isArray(productsInWishlist)) {
        return productsInWishlist.some((item) => item.id === productID);
      } else {
        return false;
      }
    };
    const [products, setProducts] = useState({ loading: true, data: [] });

    useEffect(() => {
      setProducts((prevState) => ({
        ...prevState,
        loading: true,
        data: [],
      }));
      axios
        .post("/api/products", {pageSize: 100}, config)
        .then((res) => {

          if (!res.data?.result?.success) {
            toast.error("Error while fetching products!");
            return;
          }
          setProducts((prevState) => ({
            ...prevState,
            loading: false,
            data: res.data?.result?.products ?? [],
          }));
        })
        .catch((err) => {
          console.log(err);
        });
    }, []);

    console.log("products", products.data);
    const productsInWishlist = products.data.filter(product => productsWishList?.includes(product.id));
    const updatedProductsInWishlist = productsInWishlist.map(product => {
      const productImage = productsWishListImages.find(item => item.id === product.id)?.image;
      return { ...product, image: productImage };
    });
    console.log("productsInWishlist", productsInWishlist);

    const deleteProductFromWishList = (e, productId) => {
      e.stopPropagation();
      axios
        .post(`/api/wishlist/product/${productId}/remove`, {}, config)
        .then((res) => {
          console.log(res.data);
          if (!res.data.result?.success) {
            toast.error(res.data.result?.message || t("Request failed!"));
            fetchProductsWishList()
            return;
          }
          toast.success(t("You remove product from wishlist successfully!"));
          fetchProductsWishList()
        })
        .catch((err) => {
          console.log("err", err);
          toast.error("Error while removing product from wishlist!");
          fetchProductsWishList()
        });
    };

    const deleteVenueFromWishList = (e , venueId) => {
      e.stopPropagation();
      axios.post(`/api/wishlist/venue/${venueId}/remove`, {}, config)
          .then((res) => {
            console.log(res.data)
            if (!res.data.result?.success) {
              toast.error(res.data.result?.message || "Request failed!");
              fetchWishList()
              return;
            }
            toast.success(t("You remove venue from wishlist successfully!"));
            fetchWishList()
          })
          .catch((err) => {
            console.log("err" , err)
            toast.error(t("Error while  removing venue to wishlist!"));
            fetchWishList()
          })
    };
    const [slidesPerView, setSlidesPerView] = useState(4);

    useEffect(() => {
      const updateSlidesPerView = () => {
        if (window.innerWidth < 640) {
          setSlidesPerView(1);
        } else if (window.innerWidth < 1024) {
          setSlidesPerView(2);
         }
        else if (window.innerWidth < 1280) {
          setSlidesPerView(3);
        } else {
          setSlidesPerView(4);
        }
      };
  
      updateSlidesPerView();
  
      window.addEventListener('resize', updateSlidesPerView);
  
      return () => {
        window.removeEventListener('resize', updateSlidesPerView);
      };
    }, []);
  return (
    <section>
      <div className="relative">
        <img className="w-full" src={headerImg} alt="home" />
        <div className="flex flex-col sm:gap-4 absolute bottom-[20%] px-5 xl:px-10">
          <h1 className="text-white text-2xl sm:text-[40px] font-medium font-['Roboto'] sm:leading-[44px]">
            {t("WishList")}
          </h1>
        </div>
      </div>
      {showFullDescription && (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-10 bg-gray-800 bg-opacity-50">
          <div className="bg-white p-8 rounded-lg w-1/2">
            <p>{fullDescription}</p>
            <div className="flex justify-center">
              <button
                className="h-[42px] px-[18px] py-2.5 rounded-[10px] shadow border border-red justify-center items-center gap-2.5 inline-flex mt-4"
                onClick={toggleDescription}
              >
                <span className="text-center text-red text-base font-normal  leading-tight">
                  {" "}
                  {t("Close")}
                </span>
              </button>
            </div>
          </div>
        </div>
      )}
      {venuesWishList.loading && <Loader slim />}
      {!venuesWishList.loading && (
        <div className="px-5 xl:px-10 py-12">
          <div>
            <div className="tabs flex gap-12 pb-4 mb-10 border-b">
              <button
                className={`${
                  activeTap === 1 && "!text-red !font-medium"
                } text-zinc-500 text-xl font-normal  leading-normal `}
                key={1}
                disabled={activeTap === 1}
                onClick={() => setActiveTap(1)}
              >
                {t("Venues")}
              </button>
              <button
                className={`${
                  activeTap === 2 && "!text-red !font-medium"
                } text-zinc-500 text-xl font-normal  leading-normal `}
                key={1}
                disabled={activeTap === 2}
                onClick={() => setActiveTap(2)}
              >
                {t("Store Products")}
              </button>
            </div>
            {activeTap === 1 && (
              <>
                {venuesWishList.data.length < 1 && (
                  <Info
                    msg={t("No venues have been added to the wishlist.")}
                    description={t("Please refresh to show results.")}
                  />
                )}
                <Swiper
                  spaceBetween={40}
                  slidesPerView={slidesPerView}
                  navigation={true}
                  modules={[Navigation]}
                >
                  <div className=" grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-10">
                    {venuesWishList.data.map((venue) => (
                      <SwiperSlide>
                        <div
                          onClick={() =>
                            navigate(`/facility/${venue.facility.id}`)
                          }
                          id={venue.id}
                          key={venue.id}
                          className={`rounded-[10px] shadow-md border-2 border-[#fe69724d]
                       p-4 cursor-pointer hover:border-red`}
                        >
                          <div className="mb-4">
                            <button
                              className="absolute top-6 left-6"
                              onClick={(e) => {
                                deleteVenueFromWishList(e, venue.id);
                              }}
                            >
                              <FaHeart
                                size={24}
                                className={`${
                                  isVenueInWishList(venue.id)
                                    ? "text-red"
                                    : "text-white"
                                }`}
                              />
                            </button>
                            <img
                              className="mx-auto object-cover h-60 rounded-lg w-full"
                              src={
                                venue.image === ""
                                  ? placholder_image
                                  : venue.image
                              }
                              alt=""
                            />
                          </div>
                          <div className="border-b pb-4">
                            <div className="flex justify-between items-center w-full">
                              <span className="text-gray-500 text-xl font-bold leading-[29px]">
                                {venue.name}
                              </span>
                              <span className="whitespace-nowrap text-zinc-500 text-base font-semibold leading-tight tracking-wide">
                                {venue.size.name} {t("Guests")}
                              </span>
                            </div>
                          </div>
                          <div className="pt-4 flex justify-between items-center">
                            {venue.indoorOutdoor === "indoor" && (
                              <div className="px-3 py-0.5 bg-[#CCCCCC] rounded-[21.18px] justify-start items-start gap-[10px] inline-flex">
                                <div className="text-[#4C4C4C] text-base font-normal leading-relaxed">
                                  {t("Indoor")}
                                </div>
                              </div>
                            )}
                            {venue.indoorOutdoor === "outdoor" && (
                              <div className="px-3 py-0.5 bg-red rounded-[21.18px] justify-start items-start gap-[10px] inline-flex">
                                <div className="text-white text-base font-normal leading-relaxed">
                                  {t("Outdoor")}
                                </div>
                              </div>
                            )}

                            <div className="justify-start items-center gap-[6px] flex">
                              <div className="justify-start items-center gap-1 flex border-e-2 pe-[6px]">
                                <div>
                                  <AiFillStar className="text-[12px] text-[#FFC200]" />
                                </div>
                                <p className="text-zinc-950 text-sm font-normal leading-[21px]">
                                  {Math.round(venue.rating * 10) / 10}
                                </p>
                              </div>
                              <div className="justify-start items-center gap-1 flex ">
                                <div className="text-zinc-950 text-sm font-normal leading-[21px]">
                                  {venue.reviewsCount}
                                </div>
                                <div className="text-gray-500 text-sm font-normal leading-[21px]">
                                  {t("Reviews")}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                  </div>
                </Swiper>
              </>
            )}
            {activeTap === 2 && (
              <>
                {updatedProductsInWishlist.length < 1 && (
                  <Info
                    msg={t("No products have been added to the wishlist.")}
                    description={t("Please refresh to show results.")}
                  />
                )}
                <Swiper
                  spaceBetween={40}
                  slidesPerView={slidesPerView}
                  navigation={true}
                  modules={[Navigation]}
                >
                  <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-5 gap-x-6 gap-y-12">
                    {updatedProductsInWishlist.map((product) => (
                      <SwiperSlide>
                        <ProductCard
                          key={product.id}
                          view={"grid"}
                          product={product}
                          navigate={navigate}
                          deleteFromWishList={deleteProductFromWishList}
                          isProductInWishList={isProductInWishList}
                        />
                      </SwiperSlide>
                    ))}
                  </div>
                </Swiper>
              </>
            )}
          </div>
        </div>
      )}
    </section>
  );
}

export default WishList;
