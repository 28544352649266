import React, { useEffect, useState } from "react";
import Select from "react-select";
import {Typography, Option} from "@material-tailwind/react";
import {useTranslation} from "react-i18next";
import {Controller, useForm} from "react-hook-form";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router';
import { Checkbox } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { PhoneInput } from "react-international-phone";
import { useTable, useSortBy, usePagination } from 'react-table';
import axios from "axios";
import useAxiosUserSecureConfig from "../../../hooks/useAxiosUserSecureConfig";
import Loader from "../../../components/Loader";
import { toast } from "react-toastify";
import {useParams ,useLocation } from "react-router-dom";




function ShipmentStatus () {
  const { t, i18n } = useTranslation();
  const [currentPage, setCurrentPage] = useState(0);
  const [shipmenet, setShipment] = useState({ loading: true, data: [null] }); 
  const [filter, setFilter] = useState(null);
  const [items, setItems] = useState({ loading: true, data: [null] }); 
  const location = useLocation();
  const config = useAxiosUserSecureConfig();
  const params = useParams();
  const columns = React.useMemo(
    () => [ 
      { Header: t("Image"), accessor: 'image' },
      { Header: t("Product Name"), accessor: 'items' },
      { Header: t("Qty"), accessor: 'qty' },
      { Header: t("Unit Price"), accessor: 'price' },
      { Header: t("Amount"), accessor: 'amount' },
    ],
    [t]
  );
  const fetchData = () => {
    axios.post(`/api/vendor/shipment/${params.id}`, {}, config)
      .then(async(res) => {
        console.log("products", res.data);
        if (!res.data?.result?.success) {
          toast.error("Error while fetching products!");
          return;
        }
        setShipment({
          loading: false,
          data: res.data?.result?.shipment || [],
        });
        
        const productsWithImages = await Promise.all(
          res.data.result.shipment.items.map(async (product) => {
            try {
              const imageRes = await axios.post(`/api/product/variant/${product.product.id}/image2`, {}, config);
              if (!imageRes.data.result?.success) {
                throw new Error(imageRes.data.result?.message || "Request failed!");
              }
              return { ...product, image: imageRes.data.result.image };
            } catch (error) {
              console.error('Error fetching image:', error);
              return { ...product, image: null };
            }
          })
        );
        setItems({
          loading: false,
          data: productsWithImages.slice().reverse() || [],
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    fetchData();
  }, []);
  
  console.log("items" , items)
  const reqestsData = React.useMemo(() => {
    return items.data?.length > 0
      ? items.data
          .map((key) => ({
            id: key?.id,
            items : key?.product?.name,
            qty : key?.qty,
            price : key?.price,
            amount : key?.value,
            available :key?.id,
            product : key?.product?.id,
            image : key?.image,
            ready : key?.ready,
            order_line_id : key?.order_line_id
          }))
      : [];
  }, [items]);
  console.log( "request",reqestsData)
 

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data: reqestsData,
      initialState: { pageIndex: currentPage, pageSize:  4 }, 
    },
    useSortBy,
    usePagination
  );


    const {
      control,
      formState: { errors },
      handleSubmit,
    } = useForm({ mode: "all" });

    const navigate = useNavigate();
    const customStyles = {
      control: (styles) => ({
        ...styles,
        background: "#F2F4F8",
        "border-color": "#F2F4F8",
        "border-radius": "8px",
        padding: "6px",
        
      }),
    };
    const { register } = useForm({});

    const [isChecked, setIsChecked] = useState(false);

    const handleChange = (event) => {
      setIsChecked(event.target.checked);
      if (onChange) {
        onChange(event.target.checked);
      }
    };
    const handleProductReady = (id) => {
      axios.post(`/api/vendor/shipment/line/${id}/ready` ,{}, config)
        .then((res) => {
          console.log(res.data)
          if (!res.data.result?.success) {
            toast.error(res.data.result?.message || "Request failed!");
            return;
          }
          toast.success(t("product is available!"));
          fetchData()
        })
        .catch((err) => {
          console.log("err" , err)
          toast.error("Error while change status!");
        })
    }

    const handleProductNotReady = (id) => {
      axios.post(`/api/vendor/shipment/line/${id}/not-ready` ,{}, config)
        .then((res) => {
          console.log(res.data)
          if (!res.data.result?.success) {
            toast.error(res.data.result?.message || "Request failed!");
            return;
          }
          toast.success(t("product is not available!"));
          fetchData()
        })
        .catch((err) => {
          console.log("err" , err)
          toast.error(t("Error while change status!"));
        })
    }

    const handleConfirmShipmenet = () => {
      axios.post(`/api/vendor/shipment/${shipmenet.data.id}/confirm` ,{}, config)
        .then((res) => {
          console.log(res.data)
          if (!res.data.result?.success) {
            toast.error(res.data.result?.message || "Request failed!");
            return;
          }
          toast.success(t("You confirm shipment successfully!"));
          fetchData()
        })
        .catch((err) => {
          console.log("err" , err)
          toast.error(t("Error while confirming shipmenet!"));
        })
    }

    const handlePickUpShipmenet = () => {
      axios.post(`/api/vendor/shipment/${shipmenet.data.id}/shipped` ,{}, config)
        .then((res) => {
          console.log(res.data)
          if (!res.data.result?.success) {
            toast.error(res.data.result?.message || "Request failed!");
            return;
          }
          toast.success(t("Shipment ready to pickup!"));
          fetchData()
        })
        .catch((err) => {
          console.log("err" , err)
          toast.error(t("Error while confirming shipmenet!"));
        })
    }

return (
  <div className="bg-slate-100">
    <div className="sm:flex hidden justify-between items-center mt-2 mx-5">
      <div className="flex items-center gap-6">
        <div className="justify-start items-center gap-2  hidden sm:inline-flex">
          <div
            onClick={() => navigate("/product-Supplier/Account")}
            className="text-zinc-400 text-base font-normal leading-[48px] hover:text-gray-dark cursor-pointer"
          >
            {t("Product Supplier Account")}
          </div>
          <FontAwesomeIcon
            className="text-zinc-400 text-base"
            icon={faAngleRight}
          />
          <div
            onClick={() => navigate("/ProductSupplier/shipment")}
            className="text-zinc-400 text-base font-normal leading-[48px] hover:text-gray-dark cursor-pointer"
          >
            {t("My Shipments")}
          </div>
          <FontAwesomeIcon
            className="text-zinc-400 text-base"
            icon={faAngleRight}
          />
          <div className="text-zinc-400 text-base font-normal leading-[48px] hover:text-gray-dark cursor-pointer">
            {t("Shipment Status")}
          </div>
        </div>
      </div>
    </div>
    <div className=" w-5/6 mx-auto my-6">
      <p className=" justify-start text-start font-bold text-2xl text-red">
      {t("Shipment Status")}
      </p>
    </div>
    {shipmenet.loading && <Loader/>}
   {!shipmenet.loading && <div className="px-5 sm:px-10 py-10 bg-white w-5/6 mx-auto border rounded-2xl mb-3">
      <div className="flex justify-between mb-5">
        <p>
          {t("Status")} :{" "}
          {shipmenet.data.state === "new" ? 
          <span className="text-[#CCCCCC] bg-[#F5F5F5] px-3 py-1">{t("New")}</span>
        : shipmenet.data.state === "confirmed" ? 
        <span className="text-[#49CC29] bg-[#E5FFE5] px-3 py-1 border rounded-lg"> {t("Confirmed")}</span> 
      :
      shipmenet.data.state === "cancelled" ? 
        <span className="text-[#CCCCCC] bg-[#F5F5F5] px-3 py-1">{t("Cancelled")}</span>
        :
         <span className="text-[#1D4ABF]">{t("Shipped")}</span> }
        </p>
        {shipmenet.data.state === "new" ? 
           <button onClick={()=> handleConfirmShipmenet()} className="border rounded-lg text-[#49CC29] bg-[#E5FFE5] px-4 py-2 ">
           {t("Confirm")}
         </button>
        : shipmenet.data.state === "confirmed" ? 
        <button onClick={()=> handlePickUpShipmenet()} className="border rounded-lg bg-[#6DC6F9] px-3 py-3 text-[#1D4ABF]">{t("Ready to pickup")}</button>
      :'' }
      </div>
      <form>
        <div className="grid grid-cols-1 sm:grid-cols-2  gap-x-8 gap-y-5 mb-10">
          <div className="flex flex-col">
            <label>{t("Shipment ID")}</label>
            <input
              type="text"
              className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
              value={shipmenet.data.id}
              disabled
            />
          </div>

          <div className="flex flex-col">
            <label>{t("Order ID")}</label>
            <input
              type="text"
              className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
              value={shipmenet.data.order_id}
              disabled
            />
          </div>
          <div className="flex flex-col">
            <label>{t("Order Date")}</label>
            <input
              type="text"
              className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
              value={"16/5/2024"}
              disabled
            />
          </div>

          <div className="flex flex-col">
            <label>{t("Total Price")}</label>
            <input
              type="text"
              className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
              value={shipmenet.data.total_price}
              disabled
            />
          </div>

          <div className="flex flex-col">
            <label>{t("Ordered By")}</label>
            <input
              type="text"
              className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
              value={shipmenet.data.buyer_name}
              disabled
            />
          </div>
          <div className="invitaion-verification flex flex-col justify-start items-start gap-2 ">
            <label className="text-zinc-800 text-base font-normal leading-snug">
              {t("Phone Number")}
            </label>
            <Controller
              className="w-full"
              control={control}
              name="phone"
              defaultValue={shipmenet.data.buyer_phone}
              disabled
              render={({ field, field: { name, ref } }) => {
                return (
                  <PhoneInput
                    className=""
                    defaultCountry="eg"
                    inputStyle={{
                      background: "rgb(242 244 248",
                      width: "100%",
                      margin: "0px 4px",
                      border: "0px",
                      padding: "0 14px",
                      height: "46px",
                      borderRadius: "8px",
                    }}
                    name={name}
                    ref={ref}
                    {...field}
                  />
                );
              }}
            />
          </div>
          <div className="flex flex-col">
            <label>{t("Email")}</label>
            <input
              type="text"
              className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
              value={shipmenet.data.buyer_email}
              disabled
            />
          </div>
          <div className="flex flex-col">
            <label>{t("Address")}</label>
            <input
              type="text"
              className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
              value={shipmenet.data.buyer_address}
              disabled
            />
          </div>
        </div>
        <div className="overflow-x-auto">
        <table
          {...getTableProps()}
          className="w-full table-auto border-collapse"
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr 
                className="h-12 bg-slate-100"
                {...headerGroup.getHeaderGroupProps()}
              >
                <th className="py-2 px-4 border text-gray-500 text-base font-medium leading-snug">
                  {"#"}
                </th>{" "}
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={`py-2 px-4 border text-gray-500 text-base font-medium leading-snug ${
                      column.isSorted
                        ? column.isSortedDesc
                          ? "sort-desc"
                          : "sort-asc"
                        : ""
                    }`}
                  >
                    {column.render("Header")}
                  </th>
                ))}
                {shipmenet.data.state === "new" && <th className="py-2 px-4 border text-gray-500 text-base font-medium leading-snug">
                {t("Is it Available")}
                  </th>}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows
              .slice(currentPage * 10, (currentPage + 1) * 10)
              .map((row, index) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    className="border-t h-12 cursor-pointer hover:bg-slate-100"
                  >
                    <td className="px-4 border text-center bg-slate-100 text-gray-500 text-xs font-light leading-none">
                      {index + 1 + currentPage * 10}
                    </td>{" "}
                    {row.cells.map((cell, cellIndex) => (
                      <td
                        {...cell.getCellProps()}
                        className="px-4 border text-center text-[#697077] text-sm font-normal leading-tight"
                        key={cellIndex}
                      >
                        {cell.column.Header === t("Image") ? (
                          <div className="w-full flex justify-center items-center p-1">
                            <img
                              className="w-20 h-12 object-cover border"
                              src={row.original.image}
                            />
                          </div>
                        ) : (
                          cell.render("Cell")
                        )}
                      </td>
                    ))}
                    {shipmenet.data.state === "new" && (
                      <td className="px-2 border text-center">
                        <Controller
                          control={control}
                          name={`ready.${row.original.id}`}
                          render={({ field }) => (
                            <>
                              <Select
                                // styles={customStyles}
                                {...field}
                                options={[
                                  { value: "yes", label: t("Yes") },
                                  { value: "no", label: t("No") },
                                ]}
                                isClearable
                                isSearchable
                                value={
                                  row.original.ready === true
                                    ? { value: "yes", label: "Yes" }
                                    : { value: "no", label: "No" }
                                }
                                onChange={(selectedOption) => {
                                  console.log(
                                    "Selected option:",
                                    selectedOption
                                  );
                                  if (selectedOption.value === "yes") {
                                    console.log("Product Ready!");
                                    handleProductReady(row.original.id);
                                  } else {
                                    console.log("Product Not Ready!");
                                    handleProductNotReady(row.original.id);
                                  }
                                }}
                                menuPosition="fixed" // Position the menu correctly
                                styles={{
                                  ...customStyles,
                                  menuPortal: (base) => ({ ...base, zIndex: 9999 }), // Ensure dropdown appears above other elements
                                }}
                              />
                            </>
                          )}
                        />
                      </td>
                    )}
                  </tr>
                );
              })}
          </tbody>
        </table>
        </div>
      </form>
    </div>}
  </div>
);

}
export default ShipmentStatus