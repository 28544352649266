import React, { useState , useEffect} from 'react'
import shopImage from "../../assets/images/shop/details.png"
import Slider from "react-slider"
import {Link, useSearchParams} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faMagnifyingGlass , faArrowRight ,faClose ,faBars, faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import { FiShoppingCart } from "react-icons/fi";
import { FaRegHeart } from "react-icons/fa";
import { HiOutlineViewGrid } from "react-icons/hi";
import { TfiViewListAlt } from "react-icons/tfi";
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import { FaAngleDown,FaAngleUp  } from "react-icons/fa";
import { Typography, Checkbox, FormControlLabel,FormGroup } from '@mui/material';
import { useNavigate} from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import {useDispatch, useSelector} from "react-redux";
import {addToCart, cartActions} from '../../store/slicer/cartSlice';
import axios from "axios";
import {toast} from "react-toastify";
import {Button} from "flowbite-react";
import Loader from "../../components/Loader";
import ProductCard from "./ProductCard";
import Error from "../Error";
import Info from "../Info";
import {uiActions} from "../../store/slicer/ui";
import useAxiosUserSecureConfig from "../../hooks/useAxiosUserSecureConfig";
import {IconButton } from "@material-tailwind/react";
import {Collapse as ReactCollapse} from "@material-tailwind/react";
import ReactPaginate from 'react-paginate';
import { IoReload } from 'react-icons/io5';
import {useTranslation} from "react-i18next";
import { wishListActions } from '../../store/slicer/wishListSlice';


export const ViewStyle = {
    Grid: "grid",
    Row: "row",
}

function Shop() {
  const user = useSelector(state => state.auth.user)
  console.log("user" , user)
    const [openNav, setOpenNav] = React.useState(false);
    const { t, i18n } = useTranslation();
    const cartItems = useSelector(state => state.cart.items);
    const [products , setProducts] = useState({ loading: true, data: [] , maxPrice: null })
    const [categories , setCategories] = useState({ loading: true, data: [] })

    const [searchParams, setSearchParams] = useSearchParams();
    const [queryTimer, setQueryTimer] = useState(null);
    const [priceRangeTimer, setPriceRangeTimer] = useState(null);

    const handleSearch = (e) => {
      e.preventDefault();
      setCurrentPage(0);
        clearTimeout(queryTimer)

        const timer = setTimeout(() => {
            if (e.target.value === "") {
                searchParams.delete("query");

            } else {
                searchParams.set("query", e.target.value)
            }

            setSearchParams(searchParams);
        }, 500)

        setQueryTimer(timer)
    }
    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        // Optionally, you can trigger the search logic here if needed
        handleSearch(event);
      }
    };
    const isMobile = window.innerWidth <= 375; //  for mobile 
    const isTablet =window.innerWidth  <=425;
    const isLaptop =window.innerWidth  <1024;
    const isLargLaptop =window.innerWidth >= 1024;

    const navigate = useNavigate();

    const [open, setOpen] = useState({
        size : false,
        color : false,
        brand : false,
        stock : false,
        disc : false,
        avg : false,
        condition :false,
    });
    const handleClick = (e) => {
      setOpen( open =>({
        ...open,
        [e] : !open[e]
      }));
      
    }

    const priceRangeMinimum = 0;
    const priceRangeMaximum = 35000;
    console.log("max" , priceRangeMaximum)
    const [priceRange , setPriceRange] = useState([priceRangeMinimum , priceRangeMaximum]);
    // useEffect(() => {
    //   setPriceRange([priceRangeMinimum, products.maxPrice]);
    // }, [products.maxPrice, priceRangeMinimum]);

    const [view , setView] = useState(ViewStyle.Grid)

    const dispatch = useDispatch();

    useEffect(() => {
        setProducts(prevState => ({
            ...prevState,
            loading: true,
            data: []
        }))
        const priceRange = JSON.parse(searchParams.get("range")) || [priceRangeMinimum, priceRangeMaximum];
        console.log( searchParams.get("category"))
        axios
          .post("/api/products", {
            query: searchParams.get("query") || "",
            catId:
              searchParams.get("category") != null
                ? parseInt(searchParams.get("category"))
                : "",
            minPrice: priceRange[0],
            maxPrice: priceRange[1],
            brand: selectedBrands || [],
            size: selectedSizes || [],
            color: selectedColors || [],
            condition: selectedCondition || [],
            pageSize: 100
          })
          .then(async (res) => {
            console.log("products", res.data);
            if (!res.data?.result?.success) {
              console.log(res.data)
              toast.error("Error while fetching products!");
              return;
            }
            const uniqueProducts = res.data.result.products.reduce((acc, product) => {
              const existingProduct = acc.find(p => p.product.id === product.product.id && p.color === product.color);
              if (!existingProduct) {
                acc.push(product);
              }
              return acc;
            }, []);
            const variantsWithImages = await Promise.all(
              uniqueProducts.map(async (variant) => {
                try {
                  const imageRes = await axios.post(
                    `/api/product/variant/${
                      variant.id
                    }/image?default_to_product=${false}`,
                    {},
                    config
                  );
                  if (!imageRes.data.result?.success) {
                    throw new Error(
                      imageRes.data.result?.message || "Request failed!"
                    );
                  }
                  return { ...variant, image: imageRes.data.result.image };
                } catch (error) {
                  console.error("Error fetching image:", error);
                  return { ...variant, image: null };
                }
              })
            );
            const maxPrice = Math.max(
              ...variantsWithImages.map((variant) => variant.price)
            );
            setProducts((prevState) => ({
              ...prevState,
              loading: false,
              data: variantsWithImages ?? [],
              maxPrice: maxPrice,
            }));
          })
          .catch((err) => {
            console.log(err);
          });
    }, [searchParams]);

    useEffect(() => {
        axios.post('/api/product_categories', {} ,
          {headers: {
            'Accept-Language': i18n.language === "en" ? 'en' : 'ar'
          }
        }
      ).then((res) => {
            console.log("categories", res.data);

            if (!res.data?.result?.success) {
                toast.error("Error while fetching categories!");
                return;
            }

            setCategories(prevState => ({
                ...prevState,
                loading: false,
                data: res.data?.result?.categories ?? []
            }))
        }).catch((err) => {
            console.log(err);
        })
    }, [])

    const config = useAxiosUserSecureConfig();

    const handleAddToCart = (e, product ) => {
        dispatch(cartActions.setLoading(true));
        // dispatch(uiActions.startLoading());

        axios.post("/api/add_to_cart", {"product_id": product.id,}, config)
            .then(() => {
                axios.post("/api/cart", {}, config)
                    .then((res) => {
                        dispatch(cartActions.setCart(res.data.result));
                        dispatch(cartActions.setLoading(false));
                        // dispatch(uiActions.stopLoading());
                    })

                toast.success("Cart Updated!");
            })
            .catch((err) => {
                console.log(err)
                // dispatch(uiActions.stopLoading());
                dispatch(cartActions.setLoading(false));
                toast.error("Error While Updating Cart!");
            })

        e.stopPropagation();
    };

    const viewHandleClick =(view)=>{
        setView(view);
    }
    const [displayCount, setDisplayCount] = useState(9);
    const [currentPage, setCurrentPage] = useState(0);
  
    const handleSelectChange = (e) => {
      setDisplayCount(parseInt(e.target.value));
      setCurrentPage(0); // Reset to the first page when changing display count
    };
  
    const handlePageChange = ({ selected }) => {
      setCurrentPage(selected);
    };
    const pageCount = Math.ceil(products.data.length / displayCount);
    const offset = currentPage * displayCount;
    const currentPageData = products?.data.slice(offset, offset + displayCount);

    const [productsWishList, setProductsWishList] = useState();
    const fetchWishList = () => {
      axios
        .post(`/api/wishlist/product`, {}, config)
        .then((res) => {
          setProductsWishList(res.data?.result.products);
          dispatch(wishListActions.setProductsWishList(res.data.result));
          dispatch(wishListActions.setLoading(false));
        })
        .catch((err) => {
          console.log(err);
        });
    };
    useEffect(() => {
      fetchWishList();
    }, []);

    const handleAddToWishList = (e, productId) => {
      e.stopPropagation();
      if(user === null) {
        toast.error("You must be logged in")
        navigate("/login" , { replace: true, state: { scrollToTop: true } })
        return;
      }
      axios
        .post("/api/wishlist/product/add", { product_id: productId }, config)
        .then((res) => {
          console.log(res.data);
          if (!res.data.result?.success) {
            toast.error(res.data.result?.message || t("Request failed!"));
            fetchWishList();
            return;
          }
          toast.success(t("You add product to wishlist successfully!"));
          fetchWishList();
        })
        .catch((err) => {
          console.log("err", err);
          toast.error(t("Error while adding product to wishlist!"));
          fetchWishList();
        });
    };
    const deleteFromWishList = (e, productId) => {
      e.stopPropagation();
      axios
        .post(`/api/wishlist/product/${productId}/remove`, {}, config)
        .then((res) => {
          console.log(res.data);
          if (!res.data.result?.success) {
            toast.error(res.data.result?.message || t("Request failed!"));
            fetchWishList();
            return;
          }
          toast.success(t("You remove product from wishlist successfully!"));
          fetchWishList();
        })
        .catch((err) => {
          console.log("err", err);
          toast.error(t("Error while removing product to wishlist!"));
          fetchWishList();
        });
    };
    console.log("productsWishList", productsWishList);
    const isProductInWishList = (productID) => {
      if (Array.isArray(productsWishList)) {
        return productsWishList.some((item) => item === productID);
      } else {
        return false;
      }
    };

    const [colors, setColors] = useState({ loading: true, data: [] });
  useEffect(() => {
    axios
      .post(`/api/product/colors`, {})
      .then((res) => {
        console.log("colors", res.data);

        if (!res.data?.result?.success) {
          toast.error("Error while fetching colors!");
          return;
        }

        setColors((prevState) => ({
          ...prevState,
          loading: false,
          data: res.data?.result?.colors ?? [],
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const [sizes, setSizes] = useState({ loading: true, data: [] });
  useEffect(() => {
    axios
      .post(`/api/product/sizes`, {})
      .then((res) => {
        console.log("sizes", res.data);

        if (!res.data?.result?.success) {
          toast.error("Error while fetching sizes!");
          return;
        }

        setSizes((prevState) => ({
          ...prevState,
          loading: false,
          data: res.data?.result?.sizes ?? [],
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const [brands, setBrands] = useState({ loading: true, data: [] });
  useEffect(() => {
    axios
      .post(`/api/product/brands`, {})
      .then((res) => {
        console.log("brands", res.data);

        if (!res.data?.result?.success) {
          toast.error("Error while fetching brands!");
          return;
        }

        setBrands((prevState) => ({
          ...prevState,
          loading: false,
          data: res.data?.result?.brands ?? [],
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const [selectedSizes, setSelectedSizes] = useState([]);
  const [selectedColors, setSelectedColors] = useState([]);
  const [selectedCondition, setSelectedCondition] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);

const handleCheck = (value, type) => {
  if (type === t("size")) {
    setSelectedSizes((prevState) =>
      prevState.includes(value)
        ? prevState.filter((size) => size !== value)
        : [...prevState, value]
    );
  } else if (type === "color") {
    setSelectedColors((prevState) =>
      prevState.includes(value)
        ? prevState.filter((color) => color !== value)
        : [...prevState, value]
    );
  } else if (type === "brand") {
    setSelectedBrands((prevState) =>
      prevState.includes(value)
        ? prevState.filter((brand) => brand !== value)
        : [...prevState, value]
    );
  } else if (type === "condition") {
    setSelectedCondition((prevState) =>
      prevState.includes(value)
        ? prevState.filter((condition) => condition !== value)
        : [...prevState, value]
    );
  }
};
const applyFilter = ()=>{
  searchParams.set(t("size"), selectedSizes );
  searchParams.set(t("brand"), selectedBrands );
  searchParams.set(t("condition"), selectedCondition );
  searchParams.set(t("color"), selectedColors );
  setSearchParams(searchParams)

}
const resetFilter = () => {
  setSelectedSizes([])
  setSelectedColors([])
  setSelectedCondition([])
  setSelectedBrands([])
  searchParams.set("size", [] );
  searchParams.set("brand", [] );
  searchParams.set("condition", []);
  searchParams.set("color", [] );
  setSearchParams(searchParams)
}

  return (
    <>
      <div className="relative">
        <Swiper
          centeredSlides={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={true}
          modules={[Autoplay, Pagination]}
          className="mySwiper"
        >
          <SwiperSlide>
            <img className="w-full" src={shopImage} alt="home" />
          </SwiperSlide>
          <SwiperSlide>
            <img className="w-full" src={shopImage} alt="home" />
          </SwiperSlide>
          <SwiperSlide>
            <img className="w-full" src={shopImage} alt="home" />
          </SwiperSlide>
        </Swiper>
        <h1 className="z-10 px-5 xl:px-10 absolute bottom-[20%] text-white text-2xl sm:text-[40px] font-medium font-['Roboto'] leading-[44px]">
          {t("Store")}
        </h1>
      </div>

      <section className="px-5 xl:px-10 py-12">
        <div className="flex gap-10 relative mb-10">
        <div
            className={`${ openNav ? "-translate-x-0" : i18n.language === 'en'?  ' -translate-x-[120%] ' :'translate-x-[120%]' } 
            lg:hidden absolute -top-12 ${i18n.language === 'en' ? 'left-0' : 'right-0'}  z-40 h-full overflow-y-auto transition-transform bg-white p-4 border-e`}
          >
            <aside className="">
              <div className="flex justify-end">
                <IconButton
                  variant="text"
                  className="ml-auto h-6 w-6 hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
                  ripple={false}
                  onClick={() => setOpenNav(!openNav)}
                >
                  {openNav ? (
                    <FontAwesomeIcon icon={faClose} size={"xl"} />
                  ) : (
                    <FontAwesomeIcon icon={faBars} size={"xl"} />
                  )}
                </IconButton>
              </div>
              <div className="flex flex-col gap-4 pb-6 border-b-[3px] border-b-gray-dark">
                <p className="text-zinc-500 text-base font-medium leading-normal tracking-wide">
                  {t("Price")}
                </p>
                <div className="flex justify-between">
                  <div className="px-2.5 pt-1 pb-[5px] bg-[#F4F4F4] rounded-[100px] justify-center items-center gap-3 inline-flex">
                    <div className={`flex justify-center ${i18n.language === 'en' ? 'flex-row' : 'flex-row-reverse'} font-bold leading-[18px]`}>
                      <div>{priceRange[0]}</div>
                      <div>EGP</div> 
                    </div>
                  </div>
                  <div className="px-2.5 pt-1 pb-[5px] bg-[#F4F4F4] rounded-[100px] justify-center items-center gap-3 inline-flex">
                    <div className= {`flex justify-center ${i18n.language === 'en' ? 'flex-row' : 'flex-row-reverse'} font-bold leading-[18px]`}>
                    <div>{priceRange[1]}</div>
                      <div>EGP</div> 
                    </div>
                  </div>
                </div>
                <Slider
                  thumbClassName="example-thumb"
                  trackClassName="example-track"
                  className="slider w-full h-[6px] py-2 "
                  onChange={(value) => {
                    setPriceRange(value);

                    clearTimeout(priceRangeTimer);

                    const timer = setTimeout(() => {
                      if (value === [priceRangeMinimum, priceRangeMaximum]) {
                        searchParams.delete("range");
                      } else {
                        searchParams.set("range", JSON.stringify(value));
                      }
                      setSearchParams(searchParams);
                    }, 500);

                    setPriceRangeTimer(timer);
                  }}
                  value={priceRange}
                  min={priceRangeMinimum}
                  max={priceRangeMaximum}
                ></Slider>
              </div>
              <List
                className="flex flex-col gap-4 !mb-16 !pt-4 dropdown-filter"
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "background.paper",
                }}
                component="nav"
                aria-labelledby="nested-list-subheader"
              >
                <ListItemButton onClick={() => handleClick("size")}>
                  <ListItemText
                    className="text-gray-dark"
                    primary={
                      <Typography className="text-gray-dark !text-base !font-medium !leading-none text-start ">
                        {t("Size")}
                      </Typography>
                    }
                  />
                  {open.size ? <FaAngleUp /> : <FaAngleDown />}
                </ListItemButton>
                <Collapse in={open.size} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <FormGroup className="mx-8">
                      {sizes.data.map((size) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectedSizes.includes(size)}
                              onChange={() => handleCheck(size, "size")}
                            />
                          }
                          label={size}
                        />
                      ))}
                    </FormGroup>
                  </List>
                </Collapse>
                <ListItemButton onClick={() => handleClick("color")}>
                  <ListItemText
                    className="text-gray-dark"
                    primary={
                      <Typography className="text-gray-dark !text-base !font-medium !leading-none text-start">
                        {t("Color")}
                      </Typography>
                    }
                  />
                  {open.color ? <FaAngleUp /> : <FaAngleDown />}
                </ListItemButton>
                <Collapse in={open.color} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <FormGroup className="mx-8">
                      {colors.data.map((size) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectedColors.includes(size)}
                              onChange={() => handleCheck(size, "color")}
                            />
                          }
                          label={size}
                        />
                      ))}
                    </FormGroup>
                  </List>
                </Collapse>
                <ListItemButton onClick={() => handleClick("brand")}>
                  <ListItemText
                    className="text-gray-dark"
                    primary={
                      <Typography className="text-gray-dark !text-base !font-medium !leading-none text-start">
                        Brand
                      </Typography>
                    }
                  />
                  {open.brand ? <FaAngleUp /> : <FaAngleDown />}
                </ListItemButton>
                <Collapse in={open.brand} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <FormGroup className="mx-8">
                      {brands.data.map((size) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectedBrands.includes(size)}
                              onChange={() => handleCheck(size, "brand")}
                            />
                          }
                          label={size}
                        />
                      ))}
                    </FormGroup>
                  </List>
                </Collapse>
                <ListItemButton onClick={() => handleClick("condition")}>
                  <ListItemText
                    className="text-gray-dark"
                    primary={
                      <Typography className="text-gray-dark !text-base !font-medium !leading-none text-start">
                        Items Condition
                      </Typography>
                    }
                  />
                  {open.condition ? <FaAngleUp /> : <FaAngleDown />}
                </ListItemButton>
                <Collapse in={open.condition} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <FormGroup className="mx-8">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedCondition.includes("new")}
                            onChange={() => handleCheck("new", "condition")}
                          />
                        }
                        label={t("New")}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedCondition.includes("used")}
                            onChange={() => handleCheck("used", "condition")}
                          />
                        }
                        label="Used"
                      />
                    </FormGroup>
                  </List>
                </Collapse>
              </List>
              <div className="flex flex-col gap-2">
                <button
                  onClick={() => applyFilter()}
                  className="w-full px-[22px] py-3 bg-red rounded-md border justify-center items-center gap-1 inline-flex"
                >
                  <span className="text-center text-white text-base font-semibold leading-snug">
                  {t("Apply Filter")}
                  </span>
                  {i18n.language === 'en' ? (
                    <FontAwesomeIcon className='text-white pr-5 xl:pr-8' icon={faArrowRight} />
                ) : (<FontAwesomeIcon className='text-white pl-5 xl:pl-8' icon={faArrowLeft} />)}
                </button>
                <button
                  onClick={resetFilter}
                  type="reset"
                  className="w-full px-[22px] py-3 justify-center items-center gap-2 inline-flex"
                >
                  <IoReload className="text-center text-zinc text-lg font-semibold leading-snug" />
                  <span className="text-center text-zinc text-base font-semibold leading-snug">
                    {t("Reset Filters")}
                  </span>
                </button>
              </div>
            </aside>
          </div>
          <aside className="w-[310px] hidden lg:block">
            <div className="flex flex-col gap-4 pb-6 border-b-[3px] border-b-gray-dark">
              <p className="text-zinc-500 text-base font-medium leading-normal tracking-wide">
                {t("Price")}
              </p>
              <div className="flex justify-between">
                <div className="px-2.5 pt-1 pb-[5px] bg-[#F4F4F4] rounded-[100px] justify-center items-center gap-3 inline-flex">
                <div className={`flex justify-center ${i18n.language === 'en' ? 'flex-row' : 'flex-row-reverse'} font-bold leading-[18px]`}>
                      <div>{priceRange[0]}</div>
                      <div>EGP</div> 
                  </div>
                </div>
                <div className="px-2.5 pt-1 pb-[5px] bg-[#F4F4F4] rounded-[100px] justify-center items-center gap-3 inline-flex">
                <div className={`flex justify-center ${i18n.language === 'en' ? 'flex-row' : 'flex-row-reverse'} font-bold leading-[18px]`}>
                      <div>{priceRange[1]}</div>
                      <div>EGP</div> 
                  </div>
                </div>
              </div>
              <Slider
                thumbClassName="example-thumb"
                trackClassName="example-track"
                className="slider w-full h-[2px] py-2 "
                onChange={(value) => {
                  setPriceRange(value);

                  clearTimeout(priceRangeTimer);

                  const timer = setTimeout(() => {
                    if (value === [priceRangeMinimum, priceRangeMaximum]) {
                      searchParams.delete("range");
                    } else {
                      console.log("sss");
                      searchParams.set("range", JSON.stringify(value));
                    }
                    setSearchParams(searchParams);
                  }, 500);

                  setPriceRangeTimer(timer);
                }}
                value={priceRange}
                min={priceRangeMinimum}
                max={priceRangeMaximum}
              ></Slider>
            </div>
            <List
              className="flex flex-col gap-4 !mb-16 !pt-4 dropdown-filter"
              sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
              component="nav"
              aria-labelledby="nested-list-subheader"
            >
              <ListItemButton onClick={() => handleClick("size")}>
                <ListItemText
                  className="text-gray-dark"
                  primary={
                    <Typography className="text-gray-dark !text-base !font-medium !leading-none text-start">
                      {t("Size")}
                    </Typography>
                  }
                />
                {open.size ? <FaAngleUp /> : <FaAngleDown />}
              </ListItemButton>
              <Collapse in={open.size} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <FormGroup className="mx-8">
                    {sizes.data.map((size) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedSizes.includes(size)}
                            onChange={() => handleCheck(size, "size")}
                          />
                        }
                        label={size}
                      />
                    ))}
                  </FormGroup>
                </List>
              </Collapse>
              <ListItemButton onClick={() => handleClick("color")}>
                <ListItemText
                  className="text-gray-dark"
                  primary={
                    <Typography className="text-gray-dark !text-base !font-medium !leading-none text-start">
                      {t("Color")}
                    </Typography>
                  }
                />
                {open.color ? <FaAngleUp /> : <FaAngleDown />}
              </ListItemButton>
              <Collapse in={open.color} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <FormGroup className="mx-8">
                    {colors.data.map((size) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedColors.includes(size)}
                            onChange={() => handleCheck(size, "color")}
                          />
                        }
                        label={size}
                      />
                    ))}
                  </FormGroup>
                </List>
              </Collapse>
              <ListItemButton onClick={() => handleClick("brand")}>
                <ListItemText
                  className="text-gray-dark"
                  primary={
                    <Typography className="text-gray-dark !text-base !font-medium !leading-none text-start">
                      {t("Brand")}
                    </Typography>
                  }
                />
                {open.brand ? <FaAngleUp /> : <FaAngleDown />}
              </ListItemButton>
              <Collapse in={open.brand} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <FormGroup className="mx-8">
                    {brands.data.map((size) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedBrands.includes(size)}
                            onChange={() => handleCheck(size, "brand")}
                          />
                        }
                        label={size}
                      />
                    ))}
                  </FormGroup>
                </List>
              </Collapse>
              <ListItemButton onClick={() => handleClick("condition")}>
                <ListItemText
                  className="text-gray-dark"
                  primary={
                    <Typography className="text-gray-dark !text-base !font-medium !leading-none text-start">
                      {t("Items Condition")}
                    </Typography>
                  }
                />
                {open.condition ? <FaAngleUp /> : <FaAngleDown />}
              </ListItemButton>
              <Collapse in={open.condition} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <FormGroup className="mx-8">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedCondition.includes("new")}
                          onChange={() => handleCheck("new", "condition")}
                        />
                      }
                      label={t("New")}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedCondition.includes("used")}
                          onChange={() => handleCheck("used", "condition")}
                        />
                      }
                      label="Used"
                    />
                  </FormGroup>
                </List>
              </Collapse>
            </List>
            <div className="flex flex-col gap-2">
              <button
                onClick={() => applyFilter()}
                className="w-full px-[22px] py-3 bg-red rounded-md border justify-center items-center gap-1 inline-flex"
              >
                <span className="text-center text-white text-base font-semibold leading-snug">
                {t("Apply Filter")}
                </span>
                {i18n.language === 'en' ? (
                    <FontAwesomeIcon className='text-white pr-5 xl:pr-8' icon={faArrowRight} />
                ) : (<FontAwesomeIcon className='text-white pl-5 xl:pl-8' icon={faArrowLeft} />)}
              </button>
              <button
                onClick={resetFilter}
                type="reset"
                className="w-full px-[22px] py-3 justify-center items-center gap-2 inline-flex"
              >
                <IoReload className="text-center text-zinc text-lg font-semibold leading-snug" />
                <span className="text-center text-zinc text-base font-semibold leading-snug">
                {t("Reset Filters")}
                </span>
              </button>
            </div>
          </aside>

          <div className="grow w-1">
            <div className="flex justify-between items-center mb-6">
              <div className="flex items-center gap-5">
                <IconButton
                  variant="text"
                  className="ml-auto h-6 w-6 hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
                  ripple={false}
                  onClick={() => setOpenNav(!openNav)}
                >
                  {openNav ? (
                    <FontAwesomeIcon icon={faClose} size={"xl"} />
                  ) : (
                    <FontAwesomeIcon icon={faBars} size={"xl"} />
                  )}
                </IconButton>
                <form className="flex items-center">
                  <div className="relative ">
                    <input
                      onChange={handleSearch}
                      onKeyDown={handleKeyDown}
                      type="text"
                      id="search"
                      className="xl:w-[553px] h-12 px-4 py-3 bg-white rounded-lg border border-zinc-500 justify-between items-center inline-flex"
                      placeholder={t("Search for...")}
                    />
                    <button
                      type="button"
                      className="absolute inset-y-0 end-0 flex items-center pe-3"
                    >
                      <FontAwesomeIcon
                        className="w-6 text-zinc-500"
                        icon={faMagnifyingGlass}
                      />
                    </button>
                  </div>
                </form>
              </div>
              <div className="gap-4 items-center hidden md:flex">
                <span className="text-zinc text-base font-normal leading-none tracking-wide">
                  {t("View")}
                </span>
                <TfiViewListAlt
                  onClick={() => viewHandleClick("row")}
                  className={
                    view === "grid"
                      ? "text-gray-dark cursor-pointer"
                      : "text-red cursor-pointer"
                  }
                  size={"20px"}
                />
                <HiOutlineViewGrid
                  onClick={() => viewHandleClick("grid")}
                  className={
                    view === "grid"
                      ? "text-red cursor-pointer"
                      : "text-gray-dark cursor-pointer"
                  }
                  size={"28px"}
                />
                <select
                  className="px-3 py-2 rounded-lg border border-[#8F6D6D] justify-start items-center gap-2 inline-flex"
                  onChange={handleSelectChange}
                  value={displayCount}
                >
                  <option value="3">3</option>
                  <option value="6">6</option>
                  <option value="9">9</option>
                  <option value="12">12</option>
                </select>
              </div>
            </div>
          
            {categories.loading && <Loader slim />}
           <div className="arrow">
            <ul className="flex gap-1 md:gap-6 mb-12">
           
              <li
                className={`py-4 px-2 cursor-pointer ${
                  !searchParams.has("category") ? "border-red" : ""
                } border-b-2 hover:border-red text-red text-base font-medium leading-none tracking-wide`}
                onClick={() => {
                  searchParams.delete("category");
                  setSearchParams(searchParams);
                  setCurrentPage(0);
                }}
              >
                {t('All')}
              </li>
                 
              <Swiper
              
                 spaceBetween={20}
                 slidesPerView={
                   isMobile ? 2 : isTablet ? 4 : isLaptop ? 5 : "auto"
                 }
                 navigation={true}
                 modules={[Navigation]}
               >
              {categories.data.map((category) => (
                
                <SwiperSlide className="arrow !w-auto">
                
                <li
                  key={category.id}
                  className={`py-4 px-2  cursor-pointer ${
                    parseInt(searchParams.get("category")) === category.id
                      ? "border-red"
                      : ""
                  } border-b-2 hover:border-red  text-red text-base font-medium leading-none tracking-wide`}
                  onClick={() => {
                    searchParams.set("category", category.id);
                    setSearchParams(searchParams);
                    setCurrentPage(0);
                  }}
                >
                  {category.name}
                </li>
                </SwiperSlide>
                
              ))}
             </Swiper>
             

            </ul>
            </div>
            {products.loading && <Loader />}
            <div
              className={
                view === ViewStyle.Grid
                  ? "grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-x-6 gap-y-12"
                  : "w-full grid grid-cols-1 gap-y-10"
              }
            >
              {currentPageData.map((product) => (
                <ProductCard
                  key={product.id}
                  product={product}
                  navigate={navigate}
                  handleAddToCart={handleAddToCart}
                  view={view}
                  cartItems={cartItems}
                  handleAddToWishList={handleAddToWishList}
                  deleteFromWishList={deleteFromWishList}
                  isProductInWishList={isProductInWishList}
                />
              ))}
            </div>
            {!products.loading && products.data.length < 1 && (
              <Info msg={"No Found Products!"} />
            )}
          </div>
        </div>
        <ReactPaginate
          breakLabel="..."
          nextLabel={t("Next >")}
          onPageChange={handlePageChange}
          pageCount={pageCount}
          previousLabel={t("< Previous")}
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          pageLinkClassName="page-num"
          previousLinkClassName="page-btn"
          nextLinkClassName="page-btn"
          activeLinkClassName="active"
          disabledLinkClassName="disabled-btn"
        />
      </section>
    </>
  );
}

export default Shop