import React, { useState , useEffect} from 'react'
import shopImage from "../../assets/images/shop/details.png"
import Slider from "react-slider"
import {Link, useSearchParams} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faMagnifyingGlass , faArrowRight ,faClose ,faBars, faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import { FiShoppingCart } from "react-icons/fi";
import { FaRegHeart, FaSearch } from "react-icons/fa";
import { HiOutlineViewGrid } from "react-icons/hi";
import { TfiViewListAlt } from "react-icons/tfi";
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import { FaAngleDown,FaAngleUp  } from "react-icons/fa";
import { Typography, Checkbox, FormControlLabel,FormGroup } from '@mui/material';
import { useNavigate} from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import {useDispatch, useSelector} from "react-redux";
import {addToCart, cartActions} from '../../store/slicer/cartSlice';
import axios from "axios";
import {toast} from "react-toastify";
import {Button} from "flowbite-react";
import Loader from "../../components/Loader";
import ProductCard from "./ProductCard";
import Error from "../Error";
import Info from "../Info";
import {uiActions} from "../../store/slicer/ui";
import useAxiosUserSecureConfig from "../../hooks/useAxiosUserSecureConfig";
import {IconButton } from "@material-tailwind/react";
import {Collapse as ReactCollapse} from "@material-tailwind/react";
import serv from "../../assets/images/sevices/serv.png";
import {useForm , Controller} from "react-hook-form";
import Select, { components } from 'react-select';
import resetSearch from "../../assets/images/venus/icons/reset-search.svg"
import clsx from "clsx"; 
import ServiceCard from '../../components/services/ServiceCard';
import ReactPaginate from 'react-paginate';
import { IoReload } from "react-icons/io5";
import {useTranslation} from "react-i18next";



export const ViewStyle = {
    Grid: "grid",
    Row: "row",
}

function Services() {
    const [openNav, setOpenNav] = React.useState(false);

    const cartItems = useSelector(state => state.cart.items);
    const [vendors , setVendors] = useState({ loading: true, data: [] })
    const [categories , setCategories] = useState({ loading: true, data: [] })
    const { t, i18n } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [queryTimer, setQueryTimer] = useState(null);
    const [priceRangeTimer, setPriceRangeTimer] = useState(null);

    const handleSearch = (e) => {
        clearTimeout(queryTimer)

        const timer = setTimeout(() => {
            if (e.target.value === "") {
                searchParams.delete("query");

            } else {
                searchParams.set("query", e.target.value)
            }

            setSearchParams(searchParams);
        }, 500)

        setQueryTimer(timer)
    }

    const navigate = useNavigate();

    const [open, setOpen] = useState({
        size : false,
        color : false,
        brand : false,
        stock : false,
        disc : false,
        avg : false,
        condition :false,
    });
    const handleClick = (e) => {
      setOpen( open =>({
        ...open,
        [e] : !open[e]
      }));
      
    }

    const priceRangeMinimum = 0;
    const priceRangeMaximum = 10000;
    const [priceRange , setPriceRange] = useState([priceRangeMinimum , priceRangeMaximum]);

    const [view , setView] = useState(ViewStyle.Grid)

    const dispatch = useDispatch();

    const [query, setQuery] = useState({ category: null , country: null,  state: null, city: null });

    useEffect(() => {
      setVendors((prevState) => ({
        ...prevState,
        loading: true,
       
      }));
      console.log("query" , query)
      const requestData = {
        service_category_id: query.category ? [query.category] : [],
        city_id: query.city ? [query.city] : [],
        state_id: query.state ? [query.state] : [],
        country_id: query.country ? [query.country] : [],
      };
      console.log("requestData", requestData);
      axios
        .post("/api/vendor", requestData)
        .then((res) => {
          console.log("res", res.data);
          if (!res.data?.result?.success) {
            toast.error("Error while fetching vendors!");
            return;
          }
          setVendors((prevState) => ({
            ...prevState,
            loading: false,
            data:
              res.data?.result?.vendors.filter(
                (vendor) => vendor.services && vendor.services.length > 0
              ) ?? [],
          }));
          console.log("===> ", vendors.data)
        })
        .catch((err) => {
          console.log(err);
        });
    }, [query]);

    const onSubmit = (data) => {
      console.log("data",data)
      setQuery({
        category: data?.category?.value || null,
        city: data?.city?.value || null,
        state: data?.state?.value || null,
        country: data?.country?.value || null,
      });
    }

    console.log("ven",vendors)
    

    useEffect(() => {
      axios.post('/api/service_categories', {}, {
        headers: {
          'Accept-Language': i18n.language === "en" ? 'en' : 'ar'
        }
      }).then((res) => {
            console.log("categories", res.data);

            if (!res.data?.result?.success) {
                toast.error("Error while fetching categories!");
                return;
            }

            setCategories(prevState => ({
                ...prevState,
                loading: false,
                data: res.data?.result?.categories ?? []
            }))
        }).catch((err) => {
            console.log(err);
        })
    }, [])

    const config = useAxiosUserSecureConfig();

    const viewHandleClick =(view)=>{
        setView(view);
    }
    const {
      watch,
      control,
      register,
      setValue,
      handleSubmit,
      reset
    } = useForm();

    const [countries , setCountries] = useState({ loading: true, data: [] })
    const [states , setStates] = useState({ loading: false, data: [] })
    const [cities , setCities] = useState({ loading: false, data: [] })
  
    useEffect(() => {
        axios.post('/api/country', {}).then((res) => {
        console.log("countries", res.data);
  
        if (!res.data?.result?.success) {
          toast.error("Error while fetching countries!");
          return;
        }
  
        setCountries(prevState => ({
          ...prevState,
          loading: false,
          data: res.data?.result?.countries ?? []
        }))
      }).catch((err) => {
        console.log(err);
      })
  
  
    }, []);
  
    const country = watch("country");
    const state = watch("state");
  
    useEffect(() => {
      setValue("state", null);
      setValue("city", null);
  
      if (!country) {
        setStates(prevState => ({
          ...prevState,
          data: []
        }))
        setCities(prevState => ({
          ...prevState,
          data: []
        }))
        return;
      }
  
      setStates(prevState => ({
        ...prevState,
        loading: true,
      }));
  
      axios.post(`/api/country/${country.value}`, {}).then((res) => {
        console.log("states", res.data);
  
        if (!res.data?.result?.success) {
          toast.error("Error while fetching states!");
          return;
        }
  
        setStates(prevState => ({
          ...prevState,
          loading: false,
          data: res.data?.result?.countries ?? [] // TODO: fix naming in backend from countries to states
        }))
      }).catch((err) => {
        console.log(err);
      })
    }, [country]);
  
    useEffect(() => {
      setValue("city", null)
  
      if (!state) {
        setCities(prevState => ({
          ...prevState,
          data: []
        }))
        return;
      }
  
      setCities(prevState => ({
        ...prevState,
        loading: true,
      }));
  
      axios.post(`/api/state/${state.value}`, {}).then((res) => {
        console.log("cities", res.data);
  
        if (!res.data?.result?.success) {
          toast.error("Error while fetching cities!");
          return;
        }
  
        setCities(prevState => ({
          ...prevState,
          loading: false,
          data: res.data?.result?.state.cities ?? []
        }))
      }).catch((err) => {
        console.log(err);
      })
    }, [state]);
    const defaultValues = {
      category: "",
      country: "",
      state:"",
      city: "",
    };
  
    const baseStyle = 
  "text-[#FE6972] text-sm font-normal leading-[21px] h-12 px-[18px] bg-white rounded-[10px] border border-neutral-400 justify-start items-center gap-2.5 inline-flex";

  const orderStyle = 
  "w-44 text-[#FE6972] text-sm font-normal leading-[21px] h-12 px-[18px] bg-white rounded-[10px] border border-neutral-400 justify-start items-center gap-2.5 inline-flex";

  const menuStyle = 
  "border rounded-md bg-white hover:cursor-pointer px-4 py-2 whitespace-nowrap z-100";

  const optionStyles = { 
    focus: "hover:cursor-pointer hover:text-red", 
    Selected: "bg-slate-300", 
  };
  const [displayCount, setDisplayCount] = useState(9);
  const [currentPage, setCurrentPage] = useState(0);

  const handleSelectChange = (e) => {
    setDisplayCount(parseInt(e.target.value));
    setCurrentPage(0); // Reset to the first page when changing display count
  };

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };
  const pageCount = Math.ceil(vendors.data.length / displayCount);
  const offset = currentPage * displayCount;
  const currentPageData = vendors?.data.slice(offset, offset + displayCount);

  const [checkedValues, setCheckedValues] = useState([]);
  const [allValues, setAllValues] = useState(false);

  // Function to handle checkbox toggle
  const handleCheckboxToggle = (categoryId) => {
    // Check if the category ID is already in the checkedValues array
    if (checkedValues.includes(categoryId)) {
      // If it is, remove it
      setCheckedValues(prev => prev.filter(id => id !== categoryId));
    } else {
      // If it's not, add it
      setCheckedValues(prev => [...prev, categoryId]);
    }
  };
  const handleApplyFilter = () => {
    console.log(checkedValues)
    setVendors(prevState => ({
      ...prevState,
      loading: true,
      data: []
  }))
    axios
      .post("/api/vendor", {
        service_category_id: allValues ? [] : checkedValues,
      })
      .then((res) => {
        console.log("vendors", res.data);
        if (!res.data?.result?.success) {
          toast.error("Error while fetching vendors!");
          return;
        }
        setVendors((prevState) => ({
          ...prevState,
          loading: false,
          data: res.data?.result?.vendors.filter(vendor => vendor.services && vendor.services.length > 0) ?? [],
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  }
  const resetFilter = () => {
    setCheckedValues([]);
    setAllValues(false)
    setVendors((prevState) => ({
      ...prevState,
      loading: true,
      data: []
    }));
    axios
      .post("/api/vendor", {
        service_category_id: [],
      })
      .then((res) => {
        console.log("vendors", res.data);
        if (!res.data?.result?.success) {
          toast.error("Error while fetching vendors!");
          return;
        }
        setVendors((prevState) => ({
          ...prevState,
          loading: false,
          data: res.data?.result?.vendors.filter((vendor) => vendor.services && vendor.services.length > 0) ?? [],
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className="relative">
        <img className="w-full" src={serv} alt="home" />
        <div className="absolute top-[20%] p-5 lg:top-[10%] w-full flex flex-col items-center gap-4">
          <p className="hidden lg:block text-center text-[#FAF5EE] text-lg sm:text-3xl md:text-5xl font-bold ">
            {t("Find top-rated vendors")}
          </p>
          <p className="hidden lg:block text-orange-50 text-base font-normal leading-normal">
            {t("Designing Settings for Lifelong Memories")}
          </p>
        </div>
        <div className="flex justify-center mt-8 mb-3 mx-3">
          <form
            className="w-full px-3 pt-3 pb-2 xl:px-6 xl:pt-6 xl:pb-2 bg-white rounded-[10px] shadow border gap-3 flex flex-wrap sm:flex-nowrap items-start justify-center"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="grid gap-3 grid-cols-2 sm:grid-cols-4 w-full">
              {categories.loading && <Loader slim />}
              {!categories.loading && (
                <Controller
                  control={control}
                  name="category"
                  render={({ field, field: { name, ref } }) => {
                    return (
                      <Select
                        placeholder={t("Category ")}
                        classNames={{
                          input: () => "[&_input:focus]:ring-0",
                          menu: () => menuStyle,
                          option: ({ isFocused, isSelected }) =>
                            clsx(
                              isFocused && optionStyles.focus,
                              isSelected && optionStyles.Selected
                            ),
                          control: () => baseStyle,
                        }}
                        unstyled
                        isClearable
                        isSearchable
                        options={categories.data.map((category) => ({
                          value: category.id,
                          label: category.name,
                        }))}
                        name={name}
                        ref={ref}
                        {...field}
                      />
                    );
                  }}
                />
              )}

              {countries.loading && <Loader slim />}
              {!countries.loading && (
                <Controller
                  control={control}
                  name="country"
                  render={({ field, field: { name, ref } }) => {
                    return (
                      <Select
                        placeholder={t("Country")}
                        classNames={{
                          input: () => "[&_input:focus]:ring-0",
                          menu: () => menuStyle,
                          option: ({ isFocused, isSelected }) =>
                            clsx(
                              isFocused && optionStyles.focus,
                              isSelected && optionStyles.Selected
                            ),
                          control: () => baseStyle,
                        }}
                        unstyled
                        isClearable
                        isSearchable
                        options={countries.data.map((country) => ({
                          value: country.id,
                          label: country.name,
                        }))}
                        name={name}
                        ref={ref}
                        {...field}
                      />
                    );
                  }}
                />
              )}
              {states.loading && <Loader slim />}
              {!states.loading && (
                <Controller
                  control={control}
                  name="state"
                  render={({ field, field: { name, ref } }) => {
                    return (
                      <Select
                        placeholder={t("State")}
                        classNames={{
                          input: () => "[&_input:focus]:ring-0",
                          menu: () => menuStyle,
                          option: ({ isFocused, isSelected }) =>
                            clsx(
                              isFocused && optionStyles.focus,
                              isSelected && optionStyles.Selected
                            ),
                          control: () => baseStyle,
                        }}
                        unstyled
                        isClearable
                        isSearchable
                        options={states.data.map((category) => ({
                          value: category.id,
                          label: category.name,
                        }))}
                        name={name}
                        ref={ref}
                        {...field}
                      />
                    );
                  }}
                />
              )}
              {cities.loading && <Loader slim />}
              {!cities.loading && (
                <Controller
                  control={control}
                  name="city"
                  render={({ field, field: { name, ref } }) => {
                    return (
                      <Select
                        placeholder={t("City")}
                        classNames={{
                          input: () => "[&_input:focus]:ring-0",
                          menu: () => menuStyle,
                          option: ({ isFocused, isSelected }) =>
                            clsx(
                              isFocused && optionStyles.focus,
                              isSelected && optionStyles.Selected
                            ),
                          control: () => baseStyle,
                        }}
                        unstyled
                        isClearable
                        isSearchable
                        options={cities.data.map((category) => ({
                          value: category.id,
                          label: category.name,
                        }))}
                        name={name}
                        ref={ref}
                        {...field}
                      />
                    );
                  }}
                />
              )}
            </div>

            <button
              onClick={() => reset(defaultValues)}
              title="Reset"
              type="submit"
              className="h-12 px-[18px] py-4 rounded-[10px] border border-neutral-400 justify-center items-center gap-2.5 inline-flex"
            >
              <img src={resetSearch} alt="resetSearch" />
            </button>
            <div className="flex flex-col justify-center gap-2">
              <button
                type={"submit"}
                className="h-12 px-[18px] py-[6.40px] bg-red rounded-[10px] shadow border border-white justify-center items-center gap-2.5 inline-flex"
              >
                <span className="text-center text-white text-sm sm:text-base font-normal leading-normal flex gap-[10px] items-center whitespace-nowrap">
                  {t("Search Now")} <FaSearch />
                </span>
              </button>
            </div>
          </form>
        </div>
        {/* <div className="flex justify-center absolute top-2 sm:top-6 md:top-24 xl:top-40 w-full px-2 sm:px-10">
          <form
            className="w-full px-3 pt-3 pb-2 xl:px-6 xl:pt-4 xl:pb-4 bg-white rounded-[10px] shadow border gap-3 grid grid-cols-2 items-center justify-center "
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="grid gap-3 sm:grid-cols-2 md:grid-cols-4 w-full">
              {categories.loading && <Loader slim />}
              {!categories.loading && (
                <Controller
                  control={control}
                  name="category"
                  render={({ field, field: { name, ref } }) => {
                    return (
                      <Select
                        placeholder="Category "
                        classNames={{
                          input: () => "[&_input:focus]:ring-0",
                          menu: () => menuStyle,
                          option: ({ isFocused, isSelected }) =>
                            clsx(
                              isFocused && optionStyles.focus,
                              isSelected && optionStyles.Selected
                            ),
                          control: () => baseStyle,
                        }}
                        unstyled
                        isClearable
                        isSearchable
                        options={categories.data.map((category) => ({
                          value: category.id,
                          label: category.name,
                        }))}
                        name={name}
                        ref={ref}
                        {...field}
                      />
                    );
                  }}
                />
              )}
              <div>
              {countries.loading && <Loader slim />}
              {!countries.loading && (
                <Controller
                  control={control}
                  name="country"
                  render={({ field, field: { name, ref } }) => {
                    return (
                      <Select
                        placeholder="Country"
                        classNames={{
                          input: () => "[&_input:focus]:ring-0",
                          menu: () => menuStyle,
                          option: ({ isFocused, isSelected }) =>
                            clsx(
                              isFocused && optionStyles.focus,
                              isSelected && optionStyles.Selected
                            ),
                          control: () => baseStyle,
                        }}
                        unstyled
                        isClearable
                        isSearchable
                        options={countries.data.map((country) => ({
                          value: country.id,
                          label: country.name,
                        }))}
                        name={name}
                        ref={ref}
                        {...field}
                      />
                    );
                  }}
                />
              )}
              </div>
              <div>
              {states.loading && <Loader slim />}
              {!states.loading && (
                <Controller
                  control={control}
                  name="state"
                  render={({ field, field: { name, ref } }) => {
                    return (
                      <Select
                        placeholder="State"
                        classNames={{
                          input: () => "[&_input:focus]:ring-0",
                          menu: () => menuStyle,
                          option: ({ isFocused, isSelected }) =>
                            clsx(
                              isFocused && optionStyles.focus,
                              isSelected && optionStyles.Selected
                            ),
                          control: () => baseStyle,
                        }}
                        unstyled
                        isClearable
                        isSearchable
                        options={states.data.map((category) => ({
                          value: category.id,
                          label: category.name,
                        }))}
                        name={name}
                        ref={ref}
                        {...field}
                      />
                    );
                  }}
                />
              )}
              </div>
              <div>
              {cities.loading && <Loader slim />}
              {!cities.loading && (
                <Controller
                  control={control}
                  name="city"
                  render={({ field, field: { name, ref } }) => {
                    return (
                      <Select
                        placeholder="City"
                        classNames={{
                          input: () => "[&_input:focus]:ring-0",
                          menu: () => menuStyle,
                          option: ({ isFocused, isSelected }) =>
                            clsx(
                              isFocused && optionStyles.focus,
                              isSelected && optionStyles.Selected
                            ),
                          control: () => baseStyle,
                        }}
                        unstyled
                        isClearable
                        isSearchable
                        options={cities.data.map((category) => ({
                          value: category.id,
                          label: category.name,
                        }))}
                        name={name}
                        ref={ref}
                        {...field}
                      />
                    );
                  }}
                />
              )}
              </div>
            </div>
             <div>
            <button
              onClick={() => reset(defaultValues)}
              title="Reset"
              type="submit"
              className="h-12 px-[18px] py-4 rounded-[10px] border border-neutral-400 justify-center items-center gap-2.5 inline-flex"
            >
              <img src={resetSearch} alt="resetSearch" />
            </button>
            <div className="flex flex-col justify-center gap-2">
              <button
                type="submit"
                className="h-12 px-[18px] py-[6.40px] bg-red rounded-[10px] shadow border border-white justify-center items-center gap-2.5 inline-flex"
              >
                <span className="text-center text-white text-sm sm:text-base font-normal leading-normal flex gap-[10px] items-center whitespace-nowrap">
                  Search Now <FaSearch />
                </span>
              </button>
            </div>
            </div>
          </form>
        </div> */}
      </div>

      <section className="px-5 xl:px-10 py-12 mt-[20px] sm:mt-20 md:mt-0">
        <div className="flex gap-10 relative mb-10">
          <div
            className={`${
              openNav
                ? "-translate-x-0"
                : i18n.language === "en"
                ? " -translate-x-[120%] "
                : "translate-x-[120%]"
            } 
            lg:hidden absolute -top-12 ${
              i18n.language === "en" ? "left-0" : "right-0"
            }  z-40 h-full overflow-y-auto transition-transform bg-white p-4 border-e`}
          >
            <aside className="">
              <div className="flex justify-end">
                <IconButton
                  variant="text"
                  className="h-6 w-6 hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
                  ripple={false}
                  onClick={() => setOpenNav(!openNav)}
                >
                  {openNav ? (
                    <FontAwesomeIcon icon={faClose} size={"xl"} />
                  ) : (
                    <FontAwesomeIcon icon={faBars} size={"xl"} />
                  )}
                </IconButton>
              </div>
              <List
                className="flex flex-col gap-4 !mb-16 !pt-4 dropdown-filter"
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "background.paper",
                }}
                component="nav"
                aria-labelledby="nested-list-subheader"
              >
                <ListItemButton onClick={() => handleClick("size")}>
                  <ListItemText
                    className="text-gray-dark"
                    primary={
                      <Typography className="text-gray-dark !text-base !font-medium !leading-none ">
                        {t("All Categories")}
                      </Typography>
                    }
                  />
                  {open.size ? <FaAngleUp /> : <FaAngleDown />}
                </ListItemButton>
                <Collapse in={open.size} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <FormGroup className="mx-8">
                      {categories.data.map((category) => (
                        <FormControlLabel
                          key={category.id}
                          control={
                            <Checkbox
                              checked={checkedValues.includes(category.id)} // Check if the category is checked
                              onChange={() => handleCheckboxToggle(category.id)} // Toggle the checkbox
                            />
                          }
                          label={category.name}
                        />
                      ))}
                    </FormGroup>
                  </List>
                </Collapse>
                {/* <ListItemButton onClick={() => handleClick("color")}>
                  <ListItemText
                    className="text-gray-dark"
                    primary={
                      <Typography className="text-gray-dark !text-base !font-medium !leading-none">
                        Country
                      </Typography>
                    }
                  />
                  {open.color ? <FaAngleUp /> : <FaAngleDown />}
                </ListItemButton>
                <Collapse in={open.color} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <FormGroup className="mx-8">
                      {countries.data.map((country) => (
                        <FormControlLabel
                          control={<Checkbox />}
                          label={country.name}
                          value={country.id}
                        />
                      ))}
                    </FormGroup>
                  </List>
                </Collapse>
                <ListItemButton onClick={() => handleClick("brand")}>
                  <ListItemText
                    className="text-gray-dark"
                    primary={
                      <Typography className="text-gray-dark !text-base !font-medium !leading-none">
                        State
                      </Typography>
                    }
                  />
                  {open.brand ? <FaAngleUp /> : <FaAngleDown />}
                </ListItemButton>
                <Collapse in={open.brand} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <FormGroup className="mx-8">
                      {states.data.map((state) => (
                        <FormControlLabel
                          control={<Checkbox />}
                          label={state.name}
                          value={state.id}
                        />
                      ))}
                    </FormGroup>
                  </List>
                </Collapse>
                <ListItemButton onClick={() => handleClick("stock")}>
                  <ListItemText
                    className="text-gray-dark"
                    primary={
                      <Typography className="text-gray-dark !text-base !font-medium !leading-none">
                        City
                      </Typography>
                    }
                  />
                  {open.stock ? <FaAngleUp /> : <FaAngleDown />}
                </ListItemButton>
                <Collapse in={open.stock} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <FormGroup className="mx-8">
                      {cities.data.map((city) => (
                        <FormControlLabel
                          control={<Checkbox />}
                          label={city.name}
                          value={city.id}
                        />
                      ))}
                    </FormGroup>
                  </List>
                </Collapse> */}
              </List>
              <div className="flex flex-col gap-2">
                <button
                  onClick={() => handleApplyFilter()}
                  className="w-full px-[22px] py-3 bg-red rounded-md border justify-center items-center gap-1 inline-flex"
                >
                  <span className="text-center text-white text-base font-semibold leading-snug">
                    {t("Apply Filter")}
                  </span>
                  {i18n.language === "en" ? (
                    <FontAwesomeIcon
                      className="text-white pr-5 xl:pr-8"
                      icon={faArrowRight}
                    />
                  ) : (
                    <FontAwesomeIcon
                      className="text-white pl-5 xl:pl-8"
                      icon={faArrowLeft}
                    />
                  )}
                </button>
                <button
                  onClick={resetFilter}
                  type="reset"
                  className="w-full px-[22px] py-3 justify-center items-center gap-2 inline-flex"
                >
                  <IoReload className="text-center text-zinc text-lg font-semibold leading-snug" />
                  <span className="text-center text-zinc text-base font-semibold leading-snug">
                    {t("Reset Filters")}
                  </span>
                </button>
              </div>
            </aside>
          </div>
          <aside className="w-[310px] hidden lg:block">
            <List
              className="flex flex-col gap-4 !mb-16 !pt-4 dropdown-filter"
              sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
              component="nav"
              aria-labelledby="nested-list-subheader"
            >
              <ListItemButton className='!text-start' onClick={() => handleClick("size")}>
                <ListItemText
                  className="text-gray-dark"
                  primary={
                    <Typography className="text-gray-dark !text-base !font-medium !leading-none ">
                      {t("All Categories")}
                    </Typography>
                  }
                />
                {open.size ? <FaAngleUp /> : <FaAngleDown />}
              </ListItemButton>
              <Collapse in={open.size} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <FormGroup className="mx-8">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={allValues}
                          onChange={()=> setAllValues(prev => !prev)}
                        />
                      }
                      label={t("All")}
                    />
                    {categories.data.map((category) => (
                      <FormControlLabel
                        key={category.id}
                        control={
                          <Checkbox
                            checked={checkedValues.includes(category.id)} // Check if the category is checked
                            onChange={() => handleCheckboxToggle(category.id)} // Toggle the checkbox
                          />
                        }
                        label={category.name}
                      />
                    ))}
                  </FormGroup>
                </List>
              </Collapse>
              {/* <ListItemButton onClick={() => handleClick("color")}>
                <ListItemText
                  className="text-gray-dark"
                  primary={
                    <Typography className="text-gray-dark !text-base !font-medium !leading-none">
                      Country
                    </Typography>
                  }
                />
                {open.color ? <FaAngleUp /> : <FaAngleDown />}
              </ListItemButton>
              <Collapse in={open.color} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <FormGroup className="mx-8">
                  {countries.data.map((country)=> (
                      <FormControlLabel control={<Checkbox />} label={country.name} value={country.id} />
                    ))}
                  </FormGroup>
                </List>
              </Collapse>
              <ListItemButton onClick={() => handleClick("brand")}>
                <ListItemText
                  className="text-gray-dark"
                  primary={
                    <Typography className="text-gray-dark !text-base !font-medium !leading-none">
                      State
                    </Typography>
                  }
                />
                {open.brand ? <FaAngleUp /> : <FaAngleDown />}
              </ListItemButton>
              <Collapse in={open.brand} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <FormGroup className="mx-8">
                  {states.data.map((state)=> (
                      <FormControlLabel control={<Checkbox />} label={state.name} value={state.id} />
                    ))}
                  </FormGroup>
                </List>
              </Collapse>
              <ListItemButton onClick={() => handleClick("stock")}>
                <ListItemText
                  className="text-gray-dark"
                  primary={
                    <Typography className="text-gray-dark !text-base !font-medium !leading-none">
                      City
                    </Typography>
                  }
                />
                {open.stock ? <FaAngleUp /> : <FaAngleDown />}
              </ListItemButton>
              <Collapse in={open.stock} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <FormGroup className="mx-8">
                  {cities.data.map((city)=> (
                      <FormControlLabel control={<Checkbox />} label={city.name} value={city.id} />
                    ))}
                  </FormGroup>
                </List>
              </Collapse> */}
            </List>
            <div className="flex flex-col gap-2">
              <button
                onClick={() => handleApplyFilter()}
                className="w-full px-[22px] py-3 bg-red rounded-md border justify-center items-center gap-1 inline-flex"
              >
                <span className="text-center text-white text-base font-semibold leading-snug">
                  {t("Apply Filter")}
                </span>
                {i18n.language === "en" ? (
                  <FontAwesomeIcon
                    className="text-white pr-5 xl:pr-8"
                    icon={faArrowRight}
                  />
                ) : (
                  <FontAwesomeIcon
                    className="text-white pl-5 xl:pl-8"
                    icon={faArrowLeft}
                  />
                )}
              </button>
              <button
                onClick={resetFilter}
                type="reset"
                className="w-full px-[22px] py-3 justify-center items-center gap-2 inline-flex"
              >
                <IoReload className="text-center text-zinc text-lg font-semibold leading-snug" />
                <span className="text-center text-zinc text-base font-semibold leading-snug">
                  {t("Reset Filters")}
                </span>
              </button>
            </div>
          </aside>

          <div className="grow w-1">
            <div className="flex justify-between lg:justify-end items-center mb-6">
              <IconButton
                variant="text"
                className="h-6 w-6 hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
                ripple={false}
                onClick={() => setOpenNav(!openNav)}
              >
                {openNav ? (
                  <FontAwesomeIcon icon={faClose} size={"xl"} />
                ) : (
                  <FontAwesomeIcon icon={faBars} size={"xl"} />
                )}
              </IconButton>
              <div className="gap-4 items-center flex">
                <span className="text-zinc text-base font-normal leading-none tracking-wide">
                  {t("View")}
                </span>
                <TfiViewListAlt
                  onClick={() => viewHandleClick("row")}
                  className={
                    view === "grid"
                      ? "text-gray-dark cursor-pointer"
                      : "text-red cursor-pointer"
                  }
                  size={"20px"}
                />
                <HiOutlineViewGrid
                  onClick={() => viewHandleClick("grid")}
                  className={
                    view === "grid"
                      ? "text-red cursor-pointer"
                      : "text-gray-dark cursor-pointer"
                  }
                  size={"28px"}
                />
                <select
                  className="px-3 py-2 rounded-lg border border-[#8F6D6D] justify-start items-center gap-2 inline-flex"
                  onChange={handleSelectChange}
                  value={displayCount}
                >
                  <option value="3">3</option>
                  <option value="6">6</option>
                  <option value="9">9</option>
                  <option value="12">12</option>
                </select>
              </div>
            </div>

            {vendors.loading && <Loader />}
            <div
              className={
                view === ViewStyle.Grid
                  ? "grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-x-6 gap-y-12"
                  : "w-full grid grid-cols-1 gap-y-10"
              }
            >
              {currentPageData.map((vendor) => (
                <ServiceCard
                  key={vendor.id}
                  vendor={vendor}
                  navigate={navigate}
                  view={view}
                  cartItems={cartItems}
                />
              ))}
            </div>
            {!vendors.loading && vendors.data.length < 1 && (
              <Info msg={"No Found services!"} />
            )}
          </div>
        </div>
        <ReactPaginate
          breakLabel="..."
          nextLabel={t("Next >")}
          onPageChange={handlePageChange}
          pageCount={pageCount}
          previousLabel={t("< Previous")}
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          pageLinkClassName="page-num"
          previousLinkClassName="page-btn"
          nextLinkClassName="page-btn"
          activeLinkClassName="active"
          disabledLinkClassName="disabled-btn"
        />
      </section>
    </>
  );
}

export default Services