import {toast, ToastContainer} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {Helmet} from "react-helmet";
import {Route, Routes} from "react-router-dom";
import {Suspense, useEffect, useRef} from "react";
import LoaderOverlay from "./components/LoaderOverlay";
import Loader from "./components/Loader";
import DefaultLayout from "./layouts/DefaultLayout";
import {useTranslation} from "react-i18next"; 
import StyleSelector from "./css/selector/StyleSelector";
import {initiatePartner} from "./store/dispatches/partner";
import {authActions} from "./store/slicer/auth";
import {routes} from "./routes";
import Error from "./views/Error";
import UserLayout from "./layouts/UserLayout";
import axios from "axios";
import useAxiosUserSecureConfig from "./hooks/useAxiosUserSecureConfig";
import { cartActions } from "./store/slicer/cartSlice";
import { wishListActions } from "./store/slicer/wishListSlice";
import {uiActions} from "./store/slicer/ui";

export const getMessages = (dispatch, config, i18n, messages) => {
  if (localStorage.getItem("messages")) {
    dispatch(uiActions.setMessages({loading: false, items: JSON.parse(localStorage.getItem("messages")) ,unread_count : 0 }))
  }

  const headers = {
    ...config.headers,
    'Accept-Language': i18n.language === "en" ? 'en' : 'ar',
};
  axios.post("/api/notifications", {}, { ...config, headers })
      .then((res) => {
        const newMessages = res.data.result.notifications.filter((message) => {
          // console.log(message, messages.items, !messages.items.find(m => m.id === message.id));
          // return !messages.items.find(m => m.id === message.id)
          if (JSON.parse(localStorage.getItem("messages"))) {
            return !JSON.parse(localStorage.getItem("messages")).find(m => m.id === message.id)
          }
        });
        // console.log("from server | stored | new", res.data.result.notifications, messages.items, newMessages, );

        // for (const message of newMessages) {
        //   toast.info(message.message, {
        //     autoClose: 2000,
        //     closeOnClick: true,
        //     // rtl: false,
        //     pauseOnFocusLoss: false,
        //     draggable: true,
        //     pauseOnHover: true,
        //     theme: "light"
        //   });
        // }

        dispatch(uiActions.setMessages({loading: false, items: res.data.result.notifications , unread_count : res.data.result.unread_count }))
      })
      .catch((err) => {
        console.log(err);
      })
};

function App() {
  // const dispatch = useDispatch()
  const loading = useSelector(state => state.ui.isLoading)
  const user = useSelector(state => state.auth.user)
  const messages = useSelector(state => state.ui.messages)
  const config = useAxiosUserSecureConfig();
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const effect = useRef(false);

  useEffect(() => {
    if (!effect.current) {
      dispatch(authActions.loadFromLocalStorage());
      dispatch(uiActions.loadMessagesFromLocalStorage());
    }


    return () => {
      effect.current = true;
    };
  }, []);

  useEffect(() => {
    if (user) {
      dispatch(cartActions.setLoading(true));

      axios.post("/api/cart", {}, config)
          .then((res) => {
            console.log("cart", res);
            dispatch(cartActions.setCart(res.data.result));
            dispatch(cartActions.setLoading(false));
          })

      dispatch(wishListActions.setLoading(true));

      axios.post("/api/wishlist/venue", {}, config)
          .then((res) => {
            console.log("venuewishlist", res);
            dispatch(wishListActions.setVenuesWishList(res.data.result));
            dispatch(wishListActions.setLoading(false));
          })
      axios.post("/api/wishlist/product", {}, config)
          .then((res) => {
            console.log("venuewishlist", res);
            dispatch(wishListActions.setProductsWishList(res.data.result));
            dispatch(wishListActions.setLoading(false));
          })

      getMessages(dispatch, config, i18n, messages);

      const interval = setInterval(() => getMessages(dispatch, config, i18n, messages), 10000);

      return () => {
        clearInterval(interval);
      }
    }
  }, [user]);

  const Layout = user ? UserLayout : DefaultLayout;

  return (
      <StyleSelector language={i18n.language}>
        {/* <div className={"flex flex-col min-h-screen"}> */}
          <Helmet>
            <html lang={i18n.language} dir={i18n.language === 'ar' ? "rtl" : "ltr"}/>
          </Helmet>

          <LoaderOverlay loading={loading} />

          <Suspense fallback={<Loader />}>
            <Routes>
              { routes.map((route, key) => {
                // noinspection JSXNamespaceValidation,JSXUnresolvedComponent
                return (
                    route.element && (
                        <Route
                            key={key}
                            path={route.path}
                            element={route.layout ?
                                <route.layout> <route.element /> </route.layout> : <Layout> <route.element /> </Layout>
                            }
                        />
                    )
                )
              }) }

              <Route path={"*"} element={<Error />} />
            </Routes>
          </Suspense>
           
          <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light" />
             
        {/* </div> */}
      </StyleSelector>
  );
}

export default App;
