import React, { useEffect, useState } from "react";
import { AiFillStar } from "react-icons/ai";
import Loader from "../../components/Loader";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import useAxiosUserSecureConfig from "../../hooks/useAxiosUserSecureConfig";
import BookingCancellation from "../../components/venues/BookingCancellation";
import placholder_image from "../../assets/images/booking/placeholder-image.jpg"
import ConfirmationModal from "../../components/ConfirmationModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import ServicePaymentRequest from "../../components/services/ServicePaymentRequest";

function MyServices() { 

  const config = useAxiosUserSecureConfig();
  const navigate = useNavigate();
  const [services, setServices] = useState({ loading: true, data: [] });
  const [vendor , setVendor] = useState({ loading: true, data: [] });
  const {t ,i18n } = useTranslation();
  const fetchData = () => {
    axios.post('/api/my-service-bookings', {}, config)
      .then((res) => {
        console.log("services", res.data);
        if (!res.data?.result?.success) {
          toast.error("Error while fetching bookings!");
          return;
        }
        const bookings = res.data?.result?.bookings?.slice().reverse();
        setServices((prevState) => ({
          ...prevState,
          loading : false,
          data: bookings
        }));
        bookings.forEach((booking, index) => {
          const vendorId = booking.vendor_id;
          axios.post(`/api/vendor/${vendorId}`, {} , config)
            .then((vendorRes) => {
              const vendorData = vendorRes.data?.result?.vendor;
              setServices((prevServices) => {
                const updatedServices = [...prevServices.data]; 
                updatedServices[index] = { ...booking, vendor: vendorData }; 
                return { ...prevServices, loading: false, data: updatedServices };
              });
            })
            .catch((error) => {
              console.error(`Error fetching vendor data for vendor ID ${vendorId}:`, error);
            });
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  useEffect(() => {
    fetchData()
  }, []);

  const [currentItems , setCurrentItems] = useState(services.data)
  const [pageCount , setPageCount] = useState(0)
  const [itemOffset , setItemOffset] = useState(0)
  const [itemsPerPage , setItemsPerPage] = useState(5) ;

  useEffect(()=>{
    const endOffSet = itemOffset + itemsPerPage;
    setCurrentItems(services.data.slice(itemOffset , endOffSet));
    setPageCount(Math.ceil(services.data.length / itemsPerPage));
  },[itemOffset ,itemsPerPage , services.data])

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % services.data.length;
    setItemOffset(newOffset);
  };
  const [activeState, setActiveState] = useState(null);
  const handleListFiltration = (state)=>{
    setActiveState(state);
    setServices((prevState) => ({
      ...prevState,
      loading: true,
    }));
    axios.post('/api/my-service-bookings', {}, config)
      .then((res) => {
        console.log("services", res.data);
        if (!res.data?.result?.success) {
          toast.error(t("Error while fetching bookings!"));
          return;
        }
        let bookings = res.data?.result?.bookings?.slice().reverse();
        if (state) {
          bookings = bookings.filter(item => item.state === state);
        }
        setServices((prevState) => ({
          ...prevState,
          loading : false,
          data: bookings
        }));
        bookings.forEach((booking, index) => {
          const vendorId = booking.vendor_id;
          axios.post(`/api/vendor/${vendorId}`, {} , config)
            .then((vendorRes) => {
              const vendorData = vendorRes.data?.result?.vendor;
              setServices((prevServices) => {
                const updatedServices = [...prevServices.data]; 
                updatedServices[index] = { ...booking, vendor: vendorData }; 
                return { ...prevServices, loading: false, data: updatedServices };
              });
              setCurrentItems(services.data)
            })
            .catch((error) => {
              console.error(`Error fetching vendor data for vendor ID ${vendorId}:`, error);
            });
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  const [open, setOpen] =useState(false);
  const handleClose = () => setOpen(false); 
  const [servId , setServId] = useState()
  const handleOpenModal = (id) => {
    setOpen(true),
    setServId(id)
  }
  const handleCancelService = () => {
    axios.post(`api/user/service-booking/${servId}/cancel` ,{}, config)
      .then((res) => {
        if (!res.data.result?.success) {
          toast.error(res.data.result?.message || "Request failed!");
          setOpen(false)
          return;
        }
        toast.success(t("You cancel service booking successfully!"));
        setOpen(false)
        fetchData()
      })
      .catch((err) => {
        console.log("err" , err)
        toast.error(t("Error while canceling service booking!"));
        setOpen(false)
      })
  }

  const isOneDayLater = (serviceDate) => {
    const oneDayInMs = 24 * 60 * 60 * 1000;
    const today = new Date();
    const serviceDateObj = new Date(serviceDate);
  
    return (today - serviceDateObj) >= oneDayInMs;
  };  
  const [openPayment, setOpenPayment] =useState(false);
  const handleClosePayment = () => setOpenPayment(false);
  const handlePaymentOpen = (e , id) =>{ 
    // setItemId(id)
    e.stopPropagation();
    setOpenPayment(true);
  }

  return (
    <section className="xl:px-10 px-5 py-12">
      {services.loading && <Loader slim />}
      {!services.loading && (
        <>
          <ServicePaymentRequest
            open={openPayment}
            handleClose={handleClosePayment}
            setBookingItem={setServices}
          />
          <ConfirmationModal
            open={open}
            handleClose={handleClose}
            handleAction={handleCancelService}
            msg={"Are you sure you want to cancel service booking?"}
          />
          <div className="justify-start items-center gap-2  hidden sm:inline-flex mb-5">
            <div
              onClick={() => navigate("/user/account")}
              className="text-zinc-400 text-sm md:text-base font-normal md:leading-[48px] hover:text-gray-dark cursor-pointer"
            >
              {t("My Account")}
            </div>
            <FontAwesomeIcon
              className="text-zinc-400 text-sm md:text-base"
              icon={faAngleRight}
            />
            <div className="text-zinc-400 text-sm md:text-base font-normal md:leading-[48px] hover:text-gray-dark cursor-pointer">
              {t("My Service Bookings")}
            </div>
          </div>
          <div className="flex items-center justify-between mb-6 flex-wrap gap-[10px]">
            <div className="justify-start items-center gap-3 inline-flex">
              <p className="text-red text-xl md:text-2xl font-medium">
                {t("My Service Bookings")}
              </p>
              <div className="text-gray-500 text-base font-light">
                ({services.data.length})
              </div>
            </div>
            <div className="sm:justify-end items-start gap-2 flex flex-wrap">
              <button
                onClick={() => handleListFiltration("")}
                className={`px-2.5 py-[3px] rounded-[100px] border border-blue-800 flex-col justify-start items-start gap-2.5 inline-flex ${
                  activeState === "confirmed" ? "shadow-xl" : ""
                }`}
              >
                <div className="text-center text-blue-800 text-xs font-semibold font-['SF Pro Text'] leading-[18px]">
                  {t("All")}
                </div>
              </button>
              <button
                onClick={() => handleListFiltration("accepted")}
                className={`px-2.5 py-[3px] rounded-[100px] border border-[#1A7F37] flex-col justify-start items-start gap-2.5 inline-flex ${
                  activeState === "confirmed" ? "shadow-xl" : ""
                }`}
              >
                <div className="text-center text-[#1A7F37] text-xs font-semibold font-['SF Pro Text'] leading-[18px]">
                  {t("Accepted")}
                </div>
              </button>

              <button
                onClick={() => handleListFiltration("rejected")}
                className={`px-2.5 py-[3px] rounded-[100px] border border-[#CF222E] flex-col justify-start items-start gap-2.5 inline-flex ${
                  activeState === "rejected" ? "shadow-xl" : ""
                }`}
              >
                <div className="text-center text-[#CF222E] text-xs font-semibold font-['SF Pro Text'] leading-[18px]">
                  {t("Rejected")}
                </div>
              </button>
              <button
                onClick={() => handleListFiltration("new")}
                className={`px-2.5 py-[3px] rounded-[100px] border border-[#9A6700] flex-col justify-start items-start gap-2.5 inline-flex ${
                  activeState === "new" ? "shadow-xl" : ""
                }`}
              >
                <div className="text-center text-[#9A6700] text-xs font-semibold font-['SF Pro Text'] leading-[18px]">
                  {t("HOLD")}
                </div>
              </button>
              <button
                onClick={() => handleListFiltration("cancelled")}
                className={`px-2.5 py-[3px] rounded-[100px] border border-gray-dark flex-col justify-start items-start gap-2.5 inline-flex ${
                  activeState === "new" ? "shadow-xl" : ""
                }`}
              >
                <div className="text-center text-gray-dark text-xs font-semibold font-['SF Pro Text'] leading-[18px]">
                  {t("Cancelled")}
                </div>
              </button>
            </div>
          </div>
          <div className="flex flex-col gap-6 mb-12">
            {currentItems.map(
              (serv, index) =>
                serv.vendor && (
                  <div
                    key={index}
                    className="cursor-pointer p-6 bg-zinc-100 rounded-2xl justify-start items-start gap-8 inline-flex w-full"
                  >
                    <div className="justify-start items-start gap-8 flex w-full flex-wrap md:flex-nowrap">
                      <div className="rounded-xl shadow flex-col justify-start items-start gap-4 inline-flex w-60">
                        {serv.vendor && (
                          <img
                            className="w-full rounded-xl h-[180px] object-cover"
                            src={serv.vendor.image || placholder_image}
                            alt="booking-list"
                          />
                        )}
                      </div>
                      <div className="grow flex-col justify-start items-start gap-4 inline-flex">
                        <div className="self-stretch justify-start items-start flex flex-col gap-2">
                          <div className="flex-col justify-start items-start gap-4 inline-flex w-full">
                            <div className="self-stretch justify-between items-start inline-flex flex-wrap gap-2">
                              {serv.vendor && (
                                <p className="text-gray-dark lg:text-2xl text-xl font-medium uppercase">
                                  {serv.vendor.name}
                                </p>
                              )}
                              <div className="justify-start items-start flex">
                                {serv.state === "accepted" && (
                                  <div className="flex items-center gap-6">
                                    {isOneDayLater(serv.date) && (
                                      <div className="text-red text-sm font-medium leading-tight">
                                        <button
                                          onClick={() =>
                                            navigate(
                                              `/user/service-review/${serv.vendor_id}`,
                                              { state: { data: serv.id } }
                                            )
                                          }
                                        >
                                          {t("Review")}
                                        </button>
                                      </div>
                                    )}
                                    <div className="px-2.5 py-[3px] rounded-[100px] border border-[#1A7F37] flex-col justify-start items-start gap-2.5 inline-flex">
                                      <div className="text-center text-[#1A7F37] text-xs font-semibold font-['SF Pro Text'] leading-[18px]">
                                        {t("Accepted")}
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {serv.state === "new" && (
                                  <div className="px-2.5 py-[3px] rounded-[100px] border border-[#9A6700] flex-col justify-start items-start gap-2.5 inline-flex">
                                    <div className="text-center text-[#9A6700] text-xs font-semibold font-['SF Pro Text'] leading-[18px]">
                                      {t("Hold")}
                                    </div>
                                  </div>
                                )}
                                {serv.state === "cancelled" && (
                                  <div className="px-2.5 py-[3px] rounded-[100px] border border-gray-dark flex-col justify-start items-start gap-2.5 inline-flex">
                                    <div className="text-center text-gray-dark text-xs font-semibold font-['SF Pro Text'] leading-[18px]">
                                      {t("Cancelled")}
                                    </div>
                                  </div>
                                )}
                                {serv.state === "rejected" && (
                                  <div className="px-2.5 py-[3px] rounded-[100px] border border-[#CF222E] flex-col justify-start items-start gap-2.5 inline-flex">
                                    <div className="text-center text-[#CF222E] text-xs font-semibold font-['SF Pro Text'] leading-[18px]">
                                      {t("Rejected")}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="flex gap-2">
                            {serv.services.map((serv, index) => (
                              <span
                                key={index}
                                className="text-gray-dark text-base font-semibold leading-snug"
                              >
                                ({serv.name}){index < serv.services - 1 && ","}
                              </span>
                            ))}
                          </div>
                        </div>
                        <div className="self-stretch justify-between items-end gap-6 inline-flex flex-wrap">
                          <div className="flex-col justify-start items-start gap-1 inline-flex">
                            <div className="flex gap-2 items-center">
                              <span className="text-gray-dark text-base font-normal leading-snug">
                                {t("Total price")}
                              </span>
                              <span className="text-red lg:text-base font-bold leading-[33.60px]">
                                {serv.services.reduce(
                                  (total, serv) => total + serv.list_price,
                                  0
                                )}{" "}
                                EGP
                              </span>
                            </div>
                            <div className="flex gap-2 items-center">
                              <span className="text-gray-dark text-base font-normal leading-snug">
                                {t("Event date")} :
                              </span>
                              <span className="text-red lg:text-base font-bold leading-[33.60px]">
                                {serv.date}
                              </span>
                            </div>
                            <div className="flex gap-2 items-center">
                              <span className="text-gray-dark text-base font-normal leading-snug">
                                {t("Event time")} :
                              </span>
                              <span className="text-red lg:text-base font-bold leading-[33.60px]">
                                {serv.time}
                              </span>
                            </div>
                            <div className="flex gap-2 items-center">
                              <span className="text-gray-dark text-base font-normal leading-snug">
                                {t("Event address")} :
                              </span>
                              <span className="text-red lg:text-base font-bold leading-[33.60px]">
                                {serv.address}
                              </span>
                            </div>
                          </div>

                          {serv.state === "rejected" && (
                            <div className="flex-col justify-start items-start gap-1 inline-flex">
                              <div className="flex gap-2 items-center">
                                <span className="text-gray-dark text-base font-normal leading-snug">
                                  {t("Rejection reason")} :
                                </span>
                                <span className="text-red lg:text-base font-bold leading-[33.60px]">
                                  {serv.rejection_reason}
                                </span>
                              </div>
                              <div className="flex gap-2 items-center">
                                <span className="text-gray-dark text-base font-normal leading-snug">
                                  {t("Rejection date")} :
                                </span>
                                <span className="text-red lg:text-base font-bold leading-[33.60px]">
                                  {serv.rejected_date}
                                </span>
                              </div>
                            </div>
                          )}
                          {serv.state === "new" && (
                            <button
                              onClick={() => handleOpenModal(serv.id)}
                              className="px-8 py-[9px] rounded-[10px] shadow border border-red justify-center items-center gap-2.5 flex"
                            >
                              <span className="text-center text-red text-base font-normal font-['Inter'] leading-tight">
                                {t("Cancel")}
                              </span>
                            </button>
                          )}
                          {serv.state === "accepted" && (
                            <button
                              key={serv.id}
                              onClick={(e) => handlePaymentOpen(e, serv.id)}
                              className={`px-8 py-[9px] rounded-[10px] shadow border border-red justify-center items-center gap-2.5 flex`}
                            >
                              <span className="text-center text-red text-base font-normal font-['Inter'] leading-tight">
                                {t("Make Payment")}
                              </span>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )
            )}
          </div>

          <ReactPaginate
            breakLabel="..."
            nextLabel={t("Next >")}
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            pageCount={pageCount}
            previousLabel={t("< Previous")}
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            pageLinkClassName="page-num"
            previousLinkClassName="page-btn"
            nextLinkClassName="page-btn"
            activeLinkClassName="active"
            disabledLinkClassName="disabled-btn"
          />
        </>
      )}
    </section>
  );
}

export default MyServices;
