import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Typography } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";
import error from "../Error";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import useAxiosUserSecureConfig from "../../hooks/useAxiosUserSecureConfig";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../store/slicer/auth";
import { Link, useNavigate } from "react-router-dom";

function ChangePassword() {
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [strength, setStrength] = useState(0);
  const [isValid, setIsValid] = useState(false);

  const handleChange = (event) => {
      setPassword(event.target.value);
    };
  
    const evaluatePasswordStrength = (password) => {
      let score = 0;
  
      // Minimum length (customize as needed)
      if (password.length >= 8) {
        score++;
      }
  
      // Character types
      if (/[a-z]/.test(password)) score++;
      if (/[A-Z]/.test(password)) score++;
      if (/[0-9]/.test(password)) score++;
      if (/[!@#$%^&*()_+\-=\[\]{};':",./<>?|\\ ]/.test(password)) score++;
  
      // Deductions for common patterns (optional)
      if (
        password
          .toLowerCase()
          .split("")
          .some((char) => password.includes(char.repeat(3)))
      ) {
        score--; // Repeated characters
      }
      if (password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^a-zA-Z\d]+$/)) {
        score--; // Predictable keyboard patterns
      }
  
      setStrength(score);
      setIsValid(score >= 4); // Set validity based on strength
     
      
    };
    const getStrengthText = (score) => {
      switch (score) {
        case 0:
        case 1:
          return t("Weak"); // Use translation key for "weak"
        case 2:
        case 3:
          return t("Normal"); // Use translation key for "normal"
        case 4:
          return t("Good"); // Use translation key for "good"
        default:
          return t("Very Strong"); // Handle potential future extensions (optional)
      }
    };
  
    const strengthColor = (score) => {
      switch (score) {
        case 0:
        case 1:
          return "text-red-50"; // Red for weak
        case 2:
        case 3:
          return "text-yellow-400"; // Yellow for normal
        default:
          return "text-green-700"; // Green for strong
      }
    };

    
  
    React.useEffect(() => {
    evaluatePasswordStrength(password);
  }, [password]);

  const { t, i18n } = useTranslation();


  const user = useSelector((state) => state.auth.user);
  const {
    register,
    watch,
    control,
    setError,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [changePassword, setChangePassword] = useState({
    loading: true,
    data: [],
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const config = useAxiosUserSecureConfig();


  function onSubmit(data) {
    console.log("data", data);
    data = {
      old_password: data.oldPassword,
      new_password: data.password,
    };

    axios
      .post("/api/change-password", data, config)
      .then((res) => {
        if (!res.data?.result?.success) {
          toast.error(res.data.result?.message || "Request Failed");
          return;
        }
         // Validate password strength
       if (!isValid) {
      setError("password", {
        type: "custom",
        message: t("Password strength should be at least Good"),
      });
      return;
    }
        toast.success("Password Changed Successufly");
      })
      .catch((error) => {
        toast.error("Error while change password");
      });
    dispatch(authActions.logout());
    navigate("/login");
  }
  console.log("user", user);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="px-5 xl:px-14 pt-6">
      {!user?.partner.name && (
        <div className="justify-start items-center gap-2  hidden sm:inline-flex mb-2">
          {user?.type === "user" && (
            <Link className=" py-2 flex" to={"/user/account"}>
              <span className="text-zinc-400 text-base font-normal leading-[20px] hover:text-gray-dark cursor-pointer">
                {t("My Account")}
              </span>
            </Link>
          )}
          {user?.type === "vendor" && user?.vendor_type === "venue" && (
            <Link className=" py-2 flex" to={"/vendor/account"}>
              <span className="text-zinc-400 text-base font-normal leading-[20px] hover:text-gray-dark cursor-pointer">
                {t("My Account")}
              </span>
            </Link>
          )}
          {user?.type === "vendor" && user?.vendor_type === "services" && (
            <Link className=" py-2 flex" to={"/ServiceProvider/account"}>
              <span className="text-zinc-400 md:text-lg text-sm font-normal leading-[20px] hover:text-gray-dark cursor-pointer">
                {t("Service Provider Account")}
              </span>
            </Link>
          )}
          {user?.type === "vendor" && user?.vendor_type === "supplier" && (
            <Link className=" py-2 flex" to={"/product-Supplier/Account"}>
              <span className="text-zinc-400 md:text-lg text-sm font-normal leading-[20px] hover:text-gray-dark cursor-pointer">
                {t("Product Supplier Account")}
              </span>
            </Link>
          )}
          <FontAwesomeIcon
            className="text-zinc-400 text-base"
            icon={faAngleRight}
          />
          <div
            onClick={() => navigate("/Change-Password")}
            className="text-zinc-400  font-normal md:text-lg text-sm leading-[20px] hover:text-gray-dark cursor-pointer"
          >
            {t("Change Password")}
          </div>
        </div>
      )}

      <div className="w-4/5 md:w-1/2 mx-auto mb-10 mt-4 self-stretch flex-col  gap-4 flex">
        <p className="text-lg text-red md:text-2xl font-semibold mb-8 leading-9">
          {t("Change Password")}
        </p>
        <div className="self-stretch flex-col justify-start items-start gap-4 flex">
          <label className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">
            {t("Old Password")}
          </label>
          <div className="relative w-full">
            <input
              error={!!errors.oldPassword}
              {...register("oldPassword", {
                required: t("Password is required"),
              })}
              type={showOldPassword ? "text" : "password"}
              className="w-full p-3 bg-slate-100 border-slate-100 rounded-lg "
              placeholder={t("Old Password")}
            />
            {showOldPassword ? (
              <FontAwesomeIcon
                icon={faEyeSlash}
                onClick={() => setShowOldPassword((prevState) => !prevState)}
                className={`absolute ${i18n.language === 'en' ? 'right-3' : 'left-3'} top-4 text-zinc cursor-pointer`}
              />
            ) : (
              <FontAwesomeIcon
                icon={faEye}
                onClick={() => setShowOldPassword((prevState) => !prevState)}
                className={`absolute ${i18n.language === 'en' ? 'right-3' : 'left-3'} top-4 text-zinc cursor-pointer`}
              />
            )}
          </div>
          {errors.oldPassword && (
            <Typography variant="small" color="red">
              {errors.oldPassword?.message}
            </Typography>
          )}
        </div>
        <div className="self-stretch flex-col justify-start items-start gap-4 flex">
          <label className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">
            {t("New Password")}
          </label>
          <div className="relative w-full">
            <input
              error={!!errors.password}
              {...register("password", {
                required: t("New Password is required"),
                validate: () =>
                isValid || t("Password should be at least Good"),
              })}
              type={showNewPassword ? "text" : "password"}
              value={password}
              onChange={handleChange}
              className="w-full p-3 bg-slate-100  border-slate-100 rounded-lg "
              placeholder={t("New Password")}
            />
             {strength ==0 &&(
             <p className='text-zinc-500 text-xs font-normal leading-none mt-2'>
               {t("It must be a combination of minimum 8 letters, numbers and symbols.")}
               </p>)}
              { strength >= 1 &&(
              <p className="">
                {t("Password Strength")}:<span className={strengthColor(strength)}>  
                   {getStrengthText(strength)}
                    </span>
              </p>)}
            {showNewPassword ? (
              <FontAwesomeIcon
                icon={faEyeSlash}
                onClick={() => setShowNewPassword((prevState) => !prevState)}
                className={`absolute ${i18n.language === 'en' ? 'right-3' : 'left-3'} top-4 text-zinc cursor-pointer`}
              />
            ) : (
              <FontAwesomeIcon
                icon={faEye}
                onClick={() => setShowNewPassword((prevState) => !prevState)}
                className={`absolute ${i18n.language === 'en' ? 'right-3' : 'left-3'} top-4 text-zinc cursor-pointer`}
              />
            )}
          </div>
          {errors.password && (
            <Typography variant="small" color="red" position="left-3">
              {errors.password?.message}
            </Typography>
          )}
        </div>

        <div className="self-stretch flex-col justify-start items-start gap-2 flex">
          <label className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">
            {t("Confirm New Password")}
          </label>
          <div className="relative w-full">
            <input
              error={!!errors.passwordRepeat}
              {...register("passwordRepeat", {
                required: t("Password Confirmation is required"),
                validate: (value) =>
                  value === watch("password") ||
                  t("The passwords do not match"),
              })}
              type={showConfirmPassword ? "text" : "password"}
              className="w-full p-3 bg-slate-100 border-slate-100 rounded-lg "
              placeholder={t("New Password")}
            />
            {showConfirmPassword ? (
              <FontAwesomeIcon
                icon={faEyeSlash}
                onClick={() =>
                  setShowConfirmPassword((prevState) => !prevState)
                }
                className={`absolute ${i18n.language === 'en' ? 'right-3' : 'left-3'} top-4 text-zinc cursor-pointer`}
              />
            ) : (
              <FontAwesomeIcon
                icon={faEye}
                onClick={() =>
                  setShowConfirmPassword((prevState) => !prevState)
                }
                className={`absolute ${i18n.language === 'en' ? 'right-3' : 'left-3'} top-4 text-zinc cursor-pointer`}
              />
            )}
          </div>
          {errors.passwordRepeat && (
            <Typography variant="small" color="red">
              {errors.passwordRepeat?.message}
            </Typography>
          )}
        </div>
      </div>
      <div className="w-full flex justify-center items-center my-10 gap-2">
        <Link
          to={`${
            user?.vendor_type === "services"
              ? "/ServiceProvider/account"
              : user?.vendor_type === "venue"
              ? "/vendor/account"
              : user?.vendor_type === "supplier"
              ? "/product-Supplier/Account"
              : "/user/account"
          }`}
          className="w-1/3 md:w-1/6 px-3 py-4 text-center bg-white border border-red rounded-lg "
        >
          <button type="reset">
            <span className="text-red text-base font-medium leading-none tracking-wide">
              {t("Cancel")}
            </span>
          </button>
        </Link>
      
        <button
          type="submit"
          className="w-1/3 md:w-1/6 px-3 py-4 bg-red rounded-lg"
        
        >
          <span className="text-white text-base font-medium leading-none tracking-wide">
            {t("Save")}
          </span>
        </button>
      </div>
    </form>
  );
}

export default ChangePassword;
