import React, { useEffect, useState } from "react";
import bookingIcon from "../../assets/images/account/icons/booking.svg"
import settingsIcon from "../../assets/images/account/icons/settings.svg"
import resetpasswordIcon from "../../assets/images/account/icons/reset-password.svg"
import checkoutIcon from "../../assets/images/account/icons/check-out.svg"
import {authActions} from "../../store/slicer/auth";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import useAxiosUserSecureConfig from "../../hooks/useAxiosUserSecureConfig";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import orderIcon from "../../assets/images/account/icons/checklist.svg"
import wishlistIcon from "../../assets/images/account/icons/wish-list.svg"
import collaborationIcon from "../../assets/images/account/icons/collaboration.svg"
import ConfirmationModal from "../../components/ConfirmationModal";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash , faXmark} from "@fortawesome/free-solid-svg-icons";
import {Typography} from "@material-tailwind/react";
import {useForm} from "react-hook-form";


function VendorAccount() {

  
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const config = useAxiosUserSecureConfig();
  const [myFacilities ,setMyFacilities] = useState({ loading: true, data: [null] })
  const {t} = useTranslation();
  useEffect(() => {
    axios.post('/api/venue-vendor/my-facilities' , {} , config).then((res) => {
      console.log("my-facilities", res.data);
  
      if (!res.data?.result?.success) {
        toast.error("Error while fetching bookings!");
        return;
      }
  
      setMyFacilities((prevState) => ({
        ...prevState,
        loading: false,
        data: res.data?.result?.facilities.filter((key) => key.enabled_by_user === true)
      }));

    }).catch((err) => {
      console.log(err);
    })
    
  }, []); 
  const [open, setOpen] =useState(false);
  const handleClose = () => setOpen(false); 
  const handleLogout = () => {
    dispatch(authActions.logout());
    navigate('/');
  }

  const [showOldPassword , setShowOldPassword]= useState(false);
  const [showNewPassword , setShowNewPassword]= useState(false);
  const [showConfirmPassword , setShowConfirmPassword]= useState(false);


  const {
      register,
      watch,
      control,
      setError,
      setValue,
      formState: { errors },
      handleSubmit
  } = useForm();

  function onSubmit(data){
    console.log('data',data) ;
       data={
           old_password:data.oldPassword,
           new_password:data.newPassword,
       }

     axios.post('/api/change-password',data,config).then((res)=>{

         if (!res.data?.result?.success){ toast.error(res.data.result?.message || "Request Failed")

         }
         toast.success('Password Changed Successufly')

     })
     .catch((error)=>{toast.error('Error while change password')
   })
   dispatch(authActions.logout());
   navigate('/login');
 }

 const [changePassword , setChangePassword] = useState(false);
  const toggleMenu = ( ) => {
   
    setChangePassword(!changePassword);
   
  }
  const [services,setServices] = useState({ loading: true, data: [] })
  useEffect(() => {
    axios.post('/api/my_services', {}, config)
    .then((res) => {
      console.log("services", res.data);
      if (!res.data?.result?.success) {
        toast.error("Error while fetching services!");
        return;
      }
      setServices({
        loading: false,
        data: res.data?.result?.products.filter((key) => key.enabled === true)|| [],
      });
    })
    .catch((err) => {
      console.log(err);
    });
  }, []);

  return (
    <section className="px-10 py-12">
      <ConfirmationModal
      open={open}
      handleClose={handleClose}
      handleAction={handleLogout}
      msg={t("Are you sure you want to logout?")}
    />
    
      <p className="text-red text-2xl font-medium mb-6">{t("Venue Provider Account")}</p>
      <div className="py-6">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-6 gap-y-8">
          {myFacilities.loading && <Loader slim />}
          {!myFacilities.loading &&(<Link to={"/vendor/Facilities"} className="p-4 bg-zinc-100 rounded-[10px] border border-[#B3B3B3] justify-start items-start gap-2 inline-flex">
            <div className="flex items-center gap-4">
                <img src={bookingIcon} alt="bookingIcon"/>
                <div className="flex flex-col items-start gap-2">
                    <p className="text-red text-lg font-normal">{t("My Facilities")} ({myFacilities.data.length}) </p>
                    <p className="text-gray-500 text-sm font-light ">{t("See and manage all your Facilities.")}</p>
                </div>
            </div>
          </Link>)}
          {services.loading && <Loader slim/>}
          {!services.loading && <Link to={"/vendor/services"} className="p-4 bg-zinc-100 rounded-[10px] border border-[#B3B3B3] justify-start items-start gap-2 inline-flex">
            <div className="flex items-center gap-4">
                <img src={settingsIcon} alt="bookingIcon"/>
                <div className="flex flex-col items-start gap-2">
                    <p className="text-red text-lg font-normal">{t("My Services")} ({services.data.length})</p>
                    <p className="text-gray-500 text-sm font-light ">{t("See and manage all your Services.")}</p>
                </div>
            </div>
          </Link>}
          <Link to={"/vendor/cash-requests"} className="p-4 bg-zinc-100 rounded-[10px] border border-[#B3B3B3] justify-start items-start gap-2 inline-flex">
            <div className="flex items-center gap-4">
                <img src={orderIcon} alt="bookingIcon"/>
                <div className="flex flex-col items-start gap-2">
                    <p className="text-red text-lg font-normal">{t("Cash Requests")}</p>
                    <p className="text-gray-500 text-sm font-light ">{t("See and manage all your Cash Requests.")}</p>
                </div>
            </div>
          </Link>
          <Link to={"/vendor/booking-list"} className="p-4 bg-zinc-100 rounded-[10px] border border-[#B3B3B3] justify-start items-start gap-2 inline-flex">
            <div className="flex items-center gap-4">
                <img src={wishlistIcon} alt="bookingIcon"/>
                <div className="flex flex-col items-start gap-2">
                    <p className="text-red text-lg font-normal">{t("Booking list")}</p>
                    <p className="text-gray-500 text-sm font-light ">{t("See and manage all your Booking list.")}</p>
                </div>
            </div>
          </Link>
          <Link to={"/vendor/booking-requests"} className="p-4 bg-zinc-100 rounded-[10px] border border-[#B3B3B3] justify-start items-start gap-2 inline-flex">
            <div className="flex items-center gap-4">
                <img src={collaborationIcon} alt="bookingIcon"/>
                <div className="flex flex-col items-start gap-2">
                    <p className="text-red text-lg font-normal">{t("Booking requests")}</p>
                    <p className="text-gray-500 text-sm font-light ">{t("See and manage all your Booking requests.")}</p>
                </div>
            </div>
          </Link>
          <Link to={"/vendor/edit-account"} className="p-4 bg-zinc-100 rounded-[10px] border border-[#B3B3B3] justify-start items-start gap-2 inline-flex">
            <div className="flex items-center gap-4">
                <img src={settingsIcon} alt="bookingIcon"/>
                <div className="flex flex-col items-start gap-2">
                  <p className="text-red text-lg font-normal">{t("Edit account")}</p>
                    <p className="text-gray-500 text-sm font-light ">{t("Update your personal information and preferences.")}</p>
                </div>
            </div>
          </Link>
          <Link to={'/Change-Password'} className="p-4 bg-zinc-100 rounded-[10px] border border-[#B3B3B3] justify-start items-start gap-2 inline-flex">
            <div className="flex items-center gap-4">
                <img src={resetpasswordIcon} alt="bookingIcon"/> 
                <div className="flex flex-col items-start gap-2">
                    <p className="text-red text-lg font-normal">{t("Change Password")}</p>
                    <p className="text-gray-500 text-sm font-light ">{t("Ensure your account security by updating your password.")}</p>
                </div>
            </div>
          </Link> 
          <button onClick={() => {
              setOpen(true)
            }} className="p-4 bg-zinc-100 rounded-[10px] border border-[#B3B3B3] justify-start items-start gap-2 inline-flex">
            <div className="flex items-center gap-4">
                <img src={checkoutIcon} alt="bookingIcon"/>
                <div className="flex flex-col items-start gap-2">
                    <p className="text-red text-lg font-normal">{t("Logout")}</p>
                    <p className="text-gray-500 text-sm font-light ">{t("Sign out of your account.")}</p>
                </div>
            </div>
          </button>
          
        </div>
      </div>
    </section>
  );
}

export default VendorAccount;
