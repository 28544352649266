import React from "react";
import home1 from "../../assets/images/home/home.png";
import home2 from "../../assets/images/home/home-2.png";
import home3 from "../../assets/images/home/home-3.png";
import home4 from "../../assets/images/home/home-4.png";
import aboutus from "../../assets/images/home-image.png";
import video from "../../assets/images/Video.png";
import videobox from "../../assets/images/Video Box.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMoneyBill,
  faWheelchair,
  faSeedling,
  faHouse,
  faScaleBalanced,
  faCreditCard,
} from "@fortawesome/free-solid-svg-icons";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import choice from "../../assets/images/home/icons/choice 1.svg";
import event from "../../assets/images/home/icons/event 1.svg";
import list from "../../assets/images/home/icons/list 1.svg";
import time from "../../assets/images/home/icons/time-management 1.svg";
import gallery from "../../assets/images/home/icons/gallery.svg";
import price from "../../assets/images/home/icons/price.svg";
import {useTranslation} from "react-i18next";

function Home() {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Swiper
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={true}
        modules={[Autoplay, Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className="relative">
            <img className="w-full" src={home1} alt="home" />
            <div className="absolute bottom-[20%] p-3 w-full flex flex-col items-center gap-4">
              <p className="text-center text-[#FAF5EE] text-lg sm:text-3xl lg:text-5xl font-bold md:leading-[67.20px] ">
                {t("Designing Settings for Lifelong Memories")}
              </p>
              <p className="text-orange-50 hidden md:block text-base font-normal font-['Roboto'] leading-normal">
                {t("your ultimate destination for creating the wedding of your dreams!")}
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="relative">
            <img className="w-full" src={home2} alt="home" />
            <div className="absolute bottom-[20%] p-3 w-full flex flex-col items-center gap-4">
              <p className="text-center text-[#FAF5EE] text-lg sm:text-3xl lg:text-5xl font-bold md:leading-[67.20px] ">
                {t("Designing Settings for Lifelong Memories")}
              </p>
              <p className="text-orange-50 hidden md:block text-base font-normal font-['Roboto'] leading-normal">{t("your ultimate destination for creating the wedding of your dreams!")}</p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="relative">
            <img className="w-full" src={home3} alt="home" />
            <div className="absolute bottom-[20%] p-3 w-full flex flex-col items-center gap-4">
              <p className="text-center text-[#FAF5EE] text-lg sm:text-3xl lg:text-5xl font-bold md:leading-[67.20px] ">
                {t("Designing Settings for Lifelong Memories")}
              </p>
              <p className="text-orange-50 hidden md:block text-base font-normal font-['Roboto'] leading-normal">
                {t("your ultimate destination for creating the wedding of your dreams!")}
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="relative">
            <img className="w-full" src={home4} alt="home" />
            <div className="absolute bottom-[20%] p-3 w-full flex flex-col items-center gap-4">
              <p className="text-center text-[#FAF5EE] text-lg sm:text-3xl lg:text-5xl font-bold md:leading-[67.20px] ">
              {t("Designing Settings for Lifelong Memories")}
              </p>
              <p className="text-orange-50 hidden sm:block text-base font-normal font-['Roboto'] leading-normal">
              {t("your ultimate destination for creating the wedding of your dreams!")}
              </p>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
      <section className="px-5 xl:px-20 py-12">
        <div className="flex gap-[72px]">
          <img className="hidden xl:block" src={aboutus} alt="aboutus" />
          <div>
            <h2 className="text-2xl md:text-[40px] capitalize font-bold md:leading-[59px] text-gray-dark mb-6">
            {t("your ultimate destination for creating the wedding of your dreams!")}
            </h2>
            <p className="text-zinc text-lg font-normal leading-relaxed mb-8">
              {t("Our platform is dedicated to assisting couples in every step of their wedding journey, ensuring a seamless and unforgettable experience from 'Yes' to 'I do.'. ")}
            </p>
            <div className="flex items-center gap-6 justify-center flex-wrap lg:flex-nowrap">
              <img src={video} alt="video" />
              <div>
                <h4 className="text-gray-dark text-[25px] font-bold leading-[35px]">
                  {t("Best Choicing")}
                </h4>
                <p className="text-zinc text-lg font-normal leading-relaxed">
                  {t("We understand that planning a wedding can be both exciting and overwhelming.")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="px-5 xl:px-20 py-12 bg-[#EFEFEF] bg-serv-bg bg-cover">
        <div className="flex flex-col gap-6 items-center mb-[45px]">
          <h4 className="text-center text-zinc text-sm font-semibold uppercase leading-normal tracking-wide ">
            {t("What We Do")}
          </h4>
          <p className="lg:w-1/2 w-full text-center text-gray-dark text-2xl md:text-[40px] font-bold capitalize md:leading-[59px]">
           {t("What you'll find within our digital sanctuary")}
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-[30px] sm:mx-4">
        <div className="group transition-all duration-300  bg-white hover:bg-red rounded-tl-[220px] rounded-tr-[220px] px-[35px] pt-10 md:pt-[88px] pb-8 flex-col justify-start items-center gap-[25px] inline-flex">
            <div className="w-[75px] h-[75px] bg-red group-hover:bg-white group-hover:bg-opacity-60 rounded-[30px] justify-center items-center gap-2.5 inline-flex">
              <img src={gallery} alt="" />
            </div>
            <div className="flex-col justify-start items-center gap-2.5 inline-flex">
              <div className="self-stretch text-center text-gray-dark group-hover:text-white  text-2xl font-bold leading-[35px]">
              {t("Gallery")}
              </div>
              <div className="w-[290px] h-[104px] px-2 text-center text-zinc group-hover:text-white text-base font-normal leading-relaxed">
              {t("Showcase high-quality images of the wedding halls, providing users with a visual idea of what to expect.")}
              </div>
            </div>
          </div>
          <div className="group transition-all duration-300  bg-white hover:bg-red rounded-tl-[220px] rounded-tr-[220px] px-[35px] pt-10 md:pt-[88px] pb-8 flex-col justify-start items-center gap-[25px] inline-flex">
            <div className="w-[75px] h-[75px] bg-red group-hover:bg-white group-hover:bg-opacity-60 rounded-[30px] justify-center items-center gap-2.5 inline-flex">
              <img src={event} alt="" />
            </div>
            <div className="flex-col justify-start items-center gap-2.5 inline-flex">
              <div className="self-stretch text-center text-gray-dark group-hover:text-white  text-2xl font-bold leading-[35px]">
                {t("Event Planning Tools")}
              </div>
              <div className="w-[290px] h-[104px] px-2 text-center text-zinc group-hover:text-white text-base font-normal leading-relaxed">
                {t("Provide tools and resources for event planning, including checklists, timelines, and budget calculators.")}
              </div>
            </div>
          </div>
          <div className="group transition-all duration-300  bg-white hover:bg-red rounded-tl-[220px] rounded-tr-[220px] px-[35px] pt-10 md:pt-[88px] pb-8 flex-col justify-start items-center gap-[25px] inline-flex">
            <div className="w-[75px] h-[75px] bg-red group-hover:bg-white group-hover:bg-opacity-60 rounded-[30px] justify-center items-center gap-2.5 inline-flex">
              <img src={list} alt="" />
            </div>
            <div className="flex-col justify-start items-center gap-2.5 inline-flex">
              <div className="self-stretch text-center text-gray-dark group-hover:text-white  text-2xl font-bold leading-[35px]">
                {t("Venue Listings")}
              </div>
              <div className="w-[290px] h-[120px] px-2 text-center text-zinc group-hover:text-white text-base font-normal leading-relaxed">
                {t("Create profiles for various wedding venues, including details such as location, capacity, facilities, and pricing.")}
              </div>
            </div>
          </div>
          <div className="group transition-all duration-300  bg-white hover:bg-red rounded-tl-[220px] rounded-tr-[220px] px-[35px] pt-10 md:pt-[88px] pb-8 flex-col justify-start items-center gap-[25px] inline-flex">
            <div className="w-[75px] h-[75px] bg-red group-hover:bg-white group-hover:bg-opacity-60 rounded-[30px] justify-center items-center gap-2.5 inline-flex">
              <img src={choice} alt="" />
            </div>
            <div className="flex-col justify-start items-center gap-2.5 inline-flex">
              <div className="self-stretch text-center text-gray-dark group-hover:text-white  text-2xl font-bold leading-[35px]">
                {t("Conscious Choices")}
              </div>
              <div className="w-[290px] h-[104px] px-2 text-center text-zinc group-hover:text-white text-base font-normal leading-relaxed">
                {t("Emphasis on eco-friendly options and conscious choices, because creating magic should never come at the expense of our planet.")}
              </div>
            </div>
          </div>
          <div className="group transition-all duration-300  bg-white hover:bg-red rounded-tl-[220px] rounded-tr-[220px] px-[35px] pt-10 md:pt-[88px] pb-8 flex-col justify-start items-center gap-[25px] inline-flex">
            <div className="w-[75px] h-[75px] bg-red group-hover:bg-white group-hover:bg-opacity-60 rounded-[30px] justify-center items-center gap-2.5 inline-flex">
              <img src={time} alt="" />
            </div>
            <div className="flex-col justify-start items-center gap-2.5 inline-flex">
              <div className="self-stretch text-center text-gray-dark group-hover:text-white  text-2xl font-bold leading-[35px]">
                {t("Reservation System")}
              </div>
              <div className="w-[290px] h-[104px] px-2 text-center text-zinc group-hover:text-white text-base font-normal leading-relaxed">
                {t("Access to industry experts, trendsetters, and seasoned professionals who offer invaluable advice and inspiration.")}
              </div>
            </div>
          </div>
          <div className="group transition-all duration-300  bg-white hover:bg-red rounded-tl-[220px] rounded-tr-[220px] px-[35px] pt-10 md:pt-[88px] pb-8 flex-col justify-start items-center gap-[25px] inline-flex">
            <div className="w-[75px] h-[75px] bg-red group-hover:bg-white group-hover:bg-opacity-60 rounded-[30px] justify-center items-center gap-2.5 inline-flex">
              <img src={price} alt="" />
            </div>
            <div className="flex-col justify-start items-center gap-2.5 inline-flex">
              <div className="self-stretch text-center text-gray-dark group-hover:text-white  text-2xl font-bold leading-[35px]">
                {t("Best Price")}
              </div>
              <div className="w-[290px] h-[104px] px-2 text-center text-zinc group-hover:text-white text-base font-normal leading-relaxed">
                {t("Secure unbeatable rates with our best price guarantee. We ensure that you receive the most competitive prices available, providing exceptional value for your investment.")}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="px-5 xl:px-20 py-12">
        <img className="w-full" src={videobox} alt="video" />
        <p className="text-center text-zinc py-8 text-sm font-semibold uppercase leading-normal tracking-wide">
          {t("Why Choice Us")}
        </p>
        <div className="justify-start items-center gap-8 lg:gap-12 inline-flex flex-wrap">
          <div className="lg:w-1/2 flex-col justify-center items-start gap-5 inline-flex">
            <div className="text-gray-dark text-2xl md:text-[40px] font-bold capitalize md:leading-[59px]">
             {t("What sets us apart is our dedication to offering a diverse range of resources, including")}
            </div>
          </div>
          <div className="grow shrink basis-0 self-stretch justify-start items-center gap-[30px] flex">
            <div className="grow shrink basis-0 text-zinc text-base font-normal leading-relaxed">
             {t(" Our team comprises passionate wedding enthusiasts, experienced event planners, and industry experts committed to providing you with the guidance and support needed to navigate through every aspect of wedding planning. From choosing the perfect venue and selecting vendors to managing budgets and crafting personalized themes, we're here to make your wedding planning journey effortless and enjoyable.")}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Home;
