 import React, { useEffect, useState } from 'react';
import { useTable, useSortBy, usePagination } from 'react-table';
import ReactPaginate from 'react-paginate';
import useAxiosUserSecureConfig from '../../../hooks/useAxiosUserSecureConfig';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from "react-router-dom";
import invitationHeader from "../../../assets/images/invitation/invitationHeader.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import userAccept from "../../../assets/images/invitation/userAccept.png";
import userReject from "../../../assets/images/invitation/userReject.png";
import userPending from "../../../assets/images/invitation/userPending.png";
import Loader from '../../../components/Loader';
import {useTranslation} from "react-i18next";


function GuestsStatus () {
  const {t ,i18n } = useTranslation();
  const params = useParams();
  const config = useAxiosUserSecureConfig();
  const [guests, setGuests] = useState({ loading: true, data: [] });
  const columns = React.useMemo(
    () => [
      { Header: t('Guest name'), accessor: 'name' },
      { Header: t('Guest mobile number'), accessor: 'phone' },
      { Header: t('Classified'), accessor: 'classified' },
      { Header: t('Status'), accessor: 'state' }
    ],
    [t]
  );
  useEffect(() => {
    axios.post(`/api/invitation/${params.id}/guests`, {}, config)
    .then((res) => {
      console.log("guests", res.data);
      if (!res.data?.result?.success) {
        toast.error("Error while fetching guests!");
        return;
      }
      setGuests({
        loading: false,
        data: res.data?.result?.guests?.slice().reverse() || [],
      });
    })
    .catch((err) => {
      console.log(err);
    });
  }, []);

  console.log("guestsStatus" ,guests)
  const [currentPage, setCurrentPage] = useState(0);
  
  const {
    rows,
  } = useTable(
    {
      columns,
      data: guests.data,
      initialState: { pageIndex: currentPage, pageSize: 20 }, 
    },
    useSortBy,
    usePagination
  );

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const navigate = useNavigate()

  const [filter, setFilter] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);

  const handleFilterChange = (value) => {
    setFilter(value);
    setShowDropdown(false);
  };

  const filteredRows = filter ? guests.data.filter((row) => row.state === filter) : guests.data;

  return (
    <section>
      <div className="relative">
        <img className="w-full" src={invitationHeader} alt="invitationHeader" />
        <div className="absolute bottom-[1%] md:p-10 px-10 pb-2 w-full flex items-center justify-between">
          <p className="text-center text-[#FAF5EE] sm:text-[40px] text-[30px] font-medium">
            {t("Guests status")}
          </p>
        </div>
      </div>
      {guests.loading && <Loader slim />}
      {!guests.loading && (
        <div className="px-5 xl:px-20 py-10 bg-zinc-100">
          <div className="justify-start items-center gap-2  hidden sm:inline-flex mb-2">
            <div
              onClick={() => navigate(`/invitation/view/${params.id}`)}
              className="text-zinc-400 text-base font-normal leading-[48px] hover:text-gray-dark cursor-pointer"
            >
              {t("Wedding invitation View")}
            </div>
            <FontAwesomeIcon
              className="text-zinc-400 text-base"
              icon={faAngleRight}
            />
            <div className="text-zinc-400 text-base font-normal leading-[48px] hover:text-gray-dark cursor-pointer">
              {t("Guests status")}
            </div>
          </div>
          <div className="flex gap-1 items-center mb-2">
            <div className="text-2xl font-semibold leading-9">
            {t("Guests status")}
            </div>
            <div className="text-base font-light leading-normal">
              ({guests.data.length})
            </div>
          </div>
          <div className="mb-8 w-full">
            <div className="flex md:justify-between flex-col lg:flex-row lg:items-center items-start w-full">
              <div className="flex flex-col md:flex-row gap-2 mb-4 lg:mb-0">
                <div className="bg-white flex gap-2 px-6 py-4  rounded-2xl border-[0.5px]">
                  <img src={userAccept} alt="userAccept"  />
                  <div className="flex flex-col items-center">
                    <span className="text-[#1A7F37] md:text-2xl text-base font-bold">
                      {
                        guests.data.filter(
                          (guest) => guest.state === "accepted"
                        ).length
                      }
                    </span>
                    <span className="text-gray-500 text-sm font-light">
                      {t("Attending")}
                    </span>
                  </div>
                </div>
                <div className="bg-white flex gap-2 px-6 py-4 rounded-2xl border-[0.5px]">
                  <img src={userReject} alt="userAccept" />
                  <div className="flex flex-col items-center">
                    <span className="text-[#D1242F] text-2xl font-bold">
                      {
                        guests.data.filter(
                          (guest) => guest.state === "cannot_attend"
                        ).length
                      }
                    </span>
                    <span className="text-gray-500 text-sm font-light">
                      {t("Excused")}
                    </span>
                  </div>
                </div>
                <div className="bg-white flex gap-2 px-6 py-4 rounded-2xl border-[0.5px]">
                  <img src={userPending} alt="userAccept" />
                  <div className="flex flex-col items-center">
                    <span className="text-[#9A6700] text-2xl font-bold">
                      {
                        guests.data.filter((guest) => guest.state === t("new"))
                          .length
                      }
                    </span>
                    <span className="text-gray-500 text-sm font-light">
                      {t("Pending")}
                    </span>
                  </div>
                </div>
              </div>

              <div className="justify-start items-center gap-3 flex">
                <button onClick={() => navigate(`/invitation/add-guests/${params.id}`)} className="md:px-6 py-3 px-3  rounded-lg border border-red justify-center items-center flex">
                  <span className="text-red text-sm sm:text-base font-medium leading-none tracking-wide">
                    {t("+ Add Guest")}
                  </span>
                </button>
                {/* <button className="px-6 py-3 rounded-lg border border-red  justify-center items-center flex">
                  <span className="text-red text-base font-medium leading-none tracking-wide">
                    + Upload Guest
                  </span>
                </button> */}
                <div className="relative inline-block">
                  <button
                    className="md:px-6 py-3 px-3  rounded-lg border border-red justify-center items-center flex gap-2"
                    onClick={() => setShowDropdown(!showDropdown)}
                  >
                    <span className="text-red text-sm sm:text-base font-medium leading-none tracking-wide">
                      {t("Filter by status")}
                    </span>
                    <FontAwesomeIcon
                      className="text-red text-base font-medium leading-none tracking-wide"
                      icon={faCaretDown}
                    />
                  </button>
                  {showDropdown && (
                    <ul className="absolute left-0 mt-2 py-2 w-48 bg-white border border-gray-200 rounded-lg shadow-lg z-10">
                      <li
                        className="cursor-pointer px-4 py-2 hover:bg-gray-100"
                        onClick={() => handleFilterChange(null)}
                      >
                        {t("Show All")}
                      </li>
                      <li
                        className="cursor-pointer px-4 py-2 hover:bg-gray-100"
                        onClick={() => handleFilterChange("accepted")}
                      >
                        {t("Attending")}
                      </li>
                      <li
                        className="cursor-pointer px-4 py-2 hover:bg-gray-100"
                        onClick={() => handleFilterChange("cannot_attend")}
                      >
                        {t("Excused")}
                      </li>
                      <li
                        className="cursor-pointer px-4 py-2 hover:bg-gray-100"
                        onClick={() => handleFilterChange("new")}
                      >
                        {t("Pending")}
                      </li>
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='overflow-x-auto'>
          <table className="w-full table-auto border-collapse mb-10 rounded-3xl overflow-hidden shadow-md border border-gray-500">
            <thead className="border border-gray-500">
              <tr>
                <th className="py-2 px-4 border text-nowrap text-gray-500 text-base font-medium leading-snug">
                  #
                </th>
                {columns.map((column) => (
                  <th
                    key={column.accessor}
                    className="py-2 px-4 border text-nowrap text-gray-500 text-base font-medium leading-snug"
                  >
                    {column.Header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white text-nowrap border border-gray-500">
              {filteredRows.map((row, index) => (
                <tr
                  key={index}
                  className="border-t h-12 text-nowrap cursor-pointer hover:bg-slate-100"
                >
                  <td className="px-4 border text-nowrap text-center text-sm font-normal leading-tight">
                  
                   {index+1}
                  
                  </td>

                  {columns.map((column) => (
                    <td
                      key={column.accessor}
                      className="px-4 border text-center text-sm font-normal leading-tight"
                    >
                      {column.Header === t("Status") ? (
                        <span
                          className={`${
                            row[column.accessor] === "accepted"
                              ? "text-[#1A7F37] font-semibold px-3 py-[6px] border border-[#1A7F37] rounded-[100px]"
                              : row[column.accessor] === "cannot_attend"
                              ? "text-[#D1242F] font-semibold px-3 py-[6px] border border-[#D1242F] rounded-[100px]"
                              : row[column.accessor] === "new"
                              ? "text-[#9A6700] font-semibold px-3 py-[6px] border border-[#9A6700] rounded-[100px]"
                              : "text-gray-500 font-semibold px-3 py-[6px] border border-gray-500 rounded-[100px]"
                          }`}
                        >
                          {row[column.accessor] === "accepted" && t("Attending")}
                          {row[column.accessor] === "cannot_attend" &&
                            t("Excused")}
                          {row[column.accessor] === "new" && t("Pending")}
                        </span>
                      ) : (
                        row[column.accessor]
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          </div>
          <ReactPaginate
            breakLabel="..."
            nextLabel={t("Next >")}
            onPageChange={handlePageClick}
            pageRangeDisplayed={Math.ceil(rows.length / 20)}
            pageCount={Math.ceil(rows.length / 20)}
            previousLabel={t("< Previous")}
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            pageLinkClassName="page-num"
            previousLinkClassName="page-btn"
            nextLinkClassName="page-btn"
            activeLinkClassName="active"
            disabledLinkClassName="disabled-btn"
          />
        </div>
      )}
    </section>
  );
};

export default GuestsStatus;