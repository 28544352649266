import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {totalItems} from "../../utils/utils";
import placholder_image from "../../assets/images/booking/placeholder-image.jpg"
import Loader from '../../components/Loader';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faEye, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import filterIcon from "../../assets/images/sevices/filter.png";
import useAxiosUserSecureConfig from '../../hooks/useAxiosUserSecureConfig';
import { useTable, useSortBy, usePagination } from 'react-table';
import axios from 'axios';
import eyeIcon from "../../assets/images/icons/eye.png";
import {useTranslation} from "react-i18next";




function MyOrders() {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);
  const config = useAxiosUserSecureConfig();
  const [orders, setOrders] = useState({
    loading: true,
    data: [],
  });
  const {t ,i18n } = useTranslation();
  const columns = React.useMemo(
    () => [
    { Header: t('Order ID'), accessor: 'orderId' },
    { Header: t('Order Date'), accessor: 'orderDate' },
    { Header: t('Bill-to-Name'), accessor: 'billToName' },
    { Header: t('Phone Number'), accessor: 'phone' },
    { Header: t('Address'), accessor: 'address' },
    { Header: t('Email'), accessor: 'email' },
    { Header: t('Items'), accessor: 'items' },
    { Header: t('Total Price'), accessor: 'price' },
    { Header: t('Order Status'), accessor: 'status' }, ],
    [t]
  );
  const fetchData = () => {
    axios
      .post("/api/order", {}, config)
      .then((res) => {
        console.log("orders", res.data);
        if (!res.data?.result?.success) {
          toast.error("Error while fetching orders!");
          return;
        }
        setOrders({
          loading: false,
          data: res.data?.result?.orders?.slice().reverse() || [],
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    fetchData();
  }, []);

  const reqestsData = React.useMemo(() => {
    return orders.data.length > 0
      ? orders.data.map((key) => ({
          id: key.id,
          orderId : key.id,
          orderDate : new Date(key.time_placed).toLocaleDateString(),
          billToName : key.buyer,
          phone :key.shipping_phone,
          address :key.shipping_address,
          email :  key.shipping_email,
          items : key.no_of_items ,
          price : key.total_price ,
          status : key.state.name,
          stateId : key.state.id
        }))
      : [];
  }, [orders.data]);

  const [showDropdown, setShowDropdown] = useState(false);
  const [filter, setFilter] = useState(null);

  const handleFilterChange = (value) => {
    setFilter(value);
    setShowDropdown(false);
  };
  const filteredRows = React.useMemo(() => {
    return filter ? reqestsData?.filter((row) => row.stateId === filter) : reqestsData;
  }, [filter, reqestsData]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: filteredRows,
        initialState: { pageIndex: currentPage, pageSize: 4 },
      },
      useSortBy,
      usePagination
    );

  return (
    <section className="px-5 xl:px-20 py-12">
      <div className="justify-start items-center gap-2  hidden sm:inline-flex mb-5">
        <div
          onClick={() => navigate("/user/account")}
          className="text-zinc-400 text-base font-normal leading-[48px] hover:text-gray-dark cursor-pointer"
        >
          {t("My Account")}
        </div>
        <FontAwesomeIcon
          className="text-zinc-400 text-base"
          icon={faAngleRight}
        />
        <div className="text-zinc-400 text-base font-normal leading-[48px] hover:text-gray-dark cursor-pointer">
          {t("My Orders")}
        </div>
      </div>
      {orders.loading && <Loader slim/>}
      {!orders.loading && <>
        <div className="flex justify-between items-center mb-8">
          <div className="justify-start items-center gap-2 inline-flex">
            <div className="md:text-2xl text-base font-semibold leading-9">{t("My Orders")}</div>
            <div className="text-base font-light leading-normal">
              ({orders.data.length})
            </div>
          </div>
          <div className="relative">
            <div className="flex ">
              <button
                className="md:px-6 px-3 py-2 ml-2 rounded-lg border border-red justify-center items-center flex md:gap-2 gap-1"
                onClick={() => setShowDropdown(!showDropdown)}
              >
                <img src={filterIcon} alt="filterIcon" />
                <span className="text-red text-sm md:text-base font-medium font-['Inter'] leading-none tracking-wide">
                  {t("Filtere By Status")}
                </span>
                <FontAwesomeIcon
                  className="text-red text-base font-medium leading-none tracking-wide"
                  icon={faCaretDown}
                />
              </button>
            </div>
            {showDropdown && (
              <ul className="absolute right-0 mt-2 py-2 w-48 bg-white border border-gray-200 rounded-lg shadow-lg z-10">
                <li
                  className="cursor-pointer px-4 py-2 hover:bg-gray-100"
                  onClick={() => handleFilterChange(null)}
                >
                 {t("All")}
                </li>
                <li
                  className="cursor-pointer px-4 py-2 hover:bg-gray-100"
                  onClick={() => handleFilterChange("placed")}
                >
                 {t("Placed")}
                </li>
                <li
                  className="cursor-pointer px-4 py-2 hover:bg-gray-100"
                  onClick={() => handleFilterChange("confirmed")}
                >
                 {t("Confirmed")}
                </li>
              </ul>
            )}
          </div>
        </div>
        <div className="!overflow-x-auto">
          <table
            {...getTableProps()}
            className="w-full table-auto border-collapse mb-10"
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr
                  className="h-12 bg-slate-100"
                  {...headerGroup.getHeaderGroupProps()}
                >
                  <th className="py-2 px-4 border text-gray-500 text-base font-medium leading-snug">
                    {"#"}
                  </th>{" "}
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className={`py-2 px-4 border text-gray-500 text-base font-medium leading-snug ${
                        column.isSorted
                          ? column.isSortedDesc
                            ? "sort-desc"
                            : "sort-asc"
                          : ""
                      }`}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                  <th className="py-2 px-4 border text-gray-500 text-base font-medium leading-snug">
                    {t("Actions")}
                  </th>
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows
                .slice(currentPage * 10, (currentPage + 1) * 10)
                .map((row, index) => {
                  prepareRow(row);
                  return (
                    <tr
                      {...row.getRowProps()}
                      className="border-t h-12 cursor-pointer hover:bg-slate-100"
                    >
                      <td className="px-4 border text-center bg-slate-100 text-gray-500 text-xs font-light leading-none">
                        {index + 1 + currentPage * 4}
                      </td>{" "}
                      {row.cells.map((cell, cellIndex) => (
                        <td
                          {...cell.getCellProps()}
                          className="px-4 border text-center text-[#697077] text-sm font-normal leading-tight"
                          key={cellIndex}
                        >
                          {cell.render("Cell")}
                        </td>
                      ))}
                      <td className="px-2 border text-center">
                        <button onClick={() => navigate(`/user/order-details/${row.original.id}`)} className="">
                          <img src={eyeIcon} alt="eyeIcon" />
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </>}
    </section>
  );
}

export default MyOrders
