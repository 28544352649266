function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function totalItems(items) {
  return items.reduce((total, item) => total + item.qty, 0);
}

const menuStyle =
    "border rounded-md bg-white hover:cursor-pointer px-4 py-2";

const optionStyles = {
  focus: "hover:cursor-pointer hover:text-red",
  Selected: "bg-[#F2F4F8]",
};

const baseStyle =
    " bg-[#F2F4F8]  rounded-lg self-stretch grow shrink bg- text-zinc-500 text-sm font-normal leading-tight h-12 px-[18px]  justify-start items-center gap-2 inline-flex";

export { classNames, totalItems, menuStyle, optionStyles, baseStyle };