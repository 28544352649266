import { faX } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Modal } from '@mui/material'
import axios from 'axios'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import useAxiosUserSecureConfig from '../../hooks/useAxiosUserSecureConfig'
import {useTranslation} from "react-i18next";
function BookingCancellation({openCancellationModal , handleCancelModalClose , setBookingItem , itemId , fetchData }) {
    const params = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
   
    const config = useAxiosUserSecureConfig();
  
    const handleCancelReservation = () => {
      
      setBookingItem((prevState) => ({
        ...prevState,
        loading: true,
      }));
        axios.post(`/api/venue-booking/cancel/${params.id === undefined ? itemId : params.id}`, {} , config).then((res) => {
          console.log("canceled item", res.data);
          if (!res.data?.result?.success) {
            toast.error(t("Error while canceling!"));
            return;
          } 
          setBookingItem((prevState) => ({
            ...prevState,
            loading: false,
          }));
          handleCancelModalClose()
          toast.success(t("you have cancelled your reservation"))
          fetchData();
        }).catch((err) => {
          console.log(err);
          setBookingItem((prevState) => ({
            ...prevState,
            loading: false,
          }));
        })
       }
  return (
    <Modal
      open={openCancellationModal}
      onClose={handleCancelModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="absolute md:w-2/5 w-11/12 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 px-8 py-6 bg-white rounded-2xl shadow flex-col justify-center items-start inline-flex border-4 border-red">
      <div className="w-full">
        <div className="flex justify-end">
        <button
              onClick={() => handleCancelModalClose()}
              className="self-stretch justify-between items-center flex"
            >
              <FontAwesomeIcon icon={faX} className="text-xl text-gray-500" />
            </button>
        </div>
            
            <p className="text-center text-zinc-800 text-xl font-normal mb-8">{t("Are you sure you want to cancel your reservation?")}</p>
          <div className="w-full flex justify-center gap-5">
            <button onClick={() => handleCancelModalClose()}  className="w-1/3 h-[47px] px-[18px] py-[6.40px] bg-white rounded-[10px] shadow border border-red justify-center items-center gap-2.5 inline-flex">
              <span className="text-center text-red text-base font-normal font-['Roboto'] leading-normal">
                {t("Cancel")}
              </span>
            </button>
            <button onClick={() => handleCancelReservation()}  className="w-1/3 h-[47px] px-[18px] py-[6.40px] bg-red rounded-[10px] shadow border border-red justify-center items-center gap-2.5 inline-flex">
              <span className="text-center text-white text-base font-normal font-['Roboto'] leading-normal">
                {t("Confirm")}
              </span>
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  )
}

export default BookingCancellation
