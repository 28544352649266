import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination } from "swiper/modules";
import venueImg from "../../assets/images/venus/venue-details.png";
import { AiFillStar } from "react-icons/ai";
import { Link, useNavigate, useParams } from "react-router-dom";
import adressIcon from "../../assets/images/venus/icons/adress.svg";
import dayIcon from "../../assets/images/venus/icons/calendar-number-outline.svg";
import guestsIcon from "../../assets/images/venus/icons/guests.svg";
import phoneIcon from "../../assets/images/venus/icons/phone.svg";
import websiteIcon from "../../assets/images/venus/icons/website.svg";
import axios from "axios";
import Loader from "../../components/Loader";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import placholder_image from "../../assets/images/booking/placeholder-image.jpg"
import VenueGallery from "../../components/venues/VenueGallery";
import VenueServices from "../../components/venues/VenueServices";
import { Navigation } from 'swiper/modules';
import { wishListActions } from "../../store/slicer/wishListSlice";
import Info from "../Info";
import useAxiosUserSecureConfig from "../../hooks/useAxiosUserSecureConfig";
import { FaHeart } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import Rating from "@mui/material/Rating";

function VenueDetails() {
  const { t, i18n } = useTranslation();
  const user = useSelector(state => state.auth.user);
  const navigate = useNavigate();
  const handleCheckAvailability =(id)=>{
    if(user === null) {
      toast.error("You must be logged in")
      navigate("/login")
    }
    else {
      navigate(`/facility/venue/availability/${id}`)
    }

  }


  const params = useParams();
  const [error, setError] = useState(null);
  const [facilty, setFacilty] = useState({
    loading: true,
    name: "",
    venues: [],
  });
  
  useEffect(() => {
    axios
      .post(`/api/facility/${params.id}`, { id: params.id })
      .then((res) => {
        console.log("facility", res.data);

        if (!res.data?.result?.success) {
          setError("Something went wrong");
          return;
        }
        setCurrentTab(res.data?.result.id);

        setFacilty({
          loading: false,
          name: res.data?.result.name,
          address:res.data?.result.address,
          phone:res.data?.result.phone,
          email:res.data?.result.email,
          venues: res.data?.result.venues,
          website: res.data?.result.website,
          Location:res.data?.result.location_on_map
        });
        setCurrentTab( res.data?.result.venues[0]?.id)
        
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const [imgUrls, setImgUrls] = useState([]);
  
  const [includedServicesImages, setIncludedServicesImages] = useState([]);
  const [optionalServicesImages, setOptionalServicesImages] = useState([]);

  useEffect(() => {
    const fetchServicesImages = async () => {
      try {
        const includedServicesImages = await Promise.all(
          facilty.venues.flatMap(venue =>
            venue.included_services.map((service) =>
              axios.post(`/api/product-category/${service.category.id}`, {}, config)
            )
          )
        );
  
        const optionalServicesImages = await Promise.all(
          facilty.venues.flatMap(venue =>
            venue.optional_services.map((service) =>
              axios.post(`/api/product-category/${service.category.id}`, {}, config)
            )
          )
        );
  
        setIncludedServicesImages(includedServicesImages);
        setOptionalServicesImages(optionalServicesImages);
      } catch (error) {
        console.error("Error fetching services images:", error);
      }
    };
  
    if (facilty.venues?.length > 0) {
      fetchServicesImages();
    }
  }, [facilty.venues]);


  const getCategoryImg = async (id) => {
    try {
      const response = await axios.post(`/api/product-category/${id}`, {});
      if (!response.data?.result?.success) {
        toast.error("Error while fetching category!");
        return null; // or handle error as needed
      }
      return response.data?.result?.category?.image;
    } catch (error) {
      console.error("Error while fetching category:", error);
      toast.error("Error while fetching category!");
      return null; // or handle error as needed
    }
  };
  
  useEffect(() => {
    const fetchData = async () => {
      if (!facilty.venues) return; // Guard against undefined or empty arrays
      
      const allUrls = [];
      
      for (const venue of facilty.venues) {
        if (!venue.included_services || !venue.optional_services) continue; // Guard against undefined or empty arrays
        
        const venueUrls = [];
        const allServices = [...venue.included_services, ...venue.optional_services];
        
        for (const serv of allServices) {
          const imgUrl = await getCategoryImg(serv.category.id);
          venueUrls.push(imgUrl);
        }
        
        allUrls.push(venueUrls);
      }
      
      setImgUrls(allUrls);
    };
    
    fetchData();
  }, [facilty.venues]);
  const dispatch = useDispatch();
  const [venuesWishList , setVenuesWishList] = useState()
  const fetchWishList = () => {
    axios
      .post(`/api/wishlist/venue`, {} , config)
      .then((res) => {
        // console.log("venuesWishList", res.data);
        setVenuesWishList(res.data?.result.wishlist);
        dispatch(wishListActions.setVenuesWishList(res.data.result));
        dispatch(wishListActions.setLoading(false));
      })
      .catch((err) => {
        console.log(err);
      });
  }
  useEffect(() => {
    fetchWishList()
  }, []);
  const config = useAxiosUserSecureConfig();

  const handleAddToWishList = (e , venueId) => {
    if(user === null) {
      toast.error("You must be logged in")
      navigate("/login")
      return;
    }
    e.stopPropagation();
    axios.post('/api/wishlist/venue/add' , {"venue_id": venueId}, config)
        .then((res) => {
          console.log(res.data)
          if (!res.data.result?.success) {
            toast.error(res.data.result?.message || "Request failed!");
            fetchWishList()
            return;
          }
          toast.success("You add venue to wishlist successfully!");
          fetchWishList()
        })
        .catch((err) => {
          console.log("err" , err)
          toast.error("Error while  adding venue to wishlist!");
          fetchWishList()
        })
  };
  const deleteFromWishList = (e , venueId) => {
    e.stopPropagation();
    axios.post(`/api/wishlist/venue/${venueId}/remove`, {}, config)
        .then((res) => {
          console.log(res.data)
          if (!res.data.result?.success) {
            toast.error(res.data.result?.message || "Request failed!");
            fetchWishList()
            return;
          }
          toast.success("You remove venue from wishlist successfully!");
          fetchWishList()
        })
        .catch((err) => {
          console.log("err" , err)
          toast.error("Error while  removing venue to wishlist!");
          fetchWishList()
        })
  };
  console.log("venuesWishList" , venuesWishList)
  const isVenueInWishList = (venueId) => {
    if (Array.isArray(venuesWishList)) {
      return venuesWishList.some((item) => item.id === venueId);
    } else {
      return false;
    }
  };
  console.log("imgUrls", imgUrls);
  const [currentTab, setCurrentTab] = useState();
  const [activeTap, setActiveTap] = useState(1);
  const [slidesPerView, setSlidesPerView] = useState(4);

  useEffect(() => {
    const updateSlidesPerView = () => {
      if (window.innerWidth < 640) {
        setSlidesPerView(1);
      } else if (window.innerWidth < 1024) {
        setSlidesPerView(2);
       }
      else if (window.innerWidth < 1280) {
        setSlidesPerView(3);
      } else {
        setSlidesPerView(4);
      }
    };

    updateSlidesPerView();

    window.addEventListener('resize', updateSlidesPerView);

    return () => {
      window.removeEventListener('resize', updateSlidesPerView);
    };
  }, []);
  const isMobile = window.innerWidth <= 375; //  for mobile 
  const isTablet =window.innerWidth  <=425;
  const isLaptop =window.innerWidth  <1024;
  return (
    <>
      <div className="relative">
        <Swiper
          centeredSlides={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={true}
          modules={[Autoplay, Pagination]}
          className="mySwiper"
        >
          <SwiperSlide>
            <img className="w-full" src={venueImg} alt="home" />
          </SwiperSlide>
          <SwiperSlide>
            <img className="w-full" src={venueImg} alt="home" />
          </SwiperSlide>
          <SwiperSlide>
            <img className="w-full" src={venueImg} alt="home" />
          </SwiperSlide>
        </Swiper>
        <div className="flex flex-col sm:gap-4 absolute bottom-[20%] px-5 xl:px-10 z-10">
          {facilty.loading && <Loader slim />}
          {!facilty.loading && (
            <>
              <div className="text-[#D9D9D9] sm:text-xl font-normal sm:leading-[48px]">
                <Link to={'/venues'}>{t("Wedding Venues")}</Link> &gt; {facilty.name}
              </div>
              <h1 className="text-white text-2xl sm:text-[40px] font-medium sm:leading-[44px]">
                {facilty.name}
              </h1>
            </>
          )}
        </div>
      </div>
      <section className="px-5 xl:px-10 py-12">
        <div className="mb-12">
          <div className="text-red text-2xl md:text-4xl font-medium mb-6">
            {t("Our Venues")}
          </div>
          {!facilty.loading && facilty.venues.length < 1 && (
            <Info
              msg={"No results found."}
              description={"Please refresh to show results."}
            />
          )}
          <Swiper
            spaceBetween={40}
            slidesPerView={slidesPerView}
            navigation={true}
            modules={[Navigation]}
          >
            <div className=" grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-10">
              {facilty.venues.map((venue) => (
                <SwiperSlide>
                  <div
                    onClick={() => setCurrentTab(venue.id)}
                    id={venue.id}
                    key={venue.id}
                    className={`${
                      currentTab === venue.id &&
                      "rounded-[10px] shadow-md border-2 !border-red"
                    }  p-4 cursor-pointer rounded-[10px] hover:border-2 hover:border-[#fe69724d]`}
                  >
                    <div className="mb-4">
                      <button
                        className="absolute top-6 left-6"
                        onClick={(e) => {
                          if (isVenueInWishList(venue.id)) {
                            deleteFromWishList(e, venue.id);
                          } else {
                            handleAddToWishList(e, venue.id);
                          }
                        }}
                      >
                        <FaHeart size={24} className={`${
                          isVenueInWishList(venue.id)
                            ? "text-red"
                            : "text-white"
                        }`} />
                      </button>
                      <img
                        className="mx-auto object-cover h-60 rounded-lg w-full"
                        src={
                          venue.image === "" ? placholder_image : venue.image
                        }
                        alt=""
                      />
                    </div>
                    <div className="border-b pb-4">
                      <div className="flex justify-between items-center w-full">
                        <span className="text-gray-500 text-xl font-bold leading-[29px]">
                          {venue.name}
                        </span>
                        <span className="whitespace-nowrap text-zinc-500 text-base font-semibold leading-tight tracking-wide">
                          {venue.size.name} {t("Guests")}
                        </span>
                      </div>
                    </div>
                    <div className="pt-4 flex justify-between items-center">
                      {venue.indoorOutdoor === "indoor" && (
                        <div className="px-3 py-0.5 bg-[#CCCCCC] rounded-[21.18px] justify-start items-start gap-[10px] inline-flex">
                          <div className="text-[#4C4C4C] text-base font-normal leading-relaxed">
                            {t("Indoor")}
                          </div>
                        </div>
                      )}
                      {venue.indoorOutdoor === "outdoor" && (
                        <div className="px-3 py-0.5 bg-red rounded-[21.18px] justify-start items-start gap-[10px] inline-flex">
                          <div className="text-white text-base font-normal leading-relaxed">
                            {t("Outdoor")}
                          </div>
                        </div>
                      )}

                      <div className="justify-start items-center gap-[6px] flex">
                        <div className="justify-start items-center gap-1 flex border-e-2 pe-[6px]">
                          <div>
                            <AiFillStar className="text-[12px] text-[#FFC200]" />
                          </div>
                          <p className="text-zinc-950 text-sm font-normal leading-[21px]">
                            {Math.round(venue.rating * 10) / 10}
                          </p>
                        </div>
                        <div className="justify-start items-center gap-1 flex ">
                          <div className="text-zinc-950 text-sm font-normal leading-[21px]">
                            {venue.reviewsCount}
                          </div>
                          <div className="text-gray-500 text-sm font-normal leading-[21px]">
                            {t("Reviews")}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </div>
          </Swiper>
        </div>
        {facilty.venues.map((provider) => (
          <div key={provider.id}>
            {currentTab === provider.id && (
              <>
                <div className="tabs flex gap-4 pb-4 border-b border-neutral-400 mb-6">
                  <button
                    className={`${
                      activeTap === 1 && "!text-red !font-medium"
                    } text-zinc-800 text-base font-normal `}
                    key={1}
                    disabled={activeTap === 1}
                    onClick={() => setActiveTap(1)}
                  >
                    {t("Details")}
                  </button>
                  <button
                    className={`${
                      activeTap === 2 && "!text-red !font-medium"
                    } text-zinc-800 text-base font-normal `}
                    key={2}
                    disabled={activeTap === 2}
                    onClick={() => setActiveTap(2)}
                  >
                    {t("Reviews")}
                  </button>
                </div>
                {activeTap === 1 && (
                  <>
                    <div className="mb-12 xl:flex gap-8 items-start">
                      {/* <div className="xl:w-3/5 grid md:grid-cols-4 md:grid-rows-2 gap-2 mb-8 xl:mb-0">
                  <div className="col-span-2 row-span-2 ">
                    <img
                      className="w-full h-full rounded-[10px]"
                      src={provider.image === "" ? placholder_image : provider.image}
                      alt=""
                    />
                  </div>
                  {provider.extraImages.map((img) => 
                  <div className="">
                  <img
                    className="w-full h-full rounded-[10px]"
                    src={img}
                    alt=""
                  />
                </div>)}
                  
                </div> */}
                      <div className="xl:w-3/5 venue-gallery mb-5 xl:mb-0">
                        <VenueGallery
                          mainImg={provider.image}
                          images={provider.extraImages}
                          venueId= {provider.id}
                        />
                      </div>
                      <div className="xl:w-2/5">
                        <div className="flex justify-between mb-4">
                          <p className="text-gray-dark text-2xl md:text-[32px] font-medium uppercase ">
                            {provider.name}
                          </p>
                          <div className="justify-start items-center gap-2 inline-flex">
                            <div className=" justify-center items-center gap-2 flex">
                              <span className="text-red text-base font-normal leading-normal">
                                {t("Reviews")}
                              </span>
                              <span className="text-neutral-400 text-sm font-normal leading-[21px]">
                                ({provider.reviewsCount})
                              </span>
                            </div>
                            <div className="justify-start items-center gap-1.5 flex">
                              <AiFillStar className="w-4 h-4 text-[#FFC200]" />
                              <p className="text-zinc-950 text-base font-medium leading-normal">
                                {Math.round(provider.rating * 10) / 10}
                              </p>
                            </div>
                          </div>
                        </div>
                        <p className="mb-6 text-gray-500 text-lg text-wrap font-light">
                          {provider.description}
                        </p>
                        <div className="items-center gap-2 inline-flex mb-6">
                          <span className="text-gray-dark text-base font-normal leading-snug">
                            {t("Venue price")} :
                          </span>
                          <span className="text-red text-xl font-bold leading-[33.60px]">
                            {provider.minPrice} EGP
                          </span>
                        </div>
                        <ul className="flex flex-col gap-2 mb-6">
                          <li className="flex gap-2">
                            <img src={adressIcon} alt="" />
                            <Link to={facilty.Location? facilty.Location : null} target="_blank">
                            <span className={` ${facilty.Location? 'text-[#456DE5] underline':'text-neutral-700'}  text-sm font-normal leading-[21px]`}>
                            {facilty.address}
                            </span>
                            </Link>
                          </li>

                          <li className="flex gap-2">
                            <img src={dayIcon} alt="" />
                            <span className="text-neutral-700 text-sm font-normal leading-[21px]">
                              {provider.weekAvailability}
                            </span>
                          </li>
                          <li className="flex gap-2">
                            <img src={guestsIcon} alt="" />
                            <span className="text-neutral-700 text-sm font-normal leading-[21px]">
                              {provider.size.name} {t("Guests")}
                            </span>
                          </li>
                          <li className="flex gap-2">
                            <img src={phoneIcon} alt="" />
                            <span className="text-neutral-700 text-sm font-normal leading-[21px]">
                              {facilty.phone}
                            </span>
                          </li>
                          <li className="flex gap-2">
                            <img src={websiteIcon} alt="" />
                            <Link
                              to={facilty.website}
                              target="_blank"
                              className="text-[#456DE5] text-sm font-light underline leading-[21px]"
                            >
                              {facilty.website}
                            </Link>
                          </li>
                        </ul>
                        <div className="flex gap-4 items-center">
                          <button
                            onClick={() => handleCheckAvailability(provider.id)}
                            className="h-[47px] px-9 py-2 rounded-[10px] shadow bg-red border border-red justify-center items-center inline-flex"
                          >
                            <div className="text-center text-white text-base font-normal leading-tight">
                              {t("Check Availability")}
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="mb-6 ">
                      <p className="text-zinc-800 text-2xl font-normal mb-6">
                        {t("Included services list")}
                      </p>
                      <Swiper
                        spaceBetween={20}
                        slidesPerView={
                          isMobile ? 1 : isTablet ? 2 : isLaptop ? 3 : 6
                        }
                        navigation={true}
                        modules={[Navigation]}
                      >
                        <div className="flex items-center gap-6">
                          {provider.included_services?.map((serv, index) => (
                            <SwiperSlide>
                              <div className="p-4 w-48 rounded-xl border flex-col justify-start items-start gap-2.5 inline-flex">
                                <div className="justify-start items-center gap-4 inline-flex">
                                  <div className="w-7 h-7 px-px py-[5px] justify-center items-center flex">
                                    <img src={includedServicesImages[index]?.data?.result?.category.image} alt="Icon" />
                                  </div>
                                  <div className="text-red text-base font-normal">
                                    {serv.name}
                                  </div>
                                </div>
                                <div className="text-zinc-800 text-base font-normal">
                                  {serv.list_price} EGP
                                </div>
                              </div>
                            </SwiperSlide>
                          ))}
                        </div>
                      </Swiper>
                    </div>
                    <div className="mb-6">
                      <p className="text-zinc-800 text-2xl font-normal mb-6">
                        {t("Other Services")}
                      </p>
                      <div className="flex items-center gap-6">
                        <VenueServices services={provider.optional_services} optionalServicesImages={optionalServicesImages}/>
                      </div>
                    </div>
                  </>
                )}
                {activeTap === 2 && (
                  <div className="flex-col justify-start items-start gap-6 inline-flex w-full">
                    <div className="self-stretch flex-col justify-start items-start gap-4 flex">
                      <div className="self-stretch justify-start items-center gap-6 inline-flex">
                        <div className="text-zinc-800 text-[20px] font-medium uppercase">
                          {provider.name}
                        </div>
                      </div>
                      <div className="flex flex-col gap-6 w-full">
                        {provider.reviews.map((review) => (
                          <div className="p-6 border rounded-2xl">
                          <div className="justify-start items-center gap-4 inline-flex pb-4 border-b w-full">
                            <p className="text-gray-900 text-base font-normal  leading-normal">
                              {review.user.name}
                            </p>
                          </div>
                          <div className="flex gap-1 py-4">
                            <Rating
                              name="quality"
                              value={review.rating}
                              precision={0.5}
                              readOnly
                            />
                          </div>
                          <p className="text-gray-900 text-sm font-normal leading-[21px]">
                            {review.review}
                          </p>
                        </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        ))}
      </section>
    </>
  );
}

export default VenueDetails;
