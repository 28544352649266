import React ,{useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addToCart,
  clearCart,
  decreaseCart,
  getTotals,
  removeFromCart,
} from "../../store/slicer/cartSlice";
import { FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import shippingHeader from "../../assets/images/shop/shipping.png";
import bag from "../../assets/images/shop/icons/bag-2.svg";
import wallet from "../../assets/images/shop/icons/wallet.svg";
import ticket from "../../assets/images/shop//icons/ticket-2.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faBagShopping, faCreditCard, faWallet, faTrashCan} from "@fortawesome/free-solid-svg-icons";
import { IoPricetagOutline } from "react-icons/io5";
import { useNavigate} from "react-router-dom";
import mastercard from "../../assets/images/shop/icons/mastercard.svg";
import visa from "../../assets/images/shop/icons/Visa.svg";
import coin from "../../assets/images/shop/icons/bitcoin.svg";
import interac from "../../assets/images/shop/icons/interac.svg";
import {totalItems} from "../../utils/utils";
import { Controller, useForm } from "react-hook-form";
import { PhoneInput } from "react-international-phone";
import { Typography } from "@material-tailwind/react";
import Select from 'react-select';
import axios from "axios";
import { toast } from "react-toastify";
import useAxiosUserSecureConfig from "../../hooks/useAxiosUserSecureConfig";
import {useTranslation} from "react-i18next";
import TermsAndConditions from '../content/TermsAndConditions';
import {cartActions} from "../../store/slicer/cartSlice";


function Shipping() {

  const { t, i18n } = useTranslation();

  const {
    register,
    watch,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({ defaultValues: {payment_method: "cash"} });

  const [countries , setCountries] = useState({ loading: true, data: [] })
  const [states , setStates] = useState({ loading: false, data: [] })
  const [cities , setCities] = useState({ loading: false, data: [] })
  useEffect(() => {
    axios.post('/api/country', {}).then((res) => {
      if (!res.data?.result?.success) {
        toast.error(t("Error while fetching countries!"));
        return;
      }

      setCountries(prevState => ({
        ...prevState,
        loading: false,
        data: res.data?.result?.countries ?? []
      }))
    }).catch((err) => {
      console.log(err);
    })
  }, []);

  const country = watch("country");
  const state = watch("state");

  useEffect(() => {
    setValue("state", null);
    setValue("city", null);

    if (!country) {
      setStates(prevState => ({
        ...prevState,
        data: []
      }))
      setCities(prevState => ({
        ...prevState,
        data: []
      }))
      return;
    }

    setStates(prevState => ({
      ...prevState,
      loading: true,
    }));

    axios.post(`/api/country/${country.value}`, {}).then((res) => {

      if (!res.data?.result?.success) {
        toast.error("Error while fetching states!");
        return;
      }

      setStates(prevState => ({
        ...prevState,
        loading: false,
        data: res.data?.result?.countries ?? [] 
      }))
    }).catch((err) => {
      console.log(err);
    })
  }, [country]);

  useEffect(() => {
    setValue("city", null)

    if (!state) {
      setCities(prevState => ({
        ...prevState,
        data: []
      }))
      return;
    }

    setCities(prevState => ({
      ...prevState,
      loading: true,
    }));

    axios.post(`/api/state/${state.value}`, {}).then((res) => {

      if (!res.data?.result?.success) {
        toast.error("Error while fetching cities!");
        return;
      }

      setCities(prevState => ({
        ...prevState,
        loading: false,
        data: res.data?.result?.state.cities ?? []
      }))
    }).catch((err) => {
      console.log(err);
    })
  }, [state]);

    const navigate = useNavigate();
    const cart = useSelector((state) => state.cart);
    const dispatch = useDispatch();
    const user = useSelector(state => state.auth.user)
    const config = useAxiosUserSecureConfig();

    const onSubmit = (data) => {
      console.log(data)
      if (data.payment_method === "online") {
        axios.post('/api/store/online-payment/create-session' ,data, config)
            .then((res) => {
              console.log("session_id", res.data)

              navigate(`/payment/${res.data.result.session_id}`);

            })
            .catch((err) => {
              console.error(err)
              toast.error(t("Error while placing order!"));
            })
      } else if (data.payment_method === "cash") {
        data = {
          name: data.name,
          country : data.country.value,
          state: data.state.value,
          city:  data.city.value,
          phone: data.phone,
          email: data.email,
          address: data.address,
          additional_number : data.additional_phone,
          building_number : data.building_number,
          flat_number : data.flat_number,
          floor :data.floor,
          notes :data.notes
        };
        axios.post('/api/place_order' ,data, config)
          .then((res) => {
            console.log(res.data)
            if (!res.data.result?.success) {
              toast.error(res.data.result?.message || t("Request failed!"));
              return;
            }
            toast.success(t("Order placed successfully!"));
            dispatch(cartActions.clear());
            navigate("/order-complete", { state: { data: res.data.result.order } });

          })
          .catch((err) => {
            console.log("err" , err)
            toast.error(t("Error while placing order!"));
          })
      } else {
        toast.error(t("Please select payment method!"));
      }
    }
    const customStyles = {
      control: (styles) => ({
        ...styles,
        "border-color": "#E5E7EB" , "border-radius" :"8px" ,padding: "6px" ,height: "48px" })
      }

      const [open, setOpen] = useState(false);
      const handleClose = () => setOpen(false); 

  return (
    <>
    <TermsAndConditions
        open={open}
        handleClose={handleClose}
      />
      <section className="relative">
        <img className="w-full" src={shippingHeader} alt="home" />
        <div className="flex flex-col sm:gap-4 absolute bottom-[20%] px-5 xl:px-10">
          <p className="text-[#D9D9D9] sm:text-xl font-normal sm:leading-[48px]">
            {t("Shop > Shopping Cart > Shipping")}
          </p>
          <h1 className="text-white text-2xl sm:text-[40px] font-medium font-['Roboto'] sm:leading-[44px]">
            {t("Shipping")}
          </h1>
        </div>
      </section>
      <form onSubmit={handleSubmit(onSubmit)}>
        <header className="w-full py-7 px-5 bg-orange-50 justify-center items-center gap-2 sm:gap-4 inline-flex">
          <button
            onClick={() => navigate("/cart")}
            className="flex items-center gap-2"
          >
            <div className="bg-red rounded-[100px] justify-center items-center gap-2 inline-flex">
              <FontAwesomeIcon
                className="text-sm text-white p-2"
                icon={faBagShopping}
              />
            </div>
            <p className="text-gray-dark text-sm font-medium leading-[21px]">
              {t("Shopping Cart")}
            </p>
          </button>
          <hr className="w-[85.50px] h-px bg-[#C3D2CC]" />
          <button className="flex items-center gap-2">
            <div className="bg-red rounded-[100px] justify-center items-center gap-2 inline-flex">
              <FontAwesomeIcon
                className="text-sm text-white p-2"
                icon={faCreditCard}
              />
            </div>
            <p className="text-zinc text-sm font-medium leading-[21px]">
              {t("Checkout")}
            </p>
          </button>
          <hr className="w-[85.50px] h-px bg-[#C3D2CC]" />
          <button type="submit" className="flex items-center gap-2">
            <div className="bg-white rounded-[100px] justify-center items-center gap-2 inline-flex">
              <FontAwesomeIcon
                className="text-sm text-red p-2"
                icon={faWallet}
              />
            </div>
            <p className="text-zinc text-sm font-medium leading-[21px]">
              {t("Order Complete")}
            </p>
          </button>
        </header>
        <section className="px-5 xl:px-20 py-12">
          <div className="lg:flex gap-12 items-start">
            <div className="lg:w-2/3 mb-5 lg:mb-0">
              <div className="pb-4 justify-between items-center inline-flex w-full">
                <div className="text-red text-2xl font-medium leading-9">
                  {t("Shipping")}
                </div>
                <div className="text-zinc text-base font-light leading-normal">
                  ({totalItems(cart.items)} {t("items")})
                </div>
              </div>
              <div className="rounded-[20px] border mb-4 ">
                <div className="flex flex-col gap-6 py-5 px-6">
                  <div className="flex flex-col gap-2">
                    <div className="block">
                      <label
                        className="text-stone-500 text-base font-normal font-['Roboto'] leading-normal"
                        htmlFor="name"
                      >
                        {t("Full Name")}
                        <span className="text-red-500 text-base font-normal leading-snug">
                          *
                        </span>
                      </label>
                    </div>
                    <input
                      {...register("name", {
                        required: t("Name is required"),
                      })}
                      className="w-full h-12 px-4 rounded-lg border border-[#E5E7EB] justify-start items-center gap-4 inline-flex"
                      id="name"
                      defaultValue={user?.name}
                    />
                    {errors.name && (
                      <Typography variant="small" color="red">
                        {errors.name?.message}
                      </Typography>
                    )}
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="block">
                      <label
                        className="text-stone-500 text-base font-normal font-['Roboto'] leading-normal"
                        htmlFor="country"
                      >
                        {t("Country")}
                        <span className="text-red-500 text-base font-normal leading-snug">
                          *
                        </span>
                      </label>
                    </div>
                    <Controller
                      control={control}
                      name="country"
                      rules={{ required: t("Country is required") }}
                      render={({ field, field: { name, ref } }) => {
                        return (
                          <Select
                            styles={customStyles}
                            name={name}
                            ref={ref}
                            isClearable
                            placeholder={t("Select...")}
                            isSearchable
                            options={countries.data.map((country) => ({
                              value: country.id,
                              label: country.name,
                            }))}
                            {...field}
                            className="w-full"
                          />
                        );
                      }}
                    />
                    {errors.country && (
                      <Typography variant="small" color="red">
                        {errors.country?.message}
                      </Typography>
                    )}
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="block">
                      <label
                        className="text-stone-500 text-base font-normal font-['Roboto'] leading-normal"
                        htmlFor="state"
                      >
                        {t("State")}
                        <span className="text-red-500 text-base font-normal leading-snug">
                          *
                        </span>
                      </label>
                    </div>
                    <Controller
                      control={control}
                      name="state"
                      rules={{ required: t("State is required")}}
                      render={({ field, field: { name, ref } }) => {
                        return (
                          <Select
                            styles={customStyles}
                            name={name}
                            ref={ref}
                            isClearable
                            placeholder={t("Select...")}
                            isSearchable
                            options={states.data.map((state) => ({
                              value: state.id,
                              label: state.name,
                            }))}
                            {...field}
                            className="w-full"
                          />
                        );
                      }}
                    />
                    {errors.state && (
                      <Typography variant="small" color="red">
                        {errors.state?.message}
                      </Typography>
                    )}
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="block">
                      <label
                        className="text-stone-500 text-base font-normal font-['Roboto'] leading-normal"
                        htmlFor="city"
                      >
                        {t("City")}
                        <span className="text-red-500 text-base font-normal leading-snug">
                          *
                        </span>
                      </label>
                    </div>
                    <Controller
                      control={control}
                      name="city"
                      rules={{ required: t("City is required") }}
                      render={({ field, field: { name, ref } }) => {
                        return (
                          <Select
                            styles={customStyles}
                            name={name}
                            ref={ref}
                            placeholder={t("Select...")}
                            isClearable
                            isSearchable
                            options={cities.data.map((category) => ({
                              value: category.id,
                              label: category.name,
                            }))}
                            {...field}
                            className="w-full"
                          />
                        );
                      }}
                    />
                    {errors.city && (
                      <Typography variant="small" color="red">
                        {errors.city?.message}
                      </Typography>
                    )}
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="block">
                      <label
                        className="text-stone-500 text-base font-normal font-['Roboto'] leading-normal"
                        htmlFor="address"
                      >
                        {t("Address")}
                        <span className="text-red-500 text-base font-normal leading-snug">
                          *
                        </span>
                      </label>
                    </div>
                    <input
                      {...register("address", {
                        required: t("address is required"),
                      })}
                      className="w-full h-12 px-4 rounded-lg border border-[#E5E7EB] justify-start items-center gap-4 inline-flex"
                      placeholder={t("address")}
                    />
                    {errors.address && (
                      <Typography variant="small" color="red">
                        {errors.address?.message}
                      </Typography>
                    )}
                  </div>
                  {/* <div className="flex flex-col gap-2">
                <div className="block">
                    <label
                      className="text-stone-500 text-base font-normal font-['Roboto'] leading-normal"
                      htmlFor="street"
                    >
                      Street
                      <span className="text-red-500 text-base font-normal leading-snug">*</span>
                    </label>
                  </div>
                  <input
                  {...register("street", {
                    required: "Street is required",
                  })}
                    className="w-full h-12 px-4 rounded-lg border border-[#E5E7EB] justify-start items-center gap-4 inline-flex"
                    placeholder="Street"
                  />
                  {errors.street && (
                    <Typography variant="small" color="red">
                      {errors.street?.message}
                    </Typography>
                  )}
                </div>
                <div className="flex flex-col gap-2">
                <div className="block">
                    <label
                      className="text-stone-500 text-base font-normal font-['Roboto'] leading-normal"
                      htmlFor="home_number"
                    >
                      Home Number
                    </label>
                  </div>
                  <input
                  type="number"
                  {...register("home_number")}
                    className="w-full h-12 px-4 rounded-lg border border-[#E5E7EB] justify-start items-center gap-4 inline-flex"
                    placeholder="Home Number"
                  />
                  {errors.home_number && (
                    <Typography variant="small" color="red">
                      {errors.home_number?.message}
                    </Typography>
                  )}
                </div> */}
                  <div className="phone-number flex flex-col gap-2">
                    <div className="block">
                      <label
                        className="text-stone-500 text-base font-normal font-['Roboto'] leading-normal"
                        htmlFor="country"
                      >
                        {t("Phone Number")}
                        <span className="text-red-500 text-base font-normal leading-snug">
                          *
                        </span>
                      </label>
                    </div>
                    <Controller
                      className="w-full"
                      control={control}
                      name="phone"
                      rules={{ required: t("Phone Number is required") }}
                      render={({ field, field: { name, ref } }) => {
                        return (
                          <PhoneInput
                            className=""
                            error={errors.phone}
                            defaultCountry="eg"
                            inputStyle={{
                              width: "100%",
                              margin: "0px 4px",
                              border: "1px solid #E5E7EB",
                              // borderColor: "#000",
                              padding: "0 14px",
                              height: "48px",
                              borderRadius: "8px",
                            }}
                            name={name}
                            ref={ref}
                            {...field}
                          />
                        );
                      }}
                    />
                    {errors.phone && (
                      <Typography variant="small" color="red">
                        {errors.phone?.message}
                      </Typography>
                    )}
                  </div>
                  <div className="phone-number flex flex-col gap-2">
                    <div className="block">
                      <label
                        className="text-stone-500 text-base font-normal font-['Roboto'] leading-normal"
                        htmlFor="country"
                      >
                        {t("Additional Phone Number")} 
                      </label>
                    </div>
                    <Controller
                      className="w-full"
                      control={control}
                      name="additional_phone"
                      render={({ field, field: { name, ref } }) => {
                        return (
                          <PhoneInput
                            className=""
                            error={errors.phone}
                            defaultCountry="eg"
                            inputStyle={{
                              width: "100%",
                              margin: "0px 4px",
                              border: "1px solid #E5E7EB",
                              // borderColor: "#000",
                              padding: "0 14px",
                              height: "48px",
                              borderRadius: "8px",
                            }}
                            name={name}
                            ref={ref}
                            {...field}
                          />
                        );
                      }}
                    />
                    {errors.additional_phone && (
                      <Typography variant="small" color="red">
                        {errors.additional_phone?.message}
                      </Typography>
                    )}
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="block">
                      <label
                        className="text-stone-500 text-base font-normal font-['Roboto'] leading-normal"
                        htmlFor="name"
                      >
                        {t("Email")}
                        <span className="text-red-500 text-base font-normal leading-snug">
                          *
                        </span>
                      </label>
                    </div>
                    <input
                      {...register("email", {
                        required: t("Email is required"),
                      })}
                      className="w-full h-12 px-4 rounded-lg border border-[#E5E7EB] justify-start items-center gap-4 inline-flex"
                      id="email"
                      defaultValue={user?.email}
                    />
                    {errors.email && (
                      <Typography variant="small" color="red">
                        {errors.email?.message}
                      </Typography>
                    )}
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="block">
                      <label
                        className="text-stone-500 text-base font-normal font-['Roboto'] leading-normal"
                        htmlFor="name"
                      >
                        {t("Building Number")}
                      </label>
                    </div>
                    <input
                    type="number"
                      {...register("building_number")}
                      className="w-full h-12 px-4 rounded-lg border border-[#E5E7EB] justify-start items-center gap-4 inline-flex"
                      id="building_number"
                    />
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="block">
                      <label
                        className="text-stone-500 text-base font-normal font-['Roboto'] leading-normal"
                        htmlFor="name"
                      >
                        {t("Flat Number")}
                      </label>
                    </div>
                    <input
                    type="number"
                      {...register("flat_number")}
                      className="w-full h-12 px-4 rounded-lg border border-[#E5E7EB] justify-start items-center gap-4 inline-flex"
                      id="flat_number"
                    />
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="block">
                      <label
                        className="text-stone-500 text-base font-normal font-['Roboto'] leading-normal"
                        htmlFor="name"
                      >
                        {t("Floor")}
                      </label>
                    </div>
                    <input
                    type="number"
                      {...register("floor")}
                      className="w-full h-12 px-4 rounded-lg border border-[#E5E7EB] justify-start items-center gap-4 inline-flex"
                      id="floor"
                    />
                  </div>
                </div>
              </div>
              <p className="mb-2 text-stone-500 text-base font-normal font-['Roboto'] leading-normal">
                {t("Order notes")} {t("(optional)")}
              </p>
              <input
              {...register("notes")}
                className="w-full px-6 py-5 h-[100px] border rounded-[20px]"
                placeholder={t("Notes about your order, e.g. special notes for delivery.")}
              />
            </div>
            <div className="checkout lg:w-1/3">
              <div className="text-red text-2xl font-medium leading-9 mb-4">
                {t("Order Summary")}
              </div>
              <div className="w-full p-3 sm:p-6 rounded-2xl border  flex-col justify-start items-start gap-5 inline-flex">
                <div className="w-full flex flex-col gap-4 border-b pb-5">
                  <div className="w-full justify-between items-center inline-flex">
                    <div className="text-zinc text-sm font-normal leading-[21px]">
                      {t("Subtotal")}
                    </div>
                    <div className="text-gray-dark text-sm font-normal leading-[21px]">
                      {cart.total} {cart.currency}
                    </div>
                  </div>
                  {/* <div className="w-full justify-between items-center inline-flex">
                  <div className="text-zinc text-sm font-normal leading-[21px]">
                    Discount 
                  </div>
                  <div className="text-[#FF3333] text-sm font-normal leading-[21px]">
                    0 EGP
                  </div>
                </div>
                <div className="w-full justify-between items-center inline-flex">
                  <div className="text-zinc text-sm font-normal leading-[21px]">
                    Delivery Fee
                  </div>
                  <div className="text-gray-dark text-sm font-normal leading-[21px]">
                  {cart.shipping} {cart.currency}
                  </div>
                </div> */}
                </div>
                <div className="w-full flex flex-col gap-4">
                  <div className="w-full justify-between items-center inline-flex">
                    <div className="text-black text-xl font-normal">{t("Total")}</div>
                    <div className="text-black text-2xl font-medium ">
                      {cart.total} {cart.currency}
                    </div>
                  </div>
                  <p className="text-black text-opacity-60 text-xl font-bold font-['Roboto']">
                    {t("Choose a payment method")}
                  </p>
                  <div className="flex flex-col gap-4">
                    <div className="flex items-center gap-4">
                      <input type="radio" {...register("payment_method")} value={"cash"}/>
                      <label className="flex flex-col">
                        <span className="text-black text-opacity-60 text-base font-bold">
                          {t("Cash on delivery")}
                        </span>
                        <span className="text-black text-opacity-60 text-base font-normal">
                          {t("Pay with cash upon delivery.")}
                        </span>
                        <span></span>
                      </label>
                    </div>
                    <div className="flex items-center gap-2">
                      <input  type="radio" {...register("payment_method")} value={"online"}/>
                      <label className="flex flex-col">
                        <span className="text-black text-opacity-60 text-base font-bold">
                          {t("Credit and debit cards")}
                        </span>
                        <span className="text-black text-opacity-60 text-base font-normal">
                          {t("We accept all major credit and debit cards")}:
                        </span>
                        <span></span>
                      </label>
                    </div>
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="flex gap-4 items-center">
                      <input
                        type="checkbox"
                        {...register("terms", {
                          required: t("terms and conditions  are required"),
                        })}
                      />
                      <div className="text-black text-opacity-60  lg:text-[13px] xl:text-[15px] text-sm font-normal ">
                        {t("I have read and agree to the website")} <button
                        type="button"
                        onClick={()=> setOpen(true)}
                        className="font-medium transition-colors  hover:text-gray-900"
                      >
                        &nbsp;{t("Terms and Conditions *")}
                      </button> 
                      </div>
                    </div>
                    {errors.terms && (
                      <Typography variant="small" color="red">
                        {errors.terms?.message}
                      </Typography>
                    )}
                  </div>
                  <button
                    type="submit"
                    className="w-full h-[46px] px-6 py-[7px] bg-red rounded-[10px] shadow justify-center items-center gap-2.5 inline-flex"
                  >
                    <span className="text-center text-white text-base font-normal font-['Roboto'] leading-normal">
                      {t("Place Order")}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </form>
    </>
  );
};
export default Shipping;