import React, { useEffect, useRef, useState } from "react";
import { AiFillStar } from "react-icons/ai";
import adressIcon from "../../assets/images/venus/icons/adress.svg";
import dayIcon from "../../assets/images/venus/icons/calendar-number-outline.svg";
import guestsIcon from "../../assets/images/venus/icons/guests.svg";
import phoneIcon from "../../assets/images/venus/icons/phone.svg";
import websiteIcon from "../../assets/images/venus/icons/website.svg";
import { Link, useNavigate } from "react-router-dom";
import { Checkbox, Typography } from "@material-tailwind/react";
import BookingPaymentRequest from "../../components/venues/BookingPaymentRequest";
import axios from "axios";
import { toast } from "react-toastify";
import {useParams} from "react-router-dom";
import useAxiosUserSecureConfig from "../../hooks/useAxiosUserSecureConfig";
import Loader from "../../components/Loader";
import Ticker from "../../components/venues/Ticker";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import BookingCancellation from "../../components/venues/BookingCancellation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from 'swiper/modules';
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { addDays, differenceInDays } from "date-fns";
import TickerDays from "../../components/venues/TickerDays";
import {useTranslation} from "react-i18next";
import { FaRegFilePdf } from "react-icons/fa6";

function BookingDetails() {
  const { t, i18n } = useTranslation();
  const config = useAxiosUserSecureConfig();
  
  const [open, setOpen] =useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openCancellationModal, setOpenCancellationModal] =useState(false);
  const handleCancelModalClose = () => setOpenCancellationModal(false);

  const [bookingItem, setBookingItem] = useState({ loading: true, data: [null] });
  
  const params = useParams();
  const user = useSelector(state => state.auth.user)
  const navigate = useNavigate();
  const [redirected, setRedirected] = useState(false);

  useEffect(() => {
    if (user === null && !redirected) {
      const currentPath = window.location.pathname + window.location.search;
      console.log('Storing redirect URL:', currentPath);
      localStorage.setItem('redirectAfterLogin', currentPath);
      localStorage.setItem('venueBookingEmail', true);
      navigate('/login');
      setRedirected(true);
    }
  }, [user, navigate, redirected]);
  
  const fetchData = () => {
    axios.post(`/api/venue-booking/${params.id}`, {} , config).then((res) => {
      console.log("bookingItem", res.data);
  
      if (!res.data?.result?.success) {
        toast.error(t("Error while fetching bookings!"));
        return;
      }
      setBookingItem((prevState) => ({
        ...prevState,
        loading: false,
        data: res.data?.result?.reservation
      }));
    }).catch((err) => {
      console.log(err);
    })
  };
  useEffect(() => {
    fetchData();
  }, []);
  const isMobile = window.innerWidth <= 374;
  const [includedServicesImages, setIncludedServicesImages] = useState([]);
  const [optionalServicesImages, setOptionalServicesImages] = useState([]);

  useEffect(() => {
    const fetchServicesImages = async () => {
      try {
        const includedServicesImages = await Promise.all(
          bookingItem.data.venue.included_services.map((service) =>
            axios.post(`/api/product-category/${service.category.id}`, {}, config)
          )
        );
  
        const optionalServicesImages = await Promise.all(
          bookingItem.data.reservationLines.map((service) =>
            axios.post(`/api/product-category/${service.service.category.id}`, {}, config)
          )
        );
  
        setIncludedServicesImages(includedServicesImages);
        setOptionalServicesImages(optionalServicesImages);
      } catch (error) {
        console.error(t("Error fetching services images:"), error);
      }
    };
  
    if (bookingItem.data.venue?.included_services?.length > 0 && bookingItem.data.venue?.optional_services?.length > 0) {
      fetchServicesImages();
    }
  }, [bookingItem]);

 const {
  register,
  formState: { errors },
  handleSubmit,
} = useForm();
const onSubmit = () => {
  handleOpen()
}
const [isDisabled, setIsDisabled] = useState(false);
  const [remainingTime, setRemainingTime] = useState(null);

  useEffect(() => {
    const confirmedDate = new Date(bookingItem.data.confirmedDate);
    const currentDate = new Date();
    const timeDifference = currentDate.getTime() - confirmedDate.getTime();
    const remainingHours = Math.floor((48 * 60 * 60 * 1000 - timeDifference) / (60 * 60 * 1000));

    if (remainingHours <= 0) {
      setIsDisabled(true);
      setRemainingTime(null);
    } else {
      setIsDisabled(false);
      setRemainingTime(remainingHours);
    }
  }, [bookingItem]);
 
console.log("res",bookingItem)
const [daysLeft, setDaysLeft] = useState(14);

  useEffect(() => {
    const calculateDaysLeft = () => {
      const now = new Date();
      const paymentDateObj = new Date(bookingItem.data.payment_date);
      const targetDate = addDays(paymentDateObj, 14);
      let days = differenceInDays(targetDate, now);

      if (days < 0) {
        days = 0;
      }

      setDaysLeft(days);
    };

    calculateDaysLeft();

    const intervalId = setInterval(calculateDaysLeft, 24 * 60 * 60 * 1000); // Update every day

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [bookingItem.data.payment_date]);

  const date = new Date(bookingItem.data.confirmedDate);

  date.setHours(date.getHours() + 3);

  const timeOnly = date.toLocaleTimeString("en-US", { hour12: false });
  console.log("timeOnly", timeOnly); 

  const handleDownloadPDF = () => {  
    // Ensure 'Content-Type' is set to 'application/pdf'
    const headers = {
      ...config.headers,
      'Content-Type': 'application/pdf'
    };
  
    axios.post(`/api/venue-booking/${params.id}/print`, {}, { ...config, headers, responseType: 'blob' })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'booking.pdf');
        document.body.appendChild(link);
        link.click();
        link.remove();
        })
      .catch((err) => {
        console.error("Error:", err);
        toast.error(t("Error while downloading PDF!"));
      });
  };

  const [isCountdownFinished, setIsCountdownFinished] = useState(false);

  return (
    <section className="xl:px-10 px-5 py-12">
      <BookingCancellation
        openCancellationModal={openCancellationModal}
        handleCancelModalClose={handleCancelModalClose}
        setBookingItem={setBookingItem}
        fetchData={fetchData}
      />
      <BookingPaymentRequest
        itemId={bookingItem.data.id}
        open={open}
        handleClose={handleClose}
        setBookingItem={setBookingItem}
        fetchData={fetchData}
      />
      <p className="text-red text-2xl font-medium mb-6">
        {t("Booking Details")}
      </p>
      {bookingItem.loading && <Loader slim />}
      {!bookingItem.loading && bookingItem.data?.state?.id === "cancelled" && (
        <p className="text-gray-dark text-xl font-medium mb-20">
          {t("You have cancelled your booking")}
        </p>
      )}
      {!bookingItem.loading && bookingItem.data?.state?.id === "declined" && (
        <p className="text-gray-dark text-xl font-medium mb-20">
          {t(
            "Unfortunately the venue wouldn't be advisable. You can re-apply as we  have more than alot service providers."
          )}
        </p>
      )}
      {!bookingItem.loading &&
        (bookingItem.data?.state?.id === "new" ||
          bookingItem.data?.state?.id === "confirmed" ||
          bookingItem.data?.state?.id === "pending_payment" ||
          bookingItem.data?.state?.id === "paid") && (
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="p-6 bg-zinc-100 rounded-2xl"
          >
            <div className="mb-8 flex flex-wrap lg:flex-nowrap gap-8">
              <div className="lg:w-[35%] ">
                <div className="mb-4">
                  <img
                    className="w-full h-[300px] object-cover rounded-[10px]"
                    src={bookingItem.data.venue.image}
                    alt=""
                  />
                </div>
                {(bookingItem.data.state.id === "new" ||
                  bookingItem.data.state.id === "pending_payment") && (
                  <div className="flex flex-col gap-4">
                    <button
                      type="button"
                      onClick={() => setOpenCancellationModal(true)}
                      className="h-[42px] px-[18px] py-2.5 rounded-[10px] shadow border border-red justify-center items-center gap-2.5 inline-flex"
                    >
                      <span className="text-center text-red text-base font-normal  leading-tight">
                        {t("Cancel")}
                      </span>
                    </button>
                  </div>
                )}
                {bookingItem.data.state.id === "confirmed" && (
                  <div className="flex flex-col gap-4">
                    <button
                      type="button"
                      onClick={() => setOpenCancellationModal(true)}
                      className={`h-[42px] px-[18px] py-2.5 rounded-[10px] shadow border border-red justify-center items-center gap-2.5 inline-flex ${ isCountdownFinished ? "cursor-not-allowed opacity-50" : ""}`}
                      disabled={isCountdownFinished}
                    >
                      <span className="text-center text-red text-base font-normal leading-tight">
                        {t("Cancel")}
                      </span>
                    </button>

                    <div className={`flex ${ isMobile ? 'flex-col' : 'flex-row'} gap-2 items-center justify-between `}>
                      <span className="text-center text-nowrap text-zinc-800 text-[14px] md:text-[18px] font-light tracking-tight">
                        {t("Time left to pay")}:
                      </span>
                      <span className="text-center text-red-800 text-base font-medium tracking-tight">
                        <Ticker
                          confirmedDate={bookingItem.data.confirmedDate} setIsCountdownFinished = {setIsCountdownFinished}
                        />
                      </span>
                    </div>
                  </div>
                )}
                {bookingItem.data.state.id === "paid" && (
                  <div className="flex flex-col gap-4">
                    <button
                      type="button"
                      disabled={daysLeft === 0}
                      onClick={() => setOpenCancellationModal(true)}
                      className={`h-[42px] px-[18px] py-2.5 rounded-[10px] shadow border border-red justify-center items-center gap-2.5 inline-flex ${ daysLeft === 0 ? "cursor-not-allowed opacity-50" : ""}`}
                    >
                      <span className="text-center text-red text-base font-normal  leading-tight">
                        {t("Cancel")}
                      </span>
                      <span className="text-zinc-500 text-base font-normal font-['Inter'] leading-tight">
                        ({daysLeft} 
                        {daysLeft !== 1 ? t("Day") : daysLeft !== 0 ? t("Day") : t("Days")} {t("left")})
                      </span>
                    </button>

                    <div className={`flex  gap-4 items-start `}>
                      <span className="text-center text-zinc-800 text-base font-light tracking-tight">
                        {t("Time left for the event")}
                      </span>
                      <span className="text-center text-red-800 text-base font-medium tracking-tight">
                        <TickerDays
                          date={bookingItem.data.date}
                          daysCount={0}
                        />
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <div className="lg:w-3/4 w-full mx-auto">
                <div className="flex justify-between mb-4">
                  <div className="flex gap-8">
                    <p className="text-gray-dark text-lg md:text-[32px] font-medium uppercase ">
                      {bookingItem.data.venue.name}
                    </p>
                  </div>
                  <div className="justify-start items-center gap-2 inline-flex">
                    <div className=" justify-center items-center gap-2 flex">
                      <span className="text-red text-base font-normal leading-normal">
                        {t("Reviews")}
                      </span>
                      <span className="text-neutral-400 text-sm font-normal leading-[21px]">
                        ({bookingItem.data.venue.reviewsCount})
                      </span>
                    </div>
                    <div className="justify-start items-center gap-1.5 flex">
                      <AiFillStar className="w-4 h-4 text-[#FFC200]" />
                      <p className="text-zinc-950 text-base font-medium leading-normal">
                        {Math.round(bookingItem.data.venue.rating * 10) / 10}
                      </p>
                    </div>
                  </div>
                </div>

                <p className="mb-6 text-gray-500 text-sm md:text-lg font-light">
                  {bookingItem.data.venue.description}
                </p>
                <div className="flex flex-col md:flex-row md:gap-5 items-start mb-6">
                  <div className="flex flex-col gap-1 md:mb-0">
                    <div className="justify-start items-center gap-3 inline-flex">
                      <div className="text-zinc-800 text-lg font-normal font-['Roboto'] leading-7">
                        {t("Event Date")}
                      </div>
                      <div className="text-red text-lg font-bold font-['Roboto'] leading-[33.60px]">
                        {bookingItem.data.date}
                      </div>
                    </div>
                    <div className="justify-start items-center gap-3 inline-flex">
                      <div className="text-zinc-800 text-lg font-normal font-['Roboto'] leading-7">
                        {t("Request ID")}
                      </div>
                      <div className="text-red text-lg font-bold font-['Roboto'] leading-[33.60px]">
                        {bookingItem.data.bookingCode}
                      </div>
                    </div>
                    <div className="justify-start items-center gap-3 inline-flex">
                      <div className="text-zinc-800 text-lg font-normal font-['Roboto'] leading-7">
                        {t("Booking Date")}
                      </div>
                      <div className="text-red text-lg font-bold font-['Roboto'] leading-[33.60px]">
                        {new Date(
                          bookingItem.data.booking_date
                        ).toLocaleDateString()}
                      </div>
                    </div>
                    {bookingItem.data.payment_date && (
                      <div className="justify-start items-center gap-3 inline-flex">
                        <div className="text-zinc-800 text-lg font-normal font-['Roboto'] leading-7">
                          {t("Payment Type")}
                        </div>
                        <div className="text-red text-lg font-bold font-['Roboto'] leading-[33.60px]">
                          {bookingItem.data.payment_type}
                        </div>
                      </div>
                    )}
                    {bookingItem.data.payment_date && (
                      <div className="justify-start items-center gap-3 inline-flex">
                        <div className="text-zinc-800 text-lg font-normal font-['Roboto'] leading-7">
                          {t("Payment Date")}
                        </div>
                        <div className="text-red text-lg font-bold font-['Roboto'] leading-[33.60px]">
                          {bookingItem.data.payment_date}
                        </div>
                      </div>
                    )}
                    <div className="justify-start items-center gap-3 inline-flex">
                      <div className="text-zinc-800 text-lg font-normal font-['Roboto'] leading-7">
                        {t("Payment Amount")}
                      </div>
                      <div className={`text-red text-lg font-bold font-['Roboto'] gap-1 flex ${i18n.language === 'en' ? 'flex-row' : 'flex-row-reverse'} leading-[33.60px]  `}>
                        <div>{bookingItem.data.initial_payment_amount}</div>
                        <div>EGP</div> 
                      </div>
                    </div>
                  </div>
                </div>
                <ul className="flex flex-col gap-2 mb-6">
                  <li className="flex gap-2">
                    <img src={adressIcon} alt="" />
                    <span className="text-neutral-700 text-sm font-normal leading-[21px]">
                      {bookingItem.data.venue.facility.address}
                    </span>
                  </li>

                  <li className="flex gap-2">
                    <img src={dayIcon} alt="" />
                    <span className="text-neutral-700 text-sm font-normal leading-[21px]">
                      {bookingItem.data.venue.weekAvailability}
                    </span>
                  </li>
                  <li className="flex gap-2">
                    <img src={guestsIcon} alt="" />
                    <span className="text-neutral-700 text-sm font-normal leading-[21px]">
                      {bookingItem.data.venue.size.name} {t("Guests")}
                    </span>
                  </li>
                  <li className="flex gap-2">
                    <img src={phoneIcon} alt="" />
                    <span className="text-neutral-700 text-sm font-normal leading-[21px]">
                      {bookingItem.data.venue.facility.phone}
                    </span>
                  </li>
                  <li className="flex gap-2">
                    <img src={websiteIcon} alt="" />
                    <Link
                      to={bookingItem.data.venue.facility.website}
                      target="_blank"
                      className="text-[#456DE5] text-sm font-light underline leading-[21px]"
                    >
                      {bookingItem.data.venue.facility.website}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div class="mb-8">
              <div class="grid md:grid-cols-2 md:gap-6 mb-6">
                <div class="relative z-0 w-full mb-5  group">
                  <input
                    value={bookingItem.data.brideName}
                    type="text"
                    name="floating_first_name"
                    id="floating_first_name"
                    class="block pb-3 pt-5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=""
                    disabled
                  />
                  <label
                    for="floating_first_name"
                    class="peer-focus:font-medium absolute duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 text-zinc-800 text-lg font-normal leading-snug"
                  >
                    {t("Bride Name")}:
                  </label>
                </div>
                <div class="relative z-0 w-full mb-5 group">
                  <input
                    value={bookingItem.data.groomName}
                    type="text"
                    name="floating_first_name"
                    id="floating_first_name"
                    class="block pb-3 pt-5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=""
                    disabled
                  />
                  <label
                    for="floating_first_name"
                    class="peer-focus:font-medium absolute duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 text-zinc-800 text-lg font-normal leading-snug"
                  >
                    {t("Groom Name")}:
                  </label>
                </div>
              </div>
              <div class="grid md:grid-cols-2 md:gap-6 mb-6">
                <div class="relative z-0 w-full mb-5 group">
                  <input
                    value={bookingItem.data.phone}
                    type="text"
                    name="floating_first_name"
                    id="floating_first_name"
                    class="block pb-3 pt-5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=""
                    disabled
                  />
                  <label
                    for="floating_first_name"
                    class="peer-focus:font-medium absolute duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 text-zinc-800 text-lg font-normal leading-snug"
                  >
                    {t("Phone Number")}:
                  </label>
                </div>
                <div class="relative z-0 w-full mb-5 group">
                  <input
                    value={bookingItem.data.email}
                    type="text"
                    name="floating_first_name"
                    id="floating_first_name"
                    class="block pb-3 pt-5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=""
                    disabled
                  />
                  <label
                    for="floating_first_name"
                    class="peer-focus:font-medium absolute duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 text-zinc-800 text-lg font-normal leading-snug"
                  >
                    {t("Email")}:
                  </label>
                </div>
              </div>
              <div class="grid md:grid-cols-2 md:gap-6">
                <div class="relative z-0 w-full flex mb-5 group">
                  <input
                    value={bookingItem.data.date}
                    type="text"
                    name="floating_first_name"
                    id="floating_first_name"
                    class="block pb-3 pt-5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=""
                    disabled
                  />
                  <label
                    for="floating_first_name"
                    class="peer-focus:font-medium absolute duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 text-zinc-800 text-lg font-normal leading-snug"
                  >
                    {t("Wedding date")}:
                  </label>
                </div>
                <div class="relative z-0 w-full mb-5 group">
                  <input
                    value={bookingItem.data.notes}
                    type="text"
                    name="floating_first_name"
                    id="floating_first_name"
                    class="block pb-3 pt-5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=""
                    disabled
                  />
                  <label
                    for="floating_first_name"
                    class="peer-focus:font-medium absolute duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 text-zinc-800 text-lg font-normal leading-snug"
                  >
                    {t("Message to provider")}:
                  </label>
                </div>
              </div>
            </div>
            <Swiper
              spaceBetween={20}
              slidesPerView={"auto"}
              navigation={true}
              modules={[Navigation]}
              className="mb-8"
            >
              <div className="flex gap-6">
                {bookingItem.data.venue?.included_services?.map(
                  (serv, index) => (
                    <SwiperSlide className="!w-auto">
                      <div className="p-3 rounded-xl border flex-col justify-start items-start gap-2.5 inline-flex">
                        <div className="justify-start items-center gap-4 inline-flex">
                          <div className="w-7 h-7 px-px py-[5px] justify-center items-center flex">
                            <img
                              src={
                                includedServicesImages[index]?.data?.result
                                  ?.category.image
                              }
                              alt="receptionIcon"
                            />
                          </div>
                          <div className="text-red text-base font-normal">
                            {serv.name}
                          </div>
                        </div>
                        <div className="text-zinc-800 text-base font-normal">
                          {serv.list_price} EGP
                        </div>
                      </div>
                    </SwiperSlide>
                  )
                )}

                {bookingItem.data.reservationLines.map((serv, index) => (
                  <SwiperSlide className="!w-auto">
                    <div className="p-3 rounded-xl border flex-col justify-start items-start gap-2.5 inline-flex">
                      <div className="justify-start items-center gap-4 inline-flex">
                        <div className="w-7 h-7 px-px py-[5px] justify-center items-center flex">
                          <img
                            src={
                              optionalServicesImages[index]?.data?.result
                                ?.category.image
                            }
                            alt="receptionIcon"
                          />
                        </div>
                        <div className="text-red text-base font-normal">
                          {serv.service.name}
                        </div>
                      </div>
                      <div className="text-zinc-800 text-base font-normal">
                        {serv.price} EGP
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </div>
            </Swiper>
            <div className="flex justify-between items-end flex-wrap gap-3">
              {bookingItem.data.state.id === "confirmed" && (
                <div className="flex flex-col gap-2">
                  {/* <Checkbox
                    {...register("terms", {
                      required: "terms-and-conditions is required",
                    })}
                    label={
                      <Link
                        to={"/terms-and-conditions"}
                        target="_blank"
                        className="text-blue-600 text-base font-normal underline leading-snug"
                      >
                        Terms and conditions
                      </Link>
                    }
                  />
                  <Typography variant="small" className={"text-red-700"}>
                    {errors.terms?.message || ""}
                  </Typography> */}
                  <button
                    onClick={() => handleDownloadPDF()}
                    type="button"
                    className="h-[50px] px-16 py-2 bg-white rounded-[10px] shadow border border-red justify-center items-center gap-2.5 inline-flex"
                  >
                    <span className="text-center text-red text-base font-normal leading-normal">
                      {t("Download as PDF")}
                    </span>
                    <FaRegFilePdf className="text-center text-red text-base font-normal leading-normal" />
                  </button>
                </div>
              )}
              {(bookingItem.data.state.id === "new" ||
                bookingItem.data.state.id === "pending_payment" ||
                bookingItem.data.state.id === "paid") && (
                <div className="flex flex-col gap-3">
                  {/* <Link
                    to={"/terms-and-conditions"}
                    target="_blank"
                    className="text-blue-600 text-base font-normal underline leading-snug"
                  >
                    Terms and conditions
                  </Link> */}
                </div>
              )}
              <div className="flex flex-col gap-6 w-full md:w-1/2">
                <div className={"self-stretch"}>
                  <div className="flex flex-col space-y-3">
                  <div className= " text-dark items-center justify-between gap-5 lg:gap-10  inline-flex">
                    <div> {t("Venue Price")}{" "} </div>
                      <div className={` text-red gap-1 inline-flex ${i18n.language === 'en' ? 'flex-row' : 'flex-row-reverse'} md:text-2xl`}>
                        <div>{bookingItem.data.venue.minPrice}</div>
                        <div>EGP</div>
                      </div>
                   </div>
                    <div className=" text-dark justify-between text-sm items-center gap-5 lg:gap-10 inline-flex">
                    <div>{t("Other Services Price")}{" "}</div>
                    <div className={` text-red gap-1 inline-flex ${i18n.language === 'en' ? 'flex-row' : 'flex-row-reverse'} md:text-2xl`}>
                       <div> {bookingItem.data.price - bookingItem.data.venue.minPrice}{" "}</div>
                       <div> EGP</div>
                      </div>
                    </div>
                  </div>
                  <hr className={"my-4 border border-red "} />
                
                   <div className="justify-between w-full items-center gap-5 lg:gap-10 inline-flex">
                       <div className={"text-dark justify-between md:text-2xl text-nowrap text-lg font-bold"}>
                        {t("Total Price")}{" "} 
                       </div>
                        <div className={` text-red gap-1 inline-flex ${ i18n.language === "en" ? "flex-row" : "flex-row-reverse"
                        } md:text-2xl text-lg font-bold `} >
                        <div>
                        {bookingItem.data.price}</div>
                       <div> EGP</div>
                    </div>
                    
                  </div>
                </div>
                {bookingItem.data.state.id === "confirmed" && (
                  <button
                    type="submit"
                    className={`h-[50px] px-16 py-2 bg-red rounded-[10px] shadow border border-white justify-center items-center gap-2.5 inline-flex ${
                      isCountdownFinished ? "cursor-not-allowed opacity-50" : ""
                    }`}
                    disabled={isCountdownFinished}
                  >
                    <span className="text-center text-white text-base font-normal leading-normal">
                      {isCountdownFinished
                        ? t("Make Payment (no hours remaining)")
                        : t("Make Payment")}
                    </span>
                  </button>
                )}
              </div>
            </div>
          </form>
        )}
    </section>
  );
}

export default BookingDetails;
