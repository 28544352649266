import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Collapse,
  IconButton,
  MobileNav,
  Typography,
  collapse,
  MenuHandler,
  MenuList,
  Menu,
  MenuItem, ListItemSuffix,
} from "@material-tailwind/react";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faBars,
  faClose,
  faAngleDown,
  faUser, faTrash, faMessage, faInbox
} from "@fortawesome/free-solid-svg-icons";
import { FaRegHeart ,FaGlobeAfrica, FaGlobeAmericas  } from "react-icons/fa";
import { FiShoppingCart } from "react-icons/fi";
import {useDispatch, useSelector} from "react-redux";
import {uiActions} from "../../store/slicer/ui";
import r4bLogo from "../../assets/images/brand/r4b.png"
import {useTranslation} from "react-i18next";
import { useLocation } from "react-router-dom";
import logo from "../../assets/images/logo.svg";
import headerlogo from "../../assets/images/headerLogo.png";
import { useNavigate} from "react-router-dom";
import { getTotals } from '../../store/slicer/cartSlice';
import {Navbar, Dropdown, Avatar, Badge, Spinner} from 'flowbite-react';
import {authActions} from "../../store/slicer/auth";
import { CiUser } from "react-icons/ci";
import logIcon from "../../assets/images/icons/logout.svg";
import avatar from "../../assets/images/brand/avatar.png"
import userIcon from "../../assets/images/icons/user.svg"
import heartIcon from "../../assets/images/icons/heart.svg"
import cartIcon from "../../assets/images/icons/cart.svg"
import notifactionIcon from "../../assets/images/icons/notifications.svg"
import {totalItems} from "../../utils/utils";
import axios from "axios";
import {toast} from "react-toastify";
import useAxiosUserSecureConfig from "../../hooks/useAxiosUserSecureConfig";
import {getMessages} from "../../App";
import ConfirmationModal from '../ConfirmationModal';
import ServiveProviderHeader from '../Headers/ServiceProviderHeader';
import VenueVendorHeader from '../Headers/VenueVendorHeader';
import ProductSupplierHeader from '../Headers/ProductSupplierHeader';
import RegisteredUserHeader from '../Headers/RegisteredUserHeader';




function UserHeader({ }) {

  const user = useSelector(state => state.auth.user)

  return (
    <>
    {user?.vendor_type === "services" ? <ServiveProviderHeader/> : 
    user?.vendor_type === "venue" ? <VenueVendorHeader/> :
    user?.vendor_type === "supplier" ? <ProductSupplierHeader/> : <RegisteredUserHeader/>}
    </>
  );
}

export default UserHeader;