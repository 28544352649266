import React, {useEffect, useState} from 'react'
import {AiFillStar, AiOutlineHeart} from 'react-icons/ai';
import {useDispatch, useSelector} from "react-redux";
import { FaHeart , FaRegHeart } from "react-icons/fa";
import { CiCircleCheck } from "react-icons/ci";
import details from "../../assets/images/shop/details.png";
import { FiShoppingCart } from "react-icons/fi";
import {Link, useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import Loader from "../../components/Loader";
import Error from "../Error";
import {cartActions} from "../../store/slicer/cartSlice";
import {toast} from "react-toastify";
import useAxiosUserSecureConfig from "../../hooks/useAxiosUserSecureConfig";
import img from "../../assets/images/sevices/download.png";
import serv from "../../assets/images/sevices/serv.png";
import icon from "../../assets/images/sevices/icon.png";
import { faAngleRight, faCircleCheck, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Modal, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import Select from 'react-select';
import { faFacebookF, faYoutube, faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons';
import {SlideshowLightbox} from 'lightbox.js-react'
import 'lightbox.js-react/dist/index.css'
import {Checkbox} from "@material-tailwind/react";
import placholder_image from "../../assets/images/booking/placeholder-image.jpg"
import Info from '../Info';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaCalendarAlt } from 'react-icons/fa';
import {useTranslation} from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination } from "swiper/modules";
import { Navigation } from 'swiper/modules';
import Rating from "@mui/material/Rating";


function ServiceDetails() {
  axios.defaults.timeout = 5000
  axios.defaults.timeoutErrorMessage='timeout'
  const [open, setOpen] =useState(false);
  const handleClose = () => setOpen(false); 
  const [openConfirmation, setOpenConfirmation] =useState(false);
  const handleCloseConfirmation = () => setOpenConfirmation(false); 
  const {
    register,
    watch,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm();
  const [showFullDescription , setShowFullDescripton] = useState(false);
  const [fullDescription , setFullDescription] = useState();

  const toggleDescription = (e , fullDescription) => {
    e.stopPropagation()
    setShowFullDescripton(!showFullDescription);
    setFullDescription(fullDescription)
  }
  
  const cart = useSelector((state) => state.cart);
  const [vendor , setVendor] = useState({ loading: true, data: [] })
  const params = useParams();
  const [quantity, setQuantity] = useState(1);
  const [activeTap, setActiveTap] = useState(1);
  const [categoryNames ,setCategoryNames] = useState();
  const [imageUrls, setImageUrls] = useState({loading : true , data :[]});
  const config = useAxiosUserSecureConfig();
  const user = useSelector(state => state.auth.user);
  const {t ,i18n } = useTranslation();
  useEffect(() => {
    axios.post(`/api/vendor/${params.id}`, {id: params.id}).then((res) => {
      console.log("vendor", res.data);
      if (!res.data?.result?.success) {
        toast.error("Error while fetching vendor!");
        return;
      }
      setVendor((prevState) => ({
        ...prevState,
        loading: false,
        data: res.data?.result?.vendor || [],
      }));
      setCategoryNames(res.data?.result?.vendor.service_categories.map(category => category.name).join(', '))
      const displayImageIds = res.data?.result?.vendor.display_image_ids || [];
        const imagePromises = displayImageIds.map(id =>
          axios.post(`/api/vendor-image/${id}`, {}, config)
            .then(response => ({
              src: response.data?.result?.image,
              alt: `Image ${id}`
            }))
        );
        Promise.all(imagePromises)
          .then(images => {
            setImageUrls((prevState) => ({
              ...prevState,
              loading: false,
              data: images,
            }));
          })
          .catch(error => {
            console.error('Error fetching images:', error);
          });
      })
    .catch((err) => {
      console.log(err);
    });
  }, []);
  console.log( "imagesUrls",imageUrls)
  const availableDays = Object.keys(vendor.data)
  .filter((key) => key.startsWith('available_') && vendor.data[key] === true)
  .map((key) => key.split('_')[1])
  .join(', ');

  const dispatch = useDispatch();

  // useEffect(()=> {
  //   setImages({
  //     loading : true,
  //     data : imageUrls.map((img) => ({src : img , alt :"img"})),
  //   })
    
  // },[])
  

  const onSubmit = (data) => {
    setOpen(false)
    const date = new Date(data.date);
    const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`; // Format as 'yyyy-MM-dd'
    console.log("selectedServices",selectedServices)
    const serviceIds = selectedServices.map(service => service.id);
    console.log(serviceIds)
    data = {
      vendor_id: vendor.data.id,
      date: formattedDate,
      time: data.time,
      address: data.address,
      services: serviceIds,
    };
    axios.post('/api/check-service-availability' ,data, config)
    .then((res) => {
      if (!res.data.result?.success) {
        toast.error(res.data.result?.message || "Request failed!");
        return;
      }
      toast.success("You check service availability successfully!");
      setOpenConfirmation(true)
    })
    .catch((err) => {
      console.log("err" , err)
      toast.error("Error while adding checking availability!");
    })
  }

  const getCategoryImg = async (id) => {
    try {
      const response = await axios.post(`/api/product-category/${id}`, {});
      if (!response.data?.result?.success) {
        toast.error("Error while fetching category!");
        return null; // or handle error as needed
      }
      return response.data?.result?.category?.image;
    } catch (error) {
      console.error("Error while fetching category:", error);
      toast.error("Error while fetching category!");
      return null; // or handle error as needed
    }
  };
  const [imgUrls, setImgUrls] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if (!vendor.data.services) return; // Guard against undefined or empty array
      const urls = [];
      for (const serv of vendor.data.services) {
        const imgUrl = await getCategoryImg(serv.category.id);
        urls.push(imgUrl);
      }
      setImgUrls(urls);
    };
  
    fetchData();
  }, [vendor.data.services]);
  
  const [selectedServices, setSelectedServices] = useState([]);

  // Function to handle service selection
  const handleServiceSelection = (servId) => {
    const selectedService = vendor.data.services.find(serv => serv.id === servId);
    if (selectedService) {
      setSelectedServices(prevSelectedServices => [...prevSelectedServices, selectedService]);
    }
  };

  // Function to handle service deselection
  const handleServiceDeselection = (servId) => {
    setSelectedServices(prevSelectedServices => prevSelectedServices.filter(serv => serv.id !== servId));
  };
  const subtotal = selectedServices.reduce((total, serv) => total + serv.list_price, 0);

  const navigate = useNavigate()

  const disabledDays = Object.keys(vendor.data)
    .filter((key) => key.startsWith("available_") && !vendor.data[key]) // Filter out unavailable days
    .map((key) => key.substring("available_".length)); // Extract day names
  console.log("disabledDays", disabledDays);

  const [selectedDate, setSelectedDate] = useState(null);

  // Function to check if a day is disabled
  const isDayDisabled = (date) => {
    const dayOfWeek = date.toLocaleDateString('en-US', { weekday: 'short' }).toLowerCase();
    return disabledDays.includes(dayOfWeek);
  };

  // Filter function for the date picker
  const filterDate = (date) => {
    return !isDayDisabled(date);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setValue('date', date); // Update form value
  };

  const servicePlaces =[
    { value: "wedding_location", label: t("Wedding Location") },
    { value: "working_address",  label: t("At my working Address") },
    {value: "any", label: t("Wedding Location and Working Address")},
  ];

  const customStyles = {
    control: (styles) => ({
      ...styles,
      background: "#F2F4F8", "border-color": "#F2F4F8" , "border-radius" :"8px" ,padding: "6px" })
    }
    const isMobile = window.innerWidth <= 375; //  for mobile 
    const isTablet =window.innerWidth  <=425;
    const isLaptop =window.innerWidth  <1024;

  return (
    <>
      <section className="relative">
        {showFullDescription && (
          <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-10 bg-gray-800 bg-opacity-50">
            <div className="bg-white p-8 text-left rounded-2xl md:w-1/2 w-10/12 border-4 border-red">
              <p>{fullDescription}</p>
              <div className="flex justify-center">
                <button
                  className="h-[42px] px-[18px] py-2.5 rounded-[10px] shadow border border-red justify-center items-center gap-2.5 inline-flex mt-4"
                  onClick={toggleDescription}
                >
                  <span className="text-center text-red text-base font-normal  leading-tight">
                    {" "}
                    {t("Close")}
                  </span>
                </button>
              </div>
            </div>
          </div>
        )}
        <Modal
          open={openConfirmation}
          onClose={handleCloseConfirmation}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="absolute w-full md:w-2/5 top-1/2 left-1/2  -translate-x-1/2 -translate-y-1/2 px-8 py-6 bg-white rounded-2xl shadow flex-col justify-center items-start inline-flex border-4 border-red">
            <div className="w-full">
              <div className="flex justify-end">
                <button
                  onClick={() => handleCloseConfirmation()}
                  className="self-stretch justify-between items-center flex"
                >
                  <FontAwesomeIcon
                    icon={faX}
                    className="text-xl text-gray-500"
                  />
                </button>
              </div>
              <div className="flex flex-col items-center mb-4">
                <p className="text-center">
                  {t(
                    "The Supplier Will Confirm The Service Booking Request And You Will Receive A Notification Shortly"
                  )}{" "}
                </p>
                <CiCircleCheck className="text-[200px] text-[#00ff2b]" />
              </div>
              <div className="w-full flex justify-center gap-5">
                <button
                  onClick={() => handleCloseConfirmation()}
                  className="w-1/3 h-[47px] px-[18px] py-[6.40px] bg-white rounded-[10px] shadow border border-red justify-center items-center gap-2.5 inline-flex"
                >
                  <span className="text-center text-red text-base font-normal font-['Roboto'] leading-normal">
                    {t("Ok")}
                  </span>
                </button>
              </div>
            </div>
          </Box>
        </Modal>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="absolute w-full md:w-2/5 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 md:px-8 px-2 py-6 bg-white rounded-2xl shadow flex-col justify-center items-start inline-flex border-4 border-red">
            <form onSubmit={handleSubmit(onSubmit)} className="w-full">
              <div className="flex justify-end">
                <button
                  onClick={() => handleClose()}
                  className="self-stretch justify-between items-center flex"
                >
                  <FontAwesomeIcon
                    icon={faX}
                    className="text-xl text-gray-500"
                  />
                </button>
              </div>
              <div className="flex flex-col gap-3 mb-6">
                <div className="flex flex-col justify-start items-start gap-2 ">
                  <label className="text-zinc-800 text-base font-normal leading-snug">
                    {t("Date of receiving the service")}
                    <span className="text-red-500 text-base font-normal leading-snug">
                      *
                    </span>
                  </label>
                  <div className="relative w-full">
                    <DatePicker
                      {...register("date", {
                        required: t("Date is required"),
                        validate: (value) =>
                          new Date(value) > new Date()
                            ? true
                            : t("date must be in the future"),
                      })}
                      selected={selectedDate}
                      onChange={handleDateChange}
                      filterDate={filterDate}
                      placeholderText={t("Select a date")}
                      dateFormat="yyyy-MM-dd"
                      className="w-full h-12 px-4 py-3 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 font-normal leading-tight text-sm pr-10 placeholder-[#808080]"
                    />
                    <div className="absolute top-0 right-3 flex items-center h-full text-zinc-500">
                      <FaCalendarAlt />
                    </div>
                  </div>
                  <Typography variant="small" color="red">
                    {errors.date ? errors.date?.message : ""}
                  </Typography>
                </div>
                <div className="flex flex-col justify-start items-start gap-2 ">
                  <label className="text-zinc-800 text-base font-normal leading-snug">
                    {t("Time of receiving the service")}
                    <span className="text-red-500 text-base font-normal leading-snug">
                      *
                    </span>
                  </label>
                  <input
                    type="time"
                    {...register("time", {
                      required: t("time is required"),
                    })}
                    className="w-full h-12 px-4 py-3 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 font-normal leading-tight text-sm placeholder-[#808080]"
                  />
                  <Typography variant="small" color="red">
                    {errors.time ? errors.time?.message : ""}
                  </Typography>
                </div>
                <div className="flex flex-col justify-start items-start gap-2 ">
                  <label className="text-zinc-800 text-base font-normal leading-snug">
                    {t("Address of receiving the service")}
                    <span className="text-red-500 text-base font-normal leading-snug">
                      *
                    </span>
                  </label>
                  <input
                    placeholder={t("Address")}
                    {...register("address", {
                      required: t("Address is required"),
                    })}
                    className="w-full h-12 px-4 py-3 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 font-normal leading-tight text-sm placeholder-[#808080]"
                  />
                  <Typography variant="small" color="red">
                    {errors.address ? errors.address?.message : ""}
                  </Typography>
                </div>
                <div className="text-zinc-500 text-sm font-semibold text-end">
                  {t("Total Price")} :{" "}
                  <span className="text-red text-base">{subtotal} EGP</span>{" "}
                </div>
                <div>
                  <span className="mb-5">{t("Selected services details")}</span>
                  <div
                    className="flex items-center gap-2 mt-2 flex-wrap"
                    key={serv.id}
                  >
                    {selectedServices.map((serv) => (
                      <div className="p-2 rounded-xl border flex-col justify-start items-start gap-2.5 inline-flex">
                        <div className="justify-start items-center gap-2 inline-flex">
                          <div className="w-7 h-7 px-px py-[5px] justify-center items-center flex">
                            <img src={icon} alt="receptionIcon" />
                          </div>
                          <div className="text-red text-base font-normal">
                            {serv.name}
                          </div>
                        </div>
                        <div className="text-zinc-800 text-base font-normal">
                          {serv.list_price} EGP
                        </div>
                      </div>
                    ))}
                    {selectedServices.length === 0 && (
                      <Typography variant="small" color="red">
                        {t("Please select at least one service.")}
                      </Typography>
                    )}
                  </div>
                </div>
              </div>
              <div className="w-full flex justify-center gap-5">
                <button
                  onClick={() => handleClose()}
                  type="button"
                  className="w-1/3 h-[47px] px-[18px] py-[6.40px] bg-white rounded-[10px] shadow border border-red justify-center items-center gap-2.5 inline-flex"
                >
                  <span className="text-center text-red text-base font-normal font-['Roboto'] leading-normal">
                    {t("Cancel")}
                  </span>
                </button>
                <button
                  type="submit"
                  disabled={selectedServices.length === 0}
                  className="w-1/3 h-[47px] px-[18px] py-[6.40px] bg-red rounded-[10px] shadow border border-red justify-center items-center gap-2.5 inline-flex"
                >
                  <span className="text-center text-white text-base font-normal font-['Roboto'] leading-normal">
                    {t("Submit")}
                  </span>
                </button>
              </div>
            </form>
          </Box>
        </Modal>
        <img className="w-full" src={serv} alt="home" />
        <div className="flex flex-col sm:gap-4 absolute md:bottom-[20%] bottom-[10%] px-5 xl:px-10">
          <h1 className="text-white text-xl sm:text-[40px] font-medium font-['Roboto'] sm:leading-[44px]">
            {t("Service Details")}
          </h1>
        </div>
      </section>
      {vendor.loading && <Loader slim />}
      {!vendor.loading && (
        <section className="px-5 xl:px-10 py-12">
          <div className="justify-start items-center gap-2 hidden sm:inline-flex mb-5">
            <div
              onClick={() => navigate("/services")}
              className="text-zinc-400 text-base font-normal leading-[48px] hover:text-gray-dark cursor-pointer"
            >
              {t("Wedding Services")}
            </div>
            <FontAwesomeIcon
              className="text-zinc-400 text-base"
              icon={faAngleRight}
            />
            <div className="text-zinc-400 text-base font-normal leading-[48px] hover:text-gray-dark cursor-pointer">
              {t("Service Details")}
            </div>
          </div>
          <div className="lg:flex justify-center gap-6 pb-6 border-b mb-6 ">
            <div className="flex justify-center mb-4 lg:mb-0">
              <div className="relative mb-6">
                <div className="sm:w-[340px] flex justify-center items-center mb-4">
                  <img
                    className="h-80 w-full object-cover rounded-lg"
                    src={vendor.data.image || placholder_image}
                    alt=""
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-6 grow">
              <div className="flex justify-between items-center">
                <p className="text-gray-dark text-2xl md:text-[32px] font-medium uppercase">
                  {vendor.data.brand_name}
                </p>
              </div>
              <div className="flex justify-between">
                <div className="flex-col justify-start items-start gap-3 inline-flex md:w-1/2 w-full ">
                  <div>
                    <span className="text-[#666666] text-lg font-semibold">
                      {t("Vendor name")} :
                    </span>
                    <span className="text-[#666666] text-lg font-light">
                      {" "}
                      {vendor.data.name}
                    </span>
                  </div>
                  <div>
                    <span className="text-[#666666] text-lg font-semibold">
                      {t("Category")} :
                    </span>
                    <span className="text-[#666666] text-lg font-light">
                      {" "}
                      {categoryNames}
                    </span>
                  </div>
                  <div>
                    <span className="text-[#666666] text-lg font-semibold">
                      {t("Available Days")} :
                    </span>
                    <span className="text-[#666666] text-lg font-light">
                      {" "}
                      {availableDays || "No available days"}
                    </span>
                  </div>
                  <div>
                    <span className="text-[#666666] text-lg font-semibold">
                      {t("Vendor address")} :
                    </span>
                    <span className="text-[#666666] text-lg font-light">
                      {" "}
                      {vendor.data.address}
                    </span>
                  </div>
                  <div>
                    <span className="text-[#666666] text-lg font-semibold mb-3">
                      {t("About")}:
                    </span>
                    <div className="description text-[#666666] text-lg font-light">
                      <p className={`overflow-hidden line-clamp-2`}>
                        {vendor.data.about}
                      </p>
                      <div className="flex items-center justify-between">
                        {!showFullDescription && (
                          <button
                            className="text-red text-end pt-4 text-sm font-semibold"
                            onClick={(e) =>
                              toggleDescription(e, vendor.data.about)
                            }
                          >
                            {t("Read More")}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* {vendor.data.website && (
                    <div>
                      <span className="text-[#666666] text-lg font-semibold">
                        {t("Vendor website")} :
                      </span>
                      <span className="text-[#666666] text-lg font-light">
                        {" "}
                        {vendor.data.website}
                      </span>
                    </div>
                  )} */}
                  <div>
                    <span className="text-[#666666] text-lg font-semibold">
                      {t("Service Location")}:
                    </span>{" "}
                    <span className="text-[#666666] text-lg font-light">
                      {" "}
                      {servicePlaces.find(
                        (place) => place.value === vendor.data.service_location
                      )?.label || ""}
                    </span>
                  </div>
                  <div className="flex items-center justify-center space-x-3">
                    {vendor.data.facebook_link && (
                      <Link
                        target="_blank"
                        to={vendor.data.facebook_link}
                        className={`flex items-center justify-center w-7 h-7 ${
                          i18n.language === "en" ? "ml-0" : "ml-3"
                        } rounded-full bg-blue-500 text-white hover:bg-blue-600 transition-colors duration-300`}
                      >
                        <FontAwesomeIcon
                          icon={faFacebookF}
                          className="text-sm"
                        />
                      </Link>
                    )}
                    {vendor.data.youtube_link && (
                      <Link
                        target="_blank"
                        to={vendor.data.youtube_link}
                        className="flex items-center justify-center w-7 h-7 rounded-full bg-red-500 text-white hover:bg-red-600 transition-colors duration-300"
                      >
                        <FontAwesomeIcon icon={faYoutube} className="text-sm" />
                      </Link>
                    )}
                    {vendor.data.instagram_link && (
                      <Link
                        target="_blank"
                        to={vendor.data.instagram_link}
                        className="flex items-center justify-center w-7 h-7 rounded-full bg-pink-500 text-white hover:bg-pink-600 transition-colors duration-300"
                      >
                        <FontAwesomeIcon
                          icon={faInstagram}
                          className="text-sm"
                        />
                      </Link>
                    )}
                    {vendor.data.tiktok_link && (
                      <Link
                        target="_blank"
                        to={vendor.data.tiktok_link}
                        className="flex items-center justify-center w-7 h-7 rounded-full bg-black text-white hover:bg-black transition-colors duration-300"
                      >
                        <FontAwesomeIcon icon={faTiktok} className="text-sm" />
                      </Link>
                    )}
                    {vendor.data.x_link && (
                      <Link
                        target="_blank"
                        to={vendor.data.x_link}
                        className="flex items-center justify-center w-7 h-7 text-sm rounded-full bg-gray-500 text-white hover:bg-gray-600 transition-colors duration-300"
                      >
                        X
                      </Link>
                    )}
                  </div>
                </div>
                {/* <div className="w-1/2">
                  <span className="text-[#666666] text-lg font-semibold mb-3">
                    {t("About")}:
                  </span>
                  <div className="description text-[#666666] text-lg font-light">
                    <p className={`overflow-hidden line-clamp-2`}>
                      {vendor.data.about}
                    </p>
                    <div className="flex items-center justify-between">
                      {!showFullDescription && (
                        <button
                          className="text-red text-end pt-4 text-sm font-semibold"
                          onClick={(e) =>
                            toggleDescription(e, vendor.data.about)
                          }
                        >
                          {t("Read More")}
                        </button>
                      )}
                    </div>
                  </div>
                </div> */}
              </div>
              {imageUrls.loading && <Loader slim />}
              {!imageUrls.loading && (
                <div className="mx-auto">
                  <SlideshowLightbox
                    images={imageUrls.data}
                    className="slideshowLightbox container grid grid-cols-2 md:grid-cols-6 gap-2 mx-auto services-gallery"
                    showThumbnails={true}
                  ></SlideshowLightbox>
                </div>
              )}
            </div>
          </div>

          <div className="serv-details mb-6">
            <div className="tabs flex gap-6 pb-4 mb-6">
              <button
                className={`${
                  activeTap === 1 && "!text-red !font-medium"
                } text-zinc-500 text-base font-normal  leading-normal `}
                key={1}
                disabled={activeTap === 1}
                onClick={() => setActiveTap(1)}
              >
                {t("Pricing")}
              </button>
              <button
                className={`${
                  activeTap === 2 && "!text-red !font-medium"
                } text-zinc-500 text-base font-normal  leading-normal flex items-center gap-4 `}
                key={2}
                disabled={activeTap === 2}
                onClick={() => setActiveTap(2)}
              >
                {t("Reviews")} ({vendor.data.reviews.length})
                <div className="flex gap-1 items-center">
                  <AiFillStar className="text-[16px] text-[#FFC200]" />
                  <p className="text-gray-dark text-base font-medium leading-normal">
                    {vendor.data.rating}
                  </p>
                </div>
              </button>
            </div>
            {activeTap === 1 && (
              <div className="mx-2">
                <Swiper
                  spaceBetween={20}
                  slidesPerView={isMobile ? 1 : isTablet ? 2 : isLaptop ? 3 : 5}
                  navigation={true}
                  modules={[Navigation]}
                >
                  <div className="flex items-center gap-6">
                    {vendor.data.services.length === 0 ? (
                      <Info msg={"No services Found!"} />
                    ) : (
                      vendor.data.services.map((serv, index) => (
                        <SwiperSlide key={serv.id}>
                          <Controller
                            control={control}
                            name={`serv_${serv.id}`}
                            render={({ field: { onChange, value } }) => (
                              <div
                                className="p-3 rounded-xl border flex md:gap-3 gap-1 items-center"
                                key={serv.id}
                              >
                                <div className="w-7 h-7 px-px py-[5px] justify-center items-center flex">
                                  <Checkbox
                                    onChange={(event) => {
                                      if (event.target.checked) {
                                        handleServiceSelection(serv.id);
                                      } else {
                                        handleServiceDeselection(serv.id);
                                      }
                                      onChange(
                                        event.target.checked
                                          ? [...(value || []), serv.id]
                                          : (value || []).filter(
                                              (id) => id !== serv.id
                                            )
                                      );
                                    }}
                                    ripple={false}
                                    className="h-6 w-6 rounded-full border-gray-900/20 bg-gray-900/10 transition-all hover:scale-105 hover:before:opacity-0"
                                  />
                                </div>
                                <div className="flex-col justify-start items-start gap-2.5 inline-flex">
                                  <div className="justify-start items-center gap-2 inline-flex">
                                    <div className="w-7 h-7 px-px py-[5px] justify-center items-center flex">
                                      <img src={imgUrls[index]} alt="Icon" />
                                    </div>
                                    <div className="text-red text-base font-normal">
                                      {serv.name}
                                    </div>
                                  </div>
                                  <div className="text-zinc-800 text-base font-normal">
                                    {serv.list_price} EGP
                                  </div>
                                </div>
                              </div>
                            )}
                          />
                        </SwiperSlide>
                      ))
                    )}
                  </div>
                </Swiper>
              </div>
            )}
            {activeTap === 2 && (
              <div className="flex flex-col gap-6">
                {vendor.data.reviews.map((review) => (
                  <div>
                    <div className="p-6 border rounded-2xl">
                      <div className="justify-start items-center gap-4 inline-flex pb-4 border-b w-full">
                        <p className="text-gray-900 text-base font-normal  leading-normal">
                          {review.user.name}
                        </p>
                      </div>
                      <div className="flex gap-1 py-4">
                        <Rating
                          name="quality"
                          value={review.rating}
                          precision={0.5}
                          readOnly
                        />
                      </div>
                      <p className="text-gray-900 text-sm font-normal leading-[21px]">
                        {review.review}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="flex justify-end">
            <button
              disabled={selectedServices.length === 0}
              onClick={() => {
                if (user === null) {
                  toast.error(t("You must be logged in"));
                  navigate("/login");
                } else {
                  setOpen(true);
                }
              }}
              className={`${
                selectedServices.length === 0 && "opacity-50"
              } px-12 py-3 bg-red rounded-[10px] shadow border border-white justify-center items-center gap-2.5 inline-flex`}
            >
              <span className="text-center text-white  font-normal leading-tight">
                {t("Check Availability & Booking")}
              </span>
            </button>
          </div>
        </section>
      )}
    </>
  );
}

export default ServiceDetails
