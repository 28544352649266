import {uiActions} from "../slicer/ui";
import axios from "axios";
import {authActions} from "../slicer/auth";
import {toast} from "react-toastify";


export const linkPartner = async (code, dispatch, config) => {
  try {
    dispatch(uiActions.startLoading());

    const { data, data: {result, error} } = await axios.post("/api/couple/pair", {
      code: code
    }, config)
        .finally(dispatch(uiActions.stopLoading()))

    console.log(data)

    if (error || result?.error || result?.success === false) {
      return Promise.resolve(result?.msg || "Error");
    }
    // dispatch(authActions.pairPartner({}));

    return Promise.resolve(true);
  } catch (error) {
    console.log(error)
    toast.error(error.message)
  }
}

export const initiatePartner = async (dispatch, config) => {
  try {
    dispatch(uiActions.startLoading());

    console.log(config)

    const { data, data: {result, error} } = await axios.post("/api/couple/initiate", {}, config)
        .finally(dispatch(uiActions.stopLoading()))

    console.log(data)

    if (error || result?.error) {
      return Promise.resolve(false);
    }
    // dispatch(authActions.initiatePartner({}));

    return Promise.resolve(data);
  } catch (error) {
    console.log(error)
    toast.error(error.message)
  }
}