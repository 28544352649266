import React, { useState, useEffect } from 'react';
import {addHours} from 'date-fns';
import {useTranslation} from "react-i18next";

const TickerDays = ({ date }) => {
  const { t, i18n } = useTranslation();
  const [timeLeft, setTimeLeft] = useState('');

  useEffect(() => {
    const targetTime = addHours(new Date(date), 46);

    const updateTimer = () => {
      const now = new Date();
      const distanceInSeconds = Math.max(0, Math.floor((targetTime - now) / 1000));

      const days = Math.floor(distanceInSeconds / (3600 * 24));
      const hours = Math.floor((distanceInSeconds % (3600 * 24)) / 3600);
      const minutes = Math.floor((distanceInSeconds % 3600) / 60);
      const seconds = distanceInSeconds % 60;

      setTimeLeft(
        <p>
          <span className="text-red text-2xl font-bold">{String(days).padStart(2, '0')}<span  className="text-red text-base font-normal">{t("Day")}</span></span> :{' '}
          <span className="text-red text-2xl font-bold">{String(hours).padStart(2, '0')}<span  className="text-red text-base font-normal">{t("Hr")}</span></span> :{' '}
          <span className="text-red text-2xl font-bold">{String(minutes).padStart(2, '0')}<span  className="text-red text-base font-normal">{t("Min")}</span></span> :{' '}
          <span className="text-red text-2xl font-bold">{String(seconds).padStart(2, '0')}<span  className="text-red text-base font-normal">{t("Sec")}</span></span>
        </p>
      );
    };

    updateTimer();
    const intervalId = setInterval(updateTimer, 1000);

    return () => clearInterval(intervalId);
  }, [date]);

  return <div>{timeLeft}</div>;
};

export default TickerDays;
