import React, {useEffect, useState} from 'react'
import {AiFillStar, AiOutlineHeart} from 'react-icons/ai';
import {useDispatch, useSelector} from "react-redux";
import { FaRegHeart } from "react-icons/fa";
import details from "../../../assets/images/shop/details.png";
import { FiShoppingCart } from "react-icons/fi";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import Loader from "../../../components/Loader";
import Error from "../../Error";
import {cartActions} from "../../../store/slicer/cartSlice";
import {toast} from "react-toastify";
import useAxiosUserSecureConfig from "../../../hooks/useAxiosUserSecureConfig";
import placholder_image from "../../../assets/images/booking/placeholder-image.jpg"
import ConfirmationModal from '../../../components/ConfirmationModal';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination } from "swiper/modules";
import { Navigation } from 'swiper/modules';
import Rating from "@mui/material/Rating";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";


function SupplierProductDetails() {
  const {t ,i18n } = useTranslation();
  const navigate = useNavigate();
  const [open, setOpen] =useState(false);
  const handleClose = () => setOpen(false);
  const handleDeleteProduct = () => {

  axios.post(`/api/vendor/product/${params.id}/delete` ,{}, config)
      .then((res) => {
        if (!res.data.result?.success) {
          toast.error(res.data.result?.message || "Request failed!");
          return;
        }
        toast.success(t("You archive product successfully!"));
        navigate("/productSupplier/productList")
      })
      .catch((err) => {
        console.log("err" , err)
        toast.error("Error while dearchive product!");
      })
  }
  const user = useSelector(state => state.auth.user)
  const [colors, setColors] = useState({ loading: true, data: [] });
  useEffect(() => {
    axios
      .post(`/api/product/colors`, {})
      .then((res) => {
        console.log("colors", res.data);

        if (!res.data?.result?.success) {
          toast.error("Error while fetching colors!");
          return;
        }

        setColors((prevState) => ({
          ...prevState,
          loading: false,
          data: res.data?.result?.colors ?? [],
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
 
  const [sizes, setSizes] = useState({ loading: true, data: [] });
  useEffect(() => {
    axios
      .post(`/api/product/sizes`, {})
      .then((res) => {
        console.log("sizes", res.data);

        if (!res.data?.result?.success) {
          toast.error("Error while fetching sizes!");
          return;
        }

        setSizes((prevState) => ({
          ...prevState,
          loading: false,
          data: res.data?.result?.sizes ?? [],
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const cart = useSelector((state) => state.cart);
  const [product , setProduct] = useState({ loading: true, data: [] })
  const [error , setError] = useState(null)
  const params = useParams();
  const [quantity, setQuantity] = useState(1);
  const [productImage , setProductImage] = useState(null)
  const [variantsWithImages , setVariantsWithImages] = useState([])
  const [selectedVariant, setSelectedVariant] = useState({ loading: true, data: {} });

  useEffect(() => {
    axios
    .post(`/api/vendor/product/${params.id}`, {}, config)
    .then(async (res) => {
      if (!res.data?.result?.success) {
        toast.error("Error while fetching facility!");
        return;
      }
      setProduct({
        loading: false,
        data: res.data?.result.product || [], 
      });
      const variantsWithImages = await Promise.all(
        res.data.result.product.variants.map(async (variant) => {
          try {
            const imageRes = await axios.post(`/api/vendor/product/variant/${variant.id}/image2`, {}, config);
            if (!imageRes.data.result?.success) {
              throw new Error(imageRes.data.result?.message || "Request failed!");
            }
            return { ...variant, image: imageRes.data.result.image };
          } catch (error) {
            console.error('Error fetching image:', error);
            return { ...variant, image: null };
          }
        })
      );
      const filteredVariants = variantsWithImages;
      setVariantsWithImages(filteredVariants);
      axios.post(`/api/product/${params.id}/image` , {} , config).then((res) => {
        console.log("image", res.data);
        if (!res.data?.result?.success) {
          setError("Something went wrong")
          return;
        }
        setProductImage(res.data?.result?.image)
      }).catch((err) => {
        console.log(err);
        })
    })
    .catch((err) => {
      console.log(err);
    });
  }, []);

  useEffect(() => {
    if (variantsWithImages.length > 0) {
      const selectedVariant = variantsWithImages[0];
      setSelectedVariant({
        loading: false,
        data: selectedVariant,
      });
    }
  }, [variantsWithImages]);
  
  console.log("product",product)
  console.log("selectedVariant",selectedVariant)
  console.log("variantsWithImages", variantsWithImages)
  const dispatch = useDispatch();
  const config = useAxiosUserSecureConfig();


  if (error) {
    return <Error msg={error} />;
  }

  if (!product) {
    return <Loader />;
  }

  return (
    <>
      <section className="relative">
        <ConfirmationModal
          open={open}
          handleClose={handleClose}
          handleAction={handleDeleteProduct}
          msg={t("Are you sure you want to archive this product!")}
        />
        <img className="w-full" src={details} alt="home" />
        <div className="flex flex-col sm:gap-4 absolute bottom-[20%] px-5 xl:px-10">
          <div className="sm:flex hidden justify-between items-center mb-5">
            <div className="flex items-center gap-6">
             <div className="justify-start items-center gap-2  hidden sm:inline-flex">
          <div  onClick={() => navigate('/productSupplier/productList')} className="text-[#D9D9D9] sm:text-xl font-normal sm:leading-[48px]">
            {t("My Products")} 
          </div>
          <FontAwesomeIcon
            className="text-zinc-400 text-sm sm:text-base"
            icon={faAngleRight}
          />
          <div onClick={() => navigate('/productSupplier/productList')} className="text-[#D9D9D9] sm:text-xl font-normal sm:leading-[48px]">
             {t("Product Details")}
          </div>
         </div>
       </div>
      </div>


    <h1 className="text-white text-2xl sm:text-[40px] font-medium font-['Roboto'] sm:leading-[44px]">
    {t("Product Details")}
    </h1>
        </div>
      </section>
      {selectedVariant.loading && <Loader slim />}
      {!selectedVariant.loading && (
        <section className="px-5 xl:px-10 py-12">
          <div className="lg:flex justify-center gap-6 pb-12 border-b">
            <div className="flex flex-col justify-center mb-4 lg:mb-0 gap-4 sm:w-[420px]">
              <div className="relative h-[320px]">
                <div className="sm:w-[420px] h-full flex justify-center items-center">
                  <img
                    className="w-full h-full rounded-lg object-contain border"
                    src={selectedVariant.data.image}
                    alt=""
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 md:grid-cols-4 gap-2 w-full">
                {variantsWithImages.map((variant2, index) => (
                  <img
                    onClick={() => {
                      const selectedVariantByImage = variantsWithImages.find(
                        (variant) => variant.id === variant2.id
                      );
                      setSelectedVariant((prevState) => ({
                        ...prevState,
                        loading: false,
                        data: selectedVariantByImage,
                      }));
                    }}
                    key={index}
                    src={variant2.image}
                    alt="variantImage"
                    className={`cursor-pointer h-[140px] w-full md:h-24 rounded-lg object-contain border ${
                      selectedVariant.data.id === variant2.id && "border-red"
                    }`}
                  />
                ))}
              </div>
            </div>

            <div className="flex flex-col gap-4 grow">
              <div className="flex justify-between items-start">
                <p className="text-gray-dark text-lg sm:text-[32px] font-medium uppercase">
                  {product.data.name}
                </p>
                {user?.vendor_type === "supplier" && (
                  <div className="flex flex-col gap-2 sm:flex-row sm:gap-5 items-center">
                    <Link
                      to={`/ProductSupplier/edit-product/${product.data.id}`}
                      className="px-4 sm:px-8 py-[9px] rounded-[10px] shadow border border-red justify-center items-center gap-2.5 flex"
                    >
                      <span className="text-center text-red text-base font-medium font-['Inter'] leading-tight">
                        {t("Edit Products")}
                      </span>
                    </Link>
                    {product.data.enabled_by_user ? <button
                      onClick={() => setOpen(true)}
                      className="px-4 sm:px-8 py-[9px] rounded-[10px] shadow border border-red justify-center items-center gap-2.5 flex"
                    >
                      <span className="text-center text-red text-base font-medium font-['Inter'] leading-tight">
                        {t("Archive Product")}
                      </span>
                    </button> : 
                    <span className="text-center text-red text-base font-medium font-['Inter'] leading-tight">
                    {t("Archived")}
                  </span>}
                  </div>
                )}
              </div>

              <div className="flex-col justify-start items-start gap-3 inline-flex">
                {selectedVariant.data.condition === "used" ? (
                  <div className="w-14 px-3 py-0.5 bg-[#CCCCCC] rounded-[22px] justify-start items-start gap-[10px] inline-flex">
                    <div className="text-[#4C4C4C] text-base font-normal font-['Roboto'] leading-relaxed">
                      {t("Used")}
                    </div>
                  </div>
                ) : (
                  <div className="w-14 px-3 py-0.5 bg-red rounded-[22px] justify-start items-start gap-[10px] inline-flex">
                    <div className="text-white text-base font-normal font-['Roboto'] leading-relaxed">
                      {t("New")}
                    </div>
                  </div>
                )}
                <div>
                  <span className="text-[#666666] text-lg font-normal">
                    {t("Brand")}:{" "}
                  </span>
                  <span className="text-[#666666] text-lg font-light">
                    {product.data.brand || t("None")}{" "}
                  </span>
                </div>
                <div>
                  <span className="text-[#666666] text-lg font-normal">
                  {t("Weight")}:{" "}
                  </span>
                  <span className="text-[#666666] text-lg font-light">
                    {product.data.weight}{" "}
                  </span>
                </div>
                <div>
                  <span className="text-[#666666] text-lg font-normal">
                  {t("Quantity Available")}:{" "}
                  </span>
                  <span className="text-[#666666] text-lg font-light">
                    {selectedVariant.data.quantity}{" "}
                  </span>
                </div>
                <div>
                  <span className="text-[#666666] text-lg font-normal">
                    {t("Model")}:
                  </span>
                  <span className="text-[#666666] text-lg font-light">
                    {" "}
                    {selectedVariant.data.name2}
                  </span>
                </div>
              </div>

              <div
                dangerouslySetInnerHTML={{ __html: product.data.description }}
              />

              <div className="flex gap-[18px]">
                {[
                  ...new Set(
                    variantsWithImages
                      .map((variant) => variant.color)
                  ),
                ].map((color, index) => (
                  <button
                    key={index}
                    className={`${
                      selectedVariant.data.color === color &&
                      "border border-red "
                    } p-1 rounded-full`}
                    onClick={() => {
                      const selectedVariantByColor = variantsWithImages.find(
                        (variant) =>
                          variant.color === color
                      );
                      setSelectedVariant((prevState) => ({
                        ...prevState,
                        loading: false,
                        data: selectedVariantByColor,
                      }));
                    }}
                  >
                    <div
                      className={`rounded-full w-6 h-6 focus:outline-none`}
                      style={{
                        backgroundColor: `${color.toLowerCase()}`,
                      }}
                    ></div>
                  </button>
                ))}
              </div>

              <div className="flex flex-col gap-3 pb-4 border-b ">
                <div className="flex gap-3">
                  {[
                    ...new Set(
                      variantsWithImages.filter(variant => variant.color === selectedVariant.data.color)
                      .map((variant) => variant.size)
                    ),
                  ].map((size, index) => (
                    <button
                      key={index}
                      className={`rounded-lg border border-zinc-400 ${
                        selectedVariant.data.size === size && "!border-red"
                      }  px-3 py-2 xl:px-6`}
                      onClick={() => {
                        const selectedVariantBySize = variantsWithImages.find(
                          (variant) =>
                            variant.size === size &&
                            variant.color === selectedVariant.data.color
                        );
                        setSelectedVariant((prevState) => ({
                          ...prevState,
                          loading: false,
                          data: selectedVariantBySize,
                        }));
                      }}
                    >
                      <div
                        className={`text-[#666666] ${
                          selectedVariant.data.size === size && "text-red"
                        } text-lg font-normal font-['Roboto']`}
                      >
                        {size}
                      </div>
                    </button>
                  ))}
                </div>
              </div>

              <div className="flex gap-20 items-center">
                <div>
                  <span className="text-[#666666] text-lg font-normal">
                    {t("Price")} :{" "}
                  </span>
                  <span className="text-red text-2xl md:text-[32px] font-semibold uppercase">
                    {selectedVariant.data.price}
                  </span>{" "}
                  <span className="text-red text-xl font-light uppercase">
                    EGP
                  </span>
                </div>
                {/*<span className="text-[#7F7F7F] text-base font-normal">(incl. of all taxes)</span>*/}
              </div>
            </div>
          </div>
          <div className="py-12">
            <div className="flex gap-6 items-center mb-6">
              <div>
                <p className="px-4 py-2 bg-white border-b border-zinc-500 justify-center items-center gap-2 inline-flex">
                  <span className="text-zinc-500 text-base font-normal  leading-normal">
                    {t("Reviews")}
                  </span>
                  <span className="text-neutral-400 text-sm font-normal  leading-[21px]">
                    ({product.data.reviews.length})
                  </span>
                </p>
              </div>
              <div className="flex gap-1 items-center">
                <AiFillStar className="text-[16px] text-[#FFC200]" />
                <p className="text-gray-dark text-base font-medium leading-normal">
                  {product.data.rating}
                </p>
              </div>
            </div>
            <Swiper slidesPerView={1} navigation={true} modules={[Navigation]}>
              {product.data.reviews.map((review) => (
                <SwiperSlide>
                  <div>
                    <div className="p-6 border rounded-2xl mb-6">
                      <div className="justify-start items-center gap-4 inline-flex pb-4 border-b w-full">
                        <p className="text-gray-900 text-base font-normal  leading-normal">
                          {review.user.name}
                        </p>
                      </div>
                      <div className="flex gap-1 py-4">
                        <Rating
                          name="quality"
                          value={review.rating}
                          precision={0.5}
                          readOnly
                        />
                      </div>
                      <p className="text-gray-900 text-sm font-normal leading-[21px]">
                        {review.review}
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </section>
      )}
    </>
  );
}

export default SupplierProductDetails
