import React, { useEffect, useState } from "react";
import invitationImg from "../../../assets/images/invitation/sendInvitation.png";
import { Typography } from "@material-tailwind/react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";
import { PhoneInput, defaultCountries, parseCountry } from 'react-international-phone';
import axios from "axios";
import useAxiosUserSecureConfig from "../../../hooks/useAxiosUserSecureConfig";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader";
import {useTranslation} from "react-i18next";
import { useSelector } from "react-redux";

function InvitationVerification() {
  const user = useSelector(state => state.auth.user)
  useEffect(() => {
    if (user === null) {
      navigate('/login');
    }
  }, [user]);

  const {t ,i18n } = useTranslation();
const navigate = useNavigate();
const {
  register,
  control,
  handleSubmit,
  formState: { errors },
} = useForm();

const config = useAxiosUserSecureConfig();
const params = useParams();
// const [invitation, setInvitation] = useState({loading: true,data: {},});
// useEffect(() => {
//   axios
//     .post(`/api/invitation/${params.id}`, {} ,config)
//     .then((res) => {
//       console.log("invitation", res.data);
//       if (!res.data?.result?.success) {
//         toast.error(t("Error while fetching invitation!"));
//       }
//       setInvitation({
//         loading: false,
//         data: res.data?.result.invitation || {},
//       });
//     })
//     .catch((err) => {
//       console.log(err);
//     });
// }, []);

const onSubmit = (data) => {
  console.log("Data:", data);
  const processedData = {
    phone_number: data.phone,
    invitation_id: params.id
  };
  console.log("Submitted data:", processedData);
  
  axios.post('/api/invitation/check-invited', processedData, config)
    .then((res) => {
      if (!res.data.result?.success) {
        toast.error(res.data.result?.message || t("Request failed!"));
        return;
      }
      
      if (res.data.result?.invited) {
        toast.success(t("You are invited!"));
        navigate(`/guestInvitation/${params.id}?phone=${processedData.phone_number}`, { state: { data: res.data.result.invitation} });
        // Pass phone number as a URL parameter
        return;
      }
      
      if (!res.data.result?.invited) {
        toast.error(t("You are not invited!"));
        return;
      }
    })
    .catch((err) => {
      console.log("err", err);
      toast.error("Error while sending request!");
    });
};

  return (
    <section>
      <div className="flex items-center">
        <img src={invitationImg} alt="invitationImg" className="w-1/2 hidden lg:block" /> 
        <div className="w-full px-2 py-10 lg:w-1/2 flex justify-center items-center">
          <div className="flex flex-col gap-8">
            <div className="flex flex-col items-center">
              <div className="w-full justify-center items-center gap-12 md:gap-16 inline-flex">
                <div className="grow shrink basis-0 h-[0px] border border-gray-500"></div>
                <p className="text-red xl:text-[68px] text-[50px] font-[Rochester]">{t("Welcome")}</p>
                <div className="grow shrink basis-0 h-[0px] border border-gray-500"></div>
              </div>
              {/* <p className="text-red text-2xl">{t("to")} {invitation.data.groom_name} & {invitation.data.bride_name} {t("wedding")}</p> */}
            </div>
            <div className="invitaion-verification">
              <p className="lg:text-xl text-lg mb-4 text-gray-dark">
               {t(" Please enter your phone number to access the wedding invitation")}
              </p>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="flex flex-col justify-start items-start gap-2 "
              >
                <div>
                  <label className="text-gray-500 text-base font-normal leading-snug">
                    {t("Phone Number")}
                  </label>
                  <span className="text-red-500 text-base font-normal leading-snug">
                    *
                  </span>
                </div>
                <div className="flex flex-col gap-2 mb-8 w-full">
                <Controller
                  className="w-full"
                  control={control}
                  name="phone"
                  rules={{ required: t("Phone Number is required") }}
                  render={({ field, field: { name, ref } }) => {
                    return (
                      <PhoneInput
                        className=""
                        error={errors.phone}
                        defaultCountry="eg"
                        inputStyle={{
                          background: "rgb(242 244 248",
                          width: "100%",
                          margin: "0px 4px",
                          border: "0px",
                          padding: "0 14px",
                          height: "46px",
                          borderRadius: "8px",
                        }}
                        name={name}
                        ref={ref}
                        {...field}
                      />
                    );
                  }}
                />
                <Typography variant="small" color="red">
                  {errors.phone ? errors.phone?.message : ""}
                </Typography>
                </div>
                <div className="flex items-center gap-3 w-full">
                  <button
                    onClick={() => navigate("/invitation")}
                    type="button"
                    className="w-1/2 h-12 px-8 py-2 rounded-lg border border-red justify-center items-center inline-flex"
                  >
                    <span className="text-red text-sm font-medium leading-[14px] tracking-wide">
                      {t("Cancel")}
                    </span>
                  </button>
                  <button
                    type="submit"
                    className="w-1/2 h-12 px-8 py-2 rounded-lg border bg-red justify-center items-center inline-flex gap-3"
                  >
                    <span className="text-white text-sm font-medium leading-[14px] tracking-wide">
                      {t("Continue")}
                    </span>
                    <FaArrowRight className="text-white text-sm font-medium leading-[14px] tracking-wide" />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default InvitationVerification;
