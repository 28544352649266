import React, { useState } from 'react';
import receptionIcon from "../../assets/images/venus/icons/reception.svg"
import ceremonyIcon from "../../assets/images/venus/icons/ceremony.svg"
import barIcon from "../../assets/images/venus/icons/maki_bar.svg"
import storgeIcon from "../../assets/images/venus/icons/mdi_storage.svg"
import { Link } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import {Checkbox} from "@material-tailwind/react";

function VenueServices ({services , check , control , optionalServicesImages}) {
    const handleTabClick = (e) => {
        setCurrentTab(e.target.id);
    }
    // const [services , setServicesList] = useState(services)
    console.log(" services", services)
    const categories = services.map((service) => service.category.name);
    console.log("categories", categories);

    const categoriesWithoutDuplicates = Array.from(new Set(categories));
    console.log("categoriesWithoutDuplicates", categoriesWithoutDuplicates);

    const initialTab = services.length > 0 ? services[0]?.category.name : null;
    console.log("initialTab", initialTab);

    const [currentTab, setCurrentTab] = useState(initialTab);

    console.log("currentTab", currentTab);

    const [selectedServices, setSelectedServices] = useState([]);

    return (
      <div className="container">
        <div className="tabs flex gap-4 pb-4 border-b border-neutral-400 mb-6">
          {categoriesWithoutDuplicates.map((serv, i) => (
            <button
              className={`${
                currentTab === `${serv}` && "!text-red !font-medium"
              } text-zinc-800 text-sm md:text-base font-normal `}
              key={i}
              id={serv}
              disabled={currentTab === `${serv}`}
              onClick={handleTabClick}
            >
              {serv}
            </button>
          ))}
        </div>
        <div className="content flex flex-wrap gap-6">
              {services?.map((serv , index)=>
              currentTab === serv.category.name && (
                <>
                {!check && <div className="p-4 rounded-xl border flex-col justify-start items-start gap-2.5 inline-flex">
                <div className="justify-start items-center gap-4 inline-flex">
                  <div className="w-7 h-7 px-px py-[5px] justify-center items-center flex">
                    <img src={optionalServicesImages[index]?.data?.result?.category.image} alt="receptionIcon" />
                  </div>
                  <div className="text-red text-base font-normal">{serv.name}</div>
                </div>
                <div className="text-zinc-800 text-base font-normal">{serv.list_price} EGP</div>
              </div>}
              {check && (
                <Controller
                control={control}
                name="services"
                render={({ field: {onChange, value} }) => ( 
                  
                        <div className="p-3 rounded-xl border flex gap-3 items-center" key={serv.id}>
                          <div className="w-7 h-7 px-px py-[5px] justify-center items-center flex">
                          <Checkbox
                          checked={selectedServices.includes(serv.id)}
                          onChange={(event) => {
                            const isChecked = event.target.checked;
                            if (isChecked) {
                              setSelectedServices([...selectedServices, serv.id]);
                              onChange([...selectedServices, serv.id]);
                            } else {
                              setSelectedServices(selectedServices.filter((id) => id !== serv.id));
                              onChange(selectedServices.filter((id) => id !== serv.id));
                            }
                          }}
                          ripple={false}
                          className="h-6 w-6 rounded-full border-gray-900/20 bg-gray-900/10 transition-all hover:scale-105 hover:before:opacity-0"
                        />
                             
                            </div>
                          <div className="flex-col justify-start items-start gap-2.5 inline-flex">
                          <div className="justify-start items-center gap-4 inline-flex">
                            
                            <div className="w-7 h-7 px-px py-[5px] justify-center items-center flex">
                                <img src={optionalServicesImages[index]?.data?.result?.category.image} alt="Icon" />
                              </div>
                            <div className="text-red text-base font-normal">{serv.name}</div>
                          </div>
                          <div className="text-zinc-800 text-base font-normal">
                            {serv.list_price} EGP
                          </div>
                          </div>
                        </div>
                   
              )}
            />
              )}
                </>
              )
              )}

             
        </div>
      </div>
    );
}

export default VenueServices;