import React, { useState } from "react";
import bookingIcon from "../../assets/images/account/icons/booking.svg"
import orderIcon from "../../assets/images/account/icons/checklist.svg"
import wishlistIcon from "../../assets/images/account/icons/wish-list.svg"
import collaborationIcon from "../../assets/images/account/icons/collaboration.svg"
import settingsIcon from "../../assets/images/account/icons/settings.svg"
import resetpasswordIcon from "../../assets/images/account/icons/reset-password.svg"
import checkoutIcon from "../../assets/images/account/icons/check-out.svg"
import {authActions} from "../../store/slicer/auth";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import ConfirmationModal from "../../components/ConfirmationModal";
import {useTranslation} from "react-i18next";


function MyAccount() {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] =useState(false);
  const handleClose = () => setOpen(false); 
  const handleLogout = () => {
    dispatch(authActions.logout());
    navigate('/');
  }

  return (
    <section className="px-10 py-12">
      <ConfirmationModal
      open={open}
      handleClose={handleClose}
      handleAction={handleLogout}
      msg={t("Are you sure you want to logout?")}
    />
      <p className="text-red text-2xl font-medium mb-6">{t("My Account")}</p>
      <div className="py-6">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-6 gap-y-8"> 
          <Link to={"/user/booking-list"} className="p-4 bg-zinc-100 rounded-[10px] border border-[#B3B3B3] justify-start items-start gap-2 inline-flex">
            <div className="flex items-center gap-4">
                <img src={bookingIcon} alt="bookingIcon"/>
                <div className="flex flex-col items-start gap-2">
                    <p className="text-red text-lg font-normal">{t("My Bookings")}</p>
                    <p className="text-gray-500 text-sm font-light ">{t("See and manage all your confirmed reservations.")}</p>
                </div>
            </div>
          </Link>
          <Link to={"/user/orders"} className="p-4 bg-zinc-100 rounded-[10px] border border-[#B3B3B3] justify-start items-start gap-2 inline-flex">
            <div className="flex items-center gap-4">
                <img src={orderIcon} alt="bookingIcon"/>
                <div className="flex flex-col items-start gap-2">
                    <p className="text-red text-lg font-normal">{t("My Orders")}</p>
                    <p className="text-gray-500 text-sm font-light ">{t("Track the status of your past and upcoming purchases.")}</p>
                </div>
            </div>
          </Link>
          <Link to={"/user/services"} className="p-4 bg-zinc-100 rounded-[10px] border border-[#B3B3B3] justify-start items-start gap-2 inline-flex">
            <div className="flex items-center gap-4">
                <img src={wishlistIcon} alt="bookingIcon"/>
                <div className="flex flex-col items-start gap-2">
                    <p className="text-red text-lg font-normal">{t("My Services Bookings")}</p>
                    <p className="text-gray-500 text-sm font-light ">{t("View and manage services sent.")}</p>
                </div>
            </div>
          </Link>

          <Link to={"/invitations"} className="p-4 bg-zinc-100 rounded-[10px] border border-[#B3B3B3] justify-start items-start gap-2 inline-flex">
            <div className="flex items-center gap-4">
                <img src={wishlistIcon} alt="bookingIcon"/>
                <div className="flex flex-col items-start gap-2">
                    <p className="text-red text-lg font-normal">{t("My Invitations")}</p>
                    <p className="text-gray-500 text-sm font-light ">{t("View and manage invitations sent or received.")}</p>
                </div>
            </div>
          </Link>
          <Link to={'/ConsultationSession'} className="p-4 bg-zinc-100 rounded-[10px] border border-[#B3B3B3] justify-start items-start gap-2 inline-flex">
            <div className="flex items-center gap-4">
                <img src={orderIcon} alt="bookingIcon"/>
                <div className="flex flex-col items-start gap-2">
                    <p className="text-red text-lg font-normal">{t("My Consultation Session")}</p>
                    <p className="text-gray-500 text-sm font-light ">{t("See and manage all your Consultation Session")}</p>
                </div>
            </div>
          </Link>
          {/* <Link to={"/user/partner/link"} className="p-4 bg-zinc-100 rounded-[10px] border border-[#B3B3B3] justify-start items-start gap-2 inline-flex">
            <div className="flex items-center gap-4">
                <img src={collaborationIcon} alt="bookingIcon"/>
                <div className="flex flex-col items-start gap-2">
                    <p className="text-red text-lg font-normal">Link with partner</p>
                    <p className="text-gray-500 text-sm font-light ">Connect your account with a partner for shared benefits.</p>
                </div>
            </div>
          </Link> */}
          <Link to={"/user/edit-profile"} className="p-4 bg-zinc-100 rounded-[10px] border border-[#B3B3B3] justify-start items-start gap-2 inline-flex">
            <div className="flex items-center gap-4">
                <img src={settingsIcon} alt="bookingIcon"/>
                <div className="flex flex-col items-start gap-2">
                    <p className="text-red text-lg font-normal">{t("Edit account")}</p>
                    <p className="text-gray-500 text-sm font-light ">{t("Update your personal information and preferences.")}</p>
                </div>
            </div>
          </Link>
          <Link to={"/user/wishlist"} className="p-4 bg-zinc-100 rounded-[10px] border border-[#B3B3B3] justify-start items-start gap-2 inline-flex">
            <div className="flex items-center gap-4">
                <img src={wishlistIcon} alt="bookingIcon"/>
                <div className="flex flex-col items-start gap-2">
                    <p className="text-red text-lg font-normal">{t("Wish list")}</p>
                    <p className="text-gray-500 text-sm font-light ">{t("Save items you love for later or share them with others.")}</p>
                </div>
            </div>
          </Link>
        
       
          <Link to={"/change-Password"} className="p-4 bg-zinc-100 rounded-[10px] border border-[#B3B3B3] justify-start items-start gap-2 inline-flex">
            <div className="flex items-center gap-4">
                <img src={resetpasswordIcon} alt="bookingIcon"/>
                <div className="flex flex-col items-start gap-2">
                    <p className="text-red text-lg font-normal">{t("Change Password")}</p>
                    <p className="text-gray-500 text-sm font-light ">{t("Ensure your account security by updating your password.")}</p>
                </div>
            </div>
          </Link>
         
       
          <button onClick={() => {
              setOpen(true)
            }} className="p-4 bg-zinc-100 rounded-[10px] border border-[#B3B3B3] justify-start items-start gap-2 inline-flex">
            <div className="flex items-center gap-4">
                <img src={checkoutIcon} alt="bookingIcon"/>
                <div className="flex flex-col items-start gap-2">
                    <p className="text-red text-lg font-normal">{t("Logout")}</p>
                    <p className="text-gray-500 text-sm font-light ">{t("Sign out of your account.")}</p>
                </div>
            </div>
          </button>
          
        </div>
      </div>
    </section>
  );
}

export default MyAccount;
