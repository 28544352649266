import {
    faArrowUpFromBracket,
    faDownload,
    faPenToSquare,
    faPlus,
  } from "@fortawesome/free-solid-svg-icons";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import React, { useEffect, useState } from "react";
  import { useForm, Controller } from "react-hook-form";
  import deleteIcon from "../../../assets/images/icons/delete.svg";
  import uplooad from "../../../assets/images/icons/upload.svg";
  import { useNavigate, useParams } from "react-router-dom";
  import Select from "react-select";
  import invitation1 from "../../../assets/images/invitation/invitation1.jpeg";
  import invitation2 from "../../../assets/images/invitation/invitation2.jpeg";
  import invitation3 from "../../../assets/images/invitation/invitation3.jpeg";
  import invitation4 from "../../../assets/images/invitation/invitation4.jpeg";
  import invitation5 from "../../../assets/images/invitation/invitation5.jpeg";
  import invitation6 from "../../../assets/images/invitation/invitation6.jpeg";
  import axios from "axios";
  import useAxiosUserSecureConfig from "../../../hooks/useAxiosUserSecureConfig";
  import { toast } from "react-toastify";
  import invitationHeader from "../../../assets/images/invitation/invitationHeader.png";
  import { useTable, useSortBy, usePagination, updateRow } from "react-table";
  import rejectIcon from "../../../assets/images/icons/reject.svg";
  import editIcon from "../../../assets/images/icons/edit.png";
  import trashIcon from "../../../assets/images/icons/trash.png";
  import Loader from "../../../components/Loader";
  import acceptIcon from "../../../assets/images/icons/accept.svg";
  import uploadIcon from "../../../assets/images/icons/upload.png";
  import editIconBtn from "../../../assets/images/icons/edit-02.png";
  import icon1 from "../../../assets/images/invitation/object (2).png";
import icon2 from "../../../assets/images/invitation/object (3).png";
import icon3 from "../../../assets/images/invitation/object (4).png";
import eyeIcon from "../../../assets/images/icons/eyeIcon.png";
import eyeOffIcon from "../../../assets/images/icons/eye-off.png";
import doneIcon from "../../../assets/images/icons/done.png";
import { Box, Modal,Typography, Checkbox, FormControlLabel,FormGroup } from '@mui/material';
import {useTranslation} from "react-i18next";

  
  const columns = [
    { Header: "Guest name", accessor: "guestName" },
    { Header: "Guest number", accessor: "guestNumber" },
    { Header: "Classified", accessor: "classified.label" },
  ];
  
  function EditInvitation() {
    const config = useAxiosUserSecureConfig();
    const {t ,i18n } = useTranslation();
  const params = useParams();
  const [invitation, setInvitation] = useState({loading: true,data: {},});
  const images = [icon1 , icon2 , icon3]
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [dateInputs, setDateInputs] = useState([
    {
      id: Date.now(), // Unique ID for each date input
      date: '',
      aboutStory: '',
      image: images[currentImageIndex], // Use the image at the current index
    }
  ]);
  const fetchData = () => {
    axios
      .post(`/api/invitation/${params.id}`, {}, config)
      .then((res) => {
        if (!res.data?.result?.success) {
          toast.error("Error while fetching invitation!");
          return;
        }
        setInvitation({
          loading: false,
          data: res.data?.result.invitation || {},
        });
        setImagesLoading(false)
        const invitationData = res.data?.result.invitation || {};
        if (invitationData.story) {
          const updatedDateInputs = invitationData.story.map(
            (story, index) => ({
              id: story.id,
              date: story.date,
              aboutStory: story.about_story,
              image: images[index % images.length], // Access images cyclically
            })
          );
          setDateInputs(updatedDateInputs);
        }
        const defaultInvitationStyle = invitationData.style;
        const defaultInvitation = invitations.find(
          (invitation) => invitation.value === defaultInvitationStyle
        );
        setSelectedInvitation(defaultInvitation || invitations[0]);
      })
      .catch((error) => {
        console.error("Error fetching invitation:", error);
      });
  };
  useEffect(() => {
    fetchData();
  }, [params.id]);

 
  console.log("inv: ", invitation )
  console.log("dateInputs",dateInputs)

    const [groomPhotoName, setGroomPhotoName] = useState("");
    const handleGroomPhotoUpload = (e) => {
      console.log(e.target.files[0]);
      const file = e.target.files[0];
      setGroomPhotoName(file?.name);
    };
    const [bridePhotoName, setBridePhotoName] = useState("");
    const handleBridePhotoUpload = (e) => {
      console.log(e.target.files[0]);
      const file = e.target.files[0];
      setBridePhotoName(file?.name);
    };
    const invitations = [
      {
        id: 1,
        src: invitation1,
        alt: "invitation1",
        label: t("Classic"),
        value: "classic",
      },
      {
        id: 2,
        src: invitation2,
        alt: "invitation2",
        label: t("Modern"),
        value: "modern",
      },
      {
        id: 3,
        src: invitation3,
        alt: "invitation3",
        label: t("Islamic"),
        value: "islamic",
      },
      {
        id: 4,
        src: invitation4,
        alt: "invitation4",
        label: t("Old"),
        value: "old",
      },
      {
        id: 5,
        src: invitation5,
        alt: "invitation5",
        label: t("Dark"),
        value: "dark",
      },
      {
        id: 6,
        src: invitation6,
        alt: "invitation6",
        label: t("New"),
        value: "new",
      },
    ];
    const defaultInvitationStyle = invitation.data.style;

    const defaultInvitation = invitations.find(
      (invitation) => invitation.value === defaultInvitationStyle
    );
    const {
      register,
      handleSubmit,
      control,
      trigger,
      watch,
      formState: { errors },
    } = useForm({ mode: "all" });

    const [selectedInvitation, setSelectedInvitation] = useState(null);
    const [invitaionId, setInvitaionId] = useState(defaultInvitation);
    const [invitaionLoading , setInvitaionLoading] = useState(false)
    
    
    const handleInvitationClick = (invitation , value , fieldName) => {
      console.log(invitation)
      console.log(value)
       setInvitaionId(invitation);
       setInvitaionLoading(true)
      const headers = {
          ...config.headers,
          'Content-Type': 'text/plain',
      };
      const data = {
          groom_name: fieldName === 'groom_name' && value || watch("groom_name"),
          bride_name: fieldName === 'bride_name' && value || watch("bride_name"),
          wedding_address: fieldName === 'wedding_address' && value || watch("wedding_address"),
          wedding_date: fieldName === 'wedding_date' && value || watch("wedding_date"),
          wedding_time: fieldName === 'wedding_time' && value ||  watch("wedding_time"),
          style: invitation.value,
      };
      console.log("data", data)
      axios.post('/api/invitation/render', data, { ...config, headers, responseType: 'blob' })
          .then((res) => {
              if (!res.data) {
                  setInvitaionLoading(false)
                  toast.error(res.data.result?.message || "Request failed!");
                  return;
              }
              const reader = new FileReader();
              reader.onload = () => {
                  setSelectedInvitation(reader.result);
              };
              reader.onerror = (error) => {
                  console.error("Error reading image:", error);
                  toast.error(t("Error while reading image!"));
              };
              reader.readAsDataURL(res.data);
              setInvitaionLoading(false)
          })
          .catch((err) => {
              setInvitaionLoading(false)
              console.log("Error fetching image:", err);
              toast.error(t("Error while adding invitation!"));
          });
  };
    const checkFields = () => {
      const requiredFields = ['groom_name', 'bride_name', 'wedding_address', 'wedding_date', 'wedding_time'];
      return requiredFields.every(field => watch(field));
    };
    console.log("====>" ,checkFields())
    const [invitationClicked, setInvitationClicked] = useState(false);
  
    useEffect(() => {
      if (!invitationClicked && checkFields()) {
        handleInvitationClick(defaultInvitation);
        setInvitationClicked(true);
      }
    }, [invitationClicked, checkFields, invitations]);
  
    const navigate = useNavigate();
    const [formStep, setFormStep] = useState(0);
  
    const [loading, setLoading] = useState(false);
    axios.defaults.timeout = 50000;
    axios.defaults.timeoutErrorMessage = "timeout";
    const onSubmit = async (data) => {
      console.log("Data:", data);
      setLoading(true);
      const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        });
      };
      const storyArray = [];
    dateInputs.forEach((input) => {
      const storyData = {
        date: input.date || "",
        about_story: input.aboutStory || "",
        photo: "",
      };
      storyArray.push(storyData);
    });
    const photosArrayPromises = [];
    const files = data.photos || [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      try {
        const photoBase64 = await convertToBase64(file);
        photosArrayPromises.push(photoBase64);
      } catch (error) {
        console.error(`Error converting photo ${i} to base64:`, error);
      }
    }
    const photosArray = await Promise.all(photosArrayPromises);
    const cleanedPhotosArray = photosArray.map(photo => photo.substring(photo.indexOf(',') + 1));
      const requestData = {
        invitation_id: params.id,
        groom_name: data.groom_name,
        bride_name: data.bride_name,
        // groom_photo: groomPhotoBase64 || invitation.data.groom_photo,
        // bride_photo: bridePhotoBase64 || invitation.data.bride_photo,
        wedding_address: data.wedding_address,
        wedding_location: data.location,
        wedding_date: data.wedding_date,
        wedding_time: data.wedding_time,
        about_groom: data.about_groom || "",
        about_bride: data.about_bride || "",
        notes: data.notes || "",
        style: data.invitation_style,
        story: storyArray,
        photos: cleanedPhotosArray,
      };
      console.log("requestData", requestData);
      axios
        .post("/api/invitation/update", requestData, config)
        .then((res) => {
          if (!res.data.result?.success) {
            console.log(res.data);
            toast.error(res.data.result?.msg || "Request failed!");
            setLoading(false);
            return;
          }
          toast.success(t("You updata wedding invitaion successfully!"));
          setLoading(false);
          navigate(`/invitation/view/${params.id}`);
        })
        .catch((err) => {
          console.log("err", err);
          toast.error(t("Error while updating wedding invitation!"));
          setLoading(false);
        });
    };
  
    const [guestsList, setGuestsList] = useState([]);
    const [newGuest, setNewGuest] = useState({
      guestName: "",
      guestNumber: "",
      classified: "",
    });
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setNewGuest((prevGuest) => ({ ...prevGuest, [name]: value }));
    };
    const handleClassifiedChange = (selectedOption) => {
      setNewGuest((prevGuest) => ({ ...prevGuest, classified: selectedOption }));
    };
  
    const addGuest = () => {
      setGuestsList((prevList) => [...prevList, { ...newGuest, id: Date.now() }]);
      setNewGuest({ guestName: "", guestNumber: "", classified: "" });
      console.log(guestsList);
    };
    const [editingRowId, setEditingRowId] = useState(null);
  
    // Inside your handleEdit function
    const handleEdit = (rowId) => {
      console.log("Editing row:", rowId);
      setEditingRowId(rowId);
    };
  
    const handleSave = () => {
      setEditingRowId(null);
      // Save changes to the backend or update state as needed
    };
  
    const handleDelete = (id) => {
      setGuestsList((prevList) => prevList.filter((guest) => guest.id !== id));
    };
  
    const [photoInputs, setPhotoInputs] = useState([
      { id: Date.now(), photo: "" },
    ]);
  
  const incrementImageIndex = () => {
    setCurrentImageIndex(prevIndex => (prevIndex + 1) % images.length);
  };
  const [addStoryDate , setAddStoryDate] = useState()
  const [addStoryAbout , setAddStoryAbout] = useState()
  const [openModal, setOpenModal] = useState(false);
  const handleClose = () => setOpenModal(false); 
  const handleAddStory = () => {
    console.log(addStoryDate, addStoryAbout)
    const data = {
      invitation_id: params.id,
      date: addStoryDate,
      about_story: addStoryAbout,
      photo: " ",
    };
    console.log(data)
    axios
      .post("/api/invitation/add-story", data, config)
      .then((res) => {
        if (!res.data.result?.success) {
          console.log(res.data);
          toast.error(res.data.result?.msg || "Request failed!");
          fetchData()
          handleClose()
          return;
        }
        toast.success(t("You add wedding story successfully!"));
        fetchData()
        handleClose()
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(t("Error while adding wedding story!"));
        fetchData()
        handleClose()
      });
  };
  const [hiddenRows, setHiddenRows] = useState([]);

  const handleToggleRow = (id) => {
    setHiddenRows(prevHiddenRows => {
      if (prevHiddenRows.includes(id)) {
        return prevHiddenRows.filter(rowId => rowId !== id);
      } else {
        return [...prevHiddenRows, id];
      }
    });
  };

  useEffect(() => {
    setHiddenRows(dateInputs.map(input => input.id));
  }, []);

  const handleRemoveDate = (id) => {
    setDateInputs((prevDateInputs) =>
      prevDateInputs.filter((input) => input.id !== id)
    );
  };
  const handleUpdateStory = (input) => {
    console.log(input)
    const data = {
      story_id: input.id,
      date: input.date,
      about_story: input.aboutStory,
      photo: "",
    };
    axios
      .post("/api/invitation/update-story", data, config)
      .then((res) => {
        if (!res.data.result?.success) {
          console.log(res.data);
          toast.error(res.data.result?.msg || "Request failed!");
          setLoading(false);
          return;
        }
        toast.success(t("You updata wedding story successfully!"));
      })
      .catch((err) => {
        console.log("err", err);
        toast.error("Error while updating wedding story!");
        setLoading(false);
      });
  }
const [imagesLoading , setImagesLoading] = useState(false)
  const handleAddImage = (e) => {
    setImagesLoading(true)
    const files = e.target.files;
    if (files && files.length > 0) {
      const selectedFile = files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const base64String = event.target.result;
        console.log(base64String);
        const data = {
          invitation_id: params.id,
          image: base64String.split(",")[1],
        };
        axios.post("api/invitation/add-image", data, config)
        .then((res) => {
          if (!res.data.result?.success) {
            console.log(res.data);
            toast.error(res.data.result?.msg || "Request failed!");
            setImagesLoading(false)
            return;
          }
          toast.success(t("You add image successfully!"));
          fetchData()
          })
          .catch((err) => {
            console.log("err", err);
            toast.error("Error while adding image!");
            setImagesLoading(false)
          });
      };
      reader.readAsDataURL(selectedFile);
    }
  };
  const handleDeleteImage = (img) => {
    setImagesLoading(true)
    axios
      .post("/api/invitation/delete-image", { image_id: img.id }, config)
      .then((res) => {
        if (!res.data.result?.success) {
          console.log(res.data);
          toast.error(res.data.result?.msg || "Request failed!");
          setImagesLoading(false)
          return;
        }
        toast.success(t("You delete image successfully!"));
        fetchData();
      })
      .catch((err) => {
        console.log("err", err);
        toast.error("Error while deleting image!");
        setImagesLoading(false)
      });
  };
  

  const handleChange = (index, field, value) => {
    setDateInputs((prevDateInputs) => {
      const updatedDateInputs = [...prevDateInputs];
      updatedDateInputs[index][field] = value;
      return updatedDateInputs;
    });
  };
  
    const customStyles = {
      control: (styles) => ({
        ...styles,
        background: "#F2F4F8",
        "border-color": "#F2F4F8",
        "border-radius": "8px",
        padding: "6px",
        height: "48px",
      }),
    };
  
    useEffect(() => {
      const hasErrors =
        errors.groom_name ||
        errors.bride_name ||
        errors.wedding_address ||
        errors.location ||
        errors.wedding_date ||
        errors.wedding_time;
      if (hasErrors && formStep !== 0) {
        setFormStep(0);
      }
    }, [errors, formStep]);

    
  
    return (
      <section>
        <div className="relative">
          <img
            className="w-full"
            src={invitationHeader}
            alt="invitationHeader"
          />
          <div className="absolute bottom-[1%] px-10 py-5 lg:p-10 w-full flex items-center justify-between">
            <p className="text-center text-[#FAF5EE]   font-medium">
              {t("Wedding Invitation")}
            </p>
          </div>
        </div>
        <div className="px-7 md:px-14 lg:px-[120px] py-10 bg-zinc-100">
          <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className="absolute border-4 border-red w-full md:w-2/5 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 px-8 py-6 bg-white rounded-2xl shadow flex-col justify-center items-start inline-flex">
              <div className="w-full ">
                <div className="flex justify-end"></div>
                <div className="flex flex-col items-center gap-2 mb-4">
                  <p className="text-center text-xl text-red">{t("Add story")}</p>
                  <div className="grid grid-cols-2 gap-3 w-full">
                    <div className="flex flex-col justify-start items-start gap-2 w-full">
                      <div>
                        <label className="text-zinc-800 text-base font-normal leading-snug">
                          {t("Date")}
                        </label>
                        <span className="text-red-500 text-base font-normal leading-snug">
                          *
                        </span>
                      </div>
                      <input
                        type="date"
                        onChange={(e)=> setAddStoryDate(e.target.value)}
                        className="w-full h-12 px-4 py-3 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 font-normal leading-tight text-sm placeholder-[#808080]"
                      />
                    </div>
                    <div className="flex flex-col justify-start items-start gap-2 w-full">
                      <div>
                        <label className="text-zinc-800 text-base font-normal leading-snug">
                          {t("About Story")}
                        </label>
                        <span className="text-red-500 text-base font-normal leading-snug">
                          *
                        </span>
                      </div>
                      <input
                        type="text"
                        onChange={(e)=> setAddStoryAbout(e.target.value)}
                        placeholder={t("About Story")}
                        className="w-full h-12 px-4 py-3 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 font-normal leading-tight text-sm placeholder-[#808080]"
                      />
                    </div>
                  </div>
                </div>
                <div className="w-full flex justify-center gap-2">
                  {/* Button to cancel */}
                  <button
                    onClick={handleClose}
                    className="h-9 w-[105px] px-[18px] py-[6.40px] bg-white rounded-lg shadow border border-red justify-center items-center gap-2.5 inline-flex"
                  >
                    <span className="text-center text-red text-base font-normal font-['Roboto'] leading-normal">
                      {t("Cancel")}
                    </span>
                  </button>
                  {/* Button to add notes */}
                  <button
                    onClick={handleAddStory}
                    className=" h-9 w-[105px] px-[18px] py-[6.40px] bg-red rounded-lg shadow border border-red justify-center items-center gap-2.5 inline-flex"
                  >
                    <span className="text-center text-white text-base font-normal font-['Roboto'] leading-normal">
                      + {t("Add")}
                    </span>
                  </button>
                </div>
              </div>
            </Box>
          </Modal>
          {invitation.loading && <Loader slim />}
          {!loading && !invitation.loading && (
            <form
              onSubmit={handleSubmit(onSubmit)}
              encType="multipart/form-data"
            >
              <div className={formStep === 0 ? "block" : "hidden"}>
                <div className="w-full justify-between items-center inline-flex mb-6">
                  <div className="text-neutral-800 text-lg md:text-2xl font-semibold leading-9">
                    {t("Edit Wedding Invitation")}
                  </div>
                  <div className="justify-start items-center gap-2 flex">
                    <div className="text-neutral-800 text-[12px] md:text-base font-normal leading-tight">
                      {t("Step 1 of 3")}
                    </div>
                    <div className="justify-start items-start gap-2 flex">
                      <div className="w-3 h-3 md:w-4 md:h-4 bg-red rounded-full" />
                      <div className="w-3 h-3 md:w-4 md:h-4 bg-zinc-300 rounded-full" />
                      <div className="w-3 h-3 md:w-4 md:h-4 bg-zinc-300 rounded-full" />
                    </div>
                  </div>
                </div>
                <div>
                <div>
                <div className=" p-8 bg-white rounded-3xl mb-6">
                <div className="flex items-start mb-5 ">
                     <p className=" text-neutral-800 text-lg md:text-2xl font-normal"> {t("Personal Information's")}</p> <span className="text-red-500 text-base font-normal leading-snug">
                      *
                    </span> 
                    </div>
                    <div className="flex-col md:grid grid-cols-2 gap-x-6 gap-y-4">
                    <div className="flex flex-col justify-start items-start gap-2 ">
                      <div>
                        <label className="text-zinc-800 text-base font-normal leading-snug">
                          {t("Bride Name")}
                        </label>
                        <span className="text-red-500 text-base font-normal leading-snug">
                          *
                        </span>
                      </div>
                      <input
                        placeholder={t("Name")}
                        defaultValue={invitation.data.bride_name}
                        {...register("bride_name", {
                          required: t("Bride Name is required"),
                        })}
                        onChange={(event) => {
                          handleInvitationClick(
                            defaultInvitation,
                            event.target.value,
                            "bride_name"
                          );
                        }}
                        className="w-full h-12 px-4 py-3 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 font-normal leading-tight text-sm placeholder-[#808080]"
                      />
                      <Typography variant="small" color="red">
                        {errors.bride_name ? errors.bride_name?.message : ""}
                      </Typography>
                    </div>
                    <div className="flex flex-col justify-start items-start gap-2 ">
                      <div>
                        <label className="text-zinc-800 text-base font-normal leading-snug">
                          {t("Groom Name")}
                        </label>
                        <span className="text-red-500 text-base font-normal leading-snug">
                          *
                        </span>
                      </div>
                      <input
                        placeholder={t("Name")}
                        defaultValue={invitation.data.groom_name}
                        {...register("groom_name", {
                          required: t("Groom Name is required"),
                        })}
                        onChange={(event) => {
                          handleInvitationClick(
                            defaultInvitation,
                            event.target.value,
                            "groom_name"
                          );
                        }}
                        className="w-full h-12 px-4 py-3 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 font-normal leading-tight text-sm placeholder-[#808080]"
                      />
                      <Typography variant="small" color="red">
                        {errors.groom_name ? errors.groom_name?.message : ""}
                      </Typography>
                    </div>
                    <div className="flex flex-col justify-start items-start gap-2 ">
                      <div>
                        <label className="text-zinc-800 text-base font-normal leading-snug">
                          {t("Wedding Address")}
                        </label>
                        <span className="text-red-500 text-base font-normal leading-snug">
                          *
                        </span>
                      </div>
                      <input
                        placeholder={t("Address")}
                        defaultValue={invitation.data.wedding_address}
                        {...register("wedding_address", {
                          required: t("Wedding Address is required"),
                        })}
                        onChange={(event) => {
                          handleInvitationClick(
                            defaultInvitation,
                            event.target.value,
                            "wedding_address"
                          );
                        }}
                        className="w-full h-12 px-4 py-3 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 font-normal leading-tight text-sm placeholder-[#808080]"
                      />
                      <Typography variant="small" color="red">
                        {errors.wedding_address
                          ? errors.wedding_address?.message
                          : ""}
                      </Typography>
                    </div>
                    <div className="flex flex-col justify-start items-start gap-2 ">
                      <div>
                        <label className="text-zinc-800 text-base font-normal leading-snug">
                          {t("Wedding location")}
                        </label>
                        <span className="text-red-500 text-base font-normal leading-snug">
                          *
                        </span>
                      </div>
                      <input
                        placeholder={t("Location")}
                        defaultValue={invitation.data.wedding_location}
                        {...register("location", {
                          required: t("Wedding location is required"),
                        })}
                        className="w-full h-12 px-4 py-3 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 font-normal leading-tight text-sm placeholder-[#808080]"
                      />
                      <Typography variant="small" color="red">
                        {errors.location ? errors.location?.message : ""}
                      </Typography>
                    </div>
                    <div className="flex flex-col justify-start items-start gap-2 ">
                      <div>
                        <label className="text-zinc-800 text-base font-normal leading-snug">
                          {t("Wedding date")}
                        </label>
                        <span className="text-red-500 text-base font-normal leading-snug">
                          *
                        </span>
                      </div>
                      <input
                        type="date"
                        defaultValue={invitation.data.wedding_date}
                        {...register("wedding_date", {
                          required: t("Wedding date is required"),
                          validate: (value) =>
                            new Date(value) > new Date()
                              ? true
                              : t("Wedding date must be in the future"),
                        })}
                        onChange={(event) => {
                          handleInvitationClick(
                            defaultInvitation,
                            event.target.value,
                            "wedding_date"
                          );
                        }}
                        className="w-full h-12 px-4 py-3 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 font-normal leading-tight text-sm placeholder-[#808080]"
                      />
                      <Typography variant="small" color="red">
                        {errors.wedding_date
                          ? errors.wedding_date?.message
                          : ""}
                      </Typography>
                    </div>
                    <div className="flex flex-col justify-start items-start gap-2 ">
                      <div>
                        <label className="text-zinc-800 text-base font-normal leading-snug">
                          {t("Wedding time")}
                        </label>
                        <span className="text-red-500 text-base font-normal leading-snug">
                          *
                        </span>
                      </div>
                      <input
                        type="time"
                        defaultValue={invitation.data.wedding_time}
                        {...register("wedding_time", {
                          required: t("Wedding time is required"),
                        })}
                        onChange={(event) => {
                          handleInvitationClick(
                            defaultInvitation,
                            event.target.value,
                            "wedding_time"
                          );
                        }}
                        className="w-full h-12 px-4 py-3 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 font-normal leading-tight text-sm placeholder-[#808080]"
                      />
                      <Typography variant="small" color="red">
                        {errors.wedding_time
                          ? errors.wedding_time?.message
                          : ""}
                      </Typography>
                    </div>
                    <div className="flex flex-col justify-start items-start gap-2 ">
                      <div>
                        <label className="text-zinc-800 text-base font-normal leading-snug">
                          {t("About Bride")}
                        </label>
                      </div>
                      <textarea
                        placeholder={t("About Bride")}
                        defaultValue={invitation.data.about_bride}
                        {...register("about_bride")}
                        className="w-full h-24 px-4 py-3 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 font-normal leading-tight text-sm placeholder-[#808080]"
                      />
                    </div>
                    <div className="flex flex-col justify-start items-start gap-2 ">
                      <div>
                        <label className="text-zinc-800 text-base font-normal leading-snug">
                          {t("About Groom")}
                        </label>
                      </div>
                      <textarea
                        placeholder={t("About Groom")}
                        defaultValue={invitation.data.about_groom}
                        {...register("about_groom")}
                        className="w-full h-24 px-4 py-3 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 font-normal leading-tight text-sm placeholder-[#808080]"
                      />
                    </div>
                    </div>
                    <div className="col-span-2 flex flex-col justify-start items-start gap-2 ">
                      <div>
                        <label className="text-zinc-800 text-base font-normal leading-snug">
                          {t("Notes for the wedding")}
                        </label>
                      </div>
                      <textarea
                        placeholder={t("Notes for the wedding")}
                        defaultValue={invitation.data.notes}
                        {...register("notes")}
                        className="w-full h-24 px-4 py-3 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 font-normal leading-tight text-sm placeholder-[#808080]"
                      />
                    </div>
                  </div>
                  <div className="flex items-center justify-between">
                    <button
                      onClick={() => navigate(`/invitation/view/${params.id}`)}
                      type="button"
                      className="w-[150px] h-12 px-8 py-2 rounded-lg border border-red justify-center items-center inline-flex"
                    >
                      <span className="text-red text-sm font-medium leading-[14px] tracking-wide">
                        {t("Back")}
                      </span>
                    </button>
                    <button
                      onClick={() => {
                        setFormStep(1);
                        trigger([
                          "groom_name",
                          "bride_name",
                          "wedding_address",
                          "location",
                          "wedding_date",
                          "wedding_time",
                        ]);
                      }}
                      type="button"
                      className={`w-[150px] h-12 px-8 py-2 rounded-lg border  ${
                        errors.groom_name ||
                        errors.bride_name ||
                        errors.wedding_address ||
                        errors.location ||
                        errors.wedding_date ||
                        errors.wedding_time
                          ? "bg-[#CCCCCC]"
                          : "bg-red"
                      } justify-center items-center inline-flex text-white text-sm font-medium leading-[14px] tracking-wide`}
                    >
                      <span className="text-white text-sm font-medium leading-[14px] tracking-wide">
                        {t("Next")}
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              </div>
              <div className={formStep === 1 ? "block" : "hidden"}>
                <div className="w-full justify-between items-center inline-flex mb-6">
                  <div className="text-neutral-800  text-base text-balance sm:text-lg md:text-2xl font-semibold sm:leading-9">
                    {t("Choose Invitation Style")}
                  </div>
                  <div className="justify-start items-center gap-2 flex">
                    <div className="text-neutral-800 text-[12px] md:text-base font-normal leading-tight">
                      {t("Step 2 of 3")}
                    </div>
                    <div className="justify-start items-start gap-2 flex">
                      <div className="w-3 h-3 md:w-4 md:h-4 bg-zinc-300 rounded-full" />
                      <div className="w-3 h-3 md:w-4 md:h-4 bg-red rounded-full" />
                      <div className="w-3 h-3 md:w-4 md:h-4 bg-zinc-300 rounded-full" />
                    </div>
                  </div>
                </div>
                {invitaionLoading && <Loader slim />}
                <div className="mb-10 p-8 bg-white rounded-3xl">
                <div className="grid grid-cols-3 md:grid-cols-6 gap-6 ">
                  {!invitaionLoading && selectedInvitation && (
                    <div
                      className={`col-span-6 h-[600px] bg-zinc-100 rounded-2xl flex justify-center items-center`}
                    >
                      <img
                        src={selectedInvitation}
                        alt="galleryIcon"
                        className="h-full"
                      />
                    </div>
                  )}
                  </div>
                  <div className="grid grid-cols-2 md:grid-cols-6 gap-6 mt-4">
                  {invitations.map((invitation) => {
                    const invitationId = invitation.id;
                    return (
                      <div key={invitationId} className="relative">
                        <Controller
                          name={`invitation_style`}
                          control={control}
                          // defaultValue={invitations[0].value}
                          value={invitation.value}
                          render={({ field }) => (
                            <div
                              className={`h-[250px] flex-col justify-start items-center gap-4 inline-flex cursor-pointer `}
                              onClick={() => {
                                field.onChange(invitation.value);
                                handleInvitationClick(invitation);
                              }}
                            >
                              <img
                                src={invitation.src}
                                alt={invitation.alt}
                                className={`h-[200px] rounded-[211px] shadow-lg ${
                                  invitaionId?.id === invitationId
                                    ? "border-[3px] border-red"
                                    : ""
                                }`}
                              />
                              <div className="text-center text-zinc-800 text-base font-normal leading-snug">
                                {invitation.label}
                              </div>
                            </div>
                          )}
                        />
                      </div>
                    );
                  })}
                  </div>
                
</div>
                <div className="flex items-center justify-between">
                  <button
                    onClick={() => setFormStep(0)}
                    type="button"
                    className="w-[150px] h-12 px-8 py-2 rounded-lg border border-red justify-center items-center inline-flex"
                  >
                    <span className="text-red text-sm font-medium leading-[14px] tracking-wide">
                      {t("Back")}
                    </span>
                  </button>
                  <button
                    onClick={() => {
                      setFormStep(2);
                    }}
                    type="button"
                    className="w-[150px] h-12 px-8 py-2 rounded-lg border bg-red justify-center items-center inline-flex"
                  >
                    <span className="text-white text-sm font-medium leading-[14px] tracking-wide">
                      {t("Next")}
                    </span>
                  </button>
                </div>
              </div>
              <div className={formStep === 2 ? "block" : "hidden"}>
                <div className="w-full justify-between items-center inline-flex mb-6">
                  <div className="text-neutral-800 text-2xltext-lg md:text-2xl font-semibold leading-9">
                    {t("Wedding Invitation")}
                  </div>
                  <div className="justify-start items-center gap-2 flex">
                    <div className="text-neutral-800 text-[12px] md:text-base font-normal leading-tight">
                      {t("Step 3 of 3")}
                    </div>
                    <div className="justify-start items-start gap-2 flex">
                      <div className="w-3 h-3 md:w-4 md:h-4 bg-zinc-300 rounded-full" />
                      <div className="w-3 h-3 md:w-4 md:h-4 bg-zinc-300 rounded-full" />
                      <div className="w-3 h-3 md:w-4 md:h-4 bg-red rounded-full" />
                    </div>
                  </div>
                </div>
                <div className="p-8 bg-white rounded-3xl mb-10">
                  <div className="flex justify-between items-center mb-4">
                    <p className="text-neutral-800 text-2xl font-semibold leading-9 flex items-center gap-1">
                      {t("Our Album")}
                      <span className="text-[#999999] text-base font-normal">
                        {t("(optional)")}
                      </span>
                    </p>
                    {/* <button
                    type="button"
                    className="h-12 text-base text-red justify-center items-center inline-flex gap-1"
                  >
                    <img src={uploadIcon} alt="uploadIcon" />
                    <span> Upload Bulk Photos</span>
                  </button> */}
                  </div>
                  <div className="">
                    {invitation.data?.images.length < 5 && (
                      <div className="flex flex-col justify-start items-start gap-2">
                        <label className="text-zinc-800 text-base font-normal leading-snug flex items-center gap-1">
                          {t("Add Images")}
                          <span className="text-[#999999] text-base font-normal">
                            {t("(Max 5)")}
                          </span>
                        </label>
                        <div className="relative w-full bg-slate-100 border-slate-100 rounded-lg">
                          <label>
                            <div className="h-12 flex justify-between items-center">
                              <input
                                type="file"
                                id="mediafileInput"
                                className="absolute -top-48 left-0 opacity-0 cursor-pointer w-20"
                                {...register("image")}
                                onChange={(e) => {
                                  handleAddImage(e);
                                }}
                              />
                              {/* Button to trigger file input */}
                              <label
                                htmlFor="mediafileInput"
                                className="cursor-pointer"
                              >
                                <div className={` absolute  ${i18n.language === 'en' ? 'right-0' : 'left-0'}  bottom-0 h-12 px-2 md:px-6 py-4 text-base bg-white text-red rounded-lg border border-red justify-center items-center inline-flex gap-1 md:gap-2`}>
                                  <img src={uploadIcon} alt="uploadIcon" />
                                  <span> {t("Add")}</span>
                                </div>
                              </label>
                              {/* Input field to display the selected file names */}
                              <input
                                id="mediaFileName"
                                placeholder={t("Photos")}
                                className="border-0 h-full flex-grow p-4 bg-slate-100 rounded-lg text-zinc-500 text-base font-normal leading-tight placeholder-[#808080]"
                                readOnly // Make the input field read-only to prevent manual editing
                              />
                            </div>
                          </label>
                        </div>
                        <p className="text-neutral-400 text-sm font-normal leading-tight">
                          {t("(Max Size 50 MB)")}
                        </p>
                      </div>
                    )}
                    {/* Container to display uploaded images */}
                    {imagesLoading && <Loader slim />}
                    {!imagesLoading && (
                      <div id="uploadedImages" className="flex flex-wrap mt-2">
                        {invitation.data?.images.map((img) => (
                          <div className="relative">
                            <img
                              src={img.image}
                              alt={img.id}
                              className="h-24 w-20 object-cover rounded-lg mr-2 mb-2"
                            />
                            <div
                              onClick={() => handleDeleteImage(img)}
                              className="bg-white rounded-full absolute top-1 right-2.5 w-6 h-6 cursor-pointer flex justify-center items-center"
                            >
                              <img
                                src={deleteIcon}
                                alt="deleteIcon"
                                className=""
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
                <div className="p-8 bg-white rounded-3xl mb-10">
                  <div className="flex justify-between items-center mb-4">
                    <p className="text-neutral-800 text-2xl font-semibold leading-9 flex items-center gap-1">
                      {t("Our Story")}
                      <span className="text-[#999999] text-base font-normal">
                        {t("(optional)")}
                      </span>
                    </p>
                  </div>
                  <div className="flex flex-col gap-4">
                    {dateInputs.map((input, index) => (
                      <div
                        key={input.id}
                        className="grid grid-cols-5 gap-6 md:pb-4 pb-2"
                      >
                        <div className="md:col-span-2 col-span-5 flex flex-col justify-start items-start gap-2 w-full">
                          <div>
                            <label className="text-zinc-800 text-base font-normal leading-snug">
                              {t("Date")}
                            </label>
                            <span className="text-red-500 text-base font-normal leading-snug">
                              *
                            </span>
                          </div>
                          <input
                            type="date"
                            defaultValue={input.date}
                            // value={input.date}
                            onChange={(e) =>
                              handleChange(index, "date", e.target.value)
                            }
                            className="w-full h-12 px-4 py-3 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 font-normal leading-tight text-sm placeholder-[#808080]"
                          />
                        </div>
                        <div className="md:col-span-2 col-span-5 flex flex-col justify-start items-start gap-2 w-full">
                          <div>
                            <label className="text-zinc-800 text-base font-normal leading-snug">
                              {t("About Story")}
                            </label>
                            <span className="text-red-500 text-base font-normal leading-snug">
                              *
                            </span>
                          </div>
                          <input
                            type="text"
                            defaultValue={input.aboutStory}
                            // value={input.aboutStory}
                            onChange={(e) =>
                              handleChange(index, "aboutStory", e.target.value)
                            }
                            placeholder="About Story"
                            className="w-full h-12 px-4 py-3 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 font-normal leading-tight text-sm placeholder-[#808080]"
                          />
                        </div>
                        <div className=" inline-flex md:flex-col lg:flex-row justify-between col-span-6 md:col-span-1 md:-ml-2">
                          <button
                            type="button"
                            onClick={() => handleToggleRow(input.id)}
                            className="flex items-center gap-1 md:gap-1 mt-6 mx-auto"
                          >
                            <img
                              src={
                                hiddenRows.includes(input.id)
                                  ? eyeIcon
                                  : eyeOffIcon
                              }
                              alt={
                                hiddenRows.includes(input.id) ? t("Show") : t("Hide")
                              }
                            />
                            <span
                              className={`text-red text-base md:text-[14px] font-normal  leading-snug`}
                            >
                              {hiddenRows.includes(input.id) ? t("Show") : t("Hide")}
                            </span>
                          </button>
                          <button
                            type="button"
                            onClick={() => handleUpdateStory(input)}
                            className="flex items-center gap-1 mt-6 mx-auto "
                          >
                            <img src={editIconBtn} alt="editIconBtn" />
                            <span className="text-red text-base md:text-[14px] font-normal leading-snug">
                              {t("Update")}
                            </span>
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="grid grid-cols-5 mb-10">
                    <button
                      type="button"
                      onClick={() => setOpenModal(true)}
                      className="col-span-5 md:col-span-4 h-12 w-full pl-3 pr-4 py-2 rounded-lg text-base text-red border border-red justify-center items-center gap-2 inline-flex"
                    >
                      <FontAwesomeIcon
                        className="font-normal leading-[21px]"
                        icon={faPlus}
                      />
                      <span className="font-normal leading-[21px]">
                        {t("Add story")}
                      </span>
                    </button>
                  </div>
                  {dateInputs.some(
                    (input) => !hiddenRows.includes(input.id)
                  ) && (
                    <div className="bg-[#F2F2F2] px-10 py-10 rounded-3xl">
                      {dateInputs.map(
                        (story, index) =>
                          !hiddenRows.includes(story.id) && (
                            <div key={index} className="flex items-start">
                              {index % 2 === 0 ? (
                                <div className="items-end relative border-e-[3px] border-[#f1cfcf] flex flex-col md:px-8 px-2 w-1/2  h-full pt-0 pb-28 gap-2">
                                  <div className="absolute -top-[6px] -end-[19.5px]">
                                    <img src={doneIcon} alt="doneIcon" />
                                  </div>
                                  <h4 className="text-red text-[14px] px-1 sm:text-lg text-nowrap font-[Rochester]">
                                    {story.date || "mm/dd/yy"}
                                  </h4>
                                  <p>{story.aboutStory || t("about story")}</p>
                                </div>
                              ) : (
                                <div className="relative  flex justify-end md:px-8 px-2 w-1/2 h-full pt-0 pb-10 gap-2">
                                  <div className="absolute -top-[6px] -end-[19.5px]">
                                    <img src={doneIcon} alt="doneIcon" />
                                  </div>
                                  <div className="relative">
                                    <img
                                      src={story.image || placeholder}
                                      className="w-[150px] cursor-pointer object-cover rounded-3xl"
                                      alt="YourImage"
                                    />
                                  </div>
                                </div>
                              )}
                              {index % 2 === 0 ? (
                                <div className="flex justify-start px-2 md:px-8 w-1/2">
                                  <div className="relative">
                                    <img
                                      src={story.image || placeholder}
                                      className="w-full cursor-pointer object-cover rounded-3xl"
                                      alt="YourImage"
                                    />
                                  </div>
                                </div>
                              ) : (
                                <div className="flex flex-col border-s-[3px] border-[#f1cfcf] md:px-8 px-2 w-1/2 h-full pt-0 pb-28 gap-2">
                                  <h4 className="text-red text-[14px] px-1 sm:text-lg text-nowrap font-[Rochester]">
                                    {story.date || "mm/dd/yy"}
                                  </h4>
                                  <p>{story.aboutStory || t("about story")}</p>
                                </div>
                              )}
                            </div>
                          )
                      )}
                    </div>
                  )}
                </div>
                <div className="flex items-center justify-between">
                  <button
                    onClick={() => setFormStep(1)}
                    type="button"
                    className="w-[150px] h-12 px-8 py-2 rounded-lg border border-red justify-center items-center inline-flex"
                  >
                    <span className="text-red text-sm font-medium leading-[14px] tracking-wide">
                      {t("Back")}
                    </span>
                  </button>
                  <button
                    onClick={() => {
                      setFormStep(3);
                    }}
                    type="submit"
                    className="w-[150px] h-12 px-8 py-2 rounded-lg border bg-red justify-center items-center inline-flex"
                  >
                    <span className="text-white text-sm font-medium leading-[14px] tracking-wide">
                      {t("Edit")}
                    </span>
                  </button>
                </div>
              </div>
            </form>
          )}
        </div>
      </section>
    );
  }
  
  export default EditInvitation;
  