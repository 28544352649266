import React, { useState, useEffect } from 'react';
import { addHours } from 'date-fns';

const TableTicker = ({ date }) => {
  const [timeLeft, setTimeLeft] = useState('');

  useEffect(() => {
    const targetTime = addHours(new Date(date), 46);

    const updateTimer = () => {
      const now = new Date();
      const distanceInSeconds = Math.max(0, Math.floor((targetTime - now) / 1000));

      const hours = Math.floor(distanceInSeconds / 3600);
      const minutes = Math.floor((distanceInSeconds % 3600) / 60);
      const seconds = distanceInSeconds % 60;

      setTimeLeft(
        <p>
          <span>{String(hours).padStart(2, '0')}<span>Hr</span></span> :{' '}
          <span>{String(minutes).padStart(2, '0')}<span>Min</span></span> :{' '}
          <span>{String(seconds).padStart(2, '0')}<span>Sec</span></span>
        </p>
      );
    };

    updateTimer();
    const intervalId = setInterval(updateTimer, 1000);

    return () => clearInterval(intervalId);
  }, [date]);

  return <div>{timeLeft}</div>;
};

export default TableTicker;
