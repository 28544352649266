import React, { useState } from 'react';
import {FaHeart} from "react-icons/fa";
import {FiShoppingCart} from "react-icons/fi";
import {ViewStyle} from "../../views/content/Shop"; 
import placholder_image from "../../assets/images/booking/placeholder-image.jpg"
import {useTranslation} from "react-i18next";

function ServiceCard({ vendor, navigate, view, handleAddToCart, cartItems }) {
  const {t ,i18n } = useTranslation();
  const [showFullDescription , setShowFullDescripton] = useState(false);
  const [fullDescription , setFullDescription] = useState();

  const toggleDescription = (e , fullDescription) => {
    e.stopPropagation()
    setShowFullDescripton(!showFullDescription);
    setFullDescription(fullDescription)
  }
  return (
   <>
    {showFullDescription && (
      <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-10 bg-gray-800 bg-opacity-50">
        <div className="bg-white p-8 rounded-2xl border-4 border-red md:w-1/2 w-10/12 mx-auto">
          <p>{fullDescription}</p>
          <div className="flex justify-center">
            <button
              className="h-[42px] px-[18px] py-2.5 rounded-[10px] shadow border border-red justify-center items-center gap-2.5 inline-flex mt-4"
              onClick={toggleDescription}
            >
              <span className="text-center text-red text-base font-normal  leading-tight">
                {" "}
                {t("Close")}
              </span>
            </button>
          </div>
        </div>
      </div>
    )}
    <card
      className={
        view === ViewStyle.Grid
          ? "flex flex-col cursor-pointer"
          : "flex w-full gap-3 cursor-pointer pb-4 border-b"
      }
      onClick={() => navigate(`/service/${vendor.id}`)}
    >
      <div className="relative mb-6 ">
        <img
          className={
            view === ViewStyle.Grid
              ? "mx-auto rounded-lg h-[200px] w-full object-cover"
              : "mx-auto rounded-lg w-[225px] h-[150px] object-cover"
          }
          src={vendor.image || placholder_image}
          alt=""
        />
      </div>
      <div className={
        view === ViewStyle.Grid
          ? ""
          : "flex justify-between grow items-center"
      }>
        <div className="">
          <div
            className={
              view === ViewStyle.Grid
                ? "flex justify-between w-full"
                : "flex flex-col items-start"
            }
          >
            <span className="text-left  text-gray-dark text-lg font-extrabold leading-normal">
              {vendor.brand_name}
            </span>
            <span className="text-red text-lg font-semibold font-['Roboto'] leading-normal text-end">
              {vendor.city.name}
            </span>
          </div>
          <div className={
              view === ViewStyle.Grid
                ? "my-4"
                : "my-4 whitespace-nowrap"
            }>
            <p
              className="text-zinc text-sm font-normal leading-snug overflow-hidden line-clamp-2 max-w-[40vw]"
            >{vendor.about}</p>
          </div>
          <div className={
              view === ViewStyle.Grid
                ? "flex justify-between"
                : "flex flex-col items-start"
            }>
              {!showFullDescription && (
                        <button
                          className="text-red"
                          onClick={(e) => toggleDescription(e, vendor.about)}
                        >
                          {t("Read More")}
                        </button>
                      )}
            <span className="text-red text-sm font-semibold font-['Roboto'] leading-normal text-end"><span className="text-zinc text-sm font-normal leading-snug">{t("Starting price")}:</span> {vendor.min_service_price} EGP</span>
          </div>
          
        </div>
      </div>
    </card>
   </>
  );
}

export default ServiceCard;