import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const wishListSlice = createSlice({
  name: "wishList",
  initialState: { loading: false, venueItems: [], productsItems: []},
  reducers: {
    addToWishList(state, action) {
      const existingIndex = state.items.findIndex((item) => item.id === action.payload.id);

      if (existingIndex < 0) {
        state.items.push({ ...action.payload, qty: 1 });
      } else {
        state.items[existingIndex] = {
          ...state.items[existingIndex],
          qty: state.items[existingIndex].qty + 1,
        };
      }
      console.log("wishList items", state.venueItems);
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setVenuesWishList(state, action) {
      state.venueItems = action.payload.wishlist;
    },
    setProductsWishList(state, action) {
      state.productsItems = action.payload.products;
    },
    clear(state) {
      state.venueItems = [];
    },
  },
});

export const wishListActions = wishListSlice.actions;

export default wishListSlice.reducer;