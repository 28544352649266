import React, { useEffect, useState } from "react";
import Rating from "@mui/material/Rating";
import axios from "axios";
import { toast } from "react-toastify";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import useAxiosUserSecureConfig from "../../hooks/useAxiosUserSecureConfig";
import Loader from "../../components/Loader";
import {useTranslation} from "react-i18next";


function BookingReview() {
  const {t ,i18n } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const config = useAxiosUserSecureConfig();

  const [bookingItem, setBookingItem] = useState({
    loading: true,
    data: [null],
  });

  useEffect(() => {
    axios
      .post(`/api/venue-booking/${params.id}`, {}, config)
      .then((res) => {
        console.log("bookingReview", res.data);

        if (!res.data?.result?.success) {
          toast.error(t("Error while fetching bookings!"));
          return;
        }

        setBookingItem((prevState) => ({
          ...prevState,
          loading: false,
          data: res.data?.result?.reservation,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const [oldReview, setOldReview] = useState({ loading: true, data: [null] });
  useEffect(() => {
    axios
      .post(`/api/my-venue-reviews`, {}, config)
      .then((res) => {
        console.log("oldReview", res.data);

        if (!res.data?.result?.success) {
          toast.error(t("Error while fetching reviews!"));
          return;
        }

        setOldReview((prevState) => ({
          ...prevState,
          loading: false,
          data: res.data?.result?.reviews,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const onSubmit = (data) => {
    console.log("data", data);
    data = {
      venueId: parseInt(bookingItem.data.venue.id),
      averageResponseTime: parseInt(data.response),
      flexibility: parseInt(data.flexibility),
      professionalism: parseInt(data.professionalism),
      qualityOfService: parseInt(data.quality),
      value: parseInt(data.value),
      review: data.message,
    };

    axios
      .post("/api/venue-review", data, config)
      .then((res) => {
        console.log(res.data);

        if (!res.data.result?.success) {
          toast.error(res.data.result?.message || t("Request failed!"));
          return;
        }
        toast.success(t("Review sent successfully!"));
        navigate("/venues")
      })
      .catch((err) => {
        console.log(err);
        toast.error(t("Error while sending Review!"));
      });
  };

  const [matchingReview, setMatchingReview] = useState(null);
  useEffect(() => {
    const isVenueReviewed = () => {
      if (oldReview.loading || bookingItem.loading || !bookingItem.data || !bookingItem.data.venueId) {
        return null;
      }

      const venueIdToCheck = bookingItem.data.venueId;
      const matchingReview = oldReview.data.find(review => review.venue_id === venueIdToCheck);

      if (matchingReview) {
        // Set the matching review in state for further use if needed
        setMatchingReview(matchingReview);
      }

      return matchingReview;
    };

    isVenueReviewed();
  }, [oldReview, bookingItem]); 

  const { register, control, handleSubmit } = useForm();

  console.log("matchingReview",matchingReview)
  return (
    <section className="px-10 py-12">
      {oldReview.loading && <Loader slim />}
      {!oldReview.loading && !bookingItem.loading && matchingReview && (
        <>
          <p className="text-red text-2xl font-medium mb-8">{t("Your review")}</p>
          <div className="flex gap-8 items-start">
            {!bookingItem.loading && (
              <div className="p-6 bg-zinc-100 rounded-2xl gap-6 inline-flex w-1/2">
                <div className="w-1/3">
                  {" "}
                  <img
                    className="rounded-2xl"
                    src={bookingItem.data.venue.image}
                    alt="venue"
                  />
                </div>
                <div className="flex flex-col items-start">
                  <p className="text-center text-zinc-800 text-2xl font-medium uppercase mb-4">
                    {bookingItem.data.venue.facility.name}
                  </p>
                  <p className=" text-gray-500 text-xl font-medium font-['Roboto'] mb-3">
                    {bookingItem.data.venue.name}
                  </p>
                  <div className="flex gap-2">
                    <span className=" text-gray-500 text-base font-normal font-['Roboto']">
                      {t("Event date")} :
                    </span>
                    <p className=" text-red text-base font-normal font-['Roboto']">
                      {bookingItem.data.date}
                    </p>
                  </div>
                </div>
              </div>
            )}
            <form className="w-1/2 flex flex-col gap-8">
              <div className="p-6 bg-zinc-100 rounded-2xl">
                <div className="flex justify-between items-center">
                  <p className="text-zinc-800 text-2xl font-normal">
                    <span className="text-gray-500 text-xl font-normal uppercase me-2 border-e border-gray-500 pe-2">
                      1
                    </span>
                    {t("Quality of service")}
                  </p>
                  <Rating
                    name="quality"
                    value={matchingReview.rating_quality_of_service}
                    precision={0.5}
                    readOnly
                  />
                </div>
              </div>
              <div className="p-6 bg-zinc-100 rounded-2xl">
                <div className="flex justify-between items-center">
                  <p className="text-zinc-800 text-2xl font-normal">
                    <span className="text-gray-500 text-xl font-normal uppercase me-2 border-e border-gray-500 pe-2">
                      2
                    </span>
                    {t("Professionalism")}
                  </p>
                  <Rating
                    name="professionalism"
                    value={matchingReview.rating_professionalism}
                    precision={0.5}
                    readOnly
                  />
                </div>
              </div>
              <div className="p-6 bg-zinc-100 rounded-2xl">
                <div className="flex justify-between items-center">
                  <p className="text-zinc-800 text-2xl font-normal">
                    <span className="text-gray-500 text-xl font-normal uppercase me-2 border-e border-gray-500 pe-2">
                      3
                    </span>
                    {t("Flexibility")}
                  </p>

                  <Rating
                    name="flexibility"
                    value={matchingReview.rating_flexibility}
                    precision={0.5}
                    readOnly
                  />
                </div>
              </div>
              <div className="p-6 bg-zinc-100 rounded-2xl">
                <div className="flex justify-between items-center">
                  <p className="text-zinc-800 text-2xl font-normal">
                    <span className="text-gray-500 text-xl font-normal uppercase me-2 border-e border-gray-500 pe-2">
                      4
                    </span>
                    {t("Value")}
                  </p>

                  <Rating
                    name="value"
                    value={matchingReview.rating_value}
                    precision={0.5}
                    readOnly
                  />
                </div>
              </div>
              <div className="p-6 bg-zinc-100 rounded-2xl">
                <div className="flex justify-between items-center">
                  <p className="text-zinc-800 text-2xl font-normal">
                    <span className="text-gray-500 text-xl font-normal uppercase me-2 border-e border-gray-500 pe-2">
                      5
                    </span>
                    {t("Average response time")}
                  </p>

                  <Rating
                    name="response"
                    value={matchingReview.rating_average_response_time}
                    precision={0.5}
                    readOnly
                  />
                </div>
              </div>
              <input
                value={matchingReview.review}
                disabled
                className="p-6 bg-zinc-100 rounded-2xl"
              />
            </form>
          </div>
        </>
      )}

      {!oldReview.loading && !bookingItem.loading && !matchingReview && (
        <>
          <p className="text-red text-2xl font-medium mb-8">{t("Review")}</p>
          <div className="flex gap-8 items-start">
            {!bookingItem.loading && (
              <div className="p-6 bg-zinc-100 rounded-2xl gap-6 inline-flex w-1/2">
                <div className="w-1/3">
                  {" "}
                  <img
                    className="rounded-2xl"
                    src={bookingItem.data.venue.image}
                    alt="venue"
                  />
                </div>
                <div className="flex flex-col items-start">
                  <p className="text-center text-zinc-800 text-2xl font-medium uppercase mb-4">
                    {bookingItem.data.venue.facility.name}
                  </p>
                  <p className=" text-gray-500 text-xl font-medium font-['Roboto'] mb-3">
                    {bookingItem.data.venue.name}
                  </p>
                  <div className="flex gap-2">
                    <span className=" text-gray-500 text-base font-normal font-['Roboto']">
                      {t("Event date")} :
                    </span>
                    <p className=" text-red text-base font-normal font-['Roboto']">
                      {bookingItem.data.date}
                    </p>
                  </div>
                </div>
              </div>
            )}
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="w-1/2 flex flex-col gap-8"
            >
              <div className="p-6 bg-zinc-100 rounded-2xl">
                <div className="flex justify-between items-center">
                  <p className="text-zinc-800 text-2xl font-normal">
                    <span className="text-gray-500 text-xl font-normal uppercase me-2 border-e border-gray-500 pe-2">
                      1
                    </span>
                    {t("Quality of service")}
                  </p>
                  <Controller
                    name="quality"
                    control={control}
                    defaultValue={0}
                    render={({ field }) => (
                      <Rating
                        {...field}
                        name="quality"
                        defaultValue={0}
                        precision={0.5}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="p-6 bg-zinc-100 rounded-2xl">
                <div className="flex justify-between items-center">
                  <p className="text-zinc-800 text-2xl font-normal">
                    <span className="text-gray-500 text-xl font-normal uppercase me-2 border-e border-gray-500 pe-2">
                      2
                    </span>
                    {t("Professionalism")}
                  </p>
                  <Controller
                    name="professionalism"
                    control={control}
                    defaultValue={0}
                    render={({ field }) => (
                      <Rating
                        {...field}
                        name="professionalism"
                        defaultValue={0}
                        precision={0.5}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="p-6 bg-zinc-100 rounded-2xl">
                <div className="flex justify-between items-center">
                  <p className="text-zinc-800 text-2xl font-normal">
                    <span className="text-gray-500 text-xl font-normal uppercase me-2 border-e border-gray-500 pe-2">
                      3
                    </span>
                    {t("Flexibility")}
                  </p>
                  <Controller
                    name="flexibility"
                    control={control}
                    defaultValue={0}
                    render={({ field }) => (
                      <Rating
                        {...field}
                        name="flexibility"
                        defaultValue={0}
                        precision={0.5}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="p-6 bg-zinc-100 rounded-2xl">
                <div className="flex justify-between items-center">
                  <p className="text-zinc-800 text-2xl font-normal">
                    <span className="text-gray-500 text-xl font-normal uppercase me-2 border-e border-gray-500 pe-2">
                      4
                    </span>
                    {t("Value")}
                  </p>
                  <Controller
                    name="value"
                    control={control}
                    defaultValue={0}
                    render={({ field }) => (
                      <Rating
                        {...field}
                        name="value"
                        defaultValue={0}
                        precision={0.5}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="p-6 bg-zinc-100 rounded-2xl">
                <div className="flex justify-between items-center">
                  <p className="text-zinc-800 text-2xl font-normal">
                    <span className="text-gray-500 text-xl font-normal uppercase me-2 border-e border-gray-500 pe-2">
                      5
                    </span>
                    {t("Average response time")}
                  </p>
                  <Controller
                    name="response"
                    control={control}
                    defaultValue={0}
                    render={({ field }) => (
                      <Rating
                        {...field}
                        name="response"
                        defaultValue={0}
                        precision={0.5}
                      />
                    )}
                  />
                </div>
              </div>
              <input
                {...register("message")}
                placeholder={t("Message")}
                className="p-6 bg-zinc-100 rounded-2xl"
              />
              <div className="flex gap-3 items-center justify-end">
                <button className="h-[47px] px-9 py-[6.40px] bg-white rounded-[10px] shadow border border-red justify-center items-center gap-2.5 inline-flex">
                  <span className="text-center text-red text-base font-normal leading-normal">
                    {t("Cancel")}
                  </span>
                </button>
                <button
                  type="submit"
                  className="h-[47px] px-9 py-[6.40px] bg-red rounded-[10px] shadow border border-white justify-center items-center gap-2.5 inline-flex"
                >
                  <span className="text-center text-white text-base font-normal leading-normal">
                    {t("Save")}
                  </span>
                </button>
              </div>
            </form>
          </div>
        </>
      )}
    </section>
  );
}

export default BookingReview;
