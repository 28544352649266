import React, { useEffect, useState } from 'react'
import facilityImg from "../../assets/images/feature-1.png"
import { FaEdit } from 'react-icons/fa';
import { RiDeleteBin6Line } from "react-icons/ri";
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import useAxiosUserSecureConfig from '../../hooks/useAxiosUserSecureConfig';
import Loader from '../../components/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import ConfirmationModal from '../../components/ConfirmationModal';
import Info from '../Info';
import {useTranslation} from "react-i18next";

function VendorFacilities() {
  const { t, i18n } = useTranslation();
  const config = useAxiosUserSecureConfig();
  const [myFacilities ,setMyFacilities] = useState({ loading: true, data: [null] })

 const fetchData =() => {
    axios.post('/api/venue-vendor/my-facilities' , {} , config).then((res) => {
      console.log("my-facilities", res.data);
  
      if (!res.data?.result?.success) {
        toast.error("Error while fetching bookings!");
        return;
      }
  
      setMyFacilities((prevState) => ({
        ...prevState,
        loading: false,
        data: res.data?.result?.facilities.filter((key) => key.enabled_by_user === true)
      }));

    }).catch((err) => {
      console.log(err);
    })
    
  }
  useEffect(() => {
    fetchData();
  }, []);
  const navigate = useNavigate();
  const [open, setOpen] =useState(false);
  const [facilityId, setFacilityId] =useState(false);
  const handleClose = () => setOpen(false); 
  const handleDeleteButton = (e, id) => {
    e.stopPropagation();
    setOpen(true);
    setFacilityId(id)
  }
  const handleDeleteFacility = () => {
    let data = {
      facility_id: facilityId
  };
  setMyFacilities((prevState) => ({
    ...prevState,
    loading: true,
  }));
  setOpen(false)
  axios.post('/api/venue-vendor/delete-facility' ,data, config)
      .then((res) => {
        if (!res.data.result?.success) {
          toast.error(res.data.result?.message || "Request failed!");
          return;
        }
        setMyFacilities((prevState) => ({
          ...prevState,
          loading: false,
        }));
        toast.success(t("You delete facility successfully!"));
        fetchData()
        
      })
      .catch((err) => {
        console.log("err" , err)
        toast.error("Error while deleting facility!");
        setMyFacilities((prevState) => ({
          ...prevState,
          loading: false,
        }));
      })
  }
  const handleClick = (e , id) => {
    e.stopPropagation();
    navigate(`/vendor/Facilities/${id}`);
  }
  return (
    <section className="px-2 md:px-10 py-12">
      <ConfirmationModal
      open={open}
      handleClose={handleClose}
      handleAction={handleDeleteFacility}
      msg={t("Are you sure you want to delete this facility?")}
    />
      <div className="flex justify-between items-center flex-wrap md:flex-nowrap px-5 mb-10">
      <div className="justify-start items-center gap-2  hidden sm:inline-flex">
        <div onClick={()=>navigate('/vendor/account')} className="text-zinc-400 text-base font-normal leading-[48px] hover:text-gray-dark cursor-pointer">
          {t("My Account")}
        </div>
        <FontAwesomeIcon
          className="text-zinc-400 text-base"
          icon={faAngleRight}
        />
        <div className="text-zinc-400 text-base font-normal leading-[48px] hover:text-gray-dark cursor-pointer">
          {t("My Facilities")}
        </div>
      </div>
        <Link to={"/vendor/add-facility"}  className="px-8 py-[9px] rounded-[10px] shadow border border-red justify-center items-center gap-2.5 flex">
          <span className="text-center text-red text-base font-normal font-['Inter'] leading-tight">
            {t("Add Facility")}
          </span>
        </Link>
      </div>
      {!myFacilities.loading && myFacilities.data.length < 1 && (
          <Info
            msg={t("No results found.")}
            description={t("Please refresh to show results.")}
          />
        )}
      {myFacilities.loading && <Loader slim />}
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-3 lg:gap-6">
        {!myFacilities.loading && myFacilities.data.map((facility)=> (
          <button onClick={(e)=>handleClick(e, facility.id)} className="py-4 px-1 bg-zinc-100 rounded-[10px] border border-[#B3B3B3] justify-evenly items-start inline-flex">
          <div className="flex gap-3 lg:gap-6 px-2">
            <div className="rounded-xl shadow flex-col justify-start items-start gap-4 inline-flex w-32">
              <img
                className="w-32 rounded-[10px] object-cover"
                src={facility.image}
                alt="facilityImg"
              />
            </div>
            <div className="flex flex-col gap-1 lg:gap-3">
              <p className="text-gray-dark text-base md:text-xl font-medium uppercase">
                {facility.name}
              </p>
              <p className="text-gray-500 text-base font-light">
                {facility.state.name}
              </p>
            </div>
          </div>
          <div className=" flex flex-wrap gap-2 items-center"> 
            <Link to={`/vendor/edit-facility/${facility.id}`}  className="p-2 rounded-[10px] shadow border border-red justify-center items-center flex">
              <FaEdit className="text-red text-base md:text-lg font-normal"/>
            </Link>
            <button onClick={(e)=> handleDeleteButton(e,facility.id)} className="p-2 rounded-[10px] shadow border border-red justify-center items-center flex">
            <RiDeleteBin6Line className="text-red text-base md:text-lg  font-normal"/>
            </button>

            </div>
        </button>
        ))}
      </div>
    </section>
  );
}

export default VendorFacilities
