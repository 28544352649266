import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Collapse,
  IconButton,
  MobileNav,
  Typography,
  collapse,
  MenuHandler,
  MenuList,
  Menu,
  MenuItem, ListItemSuffix,
} from "@material-tailwind/react";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faBars,
  faClose,
  faAngleDown,
  faUser, faTrash, faMessage, faInbox
} from "@fortawesome/free-solid-svg-icons";
import { FaRegHeart ,FaGlobeAfrica, FaGlobeAmericas  } from "react-icons/fa";
import { FiShoppingCart } from "react-icons/fi";
import {useDispatch, useSelector} from "react-redux";
import {uiActions} from "../../store/slicer/ui";
import r4bLogo from "../../assets/images/brand/r4b.png"
import {useTranslation} from "react-i18next";
import { useLocation } from "react-router-dom";
import logo from "../../assets/images/rose-bazaar.png";
import headerlogo from "../../assets/images/headerLogo.png";
import { useNavigate} from "react-router-dom";
import { getTotals } from '../../store/slicer/cartSlice';
import {Navbar, Dropdown, Avatar, Badge, Spinner} from 'flowbite-react';
import {authActions} from "../../store/slicer/auth";
import { CiUser } from "react-icons/ci";
import logIcon from "../../assets/images/icons/logout.svg";
import avatar from "../../assets/images/brand/avatar.png"
import userIcon from "../../assets/images/icons/user.svg"
import heartIcon from "../../assets/images/icons/heart.svg"
import cartIcon from "../../assets/images/icons/cart.svg"
import notifactionIcon from "../../assets/images/icons/notifications.svg"
import {totalItems} from "../../utils/utils";
import axios from "axios";
import {toast} from "react-toastify";
import useAxiosUserSecureConfig from "../../hooks/useAxiosUserSecureConfig";
import {getMessages} from "../../App";
import ConfirmationModal from '../ConfirmationModal';




function RegisteredUserHeader({ }) {

  const user = useSelector(state => state.auth.user)
  const messages = useSelector(state => state.ui.messages)
  const WishList = useSelector((state) => state.wishList);
  console.log("WishList" , WishList)

  const location = useLocation();
  function pathMatchRoute(route) {
    if (route === location.pathname) return true;
  }
  const [openNav, setOpenNav] = React.useState(false);
  const dark = useSelector(state => state.ui.dark);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const config = useAxiosUserSecureConfig();

  React.useEffect(() => {
    window.addEventListener(
        "resize",
        () => window.innerWidth >= 950 && setOpenNav(false)
    );
  }, []);



  const nav = [
    {
      name: t("Home"),
      to: "/",
    },
    {
      name: t("Wedding Venues"),
      to: "/venues",
    },
    {
      name: t("Wedding Services"),
      to: "/services",
    },
    {
      name: t("Store"),
      to: "/shop",
    },
    {
      name: t("Wedding Invitation"),
      to: "/invitation",
    },
    {
      name: t("Wedding Plan"),
      to: "/create-wedding-plan",
    },
    {
      name: t("Wedding Consultant"),
      to: "/consultant",
    },
    {
      name: t("About Us"),
      to: "/about",
    },
    
    {
      name: t("Contact Us"),
      to: "/contact",
    },
  ];

  const navigate = useNavigate();
  const cart = useSelector((state) => state.cart);
//   useEffect(() => {
//     dispatch(getTotals());
// }, [cart, dispatch]);


  const navList = (
      <ul className=" flex flex-col lg:flex-row lg:items-center mx-8 xl:mx-[150px] justify-between">
        {nav.map(item =>
            <Typography
                key={item.name}
                as="li"
                variant="small"
                className="cursor-pointer py-3 " 
            >
               
              <Link to={item.to} className={`lg:text-zinc text-white text-base font-normal font-['Roboto'] leading-none py-3 transition-all ease-linear
              hover:font-medium lg:hover:text-red  hover:text-gray-dark
              ${pathMatchRoute(item.to) && " !font-medium lg:!text-red !text-gray-dark  border-b-2 border-b-red"} `}>
                <span onClick={() => window.innerWidth <= 768 ? (setOpenNav(!openNav)):null}> {item.name}</span>
              </Link>
              
              {/* <span className="absolute bottom-0 left-1/2 w-0 transition-all h-[2px] bg-rose dark:bg-dark-50"></span> */}
              {/* <span className="absolute bottom-0 right-1/2 w-0 transition-all h-[2px] bg-rose dark:bg-dark-50"></span> */}
            </Typography>
        )}
      </ul>
  );
  const [open, setOpen] =useState(false);
  const handleClose = () => setOpen(false); 
  const handleLogout = () => {
    dispatch(authActions.logout());
    navigate('/');
  }
 

  const handleClearAll = () => {
    axios
      .post("/api/notification/read-all", {}, config)
      .then((res) => {
        if (!res.data.result?.success) {
          toast.error(res.data.result?.message || "Request failed!");
          return;
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error("Error while deleting notifications!");
      });
  };

  return (
    <>
      <ConfirmationModal
        open={open}
        handleClose={handleClose}
        handleAction={handleLogout}
        msg={t("Are you sure you want to logout?")}
      />
      <div className=" w-full shadow-md">
        <nav className="w-full py-2 md:py-3 relative z-50 top-0 h-max max-w-full px-4 lg:px-10 rounded-none bg-red border-0 ">
          <div className="flex items-center justify-between">
            <div className={"flex flex-wrap align-middle gap-x-1 "}>
              <Link to={"/"} className="">
                <div className="w-fit flex flex-col items-center">
                  <img src={logo} alt="" className="mx-auto w-12 md:w-16 " />
                  <div className="text-white text-[12px] md:text-[14px] font-medium font-['Roboto'] uppercase leading-[16.80px] tracking-wider">
                  Rose Bazaar
                  </div>
                </div>
              </Link>
            </div>
            <div className="flex items-center">
              <div className="flex items-center">
                {messages.loading && <Spinner className={"ml-3"} />}
                {!messages.loading && (
                  <Menu>
                    <MenuHandler>
                      <div className="relative cursor-pointer hidden lg:flex">
                        <button onClick={handleClearAll} className="text-2xl border-e px-6 h-3 flex items-center">
                          <img src={notifactionIcon} alt="notifactionIcon" />
                        </button>
                        {!!messages.items.length && (
                          <span className="bg-[#EB2606] absolute top-0 end-4 text-white w-4 h-4  text-[10px] flex items-center justify-center rounded-[50%]">
                            {messages.unread_count}
                          </span>
                        )}
                      </div>
                    </MenuHandler>
                    <MenuList className={"w-96"}>
                      {messages.items
                      .slice().reverse()
                        .slice(0, 5)
                        .map((message, index) => (
                          <MenuItem
                            key={index}
                            className={"flex flex-row justify-between"}
                          >
                            {(message.subject_type === "venue_reservation" &&
                              (message.subject_state === "new" ||
                                message.subject_state === "confirmed" ||
                                message.subject_state ===
                                  "pending_payment" ||
                                  message.subject_state === "cancelled") && (
                                <Link
                                  to={`/user/booking-list/${message.subject_id}`}
                                  variant={"small"}
                                  className={"my-auto w-full"}
                                >
                                  {message.message}
                                </Link>
                              )) ||
                              (message.subject_type === "venue_reservation" &&
                                message.subject_state === "declined" && (
                                  <Link
                                    to={`/venues`}
                                    variant={"small"}
                                    className={"my-auto w-full"}
                                  >
                                    {message.message}
                                  </Link>
                                )) ||
                              (message.subject_type === "venue_reservation" &&
                                message.subject_state === "paid" && (
                                  <Link
                                    to={`/booking-review/${message.subject_id}`}
                                    variant={"small"}
                                    className={"my-auto w-full"}
                                  >
                                    {message.message}
                                  </Link>
                                )) ||
                              (message.subject_type ===
                                "wedding_consultant_booking" &&
                                message.subject_state === "new" && (
                                  <Link
                                    to={`/ConsultationSession`}
                                    variant={"small"}
                                    className={"my-auto w-full"}
                                  >
                                    {message.message}
                                  </Link>
                                )) ||
                              (message.subject_type === "service_booking" &&
                                (message.subject_state === "accepted" ||
                                  message.subject_state === "rejected" ||
                                  message.subject_state === "cancelled") && (
                                  <Link
                                    to={`/user/services`}
                                    variant={"small"}
                                    className={"my-auto w-full"}
                                  >
                                    {message.message}
                                  </Link>
                                )) ||
                              (message.subject_type === "order" && (
                                  <Link
                                    to={`/user/order-details/${message.subject_id}`}
                                    variant={"small"}
                                    className={"my-auto w-full"}
                                  >
                                    {message.message}
                                  </Link>
                                )) || (
                                // Default rendering
                                <span className={"my-auto w-full"}>
                                  {message.message}
                                </span>
                              )}
                            <IconButton
                              variant="text"
                              color="blue-gray"
                              onClick={(e) => {
                                axios
                                  .post(
                                    `/api/notification/${message.id}/delete`,
                                    {},
                                    config
                                  )
                                  .then((res) => {
                                    console.log("read message", res);
                                    getMessages(dispatch, config, messages);
                                  })
                                  .catch((err) => {
                                    console.log(err);
                                    toast.error("Error reading message!");
                                  });
                              }}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </IconButton>
                          </MenuItem>
                        ))}
                      <Link
                        to={"/user/notifications"}
                        className="text-red px-3 pt-3"
                      >
                        See More
                      </Link>
                    </MenuList>
                  </Menu>
                )}
                {WishList.loading && <Spinner className={"ml-3"} />}
                {!WishList.loading && (
                  <div className="relative cursor-pointer hidden lg:flex">
                    <button
                      className="text-2xl border-e px-6 h-3 items-center flex"
                      onClick={() => navigate("/user/wishlist")}
                    >
                      <img src={heartIcon} alt="heartIcon" />
                    </button>
                    <span className="bg-[#EB2606] absolute top-0 end-4 text-white w-4 h-4  text-[10px] flex items-center justify-center rounded-[50%]">
                      {WishList.venueItems?.length +
                        WishList.productsItems?.length}
                    </span>
                  </div>
                )}
                {cart.loading && <Spinner className={"ml-3"} />}
                {!cart.loading && (
                  <div className="relative cursor-pointer hidden lg:flex">
                    <button
                      className="text-2xl border-e px-6 h-3 flex items-center"
                      onClick={() => navigate("/cart")}
                    >
                      <img src={cartIcon} alt="cartIcon" />
                    </button>
                    <span className="bg-[#EB2606] absolute top-0 end-4 text-white w-4 h-4  text-[10px] flex items-center justify-center rounded-[50%]">
                      {totalItems(cart.items)}
                    </span>
                  </div>
                )}
                {/* <div className="relative cursor-pointer hidden lg:flex">
                <button
                    className="text-2xl border-e px-6 h-3 flex items-center"
                    onClick={() => navigate('/cart')}
                >
                  <img className=""/>
                </button>
                <span className="bg-[#EB2606] absolute top-0 end-4 text-white w-4 h-4  text-[10px] flex items-center justify-center rounded-[50%]">{totalItems(cart.items)}</span>
              </div> */}
                <button
                  className="text-2xl px-6 hidden lg:flex"
                  onClick={() =>
                    i18n
                      .changeLanguage(i18n.language === "en" ? "ar" : "en")
                      .then(() => window.location.reload(true))
                  }
                >
                  {i18n.language === "en" ? (
                    <FaGlobeAfrica className="text-white text-2xl" />
                  ) : (
                    <FaGlobeAmericas className="text-white text-2xl" />
                  )}
                </button>
                {/* <IconButton
              variant="text"
              className="rounded-full"
              ripple={false}
              onClick={() => dispatch(uiActions.toggleDarkMode())}
              >
              {dark ? (
                  <FontAwesomeIcon icon={faSun}/>
              ) : (
                  <FontAwesomeIcon icon={faMoon}/>
              )}
              </IconButton> */}
                <Dropdown
                  className="text-white"
                  arrowIcon={false}
                  inline
                  label={
                    <div className="flex items-center gap-1">
                      <img src={userIcon} alt="user" />
                      <p className="text-white text-base font-normal font-['Roboto'] leading-tight">
                        {user?.name}
                      </p>
                      <FontAwesomeIcon
                        className="text-white p-[2px]"
                        icon={faAngleDown}
                      />
                    </div>
                  }
                >
                  <Dropdown.Header className="flex-col gap-2 items-center inline-flex pt-4">
                    <span className="text-stone-500 text-sm font-medium font-['Roboto'] leading-[14px]">
                      {user?.name}
                    </span>
                    <span className="text-stone-500 text-base font-light font-['Roboto'] leading-none">
                      {user?.email}
                    </span>
                    <br />
                    {user?.partner.name && (
                      <Badge>
                        <span className="block truncate text-sm font-medium">
                          {user?.partner?.name}
                        </span>
                      </Badge>
                    )}
                  </Dropdown.Header>
                  {!user?.partner.name && (
                    <div className="flex flex-col items-center mx-4 gap-y-6 pt-4">
                      {user?.type === "user" && (
                        <Link
                          className=" py-2 border-b w-full justify-center flex"
                          to={"/user/account"}
                        >
                          <span className="text-center text-gray-dark text-sm font-normal leading-[14px]">
                            {t("My Account")}
                          </span>
                        </Link>
                      )}
                      {user?.type === "vendor" &&
                        user?.vendor_type === "venue" && (
                          <Link
                            className=" py-2 border-b w-full justify-center flex"
                            to={"/vendor/account"}
                          >
                            <span className="text-center text-gray-dark text-sm font-normal leading-[14px]">
                              {t("My Account")}
                            </span>
                          </Link>
                        )}
                      {user?.type === "vendor" &&
                        user?.vendor_type === "services" && (
                          <Link
                            className=" py-2 border-b w-full justify-center flex"
                            to={"/ServiceProvider/account"}
                          >
                            <span className="text-center text-gray-dark text-sm font-normal leading-[14px]">
                              {t("My Account")}
                            </span>
                          </Link>
                        )}
                      {user?.type === "vendor" &&
                        user?.vendor_type === "supplier" && (
                          <Link
                            className=" py-2 border-b w-full justify-center flex"
                            to={"/product-Supplier/Account"}
                          >
                            <span className="text-center text-gray-dark text-sm font-normal leading-[14px]">
                              {t("My Account")}
                            </span>
                          </Link>
                        )}
                      {/* {(user?.type ==="user") && <Link className='py-2 border-b w-full justify-center flex' to={"/user/partner/link"}>
                        <span className='text-center text-gray-dark text-sm font-normal leading-[14px]'>{t('Link With Partner')}</span>
                </Link>} */}

                      <Dropdown.Divider />
                    </div>
                  )}
                  <div className="flex justify-center pb-4">
                    <button
                      className="text-red-800 text-sm font-normal leading-[14px] flex items-center gap-2"
                      onClick={() => {
                        setOpen(true);
                      }}
                    >
                      <img src={logIcon} alt="logout" />
                      {t("Logout")}
                    </button>
                  </div>
                </Dropdown>

                <IconButton
                  variant="text"
                  color="white"
                  className="text-white ml-auto h-6 w-6 hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
                  ripple={false}
                  onClick={() => setOpenNav(!openNav)}
                >
                  {openNav ? (
                    <FontAwesomeIcon icon={faClose} size={"xl"} />
                  ) : (
                    <FontAwesomeIcon icon={faBars} size={"xl"} />
                  )}
                </IconButton>
              </div>
            </div>
          </div>
          <Collapse open={openNav}>
            {navList}
            <div className="flex items-center border-t py-5">
              
            {messages.loading && <Spinner className={"ml-3"} />}
                {!messages.loading && (
                  <Menu>
                    <MenuHandler>
                      <div className="relative cursor-pointer flex">
                        <button onClick={handleClearAll} className="text-2xl border-e px-6 flex items-center">
                          <img src={notifactionIcon} alt="notifactionIcon" />
                        </button>
                        {!!messages.items.length && (
                          <span className="bg-[#EB2606] absolute top-0 end-4 text-white w-4 h-4  text-[10px] flex items-center justify-center rounded-[50%]">
                            {messages.unread_count}
                          </span>
                        )}
                      </div>
                    </MenuHandler>
                    <MenuList className={"w-[80%]"}>
                      {messages.items
                        .slice(0, 5)
                        .map((message, index) => (
                          <MenuItem
                            key={index}
                            className={"flex flex-row justify-between"}
                          >
                            {(message.subject_type === "venue_reservation" &&
                              (message.subject_state === "new" ||
                                message.subject_state === "confirmed" ||
                                message.subject_state ===
                                  "pending_payment") && (
                                <Link
                                  to={`/user/booking-list/${message.subject_id}`}
                                  variant={"small"}
                                  className={"my-auto w-full"}
                                  onClick={() => window.innerWidth <= 768 ? (setOpenNav(!openNav)):null}
                                >
                                  {message.message}
                                </Link>
                              )) ||
                              (message.subject_type === "venue_reservation" &&
                                message.subject_state === "declined" && (
                                  <Link
                                    to={`/venues`}
                                    variant={"small"}
                                    className={"my-auto w-full"}
                                    onClick={() => window.innerWidth <= 768 ? (setOpenNav(!openNav)):null}
                                  >
                                    {message.message}
                                  </Link>
                                )) ||
                              (message.subject_type === "venue_reservation" &&
                                message.subject_state === "paid" && (
                                  <Link
                                    to={`/booking-review/${message.subject_id}`}
                                    variant={"small"}
                                    className={"my-auto w-full"}
                                    onClick={() => window.innerWidth <= 768 ? (setOpenNav(!openNav)):null}
                                  >
                                    {message.message}
                                  </Link>
                                )) ||
                              (message.subject_type ===
                                "wedding_consultant_booking" &&
                                message.subject_state === "new" && (
                                  <Link
                                    to={`/ConsultationSession`}
                                    variant={"small"}
                                    className={"my-auto w-full"}
                                  >
                                    {message.message}
                                  </Link>
                                )) ||
                              (message.subject_type === "service_booking" &&
                                (message.subject_state === "accepted" ||
                                  message.subject_state === "rejected") && (
                                  <Link
                                    to={`/user/services`}
                                    variant={"small"}
                                    className={"my-auto w-full"}
                                    onClick={() => window.innerWidth <= 768 ? (setOpenNav(!openNav)):null}
                                  >
                                    {message.message}
                                  </Link>
                                )) ||
                                (
                                // Default rendering
                                <span className={"my-auto w-full"}>
                                  {message.message}
                                </span>
                              )}
                            <IconButton
                              variant="text"
                              color="blue-gray"
                              onClick={(e) => {
                                axios
                                  .post(
                                    `/api/notification/${message.id}/delete`,
                                    {},
                                    config
                                  )
                                  .then((res) => {
                                    console.log("read message", res);
                                    getMessages(dispatch, config, messages);
                                  })
                                  .catch((err) => {
                                    console.log(err);
                                    toast.error("Error reading message!");
                                  });
                              }}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </IconButton>
                          </MenuItem>
                        ))}
                      <Link
                        to={"/user/notifications"}
                        className="text-red px-3 pt-3"
                        onClick={() => window.innerWidth <= 768 ? (setOpenNav(!openNav)):null}
                      >
                        See More
                      </Link>
                    </MenuList>
                  </Menu>
                )}


            {WishList.loading && <Spinner className={"ml-3"} />}
                {!WishList.loading && (
                  <div className="relative cursor-pointer ">
                    <button
                      className="text-2xl border-e px-6 items-center flex"
                      onClick={() => navigate("/user/wishlist")}
                    >
                      <img src={heartIcon} alt="heartIcon"  
                      onClick={() => window.innerWidth <= 768 ? (setOpenNav(!openNav)):null}/>
                    </button>
                    <span className="bg-[#EB2606] absolute top-0 end-4 text-white w-4 h-4  text-[10px] flex items-center justify-center rounded-[50%]">
                      {WishList.venueItems?.length +
                        WishList.productsItems?.length}
                    </span>
                  </div>
                )}


              {cart.loading && <Spinner className={"ml-3"} />}
              {!cart.loading && (
                <div className="relative cursor-pointer">
                  <button
                    className="text-2xl border-e px-6  items-center flex"
                    onClick={() => navigate("/cart")}
                  >
                    <FiShoppingCart className="text-white text-2xl "
                    onClick={() => window.innerWidth <= 768 ? (setOpenNav(!openNav)):null}  />
                  </button>
                  <span className="bg-[#EB2606] absolute top-0 end-4 text-white w-4 h-4  text-[10px] flex items-center justify-center rounded-[50%]">
                    {totalItems(cart.items)}
                  </span>
                </div>
              )}
              <button
                className="text-2xl px-6 "
                onClick={() =>
                  i18n
                    .changeLanguage(i18n.language === "en" ? "ar" : "en")
                    .then(() => window.location.reload(true))
                }
              >
                {i18n.language === "en" ? (
                  <FaGlobeAfrica className="text-white text-2xl" />
                ) : (
                  <FaGlobeAmericas className="text-white text-2xl" />
                )}
              </button>
            </div>
          </Collapse>
        </nav>
        <div className="menu z-10 bg-white py-2 sticky top-0 hidden lg:block">
          <div className="mr-4 ">{navList}</div>
        </div>
      </div>
    </>
  );
}

export default RegisteredUserHeader;