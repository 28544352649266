import React, { useEffect, useState } from 'react';
import { useTable, useSortBy, usePagination } from 'react-table';
import acceptIcon from "../../assets/images/icons/accept.svg";
import rejectIcon from "../../assets/images/icons/reject.svg";
import ReactPaginate from 'react-paginate';
import useAxiosUserSecureConfig from '../../hooks/useAxiosUserSecureConfig';
import axios from 'axios';
import { toast } from 'react-toastify';
import Loader from '../../components/Loader';
import { useNavigate } from 'react-router';
import { faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Modal } from "@mui/material";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";



function BookingRequests () {
  const { t, i18n } = useTranslation();
  const [currentPage, setCurrentPage] = useState(0);
  const config = useAxiosUserSecureConfig();
  const [bookingRequests, setBookingRequests] = useState({ loading: true, data: [] });
  const columns = React.useMemo(
    () => [
      { Header: t('Booking ID'), accessor: 'requestId' },
      { Header: t('Total Price'), accessor: 'totalPrice' },
      { Header: t('Client Name'), accessor: 'clientName' },
      { Header: t('Client Mobile Number'), accessor: 'clientMobileNumber' },
      { Header: t('Event Date'), accessor: 'bookingDate' },
     
    ] ,[t]
  );
const fetchData = () => {
  setBookingRequests((prevState) => ({
    ...prevState,
    loading: true,
  }));
  axios.post('/api/venue-vendor/bookings', {}, config)
    .then((res) => {
      console.log("bookings requests", res.data);
      if (!res.data?.result?.success) {
        toast.error("Error while fetching bookings requests!");
        return;
      }
      setBookingRequests({
        loading: false,
        data: res.data?.result?.bookings?.slice().reverse() || [],
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
useEffect(() => {
  fetchData();
}, []);

  const reqestsData = React.useMemo(() => {
    return bookingRequests.data.length > 0
      ? bookingRequests.data
          .filter((key) => key.state.id === 'new')
          .map((key) => ({
            id: key.id,
            requestId: key.bookingCode,
            bookingDate: key.date,
            totalPrice: key.price,
            clientName: key.brideName,
            clientMobileNumber: key.phone,
          }))
      : [];
  }, [bookingRequests.data]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data: reqestsData,
      initialState: { pageIndex: currentPage, pageSize:  4 }, 
    },
    useSortBy,
    usePagination
  );

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleAccept = (e, row) => {
    e.stopPropagation();
    console.log('Accepted:', row);
    setBookingRequests((prevState) => ({
      ...prevState,
      loading: true,
    }));
    const requestData = {
      booking_id: row.id,
    };
    axios.post('/api/venue-vendor/confirm-booking', requestData, config)
      .then((res) => {
        if (!res.data.result?.success) {
          toast.error(res.data.result?.msg || t("Request failed!"));
          fetchData();
          return;
        }
        setBookingRequests((prevState) => ({
          ...prevState,
          loading: false,
        }));
        toast.success(t("You accepted the booking successfully!"));
        fetchData();
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(t("Error while accepting the booking!"));
        setBookingRequests((prevState) => ({
          ...prevState,
          loading: false,
        }));
      });
  };

  const [openRejectDialog, setOpenRejectDialog] = useState(false);
  const [rejectReason, setRejectReason] = useState('');
  const [loading, setLoading] = useState(false);
  const [rejectRow, setRejectRow] = useState(null);
  const handleOpenRejectDialog = (e, row) => {
    e.stopPropagation();
    setOpenRejectDialog(true);
    setRejectReason('');
    setRejectRow(row);
  };

  const handleCloseRejectDialog = () => {
    setOpenRejectDialog(false);
    setRejectReason('');
    setRejectRow(null);
  };

  const handleRejectConfirm = () => {
    setLoading(true);
    setBookingRequests((prevState) => ({
      ...prevState,
      loading: true,
    }));
    const requestData = {
      booking_id: rejectRow.id,
      rejection_reason: rejectReason,
    };
    axios.post('/api/venue-vendor/reject-booking', requestData, config)
      .then((res) => {
        if (!res.data.result?.success) {
          toast.error(res.data.result?.message || "Request failed!");
          return;
        }
        setBookingRequests((prevState) => ({
          ...prevState,
          loading: false,
        }));
        setLoading(false);
        toast.success(t("You rejected the booking successfully!"));
        handleCloseRejectDialog();
        fetchData();
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(t("Error while rejecting the booking!"));
        setLoading(false);
        handleCloseRejectDialog();
        setBookingRequests((prevState) => ({
          ...prevState,
          loading: false,
        }));
      });
  };
  console.log(rejectReason)

  const navigate = useNavigate()

  const handleClick = (rowData) => {
    navigate(`/vendor/booking-requests/${rowData.original.id}`, { state: { data: bookingRequests.data.find(item => item.id === rowData.original.id) } });
  };

  return (
    <section className="px-5 xl:px-20 py-10">
      <Modal
        open={openRejectDialog}
        onClose={handleCloseRejectDialog}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="absolute md:w-2/5 w-11/12 px-2 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 md:px-8 py-6 bg-white rounded-2xl shadow flex-col justify-center items-start inline-flex border-4 border-red">
          <div className="w-full">
            <div className="flex justify-end">
              <button
                onClick={() => handleCloseRejectDialog()}
                className="self-stretch justify-between items-center flex"
              >
                <FontAwesomeIcon icon={faX} className="text-xl text-gray-500" />
              </button>
            </div>
            <div className="flex flex-col justify-start items-start gap-2 mb-5">
              <label className="text-zinc-800 text-base font-normal leading-snug">
              {t("Enter Rejection Reason")}
              </label>
              <input
                className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-base font-normal leading-tight placeholder-[#808080]"
                placeholder={t("Rejection Reason")}
                value={rejectReason}
                onChange={(e) => setRejectReason(e.target.value)}
              />
            </div>
            <div className="w-full flex justify-center gap-5">
              <button
                onClick={() => handleCloseRejectDialog()}
                className="w-1/3 h-[47px] px-[18px] py-[6.40px] bg-white rounded-[10px] shadow border border-red justify-center items-center gap-2.5 inline-flex"
              >
                <span className="text-center text-red text-base font-normal font-['Roboto'] leading-normal">
                {t("Cancel")}
                </span>
              </button>
              <button
                onClick={() => handleRejectConfirm()}
                className="w-1/3 h-[47px] px-[18px] py-[6.40px] bg-red rounded-[10px] shadow border border-red justify-center items-center gap-2.5 inline-flex"
              >
                <span className="text-center text-white text-base font-normal font-['Roboto'] leading-normal">
                {t("Confirm")}
                </span>
              </button>
            </div>
          </div>
        </Box>
      </Modal>
      {bookingRequests.loading && <Loader slim />}
      {!bookingRequests.loading && (
        <>
       <div className="justify-start items-center gap-2  hidden sm:inline-flex mb-2">
          <div
           onClick={() => navigate('/vendor/account')}
           className="text-zinc-400 text-base font-normal leading-[48px] hover:text-gray-dark cursor-pointer"
         >
          {t("My Account")}
          </div>
          <FontAwesomeIcon
            className="text-zinc-400 text-base"
            icon={faAngleRight}
          />
          <div
            onClick={() => navigate( '/vendor/booking-requests')}
            className="text-zinc-400 text-base font-normal leading-[48px] hover:text-gray-dark cursor-pointer"
          >
           {t("Booking Requests")}
          </div>
        </div>
        <div className="flex justify-between items-center mb-2 mt-5">
          <div className="justify-start items-center gap-2 inline-flex mb-8">
            <div className="text-2xl font-semibold leading-9">
            {t("Booking Requests")}
            </div>
            <div className="text-base font-light leading-normal">
              ({reqestsData.length})
            </div>
          </div>
          </div>
          <div className='overflow-x-auto'>
          <table
            {...getTableProps()}
            className="w-full table-auto border-collapse mb-10"
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr
                  className="h-12 bg-slate-100"
                  {...headerGroup.getHeaderGroupProps()}
                >
                  <th className="py-2 px-4 border text-gray-500 text-base font-medium leading-snug">
                    {"#"}
                  </th>{" "}
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className={`py-2 px-4 border text-gray-500 text-base font-medium leading-snug ${
                        column.isSorted
                          ? column.isSortedDesc
                            ? "sort-desc"
                            : "sort-asc"
                          : ""
                      }`}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                  <th
                    colSpan="2"
                    className="py-2 px-4 border text-gray-500 text-base font-medium leading-snug"
                  >
                    {t("Actions")}
                  </th>
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows
                .slice(currentPage * 20, (currentPage + 1) * 20)
                .map((row, index) => {
                  prepareRow(row);
                  return (
                    <tr
                      onClick={() => handleClick(row)}
                      {...row.getRowProps()}
                      className="border-t h-12 cursor-pointer hover:bg-slate-100"
                    >
                      <td className="px-4 border text-center bg-slate-100 text-gray-500 text-xs font-light leading-none">
                        {index + 1 + currentPage * 20}
                      </td>{" "}
                      {row.cells.map((cell, cellIndex) => (
                        <td
                          {...cell.getCellProps()}
                          className="px-4 border text-center text-[#697077] text-sm font-normal leading-tight"
                          key={cellIndex}
                        >
                          {cell.column.id === "totalPrice" // Replace 'yourProperty' with the actual property name
                            ? `${cell.value} EGP`
                            : cell.render("Cell")}
                        </td>
                      ))}
                      <td className="px-2 border text-center bg-[#F2FFF6]">
                        <button
                          className=""
                          onClick={(e) => handleAccept(e, row.original)}
                        >
                          <img src={acceptIcon} alt="acceptIcon" />
                        </button>
                      </td>
                      <td className="px-2 border text-center bg-[#FFF2F2]">
                        <button
                          className=""
                          onClick={(e) =>
                            handleOpenRejectDialog(e, row.original)
                          }
                        >
                          <img src={rejectIcon} alt="rejectIcon" />
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          </div>
          <ReactPaginate
            breakLabel="..."
            nextLabel={t("Next >")}
            onPageChange={handlePageClick}
            pageRangeDisplayed={Math.ceil(rows.length / 20)}
            pageCount={Math.ceil(rows.length / 20)}
            previousLabel={t("< Previous")}
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            pageLinkClassName="page-num"
            previousLinkClassName="page-btn"
            nextLinkClassName="page-btn"
            activeLinkClassName="active"
            disabledLinkClassName="disabled-btn"
          />
        </>
      )}
    </section>
  );
};

export default BookingRequests;