import React from 'react';
import {Card} from "@material-tailwind/react";

import r4b from "../../assets/images/img2.jpeg"

function Website() {
  return (
      <>
        <Card className="mb-12 overflow-hidden">
          <img
              alt="nature"
              className="h-[32rem] w-full object-cover object-center"
              src={r4b}
          />
        </Card>
      </>
  );
}

export default Website;