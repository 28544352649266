import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import useAxiosUserSecureConfig from "../../hooks/useAxiosUserSecureConfig";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation } from 'swiper/modules';
import placholder_image from "../../assets/images/booking/placeholder-image.jpg"
import { FaEdit } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import ConfirmationModal from "../../components/ConfirmationModal";

function DeleteVenue() {

  const {
    register,
    watch,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm();
  const [facility, setFacility] = useState({
    loading: true,
    data: {},
  });
  const navigate = useNavigate();


  

  const params = useParams();
  const [venue, setVenue] = useState({
    loading: true,
    data: {},
  });
  const [imageBase64, setImageBase64] = useState(null);
  useEffect(() => {
    axios
      .post(`/api/facility/${params.id}`, { id: params.id })
      .then((res) => {
        console.log("facility", res.data);

        if (!res.data?.result?.success) {
          toast.error("Error while fetching facility!");
        }
        setImageBase64(res.data?.result?.image);
        setFacility({
          loading: false,
          data: res.data?.result || {}, // Set to an empty object if undefined
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [params.id]);

  useEffect(() => {
    axios
      .post(`/api/venue/${params.id}`, { id: params.id })
      .then((res) => {
        console.log("venue", res.data);

        if (!res.data?.result?.success) {
          toast.error("Error while fetching venue!");
        }
        setImageBase64(res.data?.result?.image);
        setVenue({
          loading: false,
          data: res.data?.result || {}, // Set to an empty object if undefined
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [params.id]);


  const customStyles = {
    control: (styles) => ({
      ...styles,
      background: "#F2F4F8",
      "border-color": "#F2F4F8",
      "border-radius": "8px",
      padding: "6px",
    }),
  };
  const config = useAxiosUserSecureConfig(); 
  const [open, setOpen] =useState(false);
  const handleClose = () => setOpen(false); 
  const onSubmit = (data) => {
    setOpen(true)
    console.log("data" , data)
  };
  const handleDeleteVenue = (data) => {
     data = {
      venue_id: venue.data.id
  };

  axios.post('/api/venue-vendor/venue/delete' ,data, config)
      .then((res) => {
        if (!res.data.result?.success) {
          console.log('error:',res.data)
          toast.error(res.data.result?.message || "Request failed!");
          return;
        }
        toast.success("You delete Venue successfully!");
        navigate(`/vendor/Facilities/${facility.data.id}`)
        
      })
      .catch((err) => {
        console.log("err" , err)
        toast.error("Error while deleting Venue!");
      })
  }

  return (
    <section className="px-5 xl:px-20 py-10">
      <ConfirmationModal
      open={open}
      handleClose={handleClose}
      handleAction={handleDeleteVenue}
      msg={"Are you sure you want to delete this Venue?"}
    />
      {venue.loading && <Loader slim />}
      {!venue.loading && (
        
        <>
         <div className="flex items-center justify-between flex-wrap md:flex-nowrap mb-10">
          <div className="justify-start items-center gap-2 inline-flex">
              <div
                onClick={() => navigate("/vendor/account")}
                className="text-zinc-400 text-base font-normal leading-[48px] hover:text-gray-dark cursor-pointer"
              >
                My Account
              </div>
              <FontAwesomeIcon
                className="text-zinc-400 text-base"
                icon={faAngleRight}
              />
              <div
                onClick={() => navigate("/vendor/Facilities")}
                className="text-zinc-400 text-base font-normal leading-[48px] hover:text-gray-dark cursor-pointer"
              >
                My Facilities
              </div>
              <FontAwesomeIcon
                className="text-zinc-400 text-base"
                icon={faAngleRight}
              />
              <div onClick={() => navigate(`/vendor/Facilities/${facility.data.id}`)} className="text-zinc-400 text-base font-normal leading-[48px] hover:text-gray-dark cursor-pointer">
                {facility.data.name}
              </div>
              <FontAwesomeIcon
                className="text-zinc-400 text-base"
                icon={faAngleRight}
              />
              <div className="text-zinc-400 text-base font-normal leading-[48px] hover:text-gray-dark cursor-pointer">
                {venue.data.name}
              </div>
              <FontAwesomeIcon
                className="text-zinc-400 text-base"
                icon={faAngleRight}
              />
              <div className="text-zinc-400 text-base font-normal leading-[48px] hover:text-gray-dark cursor-pointer">
            Delete
          </div>
            </div>

            </div>

            <form onSubmit={handleSubmit(onSubmit)} className="flex gap-20 flex-wrap md:flex-nowrap border-b pb-6 mb-6">
              <div className="flex w-full md:w-1/3  flex-col gap-6 items-center">
          
                <img
                  className="w-full h-[260px] object-cover rounded-lg"
                  src={venue.data.image}
                  alt=""
                />
               <div className="w-full grid grid-cols-2 gap-2">
                {venue.data.extraImages.map((image)=>(
                  <img
                  className="w-full h-[160px] object-cover rounded-lg"
                  src={image}
                  alt=""
                />
                ))
               }
               </div>
              </div>
           
            <div className="grow">
            <div className="flex justify-between items-center mb-10">
              <p className="text-red text-2xl font-medium ">Delete Venue</p>
             
            </div>
              <div>
                <div className="flex flex-col gap-10">
                  <div className="grid lg:grid-cols-2 gap-x-6 gap-y-4">
                    <div className="flex flex-col justify-start items-start gap-2 ">
                      <label className="text-zinc-800 text-base font-normal leading-snug">
                        Venue name
                      </label>
                      <input
                        type="text"
                        value={venue.data.name}
                        readOnly
                        className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                      />
                    </div>

                    <div className="flex flex-col justify-start items-start gap-2 ">
                      <label className="text-zinc-800 text-base font-normal leading-snug">
                        Basic Price
                      </label>
                      <input
                        type="text"
                        value={venue.data.minPrice}
                        readOnly
                        className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                      />
                    </div>
                    <div className="flex flex-col justify-start items-start gap-2 ">
                      <label className="text-zinc-800 text-base font-normal leading-snug">
                        Available days
                      </label>
                      <input
                        type="text"
                        value={venue.data.weekAvailability}
                        readOnly
                        className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                      />
                    </div>

                    <div className="flex flex-col justify-start items-start gap-2 ">
                      <label className="text-zinc-800 text-base font-normal leading-snug">
                        Number of guest
                      </label>
                      <input
                        type="text"
                        value={venue.data.size.name}
                        readOnly
                        className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                      />
                    </div>

                    <div className="flex flex-col justify-start items-start gap-2 ">
                      <label className="text-zinc-800 text-base font-normal leading-snug">
                        Indoor/Outdoor
                      </label>
                      <input
                        type="text"
                        value={venue.data.indoorOutdoor}
                        readOnly
                        className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                      />
                    </div>

                    <div className="flex flex-col justify-start items-start gap-2 ">
                      <label className="text-zinc-800 text-base font-normal leading-snug">
                        Description
                      </label>
                      <input
                        type="text"
                        value={venue.data.description}
                        readOnly
                        className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                      />
                    </div>

                    <div className="flex flex-col justify-start items-start gap-2 ">
                      <label className="text-zinc-800 text-base font-normal leading-snug">
                        Included Services
                      </label>
                    <div className="w-full items-center grid grid-cols-3 lg:grid-cols-2 gap-6">
                        {venue.data.services.map(
                          (serv) =>
                            !serv.optional && (
                              <div className="p-4 rounded-xl border flex-col justify-start items-start gap-2.5 inline-flex">
                                <div className="justify-start items-center gap-4 inline-flex">
                                  <div className="w-7 h-7 px-px py-[5px] justify-center items-center flex">
                                    <img
                                      src={serv.image_512}
                                      alt="receptionIcon"
                                    />
                                  </div>
                                  <div className="text-red text-base font-normal">
                                    {serv.name}
                                  </div>
                                </div>
                                <div className="text-zinc-800 text-base font-normal">
                                  {serv.price} EGP
                                </div>
                              </div>
                            )
                        )}
                      </div>
                    </div>
                    <div className="flex flex-col justify-start items-start gap-2 ">
                      <label className="text-zinc-800 text-base font-normal leading-snug">
                        Other Services
                      </label>
                    <div className="flex items-center gap-6">
                        {venue.data.services.map(
                          (serv) =>
                            serv.optional && (
                              <div className="p-4 rounded-xl border flex-col justify-start items-start gap-2.5 inline-flex">
                                <div className="justify-start items-center gap-4 inline-flex">
                                  <div className="w-7 h-7 px-px py-[5px] justify-center items-center flex">
                                    <img
                                      src={serv.image_512}
                                      alt="receptionIcon"
                                    />
                                  </div>
                                  <div className="text-red text-base font-normal">
                                    {serv.name}
                                  </div>
                                </div>
                                <div className="text-zinc-800 text-base font-normal">
                                  {serv.price} EGP
                                </div>
                              </div>
                            )
                        )}
                      </div>
                    </div>
                    </div>
                <div className="flex justify-end w-full">
                  <button
                    type="submit"
                    className="w-fit px-16 py-3 bg-red rounded-[10px] shadow border border-white justify-center items-center gap-2.5 inline-flex"
                  >
                    <div className="text-center text-white text-base font-normal leading-normal">
                      Delete
                    </div>
                  </button>
                </div>
                 
                </div>
              </div>
            </div>
          </form>

</>
         

          )
          }
 
         

           </ section>
  )}
 
export default DeleteVenue