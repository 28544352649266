import React from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";


function Icon({ id, open }) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={2}
        stroke="currentColor"
        className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
      >
        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
      </svg>
    );
  }
 
function Faq() {
  const [open, setOpen] = React.useState(0);
 
  const handleOpen = (value) => setOpen(open === value ? 0 : value);

  const { t, i18n } = useTranslation();
 
  return (
    <section className="px-5 xl:px-20 py-10 bg-zinc-100">
      <p className="text-lg mb-6 text-red md:text-2xl font-semibold leading-9">
        {t("Frequently Asked Question")}
      </p>
      <div className="border rounded-lg p-5 bg-white shadow-lg">
        <Accordion open={open === 1} icon={<Icon id={1} open={open} />}>
          <AccordionHeader onClick={() => handleOpen(1)}>
            {i18n.language === "ar" ? "ما هى منصة روز بازار للتسويق الإلكترونى ؟" : "What is the Rose Bazaar platform?"}
          </AccordionHeader>
          <AccordionBody className="text-base">
            {i18n.language === "ar" ? "روز بازار هو منصة تسويق الكترونى لتقديم خدمات ومنتجات حفلات الزفاف، ليس فقط حفلات الزفاف بل ايضا كل إحتياجات العروسين ، مثل مناسبات أخرى جميلة كالخطوبة وشهر العسل. بالإضافة الى كل مايحتاجة العروسين من منتجات وخدمات باسعار تنافسية فإننا نقدم ايضا إستشارات الزفاف ودعوات الفرح." : "Rose Bazaar is a digital marketplace that provides goods and services for weddings as well as for all the requirements of newlyweds, including engagement and honeymoon planning. Not only are all the necessities for a newlywed couple met at affordable costs, but wedding consultations and invitations are also offered."}
          </AccordionBody>
        </Accordion>
        <Accordion open={open === 2} icon={<Icon id={2} open={open} />}>
          <AccordionHeader onClick={() => handleOpen(2)}>
            {i18n.language === "ar" ? "كيف أستخدم منصة روز بازار للتسويق الألكتروتى؟" : "How should I use Rose Bazaar platform?"}
          </AccordionHeader>
          <AccordionBody className="text-base">
            <ol className="list-decimal px-5">
              {i18n.language === "ar" ? (
                <>
                  <li>الدخول على الموقع</li>
                  <li>تسجيل البيانات</li>
                  <li>مراجعة البريد الإلكترونى الخاص بك لتفعيل الحساب</li>
                  <li>تأكيد تفعيل الحساب</li>
                  <li>تفاصيل اكثر ..... <Link className="text-blue-500" to={'/register'}>اضغط الرابط</Link> </li>
                </>
              ) : (
                <>
                  <li>Sign in to the webpage.</li>
                  <li>Data entry</li>
                  <li>Verify your email to make the account active.</li>
                  <li>Verify the account's activation.</li>
                  <li>Click the link for additional information. <Link className="text-blue-500" to={'/register'}>Click here</Link></li>
                </>
              )}
            </ol>
          </AccordionBody>
        </Accordion>
        <Accordion open={open === 3} icon={<Icon id={3} open={open} />}>
          <AccordionHeader onClick={() => handleOpen(3)}>
            {i18n.language === "ar" ? "كيف أكون معلن على منصة روزبازار؟" : "How can I use the Rose Bazaar platform to advertise?"}
          </AccordionHeader>
          <AccordionBody className="text-base">
            {i18n.language === "ar" ? (
              <ol className="list-decimal px-5">
                <li>إنشاء حساب خاص بك على منصة روز بازار</li>
                <li>تحديد نوع النشاط الخاص بك</li>
                <li>تسجيل بيانات المنتج او الخدمة المقدمة</li>
                <li>تاكيد الإطلاع على الشروط والأحكام وسياسات الموقع</li>
              </ol>
            ) : (
              <ol className="list-decimal px-5">
                <li>Register for an account on the Rose Bazaar website.</li>
                <li>Ascertain the nature of what you are doing.</li>
                <li>Compiling information on the good or service rendered</li>
                <li>Verification that you have read the site's rules, terms, and conditions</li>
              </ol>
            )}
          </AccordionBody>
        </Accordion>
        <Accordion open={open === 4} icon={<Icon id={4} open={open} />}>
          <AccordionHeader onClick={() => handleOpen(4)}>
            {i18n.language === "ar" ? "كيف أكون مقدم خدمة تأجير قاعات أفراح؟" : "What steps do I need to take to start providing wedding hall rentals?"}
          </AccordionHeader>
          <AccordionBody className="text-base">
            {i18n.language === "ar" ? (
              <ol className="list-decimal px-5">
                <li>إنشاء حساب خاص بك على منصة روز بازار</li>
                <li>تحديد نوع قاعات الزفاف المتاحة اليك</li>
                <li>تسجيل بيانات القاعة / المكان الخاص والصالح لإقامة حفلات الزفاف</li>
                <li>رفع الصور الخاصة بالقاعة / المكان</li>
                <li>تاكيد الإطلاع على الشروط والأحكام وسياسات الموقع</li>
              </ol>
            ) : (
              <ol className="list-decimal px-5">
                <li>Sign up for an account on the Rose Bazaar website.</li>
                <li>Describe the types of wedding halls that you have access to.</li>
                <li>Write down information halls or other private spaces that can be used for wedding celebrations.</li>
                <li>Upload pictures of the space or hall.</li>
                <li>Confirmation that you have read the site's rules, terms, and conditions.</li>
              </ol>
            )}
          </AccordionBody>
        </Accordion>
        <Accordion open={open === 5} icon={<Icon id={5} open={open} />}>
          <AccordionHeader onClick={() => handleOpen(5)}>
            {i18n.language === "ar" ? "كيف أكون تاجر على منصة روز بازار؟" : "How can I join the Rose Bazaar platform as a broker?"}
          </AccordionHeader>
          <AccordionBody className="text-base">
            {i18n.language === "ar" ? (
              <ol className="list-decimal px-5">
                <li>إنشاء حساب خاص بك على منصة روز بازار</li>
                <li>تحديد نوع المنتج الخاص بك</li>
                <li>تسجيل بيانات المنتج والكمية المتاحة والسعر الخاص بة</li>
                <li>رفع الصور والفيديوهات الخاصة بالمنتج</li>
                <li>تاكيد الإطلاع على الشروط والأحكام وسياسات الموقع</li>
              </ol>
            ) : (
              <ol className="list-decimal px-5">
                <li>Sign up for an account on the Rose Bazaar website.</li>
                <li>Describe the nature of what you're providing.</li>
                <li>List the product's quantity and price that are available.</li>
                <li>Post images and videos of the merchandise.</li>
                <li>Verification that you have read the site's rules, terms, and conditions.</li>
              </ol>
            )}
          </AccordionBody>
        </Accordion>
        <Accordion open={open === 6} icon={<Icon id={6} open={open} />}>
          <AccordionHeader onClick={() => handleOpen(6)}>
            {i18n.language === "ar" ? "هل يشترط ان أكون متجر للإعلان عند روزبازار؟" : "Does advertising on Rose bazaar require me to run a business?"}
          </AccordionHeader>
          <AccordionBody className="text-base">
            {i18n.language === "ar" ? "لايشترط ان تكون متجر أو محل للاعلان عن منتج كما لايشترط ان يكون المنتج جديد او لم يستخدم من قبل بحيث ان لايقل سعر المنتج عن 100 جنية مصرى." : "As long as the price is at least 100 Egyptian pounds, a good or service can be promoted without needing to be in a store or even brand-new or never used."}
          </AccordionBody>
        </Accordion>
        <Accordion open={open === 7} icon={<Icon id={7} open={open} />}>
          <AccordionHeader onClick={() => handleOpen(7)}>
            {i18n.language === "ar" ? "كيف أصبح مقدم خدمة خدمات الزفاف للعرائس فى منصة روز بازار؟" : "How do I offer wedding services on the Rose Bazaar platform?"}
          </AccordionHeader>
          <AccordionBody className="text-base">
            {i18n.language === "ar" ? (
              <ol className="list-decimal px-5">
                <li>إنشاء حساب خاص بك على منصة روز بازار</li>
                <li>تحديد نوع الخدمة  الخاص بك مثل (التخطيط والتصميم لحفل الزفاف - المطربين المفضلين وال DJ’s- الزفة -الكوشة- تخطيط قائمة المدعوين وتقديم خدمات تقديم الطعام- التصميم السمعي البصري والإضاءة والمسرح والترفيه - المكياج وتسريحة شعر- العناية بالبشرة الحناء - سيارة الزفة- التصوير الفوتوغرافي والفيديو)</li>
                <li>تسجيل بيانات الخدمة  والتوقيتات  المتاحة لتقديمها  والسعر الخاص بها</li>
                <li>رفع الصور والفيديوهات الخاصة بالخدمة</li>
                <li>تاكيد الإطلاع على الشروط والأحكام وسياسات الموقع.</li>
              </ol>
            ) : (
              <ol className="list-decimal px-5">
                <li>Create a Rose Bazaar account.</li>
                <li>Decide what service you will offer: wedding planning, DJ/music, catering, audiovisual design and production, hair and makeup, etc.</li>
                <li>Enter details of your service, available times, and pricing.</li>
                <li>Upload images and videos of your service.</li>
                <li>Verify that you have read the site's terms, conditions, and policies.</li>
              </ol>
            )}
          </AccordionBody>
        </Accordion>
        <Accordion open={open === 8} icon={<Icon id={8} open={open} />}>
          <AccordionHeader onClick={() => handleOpen(8)}>
            {i18n.language === "ar" ? "كيف أستخدم خدمة إنشاء كروت دعوات الفرح وقائمة المدعوين الإلكترونية؟" : "How do I use the wedding invitation card and guest list creation service?"}
          </AccordionHeader>
          <AccordionBody className="text-base">
            {i18n.language === "ar" ? (
              <ol className="list-decimal px-5">
                <li>إنشاء حساب خاص بك على منصة روز بازار</li>
                <li>أكمل البيانات الخاصة بيوم الزفاف فى الشاشة المخصصة ( اسم العروس – إسم العريس – يوم وتوقيت الزفاف – مكان قاعة الزفاف)</li>
                <li>أختار كارت الدعوة المناسب لذوقك</li>
                <li>سجل قائمة المدعوين</li>
                <li>ارسل كارت الدعوة الخاص بك الى المدعوين من خلال مواقع التواصل الإجتماعى</li>
                <li>متابعة تلقى تاكيد الحضور</li>
                <li>متابعة التعليقات من الأهل والأصدقاء</li>
                <li>تاكيد الإطلاع على الشروط والأحكام وسياسات الموقع</li>
              </ol>
            ) : (
              <ol className="list-decimal px-5">
                <li>Create a Rose Bazaar account.</li>
                <li>Complete the wedding day information in the designated screen (bride's name, groom's name, date and time of the wedding, wedding hall location).</li>
                <li>Choose an invitation card that suits your taste.</li>
                <li>Register the guest list.</li>
                <li>Send your invitation card to the guests via social media.</li>
                <li>Monitor the receipt of attendance confirmations.</li>
                <li>Monitor feedback from family and friends.</li>
                <li>Verify that you have read the site's terms, conditions, and policies.</li>
              </ol>
            )}
          </AccordionBody>
        </Accordion>
        <Accordion open={open === 9} icon={<Icon id={9} open={open} />}>
          <AccordionHeader onClick={() => handleOpen(9)}>
            {i18n.language === "ar" ? "كيف استفيد من خدمة تخطيط يوم الزفاف؟" : "How can I benefit from the wedding day planning service?"}
          </AccordionHeader>
          <AccordionBody className="text-base">
            {i18n.language === "ar" ? (
              <ol className="list-decimal px-5">
                <li>إنشاء حساب خاص بك على منصة روز بازار</li>
                <li>أختار من القائمة المرفقة ما هي العناصر الأساسية لحفل الزفاف الخاص بك. هل هو  الموضوع او المكان أم تاريخ معين لحفل الزفاف؟ ام خدمة زفاف معين ؟ فيما يتعلق بالأمور المالية، أعط الأولوية لتلك التفاصيل وقم بترتيب الأولويات.</li>
                <li>فى حالة الرغبة فى اضافة بند جديد لخطط الزفاف يمكنك اضافة هذا البند.</li>
                <li>تاكيد الإطلاع على الشروط والأحكام وسياسات الموقع</li>
                <li>طباعة خطة الزفاف الخاصة بك</li>
              </ol>
            ) : (
              <ol className="list-decimal px-5">
                <li>Create a Rose Bazaar account.</li>
                <li>Choose the essential elements for your wedding from the attached list: theme, location, specific date, or certain wedding services. Prioritize these details financially and arrange the priorities.</li>
                <li>If you want to add a new item to the wedding plans, you can add this item.</li>
                <li>Verify that you have read the site's terms, conditions, and policies.</li>
                <li>Print your wedding plan.</li>
              </ol>
            )}
          </AccordionBody>
        </Accordion>
        <Accordion open={open === 10} icon={<Icon id={10} open={open} />}>
          <AccordionHeader onClick={() => handleOpen(10)}>
            {i18n.language === "ar" ? "كيف أتواصل مع أستشارى الزفاف؟" : "How do I communicate with a wedding consultant?"}
          </AccordionHeader>
          <AccordionBody className="text-base">
            {i18n.language === "ar" ? (
              <ol className="list-decimal px-5">
                <li>إنشاء حساب خاص بك على منصة روز بازار</li>
                <li>اختار الخدمة التى تود ان تتواصل بها مع استشارى الزفاف</li>
                <li>يقوم فريق الدعم الفنى بعد مراجعة البيانات بالاتصال بك لتحديد طريقة التواصل مع استشارى الزفاف الخاص بك</li>
              </ol>
            ) : (
              <ol className="list-decimal px-5">
                <li>Create a Rose Bazaar account.</li>
                <li>Select the service you want to communicate with the wedding consultant about.</li>
                <li>After reviewing the data, the technical support team will contact you to determine how to communicate with your wedding consultant.</li>
              </ol>
            )}
          </AccordionBody>
        </Accordion>
        <Accordion open={open === 11} icon={<Icon id={11} open={open} />}>
          <AccordionHeader onClick={() => handleOpen(11)}>
            {i18n.language === "ar" ? "لماذا  أعلن على روز بازار ؟" : "Why should I advertise on Rose Bazaar?"}
          </AccordionHeader>
          <AccordionBody className="text-base">
            {i18n.language === "ar" ? "روز بازار منصة للإعلانات خاصة بتقديم خدمات الزفاف لكل وتتيح المنصة للمعلن ان يقدم المنتج او الخدمة الخاصة بة مجانا لمدة ثلاث شهور مما يسمح لعدد كبير من المستخدمين التعرف على المنتج او الخدمة من خلال البيانات المقدمة والصور المرفقة." : "Rose Bazaar is a wedding service-specific advertising platform that allows advertisers to offer their products or services for free for three months, allowing a large number of users to learn about the product or service through the provided information and attached photos."}
          </AccordionBody>
        </Accordion>
        <Accordion open={open === 12} icon={<Icon id={12} open={open} />}>
          <AccordionHeader onClick={() => handleOpen(12)}>
            {i18n.language === "ar" ? "هل إعلانات  روز بازار مجانية؟" : "Are advertisements on Rose Bazaar free?"}
          </AccordionHeader>
          <AccordionBody className="text-base">
            {i18n.language === "ar" ? (
              <ol className="list-decimal px-5">
                <li>يجب أن يمتلك العميل حساب واحد فقط على الموقع.</li>
                <li>مدة االإعلان المجانى ثلاثون يوما  فقط.</li>
                <li>لكل عميل عرض ثلاث منتجات / خدمات فقط مجانيا</li>
                <li>يتم تحصيل قيمة مالية لعرض المنتج بعد استنفاذ عدد المرات المجانية بهدف السيطرة على الإعلانات المكرره و الغير مرغوب فيها على المنصة وإتاحة فرصة أكبر للبائعين الجادين فقط لعرض منتجاتهم وبيعها أسرع وبالتالي توفير تجربة شراء أفضل للمشترين</li>
              </ol>
            ) : (
              <ol className="list-decimal px-5">
                <li>The client should only have one account on the site.</li>
                <li>The free ad period is only thirty days.</li>
                <li>Each customer gets three free product/service listings.</li>
                <li>A fee is charged to display the product after the free listings are exhausted to control duplicate and unwanted ads on the platform, allowing more serious sellers to list and sell their products faster, thus providing a better buying experience for buyers.</li>
              </ol>
            )}
          </AccordionBody>
        </Accordion>
        <Accordion open={open === 13} icon={<Icon id={13} open={open} />}>
          <AccordionHeader onClick={() => handleOpen(13)}>
            {i18n.language === "ar" ? "هل يوجد حد أقصى للإعلانات والنشر على روز بازار ؟" : "Are there a maximum number of ads and posts allowed on Rose Bazaar?"}
          </AccordionHeader>
          <AccordionBody className="text-base">
            {i18n.language === "ar" ? "الحد الأقصى للإعلانات المجانية هدفه هو مصلحة البايع و المشترى، تم وضع الحد الأقصى للاعلان عن منتج او خدمة للتاكد من عدم استغلال الموقع بطريقة غير صحيحة و عدم عرض إعلانات مكررة مما يقلل من فرصة البايع أوتجعل تجربة المشترى صعبة والحد الأقصى يطبق على كل خدمات منصة روز بازار لتحسين جودة الإعلانات على روز بازار  وزيادة فرص البيع." : "The free ad limit is in place to benefit both the buyer and seller. The maximum limit for advertising a product or service ensures that the site is not used incorrectly and that duplicate ads are not displayed, reducing the seller's chance or making the buyer's experience difficult. The maximum limit applies to all Rose Bazaar services to improve ad quality on Rose Bazaar and increase sales opportunities."}
          </AccordionBody>
        </Accordion>
        <Accordion open={open === 14} icon={<Icon id={14} open={open} />}>
          <AccordionHeader onClick={() => handleOpen(14)}>
            {i18n.language === "ar" ? "هل يتم وقف الإعلان على روز بازار عند عدم دفع المقابل المادى؟" : "Are ads on Rose Bazaar stopped if the fee is not paid?"}
          </AccordionHeader>
          <AccordionBody className="text-base">
            {i18n.language === "ar" ? "كل مستخدم له الحق انه ينشر 3 اعلانات مجانية فى كل خدمات الموقع  كل 30 يوم باستثناء خدمة اعداد كروت الزفاف فهى مجانية حاليا. بعد استنفاذ عدد مرات النشر بالنسبة للبائع / مقدم الخدمة يتم عدم تفعيل الحساب لحين دفع المقابل المادى." : "Each user has the right to post 3 free ads in all site services every 30 days, except for the wedding card preparation service, which is currently free. After the number of postings for the seller/service provider is exhausted, the account is deactivated until the fee is paid."}
          </AccordionBody>
        </Accordion>
      </div>
    </section>
  );
}

export default Faq;