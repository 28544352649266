import React, { useEffect, useState } from "react";
import { Typography } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";
import error from "../../Error";
import useAxiosUserSecureConfig from "../../../hooks/useAxiosUserSecureConfig";
import Select from "react-select";
import Loader from "../../../components/Loader";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import placholder_image from "../../../assets/images/booking/placeholder-image.jpg";
import uplooad from "../../../assets/images/icons/upload.svg";
import { useTable, useSortBy, usePagination } from 'react-table';
import editIcon from "../../../assets/images/icons/edit.png";
import acceptIcon from "../../../assets/images/icons/accept.svg";
import eyeIcon from "../../../assets/images/icons/eye.png";



function SupplierEditProduct() {
  const user = useSelector((state) => state.auth.user);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const {
    register,
    control,
    setError,
    setValue,
    formState: { errors },
    handleSubmit,
    watch
  } = useForm();

  const columns = React.useMemo(
    () => [ 
      { Header: t('Image'), accessor: 'productImage' },
      { Header: t('Name'), accessor: 'productName' },
      { Header: t('Color'), accessor: 'productColor' },
      { Header: t('Size'), accessor: 'productSize' },
      { Header: t('Condition'), accessor: 'productCondition' },
      { Header: t('Quantity Available'), accessor: 'productQuantity' },
      { Header: t('Price'), accessor: 'productPrice' },
      { Header: t('Enabled'), accessor: 'productavailable' },
     ],
    [t]
  );

  const [categories, setCategories] = useState({ loading: true, data: [] });
  useEffect(() => {
    axios
      .post(`/api/product_categories`, {} ,
        {headers: {
          'Accept-Language': 'ar'}
        }
      )
      .then((res) => {
        console.log("categories", res.data);

        if (!res.data?.result?.success) {
          toast.error("Error while fetching categories!");
          return;
        }

        setCategories((prevState) => ({
          ...prevState,
          loading: false,
          data: res.data?.result?.categories ?? [],
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [brands, setBrands] = useState({ loading: true, data: [] });
  useEffect(() => {
    axios
      .post(`/api/product/brands`, {})
      .then((res) => {
        console.log("brands", res.data);

        if (!res.data?.result?.success) {
          toast.error("Error while fetching brands!");
          return;
        }

        setBrands((prevState) => ({
          ...prevState,
          loading: false,
          data: res.data?.result?.brands ?? [],
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const customStyles = {
    control: (styles) => ({
      ...styles,
      background: "#F2F4F8",
      "border-color": "#F2F4F8",
      "border-radius": "8px",
      padding: "6px",
    }),
  };
  const [product , setProduct] = useState({
    loading: true,
    data: [],
  });
  const [variantsWithImages , setVariantsWithImages] = useState([])
  const config = useAxiosUserSecureConfig();
  const params = useParams();

  const fetchProductData = () =>{
    axios
    .post(`/api/vendor/product/${params.id}`, {}, config)
    .then(async (res) => {
      if (!res.data?.result?.success) {
        toast.error(t("Error while fetching facility!"));
        return;
      }
      setProduct({
        loading: false,
        data: res.data?.result.product || [], 
      });
      const variantsWithImages = await Promise.all(
        res.data.result.product.variants.map(async (variant) => {
          try {
            const imageRes = await axios.post(`/api/vendor/product/variant/${variant.id}/image2`, {}, config);
            if (!imageRes.data.result?.success) {
              throw new Error(imageRes.data.result?.message || t("Request failed!"));
            }
            return { ...variant, image: imageRes.data.result.image };
          } catch (error) {
            console.error('Error fetching image:', error);
            return { ...variant, image: null };
          }
        })
      );
      setVariantsWithImages(variantsWithImages);
    })
    .catch((err) => {
      console.log(err);
    });
  }
  console.log("variantsWithImages" , variantsWithImages)
  useEffect(()=> {
    fetchProductData()
  },[])
  console.log("product", product)
  axios.defaults.timeout = 50000
  axios.defaults.timeoutErrorMessage='timeout'
  
  async function onSubmit(data) {
    console.log("data", data);
    const currentPrice = Number(product.data.list_price);
    const newPrice = Number(data.price);
    console.log("newPrice", newPrice);
    console.log("currentPrice", currentPrice);
  
    let requestData = {
      name: data.name,
      description: data.description,
      brand: data.brand,
      weight: data.weight,
      image_1920: imageBase64.split(",")[1],
    };
  
    try {
      const updatePromises = [];
  
      // Only add list_price if the price has changed
      if (newPrice !== currentPrice) {
        updatePromises.push(
          axios.post(`/api/vendor/product/${params.id}/price/update`, { list_price: newPrice }, config)
        );
      }
  
      updatePromises.push(
        axios.post(`/api/vendor/product/${params.id}/update`, requestData, config)
      );
  
      const responses = await Promise.all(updatePromises);
  
      // Check the responses
      const allSuccess = responses.every((res) => res.data.result?.success);
  
      if (allSuccess) {
        toast.success(t("You updated the product successfully!"));
        fetchProductData();
      } else {
        toast.error(t("Request failed!")); // General error message
      }
    } catch (err) {
      console.log("err", err);
      toast.error(t("Error while updating product!"));
    }
  }
  
  const [imageBase64, setImageBase64] = useState(placholder_image);
  const [imageName, setImageName] = useState("");

  useEffect(()=> {
    axios
    .post(`/api/product/${params.id}/image`, {}, config)
    .then(async (res) => {
      if (!res.data?.result?.success) {
        toast.error(t("Error while fetching facility!"));
        return;
      }
      setImageBase64(res.data?.result.image);
    })
    .catch((err) => {
      console.log(err);
    });
  },[])

  

  const handleImageChange = (e) => {
    setImageName(e.target.files[0].name);
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Extract base64 data without the prefix
        const base64WithoutPrefix = reader.result;
        setImageBase64(base64WithoutPrefix);
      };
      reader.readAsDataURL(file);
    }
  };
  const [variantimageBase64, setVariantImageBase64] = useState();
  const [variantimageName, setVariantImageName] = useState("");

  const handleVariantImageChange = (e) => {
    setVariantImageName(e.target.files[0].name);
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Extract base64 data without the prefix
        const base64WithoutPrefix = reader.result;
        setVariantImageBase64(base64WithoutPrefix);
      };
      reader.readAsDataURL(file);
    }
  };

  const reqestsData = React.useMemo(() => {
    return product.data.variants?.length > 0
      ? product.data.variants
          .map((key) => ({
            id: key.id,
            productImage :'',
            productName: key.name2,
            productColor : key.color,
            productSize: key.size,
            productCondition: key.condition || "new",
            productPrice : key.vendor_price,
            productQuantity : key.quantity,
            productavailable : key.available ? "True" : "False",
            variantId : key.id
          }))
      : [];
  }, [product.data.variants]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data: reqestsData,
      initialState: { pageIndex: 0, pageSize:  4 }, 
    },
    useSortBy,
    usePagination
  );

  const [editingRowId, setEditingRowId] = useState(null);

  // Inside your handleEdit function
  const handleEdit = (rowId) => {
    console.log("Editing row:", rowId);
    setEditingRowId(rowId);
  };

  const handleSave = (variantId) => {
    console.log(variantId)
    setEditingRowId(null);
    const data = {
      name2: watch(`variantName${variantId}`),
      quantity: watch(`quantity${variantId}`),
      available: watch(`variantEnabled${variantId}`).value,
      price: watch(`variantPrice${variantId}`),
      condition: watch(`variantCondition${variantId}`).value,
      image_1920: variantimageBase64?.split(",")[1],
    };
    console.log(data)
      axios
        .post(`/api/vendor/product/variant/${variantId}/update`, data, config)
        .then((res) => {
          if (!res.data.result?.success) {
            console.log(res.data)
            toast.error(res.data.result?.message || t("Request failed!"));
            return;
          }
          toast.success(t("You update product successfully!"));
          fetchProductData()
          setVariantImageBase64('')
        })
        .catch((err) => {
          console.log("err", err);
          toast.error(t("Error while updating product!"));
        });
  };
  
  return (
    <section className="px-5 xl:px-20 pt-10">
      <div className="flex justify-between items-center mb-5">
        <div className="flex items-center gap-6">
          <div className="justify-start items-center gap-2 hidden sm:inline-flex">
            <div
              onClick={() => navigate("/product-Supplier/Account")}
              className="text-zinc-400 text-sm sm:text-base font-normal sm:leading-[48px] hover:text-gray-dark cursor-pointer"
            >
              {t("Product Supplier Account")}
            </div>
            <FontAwesomeIcon
              className="text-zinc-400 text-base"
              icon={faAngleRight}
            />
            <div
              onClick={() => navigate("/productSupplier/productList")}
              className="text-zinc-400 text-sm sm:text-base font-normal sm:leading-[48px] hover:text-gray-dark cursor-pointer"
            >
              {t("My Products")}
            </div>
            <FontAwesomeIcon
              className="text-zinc-400 text-base"
              icon={faAngleRight}
            />
            <div className="text-zinc-400 text-sm sm:text-base font-normal sm:leading-[48px] hover:text-gray-dark cursor-pointer">
              {t("Edit Products")}
            </div>
          </div>
        </div>
      </div>
      {product.loading && <Loader slim />}
      {!product.loading && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-10 mb-10 pb-10 border-b">
            <div className=" w-[90%] mx-auto self-stretch flex-col justify-center flex">
              <p className="text-lg md:text-2xl font-semibold leading-9 mb-6 text-red">
              {t("Edit Products")}
              </p>
              <div className="w-full flex-col lg:grid grid-cols-3 justify-start items-start gap-4 inline-flex">
                <div className="flex w-full flex-col justify-start items-start gap-2 ">
                  <label className="text-zinc-800 text-base font-normal leading-snug">
                    {t("Category")}{" "}
                    <span className="text-red-500 text-base font-normal leading-snug">
                      *
                    </span>
                  </label>
                  <div className="relative w-full">
                    <input
                      defaultValue={product.data.category.name}
                      {...register("category")}
                      className="w-full p-3 bg-slate-100 border-slate-100 rounded-lg text-gray-400"
                      placeholder="category"
                      readOnly
                      disabled
                    />
                  </div>
                </div>

                <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                  <label className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">
                    {t("Name")}{" "}
                    <span className="text-red-500 text-base font-normal leading-snug">
                      *
                    </span>
                  </label>
                  <div className="relative w-full">
                    <input
                      defaultValue={product.data.name}
                      error={!!errors.name}
                      {...register("name", {
                        required: t("Product name is required"),
                      })}
                      className="w-full p-3 bg-slate-100 border-slate-100 rounded-lg "
                      placeholder={t("Product name")}
                    />
                  </div>
                  {errors.name && (
                    <Typography variant="small" color="red">
                      {errors.name?.message}
                    </Typography>
                  )}
                </div>

                <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                  <label className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">
                    {t("Price")}{" "}
                    <span className="text-red-500 text-base font-normal leading-snug">
                      *
                    </span>
                  </label>
                  <div className="relative w-full">
                    <input
                      defaultValue={product.data.list_price}
                      type="number"
                      error={!!errors.price}
                      {...register("price", {
                        required: t("Price is required"),
                      })}
                      className="w-full p-3 bg-slate-100 border-slate-100 rounded-lg "
                      placeholder={t("Product price")}
                    />
                  </div>
                  {errors.price && (
                    <Typography variant="small" color="red">
                      {errors.price?.message}
                    </Typography>
                  )}
                </div>
                <div className="flex gap-2 items-end">
                <img src={imageBase64} alt="imageBase64" className="w-20 h-[50px] object-cover rounded-lg"/>
                <div className="flex flex-col justify-start items-start gap-2 grow">   
                  <label className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">
                    {t("Image")}{" "}
                    <span className="text-red-500 text-base font-normal leading-snug">
                      *
                    </span>
                  </label>

                  <div className="relative w-full bg-slate-100 border-slate-100 rounded-lg">
                    <label>
                      <div className="h-12 w-full flex justify-between items-center">
                        {/* Hidden file input */}
                        <input
                          hidden
                          type="file"
                          {...register("productImage")}
                          onChange={handleImageChange}
                          id="fileInput" // Add an ID for labeling purposes
                        />

                        {/* Upload icon */}
                        <label htmlFor="fileInput" className="cursor-pointer">
                          <img
                            src={uplooad}
                            className={`absolute ${i18n.language === 'en' ? 'right-3' : 'left-3'}  bottom-3`}
                            alt="upload"
                          />
                        </label>

                        {/* Placeholder and image name */}
                        <input
                          type="text"
                          className="border-0 w-full flex-grow p-4 bg-slate-100 rounded-lg text-zinc-500 text-base font-normal leading-tight placeholder-[#808080]"
                          placeholder={t("Choose a file")}
                          value={imageName ? imageName : ""}
                          readOnly
                        />
                      </div>
                    </label>
                  </div>
                  {errors.productImage && (
                    <Typography variant="small" color="red">
                      {errors.productImage?.message}
                    </Typography>
                  )}
                </div>
                </div>
                <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                  <label className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">
                    {t("Brand")}
                  </label>
                  <input
                  defaultValue={product.data.brand}
                  {...register("brand")}
                  className="w-full p-3 bg-slate-100 border-slate-100 rounded-lg "
                  placeholder="Product name"
                />
                </div>
                <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                  <label className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">
                  {t("Weight")}{" "}
                  <span className="text-red-500 text-base font-normal leading-snug">
                    *{" "}
                  </span>
                  <span className="text-gray-400 text-base font-light lead">{t("(Kg)")}</span>
                  </label>
                  <input
                   type="text"
                  defaultValue={product.data.weight}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^0-9.]/g, "");
                  }}
                  {...register("weight")}
                  className="w-full p-3 bg-slate-100 border-slate-100 rounded-lg "
                  placeholder="Product weight"
                />
                </div>
                <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                  <label className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">
                  {t("Description")}
                  </label>
                  <div className="relative w-full">
                    <input
                      defaultValue={product.data.description.replace(
                        /<\/?p>/g,
                        ""
                      )}
                      {...register("description")}
                      className="w-full p-3 bg-slate-100 border-slate-100 rounded-lg "
                      placeholder="Product description"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="w-[90%] mx-auto flex justify-end">
            <button
              type="submit"
              className="w-fit px-16 py-3 bg-red rounded-[10px] shadow border border-white justify-center items-center gap-2.5 inline-flex"
            >
              <div className="text-center text-white text-base font-normal leading-normal">
                {t("Save")}
              </div>
            </button>
          </div>
          </div>
          {product.data.variants.length > 0 && (
            <div className="!overflow-x-auto">
              <table className="w-full border-collapse mb-[85px] rounded-3xl shadow-md border border-gray-500">
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr
                      className="h-12 bg-slate-100"
                      {...headerGroup.getHeaderGroupProps()}
                    >
                      <th className="py-2  px-4 border text-gray-500 text-sm md:text-base font-medium leading-snug">
                        #
                      </th>
                      {headerGroup.headers.map((column) => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          className={`py-2 px-4 border text-gray-500 text-sm md:text-base font-medium leading-snug ${
                            column.isSorted
                              ? column.isSortedDesc
                                ? "sort-desc"
                                : "sort-asc"
                              : ""
                          }`}
                        >
                          {column.render("Header")}
                        </th>
                      ))}
                      <th 
                      className="py-2 px-4 border text-gray-500 text-sm md:text-base font-medium leading-snug">
                        {t("Actions")}
                      </th>
                    </tr>
                  ))}
                </thead>
                <tbody className="bg-white">
                  {rows.map((row, index) => {
                    prepareRow(row);
                    return (
                      <tr
                        {...row.getRowProps()}
                        className="border-t h-12"
                        key={index}
                      >
                        <td className="px-1 border text-center text-gray-500 text-xs font-light leading-none">
                          {index + 1}
                        </td>
                        {row.cells.map((cell, cellIndex) => (
                          <td
                            {...cell.getCellProps()}
                            className="px-1 text-nowrap  border text-center text-[#697077] text-sm font-normal leading-tight"
                            key={cellIndex}
                          >
                            {editingRowId === row.id ? (
                              cell.column.Header === t("Image") ? (
                                <div className="flex w-60  gap-1 items-end">
                                  <img
                                  className="w-20 h-12 object-cover"
                                  src={
                                    variantimageBase64 ||
                                    variantsWithImages.find(
                                      (variant) =>
                                        variant.id === row.original.variantId
                                    )?.image 
                                  }
                                />
                                  <div className="relative bg-slate-100 border-slate-100 rounded-lg">
                                  <label>
                                    <div className="h-12 flex justify-between items-center">
                                      {/* Hidden file input */}
                                      <input
                                        hidden
                                        type="file"
                                        {...register("variantProductImage", {
                                          required: t("Product image is required"),
                                        })}
                                        onChange={handleVariantImageChange}
                                        id="variantFileInput" // Add an ID for labeling purposes
                                      />

                                      {/* Upload icon */}
                                      <label
                                        htmlFor="variantFileInput"
                                        className="cursor-pointer"
                                      >
                                        <img
                                          src={uplooad}
                                          className={`absolute ${i18n.language === 'en' ? 'right-3' : 'left-3'}  bottom-3`}
                                          alt="upload"
                                        />
                                      </label>

                                      {/* Placeholder and image name */}
                                      <input
                                        type="text"
                                      
                                        className="w-[10%] border-0 flex-grow p-4 bg-slate-100 rounded-lg text-zinc-500 text-base font-normal leading-tight placeholder-[#808080]"
                                        placeholder={t("Choose a file")}
                                        value={
                                          variantimageName
                                            ? variantimageName
                                            : ""
                                        }
                                        readOnly
                                      />
                                    </div>
                                  </label>
                                </div>
                                </div>
                              ) : cell.column.Header === t("Name") ? (
                                <input
                                  defaultValue={cell.value}
                                  error={!!errors.name}
                                  {...register(
                                    `variantName${row.original.id}`,
                                    {
                                      required: t("Product name is required"),
                                    }
                                  )}
                                  className="w-full p-3 bg-slate-100 border-slate-100 rounded-lg "
                                  placeholder="Product name"
                                />
                              ) :  cell.column.Header === t("Quantity Available") ? (
                                <input
                                type="number"
                                  defaultValue={cell.value}
                                  error={!!errors.name}
                                  {...register(
                                    `quantity${row.original.id}`,
                                    {
                                      required: t("Quantity Available is required"),
                                    }
                                  )}
                                  className="w-full p-3 bg-slate-100 border-slate-100 rounded-lg"
                                  placeholder="Product Quantity Available"
                                />
                              ) : cell.column.Header === t("Condition") ? (
                                <Controller
                                  control={control}
                                  name={`variantCondition${row.original.id}`}
                                  defaultValue={
                                    t("new")
                                      ? {
                                          value: "new",
                                          label: t("new"),
                                        }
                                      : null
                                  }
                                  render={({ field, field: { name, ref } }) => {
                                    const options = [
                                      { value: "new", label: t("new") },
                                      { value: "used", label: t("used") },
                                    ];
                                    return (
                                      <Select
                                        styles={customStyles}
                                        name={name}
                                        ref={ref}
                                        isClearable
                                        isSearchable
                                        options={options}
                                        {...field}
                                        className="w-full"
                                      />
                                    );
                                  }}
                                />
                              ) : cell.column.Header === t("Price") ? (
                                <input
                                  defaultValue={cell.value}
                                  type="number"
                                  error={!!errors.price}
                                  {...register(
                                    `variantPrice${row.original.id}`,
                                    {
                                      required: t("Price is required"),
                                    }
                                  )}
                                  className="w-full p-3 bg-slate-100 border-slate-100 rounded-lg"
                                  placeholder="Product price"
                                />
                              ) : cell.column.Header === t("Enabled") ? (
                                <Controller
                                  control={control}
                                  name={`variantEnabled${row.original.id}`}
                                  defaultValue={
                                    cell.value === "True"
                                      ? {
                                          value: 1,
                                          label: "True",
                                        }
                                      : {
                                        value: 0,
                                        label: "False",
                                      }
                                  }
                                  render={({ field, field: { name, ref } }) => {
                                    const options = [
                                      { value: 1, label: "True" },
                                      { value: 0, label: "False" },
                                    ];
                                    return (
                                      <Select
                                        styles={customStyles}
                                        name={name}
                                        ref={ref}
                                        isClearable
                                        isSearchable
                                        options={options}
                                        {...field}
                                        className="w-full"
                                      />
                                    );
                                  }}
                                />
                              ) : (
                                cell.render("Cell")
                              )
                            ) : cell.column.Header === t("Image") ? (
                              <div className="w-full flex justify-center items-center py-1">
                                <img
                                  className="w-20 h-12 object-cover"
                                  src={
                                    variantsWithImages.find(
                                      (variant) =>
                                        variant.id === row.original.variantId
                                    )?.image || ""
                                  }
                                />
                              </div>
                            ) : (
                              cell.render("Cell")
                            )}
                          </td>
                        ))}
                        <td className="px-2 border text-center">
                          {editingRowId !== row.id ? (
                            <button
                              type="button"
                              title="Edit"
                              onClick={() => handleEdit(row.id)}
                            >
                              <img src={editIcon} alt="editIcon" />
                            </button>
                          ) : (
                            <button
                              type="button"
                              title="Save"
                              onClick={() => handleSave(row.original.variantId)}
                            >
                              <img src={acceptIcon} alt="acceptIcon" />
                            </button>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </form>
      )}
    </section>
  );
}

export default SupplierEditProduct;
