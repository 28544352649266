import React, { useEffect, useState } from "react";
import { Typography } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";
import error from "../../Error";
import useAxiosUserSecureConfig from "../../../hooks/useAxiosUserSecureConfig";
import Select from "react-select";
import Loader from "../../../components/Loader";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";

function ProviderAddServices() {
  const user = useSelector(state => state.auth.user)
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    register,
    control,
    setError,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm();
  
  const [categories, setCategories] = useState({ loading: true, data: [] });
  useEffect(() => {
    axios.post(`/api/vendor/${user.partnerId}`, {id: user.partnerId}).then((res) => {
        console.log("categories", res.data);

        if (!res.data?.result?.success) {
            toast.error("Error while fetching categories!");
            return;
        }

        setCategories(prevState => ({
            ...prevState,
            loading: false,
            data: res.data?.result?.vendor?.service_categories ?? []
        }))
    }).catch((err) => {
        console.log(err);
    })
}, [])

  const customStyles = {
    control: (styles) => ({
      ...styles,
      background: "#F2F4F8",
      "border-color": "#F2F4F8",
      "border-radius": "8px",
      padding: "6px",
    }),
  };

  const config = useAxiosUserSecureConfig();
  function onSubmit(data) {
    console.log("data", data);
    data = {
      name : data.name,
      categ_id: data.category.value,
      list_price: data.price,
      description: data.description,
    }
    axios.post('/api/register_service' ,data, config)
        .then((res) => {
          if (!res.data.result?.success) {
            toast.error(res.data.result?.message || "Request failed!");
            return;
          }
          toast.success("You add service successfully!");
          navigate('/serviceProvider/services-list')
        })
        .catch((err) => {
          console.log("err" , err)
          toast.error("Error while adding service!");
        })
  }


  return (
    <section className="px-5 xl:px-20 py-10">
      <div className="flex justify-between items-center mb-5">
        <div className="flex items-center gap-6">
          <div className="justify-start items-center gap-2 hidden sm:inline-flex">
            <div
              onClick={() => navigate("/serviceProvider/account")}
              className="text-zinc-400 text-sm sm:text-base font-normal sm:leading-[48px] hover:text-gray-dark cursor-pointer"
            >
             {t("Service Provider Account")}
            </div>
            <FontAwesomeIcon
              className="text-zinc-400 text-sm sm:text-base "
              icon={faAngleRight}
            />
            <div
            onClick={() => navigate("/serviceProvider/services-list")}
             className="text-zinc-400 text-sm sm:text-base font-normal sm:leading-[48px] hover:text-gray-dark cursor-pointer">
              {t("My Services")}
            </div>
            <FontAwesomeIcon
              className="text-zinc-400 text-sm sm:text-base "
              icon={faAngleRight}
            />
            <div className="text-zinc-400 text-sm sm:text-base font-normal sm:leading-[48px] hover:text-gray-dark cursor-pointer">
            {t("Add Service")}
            </div>
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-10">
        <div className=" w-full sm:w-3/4 mx-auto self-stretch flex-col justify-center flex">
          <p className="text-lg md:text-2xl font-semibold leading-9 mb-6 text-red">
          {t("Add Service")}
          </p>
          <div className="w-full flex-col lg:grid grid-cols-2 justify-start items-start gap-4 inline-flex">
            <div className="flex w-full flex-col justify-start items-start gap-2 ">
              <label className="text-zinc-800 text-base font-normal leading-snug">
                {t("Service Category")} <span className="text-red-500 text-base font-normal leading-snug">*</span>
              </label>
              {categories.loading && <Loader slim />}
              {!categories.loading && (
              <Controller
                control={control}
                name="category"
                className="w-full"
                rules={{ required: t("category is required") }}
                render={({ field, field: { name, ref } }) => {
                  return (
                    <Select
                      styles={customStyles}
                      name={name}
                      ref={ref}
                      isClearable
                      placeholder={t("Select...")}
                      isSearchable
                      options={categories.data.map((category) => ({
                        value: category.id,
                        label: category.name,
                      }))}
                      {...field}
                      className="w-full"
                    />
                  );
                }}
              />
              )}
              {errors.category && (
                <Typography variant="small" color="red">
                  {errors.category?.message}
                </Typography>
              )}
            </div>

            <div className="self-stretch flex-col justify-start items-start gap-2 flex">
              <label className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">
                {t("Service Name")} <span className="text-red-500 text-base font-normal leading-snug">*</span>
              </label>
              <div className="relative w-full">
                <input
                  error={!!errors.name}
                  {...register("name", {
                    required: t("service name is required"),
                  })}
                  className="w-full p-3 bg-slate-100 border-slate-100 rounded-lg "
                  placeholder={t("service name")}
                />
              </div>
              {errors.name && (
                <Typography variant="small" color="red">
                  {errors.name?.message}
                </Typography>
              )}
            </div>

            <div className="self-stretch flex-col justify-start items-start gap-2 flex">
              <label className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">
                {t("Service Price")} <span className="text-red-500 text-base font-normal leading-snug">*</span>
              </label>
              <div className="relative w-full">
                <input
                type="number"
                  error={!!errors.price}
                  {...register("price", {
                    required: t("Price is required"),
                  })}
                  className="w-full p-3 bg-slate-100 border-slate-100 rounded-lg "
                  placeholder={t("serive price")}
                />
              </div>
              {errors.price && (
                <Typography variant="small" color="red">
                  {errors.price?.message}
                </Typography>
              )}
            </div>
            <div className="self-stretch flex-col justify-start items-start gap-2 flex">
              <label className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">
                {t("Service description")}
              </label>
              <div className="relative w-full">
                <input
                 
                  {...register("description")}
                  className="w-full p-3 bg-slate-100 border-slate-100 rounded-lg "
                  placeholder={t("service description")}
                />
              </div>
             
            </div>
          </div>
        </div>
        <div className="flex justify-end w-3/4 mx-auto">
              <button
                  type="submit"
                  className="w-fit px-16 py-3 bg-red rounded-[10px] shadow border border-white justify-center items-center gap-2.5 inline-flex"
              >
                <div className="text-center text-white text-base font-normal leading-normal">
                  {t("Save")}
                </div>
              </button>
        </div>
      </form>
    </section>
  );
}

export default ProviderAddServices;
