import React, { useEffect, useState } from "react";
import Select from "react-select";
import {Typography, Option} from "@material-tailwind/react";
import {useTranslation} from "react-i18next";
import {Controller, useForm} from "react-hook-form";
import { useNavigate , useParams} from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import useAxiosUserSecureConfig from "../../hooks/useAxiosUserSecureConfig";
import axios from "axios";
import Loader from "../../components/Loader";
import { useTable, useSortBy, usePagination } from 'react-table';
import BookingCancellation from "../../components/venues/BookingCancellation";
import ConfirmationModal from "../../components/ConfirmationModal";
import { toast } from "react-toastify";



function OrderDetails (){
  const { t, i18n } = useTranslation();
const navigate = useNavigate();
const params = useParams();
const config = useAxiosUserSecureConfig();
const [currentPage, setCurrentPage] = useState(0);

const [order, setOrder] = useState({
  loading: true,
  data: {},
});
const columns = React.useMemo(
  () => [
    { Header: t('Products'), accessor: 'products' },
    { Header: t('Description'), accessor: 'description' },
    { Header: t('Qty'), accessor: 'qty' },
    { Header: t('Unit Price'), accessor: 'price' },
    { Header: t('Amount'), accessor: 'Amount' },
   ],
  [t]
);
const [shipments , setShipmenets]= useState([])
const [items , setItems]= useState([])
useEffect(() => {
  axios
    .post(`/api/order/${params.id}`, {} , config)
    .then((res) => {
      if (!res.data?.result?.success) {
        toast.error(t("Error while fetching order!"));
      }
      setOrder({
        loading: false,
        data: res.data?.result.order || {}, 
      });
      setShipmenets(res.data?.result.order.shipments)
      setItems(res.data?.result.order.items)
    })
    .catch((err) => {
      console.log(err);
    });
}, [params.id]);
console.log("order", order);


const reqestsData = React.useMemo(() => {
  return  items.length > 0
    ? items.map((key) => ({
        id: key.id,
        products : key.product.name,
        description :key.product.description.replace(/<\/?p>/g, ''),
        qty : key.qty,
        price :key.price,
        Amount :key.value,
      }))
    : [];
}, [items]);

const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
  useTable(
    {
      columns,
      data: reqestsData,
      initialState: { pageIndex: currentPage, pageSize: 4 },
    },
    useSortBy,
    usePagination
  );

  const [open, setOpen] =useState(false);
  const handleClose = () => setOpen(false);
  const handleCancelOrder = () => {

  axios.post(`/api/store/order/${params.id}/cancel` ,{}, config)
      .then((res) => {
        console.log(res.data)
        if (!res.data.result?.success) {
          toast.error(res.data.result?.message || "Request failed!");
          return;
        }
        toast.success(t("You cancel order successfully!"));
        navigate("/user/orders")
      })
      .catch((err) => {
        console.log("err" , err)
        toast.error(t("Error while canceling order!"));
      })
  }

    return (
      <div className="bg-slate-100">
        <ConfirmationModal
          open={open}
          handleClose={handleClose}
          handleAction={handleCancelOrder}
          msg={t("Are you sure you want to cancel this order!")}
        />
        <div className="flex justify-between items-center mt-2 mx-5">
          <div className="flex items-center gap-6">
            <div className="justify-start items-center gap-2 hidden sm:inline-flex">
              <div
                onClick={() => navigate("/user/account")}
                className="text-zinc-400 text-sm md:text-base font-normal md:leading-[48px] hover:text-gray-dark cursor-pointer"
              >
                {t("My Account")}
              </div>
              <FontAwesomeIcon
                className="text-zinc-400 text-sm md:text-base"
                icon={faAngleRight}
              />
              <div
                onClick={() => navigate("/user/orders")}
                className="text-zinc-400 text-sm md:text-base font-normal md:leading-[48px] hover:text-gray-dark cursor-pointer"
              >
                {t("My Orders")}
              </div>
              <FontAwesomeIcon
                className="text-zinc-400 text-sm md:text-base"
                icon={faAngleRight}
              />
              <div className="text-zinc-400 text-sm md:text-base font-normal md:leading-[48px] hover:text-gray-dark cursor-pointer">
                {t("Order Details")}
              </div>
            </div>
          </div>
        </div>
        <div className="md:w-5/6 w-11/12  mx-auto my-6 flex justify-between">
          <p className=" justify-start text-start font-bold text-2xl text-red">
            {t("Order Details")}
          </p>
          {order.data?.state?.id !== "cancelled" &&
            order.data?.state?.id !== "shipped" && (
              <button
                onClick={() => setOpen(true)}
                className={`px-8 py-[9px] rounded-[10px] shadow border border-red justify-center items-center gap-2.5 flex`}
              >
                <span className="text-center text-red text-base font-normal font-['Inter'] leading-tight">
                  {t("Cancel Order")}
                </span>
              </button>
            )}
        </div>
        {order.loading && <Loader slim />}
        {!order.loading && (
          <div className="px-3 sm:px-4 lg:px-10 py-10 bg-white md:w-5/6 w-11/12 mx-auto border rounded-2xl mb-3">
            <div className="grid grid-cols-4 lg:gap-x-8 sm:gap-x-4 gap-x-2 gap-y-5 mb-10">
              <div className="flex flex-col col-span-2 md:col-span-1 ">
                <label className="text-sm sm:text-base">{t("Order ID")}</label>
                <input
                  type="text"
                  className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                  value={order.data.id}
                  disabled
                />
              </div>
              <div className="flex flex-col col-span-2 md:col-span-1">
                <label className="text-sm sm:text-base">
                  {t("Order Date")}
                </label>
                <input
                  type="text"
                  className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                  value={new Date(order.data.time_placed).toLocaleDateString()}
                  disabled
                />
              </div>

              <div className="flex flex-col col-span-2 md:col-span-1">
                <label className="text-sm sm:text-base">{t("Bill To")}</label>
                <input
                  type="text"
                  className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                  value={order.data.buyer}
                  disabled
                />
              </div>
              <div className="flex flex-col col-span-2 md:col-span-1">
                <label className="text-sm sm:text-base">{t("Ship To")}</label>
                <input
                  type="text"
                  className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                  value={order.data.shipping_address}
                  disabled
                />
              </div>
              <div className="flex flex-col col-span-2 md:col-span-1">
                <label className="text-sm sm:text-base">{t("Country")}</label>
                <input
                  type="text"
                  className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                  value={order.data.shipping_country}
                  disabled
                />
              </div>
              <div className="flex flex-col col-span-2 md:col-span-1">
                <label className="text-sm sm:text-base">{t("State")}</label>
                <input
                  type="text"
                  className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                  value={order.data.shipping_state}
                  disabled
                />
              </div>
              <div className="flex flex-col col-span-2 md:col-span-1">
                <label className="text-sm sm:text-base">{t("City")}</label>
                <input
                  type="text"
                  className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                  value={order.data.shipping_city}
                  disabled
                />
              </div>
              <div className="flex flex-col col-span-2 md:col-span-1">
                <label className="text-sm sm:text-base">
                  {t("Phone Number")}
                </label>
                <input
                  type="text"
                  className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                  value={order.data.shipping_phone}
                  disabled
                />
              </div>
              <div className="flex flex-col col-span-2 md:col-span-1">
                <label className="text-sm sm:text-base">
                  {t("Additional Phone Number")}
                </label>
                <input
                  type="text"
                  className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                  value={order.data.additional_number}
                  disabled
                />
              </div>
              <div className="flex flex-col col-span-2 md:col-span-1">
                <label className="text-sm sm:text-base">
                  {t("Building Number")}
                </label>
                <input
                  type="text"
                  className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                  value={order.data.building_number}
                  disabled
                />
              </div>
              <div className="flex flex-col col-span-2 md:col-span-1">
                <label className="text-sm sm:text-base">
                  {t("Flat Number")}
                </label>
                <input
                  type="text"
                  className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                  value={order.data.flat_number}
                  disabled
                />
              </div>
              <div className="flex flex-col col-span-2 md:col-span-1">
                <label className="text-sm sm:text-base">{t("Floor")}</label>
                <input
                  type="text"
                  className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                  value={order.data.floor}
                  disabled
                />
              </div>
              <div className="flex flex-col col-span-4">
                <label className="text-sm sm:text-base">
                  {t("Order notes")}{" "}
                </label>
                <input
                  type="text"
                  className="w-full h-24 p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                  value={order.data.notes}
                  disabled
                />
              </div>
            </div>
            <div className="!overflow-x-auto w-full my-6">
              <table
                {...getTableProps()}
                className="w-full table-auto border-collapse "
              >
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr
                      className="h-12 bg-slate-100"
                      {...headerGroup.getHeaderGroupProps()}
                    >
                      <th className="py-2 px-4 border text-gray-500 text-base font-medium leading-snug">
                        {"#"}
                      </th>{" "}
                      {headerGroup.headers.map((column) => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          className={`py-2 px-4 border text-gray-500 text-base font-medium leading-snug ${
                            column.isSorted
                              ? column.isSortedDesc
                                ? "sort-desc"
                                : "sort-asc"
                              : ""
                          }`}
                        >
                          {column.render("Header")}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows
                    .slice(currentPage * 10, (currentPage + 1) * 10)
                    .map((row, index) => {
                      prepareRow(row);
                      return (
                        <tr
                          {...row.getRowProps()}
                          className="border-t h-12 cursor-pointer hover:bg-slate-100"
                        >
                          <td className="px-4 border text-center bg-slate-100 text-gray-500 text-xs font-light leading-none">
                            {index + 1 + currentPage * 4}
                          </td>{" "}
                          {row.cells.map((cell, cellIndex) => (
                            <td
                              {...cell.getCellProps()}
                              className="px-4 border text-center text-[#697077] text-sm font-normal leading-tight"
                              key={cellIndex}
                            >
                              {cell.render("Cell")}
                            </td>
                          ))}
                        </tr>
                      );
                    })}
                  {/* Total row */}
                  <tr className="h-12 bg-white">
                    <th
                      className={
                        "py-2 px-4 text-gray-500 text-base font-medium leading-snug "
                      }
                    >
                      {" "}
                    </th>
                    <th
                      className={
                        "py-2 px-4 text-gray-500 text-base font-medium leading-snug "
                      }
                    >
                      {" "}
                    </th>
                    <th
                      className={
                        "py-2 px-4 text-gray-500 text-base font-medium leading-snug "
                      }
                    >
                      {" "}
                    </th>
                    <th
                      className={
                        "py-2 px-4 text-gray-500 text-base font-medium leading-snug "
                      }
                    >
                      {" "}
                    </th>
                    <th
                      className={
                        "py-2 px-4 border bg-red text-white text-base font-medium leading-snug "
                      }
                    >
                      {t("Total")}
                    </th>
                    <th
                      className={
                        "py-2 px-4 border bg-red text-center text-white text-base font-medium leading-snug "
                      }
                    >
                      {order.data.total_price}
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
            {order.data.shipments.map((shipment) => (
              <div key={shipment.id} className="my-6">
                <div className="flex items-center gap-6 mb-5">
                  <p>
                    {t("Shipment ID")}: {shipment.id}
                  </p>
                  <p>
                    {t("Shipment Status")}:{" "}
                    {shipment.state === "new" ? (
                      <span className="text-[#CCCCCC] bg-[#F5F5F5] px-3 py-1">
                        {t("New")}
                      </span>
                    ) : shipment.state === "confirmed" ? (
                      <span className="text-[#49CC29] bg-[#E5FFE5] px-3 py-1 border rounded-lg">
                        {t("Confirmed")}
                      </span>
                    ) : shipment.state === "cancelled" ? (
                      <span className="text-[#CCCCCC] bg-[#F5F5F5] px-3 py-1">
                        {t("Cancelled")}
                      </span>
                    ) : (
                      <span className="text-[#1D4ABF]">{t("Shipped")}</span>
                    )}
                  </p>
                </div>
                <div className="!overflow-x-auto w-full my-6">
                  <table className="w-full table-auto border-collapse">
                    <thead>
                      <tr className="h-12 bg-slate-100 border-b">
                        <th className="py-2 px-4 border text-gray-500 text-base font-medium leading-snug">
                          #
                        </th>
                        <th className="py-2 px-4 border text-gray-500 text-base font-medium leading-snug">
                          {t("Products")}
                        </th>
                        <th className="py-2 px-4 border text-gray-500 text-base font-medium leading-snug">
                          {t("Description")}
                        </th>
                        <th className="py-2 px-4 border text-gray-500 text-base font-medium leading-snug">
                          {t("Qty")}
                        </th>
                        <th className="py-2 px-4 border text-gray-500 text-base font-medium leading-snug">
                          {t("Unit Price")}
                        </th>
                        <th className="py-2 px-4 border text-gray-500 text-base font-medium leading-snug">
                          {t("Amount")}
                        </th>
                        {shipment.state === "shipped" && (
                          <th className="py-2 px-4 border text-gray-500 text-base font-medium leading-snug">
                            {t("Actions")}
                          </th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {shipment.items.map((item, index) => (
                        <tr key={index} className="h-12 bg-white">
                          <td className="px-4 border text-center text-[#697077] text-sm font-normal leading-tight">
                            {index + 1}
                          </td>
                          <td className="px-4 border text-center text-[#697077] text-sm font-normal leading-tight">
                            {item.product.name}
                          </td>
                          <td className="px-4 border text-center text-[#697077] text-sm font-normal leading-tight">
                            {item.product.description.replace(/<\/?p>/g, "")}
                          </td>
                          <td className="px-4 border text-center text-[#697077] text-sm font-normal leading-tight">
                            {item.qty}
                          </td>
                          <td className="px-4 border text-center text-[#697077] text-sm font-normal leading-tight">
                            {item.price}
                          </td>
                          <td className="px-4 border text-center text-[#697077] text-sm font-normal leading-tight">
                            {item.value}
                          </td>
                          {shipment.state === "shipped" && (
                            <td className="px-4 border text-center text-red text-sm font-medium leading-tight">
                              <button
                                onClick={() =>
                                  navigate(
                                    `/user/order-review/${item.product.product_template_id}`
                                  )
                                }
                              >
                                {t("Reviews")}
                              </button>
                            </td>
                          )}
                        </tr>
                      ))}
                      <tr className="h-12 bg-white">
                        <td className="py-2 px-4 text-gray-500 text-base font-medium leading-snug"></td>
                        <td className="py-2 px-4 text-gray-500 text-base font-medium leading-snug"></td>
                        <td className="py-2 px-4 text-gray-500 text-base font-medium leading-snug"></td>
                        <td className="py-2 px-4 text-gray-500 text-base font-medium leading-snug"></td>
                        <th className="py-2 px-4 border bg-red text-white text-base font-medium leading-snug">
                          {t("Total")}
                        </th>
                        <th className="py-2 px-4 border bg-red text-white text-base font-medium leading-snug">
                          {shipment.total_price}
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    );

}
export default OrderDetails