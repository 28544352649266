import React, {useEffect} from 'react';
import {Helmet} from "react-helmet";
import {toast} from "react-toastify";
import {useParams} from "react-router-dom";

function PaymentGateway({}) {
    const { session_id } = useParams();

    const openPaymentGateway = async () => {
        function errorCallback(error) {
            console.log(JSON.stringify(error));
        }

        function cancelCallback() {
            console.log('Payment cancelled');
        }

        Checkout.configure({
            session: {
                id: session_id
            },
            interaction: {
                merchant: {
                    name: 'Rose Bazaar',
                },
                displayControl: {
                    billingAddress : "HIDE"
                },
            }
        });

        function complete(result) {
            console.log(result);
            alert("Payment OK");
        }

        Checkout.showPaymentPage();
    }

    useEffect(() => {
        openPaymentGateway()
            .catch(e => toast.error("Error calling Payment Gateway"));
    }, []);

    return (
        <div>
            <h1>Connecting to Payment Gateway...</h1>
        </div>
    );
}

export default PaymentGateway;