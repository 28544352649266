import React, { useEffect, useState } from "react";
import Rating from "@mui/material/Rating";
import axios from "axios";
import { toast } from "react-toastify";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import useAxiosUserSecureConfig from "../../hooks/useAxiosUserSecureConfig";
import Loader from "../../components/Loader";
import {useTranslation} from "react-i18next";


function OrderReviw() {
  const { t, i18n } = useTranslation();
const { register, control, handleSubmit } = useForm();
const navigate = useNavigate();
const params = useParams();
const config = useAxiosUserSecureConfig();

const onSubmit = (data) => {
    console.log("data", data);
    data = {
      rating: parseInt(data.quality),
      review: data.message,
    };
    axios
      .post(`/api/product/${params.id}/review`, data, config)
      .then((res) => {
        console.log(res.data);

        if (!res.data.result?.success) {
          toast.error(res.data.error.data?.message || t("Request failed!"));
          return;
        }
        toast.success(t("Review sent successfully!"));
      })
      .catch((err) => {
        console.log(err);
        toast.error(t("Error while sending Review!"));
      });
  };

  const [product , setProduct] = useState({ loading: true, data: [] })
  const [productImage , setProductImage] = useState(null)

  const fetchProductData = () => {
    axios
    .post(`/api/product/${params.id}`, {}, config)
    .then((res) => {
      if (!res.data?.result?.success) {
        toast.error(t(t("Error while fetching product!")));
        return;
      }
      setProduct({
        loading: false,
        data: res.data?.result.product || [], 
      });
      axios.post(`/api/product/${params.id}/image` , {} , config).then((res) => {
        console.log("image", res.data);
        if (!res.data?.result?.success) {
          toast.error(t("Something went wrong"))
          return;
        }
        setProductImage(res.data?.result?.image)
      }).catch((err) => {
        console.log(err);
        })
    })
    .catch((err) => {
      console.log(err);
    });
  }

  useEffect(() => {
    fetchProductData()
  }, []);

  console.log("product" , product)
  return (
    <section className="px-10 py-12">
      <p className="text-red text-2xl font-medium mb-8">{t("Reviews")}</p>
        {product.loading && <Loader slim/>}
          {!product.loading && <div className="flex gap-8 items-start">
          <div className="p-6 bg-zinc-100 rounded-2xl gap-6 inline-flex w-1/2">
                <div className="">
                  {" "}
                  <img
                    className="rounded-2xl h-[120px]"
                    src={productImage}
                    alt="product"
                  />
                </div>
                <div className="flex flex-col items-start">
                  <p className="text-center text-zinc-800 text-xl font-medium uppercase mb-4">
                    {product.data.name}
                  </p>
                  <div className="flex gap-2">
                    <span className=" text-gray-500 text-base font-normal font-['Roboto']">
                    {t("Supplier name")} :
                    </span>
                    <p className=" text-red text-base font-normal font-['Roboto']">
                      {product.data.vendor.name}
                    </p>
                  </div>
                </div>
              </div>
              <form
              onSubmit={handleSubmit(onSubmit)}
              className="w-1/2 flex flex-col gap-8"
            >
              <div className="p-6 bg-zinc-100 rounded-2xl">
                <div className="flex justify-between items-center">
                  <p className="text-zinc-800 text-xl font-normal">
                    <span className="text-gray-500 text-xl font-normal uppercase me-2 border-e border-gray-500 pe-2">
                      1
                    </span>
                    {t("Quality of product")}
                  </p>
                  <Controller
                    name="quality"
                    control={control}
                    defaultValue={0}
                    render={({ field }) => (
                      <Rating
                        {...field}
                        name="quality"
                        defaultValue={0}
                        precision={0.5}
                      />
                    )}
                  />
                </div>
              </div>
              <input
                {...register("message")}
                placeholder="Message"
                className="p-6 bg-zinc-100 rounded-2xl"
              />
              <div className="flex gap-3 items-center justify-end">
                <button className="h-[47px] px-9 py-[6.40px] bg-white rounded-[10px] shadow border border-red justify-center items-center gap-2.5 inline-flex">
                  <span className="text-center text-red text-base font-normal leading-normal">
                    {t("Cancel")}
                  </span>
                </button>
                <button
                  type="submit"
                  className="h-[47px] px-9 py-[6.40px] bg-red rounded-[10px] shadow border border-white justify-center items-center gap-2.5 inline-flex"
                >
                  <span className="text-center text-white text-base font-normal leading-normal">
                    {t("Save")}
                  </span>
                </button>
              </div>
            </form>
          </div>}

    </section>
  )
}

export default OrderReviw
