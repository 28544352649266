import React, { useEffect, useState } from 'react';
import mainImg from '../../../assets/images/consaltant/Rectangle 134.png';
import object2 from '../../../assets/images/consaltant/Group1.png';
import object1 from '../../../assets/images/consaltant/Group2.png';
import img1 from '../../../assets/images/consaltant/wedding-date.png';
import img2 from '../../../assets/images/consaltant/supplier.png';
import img3 from '../../../assets/images/consaltant/money-bag.png';
import img4 from '../../../assets/images/consaltant/car.png';
import img5 from '../../../assets/images/consaltant/management-service.png';
import img6 from '../../../assets/images/consaltant/businessman.png';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight ,faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {useTranslation} from "react-i18next";



function Consaltant(){
    const { t, i18n } = useTranslation();
    const user = useSelector(state => state.auth.user);
    const navigate = useNavigate();
    
    const handleBooking =()=> {
        if(user === null) {
          toast.error("You must be logged in")
          navigate("/login")
        }
        else {
          navigate('/BookConsultant')
        }
    
      }
    
    
  

return(
    <div className=' bg-[#f2f2f2]'>
<div className='flex flex-col md:flex-row md:justify-between justify-center md:px-16 px-7 '>
 <div className='w-full md:w-[63%]  my-12' >
    <h1 className='xl:text-4xl  md:text-xl font-bold mb-7 xl:mb-10'>{t("Wedding Consultant")}</h1>
    <div className='lg:text-lg text-sm'>
    <p className='pb-4'>{t("Rose Bazaar offers couples the expertise of professional Wedding Consultants to assist them in coordinating their wedding and ensuring that their special day is perfect. Their focus is on ensuring that every detail is executed according to plan.")}</p>
    <p className='pb-4'>{t("Wedding consultants can assist in ensuring that everything is prepared for your wedding day. In the event of any unexpected issues, they can discreetly and promptly handle the situation without the couple's awareness. Consultants are also able to coordinate with vendors to ensure that all aspects of the wedding, including food, flowers, decor, and staff, work together seamlessly to create a perfect wedding day.")} </p>  
    <p className='pb-4'>{t("Wedding consultants help ensure that your wedding turns out just the way you imagined. They provide assistance with financial, social, psychological, and health-related aspects, offering solutions to any challenges that may arise. They also work closely with the officiant to ensure that everything aligns with the couple's plans.")}</p>
   
         <div className='mt-6'>
    <button onClick={handleBooking} className='bg-red  py-2 xl:py-3 border rounded-lg'>
       <span className='text-white px-5 xl:px-8'>{t("Book Consultant")}</span>
       {i18n.language === 'en' ? (
                    <FontAwesomeIcon className='text-white pr-5 xl:pr-8' icon={faArrowRight} />
                ) : (<FontAwesomeIcon className='text-white pl-5 xl:pl-8' icon={faArrowLeft} />)}
      
    </button> 
    </div>   
    </div>   
 </div>
<div className='md:w-4/12 w-full just ml-3 my-7'>
 <div className='relative'>
    <img src={mainImg} className='lg:w-8/12 md:w-11/12 w-7/12 mx-auto md:mx-4 md:mt-10 relative z-10'/>
     <img src={object1}  className={`w-14 md:w-28 absolute ${i18n.language === 'en' ? 'lg:left-[65%]' : 'lg:left-[89%]'} top-[30%]  left-[76%] z-0`} />
    <img  src={object2}  className={`w-14 md:w-36 absolute ${i18n.language === 'en' ? 'md:right-[83%]' : 'md:right-[60%]'} top-[73%]   right-[73%] z-0`}/> 
 </div>
</div>
</div>

<div className='w-full '>
  <h1 className='text-lg mg:text-2xl font-bold mb-7 md:px-16 px-7 '>{t("What Is the Role of a Wedding consultant?")}</h1>

<div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 justify-items-center my-7 gap-4 w-full md:px-5 px-3  '>

    <div className='flex flex-col justify-center '><img src={img1} className='pb-3 mx-auto' />
    <p className='text-xs text-nowrap'>{t("Creating a Wedding Schedule")}</p></div>
    <div className='flex flex-col justify-center'><img src={img2} className='pb-3 mx-auto '/>
    <p className='text-xs text-nowrap'>{t("Choosing Suppliers")} </p></div>
    <div className='flex flex-col justify-center'><img src={img3} className='pb-3 mx-auto'/>
    <p className='text-xs text-nowrap'>{t("Handling Financial Plans")}</p></div>
    <div className='flex flex-col justify-center'><img src={img4} className='pb-3 mx-auto'/>
    <p className='text-xs text-nowrap'>{t("Supervising the Logistics")}</p></div>
    <div className='flex flex-col justify-center'><img src={img5} className='pb-3 mx-auto'/>
    <p className='text-xs text-nowrap pl-5 sm:pl-0'>{t("Managing Unexpected Challenges")}</p></div>
    <div className='flex flex-col justify-center'><img src={img6} className='pb-3 mx-auto'/>
    <p className='text-xs text-nowrap'>{t("Wedding Advisors")}</p></div>
    </div>
</div>

</div>
);

}
export default Consaltant;
