import React, {useEffect, useState} from "react";
import {Typography} from "@material-tailwind/react";
import {useTranslation} from "react-i18next";
import {Controller, useForm} from "react-hook-form";
import {defaultCountries, parseCountry, PhoneInput} from "react-international-phone";
import Loader from "../../../components/Loader";
import Select from "react-select";
import clsx from "clsx";
import axios from "axios";
import {toast} from "react-toastify";
import {Link, useNavigate } from "react-router-dom";
import placholder_image from "../../../assets/images/booking/placeholder-image.jpg"
import useAxiosUserSecureConfig from "../../../hooks/useAxiosUserSecureConfig";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FaEdit } from "react-icons/fa";

function SupplierEditAccount(){
    const [imageBase64, setImageBase64] = useState(null);

    const handleImageChange = (e) => {
      const selectedFile = e.target.files[0];
  
      if (selectedFile) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImageBase64(reader.result);
        };
        reader.readAsDataURL(selectedFile);
      }
    };

    const navigate = useNavigate();
   

    const {t,i18n} = useTranslation();

    const {
        register,
        watch,
        control,
        setError,
        setValue,
        formState: { errors },
        handleSubmit
    } = useForm({ defaultValues: {country_id: 1, product_ids: []} });


    const customStyles = {
        control: (styles) => ({
            ...styles,
            background: "#F2F4F8", "border-color": "#F2F4F8" , "border-radius" :"8px" ,padding: "6px" })
    }



    const menuStyle =
        "border rounded-md bg-white hover:cursor-pointer px-4 py-2";

    const optionStyles = {
        focus: "hover:cursor-pointer hover:text-red",
        Selected: "bg-[#F2F4F8]",
    };

    const baseStyle =
        " bg-[#F2F4F8]  rounded-lg self-stretch grow shrink bg- text-zinc-500 text-sm font-normal leading-tight h-12 px-[18px]  justify-start items-center gap-2 inline-flex";

    const [countries , setCountries] = useState({ loading: true, data: [] })
    const [states , setStates] = useState({ loading: false, data: [] })
    const [cities , setCities] = useState({ loading: false, data: [] })
    const [vendor, setVendor]=useState({ loading: true, data: [] })
    useEffect(() => {
        axios
          .post('/api/my-vendor-profile',{}, config)
          .then((res) => {
            console.log("vendor", res.data);
    
            if (!res.data?.result?.success) {
              toast.error("Error while fetching vendor data!");
            }
setImageBase64(res.data?.result?.vendor.image);
            setVendor({
              loading: false,
              data: res.data?.result.vendor || {}, // Set to an empty object if undefined
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }, []);

    useEffect(() => {
        axios
          .post("/api/country", {})
          .then((res) => {
                        if (!res.data?.result?.success) {
                toast.error("Error while fetching countries!");
                return;
            }

            setCountries((prevState) => ({
                ...prevState,
                loading: false,
                data: res.data?.result?.countries ?? [],
            }));
        })
          .catch((err) => {
            console.log(err);
        });
    }, []);

    const country = watch("country");
    const state = watch("state");

    useEffect(() => {
       
        if (!country) {
            setStates((prevState) => ({
                ...prevState,
                data: [],
            }));
            setCities((prevState) => ({
                ...prevState,
                data: [],
            }));
            return;
        }

        setStates((prevState) => ({
            ...prevState,
            loading: true,
        }));

        axios
          .post(`/api/country/${country.value}`, {})
          .then((res) => {
                        if (!res.data?.result?.success) {
                toast.error("Error while fetching states!");
                return;
            }

            setStates((prevState) => ({
                ...prevState,
                loading: false,
                data: res.data?.result?.countries ?? [],
            }));
        })
          .catch((err) => {
            console.log(err);
        });
    }, [country]);

    useEffect(() => {
       

        if (!state) {
            setCities((prevState) => ({
                ...prevState,
                data: [],
            }));
            return;
        }

        setCities((prevState) => ({
            ...prevState,
            loading: true,
        }));

        axios
          .post(`/api/state/${state.value}`, {})
          .then((res) => {
                        if (!res.data?.result?.success) {
                toast.error("Error while fetching cities!");
                return;
            }

            setCities((prevState) => ({
                ...prevState,
                loading: false,
                data: res.data?.result?.state.cities ?? [],
            }));
        })
          .catch((err) => {
            console.log(err);
        });
    }, [state]);

    const config = useAxiosUserSecureConfig(); 

    const onSubmit= (data)=>{
        console.log("data",data);
        data={
            name: data.name,
            brand_name:data.brand_name,
            vendor_type: data.vendor_type,
            company_type: data.company_type,
            country_code: "eg",
            country_id: data.country.value,
            state_id: data.state.value,
            city_id: data.city.value,
            address: data.address ,
            zip: data.zip,
            phone: data.phone,
            email: data.email,
            tax_id: data.tax_id,
            cr_no: data.cr_no,
            website: data.website,
            business_start_year:data.business_start_year.value,
            location: data.location ,                                                       
            image:imageBase64 || '',
            password: "qwerty",
          

           
        };
        axios.post('/api/update-vendor' ,data, config)
        .then((res) => {
          if (!res.data.result?.success) {
            toast.error(res.data.result?.msg || "Request failed!");
            return;
          }
          toast.success(t("You Edit Account successfully!"));
          navigate("/product-Supplier/Account")
          
        })
        .catch((err) => {
          console.log("err" , err)
          toast.error("Error while Editing Account!");
        })
    }


    const [phone, setPhone] = useState('');
    const countriies = defaultCountries.filter((country) => {
        const { iso2 } = parseCountry(country);
        return ['us', 'ua', 'gb','eg',].includes(iso2);
    })

    const companyTypeOptions = [
        { value: "company", label: t("Company") },
        { value: "person",  label: t("Individual") },
    ]
   const vendorTypeOptions =[
    { value: "venue", label: t("Venue") },
    { value: "supplier",  label: t("Product Supplier") },
    {value: "services", label: t("Service Provider")},
    {value: "consultant", label: t("Consultant")},
   ]
  
    const [selectedYear, setSelectedYear] = useState("");

    const handleYearChange = (event) => {
      setSelectedYear(event.target.value);
    };

    const years = Array.from(
      { length: 50 },
      (_, index) => new Date().getFullYear() - index
    );
    
    return(
<section className="px-5 xl:px-10 py-10">
{vendor.loading && <Loader slim />}
      {!vendor.loading && (
        <>
         <div className="justify-start items-center gap-2  hidden sm:inline-flex mb-10">
          <div
            onClick={() => navigate("/product-Supplier/Account")}
            className="text-zinc-400 text-base font-normal leading-[48px] hover:text-gray-dark cursor-pointer"
          >
            {t("Product Supplier Account")}
          </div>
          <FontAwesomeIcon
            className="text-zinc-400 text-base"
            icon={faAngleRight}
          />
          <div
            onClick={() => navigate("/ProductSupplier/edit-account")}
            className="text-zinc-400 text-base font-normal leading-[48px] hover:text-gray-dark cursor-pointer"
          >
            {t("Edit Account")}
          </div>
          </div>
    <div className="xl:px-10 flex gap-20 flex-wrap md:flex-nowrap">
   
      {/* <div className="flex flex-col justify-between items-center gap-6 w-full md:w-auto">
       <div className="flex flex-col items-center gap-6 w-full md:w-auto">
            <div className="flex flex-col gap-6 items-center">
              <img
                className="w-[205px] h-[205px] rounded-full border object-cover"
                src={imageBase64}
                alt=""
              />
            </div>
            <label htmlFor="uploadBtn">
              <button
                type="button"
                className="px-2 py-[6.40px] bg-white rounded-[10px] shadow border border-red justify-center items-center gap-2.5 inline-flex"
                onClick={() => document.getElementById("uploadBtn").click()}
              >
                <div className="py-1 justify-center items-center gap-2.5 flex">
                  <div className="text-center text-red text-base font-normal leading-normal">
                    Change photo
                  </div>
                 <FaEdit className="text-red font-normal"/>
                </div>
              </button>
              <input
                type="file"
                {...register("photo")} // assuming "photo" is the field name
                id="uploadBtn"
                hidden
                onChange={handleImageChange}
              />
            </label>
          </div> 
            
         </div> */}


        <div className="grow">
            <div className="w-4/5 mx-auto flex justify-between items-center mb-4">
                <p className="text-lg text-red md:text-2xl font-semibold leading-9">{t("Edit Account")}</p>
              
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div
                    className="w-4/5 mx-auto flex-col  md:grid grid-cols-2 justify-start items-start gap-4 inline-flex mb-10">
                    <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                    <label className="text-zinc-800 text-base font-normal leading-snug">
                       {t("Vendor name")} <span className="text-red-500 text-base font-normal leading-snug"> * </span>
                     </label>
                     <Controller
                       rules={{ required: t("Vendor name is required") }}
                       name="name"
                       control={control}
                       defaultValue={vendor.data.name}
                       render={({ field }) => (
                         <input
                           type="text"
                           {...field}
                           className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                         />
                       )}
                     />
                     {errors.name && (
                       <Typography variant="small" color="red">
                         {errors.name?.message}
                       </Typography>
                     )}
                    </div>
                    <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                        <label
                            className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">{t("Email")} <span className="text-red-500 text-base font-normal leading-snug"> * </span></label>
                       <Controller
                       rules={{ required: t("email is required") }}
                       name="email"
                       control={control}
                       defaultValue={vendor.data.email}
                       render={({ field }) => (
                         <input
                         readOnly
                           type="text"
                           {...field}
                           className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                         />
                       )}
                     />
                     {errors.email && (
                       <Typography variant="small" color="red">
                         {errors.email?.message}
                       </Typography>
                     )}
                    </div>

                    <div className="invitaion-verification self-stretch flex-col justify-start items-start gap-2 flex">
                        <label
                            className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug"> 
                            {t("Phone Number")} <span className="text-red-500 text-base font-normal leading-snug"> * </span></label>

                        <div className="w-full relative flex justify-between">

                            <Controller
                            className="w-full"
                            control={control}
                           defaultValue={vendor.data.phone}
                            name="phone"
                            rules={{ required: t("Phone Number is required") }}
                            render={({ field, field: { name, ref } }) => {
                                return (
                                <PhoneInput
                                    className=""
                                    error={errors.phone}
                                    defaultCountry="eg"
                                    inputStyle={{
                                    background: "rgb(242 244 248",
                                    width: "100%",
                                    margin: "0px 4px",
                                    border: "0px",
                                    padding: "0 14px",
                                    height: "46px",
                                    borderRadius: "8px",
                                    }}
                                    name={name}
                                    ref={ref}
                                    {...field}
                                />
                                );
                            }}
                            />

                        </div>
                        {errors.phone &&
                            <Typography variant="small" color="red">
                                {errors.phone?.message}
                            </Typography>
                        }
                    </div>
                

                    <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                        <label
                            className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">
                              {t("Brand name")} </label>
                            <Controller
                       
                       name="brand_name"
                       control={control}
                     defaultValue={vendor.data.brand_name}
                       render={({ field }) => (
                         <input
                           type="text"
                           {...field}
                           className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                         />
                       )}
                     />
                      
                    </div>
                    <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                        <label
                            className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">
                              {t("Company Type")} <span className="text-red-500 text-base font-normal leading-snug">
                            *</span></label>
                        <Controller
                            control={control}
                            name="company_type"
                            rules={{required: t('Comapny type is required')}}
                            defaultValue={vendor.data.company_type}
                            render={({field: {onChange, value, name, ref}}) => {
                                return <Select
                                    className="w-full"
                                    styles={customStyles}
                                    placeholder={t("Type")}
                                    name={name}
                                    ref={ref}
                                    onChange={(entry) => {
                                        onChange(entry.value)
                                    }}
                                    value={companyTypeOptions.find(entry => entry.value === value)}
                                    options={companyTypeOptions}

                                />
                            }}
                        />
                        {errors.company_type &&
                            <Typography variant="small" color="red">
                                {errors.company_type?.message}
                            </Typography>
                        }
                    </div>

                    <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                        <label
                            className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">
                              {t("Vendor Type")} <span className="text-red-500 text-base font-normal leading-snug">
                            * </span></label>
                        <Controller
                            control={control}
                            name="vendor_type"
                            rules={{required: t('vendor type is required')}}
                           defaultValue={"Product Supplier"} 
                            render={({ field }) => (
                              <input
                           type="text"
                           readOnly
                           {...field}
                           className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                         />
                            )}
                        />
                        {errors.vendor_type &&
                            <Typography variant="small" color="red">
                                {errors.vendor_type?.message}
                            </Typography>
                        }
                    </div>



                    <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                        <label
                            className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">
                              {t("Business website")} <span className="text-gray-400 text-xs font-light lead">{t("(optional)")}</span></label>
                            <Controller
                      
                       name="website"
                       control={control}
                       defaultValue={vendor.data.website}
                       render={({ field }) => (
                         <input
                           type="text"
                           {...field}
                           className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                         />
                       )}
                     />
                        
                    </div>


                    <div className="year-picker self-stretch flex-col justify-start items-start gap-2 flex">
                            <Controller
                         control={control}
                        name="business_start_year"
                       rules={{ required: t("year is required") }}
                       defaultValue={
                          vendor.data.business_start_year
                            ? {
                                value: vendor.data.business_start_year,
                                label: vendor.data.business_start_year,
                              }
                            : null
                        }
                       render={({ field, field: { name, ref } }) => (
                        <>
                            <label className="text-zinc-800 text-base font-normal leading-snug">
                           {t("The year your business starts")} <span className="text-red-500 text-base font-normal leading-snug">
                            *
                          </span></label>
                            <Select
                              styles={customStyles}
                        name={name}
                        ref={ref}
                         isClearable
                              isSearchable
                              options={years.map((year) => ({
                                value: year,
                                label: year,
                              }))}
                              {...field}
                              className="w-full"
                              value={field.value}
                              onChange={(selectedOption) => {
                                field.onChange(selectedOption);
                              }}
                            />
                   {errors.business_start_year && (
                            <Typography variant="small" color="red">
                                {errors.business_start_year?.message}
                            </Typography>
                            )}
                          </>
                        )}
                      />
                       

                    </div>


            
                {countries.loading && <Loader slim />}
                  {!countries.loading && (
                    <div className="flex flex-col justify-start items-start gap-2">
                      <Controller
                        control={control}
                        name="country"
                        rules={{ required: "Country is required" }}
                        defaultValue={
                          vendor.data.country.id
                            ? {
                                value: vendor.data.country.id,
                                label: vendor.data.country.name,
                              }
                            : null
                        }
                        render={({ field, field: { name, ref } }) => (
                          <>
                            <label className="text-zinc-800 text-base font-normal leading-snug">
                              {t("Country")} <span className="text-red-500 text-base font-normal leading-snug">
                            *
                          </span>
                            </label>
                            <Select
                              styles={customStyles}
                              name={name}
                              ref={ref}
                              isClearable
                              isSearchable
                              options={countries.data.map((country) => ({
                                value: country.id,
                                label: country.name,
                              }))}
                              {...field}
                              className="w-full"
                              value={field.value}
                              onChange={(selectedOption) => {
                                field.onChange(selectedOption);
                              }}
                            />
                            {errors.country && (
                              <Typography variant="small" color="red">
                                {errors.country?.message}
                              </Typography>
                            )}
                          </>
                        )}
                      />
                    </div>
                  )}
                   
                    <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                        <label className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">{t("State")} <span className="text-red-500 text-base font-normal leading-snug">
                            *
                          </span></label>
                        {states.loading && <Loader slim/>}
                        {!states.loading && (
                            <Controller
                                control={control}
                                name="state"
                                rules={{required: 'State is required'}}
                                defaultValue={
                                  vendor.data.state.id
                                    ? {
                                        value: vendor.data.state.id,
                                        label: vendor.data.state.name,
                                      }
                                    : null
                                }
                                render={({field, field: {name, ref}}) => {
                                    return (
                                      <Select
                                      styles={customStyles}
                                      name={name}
                                      ref={ref}
                                      isClearable
                                      isSearchable
                                      options={states.data.map((state) => ({
                                        value: state.id,
                                        label: state.name,
                                      }))}
                                      {...field}
                                      className="w-full"
                                      value={field.value}
                                      onChange={(selectedOption) => {
                                        field.onChange(selectedOption);
                                      }}
                                    />
                                    );
                                }}
                            />
                        )}
                        <Typography variant="small" color="red">{errors.state?.message || ""}</Typography>
                    </div>

                    <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                        <label className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">{t("City")} <span className="text-red-500 text-base font-normal leading-snug">
                            *
                          </span></label>
                        {cities.loading && <Loader slim/>}
                        {!cities.loading && (
                            <Controller className='w-full'
                                        control={control}
                                        name="city"
                                        rules={{required: 'City is required'}}
                                        defaultValue={
                                          vendor.data.city.id
                                            ? {
                                                value: vendor.data.city.id,
                                                label: vendor.data.city.name,
                                              }
                                            : null
                                        }
                                        render={({field, field: {name, ref}}) => {
                                            return (
                                                <Select
                                                    className='w-full'
                                                    placeholder="City"
                                                    classNames={{
                                                        input: () => "[&_input:focus]:ring-0",
                                                        menu: () => menuStyle,
                                                        option: ({isFocused, isSelected}) =>
                                                            clsx(
                                                                isFocused && optionStyles.focus,
                                                                isSelected && optionStyles.Selected
                                                            ),
                                                        control: () => baseStyle,
                                                    }}
                                                    styles={customStyles}
                                                    isClearable
                                                    isSearchable
                                                    options={cities.data.map((city) => ({
                                                        value: city.id,
                                                        label: city.name,
                                                    }))}
                                                    name={name}
                                                    ref={ref}
                                                    {...field}
                                                />
                                            );
                                        }}
                            />
                        )}
                        <Typography variant="small" color="red">{errors.city?.message || ""}</Typography>
                    </div>

                    <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                        <label
                            className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">
                              {t("Location on map")} <span className="text-gray-400 text-xs font-light lead">{t("(optional)")}</span></label>
                       <Controller
                       
                       name="location"
                       control={control}
                       defaultValue={vendor.data.location}
                       render={({ field }) => (
                         <input
                           type="text"
                           {...field}
                           className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                         />
                       )}
                     />
                        
                    </div>
                    <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                        <label
                            className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">
                            {t("Address")} <span className="text-gray-400 text-xs font-light lead">{t("(optional)")}</span></label>
                       <Controller
                     
                       name="address"
                       control={control}
                       defaultValue={vendor.data.address}
                       render={({ field }) => (
                         <input
                           type="text"
                           {...field}
                           className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                         />
                       )}
                     />
                       
                    </div>

                    <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                        <label
                            className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">
                              {t("Zip Code")} <span className="text-gray-400 text-xs font-light lead">{t("(optional)")}</span></label>
                       <Controller
                       name="zip"
                       control={control}
                       defaultValue={vendor.data.zip}
                       render={({ field }) => (
                         <input
                           type="text"
                           {...field}
                           className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                         />
                       )}
                     />
                    </div>


                    

                   

                    <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                        <label
                            className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">
                              {t("Commercial Record")} <span className="text-gray-400 text-xs font-light lead">{t("(optional)")}</span></label>
                       <Controller
                       name="cr_no"
                       control={control}
                       defaultValue={vendor.data.cr_no}
                       render={({ field }) => (
                         <input
                           type="text"
                           {...field}
                           className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                         />
                       )}
                     />
                       
                    </div>

                    <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                        <label
                            className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">
                              {t("Tax ID")} <span className="text-gray-400 text-xs font-light lead">{t("(optional)")}</span></label>
                      <Controller
                       name="tax_id"
                       control={control}
                       defaultValue={vendor.data.tax_id}
                       render={({ field }) => (
                         <input
                           type="text"
                           {...field}
                           className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                         />
                       )}
                     />
                        
                    </div>

                  
                   
                </div>

                <div className="flex justify-end w-full">
                    <button type="submit"
                            className="w-fit px-16 py-3 bg-red rounded-[10px] shadow border border-white justify-center items-center gap-2.5 inline-flex">
                        <div className="text-center text-white text-base font-normal leading-normal">{t("Save")}</div>
                    </button>
                </div>

            </form>
        </div>
    </div>
    </>
    )}
   
</section>
    )


}

export default SupplierEditAccount;