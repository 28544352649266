import React from 'react';
import LoaderOverlay from "../../components/LoaderOverlay";

/**
 * The theme components only imports its theme CSS-file. These components are lazy
 * loaded, to enable "code splitting" (in order to avoid the themes being bundled together)
 */
const DefaultStyle = React.lazy(() => import('./DefaultStyle'));
const DefaultStyleRTL = React.lazy(() => import('./DefaultStyleRTL'));

const StyleSelector: React.FC = ({ language, children }) => (
    <>
        {/* Conditionally render theme, based on the current client context */}
        <React.Suspense fallback={<LoaderOverlay />}>
            {language !== "ar" && <DefaultStyle />}
            {language === "ar" && <DefaultStyleRTL />}

            { children }
        </React.Suspense>
    </>
);

export default StyleSelector;