import React, { useEffect, useRef, useState } from "react";
import invitationImg from "../../../assets/images/invitation/invitation.gif";
import invitationHeader from "../../../assets/images/invitation/invitationHeader.png";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import Layer1 from "../../../assets/images/About/Layer_1.png"
import vector3 from "../../../assets/images/About/Vector 2.png"
import vector2 from "../../../assets/images/About/Vector3.png" 
import heart from "../../../assets/images/invitation/mdi_heart.png"
import fram1 from "../../../assets/images/invitation/OBJECTS (5).png"
import fram2 from "../../../assets/images/invitation/OBJECTS (6).png"
import object1 from "../../../assets/images/invitation/object (1).png"
import object2 from "../../../assets/images/invitation/object (2).png"
import object3 from "../../../assets/images/invitation/object (3).png"
import object4 from "../../../assets/images/invitation/object (4).png"
import object5 from "../../../assets/images/invitation/object (5).png"
import object7 from "../../../assets/images/invitation/OBJECTS (7).png"
import object8 from "../../../assets/images/invitation/OBJECT.png"
import side1 from "../../../assets/images/invitation/Layer_1.png"
import side2 from "../../../assets/images/invitation/Layer_2.png"
import sideObj from "../../../assets/images/invitation/object (5).png"
import sideObj2 from "../../../assets/images/invitation/OBJECT (9).png"
import viewImg from "../../../assets/images/invitation/view.png"
import axios from "axios";
import useAxiosUserSecureConfig from "../../../hooks/useAxiosUserSecureConfig";
import Loader from "../../../components/Loader";
import classic from "../../../assets/images/invitation/invitation1.jpeg";
import modern from "../../../assets/images/invitation/invitation2.jpeg";
import islamic from "../../../assets/images/invitation/invitation3.jpeg";
import old from "../../../assets/images/invitation/invitation4.jpeg";
import dark from "../../../assets/images/invitation/invitation5.jpeg";
import newStyle from "../../../assets/images/invitation/invitation6.jpeg";
import placeholder from "../../../assets/images/invitation/placeholder-image.jpg";
import groomPhoto from "../../../assets/images/invitation/groom.jpg";
import bridePhoto from "../../../assets/images/invitation/bride.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faL, faPlus } from "@fortawesome/free-solid-svg-icons";
import {SlideshowLightbox} from 'lightbox.js-react'
import 'lightbox.js-react/dist/index.css';
import icon1 from "../../../assets/images/invitation/object (2).png";
import icon2 from "../../../assets/images/invitation/object (3).png";
import icon3 from "../../../assets/images/invitation/object (4).png";
import editIcon from "../../../assets/images/icons/edit-02.png";
import copyIcon from "../../../assets/images/icons/copy-outline.png";
import personIcon from "../../../assets/images/icons/person-add-outline.png";
import gusetsIcon from "../../../assets/images/icons/people-outline.png";
import {useTranslation} from "react-i18next";

const photo= 
  { src: placeholder}
;

function InvitationView() {
  
  const handleCopyLink = () => {
    // Copy the URL to the clipboard
    navigator.clipboard
      .writeText(`https://rose-bazaar.com/invitation/verification/${params.id}`)
      .then(() => {
        toast.success(t("URL copied to clipboard!"));
        localStorage.setItem('invitationVerificationLink', `https://rose-bazaar.com/invitation/verification/${params.id}`);
      })
      .catch((error) => {
        console.error("Failed to copy URL:", error);
      });
  };
  const {t ,i18n } = useTranslation();
  const navigate = useNavigate();
  const config = useAxiosUserSecureConfig();
  const params = useParams();
  const [invitation, setInvitation] = useState({loading: true,data: {},});
  const fetchData = () => {
    axios
      .post(`/api/invitation/${params.id}`, {}, config)
      .then((res) => {
        console.log("invitation", res.data);
        if (!res.data?.result?.success) {
          toast.error("Error while fetching invitation!");
        }
        setInvitation({
          loading: false,
          data: res.data?.result.invitation || {},
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    fetchData();
  }, []);
  const [selectedInvitation, setSelectedInvitation] = useState(null);
  const [invitaionLoading , setInvitaionLoading] = useState(false)
  useEffect(() => {
    if (invitation.data) {
        setInvitaionLoading(true);
        const headers = {
            ...config.headers,
            'Content-Type': 'text/plain',
        };
        const data = {
            groom_name: invitation.data.groom_name,
            bride_name: invitation.data.bride_name,
            wedding_address: invitation.data.wedding_address,
            wedding_date: invitation.data.wedding_date,
            wedding_time: invitation.data.wedding_time,
            style: invitation.data.style,
        };
        axios.post('/api/invitation/render', data, { ...config, headers, responseType: 'blob' })
            .then((res) => {
                if (!res.data) {
                    setInvitaionLoading(false);
                    toast.error(res.data.result?.message || t("Request failed!"));
                    return;
                }
                const reader = new FileReader();
                reader.onload = () => {
                    setSelectedInvitation(reader.result);
                };
                reader.onerror = (error) => {
                    console.error("Error reading image:", error);
                };
                reader.readAsDataURL(res.data);
                setInvitaionLoading(false);
            })
            .catch((err) => {
                setInvitaionLoading(false);
                console.log("Error fetching image:", err);
            });
    }
}, [invitation.data]);

  console.log("inv: ", invitation )
  const imagesArray = Array.isArray(invitation.data.images) ? invitation.data.images : [];
  const combinedPhotos = imagesArray.map(item => ({ src: item.image, alt: `Image ${item.id}` }));

  console.log("combinedPhotos" , combinedPhotos)
  const [isHovered, setIsHovered] = useState(false);
  const timeoutRef = useRef(null);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const addStoryPhoto = (e, story) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64Image = reader.result;
        console.log('Base64 image:', base64Image);
        setInvitation((prevState) => ({
          ...prevState,
          loading: true,
        }));
        const data = {
          story_id: story.id,
          date: story.date,
          about_story: story.about_story,
          photo: base64Image || story.photo,
        };
        axios
      .post("/api/invitation/update-story", data, config)
      .then((res) => {
        if (!res.data.result?.success) {
          console.log(res.data);
          toast.error(res.data.result?.msg || t("Request failed!"));
          return;
        }
        setInvitation((prevState) => ({
          ...prevState,
          loading: false,
        }));
        toast.success(t("You add image successfully!"));
        fetchData()
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(t("Error while adding the photo!"));
        setInvitation((prevState) => ({
          ...prevState,
          loading: false,
        }));
      });
        console.log('Data:', data);
      };
      reader.onerror = (error) => {
        console.error('Error reading the file:', error);
      };
    }
  };

  const [replyTexts, setReplyTexts] = useState(Array(invitation.data?.guests?.length).fill(''));

  const handleReply = (index ,id) => {
    const data = {
      response: replyTexts[index],
    };
    console.log()
    axios
      .post(`/api/user/invitation/${params.id}/guest/${id}/reply`, data, config)
      .then((res) => {
        if (!res.data.result?.success) {
          toast.error(res.data.result?.message || t("Request failed!"));
          return;
        }
        toast.success(t("You add reply successfully!"));
        fetchData()
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(t("Error while adding reply!"));
      });
  };

  const imagesIcons = [icon1 , icon2 , icon3]

  return (
    <section>
      <div className="relative ">
        <img
          src={side1}
          className="hidden sm:block absolute left-0 top-[1000px] "
        />
        <img
          src={side2}
          className="hidden sm:block absolute right-0 top-[1600px]  w-1/6 "
        />
        <img
          src={sideObj}
          className="hidden sm:block absolute right-0 top-[800px]"
        />
        <img
          src={sideObj2}
          className="hidden sm:block absolute left-0 top-[1800px]"
        />

        <img
          className="w-full h-20 sm:h-auto "
          src={invitationHeader}
          alt="invitationHeader"
        />
        <div className="absolute bottom-[10%] lg:p-10 px-2 w-full flex items-center justify-between">
          <p className="md:text-center  text-[#fff7eb] text-[15px] lg:text-[40px] font-medium">
            {t("Wedding Invitation")}
          </p>
          <img
            src={Layer1}
            className="absolute left-[35%] top-[79%] md:top-[73%] md:left-[40%] w-2/6 md:w-1/5  "
          />
        </div>
      </div>
      {invitation.loading && <Loader slim />}
      {!invitation.loading && (
        <div className="px-[60px] py-10 bg-zinc-100 flex justify-center">
          <div className="flex-col gap-14 inline-flex w-full">
            
            <div className="flex-col justify-start items-center gap-6 inline-flex w-full relative">
            <div className="xl:absolute end-0 top-0">
              <div className="gap-1 md:gap-3 grid grid-cols-2 border border-red p-2 rounded-lg">
                <button
                  onClick={() => navigate(`/invitation/edit/${params.id}`)}
                  className="lg:px-5 lg:py-2 py-1 gap-1 bg-white rounded-lg justify-center items-center flex"
                >
                  <img src={editIcon} alt="editIcon" className="w-4" />
                  <span className="text-red text-xs md:text-base font-medium leading-none tracking-wide">
                    {t("Edit")}
                  </span>
                </button>
                <button
                  onClick={handleCopyLink}
                  className="lg:px-5 lg:py-2 py-1 gap-1 bg-red rounded-lg justify-center items-center flex"
                >
                  <img src={copyIcon} alt="copyIcon" className="w-4" />
                  <span className="text-orange-50 text-xs md:text-base font-medium leading-none tracking-wide">
                    {t("Copy link")}
                  </span>
                </button>
                <button
                  onClick={() =>
                    navigate(`/invitation/add-guests/${params.id}`)
                  }
                  className="lg:px-5 lg:py-2 py-1 gap-1 bg-white rounded-lg justify-center items-center flex"
                >
                  <img src={personIcon} alt="personIcon" className="w-4" />
                  <span className="text-red text-xs md:text-base font-medium leading-none tracking-wide">
                    {t("Add Guest")}
                  </span>
                </button>
                <button
                  onClick={() =>
                    navigate(`/invitation/guests-status/${params.id}`)
                  }
                  className="lg:px-5 lg:py-2 py-1 gap-1 bg-white rounded-lg justify-center items-center flex"
                >
                  <img src={gusetsIcon} alt="gusetsIcon" className="w-4" />
                  <span className="text-red text-[10px] md:text-base font-medium leading-none tracking-wide">
                    {t("Guest status")}
                  </span>
                </button>
              </div>
            </div>
              <div
                className={`flex justify-center ${
                  i18n.language === "en" ? "flex-row" : "flex-row-reverse"
                }`}
              >
                <img src={vector2} className="w-14 md:w-24 object-contain" />
                <p className="text-red px-3 text-base md:text-2xl lg:text-3xl">
                  {t("Invitation Card")}
                </p>
                <img src={vector3} className="w-14 md:w-24 object-contain" />
              </div>
              {invitaionLoading && <Loader slim />}
              {!invitaionLoading && selectedInvitation && (
                <div className=" w-full flex justify-center items-center">
                  <img
                    src={selectedInvitation}
                    alt="galleryIcon"
                    className="w-full md:w-5/12 mx-auto"
                  />
                </div>
              )}
              <img
                src={object8}
                className="w-[222px] mx-auto"
                alt="invitationImg"
              />
            </div>

            <div className="flex-col justify-start items-center gap-6 inline-flex w-full">
              <div
                className={`flex justify-center ${
                  i18n.language === "en" ? "flex-row" : "flex-row-reverse"
                }`}
              >
                <img src={vector2} className="w-14 md:w-24 object-contain" />
                <p className="text-red px-3 text-base md:text-2xl lg:text-3xl">
                  {t("Our profile")}
                </p>
                <img src={vector3} className="w-14 md:w-24 object-contain" />
              </div>

              <div className="self-stretch justify-center items-start  gap-1 md:gap-5 inline-flex">
                <div className="grow shrink-0 basis-0 self-stretch md:px-4 py-6  flex flex-col lg:flex-row-reverse justify-start items-center gap-4 ">
                  <div>
                    <div className="relative w-[120px] h-[120px] sm:w-[148px] sm:h-[148px]">
                      <img
                        src={invitation.data.bride_photo || bridePhoto}
                        alt="bride_photo"
                        className="w-full h-full rounded-full object-cover"
                      />
                      <img
                        className="rounded-full absolute top-0 right-1 w-full h-full"
                        src={fram2}
                        alt="InvitationView"
                      />
                    </div>
                  </div>
                  <div>
                    <div
                      className={` ${
                        i18n.language === "en" ? "text-right" : "text-left"
                      } text-zinc-800 text-2xl font-semibold leading-relaxed`}
                    >
                      {invitation.data.bride_name}
                    </div>
                    <div
                      className={`${
                        i18n.language === "en" ? "text-right" : "text-left"
                      } self-stretch text-wrap w-full text-gray-500 text-xl font-normal leading-7`}
                    >
                      {invitation.data.about_bride}
                    </div>
                  </div>
                </div>
                <img src={heart} className="mt-20" />
                <div className="grow shrink basis-0 self-stretch md:px-4 py-6 flex flex-col lg:flex-row justify-start items-center gap-4 ">
                  <div>
                    <div className="relative w-[120px] h-[120px] sm:w-[148px] sm:h-[148px]">
                      <img
                        src={invitation.data.groom_photo || groomPhoto}
                        alt="groom_photo"
                        className="w-full h-full rounded-full object-cover"
                      />
                      <img
                        className="rounded-full absolute top-0 right-1 w-full h-full"
                        src={fram2}
                        alt="InvitationView"
                      />
                    </div>
                  </div>
                  <div>
                    <div
                      className={` ${
                        i18n.language === "en" ? "text-left" : "text-right"
                      } text-zinc-800 text-2xl font-semibold leading-relaxed`}
                    >
                      {invitation.data.groom_name}
                    </div>
                    <div
                      className={`${
                        i18n.language === "en" ? "text-left" : "text-right"
                      }self-stretch text-wrap w-full text-gray-500 text-xl font-normal leading-7`}
                    >
                      {invitation.data.about_groom}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-col justify-start items-center gap-6 inline-flex w-full">
              {combinedPhotos.length > 0 && (
                <>
                  <div
                    className={`flex justify-center ${
                      i18n.language === "en" ? "flex-row" : "flex-row-reverse"
                    }`}
                  >
                    <img
                      src={vector2}
                      className="w-14 md:w-24 object-contain"
                    />
                    <p className="text-red px-3  text-base md:text-2xl lg:text-3xl">
                      {t("Our Album")}
                    </p>
                    <img
                      src={vector3}
                      className="w-14 md:w-24 object-contain"
                    />
                  </div>
                  <div className="w-3/4 mx-auto">
                    {combinedPhotos.length !== 5 && (
                      <SlideshowLightbox
                        className={`slideshowLightbox container grid ${
                          combinedPhotos.length === 1
                            ? "grid-cols-1"
                            : combinedPhotos.length === 2
                            ? "grid-cols-2"
                            : combinedPhotos.length === 3
                            ? "grid-cols-3"
                            : combinedPhotos.length === 4
                            ? "grid-cols-2"
                            : ""
                        } gap-2 mx-auto album-gallery`}
                        images={combinedPhotos}
                        showThumbnails={true}
                        renderImage={(idx, image, toggleLightbox) => (
                          <div
                            className="grid-item"
                            onClick={() => toggleLightbox(idx)}
                          >
                            <img
                              className="rounded-xl object-cover w-full h-full"
                              src={image.src}
                              alt={image.alt}
                            />
                          </div>
                        )}
                      />
                    )}
                  </div>
                  <div className="invitaionGallery w-3/4 mx-auto">
                    {combinedPhotos.length === 5 && (
                      <SlideshowLightbox
                        images={combinedPhotos}
                        showThumbnails={true}
                        renderImage={(idx, image, toggleLightbox) => (
                          <div
                            className="grid-item"
                            onClick={() => toggleLightbox(idx)}
                          >
                            <img
                              className="rounded-xl object-cover w-full h-full"
                              src={image.src}
                              alt={image.alt}
                            />
                          </div>
                        )}
                      />
                    )}
                  </div>
                </>
              )}

              <div
                className={`flex justify-center ${
                  i18n.language === "en" ? "flex-row" : "flex-row-reverse"
                }`}
              >
                <img src={vector2} className="w-14 md:w-24 object-contain" />
                <p className="text-red px-3 text-base md:text-2xl lg:text-3xl">
                  {t("Our Story")}
                </p>
                <img src={vector3} className="w-14 md:w-24 object-contain" />
              </div>
              <div className="flex flex-col justify-center items-center w-[95%] mx-auto">
                {invitation.data.story.map((story, index) => (
                  <div key={index} className="flex items-start">
                    {index % 2 === 0 ? (
                      <div className="items-end relative border-e-[3px] border-[#f1cfcf] flex flex-col md:px-8 px-2 w-1/2 h-full pt-2 pb-28 gap-2">
                        <div className="absolute top-0 -end-[9.5px] w-[17px] h-[17px] bg-[#f1cfcf] rounded-full"></div>
                        <h4 className="text-red font-[Rochester]">
                          {story.date}
                        </h4>
                        <p>{story.about_story}</p>
                      </div>
                    ) : (
                      <div className="relative  flex justify-end px-8 w-1/2 h-full">
                        <div className="absolute top-0 -end-[9.5px] w-[17px] h-[17px] bg-[#f1cfcf] rounded-full"></div>
                        <div className="relative">
                          <img
                            src={imagesIcons[index % imagesIcons.length]}
                            className="w-full cursor-pointer object-cover rounded-3xl"
                            alt="YourImage"
                          />
                        </div>
                      </div>
                    )}
                    {index % 2 === 0 ? (
                      <div className="flex justify-start px-8 w-1/2">
                        <div className="relative">
                          <img
                            src={imagesIcons[index % imagesIcons.length]}
                            className="w-full cursor-pointer object-cover rounded-3xl"
                            alt="YourImage"
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="flex flex-col border-s-[3px] border-[#f1cfcf] md:px-8 px-2 w-1/2 h-full pt-6 pb-28 gap-2">
                        <h4 className="text-red font-[Rochester]">
                          {story.date}
                        </h4>
                        <p>{story.about_story}</p>
                      </div>
                    )}
                  </div>
                ))}
              </div>

              <div className="flex flex-col justify-center items-start gap-20 ">
                <img src={invitationImg} alt="invitationImg" />
                <img
                  src={object8}
                  className="w-[222px] mx-auto"
                  alt="invitationImg"
                />
              </div>
              <div
                className={`flex justify-center ${
                  i18n.language === "en" ? "flex-row" : "flex-row-reverse"
                }`}
              >
                <img src={vector2} className="w-14 md:w-24 object-contain" />
                <p className="text-red px-3 text-base md:text-2xl lg:text-3xl">
                  {t("Comments")}
                  <span className="text-blue-gray-700 text-sm px-2 pb-1 align-middle ">
                    (
                    {
                      invitation.data.guests.filter(
                        (guest) => guest.comment !== false
                      ).length
                    }
                    )
                  </span>
                </p>
                <img src={vector3} className="w-14 md:w-24 object-contain" />
              </div>
              <div className="w-[95%] flex justify-start">
                <div className="flex justify-start text-red text-xl border-b-2 border-b-red p-3">
                  {t("New")}
                  <span className="text-white text-xs px-2 pt-1 mx-1 bg-gray-400 border rounded-full align-middle ">
                    {
                      invitation.data.guests.filter(
                        (guest) => guest.comment !== false
                      ).length
                    }
                  </span>{" "}
                </div>
              </div>
              <div className="flex flex-col gap-5 w-full">
                {invitation.data.guests
                  .filter((guest) => guest.comment !== false)
                  .map((guest, index) => (
                    <div
                      key={index}
                      className="w-[95%] flex flex-col border rounded-xl bg-white justify-start"
                    >
                      <div className="border-b py-4">
                        <h1 className="text-gray-500 px-5">{guest.name}</h1>
                        <p className="text-gray-dark text-lg px-5">
                          {guest.comment}
                        </p>
                        {guest.user_response && (
                          <p className="mt-5 pt-2 px-5 border-t w-full text-gray-400">
                            {guest.user_response}
                          </p>
                        )}
                        {!guest.user_response && (
                          <div className="flex justify-between border relative rounded-xl">
                            <input
                              className="py-2 w-full px-5 bg-gray-200 border border-gray-200 rounded-xl text-gray-500"
                              type="text"
                              placeholder="Text reply here!"
                              value={replyTexts[index]} // Use replyTexts[index] for each guest
                              onChange={(e) => {
                                const updatedReplyTexts = [...replyTexts];
                                updatedReplyTexts[index] = e.target.value;
                                setReplyTexts(updatedReplyTexts);
                              }}
                            />
                            <button
                              type="button"
                              className="text-red border border-red absolute right-0 rounded-xl px-4 py-2"
                              onClick={() => handleReply(index, guest.id)}
                            >
                              {t("Reply")}
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
}

export default InvitationView;
