import React, {useEffect, useRef, useState} from 'react';
import QRCode from "react-qr-code";
import Loader from "../../../components/Loader";
import {useDispatch} from "react-redux";
import {initiatePartner} from "../../../store/dispatches/partner";
import {toast} from "react-toastify";
import { Typography} from "@material-tailwind/react";
import useAxiosAuthorizationConfig from "../../../hooks/useAxiosUserSecureConfig";
import {useNavigate} from "react-router-dom";
import { Card } from 'flowbite-react';

function UserPartnerNew(props) {
  const [code, setCode] = useState(null);
  const dispatch = useDispatch();
  const config = useAxiosAuthorizationConfig();
  const navigate = useNavigate();

  const effect = useRef(false);

  useEffect(() => {
    if (!effect.current) {
      initiatePartner(dispatch, config)
          .then(value => {
            if ( value ) {
              if (value.result?.success === false) {
                toast.warn(value.result?.msg || "Unknown error")
                navigate("/");
                return;
              }

              toast.success("Success in initiating. Please scan or copy the code")
              setCode(value.result?.code);
            } else if (value === false ) {
              toast.error("Invalid response from server")
            }
          })
      ;
    }

    return () => effect.current = true;
  }, []);

  return (
      <div className='text-center'>
        <Card className='text-center m-auto border-rose-10'>
        { code == null && <Loader /> }
        { code != null && <QRCode className='w-auto m-auto mb-3' value={code} /> }
        { code != null && <Typography variant={"h1"}>{ code }</Typography> }
        </Card>
      </div>
  );
}

export default UserPartnerNew;