import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {useTranslation} from "react-i18next";
import {AiFillStar , AiOutlineShoppingCart ,AiOutlineHeart} from 'react-icons/ai';
import { Card , Button  } from '@material-tailwind/react';
import { TextInput } from 'flowbite-react';
import { AiOutlineSearch } from 'react-icons/ai';
import {Spinner} from "flowbite-react";
import { useDispatch} from "react-redux";
// import { addToFavourits } from '../../store/slicer/wishListSlice';
import { useNavigate} from "react-router-dom";
import {cartActions} from "../../store/slicer/cartSlice";


function Products({result}) {
  console.log(result)

  const { t } = useTranslation();

  const [searchItems ,setSearchItems ] =useState("")
  // const [productsData , setProducts] = useState({
  //   loading : false,
  //   data : result,
  // })

  // useEffect(() => {
  //   axios.get("https://dummyjson.com/products")
  //   .then(res=> res.data)
  //   .then((data) => {
  //     setProducts(()=>({
  //       loading: false,
  //       data :data.products
  //     }))
  //   }).catch(err => console.log(err))
  // })
  // // console.log(products)

  const dispatch = useDispatch();

  const handleAddToCart = (e, product ) => {
    //TODO:
    e.stopPropagation();
  };
  // const handleAddToFavourits = (e,product) => {
  //   dispatch(addToFavourits(product));
  //   e.stopPropagation();
  // };

  const navigate = useNavigate();
  return (
      <div>
        <TextInput onChange={(event => {setSearchItems(event.target.value)})}
         id="search" type="text" icon={AiOutlineSearch} placeholder="Search here" className="mb-8 w-2/3" />
        <div className="grid lg:grid-cols-4 gap-6">
          {result.filter(product => {
            if(searchItems === ""){
              return product;
            }
            else if(product.title.toLowerCase().includes(searchItems.toLowerCase())){
              return product;
            }
          })
          .map(product => 
            <Card key={product.title}
            onClick={()=> navigate("/product-details")}
            className="overflow-hidden border h-[300px] cursor-pointer group dark:bg-dark-100
            ">
            <div className="relative overflow-hidden h-2/3 flex justify-center items-center">
              <img className="w-[200px] group-hover:scale-110 transition-[0.3s] ease-linear " src={product.img}  alt=""/>
              <div className="absolute -bottom-[50px] w-full flex justify-center gap-2 transition-[0.3s] ease-in-out invisible group-hover:visible group-hover:bottom-2">
                <Button
                 size="small" className="bg-white text-rose text-xl hover:bg-rose hover:text-white">
                  <AiOutlineHeart/>
                </Button>
                <Button onClick={(e) => handleAddToCart(e, product)}
                 size="small" className="bg-white text-rose text-xl hover:bg-rose hover:text-white">
                  <AiOutlineShoppingCart/>
                </Button>
              </div>
            </div>
            <div className="px-3 py-4">
              <h5 className="text-base font-bold tracking-tight text-gray-900 dark:text-white mb-2">
                {product.title}
              </h5>
              <div className="flex justify-between">
                <p className="text-sm  text-gray-500 dark:text-gray-400 flex items-center gap-2">
                        <AiFillStar size={"16px"} color="#fabb00" />
                        {product.rating} {product.reviews}
                </p>
                <p className="text-rose text-sm font-bold dark:text-gray-400 flex items-center gap-2">
                        
                          {product.newPrice} EGP
                </p>
              </div>
            </div>
          </Card> ) 
          } 
        </div>
      </div>
  );
}

export default Products;
