import React, { useEffect, useState } from 'react'
import invitationHeader from "../../../assets/images/invitation/invitationHeader.png";
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useTable, useSortBy, usePagination } from 'react-table';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import useAxiosUserSecureConfig from '../../../hooks/useAxiosUserSecureConfig';
import { toast } from 'react-toastify';
import Loader from '../../../components/Loader';
import { PhoneInput } from 'react-international-phone';
import { Typography } from '@material-tailwind/react';
import {useTranslation} from "react-i18next";



function AddGuests() {
  const {t ,i18n } = useTranslation();
  const columns = React.useMemo(
    () => [
      { Header: t('Guest name'), accessor: 'name' },
      { Header: t('Guest mobile number'), accessor: 'phone' },
      { Header: t('Classified'), accessor: 'classified' },
      { Header: t('Status'), accessor: 'state' }
    ],
    [t]
  );

  
const params = useParams();
const navigate = useNavigate();
const [guestsList, setGuestsList] = useState([]);
const [guests, setGuests] = useState({ loading: true, data: [] });

const fetchData = () => {
  axios.post(`/api/invitation/${params.id}/guests`, {}, config)
    .then((res) => {
      console.log("guests", res.data);
      if (!res.data?.result?.success) {
        toast.error(t("Error while fetching guests!"));
        return;
      }
      setGuests({
        loading: false,
        data: res.data?.result?.guests?.slice().reverse() || [],
      });
    })
    .catch((err) => {
      console.log(err);
    });
}
  useEffect(() => {
    fetchData();
  }, []);

 
  const {
    rows,
  } = useTable(
    {
      columns,
      data: guests.data,
      initialState: { pageIndex: 0, pageSize:  4 }, 
    },
    useSortBy,
    usePagination
  );
  const {
    register,
    control,
    handleSubmit,
    reset,
    triggerValidation,
    formState: { errors },
} = useForm();
const config = useAxiosUserSecureConfig();
const onSubmit = (data) => {
  
  console.log("Data:", data);
  setGuests((prevState) => ({
    ...prevState,
    loading: true,
  }));
  reset({ guestName: "", phone: "", classified: "" });
  const requestData = {
    invitation_id: params.id,
    name: data.guestName,
    phone: data.phone,
    classified: data.classified.value
 }
 console.log("Data:", requestData);
  axios
    .post("/api/invitation/add-guest", requestData, config)
    .then((res) => {
      if (!res.data.result?.success) {
        console.log(res.data)
        toast.error(res.data.result?.message || t("Request failed!"));
        return;
      }
      setGuests((prevState) => ({
        ...prevState,
        loading: false,
      }));
      toast.success(t("You add guest successfully!"));
      fetchData()
    })
    .catch((err) => {
      console.log("err", err);
      toast.error(t("Error while adding guest!"));
      setGuests((prevState) => ({
        ...prevState,
        loading: false,
      }));
    });
};
const isGuestNumberExists = (guestNumber) => {
  const exists = guests.data.some(guest => guest.phone === guestNumber);
  return exists;
};
const [filter, setFilter] = useState(null);
const filteredRows = filter ? guests.data.filter((row) => row.state === filter) : guests.data;
  const customStyles = {
    control: (styles) => ({
      ...styles,
      background: "#F2F4F8", "border-color": "#F2F4F8" , "border-radius" :"8px" ,padding: "6px" ,height : "48px" })
    }
  return (
    <section>
      <div className="relative">
        <img className="w-full" src={invitationHeader} alt="invitationHeader" />
        <div className="absolute bottom-[1%] md:p-10 px-10 pb-2 w-full flex items-center justify-between">
          <p className="text-center text-[#FAF5EE] sm:text-[40px] text-[30px] font-medium">
            {t("Add Guests")}
          </p>
        </div>
      </div>
      {guests.loading && <Loader slim />}
      {!guests.loading && (
        <div className="lg:px-[120px] px-8 py-10 bg-zinc-100">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="w-full justify-between items-center inline-flex mb-4">
              <div className="text-neutral-800 text-2xl font-semibold leading-9">
              {t("Add Guests")}
              </div>
            </div>
            <div className=" md:p-8 px-3 py-8 bg-white rounded-3xl mb-10">
              <div className="flex flex-col gap-4 mb-4">
                <div className="grid md:grid-cols-7 grid-cols-2 gap-6 pb-4 border-b items-end">
                  <div className="col-span-2 flex flex-col justify-start items-start gap-2 w-full">
                    <label className="text-zinc-800 text-base font-normal leading-snug">
                      {t("Guest name")}
                    </label>
                    <input
                      {...register("guestName", {
                        required:t("Guest name is required"),
                      })}
                      type="text"
                      placeholder={t("Guest name")}
                      name="guestName"
                      className="w-full h-12 px-4 py-3 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 font-normal leading-tight text-sm placeholder-[#808080]"
                    />
                    {errors.guestName && (
                      <Typography variant="small" color="red">
                        {errors.guestName?.message}
                      </Typography>
                    )}
                  </div>
                  <div className="col-span-2 flex flex-col justify-start items-start gap-2 w-full invitaion-verification">
                    <label className="text-zinc-800 text-base font-normal leading-snug">
                      {t("Guest mobile number")}
                    </label>
                    <Controller
                      className="w-full"
                      control={control}
                      name="phone"
                      rules={{
                        required: t("Guest mobile number is required"),
                        validate: {
                          guestNumberExists: (value) =>
                            !isGuestNumberExists(value) ||
                            t("Guest mobile number already exists"),
                        },
                      }}
                      render={({ field, field: { name, ref } }) => {
                        return (
                          <PhoneInput
                            className=""
                            defaultCountry="eg"
                            inputStyle={{
                              background: "rgb(242 244 248",
                              width: "100%",
                              margin: "0px 4px",
                              border: "0px",
                              padding: "0 14px",
                              height: "46px",
                              borderRadius: "8px",
                            }}
                            name={name}
                            ref={ref}
                            {...field}
                          />
                        );
                      }}
                    />
                    {errors.phone && (
                      <Typography variant="small" color="red">
                        {errors.phone?.message}
                      </Typography>
                    )}
                  </div>
                  <div className="col-span-2 flex flex-col justify-start items-start gap-2 w-full">
                    <label className="text-zinc-800 text-base font-normal leading-snug">
                      {t("Classified")}
                    </label>
                    <Controller
                      control={control}
                      name="classified"
                      rules={{ required: t("classified is required") }}
                      render={({ field, field: { name, ref } }) => {
                        return (
                          <Select
                            options={[
                              { value: "groom", label: t("Groom") },
                              { value: "bride", label: t("Bride") },
                              { value: "friends", label: t("Friends") },
                              { value: "others", label: t("Others") },
                            ]}
                            name={name}
                            ref={ref}
                            className="w-full"
                            styles={customStyles}
                            placeholder={t("Select...")}
                            {...field}
                          />
                        );
                      }}
                    />
                    {errors.classified && (
                      <Typography variant="small" color="red">
                        {errors.classified?.message}
                      </Typography>
                    )}
                  </div>
                  {/* Add Guest button */}
                  <button
                    type="submit"
                    className="h-12 pl-3 pr-4 py-2 rounded-lg text-base text-red border border-red justify-center items-center gap-2 inline-flex"
                  >
                    <FontAwesomeIcon
                      className="font-normal leading-[21px]"
                      icon={faPlus}
                    />
                    <span>{t("Add Guest")}</span>
                  </button>
                </div>
              </div>
              <div className="flex justify-end"></div>
            </div>
          </form>
          <div className='overflow-x-auto'>
          <table className="w-full table-auto border-collapse mb-10 rounded-3xl overflow-hidden shadow-md border border-gray-500">
            <thead className="border text-nowrap border-gray-500">
              <tr>
                <th className="py-2 px-4 border text-nowrap text-gray-500 text-base font-medium leading-snug">
                  #
                </th>
                {columns.map((column) => (
                  <th
                    key={column.accessor}
                    className="py-2 px-4 border text-nowrap text-gray-500 text-base font-medium leading-snug"
                  >
                    {column.Header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white border text-nowrap border-gray-500">
              {filteredRows.map((row, index) => (
                <tr
                  key={index}
                  className="border-t h-12 text-nowrap cursor-pointer hover:bg-slate-100"
                >
                  <td className="px-4 border text-nowrap text-center text-sm font-normal leading-tight">
                    {index + 1}
                  </td>
                  {columns.map((column) => (
                    <td
                      key={column.accessor}
                      className="px-4 border text-nowrap text-center text-sm font-normal leading-tight"
                    >
                      {column.Header === "Status" ? (
                        <span
                          className={`${
                            row[column.accessor] === "accepted"
                              ? "text-[#1A7F37] font-semibold px-3 py-[6px] border border-[#1A7F37] rounded-[100px]"
                              : row[column.accessor] === "cannot_attend"
                              ? "text-[#D1242F] font-semibold px-3 py-[6px] border border-[#D1242F] rounded-[100px]"
                              : row[column.accessor] === "new"
                              ? "text-[#9A6700] font-semibold px-3 py-[6px] border border-[#9A6700] rounded-[100px]"
                              : "text-gray-500 font-semibold px-3 py-[6px] border border-gray-500 rounded-[100px]"
                          }`}
                        >
                          {row[column.accessor] === "accepted" && "Attending"}
                          {row[column.accessor] === "cannot_attend" &&
                            "Excused"}
                          {row[column.accessor] === "new" && "Pending"}
                        </span>
                      ) : (
                        row[column.accessor]
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          </div>
          <div className="flex items-center justify-end">
            <button
              onClick={() => navigate(`/invitation/view/${params.id}`)}
              type="button"
              className="w-[150px] h-12 px-8 py-2 rounded-lg border border-red justify-center items-center inline-flex"
            >
              <span className="text-red text-sm font-medium leading-[14px] tracking-wide">
                {t("Back")}
              </span>
            </button>
          </div>
        </div>
      )}
    </section>
  );
}

export default AddGuests
