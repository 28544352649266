import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import Select from "react-select";
import { Typography } from "@material-tailwind/react";
import { Spinner } from "flowbite-react";
import editIcon from "../../assets/images/profile/icons/edit.svg";
import clsx from "clsx";
import Loader from "../../components/Loader";
import { toast } from "react-toastify";
import { PhoneInput } from "react-international-phone";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import useAxiosUserSecureConfig from "../../hooks/useAxiosUserSecureConfig";

function EditProfile() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const config = useAxiosUserSecureConfig();

  const { register, handleSubmit, watch, setValue, control, formState: { errors } } = useForm();
  const [user, setUser] = useState({
    loading: true,
    data: {},
  });
  const fetchData = () => {
    axios
    .post(`/api/user/my-profile`, { } , config)
    .then((res) => {
      console.log("user", res.data);
      if (!res.data?.result?.success) {
        toast.error("Error while fetching profile data!");
      }
      setUser({
        loading: false,
        data: res.data?.result.data || {},
      });
    })
    .catch((err) => {
      console.log(err);
    });
  }

  useEffect(() => {
   fetchData()
  }, []);
  
  
  const menuStyle = "border rounded-md bg-white hover:cursor-pointer px-4 py-2";

  const optionStyles = {
    focus: "hover:cursor-pointer hover:text-red",
    Selected: "bg-[#F2F4F8]",
  };

  const baseStyle =
    " bg-[#F2F4F8]  rounded-lg self-stretch grow shrink bg- text-zinc-500 text-sm font-normal leading-tight h-12 px-[18px]  justify-start items-center gap-2 inline-flex";
  const [countries, setCountries] = useState({ loading: true, data: [] });
  const [states, setStates] = useState({ loading: false, data: [] });
  const [cities, setCities] = useState({ loading: false, data: [] });
  useEffect(() => {
    axios
      .post("/api/country", {})
      .then((res) => {
        console.log("countries", res.data);

        if (!res.data?.result?.success) {
          //toast.error("Error while fetching countries!");
          return;
        }

        setCountries((prevState) => ({
          ...prevState,
          loading: false,
          data: res.data?.result?.countries ?? [],
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const country = watch("country");
  const state = watch("state");

  useEffect(() => {

    if (!country) {
      setStates((prevState) => ({
        ...prevState,
        data: [],
      }));
      setCities((prevState) => ({
        ...prevState,
        data: [],
      }));
      return;
    }

    setStates((prevState) => ({
      ...prevState,
      loading: true,
    }));

    axios
      .post(`/api/country/${country.value}`, {})
      .then((res) => {
        console.log("states", res.data);

        if (!res.data?.result?.success) {
          // toast.error("Error while fetching states!");
          return;
        }

        setStates((prevState) => ({
          ...prevState,
          loading: false,
          data: res.data?.result?.countries ?? [], // TODO: fix naming in backend from countries to states
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [country]);

  useEffect(() => {


    if (!state) {
      setCities((prevState) => ({
        ...prevState,
        data: [],
      }));
      return;
    }

    setCities((prevState) => ({
      ...prevState,
      loading: true,
    }));

    axios
      .post(`/api/state/${state.value}`, {})
      .then((res) => {
        console.log("cities", res.data);

        if (!res.data?.result?.success) {
          //toast.error("Error while fetching cities!");
          return;
        }

        setCities((prevState) => ({
          ...prevState,
          loading: false,
          data: res.data?.result?.state.cities ?? [],
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [state]);


  const onsubmit = (data) => {
    const requestData = {
      name: data.name,
      email: data.email,
      phone: data.phone,
      country_id: data.country.value,
      state_id: data.state.value,
      city_id: data.city.value,
      address: data.address,
    };
    console.log(requestData);
    axios.post('/api/user/account/update' ,requestData, config)
        .then((res) => {
          if (!res.data.result?.success) {
            toast.error(res.data.result?.message || "Request failed!");
            return;
          }
          toast.success("You edit your account successfully!");

        })
        .catch((err) => {
          console.log("err" , err)
          toast.error("Error while editing your account!");
        })
  };
  
  const customStyles = {
    control: (styles) => ({
      ...styles,
      background: "#F2F4F8",
      "border-color": "#F2F4F8",
      "border-radius": "8px",
      padding: "6px",
    }),
  };
  
  return (
    <>
      {user.loading && <Loader slim />}
      {!user.loading && (
        <section className="px-5 xl:px-10 py-10">
          <div className="justify-start items-center gap-2  hidden sm:inline-flex mb-10">
            <div
              onClick={() => navigate("/user/account")}
              className="text-zinc-400 text-base font-normal leading-[48px] hover:text-gray-dark cursor-pointer"
            >
              {t("My Account")}
            </div>
            <FontAwesomeIcon
              className="text-zinc-400 text-base"
              icon={faAngleRight}
            />
            <div
              onClick={() => navigate("/user/edit-profile")}
              className="text-zinc-400 text-base font-normal leading-[48px] hover:text-gray-dark cursor-pointer"
            >
              {t("Edit Account")}
            </div>
          </div>
          <div className="xl:px-10 flex gap-20 flex-wrap md:flex-nowrap">
            <div className="grow">
              <div className="flex justify-between items-center mb-6">
                <p className="text-lg md:text-2xl font-semibold leading-9 text-red">
                  {t("Personal Information")}
                </p>
              </div>
              <form onSubmit={handleSubmit(onsubmit)}>
                <div className="flex flex-col gap-10">
                  <div className="grid lg:grid-cols-2 gap-x-6 gap-y-4">
                    <div className="flex flex-col justify-start items-start gap-2 ">
                      <label className="text-zinc-800 text-base font-normal leading-snug">
                        {t("Full Name")}{" "}
                        <span className="text-red-500 text-base font-normal leading-snug">
                          {" "}
                          *{" "}
                        </span>
                      </label>
                      <input
                        {...register("name", {
                          required: t("Full Name is required"),
                        })}
                        defaultValue={user.data.name}
                        placeholder={t("name")}
                        className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                      />
                      {errors.name && (
                        <Typography variant="small" color="red">
                          {errors.name?.message}
                        </Typography>
                      )}
                    </div>

                    <div className="flex flex-col justify-start items-start gap-2 ">
                      <label className="text-zinc-800 text-base font-normal leading-snug">
                        {t("Email")}{" "}
                        <span className="text-red-500 text-base font-normal leading-snug">
                          {" "}
                          *{" "}
                        </span>
                      </label>
                      <input
                        {...register("email", {
                          required: t("Email is required"),
                        })}
                        defaultValue={user.data.email}
                        placeholder={t("Email")}
                        className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                      />

                      {errors.email && (
                        <Typography variant="small" color="red">
                          {errors.email?.message}
                        </Typography>
                      )}
                    </div>

                    <div className="invitaion-verification self-stretch flex-col justify-start items-start gap-2 flex">
                      <label className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">
                        {t("Phone Number")}{" "}
                        <span className="text-red-500 text-base font-normal leading-snug">
                          {" "}
                          *{" "}
                        </span>
                      </label>

                      <div className="w-full relative flex justify-between">
                        <Controller
                          className="w-full"
                          control={control}
                          name="phone"
                          defaultValue={user.data.phone}
                          rules={{ required: "Phone Number is required" }}
                          render={({ field, field: { name, ref } }) => {
                            return (
                              <PhoneInput
                                className=""
                                error={errors.phone}
                                defaultCountry="eg"
                                inputStyle={{
                                  background: "rgb(242 244 248",
                                  width: "100%",
                                  margin: "0px 4px",
                                  border: "0px",
                                  padding: "0 14px",
                                  height: "46px",
                                  borderRadius: "8px",
                                }}
                                name={name}
                                ref={ref}
                                {...field}
                              />
                            );
                          }}
                        />
                      </div>
                      {errors.phone && (
                        <Typography variant="small" color="red">
                          {errors.phone?.message}
                        </Typography>
                      )}
                    </div>

                    {countries.loading && <Loader slim />}
                    {!countries.loading && (
                      <div className="flex flex-col justify-start items-start gap-2">
                        <Controller
                          control={control}
                          name="country"
                          rules={{ required: t("Country is required") }}
                          defaultValue={
                            user.data.country.id
                              ? {
                                  value: user.data.country.id,
                                  label: user.data.country.name,
                                }
                              : null
                          }
                          render={({ field, field: { name, ref } }) => (
                            <>
                              <label className="text-zinc-800 text-base font-normal leading-snug">
                                {t("Country")}{" "}
                                <span className="text-red-500 text-base font-normal leading-snug">
                                  {" "}
                                  *{" "}
                                </span>
                              </label>
                              <Select
                                styles={customStyles}
                                name={name}
                                ref={ref}
                                isClearable
                                isSearchable
                                options={countries.data.map((country) => ({
                                  value: country.id,
                                  label: country.name,
                                }))}
                                {...field}
                                className="w-full"
                                value={field.value}
                                onChange={(selectedOption) => {
                                  field.onChange(selectedOption);
                                }}
                              />
                              {errors.country && (
                                <Typography variant="small" color="red">
                                  {errors.country?.message}
                                </Typography>
                              )}
                            </>
                          )}
                        />
                      </div>
                    )}

                    <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                      <label className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">
                        {t("State")}{" "}
                        <span className="text-red-500 text-base font-normal leading-snug">
                          {" "}
                          *{" "}
                        </span>
                      </label>
                      {states.loading && <Loader slim />}
                      {!states.loading && (
                        <Controller
                          control={control}
                          name="state"
                          rules={{ required: t("State is required") }}
                          defaultValue={
                            user.data.state.id
                              ? {
                                  value: user.data.state.id,
                                  label: user.data.state.name,
                                }
                              : null
                          }
                          render={({ field, field: { name, ref } }) => (
                            <>
                              <Select
                                styles={customStyles}
                                name={name}
                                ref={ref}
                                isClearable
                                isSearchable
                                options={states.data.map((state) => ({
                                  value: state.id,
                                  label: state.name,
                                }))}
                                {...field}
                                className="w-full"
                                onChange={(selectedOption) => {
                                  field.onChange(selectedOption);
                                }}
                              />
                              {errors.state && (
                                <Typography variant="small" color="red">
                                  {errors.state?.message}
                                </Typography>
                              )}
                            </>
                          )}
                        />
                      )}

                      <Typography variant="small" color="red">
                        {errors.state?.message || ""}
                      </Typography>
                    </div>

                    <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                      <label className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">
                        {t("City")}{" "}
                        <span className="text-red-500 text-base font-normal leading-snug">
                          {" "}
                          *{" "}
                        </span>
                      </label>
                      {cities.loading && <Loader slim />}
                      {!cities.loading && (
                        <Controller
                          className="w-full"
                          control={control}
                          name="city"
                          rules={{ required: t("City is required") }}
                          defaultValue={
                            user.data.city.id
                              ? {
                                  value: user.data.city.id,
                                  label:  user.data.city.name,
                                }
                              : null
                          }
                          render={({ field, field: { name, ref } }) => {
                            return (
                              <Select
                                className="w-full"
                                placeholder="City"
                                classNames={{
                                  input: () => "[&_input:focus]:ring-0",
                                  menu: () => menuStyle,
                                  option: ({ isFocused, isSelected }) =>
                                    clsx(
                                      isFocused && optionStyles.focus,
                                      isSelected && optionStyles.Selected
                                    ),
                                  control: () => baseStyle,
                                }}
                                styles={customStyles}
                                isClearable
                                isSearchable
                                options={cities.data.map((city) => ({
                                  value: city.id,
                                  label: city.name,
                                }))}
                                name={name}
                                ref={ref}
                                {...field}
                              />
                            );
                          }}
                        />
                      )}
                      <Typography variant="small" color="red">
                        {errors.city?.message || ""}
                      </Typography>
                    </div>

                    <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                      <label className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">
                        {t("Address")}
                      </label>
                      <Controller
                        name="address"
                        defaultValue={user.data.address}
                        control={control}
                        render={({ field }) => (
                          <input
                            type="text"
                            {...field}
                            className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                          />
                        )}
                      />
                    </div>
                  </div>

                  <div className="flex justify-end w-full">
                    <button
                      type="submit"
                      className="w-fit px-16 py-3 bg-red rounded-[10px] shadow border border-white justify-center items-center gap-2.5 inline-flex"
                    >
                      <div className="text-center text-white text-base font-normal leading-normal">
                        {t("Save")}
                      </div>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default EditProfile;
