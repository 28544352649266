import {authActions} from  '../slicer/auth';
import {uiActions} from "../slicer/ui";
import {toast} from "react-toastify";
import axios from "axios";
import { useNavigate } from 'react-router-dom';

export const login = async (email, password, dispatch, navigate) => {
  try {
    dispatch(uiActions.startLoading());

    const { data, data: { result, error } } = await axios.post("/api/auth", {
      jsonrpc: 2.0,
      params: {
        db: "ٌRode4Bride",
        login: email,
        password: password,
      },
    }, {})
      .then(dispatch(uiActions.stopLoading()));

    console.log("result", result);

    if (error || result?.error) {
      return Promise.resolve(false);
    }

    if (result.success === false) {
      toast.error(result.msg);
      return Promise.resolve(false);
    }

    const vendorType = result.vendor_type ? "vendor" : "user";

    dispatch(authActions.login({
      name: result?.sessionInfo?.name,
      email: result?.sessionInfo?.username,
      partner: result?.spouse,
      apiKey: result.apiKey,
      partnerId: result?.partner_id,
      type: vendorType,
      vendor_type: result?.vendor_type,
    }));

    // Set session expiration time (2 hours from now)
    const expirationTime = new Date().getTime() + 2 * 60 * 60 * 1000; // 2 hours in milliseconds
    localStorage.setItem("sessionExpiration", expirationTime.toString());

    // Timer to check session expiration
    const checkSessionExpiration = () => {
      const currentTime = new Date().getTime();
      const storedExpirationTime = localStorage.getItem("sessionExpiration");

      if (storedExpirationTime && currentTime > parseInt(storedExpirationTime, 10)) {
        // Session expired, logout the user
        dispatch(authActions.logout());
        navigate('/login'); // Redirect to login page
        toast.warning("Session expired. Please log in again.");
      }
    };

    // Set an interval to check every minute (60000 milliseconds)
    setInterval(checkSessionExpiration, 60000);

    const invitationVerificationLink = localStorage.getItem("invitationVerificationLink");

    if (result.vendor_type === "supplier") {
      navigate('/product-Supplier/Account');
    } else if (result.vendor_type === "services") {
      navigate('/ServiceProvider/account');
    } else if (result.vendor_type === "venue") {
      navigate('/vendor/account');
    } else if (invitationVerificationLink) {
      window.location.href = invitationVerificationLink;
    } else {
      navigate('/');
    }

    localStorage.removeItem("invitationVerificationLink");

    return Promise.resolve(true);
  } catch (error) {
    console.log(error);
    toast.error(error.message);
  }
};


export const registerStatuses = {
  SUCCESS: 1,
  EMAIL_TAKEN: 2,
  ERROR: 3,
};

export const userRegister = async (registerData, dispatch) => {
  try {
    dispatch(uiActions.startLoading());

    const { data:result } = await axios.post("/api/register_user", {
      "name": registerData.name,
      "address": registerData.address,
      "city": registerData.city.value,
      "phone": registerData.phone,
      "email": registerData.email,
      "password": registerData.password,
    }, {})
        .finally(dispatch(uiActions.stopLoading()))

    console.log(result)

    if (!result?.result?.success) {
      if (result?.result?.msg === 'User with the same login already exists') {
        return Promise.resolve(registerStatuses.EMAIL_TAKEN);
      }

      return Promise.resolve(registerStatuses.ERROR);
    }

    return Promise.resolve(registerStatuses.SUCCESS);
  } catch (error) {
    console.log(error)
    toast.error(error.message)
  }
}

export const vendorRegister = async (registerData, dispatch) => {
  try { 
    dispatch(uiActions.startLoading());
    console.log("vendor_type",registerData.vendor_type)

    const { data:result } = await axios.post("/api/register_vendor", {
      
        name: registerData.name,
        brand_name:  registerData.company_name,
        vendor_type:  registerData.vendor_type,
        company_type:  registerData.company_type,
        country_id: registerData.country.value,
        state_id: registerData.state.value,
        city_id: registerData.city.value,
        address:  registerData.address,
        zip: registerData.zip,
        phone: registerData.phone,
        email: registerData.email,
        tax_id:  registerData.tax_id,
        cr_no: registerData.cr_no,
        password: registerData.password,
        business_start_year:registerData.startYear,
        website: registerData.website,
        location: registerData.location, 

     
        // // youtube_link: registerData.youtubeLink ,
        // // facebook_link: registerData.facebookLink,
        // // tiktok_link: registerData.tiktokLink,
        // // instagram_link: registerData.instgramLink,
        // // x_link: registerData.xLink,

       
      
    }, {})
        .finally(dispatch(uiActions.stopLoading()))

    console.log(result)

    if (!result?.result?.success) {
      if (result?.result?.msg === 'User with the same login already exists') {
        toast.error("Email already exists.");
        return Promise.resolve(registerStatuses.EMAIL_TAKEN);
      }

      return Promise.resolve(registerStatuses.ERROR);
    }

    return Promise.resolve(registerStatuses.SUCCESS);
  } catch (error) {
    console.log(error)
    toast.error(error.message)
  }
}