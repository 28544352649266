import { Typography } from "@material-tailwind/react";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import {Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import placholder_image from "../../assets/images/booking/placeholder-image.jpg"
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import useAxiosUserSecureConfig from "../../hooks/useAxiosUserSecureConfig";
import uplooad from "../../assets/images/icons/upload.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import {faTrashCan} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import { faPlus} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

const optionsInOut =[
    {label:"indoor", value:"indoor"},
    {label:"outdoor", value:"outdoor"},
];
const optionVailable =[
    {label:"Yes", value:1},
    {label:"No", value:2},
];
const optionsService =[
  {label:"", value:""},
  {label:"", value:""},
];


function EditVenue() {
  const { t, i18n } = useTranslation();
  const Dayoptions =[
    {label:t("SUN"), value:"1"},
    {label:t("MON"), value:"2"},
    {label:t("TUE"), value:"3"},
    {label:t("WED"), value:"4"},
    {label:t("THU"), value:"5"},
    {label:t("FRI"), value:'6'},
    {label:t("SAT"), value:"7"},
    
  ];
  const {
    register,
    watch,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm();

 



const [inOut, setInOut] = useState({ loading: true, data: [] });

 
  const [imageBase64, setImageBase64] = useState(null);
  const [imageName, setImageName]=useState('');
  const handleImageChange = (e) => {
    setImageName(e.target.files[0].name);
    const file = e.target.files[0];
  
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Extract base64 data without the prefix
        const base64WithoutPrefix = reader.result;
        setImageBase64(base64WithoutPrefix);
      };
      reader.readAsDataURL(file);
    }
  };
  


  const navigate = useNavigate();
  const config = useAxiosUserSecureConfig();

  const [services, setServices] = useState({ loading: true, data: [] });

const fetchData = () => {
  axios.post('/api/my_services', {}, config)
    .then((res) => {
      console.log("services", res.data);
      if (!res.data?.result?.success) {
        toast.error("Error while fetching services!");
        return;
      }
      setServices({
        loading: false,
        data: res.data?.result?.products?.slice().reverse() || [],
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
useEffect(() => {
  fetchData();
}, []);

const user = useSelector(state => state.auth.user)
useEffect(() => {
  axios
    .post(`/api/vendor/${user.partnerId}`,{}, config)
    .then((res) => {
      console.log("profile data", res.data);

      if (!res.data?.result?.success) {
        toast.error("Error while fetching Profile data!");
      }
      setImageBase64(res.data?.result?.vendor.image);
      setVendor((prevState) => ({
        ...prevState,
        loading: false,
        data: res.data?.result?.vendor || [], 
      }));
     
    })
    .catch((err) => {
      console.log(err);
    });
}, []);


  const customStyles = {
    control: (styles) => ({
      ...styles,
      background: "#F2F4F8",
      "border-color": "#F2F4F8",
      "border-radius": "8px",
      padding: "6px",
    }),
  };
  const params = useParams();
  const [venue, setVenue] = useState({
    loading: true,
    data: {},
  });
  
  const [facility, setFacility] = useState({
    loading: true,
    data: {},
  });

  useEffect(() => {
    axios
      .post(`/api/facility/${params.id1}`, { id: params.id1 })
      .then((res) => {
        console.log("facility", res.data);

        if (!res.data?.result?.success) {
          toast.error("Error while fetching facility!");
        }
  
        setFacility({
          loading: false,
          data: res.data?.result || {}, // Set to an empty object if undefined
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [params.id1]);

  const [imageUrls, setImageUrls] = useState({loading : true , data :[]});
  // const [selectedOption, setSelectedOption] = useState(null);

  const onSubmit=(data)=>{
    console.log("data", data);
    const selectedValues = data.Unavailable.map((option) => option.value);
        const availableDays = {
          available_sun: selectedValues.includes("1"),
          available_mon: selectedValues.includes("2"),
          available_tue: selectedValues.includes("3"),
          available_wed: selectedValues.includes("4"),
          available_thu: selectedValues.includes("5"),
          available_fri: selectedValues.includes("6"),
          available_sat: selectedValues.includes("7"),
        };
        
        const IncludedArray = [];
        const otherArray = []; 

        const filterService =()=>{
           data.IncludedService.map ((service)=>{
            IncludedArray.push(service.value)
           })
           data.OtherService.map ((service)=>{
            otherArray.push(service.value)
           })
        }
        filterService ();
    data={
    venue_id: venue.data.id,
    name: data.name,
    facility_id: facility.data.id,
    size:  data.size.value,
    indoor_outdoor: data.inOut.value,
    basic_price:  data.basicBrice,
    description:  data.description,
    included_services: IncludedArray,
    optional_services: otherArray,
    ...availableDays,
    image: imageBase64.split(",")[1],
  }
  console.log("formDataWithAvailability", data);
  axios.post('/api/venue-vendor/venue/update' ,data, config)
  .then((res) => {
    console.log(res.data)
    if (!res.data.result?.success) {
      toast.error(res.data.result?.message || t("Request failed!"));
      return;
    }
    toast.success(t("You Edit Venue successfully!"));
    navigate(`/Vendor/Venue/${facility.data.id}/${venue.data.id}`)
    
  })
  .catch((err) => {
    console.log("err" , err)
    toast.error(t("Error while Editing venue!"));
  })

}
axios.defaults.timeout = 50000
axios.defaults.timeoutErrorMessage='timeout'

const fetchVenue = ()=> {
  axios
  .post(`/api/venue-vendor/venue/${params.id2}`, { id: params.id2 })
  .then((res) => {
    console.log("venue", res.data);

    if (!res.data?.result?.success) {
      toast.error("Error while fetching venue!");
      return;
    }

    setImageBase64(res.data?.result?.image);
    setIncludedServices(res.data?.result?.included_services.map((serv)=>serv.id))
    setOtherServices(res.data?.result?.optional_services.map((serv)=>serv.id))
    setVenue({
      loading: false,
      data: res.data?.result || {}, // Set to an empty object if undefined
    });
    
    // setValue("size", res.data?.result?.size);
    const displayImageIds = res.data?.result?.extraImages || [];
    const imagePromises = displayImageIds.map((id) =>
        axios
          .post(`/api/vendor/venue/${params.id2}/image/${id}`, {}, config)
          .then((response) => {
            console.log("res",response)
            return {
              src: response.data?.result?.image, id:id,
              alt: `Image ${id}`,
            }
          })
      );
      Promise.all(imagePromises)
        .then((images) => {
          setImageUrls((prevState) => ({
            ...prevState,
            loading: false,
            data: images,
          }));
        
          const filledImages = images.map((image) => ({image:image.src, id:image.id}));
          setSelectedImages(filledImages);
        })
    // if (res.data?.result?.extraImages) {
    //   const extraImagesCount = res.data.result.extraImages.length;
    //   const placeholdersCount = 5 - extraImagesCount;
    //   const filledImages = res.data.result.extraImages.concat(
    //     Array.from({ length: placeholdersCount }, () => placholder_image)
    //   );
    //   setSelectedImages(filledImages);
    // }
  })
  
  .catch((err) => {
    console.error("Error while fetching venue:", err);
  });
}

useEffect(() => {
  fetchVenue ()
}, [params.id2]);

const [selectedImages, setSelectedImages] = useState(); 

const [vendor, setVendor]=useState({ loading: true, data: [] })


    

    
// const handleImageClick = (index) => {
//   const fileInput = document.getElementById(`fileInput-${index}`);
//   if (fileInput) {
//     fileInput.click();
//   }
// };

const handleImageUpload = (e, index) => {
  const file = e.target.files[0];
  if (file) {
    const reader = new FileReader();
    reader.onload = () => {
      const updatedSelectedImages = [...selectedImages];
      updatedSelectedImages[index] = reader.result;
      setSelectedImages(updatedSelectedImages);

      // Upload the image to the server
      uploadImageToServer(reader.result);
    };
    reader.readAsDataURL(file);
  }
};
console.log("selectedImages",selectedImages)
const uploadImageToServer = (imageData) => {
  setImageUrls((prevState) => ({
    ...prevState,
    loading: true,
  }));
  const data = {
    venue_id: params.id2,
    image: imageData.split(",")[1]
  };
  axios.post('/api/venue-vendor/venue/add-image',  data  , config)
  .then((res) => {
    if (!res.data.result?.success) {
      toast.error(res.data.result?.msg || test("Request failed!"));
      return;
    }
    toast.success(t("You add image successfully!"));
    fetchVenue()
    fetchData()
    setImageUrls((prevState) => ({
      ...prevState,
      loading: false,
    }));
  })
  .catch((err) => {
    console.log("err" , err)
    toast.error("Error while adding image!");
  })
};
const removeFromServer = (id) => {
  axios.post('/api/venue-vendor/venue/delete-image',  {image_id: id}  , config)
  .then((res) => {
    if (!res.data.result?.success) {
      toast.error(res.data.result?.msg || t("Request failed!"));
      return;
    }
    toast.success(t("delete image successfully!"));
    fetchVenue()
    fetchData()
    setImageUrls((prevState) => ({
      ...prevState,
      loading: false,
    }));
  })
  .catch((err) => {
    console.log("err" , err)
    toast.error(t("Error while deleting image!"));
  })
};
const [venueSize , setVenueSize] = useState({ loading: true, data: [] })
useEffect(() => {
axios.post('/api/venue-sizes', {}).then((res) => {
  console.log("Venue size", res.data);

  if (!res.data?.result?.success) {
    toast.error("Error while fetching venue size");
    return;
  }

  setVenueSize(prevState => ({
    ...prevState,
    loading: false,
    data: res.data?.result?.venueSizes 
  }))

}).catch((err) => {
  console.log(err);
})
},[])
console.log("venue sizes :" , venueSize)  

const [includedServices, setIncludedServices] = useState([]);
const [otherServices, setOtherServices] = useState([]);
console.log("Include", includedServices )
console.log("otherclude", otherServices )

 return (
   <section className="px-5 xl:px-20 py-10">
           {venue.loading && <Loader slim />}
     {!venue.loading && (
       <>
     
     <div className="justify-start items-center gap-2 mb-10 hidden md:inline-flex">
              <div
                onClick={() => navigate("/vendor/account")}
                className="text-zinc-400 text-base font-normal leading-[48px] hover:text-gray-dark cursor-pointer"
              >
                {t("My Account")}
              </div>
              <FontAwesomeIcon
                className="text-zinc-400 text-base"
                icon={faAngleRight}
              />
              <div
                onClick={() => navigate("/vendor/Facilities")}
                className="text-zinc-400 text-base font-normal leading-[48px] hover:text-gray-dark cursor-pointer"
              >
                {t("My Facilities")}
              </div>
              <FontAwesomeIcon
                className="text-zinc-400 text-base"
                icon={faAngleRight}
              />
              <div onClick={() => navigate(`/vendor/Facilities/${facility.data.id}`)} className="text-zinc-400 text-base font-normal leading-[48px] hover:text-gray-dark cursor-pointer">
                {facility.data.name}
              </div>
              <FontAwesomeIcon
                className="text-zinc-400 text-base"
                icon={faAngleRight}
              />
              <Link to={`/Vendor/Venue/${facility.data.id}/${venue.data.id}`} >
              <div className="text-zinc-400 text-base font-normal leading-[48px] hover:text-gray-dark cursor-pointer">
                {venue.data.name}
              </div>
              </Link>
              <FontAwesomeIcon
            className="text-zinc-400 text-base"
            icon={faAngleRight}
          />
          <div className="text-zinc-400 text-base font-normal leading-[48px] hover:text-gray-dark cursor-pointer">
            {t("Edit")}
          </div>
           </div>

        
           <form onSubmit={handleSubmit(onSubmit)} className="flex gap-20 flex-wrap md:flex-nowrap">
           <div className=" flex w-full md:w-1/3 gap-15 flex-wrap md:flex-nowrap  border-b pb-6 mb-6">
           <div className="flex w-full  flex-col gap-4 items-center">
             <img
               src={imageBase64}
               className=" w-full h-[260px] object-cover rounded-lg"
             />

             <div className="self-stretch flex-col justify-start items-start gap-2 flex">
               <div className="relative w-full bg-slate-100 border-slate-100 rounded-lg">
                 <label>
                   <div className="h-12 flex justify-between items-center">
                     <input
                       type="file"
                       hidden
                       onChange={handleImageChange}
                       id="fileInput"
                       className="custom-file-input absolute left-[-100px] text-zinc-500 "
                     />

                     <label htmlFor="fileInput" className="cursor-pointer ">
                       <img
                         src={uplooad}
                         className={`absolute ${i18n.language === 'en' ? 'right-3' : 'left-3'}  bottom-3`}
                         alt="upload"
                       />
                      
                     </label>

                     {/* Placeholder and image name */}
                     <input
                       type="text"
                       className="border-0 flex-grow p-4 bg-slate-100 rounded-lg text-zinc-500 text-base font-normal leading-tight placeholder-[#808080]"
                       placeholder={t("Choose a file")}
                       value={imageName ? imageName : ""}
                       readOnly
                     />
                   </div>
                 </label>
               </div>
             </div>
              {imageUrls.loading && <Loader slim/>} 
              {!imageUrls.loading &&  (
             <div className="grid grid-cols-2 gap-2">
               {selectedImages.map(({image,id}, index) => (
                 <div key={index} className="relative">
                   <img
                     src={image}
                     className={`w-full h-[180px]  object-cover rounded-lg hover:brightness-50 ${
                       image === placholder_image && "cursor-pointer"
                     }`}
                     alt={"venue_image"}
                   /> 
            
                   <button type="button" onClick={()=>{removeFromServer(id)}}>
                     <FontAwesomeIcon icon={faTrashCan}  className={`absolute opacity-55 ${i18n.language === 'en' ? 'right-3' : 'left-3'} top-3`}
                      />
                    </button>
                 </div>
               ))}
              
                {selectedImages.length < 5 && (
                  <div className="flex justify-end">
                    <div>
                      <label>
                      <div className="pl-3 pr-4 py-2 rounded-lg hover:cursor-pointer text-base text-red border border-red justify-center items-start gap-2 inline-flex">
                        <FontAwesomeIcon
                          className="font-normal leading-[21px]"
                          icon={faPlus}
                        />
                          <span className="font-normal leading-[21px]">{t("Add Images")} </span> 
                        <input
                        title="Upload image"
                        type="file"
                        defaultValue={""}
                        //  id={`fileInput-${index}`}
                        accept="image/*"
                        className="hidden"
                        onChange={(e) => handleImageUpload(e)}
                        />
                     </div>
                     </label>
                     </div>
                     </div>
                   )}
             </div>
             )} 
           </div>
           </div>
           <div className="grow w-2/3 ">

             <p className="text-lg md:text-2xl text-red font-semibold leading-9 mb-6">
               {t("Edit Venue")}
             </p>

             
               <div className="flex flex-col gap-10">
                 <div className="w-full grid lg:grid-cols-2 gap-x-6 gap-y-4">
                   <div className="flex flex-col justify-start items-start gap-2 ">
                     <label className="text-zinc-800 text-base font-normal leading-snug">
                       {t("Venue name")} <span className="text-red-500 text-base font-normal leading-snug">*</span>
                     </label>
                     <Controller
                       rules={{ required: t("Venue name is required") }}
                       name="name"
                       control={control}
                       defaultValue={venue.data.name}
                       render={({ field }) => (
                         <input
                           type="text"
                           {...field}
                           className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                         />
                       )}
                     />
                     {errors.name && (
                       <Typography variant="small" color="red">
                         {errors.name?.message}
                       </Typography>
                     )}
                   </div>
                   <div className="flex flex-col justify-start items-start gap-2 ">
                     <label className="text-zinc-800 text-base font-normal leading-snug">
                     {t("Basic Price")} <span className="text-red-500 text-base font-normal leading-snug">*</span>
                     </label>
                     <Controller
                       rules={{ required: t("basic price is required") }}
                       name="basicBrice"
                       control={control}
                       defaultValue={venue.data.minPrice}
                       render={({ field }) => (
                         <input
                           type="text"
                           {...field}
                           className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                         />
                       )}
                     />
                     {errors.basicBrice && (
                       <Typography variant="small" color="red">
                         {errors.basicBrice?.message}
                       </Typography>
                     )}
                   </div>

                   <div className="flex flex-col justify-start items-start gap-2 ">
                     <label className="text-zinc-800 text-base font-normal leading-snug">
                     {t("Available Days")} <span className="text-red-500 text-base font-normal leading-snug">*</span>
                     </label>
                     <Controller
                       control={control}
                       name="Unavailable"
                       defaultValue={Dayoptions.filter(
                        (day) =>
                          venue.data.availableDays[day.label.toLowerCase()]
                      ).map((day) => ({
                        value: day.value,
                        label: day.label,
                      }))}
                       render={({ field }) => (
                         <Select
                           placeholder={t("Select...")}
                           className="w-full border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                           
                           onChange={(selectedOption) => {
                            field.onChange(selectedOption);
                          }}
                           options={Dayoptions}
                           isMulti
                           isClearable
                           {...field}
                           styles={customStyles}
                         />
                       )}
                     />

                     {errors.Unavailable && (
                       <Typography variant="small" color="red">
                         {errors.Unavailable?.message}
                       </Typography>
                     )}
                   </div>
              {venueSize.loading && <Loader slim />}
              {!venueSize.loading && (
              <div className="flex flex-col justify-start items-start gap-2 ">
                <label className="text-zinc-800 text-base font-normal leading-snug">
                {t("Number of Guest")} <span className="text-red-500 text-base font-normal leading-snug">*</span>
                </label>
                <Controller
                    control={control}
                    name="size"
                    rules={{required: t('Number of guest is required')}}
                    defaultValue={ 
                      venue.data.size.id
                      ? {
                      value: venue.data.size.id,
                      label: venue.data.size.name,
                    } : null }
                    render={({ field,value ,field: { name, ref } }) => {
                      const options = Object.entries(venueSize.data).map(([value, label]) => ({
                        value,
                        label
                      }));
                      const defaultValueOption = options.find(option => option.value === venue.data.size.id);
                      return <Select
                          styles={customStyles}
                          name={name}
                          defaultValue={defaultValueOption}
                          ref={ref}
                          isClearable
                          isSearchable
                          options={options}
                          {...field}
                       
                          onChange={(selectedOption) => {
                            field.onChange(selectedOption);
                          }}
                          className="w-full"
                      />
                    }}
                />
                {errors.size && (
                    <Typography variant="small" color="red">
                      {errors.size?.message}
                    </Typography>
                )}
              </div>
                 )}
                  
                   <div className="flex flex-col justify-start items-start gap-2 ">
                   <Controller
                        control={control}
                        name="inOut"
                        rules={{ required: t("Type is required") }}
                        defaultValue={
                          venue.data.indoorOutdoor
                            ? {
                                value: venue.data.indoorOutdoor,
                                label: venue.data.indoorOutdoor,
                              }
                            : null
                        }
                        render={({ field, field: { name, ref } }) => (
                          <>
                            <label className="text-zinc-800 text-base font-normal leading-snug">
                            {t("Indoor/Outdoor")} <span className="text-red-500 text-base font-normal leading-snug">*</span>
                            </label>
                            <Select
                              styles={customStyles}
                              name={name}
                              ref={ref}
                              isClearable
                              isSearchable
                              options={optionsInOut}
                              {...field}
                              className="w-full"
                              value={field.value}
                              onChange={(selectedOption) => {
                                field.onChange(selectedOption);
                              }}
                            />
                            {errors.inOut && (
                              <Typography variant="small" color="red">
                                {errors.inOut?.message}
                              </Typography>
                            )}
                          </>
                        )}
                      />
                   </div>

                  {services.loading && <Loader slim />}
                  {!services.loading && (
                   <div className="flex flex-col justify-start items-start gap-2 ">
                     <label className="text-zinc-800 text-base font-normal leading-snug">
                       {t("Included Services")} <span className="text-red-500 text-base font-normal leading-snug">*</span>
                     </label>
                     <Controller
                      control={control}
                      name="IncludedService"
                      defaultValue = {venue.data.included_services.map((service) =>({
                        value: service.id,
                        label: service.name,

                     }))}
                      rules={{}}
                      render={({ field }) => (
                        <Select
                          styles={customStyles}
                          isDisabled={false}
                          isClearable
                          isMulti
                          isSearchable
                          options={services.data
                            .filter((serv) => !otherServices.includes(serv.id)) // Filter out services selected in otherServices
                            .map((serv) => ({
                              value: serv.id,
                              label: serv.name,
                            }))}
                            
                          {...field}
                          className="w-full"
                          onChange={(selectedOptions) => {
                            setIncludedServices(
                              selectedOptions.map((option) => option.value)
                            );
                            field.onChange(selectedOptions);
                          }}
                        />
                      )}
                    />

                     {errors.IncludedService && (
                       <Typography variant="small" color="red">
                         {errors.IncludedService?.message}
                       </Typography>
                     )}
                   </div>
                  )}


                {services.loading && <Loader slim />}
                {!services.loading && (
                   <div className="flex flex-col justify-start items-start gap-2 ">
                     <label className="text-zinc-800 text-base font-normal leading-snug">
                       {t("Other Services")} <span className="text-red-500 text-base font-normal leading-snug">*</span>
                     </label>
                     <Controller
                      control={control}
                      name="OtherService"
                      defaultValue = {venue.data.optional_services.map((service) =>({
                        value: service.id,
                        label: service.name,  
                      }))}
                      rules={{}}
                      render={({ field }) => (
                        <Select
                          styles={customStyles}
                          isDisabled={false}
                          isClearable
                          isMulti
                          isSearchable
                          options={services.data
                            .filter(
                              (serv) => !includedServices.includes(serv.id)
                            ) // Filter out services selected in includedServices
                            .map((serv) => ({
                              value: serv.id,
                              label: serv.name,
                            }))}
                          {...field}
                          className="w-full"
                          onChange={(selectedOptions) => {
                            setOtherServices(
                              selectedOptions.map((option) => option.value)
                            );
                            field.onChange(selectedOptions);
                          }}
                        />
                      )}
                    />

                     {errors.OtherService && (
                       <Typography variant="small" color="red">
                         {errors.OtherService?.message}
                       </Typography>
                     )}
                   </div>
                )}

                   <div className="flex flex-col justify-start items-start gap-2 ">
                     <label className="text-zinc-800 text-base font-normal leading-snug">
                       {t("Description")} <span className="text-red-500 text-base font-normal leading-snug">*</span>
                     </label>
                     <Controller
                       rules={{ required: t("Description is required") }}
                       name="description"
                       control={control}
                       defaultValue={venue.data.description}
                       render={({ field }) => (
                         <input
                           type="text"
                           {...field}
                           className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                         />
                       )}
                     />
                     {errors.description && (
                       <Typography variant="small" color="red">
                         {errors.description?.message}
                       </Typography>
                     )}
                   </div>
                 </div>
                 <div className="flex justify-end w-full">
                   <button
                     type="submit"
                     className="w-fit px-16 py-3 bg-red rounded-[10px] shadow border border-white justify-center items-center gap-2.5 inline-flex"
                   >
                     <div className="text-center text-white text-base font-normal leading-normal">
                       {t("Save")}
                     </div>
                   </button>
                 </div>
               </div>
               </div>
             </form>
          
        
       </>
     )}
   </section>
 );
}
export default EditVenue;