import React from "react";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Modal } from "@mui/material";
import {useTranslation} from "react-i18next";
import { Link } from "react-router-dom";

function PrivacyPolicy({open , handleClose }) {
  const { t, i18n } = useTranslation();
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="absolute h-[70%] overflow-y-scroll  lg:w-[30%] w-[80%] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 px-8 py-6 bg-white rounded-2xl shadow flex-col justify-center items-start inline-flex border-4 border-red">
        <div className="w-full h-full">
          <div className="flex justify-end">
            <button
              onClick={() => handleClose()}
              className="self-stretch justify-between items-center flex"
            >
              <FontAwesomeIcon icon={faX} className="text-base text-gray-500" />
            </button>
          </div>
          {i18n.language === "en" && (
            <section className="">
              <div className="flex flex-col gap-2 mb-10">
                <div className="text-red text-lg font-bold text-center mb-5">
                  privacy policy
                </div>
                <p className="underline text-base font-medium">Objective</p>
                <p className="text-sm font-normal">
                  As part of its responsibility Your personal information will
                  be treated in a secure and confidential manner in compliance
                  with all applicable laws and regulations, when you use
                  Rose-Bazaar Websites and Services (as defined below), we
                  receive information that may directly or indirectly identify
                  you (“personal information” or “personal data”). This privacy
                  policy (this “Privacy Policy”) governs and describes how
                  Rose-Bazaar, Inc. and its subsidiaries (“Rose-Bazaar”, “we,”
                  “us” or “our”) may collect, use, and disclose personal
                  information that we obtain through or from
                  <ul className="list-disc">
                    <li>
                      {" "}
                      https://www.Rose-Bazaar.com (our “Websites”) and mobile
                      applications,{" "}
                    </li>
                    <li>
                      individuals that register to attend our marketing or
                      corporate events or receive our marketing materials
                      (“Marketing Activities”),
                    </li>
                    <li>
                      third-party sources such as data brokers, publicly
                      available sources, and our partners,
                    </li>
                    <li>
                      our subscribers’ personnel who register to use our
                      Services (including through our services user interface
                      dashboard registration forms on our websites), and
                    </li>
                    <li>
                      subscriber data submitted to our hosted search or related
                      services (the “Services”) by our subscribers or their
                      internet clients or end users, or that we collect through
                      our Services on behalf of our subscribers. Rose-Bazaar is
                      committed to international compliance with data protection
                      laws.
                    </li>
                  </ul>
                  This Data Protection Policy applies worldwide to Rose-Bazaar
                  and is based on globally accepted, basic principles on data
                  protection. Ensuring data protection is the foundation of
                  trustworthy business relationships and the reputation of
                  Rose-Bazaar as an attractive employer or partner. The Data
                  Protection Policy provides one of the necessary framework
                  conditions for cross-border data transmission. It ensures the
                  adequate level of data protection prescribed by the GDPR
                  (General Data Protection Regulation) and all the international
                  laws for cross-border data transmission, including in
                  countries that do not yet have adequate data protection laws.
                </p>
                <p className="underline text-base font-medium">Scope</p>
                <p className="text-sm font-normal">
                  policy aims at the protection of privacy to personal data
                  processing when the person responsible for processing— being
                  either an individual or a legal entity. This policy applies
                  where we are acting as a Data Controller with respect to the
                  personal data of users of our Services; in other words, where
                  we determine the purposes and means of the processing of that
                  personal data. It also applies to Rose-Bazaar website, as well
                  as other interaction (e.g. customer support conversations,
                  user surveys and interviews etc.) you may have with
                  Rose-Bazaar.
                  <ul className="list-disc">
                    <li>
                      {" "}
                      What types of personal information Rose-Bazaar collects
                      and how it is used.{" "}
                    </li>
                    <li>
                      How Rose-Bazaar collects your personal information,
                      including whether personal information is collected
                      automatically.
                    </li>
                    <li>
                      How long Rose-Bazaar retains your personal information.
                    </li>
                    <li>
                      How and when Rose-Bazaar shares your personal information
                      with others.
                    </li>
                    <li>How Rose-Bazaar protects your personal information.</li>
                    <li>
                      What choices and rights are available to you regarding the
                      use of your personal information, including how to access
                      and update personal information.
                    </li>
                    <li>
                      How personal information submitted to our Services or
                      collected through our Services on behalf of or at the
                      direction of our subscribers is treated.
                    </li>
                    <li>How to update your communication preferences.</li>
                  </ul>
                </p>
                <p className="underline text-base font-medium">
                  Responsibility
                </p>
                <p className="text-sm font-normal">
                  The Management are responsible for data processing in their
                  area of responsibility. Therefore, they are required to ensure
                  that the legal requirements, and those contained in the Data
                  Protection Policy, for data protection are met. Management
                  staff are responsible for ensuring that organizational, HR,
                  and technical measures are in place so that any data
                  processing is carried out in accordance with data protection.
                </p>
                <p className="underline text-base font-medium">
                  Abbreviations/Definition
                </p>
                <p className="text-sm font-normal">
                  <ul className="list-disc">
                    <li>
                      Data is anonymized if personal identity can never be
                      traced by anyone, or if the personal identity could be
                      recreated only with an unreasonable amount of time,
                      expense and labor.
                    </li>
                    <li>
                      Consent is the voluntary, legally binding agreement to
                      data processing.
                    </li>
                    <li>
                      Data subject under this Data Privacy Policy is any natural
                      person whose data can be processed.
                    </li>
                    <li>
                      Processing personal data means any process, with or
                      without the use of automated systems, to collect, store,
                      organize, retain, modify, query, use, forward, transmit,
                      disseminate or combine and compare data. This also
                      includes disposing of, deleting and blocking data and data
                      storage media
                    </li>
                    <li>
                      Third parties are anyone apart from the data subject and
                      the Data Controller. Transmission is all disclosure of
                      protected data by the responsible entity to third parties.
                    </li>
                    <li>
                      Highly sensitive data is data about racial and ethnic
                      origin, political opinions, religious or philosophical
                      beliefs, union membership or the health and sexual life of
                      the data subject. Under national law, further data
                      categories can be considered highly sensitive or the
                      content of the data categories can be structured
                      differently
                    </li>
                  </ul>
                </p>
                <p className="underline text-base font-medium">
                  Personal data protection principles
                </p>
                <p className="text-sm font-normal">
                  <ul className="list-disc">
                    <li>
                      Fairness and lawfulness; When processing personal data,
                      the individual rights of the data subjects must be
                      protected. Personal data must be collected and processed
                      in a legal and fair manner.
                    </li>
                    <li>
                      Restriction to a specific purpose; Personal data can be
                      processed only for the purpose that was defined before the
                      data was collected. Subsequent changes to the purpose are
                      only possible to a limited extent and require
                      substantiation.
                    </li>
                    <li>
                      Transparency; The data subject must be informed of how
                      his/her data is being handled. In general, personal data
                      must be collected directly from the individual concerned.
                      When the data is collected, the data subject must either
                      be aware of, or informed of:
                      <ul className="list-disc">
                        <li>the identity of the Data Controller</li>
                        <li>the purpose of data processing</li>
                        <li>
                          third parties or categories of third parties to whom
                          the data might be transmitted
                        </li>
                      </ul>
                    </li>
                    <li>
                      Data reduction and data economy; Before processing
                      personal data, you must determine whether and to what
                      extent the processing of personal data is necessary in
                      order to achieve the purpose for which it is undertaken.
                      Where the purpose allows and where the expense involved is
                      in proportion with the goal being pursued, anonymized or
                      statistical data must be used. Personal data may not be
                      collected in advance and stored for potential future
                      purposes unless required or permitted by national law.
                    </li>
                    <li>
                      Deletion; Personal data that is no longer needed after the
                      expiration of legal or business process- related periods
                      must be deleted. There may be an indication of interests
                      that merit protection or historical significance of this
                      data in individual cases. If so, the data must remain on
                      file until the interests that merit protection have been
                      clarified legally, or the corporate archive has evaluated
                      the data to determine whether it must be retained for
                      historical purposes.
                    </li>
                    <li>
                      Factual accuracy; up-to-datedness of data; Personal data
                      on file must be correct, complete, and – if necessary –
                      kept up to date. Suitable steps must be taken to ensure
                      that inaccurate or incomplete data are deleted, corrected,
                      supplemented or updated.
                    </li>
                    <li>
                      Confidentiality and data security; Personal data is
                      subject to data secrecy. It must be treated as
                      confidential on a personal level and secured with suitable
                      organizational and technical measures to prevent
                      unauthorized access, illegal processing or distribution,
                      as well as accidental loss, modification or destruction.
                    </li>
                  </ul>
                </p>
                <p className="underline text-base font-medium">
                  Reliability of data processing
                </p>
                <p className="text-sm font-normal">
                  Collecting, processing and using personal data is permitted
                  only under the following legal bases. One of these legal bases
                  is also required if the purpose of collecting, processing and
                  using the personal data is to be changed from the original
                  purpose
                  <ul className="list-disc">
                    <li>
                      Customer and partner data
                      <ul>
                        <li>
                          Data processing for a contractual relationship;
                          Personal data of the relevant prospects, customers and
                          partners can be processed to establish, execute and
                          terminate a contract. This also includes advisory
                          services for the partner under the contract if this is
                          related to the contractual purpose. Prior to a
                          contract – during the contract initiation phase –
                          personal data can be processed to prepare bids or
                          purchase orders or to fulfill other requests of the
                          prospect that relate to contract conclusion. Prospects
                          can be contacted during the contract preparation
                          process using the information that they have provided.
                          Any restrictions requested by the prospects must be
                          complied with.
                        </li>
                        <li>
                          Data processing for advertising purposes; If the data
                          subject contacts Rose-Bazaar to request information
                          (e.g. request to receive information), data processing
                          to meet this request is permitted. Customer loyalty or
                          advertising measures are subject to further legal
                          requirements. Personal data can be processed for
                          advertising purposes or market and opinion research,
                          provided that this is consistent with the purpose for
                          which the data was originally collected. The data
                          subject must be informed about the use of his/her data
                          for advertising purposes. If data is collected only
                          for advertising purposes, the disclosure from the data
                          subject is voluntary. The data subject shall be
                          informed that providing data for this purpose is
                          voluntary. When communicating with the data subject,
                          consent shall be obtained from him/her to process the
                          data for advertising purposes. When giving consent,
                          the data subject should be given a choice among
                          available forms of contact such as regular mail,
                          e-mail and phone. If the data subject refuses the use
                          of his/her data for advertising purposes, it can no
                          longer be used for these purposes and must be blocked
                          from use for these purposes. Any other restrictions
                          from specific countries regarding the use of data for
                          advertising purposes must be observed
                        </li>
                        <li>
                          Consent to data processing Data can be processed
                          following consent by the data subject. Before giving
                          consent, the data subject must be informed in
                          accordance with Data Protection Policy principles. The
                          declaration of consent must be obtained in writing or
                          electronically for the purposes of documentation. In
                          some circumstances, such as telephone conversations,
                          consent can be given verbally. The granting of consent
                          must be documented.
                        </li>
                        <li>
                          Data processing pursuant to legal authorization; The
                          processing of personal data is also permitted if
                          national legislation requests, requires or allows
                          this. The type and extent of data processing must be
                          necessary for the legally authorized data processing
                          activity, and must comply with the relevant statutory
                          provisions. Reliability of data processing Collecting,
                          processing and using personal data is permitted only
                          under the following legal bases. One of these legal
                          bases is also required if the purpose of collecting,
                          processing and using the personal data is to be
                          changed from the original purpose.
                        </li>
                        <li>
                          Data processing pursuant to legitimate interest;
                          Personal data can also be processed if it is necessary
                          for a legitimate interest of Rose-Bazaar. Legitimate
                          interests are generally of a legal (e.g. collection of
                          outstanding receivables) or commercial nature (e.g.
                          avoiding breaches of contract). Personal data may not
                          be processed for the purposes of a legitimate interest
                          if, in individual cases, there is evidence that the
                          interests of the data subject merit protection, and
                          that this takes precedence. Before data is processed,
                          it is necessary to determine whether there are
                          interests that merit protection.
                        </li>
                        <li>
                          Processing of highly sensitive data; Highly sensitive
                          personal data can be processed only if the law
                          requires this or the data subject has given express
                          consent. This data can also be processed if it is
                          mandatory for asserting, exercising or defending legal
                          claims regarding the data subject. If there are plans
                          to process highly sensitive data.
                        </li>
                        <li>
                          Automated individual decisions; Automated processing
                          of personal data that is used to evaluate certain
                          aspects cannot be the sole basis for decisions that
                          have negative legal consequences or could
                          significantly impair the data subject. The data
                          subject must be informed of the facts and results of
                          automated individual decisions and the possibility to
                          respond. To avoid erroneous decisions, a test and
                          plausibility check must be made by an employee.
                        </li>
                        <li>
                          User data and internet; If personal data is collected,
                          processed and used on websites or in apps, the data
                          subjects must be informed of this in a privacy
                          statement and, if applicable, information about
                          cookies. The privacy statement and any cookie
                          information must be integrated so that it is easy to
                          identify, directly accessible and consistently
                          available for the data subjects. If use profiles
                          (tracking) are created to evaluate the use of websites
                          and apps, the data subjects must always be informed
                          accordingly in the privacy statement. Personal
                          tracking may only be effected if it is permitted under
                          national law or upon consent of the data subject. If
                          tracking uses a pseudonym, the data subject should be
                          given the chance to opt out in the privacy statement.
                          If websites or apps can access personal data in an
                          area restricted to registered users, the
                          identification and authentication of the data subject
                          must offer sufficient protection during access.
                        </li>
                      </ul>
                    </li>
                    <li>
                      Employee data
                      <ul className="list-disc">
                        <li>
                          Data processing for the employment relationship; In
                          employment relationships, personal data can be
                          processed if needed to initiate, carry out and
                          terminate the employment agreement. When initiating an
                          employment relationship, the applicants’ personal data
                          can be processed. If the candidate is rejected,
                          his/her data must be deleted in observance of the
                          required retention period, unless the applicant has
                          agreed to remain on file for a future selection
                          process. Consent is also needed to use the data for
                          further application processes or before sharing the
                          application with other third parties. In the existing
                          employment relationship, data processing must always
                          relate to the purpose of the employment agreement if
                          none of the following circumstances for authorized
                          data processing apply. If it should be necessary
                          during the application procedure to collect
                          information on an applicant from a third party, the
                          requirements of the corresponding national laws must
                          be observed. In cases of doubt, consent must be
                          obtained from the data subject. There must be legal
                          authorization to process personal data that is related
                          to the employment relationship but was not originally
                          part of performance of the employment agreement. This
                          can include legal requirements, collective regulations
                          with employee representatives, consent of the
                          employee, or the legitimate interest of the company
                        </li>
                        <li>
                          Data processing pursuant to legal authorization; The
                          processing of personal employee data is also permitted
                          if national legislation requests, requires or
                          authorizes this. The type and extent of data
                          processing must be necessary for the legally
                          authorized data processing activity, and must comply
                          with the relevant statutory provisions. If there is
                          some legal flexibility, the interests of the employee
                          that merit protection must be taken into
                          consideration.
                        </li>
                        <li>
                          Collective agreements on data processing; If a data
                          processing activity exceeds the purposes of fulfilling
                          a contract, it may be permissible if authorized
                          through a collective agreement. Collective agreements
                          are pay scale agreements or agreements between
                          employers and employee representatives, within the
                          scope allowed under the relevant employment law. The
                          agreements must cover the specific purpose of the
                          intended data processing activity, and must be drawn
                          up within the parameters of national data protection
                          legislation.
                        </li>
                        <li>
                          Consent to data processing; Employee data can be
                          processed upon consent of the person concerned.
                          Declarations of consent must be submitted voluntarily.
                          Involuntary consent is void. The declaration of
                          consent must be obtained in writing or electronically
                          for the purposes of documentation. In certain
                          circumstances, consent may be given verbally, in which
                          case it must be properly documented. In the event of
                          informed, voluntary provision of data by the relevant
                          party, consent can be assumed if national laws do not
                          require express consent. Before giving consent, the
                          data subject must be informed in accordance with Data
                          Protection Policy principles.
                        </li>
                        <li>
                          Data processing pursuant to legitimate interest;
                          Personal data can also be processed if it is necessary
                          to enforce a legitimate interest of Rose-Bazaar.
                          Legitimate interests are generally of a legal (e.g.
                          filing, enforcing or defending against legal claims)
                          or financial (e.g. valuation of companies) nature.
                          Personal data may not be processed based on a
                          legitimate interest if, in individual cases, there is
                          evidence that the interests of the employee merit
                          protection. Before data is processed, it must be
                          determined whether there are interests that merit
                          protection. Control measures that require processing
                          of employee data can be taken only if there is a legal
                          obligation to do so or there is a legitimate reason.
                          Even if there is a legitimate reason, the
                          proportionality of the control measure must also be
                          examined. The justified interests of the company in
                          performing the control measure (e.g. compliance with
                          legal provisions and internal company rules) must be
                          weighed against any interests meriting protection that
                          the employee affected by the measure may have in its
                          exclusion, and cannot be performed unless appropriate.
                          The legitimate interest of the company and any
                          interests of the employee meriting protection must be
                          identified and documented before any measures are
                          taken.
                        </li>
                        <li>
                          Processing of highly sensitive data; Highly sensitive
                          personal data can be processed only under certain
                          conditions. Highly sensitive data is data about racial
                          and ethnic origin, political beliefs, religious or
                          philosophical beliefs, union membership, and the
                          health and sexual life of the data subject. Under
                          national law, further data categories can be
                          considered highly sensitive or the content of the data
                          categories can be filled out differently. The
                          processing must be expressly permitted or prescribed
                          under national law.
                        </li>
                        <li>
                          Automated decisions; If personal data is processed
                          automatically as part of the employment relationship,
                          and specific personal details are evaluated (e.g. as
                          part of personnel selection or the evaluation of
                          skills profiles), this automatic processing cannot be
                          the sole basis for decisions that would have negative
                          consequences or significant problems for the affected
                          employee. To avoid erroneous decisions, the automated
                          process must ensure that a natural person evaluates
                          the content of the situation, and that this evaluation
                          is the basis for the decision. The data subject must
                          also be informed of the facts and results of automated
                          individual decisions and the possibility to respond.
                        </li>
                        <li>
                          Telecommunications and internet; Telephone equipment,
                          e-mail addresses, intranet and internet along with
                          internal social networks are provided by the company
                          primarily for work-related assignments. They are a
                          tool and a company resource. They can be used within
                          the applicable legal regulations and internal company
                          policies. In the event of authorized use for private
                          purposes, the laws on secrecy of telecommunications
                          and the relevant national telecommunication laws must
                          be observed if applicable. There will be no general
                          monitoring of telephone and e-mail communications or
                          intranet/ internet use. To defend against attacks on
                          the IT infrastructure or individual users, protective
                          measures can be implemented for the connections to
                          Rose-Bazaar network that block technically harmful
                          content or that analyze the attack patterns. For
                          security reasons, the use of telephone equipment,
                          e-mail addresses, the intranet/internet and internal
                          social networks can be logged for a temporary period.
                          Evaluations of this data from a specific person can be
                          made only in a concrete, justified case of suspected
                          violations of laws or policies of Rose-Bazaar. The
                          evaluations can be conducted only by investigating
                          departments while ensuring that the principle of
                          proportionality is met. The relevant national laws
                          must be observed in the same manner as Rose-Bazaar
                          regulations.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </p>
                <p className="underline text-base font-medium">
                  Transmission of personal data
                </p>
                <p className="text-sm font-normal">
                  Transmission of personal data to recipients outside or inside
                  Rose-Bazaar is subject to the authorization requirements for
                  processing personal data. The data recipient must be required
                  to use the data only for the defined purposes. In the event
                  that data is transmitted to a recipient outside Rose-Bazaar to
                  a third country this country must agree to maintain a data
                  protection level equivalent to this Data Protection Policy.
                  This does not apply if transmission is based on a legal
                  obligation. If data is transmitted by a third party to a
                  Rose-Bazaar, it must be ensured that the data can be used for
                  the intended purpose.
                </p>
                <p className="underline text-base font-medium">
                  Rights of the data subject
                </p>
                <p className="text-sm font-normal">
                  Every data subject has the following rights. Their assertion
                  is to be handled immediately by the responsible unit and
                  cannot pose any disadvantage to the data subject.
                  <ul className="list-disc">
                    <li>
                      The data subject may request information on which personal
                      data relating to him/her has been stored, how the data was
                      collected, and for what purpose. If there are further
                      rights to view the employer’s documents (e.g. personnel
                      file) for the employment relationship under the relevant
                      employment laws, these will remain unaffected.
                    </li>
                    <li>
                      If personal data is transmitted to third parties,
                      information must be given about the identity of the
                      recipient or the categories of recipients.
                    </li>
                    <li>
                      If personal data is incorrect or incomplete, the data
                      subject can demand that it be corrected or supplemented.
                    </li>
                    <li>
                      The data subject can object to the processing of his or
                      her data for purposes of advertising or market/opinion
                      research. The data must be blocked from these types of
                      use.
                    </li>
                    <li>
                      The data subject may request his/her data to be deleted if
                      the processing of such data has no legal basis, or if the
                      legal basis has ceased to apply. The same applies if the
                      purpose behind the data processing has lapsed or ceased to
                      be applicable for other reasons. Existing retention
                      periods and conflicting interests meriting protection must
                      be observed.
                    </li>
                    <li>
                      The data subject generally has a right to object to
                      his/her data being processed, and this must be considered
                      if the protection of his/her interests takes precedence
                      over the interest of the data controller owing to a
                      particular personal situation. This does not apply if a
                      legal provision requires the data to be processed.
                    </li>
                  </ul>
                </p>
                <p className="underline text-base font-medium">
                  Confidentiality of processing
                </p>
                <p className="text-sm font-normal">
                  Personal data is subject to data secrecy. Any unauthorized
                  collection, processing, or use of such data by employees is
                  prohibited. Any data processing undertaken by an employee that
                  he/she has not been authorized to carry out as part of his/her
                  legitimate duties is unauthorized. The “need to know”
                  principle applies. Employees may have access to personal
                  information only as is appropriate for the type and scope of
                  the task in question. This requires a careful breakdown and
                  separation, as well as implementation, of roles and
                  responsibilities. Employees are forbidden to use personal data
                  for private or commercial purposes, to disclose it to
                  unauthorized persons, or to make it available in any other
                  way. Managers must inform their employees at the start of the
                  employment relationship about the obligation to protect data
                  secrecy. This obligation shall remain in force even after
                  employment has ended.
                </p>
                <p className="underline text-base font-medium">
                  Processing security
                </p>
                <p className="text-sm font-normal">
                  Personal data must be safeguarded from unauthorized access and
                  unlawful processing or disclosure, as well as accidental loss,
                  modification or destruction. This applies regardless of
                  whether data is processed electronically or in paper form.
                  Before the introduction of new methods of data processing,
                  particularly new IT systems, technical and organizational
                  measures to protect personal data must be defined and
                  implemented. These measures must be based on the state of the
                  art, the risks of processing, and the need to protect the
                  data. The responsible department can consult with its
                  Information Security Officer (ISO) and data protection
                  coordinator. The technical and organizational measures for
                  protecting personal data are part of Corporate Information
                  Security management and must be adjusted continuously to the
                  technical developments and organizational changes.
                </p>
                <p className="underline text-base font-medium">
                  Data protection control
                </p>
                <p className="text-sm font-normal">
                  Compliance with the Data Protection Policy and the applicable
                  data protection laws is checked regularly with data protection
                  audits and other controls. The performance of these controls
                  is the responsibility of the data protection coordinators, and
                  other company units with audit rights or external auditors
                  hired. The results of the data protection controls must be
                  reported to managing partners. Rose-Bazaar’s Board must be
                  informed of the primary results as part of the related
                  reporting duties. On request, the results of data protection
                  controls will be made available to the responsible data
                  protection authority. The responsible data protection
                  authority can perform its own controls of compliance with the
                  regulations of this Policy, as permitted under national law.
                </p>
                <p className="underline text-base font-medium">
                  Data protection incidents
                </p>
                <p className="text-sm font-normal">
                  All employees must inform their manager and data protection
                  coordinator immediately about cases of violations against this
                  Data Protection Policy or other regulations on the protection
                  of personal data. The manager responsible for the function or
                  the unit is required to inform the responsible data protection
                  coordinator immediately about data protection incidents. In
                  cases of improper transmission of personal data to third
                  parties, improper access by third parties to personal data, or
                  loss of personal data the required company reports
                  (Information Security Incident Management) must be made
                  immediately so that any reporting duties under national law
                  can be complied with.{" "}
                </p>
              </div>
              <div className="w-full flex justify-center pb-5">
                <button
                  onClick={() => handleClose()}
                  type="button"
                  className="w-[150px] h-12 px-8 py-2 rounded-lg border bg-red justify-center items-center inline-flex"
                >
                  <span className="text-white text-sm font-medium leading-[14px] tracking-wide">
                    Close
                  </span>
                </button>
              </div>
            </section>
          )}
          {i18n.language === "ar" && (
            <section className="">
              <div className="flex flex-col gap-2 mb-10">
                <div className="text-red text-lg font-bold text-center mb-5">
                  سياسة الخصوصية
                </div>
                <p className="underline text-base font-medium">
                  سياسة خصوصية موقع www.Rose-Bazaar.com
                </p>
                <p className="text-sm font-normal">
                  نسعد دائماً لزيارتكم في موقع Rose-Bazaar وفي البداية نطمئن
                  كافة زوار الموقع علي الحفاظ دائماً بخصوصياتك اثناء تصفح
                  المنتجات والخدمات المنشورة وأن سياسة الخصوصية الموجودة من خلال
                  هذه الصفحة لها أهمية كبيرة لنا وهي تمثل كافة المعلومات الهامة
                  حول الحفاظ علي المعلومات الشخصية التي نستخدمها في الموقع
                  ويستخدمها معلنين Rose-Bazaar .
                </p>
                <p className="underline text-base font-medium">
                  ملفات الدخول عبر Rose-Bazaar
                </p>
                <p className="text-sm font-normal">
                  نستخدم من خلال موقع Rose-Bazaar.com مثل جميع المواقع المتواجدة
                  علي الشبكة العنكبوتية ملفات تسجيل الدخول والتصفح مثل : نوع
                  المتصفح المستخدم ومزود خدمة الانترنت في البلد وتاريخ تسجيل
                  الدخول وأيضا التوجه في صفحات الموقع من اجل تحليل شامل يهم في
                  احصائيات الموقع . نستخدم هذه المعلومات لأغراض تحسين شبكة
                  Rose-Bazaar فقط وليس الاحتيال علي المعلومات الشخصية لزوار
                  الموقع الكرام وننوه علي الحفاظ من قبل إدارة الموقع لكافة
                  المعلومات المتواجدة وهي سرية تماماً نستخدمها فقط من اجل تحسين
                  الخدمات وجودة المحتوى المعروض علي صفحات الشبكة .
                </p>
                <p className="underline text-base font-medium">
                  استخدام الكوكيز واعدادات الشبكة
                </p>
                <p className="text-sm font-normal">
                  يستخدم موقع Rose-Bazaar.com تخزين الكوكيز من اجل تحسين طبيعة
                  التصفح من خلال موقعنا عبر انشاء سجل خاص لكل مستخدم يوجد به
                  معلومات محددة فقط تم تسجيلها عبر الصفحات او المقالات التي تم
                  الوصول اليها ومن خلال هذه المعلومات نضع لكم جودة مميزة
                  ومعلومات ومقالات تهم الزائر الكريم ومن خلال تخزين ملفات
                  الكوكيز يستطيع الزائر التصفح بحرية كاملة وبسرعة عالية .
                </p>
                <p className="underline text-base font-medium">
                  شركات الإعلانات المستخدمة في الموقع
                </p>
                <p className="text-sm font-normal">
                  بخصوص الإعلانات علي موقع Rose-Bazaar.com نستخدم العديد من
                  الشركات الكبري في مجال الإعلانات علي الشبكة العنكبوتية ومن
                  ابرزهم خدمة جوجل ادسنس المقدمة من محرك البحث الشهير (جوجل)
                  لذالك نضع لكم بعض الأرقام والمعلومات الهامة عن طريقة عمل
                  الإعلانات في موقع Rose-Bazaar :
                  <ul className="list-decimal">
                    <li>
                      شركة جوجل تستخدم تقنيات عالية ومن أهمها ملف تعريف ( DART )
                      يساعد في تحسين جودة الإعلانات المعروضة علي صفحات الموقع
                      وتستخدم المعايير الخاصة بها للزوار بشكل عام .
                    </li>
                    <li>
                      خدمة Google AdSense هي خدمة إعلانية مقدمة من محرك البحث
                      جوجل وهي طرف ثالث يحق لها استخدام سياسة الخصوصية لموقع
                      Rose-Bazaar ومن اهم الاستخدامات لها الكوكيز من اجل تقديم
                      الخدمة الاعلانية بشكل مناسب للزوار وأيضا قياس وتقييم
                      فاعلية الخدمة ، كما نوضح ان خدمة Google AdSense لها الحق
                      بناء علي الاتفاقيات المبرمة معنا في استخدام وسائل عديدة من
                      أهمها (الكوكيز ، عدادات الشبكة ، و اكواد برمجية خاصة ،
                      جافا سكربت) من اجل تحسين خدمة الإعلانات وتقييم مدي أهميتها
                      للمعلن ولموقع Rose-Bazaar .
                    </li>
                    <li>
                      يمكنك عزيزي الزائر في أي وقت إلغاء استخدام ملفان تعريف
                      الارتباط (DART) من خلال زيارة هذه الصفحة الخاصة بخدمة جوجل
                      الاعلانية: google.com/policies/technologies/ads .
                    </li>
                  </ul>
                  تعرض هذه الصفحة سياسة الخصوصية الخاصة بشركة جوجل التي تقدم
                  خدمات (Google AdSense) وهي الطرف الثالث المشارك في سياسة
                  الخصوصية علي موقع Rose-Bazaar.com والتعرف بشكل كامل علي حقوقها
                  في استخدام خوادم الشبكات ومعرفة المعلومات الهامة عن ممارساتها
                  وانشتها المختلفة . لا يقدم موقع Rose-Bazaar.com أي معلومات غير
                  هامة او تهم الزئر الى شركات الخدمات الاعلانية المسجلة (طرف
                  ثالث) دون موافقتك عليها ويمكنك بكل سهولة قبول او عدم قبول هذه
                  الخدمة من خلال اعدادات المتصفح الخاص بك في أي وقت . نوضح في
                  موقع Rose-Bazaar علي الحفاظ بشكل كامل علي حقوق المستخدم للموقع
                  من كافة المعلومات ونوضح أيضا في حالة وجود شكوي او مشكلة تخص
                  سياسة الخصوصية الخاصة بنا يمكنك زيارة هذه الصفحة : <Link to={'/contact'}>  أتصل بنا</Link> 
                </p>
              </div>
              <div className="w-full flex justify-center pb-5">
                <button
                  onClick={() => handleClose()}
                  type="button"
                  className="w-[150px] h-12 px-8 py-2 rounded-lg border bg-red justify-center items-center inline-flex"
                >
                  <span className="text-white text-sm font-medium leading-[14px] tracking-wide">
                    {t("Close")}
                  </span>
                </button>
              </div>
            </section>
          )}
        </div>
      </Box>
    </Modal>
  );
}

export default PrivacyPolicy;
