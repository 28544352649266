import React ,{useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import cartHeader from "../../assets/images/shop/cart.png";
import bag from "../../assets/images/shop/icons/bag-2.svg";
import wallet from "../../assets/images/shop/icons/wallet.svg";
import ticket from "../../assets/images/shop//icons/ticket-2.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Progress } from 'flowbite-react';
import axios from "axios";
import {cartActions} from "../../store/slicer/cartSlice";
import {toast} from "react-toastify";
import useAxiosUserSecureConfig from "../../hooks/useAxiosUserSecureConfig";
import Loader from "../../components/Loader";
import {faBagShopping, faCreditCard, faTrashCan, faWallet} from "@fortawesome/free-solid-svg-icons";
import { IoPricetagOutline } from "react-icons/io5";
import {totalItems} from "../../utils/utils";
import { useNavigate} from "react-router-dom";
import placholder_image from "../../assets/images/booking/placeholder-image.jpg"
import {useTranslation} from "react-i18next";


function Cart() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const customTheme = {
        color: {
          red: 'bg-red',
        },
      };

    const cart = useSelector((state) => state.cart);
    const dispatch = useDispatch();
    const config = useAxiosUserSecureConfig();

    const handleAddToCart = (product) => {
        // dispatch(cartActions.setLoading(true));
        axios.post("/api/add_to_cart", {"product_id": product.id,}, config)
            .then(() => {
                axios.post("/api/cart", {}, config) 
                    .then((res) => {
                        dispatch(cartActions.setCart(res.data.result));
                        dispatch(cartActions.setLoading(false));
                        // dispatch(uiActions.stopLoading());
                    })

                toast.success(t("Cart Updated!"));
            })
            .catch((err) => {
                console.log(err)
                // dispatch(uiActions.stopLoading());
                dispatch(cartActions.setLoading(false));
                toast.error(t("Error While Updating Cart!"));
            })

    };
    const handleDecreaseCart = (product, currentQty) => {
        // dispatch(cartActions.setLoading(true));
        if (currentQty === 1) {
            handleRemoveFromCart(product);
        } else {
            // dispatch(cartActions.setLoading(true));
            axios.post("/api/set_cart_item_qty", {"product_id": product.id, "qty": currentQty - 1}, config)
                .then(() => {
                    axios.post("/api/cart", {}, config)
                        .then((res) => {
                            dispatch(cartActions.setCart(res.data.result));
                            dispatch(cartActions.setLoading(false));
                            // dispatch(uiActions.stopLoading());
                        });
    
                    toast.success(t("Cart Updated!"));
                })
                .catch((err) => {
                    console.log(err);
                    // dispatch(uiActions.stopLoading());
                    dispatch(cartActions.setLoading(false));
                    toast.error("Error While Updating Cart!");
                });
        }
    };
    const handleRemoveFromCart = (product) => {
        // dispatch(cartActions.setLoading(true));
        axios.post("/api/remove_from_cart", {"product_id": product.id}, config)
            .then(() => {
                axios.post("/api/cart", {}, config)
                    .then((res) => {
                        dispatch(cartActions.setCart(res.data.result));
                        dispatch(cartActions.setLoading(false));
                        // dispatch(uiActions.stopLoading());
                    })

                toast.success(t("Cart Updated!"));
            })
            .catch((err) => {
                console.log(err)
                // dispatch(uiActions.stopLoading());
                dispatch(cartActions.setLoading(false));
                toast.error(t("Error While Updating Cart!"));
            })
    };


  return (
    <>
    <section className="relative">
        <img className="w-full" src={cartHeader} alt='home'/>
        <div className="flex flex-col sm:gap-4 absolute bottom-[20%] px-5 xl:px-10">
                    <p className="text-[#D9D9D9] sm:text-xl font-normal sm:leading-[48px]">{t("Shop > Shopping Cart")}</p>
                    <h1 className="text-white text-2xl sm:text-[40px] font-medium font-['Roboto'] sm:leading-[44px]">{t("Shopping Cart")}</h1>
        </div>
    </section>
    <header className="py-7 px-5 bg-orange-50 justify-center items-center gap-2 sm:gap-4 inline-flex">
        <button className="flex items-center gap-2">
            <div className="bg-red rounded-[100px] justify-center items-center gap-2 inline-flex"><FontAwesomeIcon className="text-sm text-white p-2" icon={faBagShopping} /></div>
            <p className="text-gray-dark text-sm font-medium leading-[21px]">{t("Shopping Cart")}</p>
        </button>
        <hr className="w-[85.50px] h-px bg-[#C3D2CC]"/>
        <button onClick={()=> totalItems(cart.items) > 0 ? navigate("/shipping") : ''} className="flex items-center gap-2">
            <div className="bg-white rounded-[100px] justify-center items-center gap-2 inline-flex"><FontAwesomeIcon className="text-sm text-red p-2" icon={faCreditCard} /></div>
            <p className="text-zinc text-sm font-medium leading-[21px]">{t("Checkout")}</p>
        </button>
        <hr className="w-[85.50px] h-px bg-[#C3D2CC]"/>
        <button className="flex items-center gap-2">
            <div className="bg-white rounded-[100px] justify-center items-center gap-2 inline-flex"><FontAwesomeIcon className="text-sm text-red p-2" icon={faWallet} /></div>
            <p className="text-zinc text-sm font-medium leading-[21px]">{t("Order Complete")}</p>
        </button>
    </header>
    {cart.loading && <Loader/>}
    {!cart.loading &&
    <section className="px-5 xl:px-20 py-12">
        <div className="lg:flex gap-10 xl:gap-12 items-start">
            <div className="cart lg:w-2/3 mb-5 lg:mb-0">
                <div className="pb-4 gap-2 items-center inline-flex">
                    <div className="text-red text-2xl font-medium leading-9">{t("Your Cart")}</div>
                    <div className="text-zinc text-2xl leading-normal">({totalItems(cart.items)})</div>
                </div>
                <div className="rounded-[20px] border ">
                {cart.items && cart.items.map((item) => (
                    <div className="flex flex-row justify-between hover:bg-gray-100  px-2 sm:px-6 py-5">
                      <div className="flex  gap-4  ">
                        <div className="w-28 h-28 flex justify-center items-center">
                          <img
                          className="w-full h-full object-cover rounded-[20px]"
                            src={item.product.image || placholder_image}
                            alt={item.name}
                          />
                        </div>
                        <div className="flex justify-between">
                            <div className="flex flex-col gap-1 md:gap-10">
                              <div className="flex flex-col">
                                <p className="text-black sm:text-xl font-medium mb-[6px]">{item.product.name}</p>
                              {/*<div className="flex gap-4">*/}
                              {/*    <p><sapn className="text-black text-base font-normal">Size: </sapn> <span className="text-black text-opacity-60 text-base font-normal">Large</span></p>*/}
                              {/*    <p><sapn className="text-black text-base font-normal">Color: </sapn> <span className="text-black text-opacity-60 text-base font-normal">Olive</span></p>*/}
                              {/*</div>*/}

                              </div>
                              <div className="flex items-center px-2 sm:px-5 py-1 border rounded-lg w-fit">
                                <button className="text-gray-500 font bold text-xl " onClick={() => handleDecreaseCart(item.product, item.qty)}>-</button>
                                <div  className="mx-1 sm:mx-3 w-9 h-9  justify-center items-center gap-3 inline-flex">{item.qty}</div>
                                <button disabled={item.product.available_quantity === 0} className={`text-gray-500 font bold text-xl ${item.product.available_quantity === 0 && "opacity-30"}`} onClick={() => handleAddToCart(item.product)}>+</button>
                              </div>
                            </div>
                        </div>
                      </div>
                      <div className="flex flex-col justify-between items-end">
                        <button
                            onClick={() => handleRemoveFromCart(item.product)}
                            className=""
                            ><FontAwesomeIcon className="text-[#FF3333]" icon={faTrashCan} />
                        </button>
                        <div className={`text-black sm:text-xl flex ${i18n.language === 'en' ? 'flex-row' : 'flex-row-reverse'} ${i18n.language === 'en' ? ' ml-[-100px] ' : ' mr-[-100px] '}  font-medium whitespace-nowrap `}>
                        <div> {item.product.value}</div>
                       <div>  {cart.currency}</div>
                        </div>
                      </div>
                  </div>
                ))}
                </div>
            </div>
            <div className="checkout lg:w-1/3">
                <div className="text-red text-2xl font-medium leading-9 mb-4">{t("Order Summary")}</div>
                <div className="w-full p-6 rounded-2xl border  flex-col justify-start items-start gap-5 inline-flex">
                <div className="w-full flex flex-col gap-4 border-b pb-5">
                    <div className="w-full justify-between items-center inline-flex">
                        <div className="text-zinc text-sm font-normal leading-[21px]">{t("Subtotal")}</div>
                        <div className={` flex  ${i18n.language === 'en' ? 'flex-row' : 'flex-row-reverse'} `}>
                        <div className="text-gray-dark text-sm font-normal leading-[21px]">{cart.total}</div>
                        <div className="text-gray-dark text-sm font-normal leading-[21px]">  {cart.currency}</div>
                    </div>
                    </div>
                    {/* <div className="w-full justify-between items-center inline-flex">
                        <div className="text-zinc text-sm font-normal leading-[21px]">Delivery Fee</div>
                        <div className="text-gray-dark text-sm font-normal leading-[21px]">{cart.shipping} {cart.currency}</div>
                    </div>
                    <div className="w-full justify-between items-center inline-flex">
                      <div className="text-zinc text-sm font-normal leading-[21px]">Taxes Fee</div>
                      <div className="text-gray-dark text-sm font-normal leading-[21px]">{cart.taxes} {cart.currency}</div>
                    </div> */}
                </div>
                <div className="w-full flex flex-col gap-4">
                <div className="w-full justify-between items-center inline-flex">
                    <div className="text-black text-2xl font-medium">{t("Total")}</div>
                        <div className={` flex  ${i18n.language === 'en' ? 'flex-row' : 'flex-row-reverse'} `}>
                        <div className="text-black text-2xl font-medium ">{cart.total}</div>
                        <div className="text-black text-2xl font-medium ">  {cart.currency}</div>
                    </div>
                </div>
                {/* <div className="w-full flex gap-4 items-center ">
                   <div className="relative w-full">
                   <input placeholder="Add promo code" className="w-full h-12 px-12 py-1 bg-[#F0F0F0] rounded-lg  justify-start items-center gap-2 inline-flex"/>
                    <IoPricetagOutline className="text-xl absolute left-4 top-4" />
                   </div>
                    <button className="h-12 px-6 py-[7px] bg-white rounded-[10px] shadow border border-red justify-center items-center gap-2.5 inline-flex">
                        <span className="text-center text-red text-base font-normal leading-normal">Apply </span>
                    </button>
                </div> */}
                </div>
                <button onClick={()=> totalItems(cart.items) > 0 ? navigate("/shipping") : ''} className={`${totalItems(cart.items) === 0 && "opacity-50"} w-full px-6 py-3 bg-red rounded-[10px] shadow justify-center items-center gap-2.5 inline-flex`}>
                    <span className="text-center text-white text-base font-normal leading-normal">{t("Proceed to checkout")}</span>
                </button>
                {/* <div className="w-100">
                    <Progress progress={75} size="sm" theme={customTheme} color="red"/>
                </div>
                <div className="w-full flex-col justify-start items-start gap-1.5 inline-flex">
                    <div><span className="text-zinc-500 text-sm font-normal leading-[21px]">Get </span><span className="text-zinc-500 text-sm font-medium leading-[21px]">Free </span><span className="text-gray-dark text-sm font-medium leading-[21px]">Shipping</span><span className="text-gray-dark text-sm font-normal leading-[21px]"> </span><span className="text-zinc-500 text-sm font-normal leading-[21px]">for orders over</span><span className="text-gray-dark text-sm font-normal leading-[21px]"> </span><span className="text-red-600 text-sm font-normal leading-[21px]">500 EGP </span></div>
                    <div className="text-gray-dark text-sm font-medium underline leading-[21px]">Continue Shopping</div>
                </div> */}
            </div>

            </div>
        </div>
    </section>
    }
    </>
  );
};
export default Cart;