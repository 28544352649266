import React, { useEffect, useState } from 'react';
import { useTable, useSortBy, usePagination } from 'react-table';
import ReactPaginate from 'react-paginate';
import useAxiosUserSecureConfig from '../../../hooks/useAxiosUserSecureConfig';
import axios from 'axios';
import { toast } from 'react-toastify';
import Loader from '../../../components/Loader';
import { useNavigate } from 'react-router';
import eyeIcon from "../../../assets/images/icons/eye.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";



function BookingList () {
  const [currentPage, setCurrentPage] = useState(0);
  const config = useAxiosUserSecureConfig();
  const [bookingList, setBookingList] = useState({ loading: true, data: [] });
  const [filter, setFilter] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const { t, i18n } = useTranslation();
  const handleFilterChange = (value) => {
    setFilter(value);
    setShowDropdown(false);
  };
  const columns = React.useMemo(
    () => [
      { Header: t('Booking ID'), accessor: 'bookingId' },
      { Header: t('Client Name'), accessor: 'clientName' },
      { Header: t('Client Mobile Number'), accessor: 'clientMobileNumber' },
      { Header: t('Payment Amount'), accessor: 'totalPrice' },
      { Header: t('Booking Date'), accessor: 'bookingDate' },
      { Header: t('Booking Status'), accessor: 'status' },
    ] ,[t]
  );
  // columns.map(entry => ({
  //   Header: t(entry.Header),
  //   accessor: entry.accessor
  //   }))

axios.defaults.timeout = 200000
axios.defaults.timeoutErrorMessage='timeout'
const fetchData = () => {
  axios.post('/api/bookings', {}, config)
    .then((res) => {
      console.log("bookings list", res.data);
      if (!res.data?.result?.success) {
        toast.error("Error while fetching bookings list!");
        return;
      }
      setBookingList({
        loading: false,
        data: res.data?.result?.bookings?.slice().reverse() || [],
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
useEffect(() => {
  fetchData();
}, []);

  const reqestsData = React.useMemo(() => {
    return bookingList.data.length > 0
      ? bookingList.data
          .map((key) => ({
            id: key.id,
            bookingId: key.id,
            bookingDate: key.date,
            totalPrice:  key.services.reduce((total, service) => total + service.list_price, 0),
            clientName: key.client,
            clientMobileNumber: key.client_mobile_number ,
            status :key.state
          }))
      : [];
  }, [bookingList.data]);

  const filteredRows = React.useMemo(() => {
    return filter ? reqestsData?.filter((row) => row.status === filter) : reqestsData;
  }, [filter, reqestsData]);
  console.log("reqestsData",reqestsData)
  console.log("filteredRows",filteredRows)
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data: filteredRows,
      initialState: { pageIndex: currentPage, pageSize:  20 }, 
    },
    useSortBy,
    usePagination
  );

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const navigate = useNavigate()

  const handleClick = (rowData) => {
    navigate(`/serviceProvider/booking-list/${rowData.id}`);
  };

  return (
    <section className="px-5 xl:px-20 py-10">
      {bookingList.loading && <Loader slim />}
      {!bookingList.loading && (
      <>
      <div className="justify-start items-center gap-2  hidden sm:inline-flex mb-4 sm:mb-2">
          <div
           onClick={() => navigate('/serviceProvider/account')}
           className="text-zinc-400 text-sm sm:text-base font-normal sm:leading-[48px] hover:text-gray-dark cursor-pointer"
         >
           {t("Service Provider Account")}
          </div>
          <FontAwesomeIcon
            className="text-zinc-400 text-sm sm:text-base"
            icon={faAngleRight}
          />
          <div
            onClick={() => navigate( '/serviceProvider/booking-list')}
            className="text-zinc-400 text-sm sm:text-base font-normal sm:leading-[48px] hover:text-gray-dark cursor-pointer"
          >
           {t("Booking List")}
          </div>
          </div>
        <div className="flex justify-between items-center mb-8">
          <div className="justify-start items-center gap-2 inline-flex">
            <div className="text-base sm:text-2xl font-semibold leading-9">{t("Booking List")}</div>
            <div className="text-base font-light leading-normal">
              ({reqestsData.length})
            </div>
          </div>
          <div className="relative inline-block">
            <button
              className="px-3 sm:px-6 py-3 rounded-lg border border-red justify-center items-center flex gap-2"
              onClick={() => setShowDropdown(!showDropdown)}
            >
              <span className="text-red text-sm sm:text-base font-medium leading-none tracking-wide">
                {t("Filter by status")}
              </span>
              <FontAwesomeIcon
                className="text-red text-base font-medium leading-none tracking-wide"
                icon={faCaretDown}
              />
            </button>
            {showDropdown && (
              <ul className="absolute left-0 mt-2 py-2 w-48 bg-white border border-gray-200 rounded-lg shadow-lg z-10">
                <li
                  className="cursor-pointer px-4 py-2 hover:bg-gray-100"
                  onClick={() => handleFilterChange(null)}
                >
                  {t("Show All")}
                </li>
                <li
                  className="cursor-pointer px-4 py-2 hover:bg-gray-100"
                  onClick={() => handleFilterChange("accepted")}
                >
                  {t("Accepted")}
                </li>
                <li
                  className="cursor-pointer px-4 py-2 hover:bg-gray-100"
                  onClick={() => handleFilterChange("new")}
                >
                  {t("Hold")}
                </li>
                <li
                  className="cursor-pointer px-4 py-2 hover:bg-gray-100"
                  onClick={() => handleFilterChange("cancelled")}
                >
                  {t("Cancelled")}
                </li>
                <li
                  className="cursor-pointer px-4 py-2 hover:bg-gray-100"
                  onClick={() => handleFilterChange("rejected")}
                >
                  {t("Rejected")}
                </li>
              </ul>
            )}
          </div>
        </div>
        <div className='!overflow-x-auto'>
        <table
          {...getTableProps()}
          className="w-full table-auto border-collapse mb-10"
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                className="h-12 bg-slate-100"
                {...headerGroup.getHeaderGroupProps()}
              >
                <th className="py-2 px-4 border text-gray-500 text-base font-medium leading-snug">
                  {"#"}
                </th>{" "}
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={`py-2 px-4 border text-gray-500 text-base font-medium leading-snug ${
                      column.isSorted
                        ? column.isSortedDesc
                          ? "sort-desc"
                          : "sort-asc"
                        : ""
                    }`}
                  >
                    {column.render("Header")}
                  </th>
                ))}
                <th className="py-2 px-4 border text-gray-500 text-base font-medium leading-snug">
                  {t("Actions")}
                </th>
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows
              .slice(currentPage * 20, (currentPage + 1) * 20)
              .map((row, index) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    className="border-t h-12 cursor-pointer hover:bg-slate-100"
                     onClick={() => handleClick(row.original)}
                  >
                    <td className="px-4 border text-center bg-slate-100 text-gray-500 text-xs font-light leading-none">
                      {index + 1 + currentPage *20}
                    </td>{" "}
                    {row.cells.map((cell, cellIndex) => (
                      <td
                        {...cell.getCellProps()}
                        className="px-4 border text-center text-[#697077] text-sm font-normal leading-tight"
                        key={cellIndex}
                      >
                        {cell.column.id === "totalPrice" ? ( // Replace 'yourProperty' with the actual property name
                          `${cell.value}`
                        ) : cell.column.id === "status" ? (
                          cell.value === "accepted" ? (
                            <div className="px-2.5 py-[3px] rounded-[100px] border border-[#1A7F37] flex-col justify-start items-start gap-2.5 inline-flex">
                              <div className="text-center text-[#1A7F37] text-xs font-semibold font-['SF Pro Text'] leading-[18px]">
                              {t("Accepted")}
                              </div>
                            </div>
                          ) : cell.value === "new" ? (
                            <div className="px-2.5 py-[3px] rounded-[100px] border border-[#9A6700] flex-col justify-start items-start gap-2.5 inline-flex">
                              <div className="text-center text-[#9A6700] text-xs font-semibold font-['SF Pro Text'] leading-[18px]">
                                {t("Hold")}
                              </div>
                            </div>
                          ) : cell.value === "cancelled" ? (
                            <div className="px-2.5 py-[3px] rounded-[100px] border border-gray-dark flex-col justify-start items-start gap-2.5 inline-flex">
                              <div className="text-center text-gray-dark text-xs font-semibold font-['SF Pro Text'] leading-[18px]">
                              {t("Cancelled")}
                              </div>
                            </div>
                          ) : cell.value === "rejected" ? (
                            <div className="px-2.5 py-[3px] rounded-[100px] border border-[#CF222E] flex-col justify-start items-start gap-2.5 inline-flex">
                              <div className="text-center text-[#CF222E] text-xs font-semibold font-['SF Pro Text'] leading-[18px]">
                              {t("Rejected")}
                              </div>
                            </div>
                          ) : (
                            cell.render("Cell")
                          )
                        ) : (
                          cell.render("Cell")
                        )}
                      </td>
                    ))}
                    <td className="px-2 border text-center">
                      <button onClick={() => handleClick(row.original)} className="">
                        <img src={eyeIcon} alt="eyeIcon" />
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        </div>
        <ReactPaginate
          breakLabel="..."
          nextLabel={t("Next >")}
          onPageChange={handlePageClick}
          pageRangeDisplayed={Math.ceil(rows.length / 20)}
          pageCount={Math.ceil(rows.length /20)}
          previousLabel={t("< Previous")}
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          pageLinkClassName="page-num"
          previousLinkClassName="page-btn"
          nextLinkClassName="page-btn"
          activeLinkClassName="active"
          disabledLinkClassName="disabled-btn"
        />
      </>
      )}
    </section>
  );
};

export {BookingList as ProviderBookingList};