import React, { useEffect, useState } from "react";
import { AiFillStar } from "react-icons/ai";
import {  useLocation } from "react-router-dom";
import Loader from "../../components/Loader";
import { useNavigate } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import useAxiosUserSecureConfig from "../../hooks/useAxiosUserSecureConfig";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from 'swiper/modules';
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import {useTranslation} from "react-i18next";

function BookingRequestsDetails() {
  const { t, i18n } = useTranslation();
  const [bookingItem, setBookingItem] = useState({ loading: true, data: [null] });
  
  const location = useLocation();

  const navigate = useNavigate()

  useEffect(() => {
    const bookingRequestData = location.state?.data;
    console.log('Data from previous page:', bookingRequestData);
    setBookingItem((prevState) => ({
      ...prevState,
      loading: false,
      data: bookingRequestData
    }));
  }, [location.state]);

  const config = useAxiosUserSecureConfig();
  const [includedServicesImages, setIncludedServicesImages] = useState([]);
  const [optionalServicesImages, setOptionalServicesImages] = useState([]);

  useEffect(() => {
    const fetchServicesImages = async () => {
      try {
        const includedServicesImages = await Promise.all(
          bookingItem.data.venue.included_services.map((service) =>
            axios.post(`/api/product-category/${service.category.id}`, {}, config)
          )
        );
        const optionalServicesImages = await Promise.all(
          bookingItem.data.reservationLines.map((service) =>
            axios.post(`/api/product-category/${service.service.category.id}`, {}, config)
          )
        );
        setIncludedServicesImages(includedServicesImages);
        setOptionalServicesImages(optionalServicesImages);
      } catch (error) {
        console.error("Error fetching services images:", error);
      }
    };
  
    if (bookingItem.data.venue?.included_services?.length > 0 && bookingItem.data.venue?.optional_services?.length > 0) {
      fetchServicesImages();
    }
  }, [bookingItem.data]);
console.log("res",bookingItem)
console.log("includedServicesImages",includedServicesImages)


  return (
    <section className="md:px-10 px-4 py-8">
      <div className="justify-start items-center gap-2 hidden sm:inline-flex mb-2">
        <div
          onClick={() => navigate("/vendor/account")}
          className="text-zinc-400 text-base font-normal leading-[48px] hover:text-gray-dark cursor-pointer"
        >
          {t("My Account")}
        </div>
        <FontAwesomeIcon
          className="text-zinc-400 text-base"
          icon={faAngleRight}
        />
        <div
          onClick={() => navigate("/vendor/booking-list")}
          className="text-zinc-400 text-base font-normal leading-[48px] hover:text-gray-dark cursor-pointer"
        >
          {t("Booking List")}
        </div>
        <FontAwesomeIcon
          className="text-zinc-400 text-base"
          icon={faAngleRight}
        />
        <div className="text-zinc-400 text-base font-normal leading-[48px] hover:text-gray-dark cursor-pointer">
          {t("Details")}
        </div>
      </div>
      <p className="text-red text-2xl font-medium mb-6">{t("Booking Details")}</p>
      {bookingItem.loading && <Loader slim />}
      {!bookingItem.loading && (
        <form className="p-6 bg-zinc-100 rounded-2xl">
          <div className="mb-8 xl:flex gap-8">
            <div className="xl:w-1/4 md:w-[40%]">
              <div className="mb-4">
                <img
                  className="w-full h-[250px] rounded-[10px] object-cover"
                  src={bookingItem.data.venue.image}
                  alt=""
                />
              </div>
              <div className="flex flex-col gap-4">
                <div className="justify-between items-center inline-flex">
                  <span className="text-center text-zinc-800 text-base font-light tracking-tight">
                    {t("Request ID")}:
                  </span>
                  <span className="text-center text-red-800 text-base font-medium tracking-tight">
                    {bookingItem.data.bookingCode}
                  </span>
                </div>
              </div>
            </div>
            <div className="xl:w-3/4">
              <div className="flex justify-between mb-4">
                <div className="flex  flex-col gap-3 md:flex-row md:gap-5 ">
                  <p className="text-gray-dark text-xl md:text-[32px] font-medium uppercase ">
                    {bookingItem.data.venue.name}
                  </p>  
                </div>
                <div className="justify-start items-center gap-2 inline-flex">
                  <div className=" justify-center items-center gap-2 flex">
                    <span className="text-red text-base font-normal leading-normal">
                      {t("Reviews")}
                    </span>
                    <span className="text-neutral-400 text-sm font-normal leading-[21px]">
                      ({bookingItem.data.venue.reviewsCount})
                    </span>
                  </div>
                  <div className="justify-start items-center gap-1.5 flex">
                    <AiFillStar className="w-4 h-4 text-[#FFC200]" />
                    <p className="text-zinc-950 text-base font-medium leading-normal">
                      {Math.round(bookingItem.data.venue.rating * 10) / 10}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-1">
              <div className="justify-start items-center gap-3 inline-flex">
                  <div className="text-zinc-800 text-lg font-normal font-['Roboto'] leading-7">
                    {t("Event Date")}
                  </div>
                  <div className="text-red text-lg font-bold font-['Roboto'] leading-[33.60px]">
                  {bookingItem.data.date}
                  </div>
                </div>
                <div className="justify-start items-center gap-3 inline-flex">
                  <div className="text-zinc-800 text-lg font-normal font-['Roboto'] leading-7">
                    {t("Venue Price")}
                  </div>
                  <div className="text-red text-lg font-bold font-['Roboto'] leading-[33.60px]">
                    {bookingItem.data.venue.minPrice} EGP
                  </div>
                </div>
                <div className="justify-start items-center gap-3 inline-flex">
                  <div className="text-zinc-800 text-lg font-normal font-['Roboto'] leading-7">
                    {t("Booking Date")}
                  </div>
                  <div className="text-red text-lg font-bold font-['Roboto'] leading-[33.60px]">
                    {new Date(bookingItem.data.booking_date).toLocaleDateString()}
                  </div>
                </div>
                {bookingItem.data.payment_date &&<div className="justify-start items-center gap-3 inline-flex">
                  <div className="text-zinc-800 text-lg font-normal font-['Roboto'] leading-7">
                    {t("Payment Type")}
                  </div>
                  <div className="text-red text-lg font-bold font-['Roboto'] leading-[33.60px]">
                    {bookingItem.data.payment_type}
                  </div>
                </div>}
                {bookingItem.data.payment_date && <div className="justify-start items-center gap-3 inline-flex">
                  <div className="text-zinc-800 text-lg font-normal font-['Roboto'] leading-7">
                    {t("Payment Date")}
                  </div>
                  <div className="text-red text-lg font-bold font-['Roboto'] leading-[33.60px]">
                    {bookingItem.data.payment_date}
                  </div>
                </div>}
                <div className="justify-start items-center gap-3 inline-flex">
                  <div className="text-zinc-800 text-lg font-normal font-['Roboto'] leading-7">
                    {t("Payment Amount")}
                  </div>
                  <div className="text-red text-lg font-bold font-['Roboto'] leading-[33.60px]">
                    {bookingItem.data.initial_payment_amount} EGP
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mb-8">
            <div class="grid md:grid-cols-2 md:gap-6 mb-6">
              <div class="relative z-0 w-full mb-5 group">
                <input
                  value={bookingItem.data.brideName}
                  type="text"
                  name="floating_first_name"
                  id="floating_first_name"
                  class="block pb-3 pt-5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=""
                  required
                  disabled
                />
                <label
                  for="floating_first_name"
                  class="peer-focus:font-medium absolute duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 text-zinc-800 text-lg font-normal leading-snug"
                >
                  {t("Bride Name")}:
                </label>
              </div>
              <div class="relative z-0 w-full mb-5 group">
                <input
                  value={bookingItem.data.groomName}
                  type="text"
                  name="floating_first_name"
                  id="floating_first_name"
                  class="block pb-3 pt-5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=""
                  required
                  disabled
                />
                <label
                  for="floating_first_name"
                  class="peer-focus:font-medium absolute duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 text-zinc-800 text-lg font-normal leading-snug"
                >
                  {t("Groom Name")}:
                </label>
              </div>
            </div>
            <div class="grid md:grid-cols-2 md:gap-6 mb-6">
              <div class="relative z-0 w-full mb-5 group">
                <input
                  value={bookingItem.data.phone}
                  type="text"
                  name="floating_first_name"
                  id="floating_first_name"
                  class="block pb-3 pt-5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=""
                  required
                  disabled
                />
                <label
                  for="floating_first_name"
                  class="peer-focus:font-medium absolute duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 text-zinc-800 text-lg font-normal leading-snug"
                >
                  {t("Phone Number")}:
                </label>
              </div>
              <div class="relative z-0 w-full mb-5 group">
                <input
                  value={bookingItem.data.email}
                  type="text"
                  name="floating_first_name"
                  id="floating_first_name"
                  class="block pb-3 pt-5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=""
                  required
                  disabled
                />
                <label
                  for="floating_first_name"
                  class="peer-focus:font-medium absolute duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 text-zinc-800 text-lg font-normal leading-snug"
                >
                  {t("Email")}:
                </label>
              </div>
            </div>
            <div class="grid md:grid-cols-2 md:gap-6">
              <div class="relative z-0 w-full mb-5 group">
                <input
                  value={bookingItem.data.date}
                  type="text"
                  name="floating_first_name"
                  id="floating_first_name"
                  class="block pb-3 pt-5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=""
                  required
                  disabled
                />
                <label
                  for="floating_first_name"
                  class="peer-focus:font-medium absolute duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 text-zinc-800 text-lg font-normal leading-snug"
                >
                  {t("Wedding date")}:
                </label>
              </div>
              <div class="relative z-0 w-full mb-5 group">
                <input
                  value={bookingItem.data.notes}
                  type="text"
                  name="floating_first_name"
                  id="floating_first_name"
                  class="block pb-3 pt-5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=""
                  required
                  disabled
                />
                <label
                  for="floating_first_name"
                  class="peer-focus:font-medium absolute duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 text-zinc-800 text-lg font-normal leading-snug"
                >
                  {t("Message to provider")}:
                </label>
              </div>
            </div>
          </div>

          <Swiper
              spaceBetween={24}
              slidesPerView={'auto'}
              navigation={true}
              modules={[Navigation]}
              className="mb-8"
            >
              <div className="flex gap-6">
                {bookingItem.data.venue?.included_services?.map(
                  (serv, index) => (
                    <SwiperSlide className="!w-auto">
                      <div className="p-3 rounded-xl border flex-col justify-start items-start gap-2.5 inline-flex">
                      <div className="justify-start items-center gap-4 inline-flex">
                        <div className="w-7 h-7 px-px py-[5px] justify-center items-center flex">
                          <img
                            src={
                              includedServicesImages[index]?.data?.result
                                ?.category.image
                            }
                            alt="receptionIcon"
                          />
                        </div>
                        <div className="text-red text-base font-normal">
                          {serv.name}
                        </div>
                      </div>
                      <div className="text-zinc-800 text-base font-normal">
                        {serv.list_price} EGP
                      </div>
                    </div>
                    </SwiperSlide>
                  )
                )}

                {bookingItem.data.reservationLines.map((serv, index) => (
                   <SwiperSlide className="!w-auto">
                    <div className="p-3 rounded-xl border flex-col justify-start items-start gap-2.5 inline-flex">
                    <div className="justify-start items-center gap-4 inline-flex">
                      <div className="w-7 h-7 px-px py-[5px] justify-center items-center flex">
                        <img
                          src={
                            optionalServicesImages[index]?.data?.result
                              ?.category.image
                          }
                          alt="receptionIcon"
                        />
                      </div>
                      <div className="text-red text-base font-normal">
                        {serv.service.name}
                      </div>
                    </div>
                    <div className="text-zinc-800 text-base font-normal">
                      {serv.price} EGP
                    </div>
                  </div>
                   </SwiperSlide>
                ))}
              </div>
            </Swiper>
          <div className="w-full flex justify-end">
            <div className="flex flex-col gap-6 md:w-1/2 w-full">
              <div className="self-stretch">
                <div className="space-y-3 flex flex-col ">
                  <div className= "justify-between text-dark items-center gap-5 inline-flex">
                    <div> {t("Venue Price")}{" "} </div>
                    <div className={` text-red gap-1 inline-flex ${i18n.language === 'en' ? 'flex-row' : 'flex-row-reverse'} md:text-2xl`}>
                      <div>{bookingItem.data.venue.minPrice}</div> <div> EGP</div>
                    </div>
                  </div>
                  <div className="justify-between text-dark items-center gap-5 inline-flex">
                    <div>{t("Other Services Price")}{" "}</div>
                    <div className={` text-red gap-1 inline-flex ${i18n.language === 'en' ? 'flex-row' : 'flex-row-reverse'} md:text-2xl`}>
                      <div>{bookingItem.data.price - bookingItem.data.venue.minPrice}{" "}</div>
                      <div> EGP</div>
                    </div>
                  </div>
                </div>
                <hr className={"my-4 border border-red"} />
                <div className= "justify-between w-full items-center gap-5 inline-flex">
                  <div className={"text-dark md:text-2xl text-lg font-bold"}>
                    {t("Total Price")}{" "}</div>
                    <div className={` text-red gap-1 inline-flex ${i18n.language === 'en' ? 'flex-row' : 'flex-row-reverse'} md:text-2xl text-lg font-bold `}>
                      <div>{bookingItem.data.price}</div> <div> EGP</div>
                    </div>
                
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </section>
  );
}

export default BookingRequestsDetails;
