import { Typography } from "@material-tailwind/react";
import React, {useEffect, useState} from "react";
import { Controller, useForm } from "react-hook-form";
import Select from 'react-select';
import axios from "axios";
import {toast} from "react-toastify";
import useAxiosUserSecureConfig from "../../hooks/useAxiosUserSecureConfig";
import uplooad from "../../assets/images/icons/upload.svg";
import Loader from "../../components/Loader";
import { useNavigate } from "react-router-dom";
import placholder_image from "../../assets/images/booking/placeholder-image.jpg"
import { PhoneInput } from "react-international-phone";
import {useTranslation} from "react-i18next";


function AddFacility() {
  const { t, i18n } = useTranslation();
  const [imageBase64, setImageBase64] = useState(placholder_image);
  const [imageName, setImageName] = useState('');

  const handleImageChange = (e) => {
    setImageName(e.target.files[0].name);
    const file = e.target.files[0];
  
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Extract base64 data without the prefix
        const base64WithoutPrefix = reader.result;
        setImageBase64(base64WithoutPrefix);
      };
      reader.readAsDataURL(file);
    }
  };
  
  console.log(imageBase64)
  const navigate = useNavigate();
  const {
    register,
    watch,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm();

  const [countries , setCountries] = useState({ loading: true, data: [] })
  const [states , setStates] = useState({ loading: false, data: [] })
  const [cities , setCities] = useState({ loading: false, data: [] })
  const [categories , setCategories] = useState({ loading: true, data: [] })

  useEffect(() => {
    axios.post('/api/country', {}).then((res) => {
      if (!res.data?.result?.success) {
        toast.error("Error while fetching countries!");
        return;
      }

      setCountries(prevState => ({
        ...prevState,
        loading: false,
        data: res.data?.result?.countries ?? []
      }))
    }).catch((err) => {
      console.log(err);
    })

    axios.post('/api/facility-types', {}).then((res) => {
      console.log("facility types", res.data);

      if (!res.data?.result?.success) {
        toast.error("Error while fetching facility types");
        return;
      }

      setCategories(prevState => ({
        ...prevState,
        loading: false,
        data: res.data?.result?.facilityTypes?? []
      }))

    }).catch((err) => {
      console.log(err);
    })
  }, []);

  const country = watch("country");
  const state = watch("state");

  useEffect(() => {
    setValue("state", null);
    setValue("city", null);

    if (!country) {
      setStates(prevState => ({
        ...prevState,
        data: []
      }))
      setCities(prevState => ({
        ...prevState,
        data: []
      }))
      return;
    }

    setStates(prevState => ({
      ...prevState,
      loading: true,
    }));

    axios.post(`/api/country/${country.value}`, {}).then((res) => {

      if (!res.data?.result?.success) {
        toast.error("Error while fetching states!");
        return;
      }

      setStates(prevState => ({
        ...prevState,
        loading: false,
        data: res.data?.result?.countries ?? [] 
      }))
    }).catch((err) => {
      console.log(err);
    })
  }, [country]);

  useEffect(() => {
    setValue("city", null)

    if (!state) {
      setCities(prevState => ({
        ...prevState,
        data: []
      }))
      return;
    }

    setCities(prevState => ({
      ...prevState,
      loading: true,
    }));

    axios.post(`/api/state/${state.value}`, {}).then((res) => {

      if (!res.data?.result?.success) {
        toast.error("Error while fetching cities!");
        return;
      }

      setCities(prevState => ({
        ...prevState,
        loading: false,
        data: res.data?.result?.state.cities ?? []
      }))
    }).catch((err) => {
      console.log(err);
    })
  }, [state]);

  const config = useAxiosUserSecureConfig();
  axios.defaults.timeout = 50000
  axios.defaults.timeoutErrorMessage='timeout'

  const onSubmit = (data) => {
    console.log("data" , data)
    data = {
      name: data.facilityName,
      description: data.description,
      facility_type_id: data.category.value,
      country_id: data.country.value,
      state_id: data.state.value,
      city_id: data.city.value,
      address: data.address,
      website: data.website,
      email: data.email,
      phone: data.phone,
      image: imageBase64.split(",")[1],
      business_start_year: data.startingYear,
      zip_code: data.zipCode,
      location_on_map: data.mapLocation,
      commercial_record_number: data.commericalRecord,
      tax_id: data.taxId,
      youtube_link: data.youtubeLink,
      facebook_link: data.facebookLink,
      tiktok_link: data.tiktokkLink,
      instagram_link: data.instagramLink,
      x_link: data.xLink,
    };

    axios.post('/api/venue-vendor/facility/create' ,data, config)
        .then((res) => {
          console.log(res.data)
          if (!res.data.result?.success) {
            toast.error(res.data.result?.message || "Request failed!");
            return;
          }
          toast.success(t("You add facility successfully!"));
          navigate("/vendor/Facilities")
          
        })
        .catch((err) => {
          console.log("err" , err)
          toast.error("Error while adding facility!");
        })
  };

  const customStyles = {
    control: (styles) => ({
      ...styles,
      background: "#F2F4F8", "border-color": "#F2F4F8" , "border-radius" :"8px" ,padding: "6px" })
    }
  
  return (
    <section className="px-5 xl:px-20 py-10">
      <div className="flex gap-20 flex-wrap md:flex-nowrap">
        <div className="flex flex-col gap-6 items-center">
          <img
            className="w-[205px] h-[205px] rounded-full border object-cover"
            src={imageBase64}
            alt=""
          />
        </div>
        <div className="grow">
          <p className="text-lg md:text-2xl font-semibold leading-9 mb-6 text-red">
            {t("Add Facility")}
          </p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col gap-10">
              <div className="grid lg:grid-cols-2 gap-x-6 gap-y-4">
                <div className="flex flex-col justify-start items-start gap-2 ">
                  <label className="text-zinc-800 text-base font-normal leading-snug">
                    {t("Facility name")}
                  </label>
                  <input
                    placeholder={t("Facility name")}
                    {...register("facilityName", {
                      required: t("Facility brand name is required"),
                    })}
                    className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-base font-normal leading-tight placeholder-[#808080]"
                  />

                  {errors.facilityName && (
                    <Typography variant="small" color="red">
                      {errors.facilityName?.message}
                    </Typography>
                  )}
                </div>
                <div className="flex flex-col justify-start items-start gap-2">
                  <label className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">
                    {t("Brand Logo")}
                  </label>

                  <div className="relative w-full bg-slate-100 border-slate-100 rounded-lg">
                    <label>
                      <div className="h-12 flex justify-between items-center">
                        {/* Hidden file input */}
                        <input
                          hidden
                          type="file"
                          {...register("facilityLogo", {
                            required: t("Facility logo is required"),
                          })}
                          onChange={handleImageChange}
                          id="fileInput" // Add an ID for labeling purposes
                        />

                        {/* Upload icon */}
                        <label htmlFor="fileInput" className="cursor-pointer">
                          <img
                            src={uplooad}
                            className={`absolute ${i18n.language === 'en' ? 'right-3' : 'left-3'}  bottom-3`}
                            alt="upload"
                          />
                        </label>

                        {/* Placeholder and image name */}
                        <input
                          type="text"
                          className="border-0 flex-grow p-4 bg-slate-100 rounded-lg text-zinc-500 text-base font-normal leading-tight placeholder-[#808080]"
                          placeholder={t("Choose a file")}
                          value={imageName ? imageName : ""}
                          readOnly
                        />
                      </div>
                    </label>
                  </div>
                  {errors.facilityLogo && (
                    <Typography variant="small" color="red">
                      {errors.facilityLogo?.message}
                    </Typography>
                  )}
                </div>
                <div className="flex flex-col justify-start items-start gap-2 ">
                  <label className="text-zinc-800 text-base font-normal leading-snug">
                    {t("Description")}
                  </label>
                  <input
                    placeholder={t("Description")}
                    {...register("description", {
                      required: t("Description is required"),
                    })}
                    className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-base font-normal leading-tight placeholder-[#808080]"
                  />
                  {errors.description && (
                    <Typography variant="small" color="red">
                      {errors.description?.message}
                    </Typography>
                  )}
                </div>
                <div className="flex flex-col justify-start items-start gap-2 ">
                  <label className="text-zinc-800 text-base font-normal leading-snug">
                    {t("Business website")}
                  </label>
                  <input
                    placeholder={t("Business website")}
                    {...register("website", {
                      required:t("Business website is required"),
                    })}
                    className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-base font-normal leading-tight placeholder-[#808080]"
                  />

                  {errors.website && (
                    <Typography variant="small" color="red">
                      {errors.website?.message}
                    </Typography>
                  )}
                </div>
                <div className="flex flex-col justify-start items-start gap-2 ">
                  <label className="text-zinc-800 text-base font-normal leading-snug">
                    {t("Email address")}
                  </label>
                  <input
                    placeholder={t("Email adrress")}
                    {...register("email", {
                      required: t("email is required"),
                    })}
                    className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-base font-normal leading-tight placeholder-[#808080]"
                  />

                  {errors.email && (
                    <Typography variant="small" color="red">
                      {errors.email?.message}
                    </Typography>
                  )}
                </div>
                <div className="invitaion-verification flex flex-col justify-start items-start gap-2 ">
                  <label className="text-zinc-800 text-base font-normal leading-snug">
                    {t("Phone Number")}
                  </label>
                  <Controller
                    className="w-full"
                    control={control}
                    name="phone"
                    rules={{ required: t("Phone Number is required") }}
                    render={({ field, field: { name, ref } }) => {
                      return (
                        <PhoneInput
                          className=""
                          error={errors.phone}
                          defaultCountry="eg"
                          inputStyle={{
                            background: "rgb(242 244 248",
                            width: "100%",
                            margin: "0px 4px",
                            border: "0px",
                            padding: "0 14px",
                            height: "46px",
                            borderRadius: "8px",
                          }}
                          name={name}
                          ref={ref}
                          {...field}
                        />
                      );
                    }}
                  />

                  {errors.phone && (
                    <Typography variant="small" color="red">
                      {errors.phone?.message}
                    </Typography>
                  )}
                </div>
                <div className="flex flex-col justify-start items-start gap-2 ">
                  <label className="text-zinc-800 text-base font-normal leading-snug">
                    {t("The year your business starts")}
                  </label>
                  <input
                    type="number"
                    placeholder={t("The year your business starts")}
                    {...register("startingYear", {
                      required: t("The year your business starts is required"),
                    })}
                    className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-base font-normal leading-tight placeholder-[#808080]"
                  />

                  {errors.startingYear && (
                    <Typography variant="small" color="red">
                      {errors.startingYear?.message}
                    </Typography>
                  )}
                </div>
                {categories.loading && <Loader slim />}
                {!categories.loading && (
                  <div className="flex flex-col justify-start items-start gap-2 ">
                    <label className="text-zinc-800 text-base font-normal leading-snug">
                      {t("Type")}
                    </label>
                    <Controller
                      control={control}
                      name="category"
                      rules={{ required: t("Type is required") }}
                      render={({ field, field: { name, ref } }) => {
                        return (
                          <Select
                            styles={customStyles}
                            name={name}
                            ref={ref}
                            isClearable
                            isSearchable
                            options={categories.data.map((category) => ({
                              value: category.id,
                              label: category.name,
                            }))}
                            {...field}
                            className="w-full"
                          />
                        );
                      }}
                    />
                    {errors.category && (
                      <Typography variant="small" color="red">
                        {errors.category?.message}
                      </Typography>
                    )}
                  </div>
                )}
                {countries.loading && <Loader slim />}
                {!countries.loading && (
                  <div className="flex flex-col justify-start items-start gap-2 ">
                    <label className="text-zinc-800 text-base font-normal leading-snug">
                      {t("Country")}
                    </label>
                    <Controller
                      control={control}
                      name="country"
                      rules={{ required: t("Country is required") }}
                      render={({ field, field: { name, ref } }) => {
                        return (
                          <Select
                            styles={customStyles}
                            name={name}
                            ref={ref}
                            isClearable
                            isSearchable
                            options={countries.data.map((country) => ({
                              value: country.id,
                              label: country.name,
                            }))}
                            {...field}
                            className="w-full"
                          />
                        );
                      }}
                    />
                    {errors.country && (
                      <Typography variant="small" color="red">
                        {errors.country?.message}
                      </Typography>
                    )}
                  </div>
                )}
                {states.loading && <Loader slim />}
                {!states.loading && (
                  <div className="flex flex-col justify-start items-start gap-2 ">
                    <label className="text-zinc-800 text-base font-normal leading-snug">
                      {t("State")}
                    </label>
                    <Controller
                      control={control}
                      name="state"
                      rules={{ required: t("State is required") }}
                      render={({ field, field: { name, ref } }) => {
                        return (
                          <Select
                            styles={customStyles}
                            name={name}
                            ref={ref}
                            isClearable
                            isSearchable
                            options={states.data.map((state) => ({
                              value: state.id,
                              label: state.name,
                            }))}
                            {...field}
                            className="w-full"
                          />
                        );
                      }}
                    />
                    {errors.state && (
                      <Typography variant="small" color="red">
                        {errors.state?.message}
                      </Typography>
                    )}
                  </div>
                )}
                {cities.loading && <Loader slim />}
                {!cities.loading && (
                  <div className="flex flex-col justify-start items-start gap-2 ">
                    <label className="text-zinc-800 text-base font-normal leading-snug">
                      {t("City")}
                    </label>
                    <Controller
                      control={control}
                      name="city"
                      rules={{ required: t("City is required") }}
                      render={({ field, field: { name, ref } }) => {
                        return (
                          <Select
                            styles={customStyles}
                            name={name}
                            ref={ref}
                            isClearable
                            isSearchable
                            options={cities.data.map((category) => ({
                              value: category.id,
                              label: category.name,
                            }))}
                            {...field}
                            className="w-full"
                          />
                        );
                      }}
                    />
                    {errors.city && (
                      <Typography variant="small" color="red">
                        {errors.city?.message}
                      </Typography>
                    )}
                  </div>
                )}
                <div className="flex flex-col justify-start items-start gap-2 ">
                  <label className="text-zinc-800 text-base font-normal leading-snug">
                    {t("Zip code")}
                  </label>
                  <input
                    type="number"
                    placeholder={t("zip code")}
                    {...register("zipCode", {
                      required: t("zip code is required"),
                    })}
                    className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-base font-normal leading-tight placeholder-[#808080]"
                  />

                  {errors.zipCode && (
                    <Typography variant="small" color="red">
                      {errors.zipCode?.message}
                    </Typography>
                  )}
                </div>
                <div className="flex flex-col justify-start items-start gap-2 ">
                  <label className="text-zinc-800 text-base font-normal leading-snug">
                    {t("Address")}
                  </label>
                  <input
                    placeholder={t("Address")}
                    {...register("address", {
                      required: t("Address is required"),
                    })}
                    className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-base font-normal leading-tight placeholder-[#808080]"
                  />

                  {errors.address && (
                    <Typography variant="small" color="red">
                      {errors.address?.message}
                    </Typography>
                  )}
                </div>
                <div className="flex flex-col justify-start items-start gap-2 ">
                  <label className="text-zinc-800 text-base font-normal leading-snug">
                    {t("Location on map")}
                  </label>
                  <input
                    placeholder={t("Location")}
                    {...register("mapLocation", {
                      required: t("location is required"),
                    })}
                    className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-base font-normal leading-tight placeholder-[#808080]"
                  />

                  {errors.mapLocation && (
                    <Typography variant="small" color="red">
                      {errors.mapLocation?.message}
                    </Typography>
                  )}
                </div>
                <div className="flex flex-col justify-start items-start gap-2 ">
                  <label className="text-zinc-800 text-base font-normal leading-snug">
                    {t("Commercial Record")} 
                  </label>
                  <input
                    type="number"
                    placeholder={t("co_num")}
                    {...register("commericalRecord", {
                      required: t("Commercial record number is required"),
                    })}
                    className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-base font-normal leading-tight placeholder-[#808080]"
                  />

                  {errors.commericalRecord && (
                    <Typography variant="small" color="red">
                      {errors.commericalRecord?.message}
                    </Typography>
                  )}
                </div>
                <div className="flex flex-col justify-start items-start gap-2 ">
                  <label className="text-zinc-800 text-base font-normal leading-snug">
                    {t("Tax ID")}
                  </label>
                  <input
                    type="number"
                    placeholder={t("tax_id")}
                    {...register("taxId", {
                      required: t("Tax ID is required"),
                    })}
                    className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-base font-normal leading-tight placeholder-[#808080]"
                  />

                  {errors.taxId && (
                    <Typography variant="small" color="red">
                      {errors.taxId?.message}
                    </Typography>
                  )}
                </div>
                <div className="flex flex-col justify-start items-start gap-2 ">
                  <label className="text-zinc-800 text-base font-normal leading-snug">
                  {t("Youtube Link")}
                  </label>
                  <input
                    placeholder={t("youtube link")}
                    {...register("youtubeLink")}
                    className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-base font-normal leading-tight placeholder-[#808080]"
                  />
                </div>
                <div className="flex flex-col justify-start items-start gap-2 ">
                  <label className="text-zinc-800 text-base font-normal leading-snug">
                    {t("Facebook Link")}
                  </label>
                  <input
                    placeholder={t("facebook link")}
                    {...register("facebookLink")}
                    className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-base font-normal leading-tight placeholder-[#808080]"
                  />
                </div>
                <div className="flex flex-col justify-start items-start gap-2 ">
                  <label className="text-zinc-800 text-base font-normal leading-snug">
                  {t("TikTok Link")}
                  </label>
                  <input
                    placeholder={t("tiktok link")}
                    {...register("tiktokkLink")}
                    className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-base font-normal leading-tight placeholder-[#808080]"
                  />
                </div>
                <div className="flex flex-col justify-start items-start gap-2 ">
                  <label className="text-zinc-800 text-base font-normal leading-snug">
                  {t("Instagram Link")}
                  </label>
                  <input
                    placeholder={t("instgram link")}
                    {...register("instagramLink")}
                    className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-base font-normal leading-tight placeholder-[#808080]"
                  />
                </div>
                <div className="flex flex-col justify-start items-start gap-2 ">
                  <label className="text-zinc-800 text-base font-normal leading-snug">
                    {t("X Link")}
                  </label>
                  <input
                    placeholder={t("x link")}
                    {...register("xLink")}
                    className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-base font-normal leading-tight placeholder-[#808080]"
                  />
                </div>
              </div>

              <div className="flex justify-end w-full">
                <button
                  type="submit"
                  className="w-fit px-16 py-3 bg-red rounded-[10px] shadow border border-white justify-center items-center gap-2.5 inline-flex"
                >
                  <div className="text-center text-white text-base font-normal leading-normal">
                    {t("Save")}
                  </div>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}

export default AddFacility;
