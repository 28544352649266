import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./slicer/auth";
import uiSlice from "./slicer/ui";
import cartReducer from "./slicer/cartSlice";
import wishListReducer from "./slicer/wishListSlice";


const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    ui: uiSlice.reducer,
    cart: cartReducer,
    wishList :wishListReducer,
  }
});

export default store;