import React , {useEffect, useState} from 'react'
import {useTranslation} from "react-i18next";
import { Button, Checkbox, Label, TextInput , Badge  } from 'flowbite-react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faPencil , faPenToSquare ,faEye ,faEyeSlash} from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useForm , Controller } from 'react-hook-form';
import axios from 'axios';
import Select from 'react-select';
import { Typography } from '@material-tailwind/react';
import {Spinner} from "flowbite-react";
import editIcon from "../../assets/images/profile/icons/edit.svg"





function EditProfile() {
    
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [imgInput , setImgInput] = useState("https://via.placeholder.com/205x205")

    const [showPassword , setShowPassword]= useState(false);
    const [showConfirmPassword , setShowConfirmPassword]= useState(false);

    const {register , watch,handleSubmit ,setValue , control , formState: { errors }} = useForm({
        defaultValues :  async()=>{
            
            return axios.get('https://jsonplaceholder.typicode.com/users/2')
            .then(response => response.data)
            .then( data => {
                return{
                    full_name : data.name,
                    email :data.email,
                    phone :data.phone,
                    adrress :data.address.street,
                    zipcode :data.address.zipcode,
                    cr_no: data.address.zipcode,
                    tax_id: data.address.zipcode,
                    country_id: 1,
                   
                }
            })
        }
        
    })
    
    const onsubmit= (data)=>{
        console.log(data)
    }
    function handleImgInput (e){
        setImgInput(URL.createObjectURL(e.target.files[0]));
    }
    
    const companyTypeOptions  = 
    [
        {value: 'company', label: 'company'},
        {value: 'individual', label: 'individual'},
    ]
      
        
      
    
    const [selectables, setSelectables] = useState({
        products: {
          loading: true,
          options: [],
          data: [],
        },
        countries : {
          loading: true,
          options: [],
          data: [],
        },
      });
      useEffect(() => {
        axios.post('/api/products', {}).then((res) => {
          setSelectables((prev) => ({
            ...prev,
            products: {
              loading: false,
              data: res.data.result.products,
              options: res.data.result.products.map((product) => ({ 
                value: product.id,
                label: product.name
              }))
            }
          }))
        }).catch((err) => {
          console.log(err);
        })

      axios.post('/api/countries', {}).then((res) => {
        setSelectables((prev) => ({
          ...prev,
          countries: {
            loading: false,
            data: res.data.result.countries,
            options: res.data.result.countries.map((country) => ({
              value: country.id,
              label: country.name
            }))
          }
        }))
      }).catch((err) => {
        console.log(err);
      })
    }, []);

    const [defaultValue, setDefaultValue] = useState(
        {
          company_type : {
            label: "individual",
            value: "individual"
            },
           products : [
            {
            label :"Photographer",
            value:1
            }, 
            {
            label :"Decorator",
            value:2
            }, 
        ]
        }
    );
    const customStyles = {
      control: (styles) => ({
        ...styles,
        background: "#F2F4F8", "border-color": "#F2F4F8" , "border-radius" :"8px" ,padding: "6px" })
       }


  return (
    <section className="px-5 xl:px-10 py-10">
      <div className="xl:px-10 flex gap-20 flex-wrap md:flex-nowrap">
        <div className="flex flex-col justify-between items-center gap-6 w-full md:w-auto">
          <div className="flex flex-col gap-6 items-center">
            <img className="w-[205px] h-[205px] rounded-full" src={imgInput} alt='' />
            <button onClick={handleImgInput} for="uploadBtn" title="Click to upload" className="px-2 py-[6.40px] bg-white rounded-[10px] shadow border border-red justify-center items-center gap-2.5 inline-flex">
                 {/*<input hidden type="file"  name="uploadBtn" id="uploadBtn" />*/}
                <div className="py-1 justify-center items-center gap-2.5 flex">
                    <div className="text-center text-red text-base font-normal leading-normal">Change photo</div>
                    <img src={editIcon} alt=''/>
                </div>
            </button>
          </div>
          <button className="px-[18px] py-[6.40px] self-stretch bg-white rounded-[10px] shadow border border-red justify-center items-center gap-2.5 inline-flex">
                <div className="py-1 justify-center items-center gap-2.5 flex">
                    <div className="text-center text-red text-base font-normal leading-normal">Delete Account</div>
                </div>
            </button>
        </div>

        <div className="grow">
          <div className="flex justify-between items-center mb-4">
            <p className="text-lg md:text-2xl font-semibold leading-9">Service Provider information</p>
            <button className="px-[18px] py-[6.40px] bg-white rounded-[10px] shadow border border-red justify-center items-center gap-2.5 inline-flex">
              <div className="py-1 justify-center items-center gap-2.5 flex">
                  <div className="text-center text-red text-base font-normal leading-normal">Edit</div>
                  <img src={editIcon} alt='icon'/>
              </div>
            </button>
          </div>
          <form onSubmit={handleSubmit(onsubmit)}>
            <div className="flex flex-col gap-10">
              <div className="grid lg:grid-cols-2 gap-x-6 gap-y-4">
                <div className="flex flex-col justify-start items-start gap-2 ">
                        <label className="text-zinc-800 text-base font-normal leading-snug">Full Name</label>
                          <input {...register("full_name", {required: t('Full Name is required')})}
                            className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"/>

                        {errors.full_name &&
                      <Typography variant="small" color="red">
                        {errors.full_name?.message}
                      </Typography>
                        }
                </div>

                <div className="flex flex-col justify-start items-start gap-2 ">
                        <label className="text-zinc-800 text-base font-normal leading-snug">Email</label>
                          <input {...register("email", {required: t('Email is required')})}
                            className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"/>

                        {errors.email &&
                      <Typography variant="small" color="red">
                        {errors.email?.message}
                      </Typography>
                        }
                </div>
                
                <div className="flex flex-col justify-start items-start gap-2 ">
                        <label className="text-zinc-800 text-base font-normal leading-snug">Phone Number</label>
                          <input {...register("phone", {required: t('Phone Number is required')})}
                            className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"/>

                        {errors.phone &&
                      <Typography variant="small" color="red">
                        {errors.phone?.message}
                      </Typography>
                        }
                </div>

                <div className="flex flex-col justify-start items-start gap-2 ">
                        <label className="text-zinc-800 text-base font-normal leading-snug">Company Type</label>
                        <Controller
                        control={control}
                        name="company_type"
                        defaultValue={defaultValue.company_type}
                        rules={{ required: t('Company type is required') }}
                        render={({ field: {onChange,value, name, ref} }) => {
                            return <Select
                            styles={customStyles}
                            error={!!errors.type}
                                name={name}
                                ref={ref}
                                options={companyTypeOptions}
                                value={value}
                                onChange={(val) => onChange(val)}
                                className="w-full"
                            />
                  }}
              />
                    {errors.type &&
                    <Typography variant="small" color="red">
                      {errors.type?.message}
                    </Typography>
                    }
                </div>

                <div className="flex flex-col justify-start items-start gap-2 ">
                        <label className="text-zinc-800 text-base font-normal leading-snug">Occasion Date</label>
                          <input {...register("date", {required: t('Occasion Date is required')})}
                          type='date' className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"/>

                        {errors.date &&
                      <Typography variant="small" color="red">
                        {errors.date?.message}
                      </Typography>
                        }
                </div>

                <div className="flex flex-col justify-start items-start gap-2 ">
                        <label className="text-zinc-800 text-base font-normal leading-snug">Zip code</label>
                          <input {...register("zipcode", {required: t('Zip code is required')})}
                            className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"/>

                        {errors.zipcode &&
                      <Typography variant="small" color="red">
                        {errors.zipcode?.message}
                      </Typography>
                        }
                </div>

                <div className="flex flex-col justify-start items-start gap-2 ">
                        <label className="text-zinc-800 text-base font-normal leading-snug">Country</label>
                        {selectables.countries.loading && <Spinner /> }
                        {!selectables.countries.loading &&
                        <Controller
                          control={control}
                          name="country_id"
                          rules={{ required: t('Country is required') }}
                          render={({ field: {onChange, value, name, ref} }) => {
                              const options = selectables.countries.options;
                              return <Select
                              styles={customStyles}
                                name={name}
                                ref={ref}
                                onChange={entry => {
                                  onChange(entry.value)
                                  setValue('city_id', "")
                                }}
                                value={options.find(entry => entry.value === value)}
                                options={options}
                                className="w-full"
                                />
                          }}
                      />
                      
                      }
                      {errors.country_id &&
                      <Typography variant="small" color="red">
                        {errors.country_id?.message}
                      </Typography>
                  }
                </div>

                <div className="flex flex-col justify-start items-start gap-2 ">
                        <label className="text-zinc-800 text-base font-normal leading-snug">City</label>
                        {selectables.countries.loading && <Spinner /> }
                        {!selectables.countries.loading &&
                        <Controller
                        control={control} name="city_id"
                        render={({ field: {onChange, value, name, ref} }) => {
                          const options = selectables.countries.data.find(country => country.id === watch("country_id")).cities.map((city) => ({
                              value: city.id,
                              label: city.name
                          }))
      
                            return <Select
                            styles={customStyles}
                                name={name}
                                ref={ref}
                                onChange={entry => onChange(entry.value)}
                                value={options.find(entry => entry.value === value)}
                                options={options}
                                className="w-full"
                            />
                        }}
                    />}
                </div>
                <div className="flex flex-col justify-start items-start gap-2 ">
                        <label className="text-zinc-800 text-base font-normal leading-snug">Commercial Record</label>
                        <input {...register("cr_no" , {required: t('Commercial Record is required')})} id="" type="text"
                        className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"/>
                        {errors.cr_no &&
                          <Typography variant="small" color="red">
                            {errors.cr_no?.message}
                          </Typography>
                      }
                </div>

                <div className="flex flex-col justify-start items-start gap-2 ">
                        <label className="text-zinc-800 text-base font-normal leading-snug">Tax ID</label>
                        <input {...register("tax_id" , {required: t('Tax ID is required')})} id="" type="text"
                        className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"/>

                        {errors.tax_id &&
                        <Typography variant="small" color="red">
                          {errors.tax_id?.message}
                        </Typography>
                    }
                </div>

                <div className="lg:col-span-2 flex flex-col justify-start items-start gap-2 ">
                        <label className="text-zinc-800 text-base font-normal leading-snug">Address</label>
                        <input {...register("adrress" ,{required: t('Address is required')})} id="" type="text"
                        className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"/>

                        {errors.adrress &&
                        <Typography variant="small" color="red">
                          {errors.adrress?.message}
                        </Typography>
                    }
                </div>

                <div className="lg:col-span-2 flex flex-col justify-start items-start gap-2 ">
                        <label className="text-zinc-800 text-base font-normal leading-snug">Products</label>
                        <Controller
                          control={control} 
                          name="product_ids"
                          defaultValue={defaultValue.products}
                          render={({ field: {onChange, value, name, ref} }) => {
                            const options = selectables.products.options;
                            return <Select
                            styles={customStyles}
                              placeholder={t("Products")}
                              name={name}
                              ref={ref}
                              options={options}
                              value={value}
                              onChange={(val) => onChange(val)}
                              isMulti
                              className=" w-full"
                            />
                        }}
                      />
                </div>
              </div>
              
              <div className="flex justify-end w-full">
                <button type="submit" className="w-fit px-16 py-3 bg-red rounded-[10px] shadow border border-white justify-center items-center gap-2.5 inline-flex">
                      <div className="text-center text-white text-base font-normal leading-normal">Save</div>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  )
}

export default EditProfile
