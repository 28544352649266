import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from 'react-select';
import editIcon from "../../assets/images/profile/icons/edit.svg"
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import useAxiosUserSecureConfig from "../../hooks/useAxiosUserSecureConfig";
import ConfirmationModal from "../../components/ConfirmationModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { PhoneInput } from "react-international-phone";


function DeleteFacility() {

const params = useParams();
  const [facility, setFacilty] = useState({
    loading: true,
    data: [],
  });

useEffect(() => {
    axios
      .post(`/api/facility/${params.id}`, { id: params.id })
      .then((res) => {
        console.log("facility", res.data);

        if (!res.data?.result?.success) {
            toast.error("Error while fetching facility!");
        }
        setFacilty({
          loading: false,
         data : res.data?.result
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  console.log(facility)
const {
   control,
    handleSubmit,
  } = useForm();
  
  const config = useAxiosUserSecureConfig(); 
  const navigate = useNavigate();
  const [open, setOpen] =useState(false);
  const handleClose = () => setOpen(false); 

  const onSubmit = (data) => {
    setOpen(true)
    console.log("data" , data)
  };
  const handleDeleteFacility = () => {
    let data = {
      facility_id: facility.data.id
  };

  axios.post('/api/venue-vendor/delete-facility' ,data, config)
      .then((res) => {
        if (!res.data.result?.success) {
          toast.error(res.data.result?.message || "Request failed!");
          return;
        }
        toast.success("You delete facility successfully!");
        navigate("/vendor/Facilities")
        
      })
      .catch((err) => {
        console.log("err" , err)
        toast.error("Error while deleting facility!");
      })
  }

return (
  <section className="px-5 xl:px-20 py-10">
    <ConfirmationModal
      open={open}
      handleClose={handleClose}
      handleAction={handleDeleteFacility}
      msg={"Are you sure you want to delete this facility?"}
    />
    {facility.loading && <Loader slim />}
    {!facility.loading && (
      <>
        <div className="justify-start items-center gap-2 inline-flex mb-10">
          <div
            onClick={() => navigate("/vendor/account")}
            className="text-zinc-400 text-base font-normal leading-[48px] hover:text-gray-dark cursor-pointer"
          >
            My Account
          </div>
          <FontAwesomeIcon
            className="text-zinc-400 text-base"
            icon={faAngleRight}
          />
          <div
            onClick={() => navigate("/vendor/Facilities")}
            className="text-zinc-400 text-base font-normal leading-[48px] hover:text-gray-dark cursor-pointer"
          >
            My Facilities
          </div>
          <FontAwesomeIcon
            className="text-zinc-400 text-base"
            icon={faAngleRight}
          />
          <div
          onClick={() => navigate(`/vendor/Facilities/${facility.data.id}`)}
           className="text-zinc-400 text-base font-normal leading-[48px] hover:text-gray-dark cursor-pointer">
            {facility.data.name}
          </div>
          <FontAwesomeIcon
            className="text-zinc-400 text-base"
            icon={faAngleRight}
          />
          <div className="text-zinc-400 text-base font-normal leading-[48px] hover:text-gray-dark cursor-pointer">
            Delete
          </div>
        </div>
        <div className="flex gap-20 flex-wrap md:flex-nowrap">
          <div className="flex flex-col justify-between items-center gap-6 w-full md:w-auto">
            <div className="flex flex-col gap-6 items-center">
              <img
                className="w-[205px] h-[205px] rounded-full border object-cover"
                src={facility.data.image}
                alt=""
              />
            </div>
          </div>
          <div className="grow">
            <p className="text-lg md:text-2xl font-semibold leading-9 mb-6 text-red">
              Delete Facility
            </p>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="flex flex-col gap-10">
                <div className="grid lg:grid-cols-2 gap-x-6 gap-y-4">
                  <div className="flex flex-col justify-start items-start gap-2 ">
                    <label className="text-zinc-800 text-base font-normal leading-snug">
                      Facility name
                    </label>
                    <input
                      type="text"
                      value={facility.data.name}
                      className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                    />
                  </div>
                  <div className="flex flex-col justify-start items-start gap-2 ">
                    <label className="text-zinc-800 text-base font-normal leading-snug">
                      Business website
                    </label>
                    <input
                      type="text"
                      value={facility.data.website}
                      className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                    />
                  </div>
                  <div className="flex flex-col justify-start items-start gap-2 ">
                    <label className="text-zinc-800 text-base font-normal leading-snug">
                      Description
                    </label>
                    <input
                      type="text"
                      value={facility.data.description}
                      className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                    />
                  </div>

                  <div className="flex flex-col justify-start items-start gap-2">
                    <label className="text-zinc-800 text-base font-normal leading-snug">
                      Type
                    </label>
                    <input
                      type="text"
                      value={facility.data.type.name}
                      className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                    />
                  </div>
                  <div className="flex flex-col justify-start items-start gap-2 ">
                    <label className="text-zinc-800 text-base font-normal leading-snug">
                      The year your business starts
                    </label>
                    <input
                      type="number"
                      value={facility.data.business_start_year}
                      className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                    />
                  </div>
                  <div className="flex flex-col justify-start items-start gap-2">
                    <label className="text-zinc-800 text-base font-normal leading-snug">
                      Country
                    </label>
                    <input
                      type="text"
                      value={facility.data.country.name}
                      className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                    />
                  </div>
                  <div className="flex flex-col justify-start items-start gap-2">
                    <label className="text-zinc-800 text-base font-normal leading-snug">
                      State
                    </label>
                    <input
                      type="text"
                      value={facility.data.state.name}
                      className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                    />
                  </div>
                  <div className="flex flex-col justify-start items-start gap-2">
                    <label className="text-zinc-800 text-base font-normal leading-snug">
                      City
                    </label>
                    <input
                      type="text"
                      value={facility.data.city.name}
                      className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                    />
                  </div>
                  <div className="flex flex-col justify-start items-start gap-2 ">
                    <label className="text-zinc-800 text-base font-normal leading-snug">
                      Zip code
                    </label>
                    <input
                      type="number"
                      value={facility.data.zip_code}
                      className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                    />
                  </div>
                  <div className="flex flex-col justify-start items-start gap-2 ">
                    <label className="text-zinc-800 text-base font-normal leading-snug">
                      Address
                    </label>
                    <input
                      type="text"
                      value={facility.data.address}
                      className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                    />
                  </div>
                  <div className="flex flex-col justify-start items-start gap-2 ">
                    <label className="text-zinc-800 text-base font-normal leading-snug">
                      Location on map
                    </label>
                    <input
                      type="text"
                      value={facility.data.location_on_map}
                      className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                    />
                  </div>
                  <div className="invitaion-verification flex flex-col justify-start items-start gap-2 ">
                    <label className="text-zinc-800 text-base font-normal leading-snug">
                      Phone number
                    </label>
                    <Controller
                        className="w-full"
                        control={control}
                        name="phone"
                        rules={{ required: "Phone Number is required" }}
                        value={facility.data.phone}
                        render={({ field, field: { name, ref } }) => {
                          return (
                            <PhoneInput
                              className=""
                              defaultCountry="eg"
                              value={facility.data.phone}
                              inputStyle={{
                                background: "rgb(242 244 248",
                                width: "100%",
                                margin: "0px 4px",
                                border: "0px",
                                padding: "0 14px",
                                height: "46px",
                                borderRadius: "8px",
                              }}
                              name={name}
                              ref={ref}
                              {...field}
                            />
                          );
                        }}
                      />
                  </div>
                  <div className="flex flex-col justify-start items-start gap-2 ">
                    <label className="text-zinc-800 text-base font-normal leading-snug">
                      Email address
                    </label>
                    <input
                      type="text"
                      value={facility.data.email}
                      className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                    />
                  </div>
                  <div className="flex flex-col justify-start items-start gap-2 ">
                    <label className="text-zinc-800 text-base font-normal leading-snug">
                      Commercial record number
                    </label>
                    <input
                      type="number"
                      value={facility.data.commercial_record_number}
                      className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                    />
                  </div>
                  <div className="flex flex-col justify-start items-start gap-2 ">
                    <label className="text-zinc-800 text-base font-normal leading-snug">
                      Tax ID
                    </label>
                    <input
                      type="number"
                      value={facility.data.tax_id}
                      className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                    />
                  </div>
                  <div className="flex flex-col justify-start items-start gap-2 ">
                    <label className="text-zinc-800 text-base font-normal leading-snug">
                      youtube link
                    </label>
                    <input
                      type="text"
                      value={facility.data.youtube_link}
                      className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                    />
                  </div>
                  <div className="flex flex-col justify-start items-start gap-2 ">
                    <label className="text-zinc-800 text-base font-normal leading-snug">
                      Facebook link
                    </label>
                    <input
                      type="text"
                      value={facility.data.facebook_link}
                      className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                    />
                  </div>
                  <div className="flex flex-col justify-start items-start gap-2 ">
                    <label className="text-zinc-800 text-base font-normal leading-snug">
                      Tiktok link
                    </label>
                    <input
                      type="text"
                      value={facility.data.tiktok_link}
                      className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                    />
                  </div>
                  <div className="flex flex-col justify-start items-start gap-2 ">
                    <label className="text-zinc-800 text-base font-normal leading-snug">
                      Instagram link
                    </label>
                    <input
                      type="text"
                      value={facility.data.instagram_link}
                      className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                    />
                  </div>
                  <div className="flex flex-col justify-start items-start gap-2 ">
                    <label className="text-zinc-800 text-base font-normal leading-snug">
                      X link
                    </label>
                    <input
                      type="text"
                      value={facility.data.x_link}
                      className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                    />
                  </div>
                </div>
                <div className="flex justify-end w-full">
                  <button
                    type="submit"
                    className="w-fit px-16 py-3 bg-red rounded-[10px] shadow border border-white justify-center items-center gap-2.5 inline-flex"
                  >
                    <div className="text-center text-white text-base font-normal leading-normal">
                      Delete
                    </div>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    )}
  </section>
);
}

export default DeleteFacility;
