import React from 'react';
import axios from "axios";
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from "@material-tailwind/react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import 'react-toastify/dist/ReactToastify.css';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import arabic from "./translation/ar.json";
import english from "./translation/en.json";

// Axios defaults override
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.timeout = process.env.REACT_APP_API_TIMEOUT;
axios.defaults.headers.common['Content-Type'] = 'application/json';

// noinspection JSIgnoredPromiseFromCall
i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
      resources: {
        en: {
          translation: english,
        },
        ar: {
          translation: arabic,
        },
      },
      debug: process.env.REACT_APP_DEBUG_INTL,
      fallbackLng: "en",
      supportedLngs: ['en', 'ar'],
      interpolation: {
        escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
      }
    });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider>
        <Provider store={store}>
          <App />
        </Provider>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
