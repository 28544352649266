import React from 'react';
import AboutUS from "../../assets/images/About/AboutUS.png";
import Layer1 from "../../assets/images/About/Layer_1.png"
import vector from "../../assets/images/About/Vector.png"
import object from "../../assets/images/About/OBJECTS.png"
import object2 from "../../assets/images/About/Isolation_Mode.png"
import card1 from "../../assets/images/About/card1.png"
import vector3 from "../../assets/images/About/Vector 2.png"
import vector2 from "../../assets/images/About/Vector3.png" 
import vector4 from "../../assets/images/About/vector4.png"
import card2 from "../../assets/images/About/card2.png"
import card3 from "../../assets/images/About/card3.png"
import card4 from "../../assets/images/About/card4.png"
import card5 from "../../assets/images/About/card5.png"
import card6 from "../../assets/images/About/card6.jpg"
import card7 from "../../assets/images/About/card7.jpg"
import card8 from "../../assets/images/About/card8.png"
import {useTranslation} from "react-i18next";

function About() {
    const { t, i18n } = useTranslation();
  return (
      <div className='bg-[#f2f2f2]'>
         <div className='relative'>
               <img src={AboutUS}  className='w-full relative h-[122px] sm:h-[160px]'/>
               <div className=" sm:w-full w-[full] mx-auto absolute  lg:top-[23%] top-[10%] text-[#FAF5EE] text-sm sm:text-base md:text-lg text-center">
                {t("For you, your wedding is a way of life, not just a special day. It is the entrance to your paradise with your soul mate.")}
                     <p className='font-bold'>{t("God bless you")} </p>
                    <p className='font-bold '>{t("Rose Bazaar will help you achieve your Dream")}</p>
                    </div>
               <img src={Layer1} className='absolute left-[35%] top-[81%] md:top-[76%] xl:top-[71%] md:left-[40%] w-2/6 md:w-1/5 ' />
          </div>
      
          <div className='bg-white w-5/6  lg:w-3/4 mx-auto mb-20 mt-20 md:mt-20  relative  border rounded-xl'>
       <div className=' w-full md:w-3/5'>
              <p className='p-8 lg:py-5 font-bold text:xl lg:text-2xl'>
             {t(" Welcome to RoseBazzar,")}<br></br>
               {t(" where love blossoms and dreams come true !")}
              </p>
          </div>
              <div className='flex flex-col-reverse justify-evenly md:flex-row '>
                <div className='flex flex-col px-3 md:px-10 w-full md:w-5/6'>
              <p className='pt-0 pb-1'> {t("The wonderful website Rose Bazaar believes that every wedding ought to be a tale straight out of One Thousand and One Nights. At Rose Bazaar, we take great pride in offering everything that is suitable for a range of tastes and in-season.")}</p>
              <p className='pt-0 pb-1'>{t("Every single person who works at Rose Bazaar is a positive person. As a unified team, we provide the greatest selection of wedding-related goods and services. We work with passionate vendor partners and make use of our resources because we sincerely believe that we should help young people who are looking for love and happiness.")}</p>
              <p className='pt-0 pb-2'>{t("Special memories should be savored by the couple, appreciated by their loved ones and distinguished visitors, and then passed on to their newlyweds, according to our guiding principles. That's what provides our service its soul - we make your moments truly unforgettable. When the bride and groom exchange vows, we can't help but feel that this is what we want to live for. These smiles inspire imaginative stories")}  </p>
              </div>
              <div >
              <img src={card1} className='w-full pb-5 md:w-full px-10 md:pl-5 mt-[-40px]  object-cover '/>
              </div>
              <img src={object} className='w-[101px] h-[140px] absolute right-[-21px] top-[-20px]' />
              <img src={object2} className='w-[85px] h-[101px] absolute left-[-14px] bottom-[-13px]' />
            
          
          </div>
       </div>
       

       <div className={`flex justify-center ${i18n.language === 'en' ? 'flex-row' : 'flex-row-reverse'}`}>
              <img src={vector2} className='w-20 md:w-28' />
              <p className='text-red text-lg md:text-2xl lg:text-3xl' >{t("Wedding Venues")}</p>
              <img src={vector3} className='w-20 md:w-28' />
       </div>
      
       <div className='bg-white w-5/6 lg:w-3/4 mx-auto mb-20 mt-12  relative  border rounded-xl'>
       <div className=' w-full md:w-3/5'>
              <p className='p-10 lg:py-6 font-bold text:xl lg:text-3xl'>
              {t("Wedding Venues")}
              </p>
          </div>
              <div className='flex flex-col-reverse justify-evenly md:flex-row '>
              <div className='flex flex-col px-3 md:px-10 w-full md:w-5/6'>
              <p className='pt-0 pb-1'>{t("For the Bride & Groom to be Sincere congrats to the gorgeous couple who will soon tie the knot! We are delighted you choose Rose Bazaar as your ideal location for your special day. We value your interest in using our website to get all of your wedding-related needs met.")}</p>
                <p className='pt-0 pb-1'>{t("You can always select the location of your wedding, be it a hotel ballroom, a floating restaurant, a historical site, or even a garden with a stunning view of the setting sun, or a popular character's country home, or on the beach with the sound of breaking waves...")}</p>
                <p className='pt-0 pb-1'>{t("Our wide network of reliable partners allows us to provide you with the greatest options for providers and locations that are especially needed for your special day. We're ready to make it an unforgettable experience. While we make the best possible adjustments to your budget and goals.")}</p>
         </div>
              <div >
              <img src={card3} className='w-full pb-5 md:w-full px-10 md:pl-5 mt-[-30px] object-cover'/>
              </div>
              <img src={object} className='w-[101px] h-[140px] absolute right-[-21px] top-[-20px]' />
              <img src={object2} className='w-[85px] h-[101px] absolute left-[-14px] bottom-[-13px]' />
              <img src={vector4} className='w-[50px] absolute left-[12px] top-[16px]' />
          
          </div>
       </div> 

       <div className={`flex justify-center ${i18n.language === 'en' ? 'flex-row' : 'flex-row-reverse'}`}>
              <img src={vector2} className='w-20 md:w-28' />
              <p className='text-red text-lg md:text-2xl lg:text-3xl ' >{t("Wedding Services")}</p>
              <img src={vector3} className='w-20 md:w-28' />
       </div>

       <div className='bg-white w-5/6 lg:w-3/4 mx-auto mb-20 mt-12  relative  border rounded-xl'>
       <div className=' w-full md:w-3/5'>
              <p className='p-10 lg:py-5 font-bold text:xl lg:text-3xl'>
             {t("Wedding Services")}
              </p>
          </div>
              <div className='flex flex-col-reverse justify-evenly md:flex-row '>
              <div className='flex flex-col px-3 md:px-10 w-full md:w-5/6'>
              <p className='pt-0 pb-1'>{t("The best place to arrange a wedding and all newlyweds' needs, including arranging for other amazing events like engagements and honeymoons, is Rose Bazaar. We believe that since big events are always followed by an incredible party, our clients should be aware that we are able to plan these kinds of events.")} </p>
              <p className='pt-0 pb-1'>{t("Rose Bazaar may help you make the most romantic choice ever because it will be easier for you to collaborate to plan multiple significant events using a single planner.")} </p>
              <p className='pt-0 pb-1'>{t("In addition to providing newlyweds with reasonably cost products and services, we also provide happy invitations and wedding consultations. Remember to consult the Rose Bazaar list to determine the priorities and timeline for your wedding day.")} </p>
            </div>
              <div >
              <img src={card7} className='w-full pb-5 md:w-full px-10 md:pl-5  object-cover'/>
              </div>
              <img src={object} className='w-[101px] h-[140px] absolute right-[-21px] top-[-20px]' />
              <img src={object2} className='w-[85px] h-[101px] absolute left-[-14px] bottom-[-13px]' />
              <img src={vector4} className='w-[50px] absolute left-[12px] top-[16px]' />
          
          </div>
       </div>
     
       <div className={`flex justify-center ${i18n.language === 'en' ? 'flex-row' : 'flex-row-reverse'}`}>
              <img src={vector2} className='w-20 md:w-28' />
              <p className='text-red text-lg md:text-2xl lg:text-3xl ' > {t("Store")}</p>
              <img src={vector3} className='w-20 md:w-28' />
       </div>

       <div className='bg-white w-5/6 lg:w-3/4 mx-auto mb-20 mt-12  relative  border rounded-xl'>
       <div className=' w-full md:w-3/5'>
              <p className='p-10 lg:py-5 font-bold text:xl lg:text-3xl'>
              {t("Store")}
              </p>
          </div>
              <div className='flex flex-col-reverse justify-evenly md:flex-row '>
             <div className='flex flex-col px-3 md:px-10 w-full md:w-5/6'>
             <p className='pt-0 pb-1'> {t("Enjoy browsing our exclusive products and the greatest selection of products that will delight newlyweds on the Rose Bazaar website.Shopping with Rose Bazaar is an enjoyable experience. No doubt, the newlyweds, their family, and friends will find whatever they require at a fair price.")}</p>
                <p className='pt-0 pb-1'>{t("We offer a wide range of jewelers, lingerie, cosmetics, wedding presents, bridesmaid dresses, shoes, and headwear, as well as the widest assortment of bridal dresses, formal wear, and accessories for brides. Besides headwear, shoes, and bridesmaid gowns. Along with everything a groom could possibly require, we also carry a fantastic assortment of ties, watches, luxury perfumes, wedding suits, and shirts.")}</p>
                <p className='pt-0 pb-1'>{t("You may always choose from things that suit your style and satisfy your needs from a huge number of providers at incomparable prices, in addition to furniture, household appliances, decorative supplies, flowers, candles, and fragrances. Never doubt Rose Bazaar to provide for all of your needs.")}</p>
                </div>
              <div >
              <img src={card5} className='w-full pb-5 md:w-full px-10 md:pl-5  object-cover'/>
              </div>
              <img src={object} className='w-[101px] h-[140px] absolute right-[-21px] top-[-20px]' />
              <img src={object2} className='w-[85px] h-[101px] absolute left-[-14px] bottom-[-13px]' />
              <img src={vector4} className='w-[50px] absolute left-[12px] top-[16px]' />
          
          </div>
       </div>
      
      
       <div className={`flex justify-center ${i18n.language === 'en' ? 'flex-row' : 'flex-row-reverse'}`}>
              <img src={vector2} className='w-20 md:w-28' />
              <p className='text-red text-lg md:text-2xl lg:text-3xl ' >{t("Wedding Invitation")}</p>
              <img src={vector3} className='w-20 md:w-28' />
       </div>
       <div className='bg-white w-5/6 lg:w-3/4 mx-auto mb-20 mt-12  relative  border rounded-xl'>
       <div className=' w-full md:w-3/5'>
              <p className='p-10 lg:py-5 font-bold text:xl lg:text-3xl'>
              {t("Wedding Invitation")}
              </p>
          </div>
              <div className='flex flex-col-reverse justify-evenly md:flex-row '>
              <div className='flex flex-col px-3 md:px-10 w-full md:w-5/6'>
             <p className='pt-0 pb-1'>{t("The connected wedding guest list Are you trying to come up with a plan on how to incorporate everyone in your wedding guest list? In addition to helping.")}</p>
             <p className='pt-0 pb-1'>{t("You create your own invitations and guest list, Rose Bazaar can make sure that the people you love are there on the most significant day of your life.")}</p>
             <p className='pt-0 pb-1'>{t("Plan every aspect of your wedding, please. Utilizing our templates, make your own card invitations and invite as many people as possible. Send messages, track RSVPs, keep an eye on the response rates to your invitations, add guest lists, or import guest lists from your contact list or social media.")}</p>
             </div>
              <div >
              <img src={card4} className='w-full pb-5 md:w-full px-10 md:pl-5 object-cover'/>
              </div>
              <img src={object} className='w-[101px] h-[140px] absolute right-[-21px] top-[-20px]' />
              <img src={object2} className='w-[85px] h-[101px] absolute left-[-14px] bottom-[-13px]' />
              <img src={vector4} className='w-[50px] absolute left-[12px] top-[16px]' />
          
          </div>
       </div>

       
       <div className={`flex justify-center ${i18n.language === 'en' ? 'flex-row' : 'flex-row-reverse'}`}>
              <img src={vector2} className='w-20 md:w-28' />
              <p className='text-red text-lg md:text-2xl lg:text-3xl ' >{t("Wedding Plan")}</p>
              <img src={vector3} className='w-20 md:w-28' />
       </div>
       <div className='bg-white w-5/6 lg:w-3/4 mx-auto mb-20 mt-12  relative  border rounded-xl'>
       <div className=' w-full md:w-3/5'>
              <p className='p-10 lg:py-5 font-bold text:xl lg:text-3xl'>
              {t("Wedding Plan")}
              </p>
          </div>
              <div className='flex flex-col-reverse justify-evenly md:flex-row '>
              <div className='flex flex-col px-3 md:px-10 w-full md:w-5/6'>
             <p className='pt-0 pb-1'> {t("Together with the love of your life, select the necessities for your nuptials. Is it a specific date, the wedding's theme, or the venue? Maybe a particular wedding ceremony? In terms of your money, give those the utmost importance.")}</p>
            <p className='pt-0 pb-1'>{t(" Start early to get a list of everything you want done for your wedding and an estimated price for each item. Using this list to help you determine your estimated wedding day budget will be very helpful. Rose Bazaar helps you stay within your budget and focus on the things that are truly important. There can be a ton of ideas for a wedding. Avoid making an effort to multitask.")}</p>
            <p className='pt-0 pb-1'> {t("For this reason, you should strive to spend as little time as possible reviewing concepts and tasks in a single session. Browse through our selection of wedding plans to decide what you want and don't want, likes and dislikes. Rose Bazaar can assist you in determining what motivates you to achieve more success.")} </p>
                   </div>
                    <div >
                    <img src={card8} className='w-full pb-5 md:w-full px-10 md:pl-5 object-cover'/>
                    </div>
                    <img src={object} className='w-[101px] h-[140px] absolute right-[-21px] top-[-20px]' />
                    <img src={object2} className='w-[85px] h-[101px] absolute left-[-14px] bottom-[-13px]' />
                    <img src={vector4} className='w-[50px] absolute left-[12px] top-[16px]' />
             </div>
       </div>
       <div className={`flex justify-center ${i18n.language === 'en' ? 'flex-row' : 'flex-row-reverse'}`}>
              <img src={vector2} className='w-20 md:w-28' />
              <p className='text-red text-lg md:text-2xl lg:text-3xl ' >{t("Wedding Consultant")}</p>
              <img src={vector3} className='w-20 md:w-28' />
       </div>
       <div className='bg-white w-5/6 lg:w-3/4 mx-auto mb-20 mt-12  relative  border rounded-xl'>
       <div className=' w-full md:w-3/5'>
              <p className='p-10 lg:py-5 font-bold text:xl lg:text-3xl'>
              {t("Wedding Consultant")}
              </p>
          </div>
              <div className='flex flex-col-reverse justify-evenly md:flex-row '>
              <div className='flex flex-col px-3 md:px-10 w-full md:w-5/6'>
             <p className='pt-0 pb-1'>{t("Rose Bazaar offers couples the expertise of professional Wedding Consultants to assist them in coordinating their wedding and ensuring that their special day is perfect. Their focus is on ensuring that every detail is executed according to plan.")} </p>
             <p className='pt-0 pb-1'>{t("Wedding consultants can assist in ensuring that everything is prepared for your wedding day. In the event of any unexpected issues, they can discreetly and promptly handle the situation without the couple's awareness. Consultants are also able to coordinate with vendors to ensure that all aspects of the wedding, including food, flowers, decor, and staff, work together seamlessly to create a perfect wedding day.")}</p>
             <p className='pt-0 pb-1'>{t("Wedding consultants help ensure that your wedding turns out just the way you imagined. They provide assistance with financial, social, psychological, and health-related aspects, offering solutions to any challenges that may arise. They also work closely with the officiant to ensure that everything aligns with the couple's plans.")}</p>
              </div>
              <div >
              <img src={card6} className='w-full pb-5 md:w-96 px-10 md:pl-5 object-cover'/>
              </div>
              <img src={object} className='w-[101px] h-[140px] absolute right-[-21px] top-[-20px]' />
              <img src={object2} className='w-[85px] h-[101px] absolute left-[-14px] bottom-[-13px]' />
              <img src={vector4} className='w-[50px] absolute left-[12px] top-[16px]' />
          
          </div>
       </div>
      </div>
  );
}

export default About;