import React from 'react';
import Footer from "../components/default/Footer";
import {useSelector} from "react-redux";
import UserHeader from "../components/user/UserHeader";


function UserLayout({ children }) {
    const dark = useSelector(state => state.ui.dark)

    return (
        <div className={''}>
          <div className={"bg-white min-h-screen flex flex-col"}>
            <UserHeader position="sticky"/>

           
                { children }

            <Footer/>
          </div>
        </div>
    );
}

export default UserLayout;