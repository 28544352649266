import React, { useEffect, useState } from 'react';
import {Controller, useForm} from "react-hook-form";
import {defaultCountries, parseCountry, PhoneInput} from "react-international-phone";
import axios from "axios";
import {toast} from "react-toastify";
import {Link, useNavigate } from "react-router-dom";
import useAxiosUserSecureConfig from "../../../hooks/useAxiosUserSecureConfig";
import Select from 'react-select';
import Loader from "../../../components/Loader";
import { Checkbox } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import {Typography} from "@material-tailwind/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faCheck } from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";

function BookConsultant(){
  const { t, i18n } = useTranslation();
    const {
        register,
        control,
        formState: { errors },
        watch,
        handleSubmit
    } = useForm({ defaultValues: {country_id: 1, product_ids: []} });

    const [formSubmitted, setFormSubmitted] = useState(false);

    const selectedConsultations = watch([
      "create_wedding_schedule",
      "choosing_suppliers",
      "supervising_logistics",
      "managing_unexpected_challenges",
      "wedding_advisor",
      "handling_financial_plans",
    ]);

    const navigate = useNavigate();
    const customStyles = {
        control: (styles) => ({
            ...styles,
            background: "#F2F4F8", "border-color": "#F2F4F8" , "border-radius" :"8px" ,padding: "6px" })
    }

   
    // const [checkedValues, setCheckedValues] = useState([]);
    // const options = ['Option 1', 'Option 2', 'Option 3'];
    
    // function handleCheckboxChange(e, option) {
    //     const isChecked = e.target.checked;
    //     setCheckedValues(
    //       isChecked
    //         ? checkedValues.concat(option)
    //         : checkedValues.filter((value) => value !== option)
    //     );
    //   }


      const [isChecked, setIsChecked] = useState(false);

      const handleChange = (e) => {
        setIsChecked(e.target.checked);
        console.log("checked",setIsChecked)
      };
      const notify =()=>{
        toast.success("Your Inquiry has been Recived, Our Team will contact you soon! ");
       }
      const [showFullDescription , setShowFullDescripton] = useState(false);
      const [fullDescription , setFullDescription] = useState();
    
      const toggleDescription = (fullDescription) => {
       
        setShowFullDescripton(!showFullDescription);
        setFullDescription(fullDescription)
      }
      
    const config = useAxiosUserSecureConfig(); 
      const onSubmit = (data) => {
       
        data={
         name: data.name,
         bride_or_groom_name: data.Pname,
         location: data.location.value,
         phone: data.phone,
         wedding_date: data.weddingDate || false,
         details: data.Inquirie,
         estimated_guests: data.size,
         create_wedding_schedule: data.create_wedding_schedule,
         choosing_suppliers: data.choosing_suppliers,
         supervising_logistics: data.supervising_logistics,
         managing_unexpected_challenges: data.managing_unexpected_challenges,
         wedding_advisor:data.wedding_advisor,
         handling_financial_plans:data.handling_financial_plans,
        };
        if (selectedConsultations.some(Boolean)) {
          axios
            .post("/api/wedding-consultant-booking/create", data, config)
            .then((res) => {
              console.log("err", res.data);
              if (!res.data.result?.success) {
                toast.error(res.data.result?.msg || "Request failed!");
                return;
              }
              toggleDescription();
              toast.success(t("Your Inquiry has been Recived, Our Team will contact you soon!"));
              console.log("data", data);
            })
            .catch((err) => {
              console.log("err", err);
              toast.error("Error while Booking Consultant!");
            });
        }
      };
        
      const [open, setOpen] =useState(false);
      const handleClose = () => setOpen(false);

     

      const locationOptions =[
        {label:t("Zoom"), value:"zoom"},
        {label:t("Phone Call"), value:"phoneCall"},
      ];
    return (
      <div className=" relative">
        {showFullDescription && (
          <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-10 bg-gray-800 bg-opacity-50">
            <div className="bg-white p-8 rounded-2xl w-1/2 border-4 border-red">
              <p className="text-center text-balance">
               {t(" Your Inquiry Has Been Recived, Our Team Will Contact You Soon")}{" "}
              </p>
              <FontAwesomeIcon
                className=" md:mx-[33%] "
                icon={faCheck}
                size="10x"
                style={{ color: "#4dbf36" }}
              />
              <div className="flex justify-center">
                <Link
                  to={"/consultant"}
                  className="h-[42px] px-[18px] py-2.5 rounded-[10px] shadow border border-red justify-center items-center gap-2.5 inline-flex mt-4"
                  onClick={toggleDescription}
                >
                  <span className="text-center text-red text-base font-normal  leading-tight">
                    {" "}
                    {t("Close")}
                  </span>
                </Link>
              </div>
            </div>
          </div>
        )}
        <div className="justify-start items-center gap-2  hidden sm:inline-flex my-7 mx-4 md:mx-12">
          <div
            onClick={() => navigate("/consultant")}
            className="text-zinc-400 text-base font-normal leading-[48px] hover:text-gray-dark cursor-pointer"
          >
            {t("Wedding Consultant")}
          </div>
          <FontAwesomeIcon
            className="text-zinc-400 text-base"
            icon={faAngleRight}
          />
          <div
            onClick={() => navigate("/BookConsultant")}
            className="text-zinc-400 text-base font-normal leading-[48px] hover:text-gray-dark cursor-pointer"
          >
            {t("Book Consultant")}
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="w-4/5 mx-auto">
          <div>
            <p className="text-lg mb-11 text-red md:text-2xl font-semibold leading-9  ">
              {t("Book Consultant Session")}
            </p>
          </div>
          <div className=" w-full mx-auto flex-col md:grid grid-cols-2 justify-start items-start gap-4 inline-flex mb-10">
            <div className="self-stretch flex-col justify-start items-start gap-2 flex">
              <label className="text-zinc-800 text-base font-normal leading-snug">
                {t("Location")}
                <span className="text-red-500 text-base font-normal leading-snug">
                  {" "}
                  *{" "}
                </span>
              </label>
              <Controller
                rules={{ required: t("location is required") }}
                name="location"
                control={control}
                render={({ field, field: { name, ref } }) => (
                  <>
                    <Select
                      styles={customStyles}
                      name={name}
                      ref={ref}
                      isClearable
                      isSearchable
                      options={locationOptions}
                      {...field}
                      className="w-full"
                      value={field.value}
                      onChange={(selectedOption) => {
                        field.onChange(selectedOption);
                      }}
                      placeholder= {t("Select...")}
                    />
                  </>
                )}
              />
              {errors.location && (
                <Typography variant="small" color="red">
                  {errors.location?.message}
                </Typography>
              )}
            </div>

            <div className="self-stretch flex-col justify-start items-start gap-2 flex">
              <label className="text-zinc-800 text-base font-normal leading-snug">
                {t("Your name")}
                <span className="text-red-500 text-base font-normal leading-snug">
                  {" "}
                  *{" "}
                </span>
              </label>
              <Controller
                rules={{ required: t("Your name is required") }}
                name="name"
                control={control}
                render={({ field }) => (
                  <input
                    type="text"
                    {...field}
                    placeholder={t("name")}
                    className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                  />
                )}
              />
              {errors.name && (
                <Typography variant="small" color="red">
                  {errors.name?.message}
                </Typography>
              )}
            </div>

            <div className="self-stretch flex-col justify-start items-start gap-2 flex">
              <label className="text-zinc-800 text-base font-normal leading-snug">
                {t("Partner's Name")}
              </label>
              <Controller
                name="Pname"
                control={control}
                render={({ field }) => (
                  <input
                    type="text"
                    {...field}
                    placeholder={t("Partner name")}
                    className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                  />
                )}
              />
            </div>

            <div className="invitaion-verification self-stretch flex-col justify-start items-start gap-2 flex">
              <label className="self-stretch text-zinc-800 text-base font-normal font-['Roboto'] leading-snug">
                {t("Phone Number")}{" "}
                <span className="text-red-500 text-base font-normal leading-snug">
                  *
                </span>
              </label>

              <div className="w-full relative flex justify-between">
                <Controller
                  className="w-full"
                  control={control}
                  name="phone"
                  rules={{ required: "Phone Number is required" }}
                  render={({ field, field: { name, ref } }) => {
                    return (
                      <PhoneInput
                        className=""
                        error={errors.phone}
                        defaultCountry="eg"
                        inputStyle={{
                          background: "rgb(242 244 248",
                          width: "100%",
                          margin: "0px 4px",
                          border: "0px",
                          padding: "0 14px",
                          height: "46px",
                          borderRadius: "8px",
                        }}
                        name={name}
                        ref={ref}
                        {...field}
                      />
                    );
                  }}
                />
              </div>
              {errors.phone && (
                <Typography variant="small" color="red">
                  {errors.phone?.message}
                </Typography>
              )}
            </div>

            <div className="self-stretch flex-col justify-start items-start gap-2 flex">
              <div>
                <label className="text-zinc-800 text-base font-normal leading-snug">
                  {t("Wedding date")}
                </label>
              </div>
              <input
                type="date"
                {...register("weddingDate", {
                  validate: (value) =>
                    !value || new Date(value) > new Date()
                      ? true
                      : t("Wedding date must be in the future"),
                })}
                className="w-full h-12 px-4 py-3 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 font-normal leading-tight text-sm placeholder-[#808080]"
              />
              <Typography variant="small" color="red">
                {errors.weddingDate ? errors.weddingDate?.message : ""}
              </Typography>
            </div>

            <div className="flex flex-col w-full justify-start items-start gap-2 ">
              <label className="text-zinc-800 text-base font-normal leading-snug">
                {t("Estimated Number of Guest")}
              </label>
              <Controller
                control={control}
                name="size"
                render={({ field }) => (
                  <input
                    type="number"
                    {...field}
                    placeholder={t("Guest Number")}
                    className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                  />
                )}
              />
            </div>
          </div>

          <div className="flex flex-col w-full justify-start items-start gap-2">
        <label className="text-zinc-800 text-base font-bold leading-snug">
          {t("Which Consultation Service Do you want?")}{" "}
          <span className="text-red-500 text-base font-normal leading-snug">
            {" "}
            *{" "}
          </span>
        </label>
        <div className="w-full grid grid-cols-1 md:grid-cols-2">
              <div>
                <FormGroup aria-label="position" col>
                  <FormControlLabel
                    value="first"
                    control={
                      <Checkbox {...register("create_wedding_schedule")} />
                    }
                    label={t("Creating a Wedding Schedule")}
                  />
                  <FormControlLabel
                    value="sec"
                    control={<Checkbox {...register("choosing_suppliers")} />}
                    label={t("Choosing Suppliers")}
                  />
                  <FormControlLabel
                    value="third"
                    control={
                      <Checkbox {...register("supervising_logistics")} />
                    }
                    label={t("Supervising the Logistics")}
                  />
                </FormGroup>
              </div>
              <div>
                <FormGroup>
                  <FormControlLabel
                    value="fourth"
                    control={
                      <Checkbox
                        {...register("managing_unexpected_challenges")}
                      />
                    }
                    label={t("Managing Unexpected Challenges")}
                  />
                  <FormControlLabel
                    value="fifth"
                    control={<Checkbox {...register("wedding_advisor")} />}
                    label={t("Wedding Advisors")}
                  />
                  <FormControlLabel
                    value="sixth"
                    control={
                      <Checkbox {...register("handling_financial_plans")} />
                    }
                    label={t("Handling Financial Plans")}
                  />
                </FormGroup>
              </div>
            </div>
        {formSubmitted && !selectedConsultations.some(Boolean) && (
          <Typography variant="body2" color="red">
           {t("Please select at least one consultation service")}
          </Typography>
        )}
      </div>

          <div className="self-stretch flex-col justify-start mt-3 items-start gap-2 flex">
            <label className="text-zinc-800 text-base font-normal leading-snug">
             {t("More details about your inquiries")}
            </label>
            <Controller
              name="Inquirie"
              control={control}
              render={({ field }) => (
                <textarea
                  type="text"
                  {...field}
                  placeholder={t("text here...")}
                  className="w-full p-4 bg-slate-100 border-slate-100 rounded-lg text-zinc-500 text-sm font-normal leading-tight"
                ></textarea>
              )}
            />
          </div>
          <div className="flex  justify-center w-full gap-x-4 my-7">
            <Link
              to={"/consultant"}
              className="md:w-40 w-32 py-3  bg-white rounded-lg border border-red justify-center items-center inline-flex"
            >
              <span className="text-red text-base font-medium leading-none tracking-wide">
                {t("Cancel")}
              </span>
            </Link>

            <div className="flex items-center justify-between">
              {!showFullDescription && (
                <button
                onClick={()=>setFormSubmitted(true)}
                  type="submit"
                  className="md:w-40 w-32 py-3  bg-red rounded-lg border border-red justify-center items-center inline-flex"
                >
                  <span className="text-white text-base font-medium leading-none tracking-wide">
                    {t("Book Now")}
                  </span>
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    );
}
export default BookConsultant