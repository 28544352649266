import React from 'react'
import { faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom';
import { Spinner } from 'flowbite-react';
import axios from 'axios';
import {getMessages} from "../../App";
import useAxiosUserSecureConfig from '../../hooks/useAxiosUserSecureConfig';
import { toast } from 'react-toastify';
import Info from '../Info';
import {useTranslation} from "react-i18next";

function Notifications() {
  const user = useSelector(state => state.auth.user)
  console.log("user", user)
  const {t ,i18n } = useTranslation();
    const messages = useSelector(state => state.ui.messages)
    console.log("messages" , messages)
    const dispatch = useDispatch();
    const config = useAxiosUserSecureConfig();

    const handleClearAll = () => {
      axios
        .post("/api/notification/read-all", {}, config)
        .then((res) => {
          if (!res.data.result?.success) {
            toast.error(res.data.result?.message || t("Request failed!"));
            return;
          }
          toast.success(t("You clear all notifications successfully!"));
        })
        .catch((err) => {
          console.log("err", err);
          toast.error("Error while deleting notifications!");
        });
    };

  return (
    <section className="px-10 py-12">
      <div className="flex justify-between items-center">
        <div className="flex gap-3 items-center mb-6">
          <span className="text-red text-2xl font-medium">{t("Notifications")}</span>
          <span className="text-gray-500 text-base font-light">
            ({messages.items.length})
          </span>
        </div>
        {/* {messages.items.length > 0 && (
          <button
            onClick={() => handleClearAll()}
            className="text-red text-lg font-medium"
          >
            {t("Clear All")}
          </button>
        )} */}
      </div>
      <div className="flex flex-col gap-6">
        {!messages.loading && messages.items.length < 1 && (
          <Info msg={t("No Found Notifications!")} />
        )}
        {messages.loading && <Spinner className={"ml-3"} />}
        {!messages.loading &&
          messages.items.slice().reverse()
            .map((message, index) => (
              <div
                key={index}
                className="p-6 bg-orange-50 rounded-[10px] justify-between items-center inline-flex"
              >
                <div className="justify-start items-center gap-6 flex">
                  <div className="text-stone-500 text-lg font-normal font-['Roboto']">
                  { message.message}
                  </div>
                  {message.subject_type === "venue_reservation" &&
                    (message.subject_state === t("new") ||
                      message.subject_state === t("confirmed") ||
                      message.subject_state === "pending_payment") && (
                      <Link
                        to={`/user/booking-list/${message.subject_id}`}
                        className="text-blue-500 text-lg font-normal font-['Roboto'] underline"
                      >
                        {t("Check your booking")}
                      </Link>
                    )}
                  {message.subject_type === "venue_reservation" &&
                    message.subject_state === "declined" && (
                      <Link
                        to={`/venues`}
                        className="text-blue-500 text-lg font-normal font-['Roboto'] underline"
                      >
                        {t("Check another venues")}
                      </Link>
                    )}
                  {message.subject_type === "venue_reservation" &&
                    message.subject_state === "cancelled" &&
                    user.vendor_type === "venue" && (
                      <Link
                        to={`/vendor/booking-list`}
                        className="text-blue-500 text-lg font-normal font-['Roboto'] underline"
                      >
                        {t("Check your booking")}
                      </Link>
                    )}
                  {message.subject_type === "venue_reservation" &&
                    message.subject_state === "paid" && (
                      <Link
                        to={`/booking-review/${message.subject_id}`}
                        className="text-blue-500 text-lg font-normal font-['Roboto'] underline"
                      >
                        {t("Review Your Booking Venue Experience")}
                      </Link>
                    )}
                  {message.subject_type === "wedding_consultant_booking" &&
                    message.subject_state === "new" && (
                      <Link
                        to={`/ConsultationSession`}
                        className="text-blue-500 text-lg font-normal font-['Roboto'] underline"
                      >
                        {t("Check your Consultation Sessions")}
                      </Link>
                    )}
                  {message.subject_type === "service_booking" &&
                    message.subject_state === "cancelled" &&
                    user.vendor_type === "services" && (
                      <Link
                        to={`/serviceProvider/booking-list/${message.subject_id}`}
                        className="text-blue-500 text-lg font-normal font-['Roboto'] underline"
                      >
                        {t("Check the booking details")}
                      </Link>
                    )}
                  {message.subject_type === "service_booking" && 
                    (message.subject_state === "accepted" ||
                    message.subject_state === "rejected" ||
                    message.subject_state === "cancelled"
                     ) && (
                      <Link
                        to={`/user/services`}
                        className="text-blue-500 text-lg font-normal font-['Roboto'] underline"
                      >
                        {t("Check your bookings")}
                      </Link>
                    )}
                  {message.subject_type === "order" && (
                      <Link
                        to={`/user/order-details/${message.subject_id}`}
                        className="text-blue-500 text-lg font-normal font-['Roboto'] underline"
                      >
                        {t("Check your order details")}
                      </Link>
                    )}
                  {message.subject_type === "shipment" && (
                      <Link
                        to={`/ProductSupplier/shipment/${message.subject_id}`}
                        className="text-blue-500 text-lg font-normal font-['Roboto'] underline"
                      >
                        {t("Check order details")}
                      </Link>
                    )}
                </div>
                <button
                  onClick={(e) => {
                    axios
                      .post(`/api/notification/${message.id}/delete`, {}, config)
                      .then((res) => {
                        console.log("read message", res);
                        getMessages(dispatch, config, messages);
                      })
                      .catch((err) => {
                        console.log(err);
                        toast.error(t("Error reading message!"));
                      });
                  }}
                >
                  <FontAwesomeIcon icon={faX} />
                </button>
              </div>
            ))}
      </div>
    </section>
  );
}

export default Notifications
