import React, { useEffect } from 'react';
import {Button, Card, Collapse, IconButton, MobileNav, Navbar, Typography, collapse} from "@material-tailwind/react";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faBars,
  faClose,
  faFileArchive,
  faGlobeAfrica, faGlobeAmericas,
  faHome,
  faMobileRetro,
  faMoon,
  faSun,
  faHeart,
  faCartShopping,
} from "@fortawesome/free-solid-svg-icons";
import { FaRegHeart ,FaGlobeAfrica, FaGlobeAmericas  } from "react-icons/fa";
import { FiShoppingCart } from "react-icons/fi";
import {useDispatch, useSelector} from "react-redux";
import {uiActions} from "../../store/slicer/ui";
import r4bLogo from "../../assets/images/brand/r4b.png"
import {useTranslation} from "react-i18next";
import { useLocation } from "react-router-dom";
import logo from "../../assets/images/rose-bazaar.png";
import headerlogo from "../../assets/images/headerLogo.png";
import { useNavigate} from "react-router-dom";
import {totalItems} from "../../utils/utils";
import {Spinner} from "flowbite-react";




function Header({ }) {
  const location = useLocation();
  function pathMatchRoute(route) {
    if (route === location.pathname) return true;
  }
  const [openNav, setOpenNav] = React.useState(false);
  const dark = useSelector(state => state.ui.dark);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  React.useEffect(() => {
    window.addEventListener(
        "resize",
        () => window.innerWidth >= 1150 && setOpenNav(false)
    );
  }, []);

  const nav = [
    {
      name: t("Home"),
      to: "/",
    },
    {
      name: t("Wedding Venues"),
      to: "/venues",
    },
    {
      name: t("Wedding Services"),
      to: "/services",
    },
    {
      name: t("Store"),
      to: "/shop",
    },
    {
      name: t("Wedding Invitation"),
      to: "/invitation",
    },
    {
      name: t("Wedding Plan"),
      to: "/create-wedding-plan",
    },
    {
      name: t("Wedding Consultant"),
      to: "/consultant",
    },
    {
      name: t("About Us"),
      to: "/about",
    },
    
    {
      name: t("Contact Us"),
      to: "/contact",
    },
  ];

  const navigate = useNavigate();
  const cart = useSelector((state) => state.cart);


  const navList = (
      <ul className=" flex flex-col lg:flex-row lg:items-center mx-8 xl:mx-[150px] justify-between">
        {nav.map(item =>
            <Typography
                key={item.name}
                as="li"
                variant="small"
                className="cursor-pointer py-3 " 
            >
              
              <Link to={item.to}   className={`lg:text-zinc text-white text-base font-normal font-['Roboto'] leading-none py-3 transition-all ease-linear
              hover:font-medium lg:hover:text-red  hover:text-gray-dark
              ${pathMatchRoute(item.to) && " !font-medium lg:!text-red !text-gray-dark  border-b-2 border-b-red"} `}>
                 <span onClick={() => window.innerWidth <= 768 ? (setOpenNav(!openNav)):null}> {item.name}</span>
              </Link>
             
              {/* <span className="absolute bottom-0 left-1/2 w-0 transition-all h-[2px] bg-rose dark:bg-dark-50"></span> */}
              {/* <span className="absolute bottom-0 right-1/2 w-0 transition-all h-[2px] bg-rose dark:bg-dark-50"></span> */}
            </Typography>
        )}
      </ul>
  );

  return (
    <div className=" w-full shadow-md">
    <nav className="w-full py-2 md:py-3 relative z-50 top-0 h-max max-w-full px-4 lg:px-10 rounded-none bg-red border-0 ">
      <div className="flex items-center justify-between">
        <div className={"flex flex-wrap align-middle gap-x-1 "}>
            <Link to={"/"} className="">
            <div className="w-fit flex flex-col items-center">
                  <img src={logo} alt="" className="mx-auto w-12 md:w-16 " />
                  <div className="text-white text-[12px] md:text-[14px] font-medium font-['Roboto'] uppercase leading-[16.80px] tracking-wider">
                  Rose Bazaar
                  </div>
                </div>
            </Link>
        </div>
        <div className="flex items-center"> 
          <div className="flex items-center">
          <button
                   className="text-2xl px-6 hidden lg:flex"
                  onClick={() => i18n.changeLanguage(i18n.language === 'en' ? 'ar' : 'en').then(() => window.location.reload(true))}
              >
                {i18n.language === 'en' ? (
                    <FaGlobeAfrica className="text-white text-2xl"  />
                ) : (
                    <FaGlobeAmericas className="text-white text-2xl" />

                )}
              </button>
              {/* <IconButton
              variant="text"
              className="rounded-full"
              ripple={false}
              onClick={() => dispatch(uiActions.toggleDarkMode())}
              >
              {dark ? (
                  <FontAwesomeIcon icon={faSun}/>
              ) : (
                  <FontAwesomeIcon icon={faMoon}/>
              )}
              </IconButton> */}
              <div className="flex gap-2">
                <Link className='p-3 rounded-md border border-white justify-center items-center inline-flex' to={"/login"}>
                    <span className='text-white text-base font-normal leading-tight'>{t('Sign In')}</span>
                </Link>
                <Link className=' p-3 rounded-md border border-white justify-center items-center hidden lg:inline-flex' to={"/register/vendor"}>
                    <span className=' text-white text-base font-normal leading-tight '>{t('Become a Vendor')}</span>
                </Link>
                <Link className='p-3 rounded-md border bg-white  border-white justify-center items-center hidden lg:inline-flex' to={"/register"}>
                    <span className=' text-gray-dark text-base font-normal leading-tight'>{t("Register Now")}</span>
                </Link>
              </div>
              <IconButton
                  variant="text"
                  color='white'
                  className="text-white ml-auto h-6 w-6 hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
                  ripple={false}
                  onClick={() => setOpenNav(!openNav)}
              >
                {openNav ? (
                    <FontAwesomeIcon icon={faClose} size={"xl"}/>
                ) : (
                    <FontAwesomeIcon icon={faBars} size={"xl"}/>
                )}
              </IconButton>
          </div>
        </div>

            
      </div>
      <Collapse open={openNav}>
        {navList}
        <div className="flex items-center border-t py-5"> 
          <button
                    className="text-2xl border-e px-6 items-center "
                    onClick={() => ''}
                >
                  <FaRegHeart className="text-white " />
            </button>
            {cart.loading && <Spinner className={"ml-3"}/> }
            {!cart.loading &&
            <div className="relative cursor-pointer">
              <button
                className="text-2xl border-e px-6 items-center flex"
                onClick={() => navigate('/cart')}
              >
                <FiShoppingCart className="text-white text-2xl" />
              </button>
              <span className="bg-[#EB2606] absolute top-0 end-4 text-white w-4 h-4  text-[10px] flex items-center justify-center rounded-[50%]">{totalItems(cart.items)}</span>
            </div>
            }
            <button
                   className="text-2xl px-6 "
                  onClick={() => i18n.changeLanguage(i18n.language === 'en' ? 'ar' : 'en').then(() => window.location.reload(true))}
              >
                {i18n.language === 'en' ? (
                    <FaGlobeAfrica className="text-white text-2xl"  />
                ) : (
                    <FaGlobeAmericas className="text-white text-2xl" />

                )}
            </button>
        </div>
        <div className="flex gap-2 py-3">
                <Link className='p-2 rounded-md border border-white justify-center items-center ' to={"/login"}>
                    <span className='text-white text-sm font-normal leading-tight'>{t('Sign In')}</span>
                </Link>
                <Link className=' p-2 rounded-md border border-white justify-center items-center' to={"/register/vendor"}>
                    <span className=' text-white text-sm font-normal leading-tight '>{t('Become a Vendor')}</span>
                </Link>
                <Link className='p-2 rounded-md border bg-white  border-white justify-center items-center' to={"/register"}>
                    <span className=' text-gray-dark text-sm font-normal leading-tight'>{t("Register Now")}</span>
                </Link>
        </div>
        
        
      </Collapse>

    </nav>
    <div className="menu z-10 bg-white py-2 sticky top-0 hidden lg:block">
      <div className="mr-4 ">{navList}</div>
    </div>
    </div>
  );
}

export default Header;