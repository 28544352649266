import React from 'react';
import {useSelector} from "react-redux";
import UserHeader from "../../components/user/UserHeader"
import ProductsSidebar from '../../components/products/ProductsSidebar';
import { useState } from 'react';
import Products from '../../components/products/Products';



function Shop() {

    const [selectedCategory, setSelectedCategory] = useState(null);


    const handleChange = (event) => {
        setSelectedCategory(event.target.value);
      };

    
      function filteredData(products, selected) {
        let filteredProducts = products;
    
        
        if (selected) {
          filteredProducts = filteredProducts.filter(
            ({ category }) =>
              category === selected 
          );
        }
    
        return filteredProducts;
      }
    

    const dark = useSelector(state => state.ui.dark)

    return (
        <div className={ dark ? "dark" : null}>
            <div className="flex h-screen">
                <ProductsSidebar handleChange={handleChange} />
                <div className="flex-1 flex flex-col overflow-hidden">
                <UserHeader/>
                <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100 p-4 dark:bg-dark-50">
                    <Products />
                </main>
                </div>
            </div>
        </div>
    );
}

export default Shop;