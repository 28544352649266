import React, { useEffect, useState } from 'react';
import { useTable, useSortBy, usePagination } from 'react-table';
import ReactPaginate from 'react-paginate';
import useAxiosUserSecureConfig from '../../../hooks/useAxiosUserSecureConfig';
import axios from 'axios';
import { toast } from 'react-toastify';
import Loader from '../../../components/Loader';
import { useNavigate } from 'react-router';
import editIcon from "../../../assets/images/icons/edit.png";
import trashIcon from "../../../assets/images/icons/trash.png";
import eyeIcon from "../../../assets/images/icons/eye.png";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { Controller, useForm } from "react-hook-form";
import Select from 'react-select';
import ConfirmationModal from '../../../components/ConfirmationModal';
import { useSelector } from "react-redux";
import filterIcon from "../../../assets/images/sevices/filter.png";
import {useTranslation} from "react-i18next";


function ProductList() {
  const { t, i18n } = useTranslation();
  const [currentPage, setCurrentPage] = useState(0);
  const config = useAxiosUserSecureConfig();
  const user = useSelector(state => state.auth.user)
  const [products, setProducts] = useState({ loading: true, data: [] });
  const [filter, setFilter] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showActiveDropdown, setActiveShowDropdown] = useState(false);
  const columns = React.useMemo(
    () => [
      { Header: t('Product Image'), accessor: 'productImage' },
      { Header: t('Product Name'), accessor: 'productName' },
      { Header: t('Product Category'), accessor: 'productCategory' },
      { Header: t('Product Brand'), accessor: 'productBrand' },
      { Header: t('Product Weight'), accessor: 'productweight' },
      { Header: t('Product Price'), accessor: 'productPrice' },
     ],
    [t]
  );
  const handleFilterChange = (value) => {
    setFilter(value);
    setShowDropdown(false);
  };


const fetchData = () => {
  axios.post('/api/my_products', {}, config)
    .then(async(res) => {
      console.log("products", res.data);
      if (!res.data?.result?.success) {
        toast.error(t("Error while fetching products!"));
        return;
      }
      
      const productsWithImages = await Promise.all(
        res.data.result.products.map(async (product) => {
          try {
            const imageRes = await axios.post(`/api/product/${product.id}/image`, {}, config);
            if (!imageRes.data.result?.success) {
              throw new Error(imageRes.data.result?.message || t("Request failed!"));
            }
            return { ...product, image: imageRes.data.result.image };
          } catch (error) {
            console.error('Error fetching image:', error);
            return { ...product, image: null };
          }
        })
      );
      setProducts({
        loading: false,
        data: productsWithImages.slice().reverse() || [],
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
useEffect(() => {
  fetchData();
}, []);

  const reqestsData = React.useMemo(() => {
    return products.data.length > 0
      ? products.data
          .map((key) => ({
            id: key.id,
            productName: key.name,
            productCategory : key.category.name,
            productBrand : key.brand,
            productweight : key.weight,
            productPrice : key.list_price,
            categoryId :key.category.id,
            productImage : key.image,
            enabledByUser: key.enabled_by_user,
          }))
      : [];
  }, [products.data]);
  console.log( "request",reqestsData)

  const [productStatusFilter, setProductStatusFilter] = React.useState('Active');

  const filteredRows = React.useMemo(() => {
  let rows = reqestsData;
  if (filter) {
    rows = rows.filter((row) => row.categoryId === filter);
  }
  if (productStatusFilter === 'Active') {
    rows = rows.filter((row) => row.enabledByUser === true);
  } else if (productStatusFilter === 'Archived') {
    rows = rows.filter((row) => row.enabledByUser === false);
  }
  return rows;
}, [filter, productStatusFilter, reqestsData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data: filteredRows,
      initialState: { pageIndex: currentPage, pageSize:  20 }, 
    },
    useSortBy,
    usePagination
  );
  const [categories, setCategories] = useState({ loading: true, data: [] });
  useEffect(() => {
    axios.post(`/api/product_categories`, {} ,{headers: {
      'Accept-Language': 'ar'}
    }, config).then((res) => {
        console.log("categories", res.data);

        if (!res.data?.result?.success) {
            toast.error(t("Error while fetching categories!"));
            return;
        }

        setCategories(prevState => ({
            ...prevState,
            loading: false,
            data: res.data?.result?.categories ?? []
        }))
    }).catch((err) => {
        console.log(err);
    })
}, [])
console.log("cate",categories)

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const navigate = useNavigate()
  const {
    control,
  } = useForm();

  const customStyles = {
    control: (styles) => ({
      ...styles,
      background: "#F2F4F8", "border-color": "#F2F4F8" , "border-radius" :"8px" ,padding: "6px" })
    }

  return (
    <section className="px-5 sm:px-10 py-12">
      <div className="flex justify-between items-center mb-5">
        <div className="flex items-center gap-2 sm:gap-6">
          <div className="justify-start items-center gap-2 hidden sm:inline-flex">
            <div
              onClick={() => navigate('/product-Supplier/Account')}
              className="text-zinc-400 text-sm sm:text-base font-normal md:leading-[48px] hover:text-gray-dark cursor-pointer"
            >
              {t("Product Supplier Account")}
            </div>
            <FontAwesomeIcon
              className="text-zinc-400 text-base"
              icon={faAngleRight}
            />
            <div onClick={()=>navigate("/productSupplier/productList")} className="text-zinc-400 text-sm sm:text-base font-normal md:leading-[48px] hover:text-gray-dark cursor-pointer">
              {t("My Products")}
            </div>
          </div>
        </div>
       
      </div>
      {products.loading && <Loader slim/>}
      {!products.loading && <>
      <div className="flex justify-between items-center mb-8">
        <div className="justify-start items-center gap-2 inline-flex">
          <div className="text-lg md:text-2xl font-semibold leading-9">{t("My Products")}</div>
          <div className="text-base font-light leading-normal">
            ({filteredRows.length})
          </div>
        </div>
        <div>
          <div className='flex flex-col sm:flex-row gap-2 '>
            <Link
              to={'/ProductSupplier/add-product'}
              className="px-3  ml-2 sm:px-8 py-[9px] rounded-lg shadow border border-red justify-center items-center gap-2.5 flex"
            >
              <span className="text-center text-red text-base font-medium font-['Inter'] leading-tight">
                {t("Add Products")}
              </span>
            </Link>
            <div className="relative">
              <button
              className="px-3 sm:px-6 py-2 ml-2 rounded-lg border border-red justify-center items-center flex gap-2"
              onClick={() => setShowDropdown(!showDropdown)}
            >
              <img src={filterIcon} alt="filterIcon" />
              <span className="text-red text-base font-medium font-['Inter'] leading-none tracking-wide">
                {t("Category")}
              </span>
              <FontAwesomeIcon
                className="text-red text-base font-medium leading-none tracking-wide"
                icon={faCaretDown}
              />
            </button>
            {showDropdown && (
              <ul className="absolute right-0 mt-2 py-2 w-48 bg-white border border-gray-200 rounded-lg shadow-lg z-10">
                <li
                  className="cursor-pointer px-4 py-2 hover:bg-gray-100"
                  onClick={() => handleFilterChange(null)}
                >
                  {t("Show All")}
                </li>
                {categories.data.map((category) => (
                  <li
                    key={category.id}
                    className="cursor-pointer px-4 py-2 hover:bg-gray-100"
                    onClick={() => handleFilterChange(category.id)}
                  >
                    {category.name}
                  </li>
                ))}
              </ul>
              )}
            </div>
            <div className="relative">
              <button
                className="h-full px-3 ml-2 sm:px-8 py-[9px] rounded-lg shadow border border-red justify-center items-center gap-2.5 flex"
                onClick={() => setActiveShowDropdown(!showActiveDropdown)}
              >
                <span className="text-red text-base font-medium font-['Inter'] leading-none tracking-wide">
                  {productStatusFilter === 'Active' ? t('Active Products') : t('Archived Products')}
                </span>
                <FontAwesomeIcon
                  className="text-red text-base font-medium leading-none tracking-wide"
                  icon={faCaretDown}
                />
              </button>
              {showActiveDropdown && (
                <ul className="absolute right-0 mt-2 py-2 w-48 bg-white border border-gray-200 rounded-lg shadow-lg z-10">
                  <li
                    className="cursor-pointer px-4 py-2 hover:bg-gray-100"
                    onClick={() => setProductStatusFilter('Active')}
                  >
                    {t('Active Products')}
                  </li>
                  <li
                    className="cursor-pointer px-4 py-2 hover:bg-gray-100"
                    onClick={() => setProductStatusFilter('Archived')}
                  >
                    {t('Archived Products')}
                  </li>
                </ul>
              )}
            </div>
          </div>
          
        </div>
      </div>
    <div className='!overflow-x-auto'>
        <table
          {...getTableProps()}
          className="w-full table-auto border-collapse mb-10"
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr 
                className="h-12 bg-slate-100"
                {...headerGroup.getHeaderGroupProps()}
              >
                <th className="py-2 px-4 border text-gray-500 text-base font-medium leading-snug">
                  {"#"}
                </th>{" "}
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={`py-2 px-4 border text-gray-500 text-base font-medium leading-snug ${
                      column.isSorted
                        ? column.isSortedDesc
                          ? "sort-desc"
                          : "sort-asc"
                        : ""
                    }`}
                  >
                    {column.render("Header")}
                  </th>
                ))}
                <th
                  className="py-2 px-4 border text-gray-500 text-base font-medium leading-snug"
                >
                 {t("Actions")}
                </th>
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows
              .slice(currentPage * 20, (currentPage + 1) * 20)
              .map((row, index) => {
                prepareRow(row);
                return (
                  <tr
                  onClick={()=>navigate(`/productSupplier/product/${row.original.id}`)}
                    {...row.getRowProps()}
                    className="border-t h-12 cursor-pointer hover:bg-slate-100"
                  >
                    <td className="px-4 border text-center bg-slate-100 text-gray-500 text-xs font-light leading-none">
                      {index + 1 + currentPage * 20}
                    </td>{" "}
                    {row.cells.map((cell, cellIndex) => (
                      <td
                        {...cell.getCellProps()}
                        className="px-2 border text-center text-[#697077] text-sm font-normal leading-tight"
                        key={cellIndex}
                      >
                        {cell.column.Header === t("Product Image") ? (
                          <div className="w-full flex justify-center items-center p-1">
                            <img className="w-20 h-12 object-cover border" src={row.original.productImage} />
                          </div>
                        ) : (
                          cell.render("Cell")
                        )}
                      </td>
                    ))}
                    <td className="px-2 border text-center ">
                      <button
                        onClick={(e) =>
                          {e.stopPropagation();
                          navigate(
                            `/ProductSupplier/edit-product/${row.original.id}`,
                            {
                              state: { rowData: row.original },
                            }
                          )}
                        }
                        className=""
                      >
                        <img src={editIcon} alt="editIcon" />
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        </div>
      </>}
     
      <ReactPaginate
        breakLabel="..."
        nextLabel={t("Next >")}
        onPageChange={handlePageClick}
        pageRangeDisplayed={Math.ceil(rows.length / 20)}
        pageCount={Math.ceil(rows.length / 20)}
        previousLabel={t("< Previous")}
        renderOnZeroPageCount={null}
        containerClassName="pagination"
        pageLinkClassName="page-num"
        previousLinkClassName="page-btn"
        nextLinkClassName="page-btn"
        activeLinkClassName="active"
        disabledLinkClassName="disabled-btn"
      />
    </section>
  );
}

export default ProductList;
