import React, { useState } from 'react';
import {FaHeart, FaRegHeart} from "react-icons/fa";
import {FiShoppingCart} from "react-icons/fi";
import {ViewStyle} from "./Shop";
import placholder_image from "../../assets/images/booking/placeholder-image.jpg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';
import {useTranslation} from "react-i18next";


function ProductCard({ product, navigate, view, handleAddToCart, cartItems ,handleAddToWishList , deleteFromWishList, isProductInWishList }) {
  const { t, i18n } = useTranslation();
  const [showFullDescription , setShowFullDescripton] = useState(false);
  const [fullDescription , setFullDescription] = useState();
  const toggleDescription = (e , fullDescription) => {
    e.stopPropagation()
    setShowFullDescripton(!showFullDescription);
    setFullDescription(fullDescription)
  }
  return (
    <>
      {showFullDescription && (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-10 bg-gray-800 bg-opacity-50">
          <div className="bg-white p-8 rounded-2xl border-4 border-red w-1/2">
            <div className="flex justify-end">
              <button
                onClick={toggleDescription}
                className="self-stretch justify-between items-center flex"
              >
                <FontAwesomeIcon icon={faX} className="text-xl text-gray-500" />
              </button>
            </div>
            <p>{fullDescription}</p>
            <div className="flex justify-center">
              <button
                className="h-[42px] px-[18px] py-2.5 rounded-[10px] shadow border border-red justify-center items-center gap-2.5 inline-flex mt-4"
                onClick={toggleDescription}
              >
                <span className="text-center text-red text-base font-normal  leading-tight">
                  {" "}
                  {t("Close")}
                </span>
              </button>
            </div>
          </div>
        </div>
      )}
      <card
        className={
          view === ViewStyle.Grid
            ? "flex flex-col cursor-pointer"
            : "flex w-full gap-3 cursor-pointer pb-4 border-b"
        }
        onClick={() =>
          navigate(`/product/${product.product.id}`, {
            state: { data: product.id },
          })
        }
      >
        <div className="relative mb-6 ">
          <button
            className={
              view === ViewStyle.Grid
                ? "absolute top-4 left-6 text-zinc bg-red rounded-full p-1 bg-opacity-30"
                : "absolute top-2 left-2 text-zinc bg-red rounded-full p-1 bg-opacity-30"
            }
            onClick={(e) => {
              if (isProductInWishList(product.id)) {
                deleteFromWishList(e, product.id);
              } else {
                handleAddToWishList(e, product.id);
              }
            }}
          >
            <FaHeart
              size={24}
              className={`${
                isProductInWishList(product.id) ? "text-red" : "text-white"
              }`}
            />
          </button>
          <img
            className={
              view === ViewStyle.Grid
                ? "mx-auto rounded-lg h-[200px] w-full object-cover border"
                : "mx-auto rounded-lg w-[225px] h-[150px] object-cover border"
            }
            src={product.image || placholder_image}
            alt=""
          />
        </div>
        <div>
          <div
            className={
              view === ViewStyle.Grid
                ? "flex justify-between w-full"
                : "flex gap-5 whitespace-nowrap"
            }
          >
            <span className="text-gray-dark text-base font-extrabold leading-normal">
              {product.name2}
            </span>
            <div className={`text-red text-base font-semibold font-['Roboto'] leading-normal text-end flex justify-center 
            ${i18n.language === 'en' ? 'flex-row' : 'flex-row-reverse'}`}>
              <div>{product.price}</div>
              <div>EGP</div> 
            </div>
          </div>
          <div className="my-4">
            <p className="text-zinc text-sm font-normal leading-snug overflow-hidden line-clamp-2">
              {" "}
              {product.product.description.replace(/<\/?p>/g, "")}
            </p>
          </div>
          <div className="flex justify-between items-start">
            <div className="flex items-center gap-2">
            {product.condition === "used" ? (
              <div className="w-14 px-3 py-0.5 bg-[#CCCCCC] rounded-[22px] justify-start items-start gap-[10px] inline-flex">
                <div className="text-[#4C4C4C] text-base font-normal font-['Roboto'] leading-relaxed">
                  {t("Used")}
                </div>
              </div>
            ) : (
              <div className="w-14 px-3 py-0.5 bg-red rounded-[22px] justify-start items-start gap-[10px] inline-flex">
                <div className="text-white text-base font-normal font-['Roboto'] leading-relaxed">
                  {t("New")}
                </div>
              </div>
            )}
            {product.quantity === 0 && <div className="px-3 py-0.5 bg-zinc-400 rounded-[22px] justify-start items-start gap-[10px] inline-flex">
                    <div className="text-white text-base font-normal font-['Roboto'] leading-relaxed">
                    {t("Sold Out")}
                    </div>
                  </div>}
            </div>
            {!showFullDescription && (
              <button
                className="text-red text-end"
                onClick={(e) =>
                  toggleDescription(
                    e,
                    product.product.description.replace(/<\/?p>/g, "")
                  )
                }
              >
                {t("Read More")}
              </button>
            )}
          </div>
        </div>
      </card>
    </>
  );
}

export default ProductCard;