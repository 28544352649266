import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight ,faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import invitationImg from "../../../assets/images/invitation/invitation.png";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {useTranslation} from "react-i18next";
import axios from "axios";
import useAxiosUserSecureConfig from "../../../hooks/useAxiosUserSecureConfig";

function Invitation() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const user = useSelector(state => state.auth.user);
  const config = useAxiosUserSecureConfig();
  const [invitaions, setInvitations] = useState({ loading: true, data: [] });

  useEffect(() => {
    axios.post('/api/invitation', {}, config)
      .then((res) => {
        console.log("invitations", res.data);
        if (!res.data?.result?.success) {
          toast.error("Error while fetching invitations!");
          return;
        }
        setInvitations({
          loading: false,
          data: res.data?.result?.invitations?.slice().reverse() || [],
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user]);

  return (
    <section>
      <div className="flex items-center bg-neutral-50 relative">
        <img src={invitationImg} alt="invitationImg" className="hidden md:block" />
        <div className={`flex justify-center items-center md:absolute ${i18n.language === 'en' ?'right-[10%]':'left-[10%]' } w-full md:w-auto`}>
          <div className="md:bg-invition-frame bg-[100%] lg:px-[88px] lg:pt-[106px] lg:pb-[120px] flex flex-col items-center justify-center  lg:w-[600px] lg:h-[600px] w-[550px] h-[550px] bg-[length:550px] lg:bg-auto bg-no-repeat">
          <div className="text-center text-zinc-800 text-lg lg:text-2xl font-semibold leading-relaxed mb-4">
            {t("Welcome to wedding invitaion")}
          </div>
          <div className="w-full px-2 sm:px-0 sm:w-[382px] text-zinc-800 text-base lg:text-xl font-normal leading-7 mb-10 text-center">
            {t("At RoseBazzar Wedding Invitation, we believe that your wedding invitation should be as unique as your love story. That's why we offer a wide range of invitation designs that can be customized to fit your style and preferences. Whether you're looking for a classic, elegant design or something more modern and unique, we have you covered.")}
          </div>
          <div className="flex flex-col items-center justify-center gap-2">
            <button
              onClick={() => user === null ? (toast.error("You must be logged in"), navigate("/login")) : navigate("/invitation/create")}
              className="self-stretch lg:w-[285px] lg:h-12 p-4 bg-red rounded-lg justify-center items-center inline-flex gap-3"
            >
              <span className="text-white text-base font-medium leading-none tracking-wide">
                {t("Create Wedding Invitation")}
              </span>
              {i18n.language === 'en' ? (
                    <FontAwesomeIcon className='text-white text-base font-medium' icon={faArrowRight} />
                ) : (<FontAwesomeIcon className='text-white text-base font-medium' icon={faArrowLeft} />)}
            </button>
           {(!invitaions.loading && invitaions.data.length > 0) && <button
              onClick={() => navigate("/invitations")}
              className="self-stretch lg:w-[285px] lg:h-12 p-4 bg-white border border-red rounded-lg justify-center items-center inline-flex gap-3"
            >
              <span className="text-red text-base font-medium leading-none tracking-wide">
                {t("Go to My Invitations")}
              </span>
              {i18n.language === 'en' ? (
                    <FontAwesomeIcon className='text-red text-base font-medium' icon={faArrowRight} />
                ) : (<FontAwesomeIcon className='text-red text-base font-medium' icon={faArrowLeft} />)}
            </button>}
          </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Invitation;