import React, { useEffect, useState } from "react";
import { AiFillStar } from "react-icons/ai";
import Loader from "../../components/Loader";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import useAxiosUserSecureConfig from "../../hooks/useAxiosUserSecureConfig";
import BookingPaymentRequest from "../../components/venues/BookingPaymentRequest";
import BookingCancellation from "../../components/venues/BookingCancellation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { addDays, differenceInDays } from "date-fns";
import {useTranslation} from "react-i18next";

function BookingList() {
  const isDisabled = (confirmedDate) => {
    const currentDate = new Date();
    const confirmedDateTime = new Date(confirmedDate);
    const timeDifference = currentDate.getTime() - confirmedDateTime.getTime();
    const remainingMilliseconds = 51 * 60 * 60 * 1000 - timeDifference;
    return remainingMilliseconds <= 0;
  };
  const {t ,i18n } = useTranslation();
  const [open, setOpen] =useState(false);
  const handleClose = () => setOpen(false);
  const handlePaymentOpen = (e , id) =>{ 
    setItemId(id)
    e.stopPropagation();
    setOpen(true);
  }
  const [openCancellationModal, setOpenCancellationModal] =useState(false);
  const handleCancelModalClose = () => setOpenCancellationModal(false);
  const [itemId , setItemId] = useState(null) 
  const handleCancellationOpen = (e , id) => {
    setItemId(id)
    e.stopPropagation();
    setOpenCancellationModal(true);
  }
 
  const config = useAxiosUserSecureConfig();
  const navigate = useNavigate();
  const [bookingList, setBookingList] = useState({ loading: true, data: [] });
  axios.defaults.timeout = 50000
  axios.defaults.timeoutErrorMessage='timeout'

  useEffect(() => {
    axios.post('/api/venue-bookings', {} , config).then((res) => {
      console.log("bookings", res.data);
  
      if (!res.data?.result?.success) {
        toast.error("Error while fetching bookings!");
        return;
      }
  
      setBookingList((prevState) => ({
        ...prevState,
        loading: false,
        data: res.data?.result?.reservations?.slice().reverse()
      }));
      
      

    }).catch((err) => {
      console.log(err);
    })
    
  }, []);
 

  
  const [currentItems , setCurrentItems] = useState(bookingList.data)
  const [pageCount , setPageCount] = useState(0)
  const [itemOffset , setItemOffset] = useState(0)
  const [itemsPerPage , setItemsPerPage] = useState(5) ;

  useEffect(()=>{
    const endOffSet = itemOffset + itemsPerPage;
    setCurrentItems(bookingList.data.slice(itemOffset , endOffSet));
    setPageCount(Math.ceil(bookingList.data.length / itemsPerPage));
  },[itemOffset ,itemsPerPage , bookingList.data])

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % bookingList.data.length;
    setItemOffset(newOffset);
  };
  const [activeState, setActiveState] = useState(null);
  const handleListFiltration = (state) => {
    setActiveState(state);
    setBookingList((prevState) => ({
      ...prevState,
      loading: true,
    }));
    axios.post('/api/venue-bookings', {}, config)
      .then((res) => {
        console.log("bookings", res.data);
        if (!res.data?.result?.success) {
          toast.error(t("Error while fetching bookings!"));
          return;
        }
        let filteredBookings;
        if (state === null) {
          filteredBookings = res.data?.result?.reservations?.slice().reverse();
        } else {
          filteredBookings = res.data?.result?.reservations?.slice().reverse().filter(item => item.state.id === state);
        }
        setBookingList((prevState) => ({
          ...prevState,
          loading: false,
          data: filteredBookings,
        }));
        setCurrentItems(filteredBookings);
      })
      .catch((error) => {
        console.error("Error fetching bookings:", error);
        toast.error(t("Error while fetching bookings!"));
      });
  }

  const calculateDaysLeft = (paymentDate) => {
    const now = new Date();
    const paymentDateObj = new Date(paymentDate);
    const targetDate = addDays(paymentDateObj, 14);
    let days = differenceInDays(targetDate, now);
    if (days < 0) {
      days = 0;
    }
    return days;
  };
  
  return (
    <section className="xl:px-10 px-5 py-12">
      <BookingCancellation
        openCancellationModal={openCancellationModal}
        handleCancelModalClose={handleCancelModalClose}
        itemId={itemId}
        setBookingItem={setBookingList}
      />
      <BookingPaymentRequest
        open={open}
        handleClose={handleClose}
        itemId={itemId}
        setBookingItem={setBookingList}
      />
      <div className="justify-start items-center gap-2 hidden sm:inline-flex mb-5">
        <div
          onClick={() => navigate("/user/account")}
          className="text-zinc-400 text-base font-normal leading-[48px] hover:text-gray-dark cursor-pointer"
        >
          {t("My Account")}
        </div>
        <FontAwesomeIcon
          className="text-zinc-400 text-base"
          icon={faAngleRight}
        />
        <div className="text-zinc-400 text-base font-normal leading-[48px] hover:text-gray-dark cursor-pointer">
          {t("My Booking list")}
        </div>
      </div>
      <div className="flex items-center justify-between mb-6 flex-wrap gap-[10px]">
        <div className="justify-start items-center gap-3 inline-flex">
          <p className="text-red text-2xl font-medium">{t("Booking list")}</p>
          <div className="text-gray-500 text-base font-light">
            ({bookingList.data.length})
          </div>
        </div>
        <div className="sm:justify-end items-start gap-2 flex flex-wrap">
          <button
            onClick={() => handleListFiltration(null)}
            className={`px-2.5 py-[3px] rounded-[100px] border border-blue-800 flex-col justify-start items-start gap-2.5 inline-flex ${
              activeState === "confirmed" ? "shadow-xl" : ""
            }`}
          >
            <div className="text-center text-blue-800 text-xs font-semibold font-['SF Pro Text'] leading-[18px]">
              {t("All")}
            </div>
          </button>
          <button
            onClick={() => handleListFiltration("confirmed")}
            className={`px-2.5 py-[3px] rounded-[100px] border border-[#1A7F37] flex-col justify-start items-start gap-2.5 inline-flex ${
              activeState === "confirmed" ? "shadow-xl" : ""
            }`}
          >
            <div className="text-center text-[#1A7F37] text-xs font-semibold font-['SF Pro Text'] leading-[18px]">
              {t("Accepted")}
            </div>
          </button>
          <button
            onClick={() => handleListFiltration("paid")}
            className={`px-2.5 py-[3px] rounded-[100px] border border-blue-600 flex-col justify-start items-start gap-2.5 inline-flex ${
              activeState === "paid" ? "shadow-xl" : ""
            }`}
          >
            <div className="text-center text-blue-600 text-xs font-semibold font-['SF Pro Text'] leading-[18px]">
              {t("Paid")}
            </div>
          </button>
          <button
            onClick={() => handleListFiltration("declined")}
            className={`px-2.5 py-[3px] rounded-[100px] border border-[#CF222E] flex-col justify-start items-start gap-2.5 inline-flex ${
              activeState === "declined" ? "shadow-xl" : ""
            }`}
          >
            <div className="text-center text-[#CF222E] text-xs font-semibold font-['SF Pro Text'] leading-[18px]">
              {t("Rejected")}
            </div>
          </button>
          <button
            onClick={() => handleListFiltration("new")}
            className={`px-2.5 py-[3px] rounded-[100px] border border-[#9A6700] flex-col justify-start items-start gap-2.5 inline-flex ${
              activeState === "new" ? "shadow-xl" : ""
            }`}
          >
            <div className="text-center text-[#9A6700] text-xs font-semibold font-['SF Pro Text'] leading-[18px]">
              {t("HOLD")}
            </div>
          </button>
          <button
            onClick={() => handleListFiltration("pending_payment")}
            className={`px-2.5 py-[3px] rounded-[100px] border border-[#9A6700] flex-col justify-start items-start gap-2.5 inline-flex ${
              activeState === "new" ? "shadow-xl" : ""
            }`}
          >
            <div className="text-center text-[#9A6700] text-xs font-semibold font-['SF Pro Text'] leading-[18px]">
              {t("Pending Payment")}
            </div>
          </button>
          <button
            onClick={() => handleListFiltration("cancelled")}
            className={`px-2.5 py-[3px] rounded-[100px] border border-gray-dark flex-col justify-start items-start gap-2.5 inline-flex ${
              activeState === "cancelled" ? "shadow-xl" : ""
            }`}
          >
            <div className="text-center text-gray-dark text-xs font-semibold font-['SF Pro Text'] leading-[18px]">
              {t("Cancelled")}
            </div>
          </button>
        </div>
      </div>

      {bookingList.loading && <Loader slim />}
      <div className="flex flex-col gap-6 mb-12">
        {!bookingList.loading &&
          currentItems.map((item) => {
            const daysLeft = calculateDaysLeft(item.payment_date);
            return (
              <div
                onClick={() => navigate(`/user/booking-list/${item.id}`)}
                className="cursor-pointer p-6 bg-zinc-100 rounded-2xl justify-start items-start gap-8 inline-flex w-full"
              >
                <div className="justify-start items-start gap-8 flex w-full flex-wrap md:flex-nowrap">
                  <div className="rounded-xl shadow flex-col justify-start items-start gap-4 inline-flex w-60">
                    <img
                      className="w-full rounded-xl h-[160px] object-cover"
                      src={item.venue.image}
                      alt="booking-list"
                    />
                  </div>
                  <div className="grow flex-col justify-start items-start gap-6 inline-flex">
                    <div className="self-stretch justify-start items-start gap-6 inline-flex">
                      <div className="flex-col justify-start items-start gap-4 inline-flex w-full">
                        <div className="self-stretch justify-between items-start inline-flex flex-wrap gap-2">
                          <p className="text-gray-dark lg:text-2xl text-xl font-medium uppercase">
                            {item.venue.facility.name}
                          </p>
                          <div className="justify-start items-start gap-6 flex">
                            <div className="justify-start items-center gap-2 flex">
                              <div className="justify-center items-center gap-2 flex">
                                <p className="text-red text-base font-normal leading-normal">
                                  {t("Reviews")}
                                </p>
                                <span className="text-neutral-400 text-sm font-normal leading-[21px]">
                                  ({item.venue.reviewsCount})
                                </span>
                              </div>
                              <div className="justify-start items-center gap-1.5 flex">
                                <div className="w-4 h-4 justify-center items-center flex">
                                  <AiFillStar className="text-base text-[#FFC200]" />
                                </div>
                                <div className="text-zinc text-base font-medium leading-normal">
                                  {Math.round(item.venue.rating * 10) / 10}
                                </div>
                              </div>
                            </div>
                            {item.state.id === "confirmed" && (
                              <div className="px-2.5 py-[3px] rounded-[100px] border border-[#1A7F37] flex-col justify-start items-start gap-2.5 inline-flex">
                                <div className="text-center text-[#1A7F37] text-xs font-semibold font-['SF Pro Text'] leading-[18px]">
                                  {t("Accepted")}
                                </div>
                              </div>
                            )}
                            {item.state.id === "new" && (
                              <div className="px-2.5 py-[3px] rounded-[100px] border border-[#9A6700] flex-col justify-start items-start gap-2.5 inline-flex">
                                <div className="text-center text-[#9A6700] text-xs font-semibold font-['SF Pro Text'] leading-[18px]">
                                  {t("HOLD")}
                                </div>
                              </div>
                            )}
                            {item.state.id === "pending_payment" && (
                              <div className="px-2.5 py-[3px] rounded-[100px] border border-[#9A6700] flex-col justify-start items-start gap-2.5 inline-flex">
                                <div className="text-center text-[#9A6700] text-xs font-semibold font-['SF Pro Text'] leading-[18px]">
                                  {t("Pending Payment")}
                                </div>
                              </div>
                            )}
                            {item.state.id === "declined" && (
                              <div className="px-2.5 py-[3px] rounded-[100px] border border-[#CF222E] flex-col justify-start items-start gap-2.5 inline-flex">
                                <div className="text-center text-[#CF222E] text-xs font-semibold font-['SF Pro Text'] leading-[18px]">
                                  {t("Rejected")}
                                </div>
                              </div>
                            )}
                            {item.state.id === "cancelled" && (
                              <div className="px-2.5 py-[3px] rounded-[100px] border border-gray-dark flex-col justify-start items-start gap-2.5 inline-flex">
                                <div className="text-center text-gray-dark text-xs font-semibold font-['SF Pro Text'] leading-[18px]">
                                  {t("Cancelled")}
                                </div>
                              </div>
                            )}
                            {item.state.id === "paid" && (
                              <div className="px-2.5 py-[3px] rounded-[100px] border border-blue-600 flex-col justify-start items-start gap-2.5 inline-flex">
                                <div className="text-center text-blue-600 text-xs font-semibold font-['SF Pro Text'] leading-[18px]">
                                  {t("Paid")}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="self-stretch flex-col justify-start items-start gap-[13px] flex">
                          <div className="self-stretch justify-start items-center gap-3 inline-flex">
                            <p className="text-gray-500 text-base font-light">
                              {item.venue.name}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="self-stretch justify-between items-end gap-6 inline-flex flex-wrap">
                      <div className="flex-col justify-start items-start gap-2 inline-flex">
                        <span className="text-gray-dark text-base font-normal leading-snug">
                          {t("Venue price")}
                        </span>
                        <span className="text-red lg:text-2xl text-xl font-bold leading-[33.60px]">
                          {item.price} EGP
                        </span>
                      </div>
                      <div className="justify-start items-center gap-3 flex">
                        {item.state.id === "confirmed" && (
                          <button
                            key={item.id}
                            onClick={(e) => handlePaymentOpen(e, item.id)}
                            className={`px-8 py-[9px] rounded-[10px] shadow border border-red justify-center items-center gap-2.5 flex ${
                              isDisabled(item.confirmedDate)
                                ? "cursor-not-allowed opacity-50"
                                : ""
                            }`}
                            disabled={isDisabled(item.confirmedDate)}
                          >
                            <span className="text-center text-red text-base font-normal font-['Inter'] leading-tight">
                              {isDisabled(item.confirmedDate)
                                ? t("Make Payment (no hours remaining)")
                                : t("Make Payment")}
                            </span>
                          </button>
                        )}
                        {item.state.id === "new" && (
                          <div className="flex items-center gap-3">
                            {/* <button className="px-8 py-[9px] rounded-[10px] shadow border border-red justify-center items-center gap-2.5 flex">
                          <span className="text-center text-red text-base font-normal font-['Inter'] leading-tight">
                          Edit
                          </span>             
                      </button> */}
                            <button
                              onClick={(e) =>
                                handleCancellationOpen(e, item.id)
                              }
                              className="px-8 py-[9px] rounded-[10px] shadow border border-red justify-center items-center gap-2.5 flex"
                            >
                              <span className="text-center text-red text-base font-normal font-['Inter'] leading-tight">
                                {t("Cancel")}
                              </span>
                            </button>
                          </div>
                        )}
                        {item.state.id === "pending_payment" && (
                          <div className="flex items-center gap-3">
                            <button
                              onClick={(e) =>
                                handleCancellationOpen(e, item.id)
                              }
                              className="px-8 py-[9px] rounded-[10px] shadow border border-red justify-center items-center gap-2.5 flex"
                            >
                              <span className="text-center text-red text-base font-normal font-['Inter'] leading-tight">
                                {t("Cancel")}
                              </span>
                            </button>
                          </div>
                        )}
                        {item.state.id === "declined" && (
                          <div className="flex items-center gap-3">
                            {/* <button disabled className="opacity-25 px-8 py-[9px] rounded-[10px] shadow border border-red justify-center items-center gap-2.5 flex">
                          <span className="text-center text-red text-base font-normal font-['Inter'] leading-tight">
                          Edit
                          </span>             
                      </button> */}
                            {/* <button disabled className="opacity-25 px-8 py-[9px] rounded-[10px] shadow border border-red justify-center items-center gap-2.5 flex">                     
                          <span className="text-center text-red text-base font-normal font-['Inter'] leading-tight">
                          Cancel
                          </span>                
                      </button> */}
                          </div>
                        )}
                        {item.state.id === "cancelled" && (
                          <div className="flex items-center gap-3">
                            {/* <button disabled className="opacity-25 px-8 py-[9px] rounded-[10px] shadow border border-red justify-center items-center gap-2.5 flex">
                          <span className="text-center text-red text-base font-normal font-['Inter'] leading-tight">
                          Edit
                          </span>             
                      </button> */}
                            {/* <button disabled className="opacity-25 px-8 py-[9px] rounded-[10px] shadow border border-red justify-center items-center gap-2.5 flex">                     
                          <span className="text-center text-red text-base font-normal font-['Inter'] leading-tight">
                          Cancel
                          </span>                
                      </button> */}
                          </div>
                        )}
                        {item.state.id === "paid" && (
                          <div className="flex items-center gap-3">
                            <button
                              disabled={daysLeft === 0}
                              onClick={(e) =>
                                handleCancellationOpen(e, item.id)
                              }
                              className={`px-8 py-[9px] rounded-[10px] shadow border border-red justify-center items-center gap-2.5 flex ${
                                daysLeft === 0
                                  ? "cursor-not-allowed opacity-50"
                                  : ""
                              }`}
                            >
                              <span className="text-center text-red text-base font-normal font-['Inter'] leading-tight">
                                {t("Cancel")}
                              </span>
                              <span className="text-zinc-500 text-base font-normal font-['Inter'] leading-tight">
                                ({daysLeft} {daysLeft !== 1 ? t("Day") : daysLeft !== 0 ? t("Day") : t("Days")} {t("left")})
                              </span>
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>

      <ReactPaginate
        breakLabel="..."
        nextLabel={t("Next >")}
        onPageChange={handlePageClick}
        pageRangeDisplayed={2}
        pageCount={pageCount}
        previousLabel={t("< Previous")}
        renderOnZeroPageCount={null}
        containerClassName="pagination"
        pageLinkClassName="page-num"
        previousLinkClassName="page-btn"
        nextLinkClassName="page-btn"
        activeLinkClassName="active"
        disabledLinkClassName="disabled-btn"
      />
    </section>
  );
}

export default BookingList;
