import React, { useEffect, useState } from 'react'
import {SlideshowLightbox} from 'lightbox.js-react'
import 'lightbox.js-react/dist/index.css'
import axios from 'axios'
import useAxiosUserSecureConfig from '../../hooks/useAxiosUserSecureConfig';
          
function VenueGallery({mainImg ,images , venueId}) {
 
  const config = useAxiosUserSecureConfig();
  const [imagesCopy , setImagess] = useState({loading :true , data : []})
  let mainImage = {src : mainImg , alt :"img"}
  
  useEffect(() => {
    setImagess({
      loading: true,
      data: [],
    });
  
    const fetchImages = async () => {
      try {
        const imagesData = await Promise.all(
          images.map(async (id) => {
            const response = await axios.post(`/api/venue/${venueId}/image/${id}`,{},config);
            console.log("response" , response)
            return {
              src: response.data.result.image,
              alt: "img",
            };
          })
        );
        setImagess({ loading: false, data: imagesData });
      } catch (error) {
        console.error("Error fetching images:", error);
        setImagess({ loading: false, data: [] });
      }
    };
  
    fetchImages();
  }, [images]);
  
    

  useEffect(()=>{
    imagesCopy.data.push(mainImage)
  },[imagesCopy.data])
  console.log("copy",imagesCopy)
  useEffect(()=> {
    setImagess((prevState) => ({
      ...prevState,
      loading: false,
    }));
  },[])
 
  
  console.log("imagesCopy",imagesCopy)
 

  return (
    <>
     {!imagesCopy.loading && ( <SlideshowLightbox images={imagesCopy.data} 
        className='slideshowLightbox container grid grid-cols-3 gap-2 mx-auto venue-gallery' showThumbnails={true}>
      </SlideshowLightbox> )}
    </>
  )
}
export default VenueGallery