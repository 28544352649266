import React, { useEffect, useState } from 'react';
import contactus from '../../assets/images/ContactUs/contactPhoto.png'
import upload from '../../assets/images/plan/upload.png'
import left from '../../assets/images/plan/left.png'
import right from '../../assets/images/plan/right.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faCaretDown, faX } from '@fortawesome/free-solid-svg-icons';
import Layer1 from "../../assets/images/About/Layer_1.png"
import vector3 from "../../assets/images/About/Vector 2.png"
import vector2 from "../../assets/images/About/Vector3.png" 
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import { FaAngleDown,FaAngleUp  } from "react-icons/fa";
import { Box, Modal,Typography, Checkbox, FormControlLabel,FormGroup } from '@mui/material';
import { useTable, useSortBy, usePagination } from 'react-table';
import eyeIcon from "../../assets/images/icons/eye.png";
import filterIcon from "../../assets/images/sevices/filter.png";
import actionIcon from "../../assets/images/plan/action.png";
import daterIcon from "../../assets/images/plan/date.png";
import progressIcon from "../../assets/images/plan/progress.png";
import costIcon from "../../assets/images/plan/cost.png";
import notesIcon from "../../assets/images/plan/notes.png";
import weddingIcon from "../../assets/images/plan/happy-wedding 1.png";
import axios from 'axios';
import { toast } from 'react-toastify';
import useAxiosUserSecureConfig from '../../hooks/useAxiosUserSecureConfig';
import TableComponent from '../../components/plan/TableComponent';
import Loader from "../../components/Loader";
import {useTranslation} from "react-i18next";"Action"



function WeddingPlan() { 
  const {t ,i18n } = useTranslation();
  const columns = React.useMemo(
    () => [ { Header: t('Action'), accessor: 'name' },
    { Header: t('Date'), accessor: 'date' },
    { Header: t('Progress'), accessor: 'state' },
    { Header: t('Cost'), accessor: 'cost' },
    { Header: t('Notes'), accessor: 'notes' }, 

    ],
    [t]
  );
  const iconMapping = {
    name: actionIcon,
    date: daterIcon,
    progress: progressIcon,
    cost: costIcon,
    notes: notesIcon,
  };
 
  const [open, setOpen] = useState({
    '10-12m': false,
    '8-10m': false,
    '4-8m': false,
    '1m': false,
    '1-2w': false,
    '1-2d': false,
    'wedding_day': false,
});
const handleClick = (key) => {
  setOpen(prevState => ({
      ...Object.fromEntries(Object.keys(prevState).map(k => [k, k === key ? !prevState[k] : false]))
  }));
};

const [weddingPlan , setWeddingPlan]= useState({ loading: true, data: [] });
const [currentPage, setCurrentPage] = useState(0);
const [filter, setFilter] = useState(null);

const [progressfilter, setProgressFilter] = useState(null);
const [progressShowDropdown, setProgressShowDropdown] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
const handleProgressFilterChange = (value) => {
  setProgressFilter(value);
  setProgressShowDropdown(false);
};
const [openModal, setOpenModal] =useState(false);
const handleClose = () => setOpenModal(false); 
const [openModalView, setOpenModalView] =useState(false);
const handleCloseView = () => setOpenModalView(false);

const config = useAxiosUserSecureConfig();

const fetchData = ()=> {
  axios.post('/api/wedding-plan', {} , config).then((res) => {
    console.log("wedding-plan", res.data);
    if (!res.data?.result) {
      toast.error("Error while fetching wedding plan!");
      return;
    }
    setWeddingPlan((prevState) => ({
      ...prevState,
      loading: false,
      data: res.data?.result
    }));
  }).catch((err) => {
    console.log(err);
  })
}

useEffect(() => {
  fetchData()
}, []);
console.log("---> ",weddingPlan.data)
const calculateSubtotal = (dataArray) => {
  if (!dataArray) return 0; // Return 0 if dataArray is undefined
  return dataArray.reduce((subtotal, item) => subtotal + item.cost, 0);
};

// Calculate subtotal for each timeframe
const subtotalByTimeframe = {};
for (const timeframe in weddingPlan.data) {
  const subtotal = calculateSubtotal(weddingPlan.data[timeframe]);
  subtotalByTimeframe[timeframe] = subtotal;
}

let totalSubtotal = 0;

// Iterate over subtotalByTimeframe and add up all the subtotals
for (const timeframe in subtotalByTimeframe) {
  totalSubtotal += subtotalByTimeframe[timeframe];
}
const [notes, setNotes] = useState('');
const [noteId, setNoteId] = useState();

const handleAddNotes = (id) => {
  setOpenModal(true)
  console.log("id",id)
  setNoteId(id)
 }
 const handleSaveNotes = () => {
  const data = {
    notes: notes,
  };
  axios.post(`/api/wedding-plan/item/${noteId}` ,data, config)
      .then((res) => {
        console.log(res.data)
        if (!res.data.result?.success) {
          toast.error(res.data.result?.message || "Request failed!");
          return;
        }
        toast.success(t("You add notes successfully!"));
        fetchData()
        setOpenModal(false)
        setOpenModalView(false)
        setNotes('')
      })
      .catch((err) => {
        console.log("err" , err)
        toast.error("Error while adding notes!");
      })
 }
 const [viewNotes , setViewNotes] = useState()
 const handleViewNotes = (notes , id) => {
  setOpenModalView(true)
  setViewNotes(notes)
  setNoteId(id)
 }
 const handleAddDate = (date , id)=> {
  axios.post(`/api/wedding-plan/item/${id}` ,{
    target_date : date
  }, config)
      .then((res) => {
        console.log(res.data)
        if (!res.data.result?.success) {
          toast.error(res.data.result?.message || "Request failed!");
          return;
        }
        toast.success(t("You add date successfully!"));
        fetchData()
      })
      .catch((err) => {
        console.log("err" , err)
        toast.error("Error while adding date!");
      })
 }
 const handleChangProgress = (value , id) => {
  axios.post(`/api/wedding-plan/item/${id}` ,{
    state : value
  }, config)
      .then((res) => {
        console.log(res.data)
        if (!res.data.result?.success) {
          toast.error(res.data.result?.message || "Request failed!");
          return;
        }
        toast.success(t("You change progress successfully!"));
        fetchData()
      })
      .catch((err) => {
        console.log("err" , err)
        toast.error("Error while changing progress!");
      })
 }

 const handleChangeCost = (value , id) => {
  console.log(value, id)
  
  axios.post(`/api/wedding-plan/item/${id}` ,{
    cost : value
  }, config)
      .then((res) => {
        console.log(res.data)
        if (!res.data.result?.success) {
          toast.error(res.data.result?.message || "Request failed!");
          return;
        }
        fetchData()
      })
      .catch((err) => {
        console.log("err" , err)
        toast.error("Error while changing cost!");
      })
 }
 const handleDeleteAction = (id) => {
  console.log(id)
  axios.post(`/api/wedding-plan/item/${id}/delete` ,{}, config)
      .then((res) => {
        console.log(res.data)
        if (!res.data.result?.success) {
          toast.error(res.data.result?.message || "Request failed!");
          return;
        }
        toast.success("You delete action successfully!");
        fetchData()
      })
      .catch((err) => {
        console.log("err" , err)
        toast.error("Error while deleting action!");
      })
 }
 
 const [showDropdown, setShowDropdown] = useState(false);
  const [filterState, setFilterState] = useState(null); // State to hold the filter value

  // Function to handle filter change
  const handleFilterChange = (state) => {
    setFilterState(state); // Set the filter state
    setShowDropdown(false); // Close the dropdown
  };
  const item = weddingPlan.data["10-12m"]?.find(
    (obj) => obj.name === "Set date/time"
  );
  const targetDate = item ? item.target_date : "";

  let totalCount = 0;
  let completedCount = 0;

  // Iterate over each array
  for (const timeframe in weddingPlan.data) {
    // Get the items in the current timeframe
    const items = weddingPlan.data[timeframe];

    // Filter out items with the state "not_required"
    const relevantItems = items.filter((item) => item.state !== "not_required");

    // Update the total count and the completed count
    totalCount += relevantItems.length;
    completedCount += relevantItems.filter(
      (item) => item.state === "completed"
    ).length;
  }

  // Calculate the percentage
  const percentageCompleted = (completedCount / totalCount) * 100;

  console.log("Percentage completed:", percentageCompleted.toFixed(2) + "%");

  const [newActionModal, setNewActionModal] = useState(false);
  const [newActionName, setNewActionName] = useState('');
  const [actionKey, setActionKey] = useState('');

  const handleOpenModal = (key) => {
    setNewActionModal(true)
    setActionKey(key);
  };

  const handleCloseModal = () => {
    setNewActionModal(false);
    setNewActionName('');
    setActionKey('')
  };
  const handleAddNewAction = () => {
    axios
      .post(
        `/api/wedding-plan/item/create`,
        {
          name: newActionName,
          date: actionKey,
        },
        config
      )
      .then((res) => {
        console.log(res.data);
        if (!res.data.result?.success) {
          toast.error(res.data.result?.message || "Request failed!");
          return;
        }
        toast.success(t("You add item successfully!"));
        fetchData();
      })
      .catch((err) => {
        console.log("err", err);
        toast.error("Error while adding item!");
      });
    handleCloseModal();
  };

  const handleDownloadPDF = () => {  
    // Ensure 'Content-Type' is set to 'application/pdf'
    const headers = {
      ...config.headers,
      'Content-Type': 'application/pdf'
    };
  
    axios.post(`/api/wedding-plan/print`, {}, { ...config, headers, responseType: 'blob' })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'wedding plan.pdf');
        document.body.appendChild(link);
        link.click();
        link.remove();
        })
      .catch((err) => {
        console.error("Error:", err);
        toast.error("Error while downloading PDF!");
      });
  };
  

  return (
    <>
      <div className="relative">
        <img className="w-full" src={contactus} alt="contactus" />
        <div className="z-10 px-5 xl:px-10 absolute bottom-[20%] w-full flex justify-between">
          <h1 className=" text-white text-lg md:text-2xl sm:text-[40px] font-medium leading-[44px]">
            {t("Wedding Plan")}
          </h1>
        </div>
        <img
          src={Layer1}
          className="absolute left-[35%] top-[55%] md:top-[73%] md:left-[40%] w-2/6 md:w-1/5 "
        />
      </div>
      {weddingPlan.loading && <Loader slim />}
      {!weddingPlan.loading && (
        <section className="px-4 sm:px-6 md:px-12 py-10 bg-zinc-100">
          <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className="absolute w-11/12 md:w-2/5 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 px-8 py-6 bg-white rounded-2xl shadow flex-col justify-center items-start inline-flex">
              <div className="w-full ">
                <div className="flex justify-end"></div>
                <div className="flex flex-col items-center gap-2 mb-4">
                  <p className="text-center text-xl text-red">{t("Add notes")}</p>
                  <textarea
                    placeholder={t("Type here..")}
                    className="h-24 w-full bg-[#F5F5F5] placeholder-[#CCCCCC] border-0 rounded-2xl"
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                  />
                </div>
                <div className="w-full flex justify-center gap-2">
                  {/* Button to cancel */}
                  <button
                    onClick={handleClose}
                    className="h-9 w-[105px] px-[18px] py-[6.40px] bg-white rounded-lg shadow border border-red justify-center items-center gap-2.5 inline-flex"
                  >
                    <span className="text-center text-red text-base font-normal font-['Roboto'] leading-normal">
                      {t("Cancel")}
                    </span>
                  </button>
                  {/* Button to add notes */}
                  <button
                  disabled={notes === ''}
                    onClick={handleSaveNotes}
                    className={`${notes === '' && "opacity-50"} h-9 w-[105px] px-[18px] py-[6.40px] bg-red rounded-lg shadow border border-red justify-center items-center gap-2.5 inline-flex`}
                  >
                    <span className="text-center text-white text-base font-normal font-['Roboto'] leading-normal">
                      + {t("Add")}
                    </span>
                  </button>
                </div>
              </div>
            </Box>
          </Modal>
          <Modal
            open={openModalView}
            onClose={handleCloseView}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className="absolute md:w-2/5 w-11/12 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 px-8 py-6 bg-white rounded-2xl shadow flex-col justify-center items-start inline-flex">
              <div className="w-full ">
                <div className="flex justify-end"></div>
                <div className="flex flex-col items-center gap-2 mb-4">
                  <p className="text-center text-xl text-red">{t("Notes")}</p>
                  <textarea
                  defaultValue={viewNotes}
                  onChange={(e) => setNotes(e.target.value)}
                    placeholder={t("Type here..")}
                    className="h-24 w-full bg-[#F5F5F5] placeholder-[#CCCCCC] border-0 rounded-2xl"
                  />
                </div>
                <div className="w-full flex justify-center gap-2">
                  <button
                    onClick={() => handleCloseView()}
                    className="h-9 w-[105px] px-[18px] py-[6.40px] bg-white rounded-lg shadow border border-red justify-center items-center gap-2.5 inline-flex"
                  >
                    <span className="text-center text-red text-base font-normal font-['Roboto'] leading-normal">
                      {t("Cancel")}
                    </span>
                  </button>
                  <button
                  disabled={notes === ''}
                    onClick={handleSaveNotes}
                    className={`${notes === '' && "opacity-50"} h-9 w-[105px] px-[18px] py-[6.40px] bg-red rounded-lg shadow border border-red justify-center items-center gap-2.5 inline-flex`}
                  >
                    <span className="text-center text-white text-base font-normal font-['Roboto'] leading-normal">
                      {t("Save")}
                    </span>
                  </button>
                </div>
              </div>
            </Box>
          </Modal>
          <Modal
            open={newActionModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         
          >
            <Box className="absolute w-4/5  md:w-2/5 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 px-8 py-6 bg-white rounded-2xl shadow flex-col justify-center items-start inline-flex">
              <div className="w-full">
                <div className="flex justify-end"></div>
                <div className="flex flex-col items-center gap-2 mb-4">
                  <p className="text-center text-xl text-red">{t("Enter Action Name")}</p>
                  <textarea
                  defaultValue={viewNotes}
                  onChange={(e) => setNewActionName(e.target.value)}
                    placeholder={t("Type here..")}
                    className="h-24 w-full bg-[#F5F5F5] placeholder-[#CCCCCC] border-0 rounded-2xl"
                  />
                </div>
                <div className="w-full flex justify-center gap-2">
                  <button
                    onClick={() => handleCloseModal()}
                    className="h-9 w-[105px] px-[18px] py-[6.40px] bg-white rounded-lg shadow border border-red justify-center items-center gap-2.5 inline-flex"
                  >
                    <span className="text-center text-red text-base font-normal font-['Roboto'] leading-normal">
                      {t("Cancel")}
                    </span>
                  </button>
                  <button
                  disabled={newActionName === ''}
                    onClick={()=> handleAddNewAction()}
                    className={`${newActionName === '' && "opacity-50"} h-9 w-[105px] px-[18px] py-[6.40px] bg-red rounded-lg shadow border border-red justify-center items-center gap-2.5 inline-flex`}
                  >
                    <span className="text-center text-white text-base font-normal font-['Roboto'] leading-normal">
                      {t("Add")}
                    </span>
                  </button>
                </div>
              </div>
            </Box>
          </Modal>
          <div className={`flex justify-center ${i18n.language === 'en' ? 'flex-row' : 'flex-row-reverse'} mb-10`}>
            <img src={left} alt="left" className='w-2/6 md:w-1/5 object-contain' />
            <p className="text-red text-[13px] sm:text-[25px] md:text-[40px] font-normal md:leading-[64px] tracking-wide w-4/5 md:w-2/5 text-center">
              {t("Complete Your Plan to Celebrate with your partner")}
            </p>
            <img src={right} alt="right" className='w-2/6 md:w-1/5 object-contain'/>
          </div>
          <div className="flex gap-1 items-center mb-10">
            <div className="h-3 w-full bg-gradient-to-l from-[#CFCFCF] to-transparent rounded-[120px] pl-12">
              <div
                className="h-3 bg-gradient-to-l from-red to-transparent rounded-[120px] relative"
                style={{ width: percentageCompleted.toFixed(2) + "%" }}
              >
                <div className="absolute end-0 -top-2.5 w-8 h-8 bg-white border-8 border-[#982E2E] rounded-full shadow-xl"></div>
                <div className="absolute -end-4 -top-14 w-[74px] h-9 bg-red text-white rounded-full flex justify-center items-center text-[15px] font-bold">
                  {percentageCompleted.toFixed(2) + "%"}
                </div>
                <div className="absolute -end-4 top-8 text-red text-sm md:text-base font-normal">
                  {t("Completed")}
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-2 justify-center items-center">
              <img src={weddingIcon} alt="weddingIcon" />
              <span className="text-red text-nowrap text-lg">{targetDate}</span>
            </div>
          </div>
          <div className="border border-zinc-400 rounded-lg p-2 md:p-6 flex justify-between items-center mb-10">
            <p className="text-red text-base md:text-2xl font-medium">{t("Total cost to date")}</p>
            <p className="text-red text-base md:text-2xl font-medium">{totalSubtotal} {t("EGP")}</p>
          </div>
          <div className="w-full flex flex-col gap-6 mb-10">
            <div className="flex justify-end">
              <div className="relative inline-block ">
                <button
                  className="px-6 py-2 rounded-lg border border-red justify-center items-center flex gap-2"
                  onClick={(e) => {
                    setShowDropdown(!showDropdown);
                    e.stopPropagation();
                  }}
                >
                  <img src={filterIcon} alt="filterIcon" />
                  <span className="text-red text-sm md:text-base font-medium leading-none tracking-wide">
                    {t("Progress")}
                  </span>
                  <FontAwesomeIcon
                    className="text-red text-sm md:text-base font-medium leading-none tracking-wide"
                    icon={faCaretDown}
                  />
                </button>
                {showDropdown && (
                  <ul className="absolute left-0 mt-2 py-2 w-48 bg-white border border-gray-200 rounded-lg shadow-lg z-10">
                    <li
                      className="cursor-pointer px-4 py-2 hover:bg-gray-100"
                      onClick={() => handleFilterChange(null)}
                    >
                      {t("Show All")}
                    </li>
                    <li
                      className="cursor-pointer px-4 py-2 hover:bg-gray-100"
                      onClick={() => handleFilterChange("completed")}
                    >
                      {t("Completed")}
                    </li>
                    <li
                      className="cursor-pointer px-4 py-2 hover:bg-gray-100"
                      onClick={() => handleFilterChange("not_required")}
                    >
                      {t("Not Required")}
                    </li>
                    <li
                      className="cursor-pointer px-4 py-2 hover:bg-gray-100"
                      onClick={() => handleFilterChange("in_progress")}
                    >
                      {t("In Progress")}
                    </li>
                    <li
                      className="cursor-pointer px-4 py-2 hover:bg-gray-100"
                      onClick={() => handleFilterChange("not_started")}
                    >
                      {t("Not Started")}
                    </li>
                  </ul>
                )}
              </div>
            </div>
            {Object.keys(weddingPlan.data).map((key) => (
              <div
                className="flex flex-col w-full"
                component="nav"
                aria-labelledby="nested-list-subheader"
              >
                <div
                  className=" bg-white p-6 flex justify-between items-center"
                  onClick={() => handleClick(key)}
                >
                  <ListItemText
                    className="text-red"
                    primary={
                      <Typography className="text-red !text-lg md:!text-2xl !font-medium !leading-none ">
                        {key === "10-12m"
                          ? t("10 to 12 Months Out")
                          : key === "8-10m"
                          ? t("8 to 10 Months Out")
                          : key === "4-8m"
                          ? t("4 to 8 Months Out")
                          : key === "1m"
                          ? t("One Month Out")
                          : key === "1-2w"
                          ? t("One to Two Weeks Out")
                          : key === "1-2d"
                          ? t("One to Two Days Out")
                          : t("Wedding Day Out")}{" "}
                      </Typography>
                    }
                  />
                  <div className="flex items-center gap-2">
                    {open[key] ? (
                      <div className="bg-[#F4F4F4] p-3 rounded-lg cursor-pointer">
                        <FaAngleUp />
                      </div>
                    ) : (
                      <div className="bg-[#F4F4F4] p-3 rounded-lg cursor-pointer">
                        <FaAngleDown />
                      </div>
                    )}
                  </div>
                </div>
              
                <Collapse
                  className="bg-white !overflow-x-auto p-6"
                  in={open[key]}
                  timeout="auto"
                  unmountOnExit
                >
                  <TableComponent
                    columns={columns}
                    data={weddingPlan.data[key].filter(row => !filterState || row.state === filterState)}
                    handleAddNotes={handleAddNotes}
                    handleViewNotes={handleViewNotes}
                    handleAddDate ={handleAddDate}
                    handleChangProgress={handleChangProgress}
                    handleChangeCost={handleChangeCost}
                    handleDeleteAction={handleDeleteAction}
                  />
                  <button
                    onClick={()=>handleOpenModal(key)}
                    className="self-stretch h-10 p-4 bg-red rounded-lg justify-center items-center inline-flex gap-3"
                  >
                    <span className="text-white text-sm font-normal leading-none tracking-wide">
                      + {t("Add New Action")}
                    </span>
                  </button>
                </Collapse>
              </div>
            ))}
          </div>
          <div className="flex justify-end">
            <button
              onClick={() => handleDownloadPDF()}
              className="self-stretch h-12 p-4 bg-red rounded-lg justify-center items-center inline-flex gap-3"
            >
              <img src={upload} alt="upload" />
              <span className="text-white text-base font-normal leading-none tracking-wide">
                {t("Download Plan")}
              </span>
            </button>
          </div>
        </section>
      )}
    </>
  );
}

export default WeddingPlan;