import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const cartSlice = createSlice({
  name: "cart",
  initialState: { loading: false, items: [], currency: "EGP", shipping: 0, taxes: 0, total: 0, grand_total: 0 },
  reducers: {
    addToCart(state, action) {
      const existingIndex = state.items.findIndex((item) => item.id === action.payload.id);

      if (existingIndex < 0) {
        state.items.push({ ...action.payload, qty: 1 });
      } else {
        state.items[existingIndex] = {
          ...state.items[existingIndex],
          qty: state.items[existingIndex].qty + 1,
        };
      }

      console.log("cart items", state.items);
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setCart(state, action) {
      state.items = action.payload.items;
      state.currency = action.payload.currency;
      state.shipping = action.payload.shipping;
      state.taxes = action.payload.taxes;
      state.total = action.payload.total;
      state.grand_total = action.payload.grand_total;
    },
    clear(state) {
      state.items = [];
      state.currency = "EGP";
      state.shipping = 0;
      state.taxes = 0;
      state.total = 0;
      state.grand_total = 0;
    },
  },
});

export const cartActions = cartSlice.actions;

export default cartSlice.reducer;